import React, { FC, useState } from 'react';
import { Box, MenuItem, Select, TableCell, TableRow } from '@material-ui/core';
import _ from 'lodash';
import {
  HowToBuyOptionsResult,
  getHowToBuyOptions,
} from '../HowToBuy4/utils/getHowToBuyOptions';
import { labelProvider } from '../../../../constants/label-provider';

const transfromAdditionCell = (cellInfo: string) => {
  if (!cellInfo) {
    return;
  }

  return cellInfo.split('\n').map((record: any) => {
    const [shippingMethod, ...restInfo] = record.split(':');

    const info = typeof restInfo === 'string' ? restInfo : restInfo.join(':');

    return (
      <Box>
        <b>{shippingMethod}</b>: {info}
      </Box>
    );
  });
};

interface HowToBuyRowProps {
  warehouse: string;
  howToBuyOption: HowToBuyOptionsResult['howToBuyOptions'][string];
  bestShipping?: ReturnType<typeof getHowToBuyOptions>['bestShipping'];
  onRowClick: (
    rid: string,
    incomingWarehouse: string,
    shippingMethod: string,
    enterTracknumber: string,
    labelProvider: string,
  ) => void;
  onShippingMethodChange: (warehouse: string, shippingMethod: string) => void;
  onLabelProviderChange: (warehouse: string, labelProvider: string) => void;
  selectedItems: {
    warehouse: string;
    rid: string;
  }[];
  uniqueItemsQty: number;
  isPickup: boolean;
}

const enterTracknumberOptions = [
  'products in order',
  'manually',
  "don't enter",
  'product in parcel',
];

const HowToBuyRow: FC<HowToBuyRowProps> = ({
  warehouse,
  howToBuyOption,
  bestShipping,
  onRowClick,
  uniqueItemsQty,
  selectedItems,
  onShippingMethodChange,
  onLabelProviderChange,
  isPickup,
}) => {
  const [isQuantityShowed, setIsQuantityShowed] = useState(false);

  const getPriceByHowToBuy = (data: typeof howToBuyOption[number]) => {
    const rid = data?.rollunId;

    if (rid) {
      // handle how to buy 4
      if (
        data.shippingOptions?.length > 1 &&
        typeof data.shippingOptions[0] === 'object'
      ) {
        return data.shippingOptions[0]?.price_item || 0;
      }

      // handle create linked deals v2
      return data.price;
    }
  };

  const getShippingMethodsFromOptions = (data: typeof howToBuyOption) => {
    if (howToBuyOption[0].shippingMethods) {
      return howToBuyOption[0].shippingMethods;
    }

    const shippingMethods = data.map(({ shippingOptions }) =>
      shippingOptions.map(({ shippingMethodName }) => shippingMethodName),
    );
    return [...new Set(shippingMethods.flat())];
  };

  const isSelectedRid = (rid: string) =>
    selectedItems.find(
      ({ warehouse: selectedWarehouse, rid: selectedRid }) =>
        selectedWarehouse === warehouse && selectedRid === rid,
    );

  const currentRids = howToBuyOption.map(({ rollunId }) => rollunId);

  const uniqueItems =
    currentRids.length < uniqueItemsQty
      ? Array(uniqueItemsQty - currentRids.length).fill(null)
      : null;

  return (
    <TableRow key={warehouse}>
      <TableCell>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          {howToBuyOption[0]?.inventoryQuantity ? (
            <>
              <Box
                onClick={() => setIsQuantityShowed(true)}
                style={{
                  color: isQuantityShowed ? 'red' : 'blue',
                  cursor: isQuantityShowed ? 'auto' : 'pointer',
                }}
              >
                {_.upperFirst(warehouse)}
              </Box>
              {isQuantityShowed && (
                <Box>
                  {howToBuyOption.map((option, optionIndex) => {
                    return option && option.inventoryQuantity ? (
                      <React.Fragment key={optionIndex}>
                        <Box
                          style={{
                            color: 'red',
                          }}
                        >
                          RID - {option.rollunId}
                        </Box>
                        {option?.inventoryQuantity &&
                          Object.entries(option.inventoryQuantity).map(
                            ([location, quantity], index) => (
                              <Box key={index}>
                                {location}: {quantity}
                              </Box>
                            ),
                          )}
                      </React.Fragment>
                    ) : (
                      <></>
                    );
                  })}
                </Box>
              )}
            </>
          ) : (
            <Box>{_.upperFirst(warehouse)}</Box>
          )}
        </Box>
      </TableCell>
      {howToBuyOption.map((data, index) => {
        return (
          <TableCell key={index}>
            <Box
              display="flex"
              alignItems="center"
              style={{
                gap: 16,
                padding: 8,
                ...(isSelectedRid(data.rollunId as string) && {
                  border: '1px solid blue',
                  borderRadius: 30,
                }),
                ...(getPriceByHowToBuy(data) === 0 && {
                  background: '#C8C8C8',
                  borderRadius: '5px',
                  opacity: '0.5',
                }),
              }}
              onClick={() => {
                if (getPriceByHowToBuy(data) !== 0) {
                  onRowClick(
                    data.rollunId as string,
                    warehouse,
                    getShippingMethodsFromOptions(howToBuyOption)[0], // mb wrong TODO
                    enterTracknumberOptions[0], // mb wrong TODO
                    labelProvider[0],
                  );
                }
              }}
            >
              <Box fontWeight="bold" fontSize={16}>
                {`${getPriceByHowToBuy(data)}$ ${
                  data.warehouseQty ? ` X ${data.warehouseQty}` : ''
                }`}
              </Box>
            </Box>
          </TableCell>
        );
      })}
      {!!uniqueItems &&
        uniqueItems.map(() => (
          <TableCell>
            <Box>Empty</Box>
          </TableCell>
        ))}
      <TableCell>
        <Select
          defaultValue={getShippingMethodsFromOptions(howToBuyOption)[0]}
          style={{
            minWidth: 150,
            maxWidth: 150,
          }}
          disabled={!currentRids.some((rid) => isSelectedRid(rid))}
          onChange={(e) =>
            onShippingMethodChange(warehouse, e.target.value as string)
          }
        >
          {getShippingMethodsFromOptions(howToBuyOption).map(
            (option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ),
          )}
        </Select>
      </TableCell>
      <TableCell>
        {isPickup ? (
          <Select
            defaultValue={labelProvider[0]}
            style={{
              minWidth: 150,
              maxWidth: 150,
            }}
            disabled={!currentRids.some((rid) => isSelectedRid(rid))}
            onChange={(e) =>
              onLabelProviderChange(warehouse, e.target.value as string)
            }
          >
            {labelProvider.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Box>Not pickup</Box>
        )}
      </TableCell>
      <TableCell>
        <Box
          style={{
            width: '320px',
          }}
        >
          {transfromAdditionCell((howToBuyOption[0] as any)?.deliveryCost)}
        </Box>
      </TableCell>
      <TableCell>
        <Box
          style={{
            width: '320px',
          }}
        >
          {!!uniqueItems && 'Not full order - '}
          {transfromAdditionCell((howToBuyOption[0] as any)?.cogs)}
        </Box>
      </TableCell>
      <TableCell>
        <Box
          style={{
            width: '410px',
          }}
        >
          {transfromAdditionCell((howToBuyOption[0] as any)?.labelProvider)}
        </Box>
      </TableCell>
      <TableCell>
        <Box
          style={{
            width: '410px',
          }}
        >
          {transfromAdditionCell((howToBuyOption[0] as any)?.trackNumberDate)}
        </Box>
      </TableCell>
      <TableCell>
        <Box
          style={{
            width: '410px',
          }}
        >
          {transfromAdditionCell(
            (howToBuyOption[0] as any)?.shippingArriveDate,
          )}
        </Box>
      </TableCell>
      <TableCell>
        <Box
          style={{
            width: '310px',
          }}
        >
          {transfromAdditionCell((howToBuyOption[0] as any)?.isDeliveryOnTime)}
        </Box>
      </TableCell>
      <TableCell>
        <Box
          style={{
            width: '320px',
          }}
        >
          {transfromAdditionCell((howToBuyOption[0] as any)?.hoursLate)}
        </Box>
      </TableCell>
      <TableCell>
        <Box
          style={{
            width: '310px',
          }}
        >
          {transfromAdditionCell((howToBuyOption[0] as any)?.daysLate)}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default HowToBuyRow;
