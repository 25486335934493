import React, { Component } from 'react';
import { RuleRow } from '../../utils/types';

class RulesView extends Component<{ rules: RuleRow[] }> {
  render() {
    const { rules } = this.props;
    return (
      <div className="my-2 mx-3">
        <h3>Rules</h3>
        <div>
          {rules.map((rule) => {
            return (
              <div key={rule.id} className="d-flex justify-content-between">
                <h5>{rule.role_id}</h5>
                <h5>{rule.resource_id}</h5>
                <h5>{rule.privilege_id}</h5>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default RulesView;
