import DatetimeEditor from '../components/Table/Cell/Editors/DatetimeEditor';
import JsonEditor from '../components/Table/Cell/Editors/JsonEditor';
import ManagersCommentEditor from '../components/Table/Cell/Editors/ManagersCommentEditor';
import React, {
  Component,
  ComponentClass,
  FunctionComponent,
  PureComponent,
} from 'react';
import DatetimeRenderer from '../components/Table/Cell/Renderers/DatetimeRenderer';
import StringDateRender from '../components/Table/Cell/Renderers/StringDateRender';
import JsonRenderer from '../components/Table/Cell/Renderers/JsonRenderer';
import { FieldConfig } from '../../../index';
import StatusRenderer from '../components/Table/Cell/Renderers/StatusRenderer';
import LinkRenderer from '../components/Table/Cell/Renderers/LinkRenderer';
import { CreateFormFieldConfig } from '../../AbstractService';
import Selector from '../components/Table/CreateFormInputEditor/Selector';
import { FluidFormField } from '../../../UI/FluidForm';
import ImageLinkRenderer from '../components/Table/Cell/Renderers/ImageLinkRenderer';
import BooleanRenderer from '../components/Table/Cell/Renderers/BooleanRenderer';
import DefaultRenderer, {
  IRendererProps,
} from '../components/Table/Cell/Renderers/DefaultRenderer';
import { IEditorProps } from '../components/Table/Cell/Editors/AbstractEditor';
import Tags from '../../../UI/Tags';
import LocalLinkRenderer from '../components/Table/Cell/Renderers/LocalLinkRenderer';
import ExternalLinkRenderer from '../components/Table/Cell/Renderers/ExternalLinkRenderer';
import ExternalLinkInControlRenderer from '../components/Table/Cell/Renderers/ExternalLinkInControlRenderer';

export type GridCellEditor =
  | PureComponent<IEditorProps>
  | Component<IEditorProps>
  | FunctionComponent<IEditorProps>
  | ComponentClass<IEditorProps>;

export interface ColumnConfig {
  label?: string;
  isEditable?: boolean;
  minWidth?: number;
  renderer?:
    | React.ReactElement
    | React.ComponentClass<any>
    | React.FunctionComponent<any>;

  rendererName?: string;
  editor?: GridCellEditor;
}

export interface ColumnsConfig {
  [columnmName: string]: ColumnConfig;
}

/**
 * Gets config, gain from server, and set actual functions/Renderers/Editors according to config
 * @param config
 * Every column in Table can have some additional settings.
 * @see https://github.com/rollun-com/rollun-frontend/blob/master/README.md (private repo)
 * Example:
 parseColumnsConfig([{
            editorName: "json"
            name: "data"
            rendererName: "json"
    }]); -> {data: {
                editor: class JsonEditor,
                editorName: 'json',
                renderer: () => {}, // some renderer function component
                rendererName: 'json'
    }}
 */

export const parseColumnsConfig = (config: FieldConfig[]): ColumnsConfig => {
  const columnsConfig: ColumnsConfig = {};
  config.forEach((fieldConfig: FieldConfig) => {
    const finalFieldInfo: ColumnConfig = fieldConfig;
    if (fieldConfig.editorName) {
      finalFieldInfo.editor = getActualEditor(fieldConfig.editorName);
    }
    if (fieldConfig.rendererName) {
      finalFieldInfo.renderer = getActualRenderer(
        fieldConfig.rendererName,
        fieldConfig.params,
      );
      finalFieldInfo.rendererName = fieldConfig.rendererName;
    }
    columnsConfig[fieldConfig.name] = finalFieldInfo;
  });
  return columnsConfig;
};

export const getActualEditor = (editorName: string): GridCellEditor => {
  switch (editorName) {
    case 'calendar':
      return DatetimeEditor;
    case 'json':
      return JsonEditor;
    case 'manager_comment':
      return ManagersCommentEditor;
    default:
      throw new Error(`Unknown editor name: ${editorName}`);
  }
};

const withParams = <T extends IRendererProps>(
  Component: FunctionComponent<T> | ComponentClass<T>,
  params?: any,
) => (props: any) => <Component {...props} config={params} />;

export const getActualRenderer = (
  rendererName: string,
  params?: any,
): React.FC<IRendererProps> => {
  switch (rendererName) {
    case 'default':
      return withParams(DefaultRenderer, params);
    case 'datetime':
      return DatetimeRenderer;
    case 'stringDate':
      return StringDateRender;
    case 'json':
      return JsonRenderer;
    case 'link':
      return LinkRenderer;
    case 'localLink':
      return withParams(LocalLinkRenderer, params);
    case 'externalLink':
      return withParams(ExternalLinkRenderer, params);
    case 'imageLink':
      return ImageLinkRenderer;
    case 'status':
      return withParams(StatusRenderer, params);
    case 'bool':
      return BooleanRenderer;
    case 'externalLinkInControl':
      return withParams(ExternalLinkInControlRenderer, params);
    default:
      throw new Error(`Unknown renderer name: ${rendererName}`);
  }
};

/**
 * Converts config of 'Create element form' in Table to actual Editors.
 * @param config
 */

export const getCreateFormConfig = (
  config: Array<CreateFormFieldConfig>,
): Array<FluidFormField> => {
  return config
    .filter((conf) => conf.field)
    .map((conf) => {
      // console.log('create ');
      // if (!conf.field) {
      //   return conf;
      // }
      // let editorName = conf.editorName;
      // if (!editorName && [ /tag/i ].some(re => re.test(conf.field))) {
      //   editorName = 'tag';
      // }
      let editor: React.FC<any>;
      switch (conf.editorName) {
        case 'selector':
          editor = (props: any) => (
            <Selector
              {...props}
              fieldName={conf.field}
              options={conf.options || []}
            />
          );
          break;
        case 'tag':
          editor = (props: any) => (
            <Tags onChange={(val) => props.onChange(JSON.stringify(val))} />
          );
          break;
        default:
          editor = () => <div>invalid editor</div>;
      }
      return {
        field: conf.field,
        editor: editor,
      };
    });
};
