import React, { useState } from 'react';
import axios from 'axios';
import { camelCaseToWords, last } from 'rollun-ts-utils';
import { ErrorType } from '../../../../utils/common.types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from '../../../../UI';
import '../../../../index.css';
import MuiTooltip from '../../../../UI/MuiTooltip';
import { Box } from '@material-ui/core';

interface IProps {
  appName: string;
  dataStoreUrl: string;
}

type HttpDataStore = { name: string; url: string };

export const removeFirstSlash = (string: string): string => {
  return string[0] === '/' ? string.slice(1, string.length) : string;
};

const TableHeader: React.FC<IProps> = ({ appName, dataStoreUrl }) => {
  const [datastoreUrl, setDatastoreUrl] = useState<string | null>(null);
  const [error, setError] = useState<ErrorType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [copiedPrivate, setCopiedPrivate] = useState<boolean>(false);
  const [copiedPublic, setCopiedPublic] = useState<boolean>(false);

  const getWebhookInfo = async () => {
    try {
      if (datastoreUrl) {
        await navigator.clipboard.writeText(datastoreUrl);
        setCopiedPrivate(true);
        setTimeout(() => setCopiedPrivate(false), 500);
        return;
      }
      setLoading(true);
      const formattedDataStoreUrl = removeFirstSlash(dataStoreUrl);
      const { data } = await axios.get<HttpDataStore[]>(
        '/api/webhook/HttpDataStoreList',
      );
      const info = data.find(
        ({ name }) => name === last(formattedDataStoreUrl.split('/')),
      );

      setDatastoreUrl(
        info
          ? info.url
          : ` http://rollun-net-prvt/api/datastore/${last(
              formattedDataStoreUrl.split('/'),
            )}`,
      );
      setLoading(false);
    } catch (e) {
      if (!/is not a function/gi.test((e as Error).message)) {
        setError({
          code: 0,
          text: (e as Error).message,
        });
      }
      setLoading(false);
    }
  };

  const handlePublicCopy = async () => {
    await navigator.clipboard.writeText(`https://rollun.net${dataStoreUrl}`);
    setCopiedPublic(true);
    setTimeout(() => setCopiedPublic(false), 500);
  };

  return (
    <div className="table-header">
      <div
        className={`${
          datastoreUrl ? 'datastore-url__active' : 'datastore-url__not-active'
        }`}
      >
        <h3 className="my-auto first-capitalize">
          {camelCaseToWords(appName || 'noName')}
        </h3>
        <Box justifyContent="column">
          <MuiTooltip
            open={copiedPrivate}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            leaveDelay={500}
            title={<h5>Copied!</h5>}
            id="copy"
          >
            <div
              onClick={getWebhookInfo}
              id="datastore-url"
              className={`datastore-url ${
                error ? 'datastore-url__invalid' : ''
              } ${!datastoreUrl ? 'ml-2 h-100' : ''}`}
            >
              {loading ? (
                <Spinner size={20} />
              ) : error ? (
                error.text
              ) : datastoreUrl ? (
                `private: ${datastoreUrl}`
              ) : (
                <FontAwesomeIcon style={{ margin: 'auto' }} icon={faLink} />
              )}
            </div>
          </MuiTooltip>
          {datastoreUrl ? (
            <MuiTooltip
              open={copiedPublic}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              leaveDelay={500}
              title={<h5>Copied!</h5>}
              id="copy-private"
            >
              <div
                onClick={handlePublicCopy}
                id="datastore-url-public"
                className={`datastore-url${
                  error ? 'datastore-url__invalid' : ''
                } ${!datastoreUrl ? 'ml-2' : ''}`}
              >
                public: https://rollun.net{dataStoreUrl}
              </div>
            </MuiTooltip>
          ) : null}
        </Box>
      </div>
    </div>
  );
};

export default TableHeader;
