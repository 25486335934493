import useHttpDataStore from '../useHttpDatastore';

export interface DimensionItem {
  id: string;
  width: string | null;
  height: string | null;
  length: string | null;
  weight: string | null;
  image: string;
  title: string;
}

const useDimensionDatastore = () => {
  return useHttpDataStore<DimensionItem>('/api/datastore/DimensionStore');
};

export { useDimensionDatastore };
