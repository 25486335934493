import React from 'react';
import WebHookActivator, { WebHookConfig } from './WebhookActivator';

export interface WebHookActivatorProps {
  hooks: WebHookConfig[];
  isSlim?: boolean;
}

const WebHookActivatorList: React.FC<WebHookActivatorProps> = ({
  isSlim,
  hooks,
}) => {
  return (
    <div
      className={
        'd-flex flex-row flex-wrap my-2 ' +
        (isSlim ? '' : 'justify-content-center')
      }
    >
      {hooks.map((config: WebHookConfig) => (
        <WebHookActivator isSlim={isSlim} key={config.name} {...config} />
      ))}
    </div>
  );
};

export default WebHookActivatorList;
