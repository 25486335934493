import React from 'react';
import { useBagSelect, useBagsList } from '../hooks';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import MuiButton from '../../../UI/MuiButton';
import {
  getCardBorderColorByStatus,
  getDeliveryTypeString,
  getStatusString,
} from '../utils';
import { ErrorView } from '../../../UI';

const BagsList = () => {
  const { result, error, status } = useBagsList();
  const theme = useTheme();
  const handleBagSelect = useBagSelect();

  return (
    <Grid container spacing={2}>
      {status === 'loading' ? (
        <Grid item xs={12}>
          <Skeleton height={70} />
          <Skeleton height={70} />
          <Skeleton height={70} />
          <Skeleton height={70} />
        </Grid>
      ) : error ? (
        <ErrorView error={error} />
      ) : result?.length === 0 ? (
        <Box width="100%" marginTop={1}>
          <Typography variant="h4" align="center">
            No return bags
          </Typography>
        </Box>
      ) : (
        result?.map(
          ({
            bag_number,
            shipment_type,
            process_status,
            supplier,
            updated_at,
            itemsCount,
          }) => (
            <Grid item xs={12} key={bag_number}>
              <Box
                border={2}
                borderRadius={4}
                borderColor={getCardBorderColorByStatus(process_status, theme)}
              >
                <Card>
                  <CardContent>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="h4">{bag_number}</Typography>
                      <Typography color="textSecondary">
                        {/* little hack for ios, ios does not support date format 'yyyy-mm-dd hh:mm:ss' */}
                        {new Date(updated_at.replace(' ', 'T')).toDateString()}
                      </Typography>
                    </Box>
                    <Typography variant="body1" color="textSecondary">
                      {supplier}
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                      {getDeliveryTypeString(shipment_type)}
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                      {getStatusString(process_status)}
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                      {`Items in bag: ${itemsCount}`}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <MuiButton
                      color="primary"
                      fullWidth
                      onClick={() => handleBagSelect(bag_number)}
                    >
                      Select this bag
                    </MuiButton>
                  </CardActions>
                </Card>
              </Box>
            </Grid>
          ),
        )
      )}
    </Grid>
  );
};

export default BagsList;
