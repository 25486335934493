import React, { FC, useState } from 'react';
import Select from 'rollun-ts-rql/dist/nodes/Select';
import NodeFieldName from '../NodeFieldName/NodeFieldName';
import AggregateFunctionNode from 'rollun-ts-rql/dist/nodes/aggregateNodes/AggregateFunctionNode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../../../../UI';
import { Box } from '@material-ui/core';
import _ from 'lodash';

export interface NodeEditorProps {
  node: Select | undefined;
  fieldNames: string[];
  onSelectNodeChange(fields: (string | AggregateFunctionNode)[]): void;
  onRemove(): void;
  idField?: string;
}

const SelectNodeEditor: FC<NodeEditorProps> = (props) => {
  const { node, onRemove, onSelectNodeChange, idField } = props;
  const [state, setState] = useState({
    awaitingDrop: false,
    validDropTarget: false,
  });

  const borderClassName = state.awaitingDrop
    ? state.validDropTarget
      ? 'border-valid'
      : 'border-invalid'
    : 'border-default';

  const renderEmptyEditor = () => (
    <Box
      id="empty-editor"
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Button className="mb-1" size="lg" color="light">
        Drag to add select node
      </Button>
    </Box>
  );

  const renderNotEmptyEditor = () =>
    node && (
      <>
        <div
          id="not-empty-editor"
          className="d-flex flex-row card-title justify-content-between"
        >
          <div>Selected fields</div>
          <div>
            <Button size="sm" color="danger" onClick={() => onRemove()}>
              <FontAwesomeIcon icon={'times'} />
            </Button>
          </div>
        </div>
        {idField &&
          !node.fields.filter((field) =>
            field instanceof AggregateFunctionNode
              ? getNodeName(field) === idField
              : field === idField,
          ).length && (
            <div>
              <Button
                className="mt-2"
                size="sm"
                color="primary"
                onClick={() => {
                  onSelectNodeChange([idField, ...node.fields]);
                }}
              >
                Add id field
              </Button>
            </div>
          )}
        <div className={`d-flex card-text active-nodes-container`}>
          <div className={`d-flex flex-column w-100 active-select-nodes`}>
            {node.fields.map((fieldName) => (
              <NodeFieldName
                fieldName={fieldName}
                nodeType={'selectnode'}
                key={`${getNodeName(fieldName)}`}
              />
            ))}
          </div>
        </div>
      </>
    );

  const disableDropTarget = () => {
    setState({ awaitingDrop: false, validDropTarget: false });
  };

  const checkDropPosibility = () => state.awaitingDrop && state.validDropTarget;

  const getNodeName = (node: string | AggregateFunctionNode) =>
    node instanceof AggregateFunctionNode
      ? `${node.function}(${node.field})`
      : node;

  const setDropPossibilityState = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const dataTransfer = event.dataTransfer;

    if (!dataTransfer) {
      throw new Error('Datatransfer is null or undefined');
    }

    const hasFieldName = dataTransfer.types.indexOf('nodefieldname') !== -1;
    const isSortNode = dataTransfer.types.indexOf('sortnode') !== -1;

    if (hasFieldName && !isSortNode) {
      setState({ awaitingDrop: true, validDropTarget: true });
      return;
    }

    setState({ awaitingDrop: true, validDropTarget: false });
  };

  const addFieldToNode = (fieldName: string, isSelectType: boolean) => {
    if (!node) {
      onSelectNodeChange([fieldName]);
      return;
    }

    const { fields } = node;
    const nodeInFields = fields.find((field) =>
      field instanceof AggregateFunctionNode
        ? getNodeName(field) === fieldName
        : field === fieldName,
    );

    // if node is already present, remove it and add it to the end
    if (nodeInFields) {
      const newFields = fields.filter((field) => field !== nodeInFields);

      newFields.push(nodeInFields);
      onSelectNodeChange(newFields);
      return;
    }

    if (isSelectType) {
      return;
    }

    onSelectNodeChange([...fields, fieldName]);
  };

  const addDroppedNodeToSelectedNodes = (
    event: React.DragEvent<HTMLDivElement>,
  ) => {
    const dataTransfer = event.dataTransfer;

    if (!checkDropPosibility()) {
      disableDropTarget();
      return;
    }

    if (!dataTransfer) {
      throw new Error('Datatransfer is null or undefined');
    }

    const isSelectType = dataTransfer.types.indexOf('selectnode') !== -1;

    addFieldToNode(dataTransfer.getData('nodefieldname'), isSelectType);
    disableDropTarget();
  };

  return (
    <div
      className={`d-flex flex-column card mx-1 h-100 border-transparent ${borderClassName}`}
    >
      <div
        id="select-node"
        className={`card-body p-3 ${
          !node || _.isEmpty(node.fields)
            ? 'd-flex justify-content-center flex-column'
            : ''
        }`}
        onDragOver={setDropPossibilityState}
        onDragLeave={disableDropTarget}
        onDrop={addDroppedNodeToSelectedNodes}
      >
        {node && !_.isEmpty(node.fields)
          ? renderNotEmptyEditor()
          : renderEmptyEditor()}
      </div>
    </div>
  );
};

export default React.memo(SelectNodeEditor);
