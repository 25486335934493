import React, { Component } from 'react';
import '../styles/index.css';
import Orders from './Orders';
import Labels from './Labels';
import ScanExcess from './ScanExcess';
import { Button, Card } from '../../../UI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Senders } from '../../../utils/common.types';

enum BarcodeAppAction {
  ORDERS,
  LABELS,
  EXCESS_GOODS,
}

interface IProps {
  displayName: string;
  senderName: Senders;
  onSupplierReset: () => void;
}

interface IState {
  action: BarcodeAppAction;
  orders: Array<{ id: string; supplier: string }>;
  somePartiallyPacked: boolean;
}

class BarcodeApp extends Component<IProps, IState> {
  state = {
    action: BarcodeAppAction.ORDERS,
    orders: [],
    somePartiallyPacked: false,
  };

  setAction = (action: BarcodeAppAction) => {
    this.setState({ action });
  };

  onOrdersSelectComplete = (
    orders: Array<{ id: string; supplier: string }>,
    somePartiallyPacked: boolean,
  ) => {
    this.setState({
      orders,
      action: BarcodeAppAction.LABELS,
      somePartiallyPacked,
    });
  };

  renderSwitch(action: BarcodeAppAction) {
    const { orders, somePartiallyPacked } = this.state;
    const { senderName } = this.props;
    switch (action) {
      case BarcodeAppAction.ORDERS:
        return (
          <div>
            <Orders
              senderName={senderName}
              onOrdersSelectComplete={this.onOrdersSelectComplete}
            />
          </div>
        );
      case BarcodeAppAction.LABELS:
        return (
          <Labels
            orders={orders}
            senderName={senderName}
            somePartiallyPacked={somePartiallyPacked}
            onGoBack={() => this.setAction(BarcodeAppAction.ORDERS)}
            onLabelsProcessingComplete={() =>
              // earlier, after pressing button 'No more labels'
              // you were going to EXCESS_GOODS page,
              // but it was requested to be turned off for now (18.01.2022) https://trello.com/c/6f3M8cG0
              // this.setAction(BarcodeAppAction.EXCESS_GOODS)
              this.setAction(BarcodeAppAction.ORDERS)
            }
          />
        );
      case BarcodeAppAction.EXCESS_GOODS:
        return (
          <ScanExcess
            onGoodsProcessingComplete={() =>
              this.setAction(BarcodeAppAction.ORDERS)
            }
            senderName={senderName}
            orders={orders}
          />
        );
    }
  }

  render() {
    return (
      <div>
        <div className="d-flex justify-content-between">
          <h1>{this.props.displayName}</h1>
          <Button
            color="primary"
            size="sm"
            className="my-2"
            onClick={this.props.onSupplierReset}
          >
            <FontAwesomeIcon icon="arrow-left" />
          </Button>
        </div>
        <Card className="py-5">{this.renderSwitch(this.state.action)}</Card>
      </div>
    );
  }
}

export default BarcodeApp;
