import React, { useContext, useState } from 'react';
import { BagFillingContext } from '../BagFillingContext';
import { useBagInfo, useReactiveChangeOfFound } from '../hooks';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ErrorView } from '../../../UI';
import ItemCard from './ItemCard';
import MuiButton from '../../../UI/MuiButton';
import SetDimensions from './SetDimensions';

const BagPreview = () => {
  const context = useContext(BagFillingContext);
  const { status, error, result } = useBagInfo(context.bagId || '');
  const [ridsWithFound, setRidsWithFound] = useReactiveChangeOfFound(result);
  const [isDone, setIsDone] = useState(false);

  return (
    <Grid container spacing={2}>
      {isDone ? (
        <>
          <Grid item xs={12}>
            <MuiButton
              fullWidth
              color="primary"
              disabled={status === 'loading'}
              onClick={() => setIsDone(false)}
            >
              Back to processing bag
            </MuiButton>
          </Grid>
          <SetDimensions bagId={context.bagId} />
        </>
      ) : status === 'loading' ? (
        <Grid item xs={12}>
          <Skeleton height={70} />
          <Skeleton height={70} />
          <Skeleton height={70} />
          <Skeleton height={70} />
        </Grid>
      ) : error ? (
        <ErrorView error={error} />
      ) : (
        <>
          <Grid item xs={12}>
            <MuiButton
              fullWidth
              color="primary"
              disabled={ridsWithFound?.some(
                ({ found, problem }) => found === 'no' && !problem,
              )}
              onClick={() => setIsDone(true)}
            >
              Done
            </MuiButton>
          </Grid>
          {ridsWithFound.map((item, index) => (
            <Grid item xs={12} key={index}>
              <ItemCard item={item} setRidsWithFound={setRidsWithFound} />
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );
};

export default BagPreview;
