import { noop } from '../../../utils/common.utils';
import {
  fetchCategories,
  fetchCommonItemsData,
  fetchCompatibles,
  fetchImages,
  FileExchangeDataByRollunId,
  formatTitle,
} from './getFileExchangeByRollunId';
import _ from 'lodash';
import { CompatibleVehicle } from '../../../utils/common.types';
import { isJSON } from 'rollun-ts-utils';
import HttpDatastore from 'rollun-ts-datastore';
import { Query, In, Select } from 'rollun-ts-rql';

const TRCatalog = new HttpDatastore(
  '/api/datastore/TuckerInventoryCacheDataStore',
);
export const fetchItemsData = async (rollunIds: Array<string>) => {
  if (!rollunIds.length) return [];
  return TRCatalog.query(
    new Query()
      .setQuery(new In('rollun_id', rollunIds))
      .setSelect(
        new Select([
          'rollun_id',
          'brand',
          'item_description',
          'vendor_part',
          'primary_color',
          'tx_qty',
        ]),
      ),
  );
};

export const fetchTuckerRockyData = async (
  rollunIds: Array<string>,
  progressCB: (p: string) => void = noop,
): Promise<FileExchangeDataByRollunId> => {
  progressCB('Starting fetching data for RockyMountain...');

  const chunks = _.chunk(rollunIds, 100);

  let commonItemsData: Array<any> = [];
  let itemsData: Array<any> = [];
  let images: Array<{ rollunId: string; image: string | null }> = [];

  let categoriesData: Array<{
    rollun_id: string;
    category_id: string;
    category_name: string;
  }> = [];
  let compatibles: Array<CompatibleVehicle & { rollunId: string }> = [];

  for (let i = 0, len = chunks.length; i < len; i++) {
    const chunk = chunks[i];
    progressCB &&
      progressCB(
        `Fetching ${i * 100 + chunk.length} of ${rollunIds.length} items`,
      );
    const [
      currentCommonItemsData,
      currentItemData,
      currentImages,
    ] = await Promise.all([
      fetchCommonItemsData(chunk),
      fetchItemsData(chunk),
      fetchImages(chunk),
    ]);

    const [currentCategoriesData, currentCompatibles] = await Promise.all([
      fetchCategories(
        currentItemData.map(({ rollun_id, item_description }) => ({
          rollun_id,
          name: item_description,
        })),
      ),
      fetchCompatibles(
        currentCommonItemsData
          .map(({ compatibles, id: rollunId }) => {
            return (compatibles !== '-1' && isJSON(compatibles)
              ? (Object.values(JSON.parse(compatibles)) as Array<string>)
              : []
            ).map((compatibleId) => ({ rollunId, compatibleId }));
          })
          .flat(),
      ),
    ]);

    images = images.concat(currentImages);
    compatibles = compatibles.concat(currentCompatibles);
    commonItemsData = commonItemsData.concat(currentCommonItemsData);
    itemsData = itemsData.concat(currentItemData);
    categoriesData = categoriesData.concat(currentCategoriesData);
  }
  return rollunIds.reduce((acc: FileExchangeDataByRollunId, rollunId) => {
    const {
      brand = '',
      item_description = '',
      vendor_part = '',
      primary_color = '',
      tx_qty = 0,
    } = itemsData.find(({ rollun_id }) => rollun_id === rollunId) || {};

    const { ct_rollun_price = 0, ct_plaisir_price = 0 } =
      commonItemsData.find(({ id }) => id === rollunId) || {};

    const { category_id = '' } =
      categoriesData.find(({ rollun_id }) => rollun_id === rollunId) || {};

    const { image = null } =
      images.find((el) => el.rollunId === rollunId) || {};

    acc[rollunId] = {
      ct_plaisir_price,
      ct_rollun_price,
      name: formatTitle([brand, item_description, vendor_part]),
      size: '',
      upc: '',
      brand,
      description: `${brand} ${item_description} ${vendor_part}`,
      mpn: vendor_part,
      color: primary_color,
      photo: (image == '-1' ? '' : image) || '',
      qty: tx_qty,
      categoryId: category_id,
      vehicles: compatibles.filter(({ rollunId: rid }) => rid === rollunId),
    };
    return acc;
  }, {});
};
