import { Typography, withStyles } from '@material-ui/core';
import themeProvider from '../themeProvider';

const WhiteTextTypography = withStyles({
  root: {
    color: themeProvider.palette.success.main,
  },
})(Typography);

export default WhiteTextTypography;
