import React, { FC } from 'react';
import MuiButton, { MuiColors } from '../../../UI/MuiButton';
import GoogleIcon from './GoogleIcon';

interface LoginButtonProps {
  href: string;
  name: string;
  color: MuiColors;
}

const LoginButton: FC<LoginButtonProps> = ({ href, color, name }) => {
  return (
    <MuiButton href={href} color={color} fullWidth startIcon={<GoogleIcon />}>
      {name}
    </MuiButton>
  );
};

export default LoginButton;
