import React, { ReactNode } from 'react';

const NoData = ({ children }: { children?: ReactNode[] | ReactNode }) => {
  return (
    <div className="error-msg d-flex justify-content-center align-items-center flex-column">
      <div className="m-5">{children}</div>
    </div>
  );
};

export default NoData;
