import HttpDatastore from 'rollun-ts-datastore';
import { Employees, EmployeesDTO } from './types';

const datastore = new HttpDatastore('/api/datastore/EmployeesDataStore');

export class ApiClientsEmployees {
  async getById(id: string): Promise<Employees> {
    const employeesDTO = await datastore.read(id);

    if (!employeesDTO) {
      throw new Error(`Employees with id ${id} not found`);
    }

    const employees = this.convertDTOtoModel(employeesDTO);
    return employees;
  }

  async update(id: string, data: Partial<Employees>) {
    await datastore.update({
      ...this.convertModelToDTO({ ...data, id }),
    });
  }

  async create(data: Omit<Employees, 'id'>): Promise<Employees> {
    const pickupDTO = this.convertModelToDTO(data);
    const pickup = await datastore.create(pickupDTO);

    return this.getById(pickup.Id);
  }

  convertDTOtoModel(employeesDTO: EmployeesDTO): Employees {
    return {
      id: employeesDTO.Id,
      typeOfEmployment: employeesDTO.TypeOfEmployment,
      team: employeesDTO.Team,
      name: employeesDTO.Name,
      position: employeesDTO.Position,
      fileWithPasswords: employeesDTO.FileWithPasswords,
      fileSalary: employeesDTO.FileSalary,
      passportDrLicense: employeesDTO.PassportDrLicense,
      identificationNumber: employeesDTO.IdentificationNumber,
      W8W9Form: employeesDTO.W8W9Form,
      W8W9Form2021: employeesDTO.W8W9Form2021,
      W8W9Form2022: employeesDTO.W8W9Form2022,
      W8W9Form2023: employeesDTO.W8W9Form2023,
      kontaktiBlizkih: employeesDTO.KontaktiBlizkih,
      mpUaId: employeesDTO.MpUaId,
      date1stDayAtWork: employeesDTO.Date1stDayAtWork,
      slackName: employeesDTO.SlackName,
      email: employeesDTO.Email,
      status: employeesDTO.Status,
      statusName: employeesDTO.StatusName,
      timeCreated: employeesDTO.TimeCreated,
      timeUpdated: employeesDTO.TimeUpdated,
    } as Employees;
  }

  convertModelToDTO(employees: Partial<Employees>): EmployeesDTO {
    return {
      Id: employees.id,
      TypeOfEmployment: employees.typeOfEmployment,
      Team: employees.team,
      Name: employees.name,
      Position: employees.position,
      FileWithPasswords: employees.fileWithPasswords,
      FileSalary: employees.fileSalary,
      PassportDrLicense: employees.passportDrLicense,
      IdentificationNumber: employees.identificationNumber,
      W8W9Form: employees.W8W9Form,
      W8W9Form2021: employees.W8W9Form2021,
      W8W9Form2022: employees.W8W9Form2022,
      W8W9Form2023: employees.W8W9Form2023,
      KontaktiBlizkih: employees.kontaktiBlizkih,
      MpUaId: employees.mpUaId,
      Date1stDayAtWork: employees.date1stDayAtWork,
      SlackName: employees.slackName,
      Email: employees.email,
      Status: employees.status,
      StatusName: employees.statusName,
      TimeCreated: employees.timeCreated,
      TimeUpdated: employees.timeUpdated,
    } as EmployeesDTO;
  }
}

export const apiClientsEmployees = new ApiClientsEmployees();
