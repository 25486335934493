import {
  Pickup,
  Position,
} from '../../../../CRMDeals/components/api-clients/types';
import { Dimension } from './fetchDimensions';
import { fitItemInProperSize } from './formatItemSize';

const dataToCSV = (flatData: Record<string, unknown>[]) => {
  if (flatData.length === 0) return '';
  const data = flatData.map((dataRecord) => Object.values(dataRecord));
  const dataArray = data.map((arr) => {
    return arr.map((ar: any) => {
      if (ar) {
        if (ar.length > 1 && ar[0] === '"' && ar[ar.length - 1] === '"') {
          return ar.slice(1, -1).replace(/"/g, '""');
        } else if (typeof ar === 'string') {
          return ar.replace(/"/g, '""');
        } else {
          return ar.toString();
        }
      } else {
        return null;
      }
    });
  });
  let dataString = dataArray.map((row) => row.join('","')).join('"\n"');
  dataString = '"' + dataString + '"\n';
  const columnHeadersString = Object.keys(flatData[0]).join(',');
  return `${columnHeadersString}\n${dataString}`;
};

const downloadCSV = (
  string: string | Record<string, any>[],
  name = 'download.csv',
) => {
  if (typeof string !== 'string') {
    string = dataToCSV(string);
  }
  downloadFile(string, 'text/csv', name);
};

const downloadFile = (string: string, contentType: string, name: string) => {
  const blob = new Blob([string], { type: contentType });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = name || 'file';
  link.target = '_blank';
  link.click();
  URL.revokeObjectURL(link.href);
};

export function downloadClickNShipInfoFiles(downloadInfo: Record<string, any>) {
  for (const key in downloadInfo) {
    const downloadCsv = downloadInfo[key];

    if (downloadCsv.length > 0) {
      downloadCSV(downloadCsv, `Click&Ship_${key}.csv`);
    }
  }
}

export function connectObjectsByRid(objects: Position[]): Position[] {
  const connectedObjects: any = {};

  for (const obj of objects) {
    const article = obj.article;

    if (article in connectedObjects) {
      connectedObjects[article].quantity += obj.quantity;
    } else {
      connectedObjects[article] = { ...obj };
    }
  }

  return Object.values(connectedObjects);
}

export function calculateDimensionsFromDeal(
  deal: Pickup,
  dimensions: Dimension[],
) {
  const positions = connectObjectsByRid(deal.no_special_items_positions);

  const items = positions.map((position) => {
    const dimension = dimensions.find(({ rid }) => rid === position.article);
    return { ...dimension, quantity: position.quantity };
  });

  return calculateDimensions(items);
}

export function calculateDimensions(objects: any[]) {
  let weight = 0;
  let height = 0;
  let maxWidth = 0;
  let maxLength = 0;

  for (const obj of objects) {
    const quantity = obj.quantity || 1;

    weight += parseFloat(obj.weight) * quantity;
    height += parseFloat(obj.height) * quantity;

    const width = parseFloat(obj.width);
    const length = parseFloat(obj.length);

    if (width > maxWidth) {
      maxWidth = width;
    }

    if (length > maxLength) {
      maxLength = length;
    }
  }

  return {
    weight: String(weight.toFixed(3)),
    height: String(height.toFixed(3)),
    width: String(maxWidth.toFixed(3)),
    length: String(maxLength.toFixed(3)),
    rid: '',
  };
}

export function isDifferentDimensions(
  calculatedObject: Dimension,
  dealDimensions: Dimension,
) {
  if (
    calculatedObject.weight === dealDimensions.weight &&
    calculatedObject.height === dealDimensions.height &&
    calculatedObject.length === dealDimensions.length &&
    calculatedObject.width === dealDimensions.width
  ) {
    return false;
  }
  return true;
}

export function parseDealDimensions(deal: Pickup) {
  if (!deal.dimensions) return;
  const [length, width, height] = deal.dimensions.split(',');
  return {
    weight: String((+deal.weight).toFixed(3)),
    length: String((+length).toFixed(3)),
    width: String((+width).toFixed(3)),
    height: String((+height).toFixed(3)),
    rid: '',
  };
}

export function getDimensions(
  deal: Pickup,
  dimensions: Dimension[],
  dealType: 'Pickup' | 'Dropship',
) {
  let properItem, rightSize;
  const comparedItems = calculateDimensionsFromDeal(deal, dimensions);
  console.log('deal', deal);
  console.log('dimensions', dimensions);

  const {
    rightSize: rightSizeCalculated,
    ...properItemCalculated
  } = fitItemInProperSize(comparedItems);

  if (dealType === 'Dropship') {
    properItem = properItemCalculated;
    rightSize = rightSizeCalculated;
  } else if (dealType === 'Pickup') {
    const parsedDealDimensions = parseDealDimensions(deal);
    if (!parsedDealDimensions)
      throw new Error("Pickup deal is old and doensn't have dimensions");

    const {
      rightSize: parsedRightSize,
      ...properItemParsed
    } = fitItemInProperSize(parsedDealDimensions);

    rightSize = parsedRightSize;
    properItem = properItemParsed;

    // alert if differentDimensions
    if (isDifferentDimensions(properItem, properItemParsed))
      alert("Check dimensions. Values from deal and calculated aren't same");
  }
  return { rightSize, properItem };
}
