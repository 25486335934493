import HttpDatastore from 'rollun-ts-datastore';
import { snakeToPascalCase } from './snakeToPascalCase';
import {
  CreateLinkedDealsSupplierConfig,
  SUPPLIER_CONFIG,
} from '../../../../../constants/suppleir-config';
import { Eq, Query, Select } from 'rollun-ts-rql';
import {
  getAbbreviationBySupplierName,
  getShippingOptionsBySupplierName,
} from './supplierUtils';
import { randomString } from 'rollun-ts-utils';
import { HowToBuyOptionsResult } from './getHowToBuyOptions';
import { Position } from '../../../../../api-clients/types';
import { isNumber, uniqBy } from 'lodash';
import { addInventoryInfo } from './addInventoryInfo';
import { logger } from '../../../../../../../../utils/logger';

const warehouseBySpeedDatastore = new HttpDatastore(
  '/api/datastore/SuppliersWarehousesBySpeed',
);

const getIsLocationAllowed = (location: string) => {
  const [pickup, state] = location.split('_');

  const loc = `${pickup}_${state}`;
  const allowedLocations = [
    'rm_ds',
    'wps_ds',
    'pu_ds',
    'au_ds',
    'pickup_cn',
    'pickup_ky',
    'pickup_ut',
    'tft_ds',
  ];

  return allowedLocations.includes(loc);
};

export async function getSendersBySpeed() {
  const data = await warehouseBySpeedDatastore.query();
  // input: ['PU_NC', 'PU_NV']
  // output: ['nc', 'nv']
  const transformSenders = (senders: string[]) => {
    console.log('senders getSenderBySpeed::', senders);
    // need to take second element, cases ROF_KY_pUp -> ky, RM_KIT -> kit
    return senders.map((sender) => ({
      alias: sender.split('_').at(1)?.toLowerCase(),
      sender,
    }));
  };
  // input 'TuckerRocky', null
  // output TuckerRocky
  // input 'TuckerRocky', 'pickup_tx'
  // output TuckerRockyPickupTx
  const createSupplierName = (supplierName: string, senderName: string) => {
    if (!senderName) {
      return supplierName;
    }

    return `${supplierName}${snakeToPascalCase(senderName)}`;
  };
  const formatSender = ({
    senders,
    pickup,
    handling_time,
    sr_name,
    sender_name,
  }: any) => ({
    isPickup: pickup === '1',
    handlingTime: handling_time,
    supplierName: createSupplierName(sr_name, sender_name),
    senders: transformSenders(JSON.parse(senders)),
  });

  console.log('data getSendersBySpeed', data);
  return data.map(formatSender);
}

const getItemQuantityBySupplierInfo = async (
  rid: string,
  {
    url,
    quantityFields,
    supplier,
    ridField,
    priceField,
  }: CreateLinkedDealsSupplierConfig & { supplier: string },
) => {
  const datastore = new HttpDatastore(url);
  const query = new Query()
    .setQuery(new Eq(ridField, rid))
    .setSelect(new Select([priceField, ridField, ...quantityFields]));

  const data = await datastore.query(query);

  const transformData = (item: any) => {
    const rid = item[ridField];
    const price = item[priceField];

    delete item[ridField];
    delete item[priceField];

    return {
      rid,
      price: +price,
      ...item,
    };
  };

  return [supplier, data.map(transformData)];
};

const getItemQuantityBySupplierInfosInner = async (article: string) => {
  const spToFlMap = Object.entries(SUPPLIER_CONFIG).map(
    ([supplier, config]) => {
      return { fields: config.quantityFields, supplier };
    },
  );

  const supplierConfig = Object.entries(SUPPLIER_CONFIG).map(
    ([supplier, config]) => {
      return { article, config, supplier };
    },
  );

  const combinedSuppliers: Record<string, any[]> = {
    Wps: [],
    Autodist: [],
    PartsUnlimited: [],
    RockyMountain: [],
    RollunOffice: [],
    Turn14: [],
  };

  const addToResult = (entry: any) => {
    const supplierKey = Object.keys(combinedSuppliers).find((key) =>
      entry.supplier.startsWith(key),
    );
    if (supplierKey) {
      combinedSuppliers[supplierKey] = combinedSuppliers[supplierKey] || [];
      combinedSuppliers[supplierKey].push(entry.config);
    }
  };

  supplierConfig.forEach(addToResult);

  const quantityFieldsResult: any = {};

  supplierConfig.forEach((entry) => {
    const supplierKey = Object.keys(combinedSuppliers).find((key) =>
      entry.supplier.startsWith(key),
    );
    if (supplierKey) {
      quantityFieldsResult[supplierKey] =
        quantityFieldsResult[supplierKey] || new Set();
      const quantityFields = entry.config.quantityFields || [];
      quantityFields.forEach((field) =>
        quantityFieldsResult[supplierKey].add(field),
      );
    }
  });

  Object.keys(quantityFieldsResult).forEach((key) => {
    quantityFieldsResult[key] = Array.from(quantityFieldsResult[key]);
  });

  const infosObj = Object.fromEntries(
    await Promise.all(
      Object.entries(quantityFieldsResult).map(([supplier, quantityFields]) =>
        getItemQuantityBySupplierInfo(article, {
          ...combinedSuppliers[supplier][0],
          quantityFields,
          supplier,
        }),
      ),
    ),
  );

  const result: Record<string, any> = {};
  const spToFlMapObj: Record<string, string[]> = {};
  spToFlMap.map(({ supplier, fields }) => {
    spToFlMapObj[supplier] = fields;
  });

  spToFlMap.map((mapping) => {
    const mpSupplier = mapping.supplier;

    const setFinalResultBySupplier = (supplier: string) => {
      const [data] = infosObj[supplier];
      const spData = spToFlMapObj[mpSupplier];

      if (!data) {
        return;
      }

      const fieldsData: Record<string, any> = {
        price: data.price,
        rid: data.rid,
      };

      for (let i = 0; i < spData.length; i++) {
        fieldsData[spData[i]] = data[spData[i]];
      }
      result[mpSupplier] = [fieldsData];
    };

    Object.entries(combinedSuppliers).map(([supplier]) => {
      if (mpSupplier.startsWith(supplier)) {
        setFinalResultBySupplier(supplier);
      }
    });
  });

  return result;
};

const getItemQuantityBySupplierInfos = async (article: string) => {
  const infos = Object.entries(
    await getItemQuantityBySupplierInfosInner(article),
  );

  console.log('infos', infos);

  // filter supplier without item info
  const itemInfosFiltered = infos.filter(([, infos]) => infos.length > 0);
  return Object.fromEntries(itemInfosFiltered);
};

const convertWarehouseToSenderQuantity = (itemsInfo: any) => {
  const result: Record<string, any> = {};
  const getSendersQuantityByWarehouses = (supplier: any, info: any) => {
    const { rid, price, ...quantityFields } = info;
    const mapWarehouseToSendersQuantity = (warehouseWithQuantity: any) => {
      const result = {};

      for (const warehouse in warehouseWithQuantity) {
        const quantity = warehouseWithQuantity[warehouse];
        console.log(supplier, warehouse);
        const sender = (SUPPLIER_CONFIG[supplier].quantityFieldsMapper([
          warehouse,
        ]) as unknown) as string;

        console.log(sender);

        // if sender repeats just add, because we need to add up warehouse quantity that
        // maps to one sender
        // @ts-expect-error
        result[sender] =
          // @ts-expect-error
          sender in result ? result[sender as string] + +quantity : +quantity;
      }

      return result;
    };

    return { price, ...mapWarehouseToSendersQuantity(quantityFields) };
  };

  const removeZeroQuantitySenders = (itemsInfo: any) => {
    const result: Record<string, any> = {};

    for (const itemId in itemsInfo) {
      result[itemId] = {};
      for (const senderId in itemsInfo[itemId]) {
        const { rid, price, ...quantities } = itemsInfo[itemId][senderId][0];

        console.log('quantities', senderId, quantities);
        const totalQuantity = SUPPLIER_CONFIG[senderId].calculateQuantity(
          quantities,
        );

        console.log('totalQuantity', senderId, totalQuantity);
        if (totalQuantity > 0) {
          result[itemId][senderId] = [...itemsInfo[itemId][senderId]];
        }
      }
    }

    return result;
  };

  const syncSendersBetweenItems = (itemsInfo: any) => {
    const result: Record<string, any> = {};
    const uniqueSenders = [
      ...new Set(
        Object.keys(itemsInfo).reduce(
          // @ts-expect-error
          (acc, curr) => [acc, Object.keys(itemsInfo[curr])].flat(),
          [],
        ),
      ),
    ];

    for (const itemId in itemsInfo) {
      const [rid] = itemId.split('_');
      const sendersByItemId = Object.keys(itemsInfo[itemId]);
      result[itemId] = {};

      for (const uniqueSender of uniqueSenders) {
        const isPresentInItemInfo = sendersByItemId.includes(uniqueSender);

        result[itemId][uniqueSender] = isPresentInItemInfo
          ? [...itemsInfo[itemId][uniqueSender]]
          : [
              {
                price: 0,
                qty: 0,
                rid,
                isPlaceholder: true,
              },
            ];
      }
    }
    return result;
  };

  const nonZeroQuantityItemsInfo = removeZeroQuantitySenders(itemsInfo);

  console.log('nonZeroQuantityItemsInfo', nonZeroQuantityItemsInfo);
  const syncedItemsInfo = syncSendersBetweenItems(nonZeroQuantityItemsInfo);

  console.log('syncedItemsInfo', syncedItemsInfo);

  for (const [rid, itemInfo] of Object.entries(syncedItemsInfo)) {
    result[rid] = {};

    console.log('itemInfo', itemInfo);
    for (const [supplier, infos] of Object.entries(itemInfo)) {
      // need to take first item only
      result[rid][supplier] = getSendersQuantityByWarehouses(
        supplier,
        (infos as any[]).at(0),
      );

      console.log('result', result[rid][supplier]);
    }
  }

  return result;
};

const convertSenderToSupplierQuantity = (
  senderItemInfo: any,
  senders: any[],
) => {
  const result: Record<string, any> = {};
  const createSupplierName = ({
    supplierName,
    isPickup,
    handlingTime,
  }: any) => {
    if (isPickup) {
      const matchSupplierPickupStateRofRegex = /(?<supplierName>.*)(?<pickup>pickup)(?<state>tx|ky|ut|nc|wi|ny|cn)(?<rofPart>rof)?/gi;
      const {
        // @ts-expect-error
        groups: { pickup, state, rofPart },
      } = matchSupplierPickupStateRofRegex.exec(supplierName);

      // this piece fof code transforms this: [Pickup, Nc, Rof] or [Pickup, Nc, undefined]
      // to snake case variant like this: pickup_nc_rof or pickup_nc
      return [pickup, state, rofPart]
        .filter((part) => !!part)
        .map((part) => part.toLowerCase())
        .join('_');
    }

    return `${getAbbreviationBySupplierName(
      supplierName,
    ).toLowerCase()}_ds_${handlingTime.toLowerCase()}`;
  };

  const makeCreateLinkedDealInfo = ({
    itemId,
    totalQuantity,
    price,
    supplierName,
    fullSupplierName,
  }: any) => {
    const [rid, mpItemId, qty, uniqItemId] = itemId.split('_');

    return {
      rollunId: rid,
      mpItemId,
      qty,
      uniqItemId,
      warehouseQty: totalQuantity,
      price,
      isAvailable: totalQuantity > 0,
      shippingMethods: getShippingOptionsBySupplierName(
        supplierName,
        fullSupplierName,
      ),
      // for compatibility with HowToBuy4 processing
      shippingOptions: [],
    };
  };

  for (const [itemId, sendersInfo] of Object.entries(senderItemInfo)) {
    for (const [senderId, quantityWithPrice] of Object.entries(
      sendersInfo as any,
    )) {
      const filteredSenders = senders.filter(
        ({ supplierName }) => senderId === supplierName,
      );
      for (const sender of filteredSenders) {
        const supplierConfigInfo = SUPPLIER_CONFIG[senderId];
        if (!sender || !supplierConfigInfo) {
          throw new Error(`Unknown supplier id - [${senderId}]`);
        }
        const fullSupplierName = createSupplierName(sender);

        const {
          price,
          isPlaceholder,
          ...quantities
        } = quantityWithPrice as any;

        let totalQuantity = supplierConfigInfo.calculateQuantity(quantities);
        if (sender.senders.length !== Object.entries(quantities).length) {
          const quantity = sender.senders.reduce((acc: number, cur: any) => {
            return acc + quantities[cur.alias];
          }, 0);
          if (isNumber(quantity) && !isNaN(quantity)) {
            totalQuantity = quantity;
          }
        }

        if (!isPlaceholder && totalQuantity === 0) {
          continue;
        }

        const isLocationAllowed = getIsLocationAllowed(fullSupplierName);
        if (!isLocationAllowed) {
          continue;
        }

        const createLinkedDealInfo = makeCreateLinkedDealInfo({
          itemId,
          totalQuantity,
          price,
          fullSupplierName,
          supplierName: sender.supplierName,
        });

        result[fullSupplierName] = [
          ...(fullSupplierName in result ? result[fullSupplierName] : []),
          createLinkedDealInfo,
        ];
      }
    }
  }

  return result;
};

export const fetchNewLinkedDealsData = async (
  items: Position[],
): Promise<HowToBuyOptionsResult> => {
  const uniqueItems = uniqBy(items, ({ article }) => article);
  console.log('items', items);

  const senders = await getSendersBySpeed();
  const itemsInfo: Record<string, any> = {};
  const createIdForItemInfo = ({ article, id, uniqItemOrderId }: any) => {
    // quantity is always 1 for linked deals
    return `${article}_${id}_1_${uniqItemOrderId}`;
  };

  console.log(uniqueItems);

  for (const { article, quantity } of uniqueItems) {
    const idForItemInfo = createIdForItemInfo({
      article,
      id: randomString(30),
      quantity,
      uniqItemOrderId: randomString(30),
    });

    console.log('idForItemInfo', { idForItemInfo, uniqueItems });
    itemsInfo[idForItemInfo] = await getItemQuantityBySupplierInfos(
      article as string,
    );
  }

  console.log('itemsInfo', itemsInfo);

  const itemInfoWithSenders = convertWarehouseToSenderQuantity(itemsInfo);

  console.log('itemInfoWithSenders', itemInfoWithSenders);

  const convertedHowToBuyOptions = convertSenderToSupplierQuantity(
    itemInfoWithSenders,
    senders,
  );

  try {
    console.log('convertedHowToBuyOptions', convertedHowToBuyOptions);

    const howToBuyOptions = await addInventoryInfo(convertedHowToBuyOptions);
    return {
      howToBuyOptions,
    };
  } catch (err) {
    logger.error('CLDv2:addInventoryInfo', err);

    return {
      howToBuyOptions: convertedHowToBuyOptions,
    };
  }
};
