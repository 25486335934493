import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';

const docsMapper = {
  DownloadSrCatalogPartnumbers:
    'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.c5uiavstzocj',
  RowsHighlighter:
    'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.48o2wzv6nrxm',
  CreateDeal:
    'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.lftgpwvs7dbh',
  Scenarios:
    'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.cd0divji7tca',
  RofInventoryUpdater: 'dont have docs',
  DownloadClickNShipInfo:
    'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.5kwcoq1b0exg',
  HowToReturnV3:
    'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.32wiz2ayj0yh',
  CreateBag:
    'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.zcdvk4vc63x7',
  UploadPartNumbersToDeals:
    'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.vq06u2s57fbt',
  DealsToDSPackaging:
    'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.srsohqr5poul',
  DownloadBagInfo:
    'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.gllm9mgxhtiz',
  DownloadPickUpInfo:
    'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.on8eunhoqzqb',
  DownloadReturnListDeals:
    'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.on8eunhoqzqb',
};

interface TableHeaderButtonDocsProps {
  appConfig: any;
  isOpen: boolean;
  onClose: () => void;
}

export const TableHeaderButtonDocsDialog = ({
  appConfig,
  isOpen,
  onClose,
}: TableHeaderButtonDocsProps) => {
  console.log('appConfig', appConfig);

  console.log(
    'appConfig.appParams.gridParams.tableHeaderButtons.find(({ name }) => {name === TableHeaderDocs})',
    appConfig?.appParams?.gridParams?.tableHeaderButtons,
  );

  const buttons = appConfig?.appParams?.gridParams?.tableHeaderButtons;

  if (!buttons) return null;

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Docs for tableHeaderButtons</DialogTitle>
      <DialogContent>
        {buttons.map(({ name }: { name: string }) => {
          if (!(docsMapper as any)[name]) return;
          return (
            <Typography>
              {name}: <a href={(docsMapper as any)[name]}>Docs link</a>
            </Typography>
          );
        })}
      </DialogContent>
    </Dialog>
  );
};
