import React, { FC } from 'react';
import MultiRowUpdate from '../MultiRowUpdate/MultiRowUpdate';
import { Query } from 'rollun-ts-rql';
import { Dialog as MuiDialog, DialogTitle } from '@material-ui/core';

interface MultiUpdateDialogProps {
  isMultiUpdateModalShown: boolean;
  toggleMultiUpdateModal: (headerName?: string) => void;
  datastoreUrl: string;
  query: Query;
  idField: string;
  fieldNames: string[];
  headerName: string;
}

const MultiUpdateDialog: FC<MultiUpdateDialogProps> = ({
  isMultiUpdateModalShown,
  toggleMultiUpdateModal,
  datastoreUrl,
  query,
  idField,
  fieldNames,
  headerName,
}) => {
  return (
    <MuiDialog
      open={isMultiUpdateModalShown}
      onClose={() => toggleMultiUpdateModal()}
    >
      <DialogTitle>
        Update column in multiple rows in current filter
      </DialogTitle>
      <MultiRowUpdate
        datastoreURL={datastoreUrl}
        currentQuery={query}
        idField={idField || 'id'}
        fieldNames={fieldNames.filter((field) => field !== (idField || 'id'))}
        updateFieldName={headerName}
      />
    </MuiDialog>
  );
};

export default MultiUpdateDialog;
