import React from 'react';
import Card from './Card';
import { MarkdownViewer } from './index';

// Component can be used as description area
// Markdown is supported.

interface IProps {
  source: string;
}

const Description: React.FC<IProps> = ({ source }) => {
  return (
    <Card noMargin className="p-3 my-2">
      <MarkdownViewer source={source} />
    </Card>
  );
};

export default Description;
