import { useQuery } from 'react-query';
import { secretsApi } from '../api-client';

export const GET_SECRETS_KEY = 'GET_SECRETS_KEY';

export const useGetSecrets = () => {
  return useQuery(GET_SECRETS_KEY, async () => {
    const { status, data } = await secretsApi.secretsControllerGetAllSecrets();

    if (status === 301) {
      throw new Error('Something went wrong. Try to re-login');
    }

    return data;
  });
};
