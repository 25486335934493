import HttpDatastore from 'rollun-ts-datastore';
import {
  Position,
  SPECIAL_ITEMS_IDS,
  Client,
  ReturnOrderDTO,
  ReturnOrder,
  ReturnDeal,
} from './types';
import ApiClientsPositions from './api-clients-position';
import { Query, Eq } from 'rollun-ts-rql';
import ApiClients from './api-clients-clients';
import { compareStringNumbers } from '../common/utils/compareStringNumbers';

export const nameFieldWithoutMpOrderId = (item: any) => {
  const itemNameSplitted = item.name.split('  ');
  const itemName =
    itemNameSplitted.length > 2
      ? itemNameSplitted[0] + '  ' + itemNameSplitted[1]
      : item.name;
  return itemName;
};

const datastore = new HttpDatastore<ReturnOrderDTO>(
  '/api/datastore/CrmReturnsDataStore',
);

export class ApiClientReturnOrders {
  apiPositions: ApiClientsPositions;
  apiClients: ApiClients;
  constructor() {
    this.apiPositions = new ApiClientsPositions();
    this.apiClients = new ApiClients();
  }

  async getById(id: string): Promise<ReturnOrder> {
    const returnOrderDto = await datastore.read(id);

    if (!returnOrderDto) {
      throw new Error(`Return order with id ${id} not found`);
    }

    const positions = this.apiPositions.getByDealId(id);
    const client = returnOrderDto.Contractor
      ? this.apiClients.getById(returnOrderDto.Contractor)
      : ((null as unknown) as Client);

    const order = await Promise.all([positions, client]).then((data) => {
      return this.convertDTOtoModel(returnOrderDto, ...data);
    });

    return order;
  }

  async getByMpOrderNumber(mpOrderNumber: string): Promise<ReturnOrder | null> {
    const [returnOrderDto] = await datastore.query(
      new Query().setQuery(new Eq('MpOrderNumber', mpOrderNumber)),
    );

    if (!returnOrderDto) {
      return null;
    }

    const positions = this.apiPositions.getByDealId(returnOrderDto.Id);
    const client = this.apiClients.getById(returnOrderDto.Contractor);

    const order = await Promise.all([positions, client]).then((data) => {
      return this.convertDTOtoModel(returnOrderDto, ...data);
    });

    return order;
  }

  async getByQuery(query: Query): Promise<ReturnOrder[]> {
    const returnOrdersDto = await datastore.query(query);
    return Promise.all(
      returnOrdersDto.map((orderDto) => this.getById(orderDto.Id)),
    );
  }

  async create(data: Omit<ReturnOrder, 'id'>): Promise<ReturnOrder> {
    const pickupDTO = this.convertModelToDTO(data);
    const pickup = await datastore.create(pickupDTO);

    if (data.positions) {
      await this.addItems(pickup.Id, data.positions);
    }

    return this.getById(pickup.Id);
  }

  async createDeal(deal: ReturnDeal): Promise<ReturnOrder> {
    const client = await new ApiClients().getById(deal.order.client.id);
    deal.returnOrder.contractor = deal.order.client.id;

    const orderDTO = await datastore.create(
      this.convertModelToDTO(deal.returnOrder),
    );
    this.addOrUpdateItems(orderDTO.Id, deal.positions);
    const order = this.convertDTOtoModel(orderDTO, [], client);

    return order;
  }

  async createFbaDeal(deal: ReturnDeal): Promise<ReturnOrder> {
    const fbaClientId = '1000000';
    const client = await new ApiClients().getById(fbaClientId);
    deal.returnOrder.contractor = fbaClientId;

    const orderDTO = await datastore.create(
      this.convertModelToDTO(deal.returnOrder),
    );
    this.addOrUpdateItems(orderDTO.Id, deal.positions);
    const order = this.convertDTOtoModel(orderDTO, [], client);

    return order;
  }

  async update(
    id: string,
    data: Partial<Omit<ReturnOrder, 'id' | 'positions'>>,
  ) {
    await datastore.update({
      ...this.convertModelToDTOforUpdate(data),
      Id: id,
    });
  }

  async addOrUpdateItems(
    id: string,
    items: Omit<Position, 'offerId' | 'dealId' | 'id'>[],
  ) {
    const deal = await this.getById(id);
    const positions = deal.positions;

    const tempSortedItems: Position[] = [];

    for (let i = 0; i < positions.length; i++) {
      const position = { ...positions[i] };

      if (
        tempSortedItems.filter(
          (item) =>
            item.article === position.article &&
            compareStringNumbers(item.cost, position.cost) &&
            nameFieldWithoutMpOrderId(item) ===
              nameFieldWithoutMpOrderId(position),
        ).length
      )
        continue;

      tempSortedItems.push(position);

      const sameItems = items.filter((item) => {
        return (
          item.article === position.article &&
          compareStringNumbers(item.cost, position.cost) &&
          nameFieldWithoutMpOrderId(item) ===
            nameFieldWithoutMpOrderId(position)
        );
      });
      if (!sameItems.length) continue;

      const allQuantity = sameItems.reduce(
        (prev, cur) => prev + +cur.quantity,
        0,
      );
      position.quantity = +position.quantity + allQuantity;

      await this.apiPositions.update(position);
    }

    const filteredItems = items.filter((item) => {
      for (let i = 0; i < tempSortedItems.length; i++) {
        const position = { ...tempSortedItems[i] };
        if (
          item.article === position.article &&
          compareStringNumbers(item.cost, position.cost) &&
          nameFieldWithoutMpOrderId(item) ===
            nameFieldWithoutMpOrderId(position)
        )
          return false;
      }
      return true;
    });

    if (filteredItems.length) await this.addItems(id, filteredItems);
  }

  async updateClient(id: string, data: Client) {
    await this.apiClients.update(id, data);
  }

  async updateClientByOrderId(orderId: string, data: Client) {
    const { Contractor: clientId } = await datastore.read(orderId);
    await this.apiClients.update(clientId, data);
  }

  async deleteItems(id: string, itemIds: string[]): Promise<void> {
    await Promise.all(
      itemIds.map((itemId) => this.apiPositions.delete(id, itemId)),
    );
  }

  async addItems(
    id: string,
    items: Omit<Position, 'offerId' | 'dealId' | 'id'>[],
  ): Promise<void> {
    for (const item of items) {
      await this.apiPositions.add(id, item);
    }
  }

  convertDTOtoModel(
    returnOrderDTO: ReturnOrderDTO,
    positions: Position[],
    client: Client | null = null,
  ): ReturnOrder {
    const no_special_items_positions = positions.filter(
      (position) => !SPECIAL_ITEMS_IDS.includes(position.article),
    );

    return {
      id: returnOrderDTO.Id,
      GUID: returnOrderDTO.GUID,
      name: returnOrderDTO.Name,
      contractor: returnOrderDTO.Contractor,
      timeCreated: returnOrderDTO.TimeCreated,
      timeUpdated: returnOrderDTO.TimeUpdated,
      owner: returnOrderDTO.Owner,
      isDraft: returnOrderDTO.IsDraft,
      isPaid: returnOrderDTO.IsPaid,
      finalPrice: returnOrderDTO.FinalPrice,
      programId: returnOrderDTO.ProgramId,
      mpName: returnOrderDTO.MpName,
      mpOrderNumber: returnOrderDTO.MpOrderNumber,
      mpReturnNumber: returnOrderDTO.MpReturnNumber,
      mpDateOpened: returnOrderDTO.MpDateOpened,
      mpRetReason: returnOrderDTO.MpRetReason,
      dealStatus: returnOrderDTO.DealStatus,
      returnLocation: returnOrderDTO.ReturnLocation,
      labelCreator: returnOrderDTO.LabelCreator,
      tracknumber: returnOrderDTO.Tracknumber,
      carrier: returnOrderDTO.Carrier,
      notes: returnOrderDTO.Notes,
      returnCondition: returnOrderDTO.ReturnCondition,
      problemDescription: returnOrderDTO.ProblemDescription,
      srName: returnOrderDTO.SrName,
      mpUaId: returnOrderDTO.MpUaId,
      manager: returnOrderDTO.Manager,
      status: returnOrderDTO.Status,
      statusName: returnOrderDTO.StatusName,
      programName: returnOrderDTO.ProgramName,
      positions: positions,
      no_special_items_positions: no_special_items_positions,
      client: client,
    };
  }

  convertModelToDTOforUpdate(
    returnOrder: Partial<Omit<ReturnOrder, 'id' | 'positions' | 'client'>>,
  ): Partial<Omit<ReturnOrderDTO, 'Id'>> {
    return {
      GUID: returnOrder.GUID,
      Name: returnOrder.name,
      Contractor: returnOrder.contractor,
      TimeCreated: returnOrder.timeCreated,
      TimeUpdated: returnOrder.timeUpdated,
      Owner: returnOrder.owner,
      IsDraft: returnOrder.isDraft,
      IsPaid: returnOrder.isPaid,
      FinalPrice: returnOrder.finalPrice,
      MpName: returnOrder.mpName,
      MpOrderNumber: returnOrder.mpOrderNumber,
      MpReturnNumber: returnOrder.mpReturnNumber,
      MpDateOpened: returnOrder.mpDateOpened,
      MpRetReason: returnOrder.mpRetReason,
      DealStatus: returnOrder.dealStatus,
      ReturnLocation: returnOrder.returnLocation,
      LabelCreator: returnOrder.labelCreator,
      Tracknumber: returnOrder.tracknumber,
      Carrier: returnOrder.carrier,
      Notes: returnOrder.notes,
      ReturnCondition: returnOrder.returnCondition,
      ProblemDescription: returnOrder.problemDescription,
      SrName: returnOrder.srName,
      MpUaId: returnOrder.mpUaId,
      Manager: returnOrder.manager,
    };
  }

  convertModelToDTO(
    returnOrder: Partial<Omit<ReturnOrder, 'id' | 'positions' | 'client'>>,
  ): Partial<Omit<ReturnOrderDTO, 'Id'>> {
    return {
      GUID: returnOrder.GUID,
      Name: returnOrder.name,
      Contractor: returnOrder.contractor,
      TimeCreated: returnOrder.timeCreated,
      TimeUpdated: returnOrder.timeUpdated,
      Owner: returnOrder.owner,
      IsDraft: returnOrder.isDraft,
      IsPaid: returnOrder.isPaid,
      FinalPrice: returnOrder.finalPrice,
      ProgramId: returnOrder.programId,
      MpName: returnOrder.mpName,
      MpOrderNumber: returnOrder.mpOrderNumber,
      MpReturnNumber: returnOrder.mpReturnNumber,
      MpDateOpened: returnOrder.mpDateOpened,
      MpRetReason: returnOrder.mpRetReason,
      DealStatus: returnOrder.dealStatus,
      ReturnLocation: returnOrder.returnLocation,
      LabelCreator: returnOrder.labelCreator,
      Tracknumber: returnOrder.tracknumber,
      Carrier: returnOrder.carrier,
      Notes: returnOrder.notes,
      ReturnCondition: returnOrder.returnCondition,
      ProblemDescription: returnOrder.problemDescription,
      SrName: returnOrder.srName,
      MpUaId: returnOrder.mpUaId,
      Manager: returnOrder.manager,
      Status: returnOrder.status,
      StatusName: returnOrder.statusName,
      ProgramName: returnOrder.programName,
    };
  }
}

export const apiClientsReturnOrders = new ApiClientReturnOrders();
