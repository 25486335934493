export const SHIPPING_TYPES = {
  pickup: [
    'USPS Ground Advantage',
    'UPS Ground',
    'USPS Priority Mail',
    'USPS Priority Mail Legal Flat Rate Envelope',
    'USPS Priority Mail Flat Rate Padded Envelope',
    'USPS Priority Mail Flat Rate Envelope',
    'USPS Priority Mail Cubic',
    'FEDEX Home Delivery',
    'FEDEX 2Day One Rate Envelope',
    'FEDEX 2Day One Rate Box',
  ],
  dropship: ['Standard', '2 DAY', 'NEXT DAY', 'USPS Ground Advantage'],
  rm_dropship: ['Standard', '2 DAY', 'NEXT DAY'],
  standard: ['Standard'],
  tft_dropship: [
    'Standard',
    'UPS Ground',
    'FedEx Ground',
    'FedEx Home Delivery',
    'OnTrac Ground',
    '3 DAY',
    'UPS 3 Day Select',
    'FedEx Express Saver',
    'USPS Priority Mail',
    '2 DAY',
    'UPS 2nd Day Air',
    'FedEx 2Day',
    'NEXT DAY',
    'UPS Next Day Air',
    'FedEx Standard Overnight',
  ],
} as const;
