import React, { Component } from 'react';
import { ErrorType } from '../../../../utils/common.types';
import { Button, Dropdown, ErrorView } from '../../../../UI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { httpErrorHandler } from '../../../../utils/common.utils';
import axios from 'axios';
import { downloadDataWithContentType } from 'rollun-ts-utils/dist';
import MuiIconButton from '../../../../UI/MuiIconButton';

interface IState {
  isLoading: boolean;
  error: ErrorType | null;
}

interface IProps {
  datastoreUrl: string;
  appName?: string;
}

class DownloadFullTable extends Component<IProps, IState> {
  state: IState = { isLoading: false, error: null };

  downloadFullTable = async () => {
    this.setState({ isLoading: true });
    const { appName, datastoreUrl } = this.props;
    try {
      const res = await axios.get(datastoreUrl, {
        headers: { Download: 'csv' },
      });
      const fileName = appName
        ? `${appName}_full_table.csv`
        : `${datastoreUrl.replace(/\//g, '_')}_full_table.csv`;
      downloadDataWithContentType(res.data, 'text/csv', fileName);
    } catch (e) {
      httpErrorHandler(e, (code, text) => {
        this.setState({ error: { code, text } });
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading, error } = this.state;
    if (error) {
      return (
        <Dropdown
          innerWidth={350}
          renderToggle={({ onClick }) => (
            <Button color="danger" size="sm" onClick={() => onClick()}>
              <FontAwesomeIcon icon="exclamation-circle" />
            </Button>
          )}
        >
          <ErrorView error={error}>
            <Button
              color="primary"
              onClick={() => this.setState({ isLoading: false, error: null })}
            >
              OK
            </Button>
          </ErrorView>
        </Dropdown>
      );
    }

    return (
      <MuiIconButton
        title="Download Full table as CSV"
        color="warning"
        width={40}
        height={40}
        iconName="file-download"
        disabled={isLoading}
        onClick={() => this.downloadFullTable()}
      />
    );
  }
}

export default DownloadFullTable;
