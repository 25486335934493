import React, { Component } from 'react';
import { ErrorType } from '../../../../utils/common.types';
import { Button, ErrorView, Spinner } from '../../../../UI';
import { createProductsFromSupplier } from '../../utils/createProductFromSupplier';
import { CreateProductPayload } from '../../utils/types';
import { httpErrorHandler } from '../../../../utils/common.utils';
import { downloadAsCSV } from 'rollun-ts-utils/dist';
import _ from 'lodash';

interface IState {
  error: ErrorType | null;
  isLoading: boolean;
  inputString: string;
  result: {
    items: Array<{
      id: string;
      brand_id: string;
      manufacturer_part_number: string;
      supplier_id: string;
      supplier_name: string;
    }>;
    problems: Array<{
      reason: string;
      item?: {
        supplier_id: string;
        supplier_name: string;
        csn?: string;
      };
      itemTask?: {
        supplier_id: string;
        supplier_name: string;
        csn?: string;
      };
    }>;
  } | null;
  validationError: string;
}

interface IProps {
  suppliersNames: Array<string>;
}

class CreateMultipleProducts extends Component<IProps, IState> {
  state: IState = {
    validationError: '',
    inputString: '',
    error: null,
    isLoading: false,
    result: null,
  };

  parseData = (data: string): Array<CreateProductPayload> => {
    return data
      .split(/\n\r?/)
      .filter((row) => !!row.trim())
      .map((row) => {
        const [supplier_name, supplier_id, sku = null] = row.split('\t');
        return { supplier_name, supplier_id, ...(sku && { sku }) };
      });
  };

  createProducts = () => {
    let data = null;
    try {
      data = this.parseData(this.state.inputString);
    } catch (err) {
      return this.setState({
        error: {
          code: -1,
          text: `Couldn't parse data! ${(err as Error).message}`,
        },
      });
    }
    this.setState({ isLoading: true, error: null, result: null });
    createProductsFromSupplier(data)
      .then((res) => {
        this.setState({ result: res });
      })
      .catch((err) => {
        httpErrorHandler(err, (code, text) =>
          this.setState({ error: { code, text } }),
        );
      })
      .finally(() => this.setState({ isLoading: false }));
  };

  validateInput = _.debounce((str: string) => {
    const { valid, message } = validateData(str, this.props.suppliersNames);
    if (!valid)
      this.setState({ validationError: message || 'Data is invalid!' });
    else this.setState({ validationError: '' });
  }, 300);

  render() {
    const {
      error,
      isLoading,
      result,
      inputString,
      validationError,
    } = this.state;
    if (error) {
      return (
        <div className="m-2">
          <ErrorView error={error}>
            <Button
              onClick={() =>
                this.setState({ result: null, error: null, isLoading: false })
              }
              color="primary"
            >
              Retry
            </Button>
          </ErrorView>
        </div>
      );
    }
    if (result) {
      return (
        <div className="m-2 mt-4 text-center">
          <h4>
            Complete {result.items.length + result.problems.length} Rollun IDs!
          </h4>
          {result.items.length > 0 && (
            <h5 className="text-success">
              Items created: {result.items.length}
            </h5>
          )}
          {result.problems.length > 0 && (
            <h5 className="text-danger">
              Items failed: {result.problems.length}
            </h5>
          )}
          <div className="d-flex justify-content-center flex-column align-items-center">
            <div style={{ width: 330 }}>
              <div className="d-flex ">
                <Button
                  className="mt-2 mr-1"
                  block
                  onClick={() => {
                    downloadAsCSV(
                      result.problems.map(({ reason, itemTask }) => ({
                        reason,
                        ...itemTask,
                      })),
                      'rollun_ids_creating_problems.csv',
                    );
                  }}
                  color="danger"
                >
                  Download failed
                </Button>
                <Button
                  className="mt-2 ml-1"
                  block
                  onClick={() => {
                    downloadAsCSV(
                      result.items,
                      'rollun_ids_creating_success.csv',
                    );
                  }}
                  color="success"
                >
                  Download succeeded
                </Button>
              </div>
              <Button
                className="mt-2"
                block
                onClick={() => this.setState({ result: null, inputString: '' })}
                color="primary"
              >
                Retry
              </Button>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="m-2">
        {isLoading ? (
          <div className="py-5">
            <Spinner />
          </div>
        ) : (
          <div>
            <div className="my-2">
              {validationError ? (
                <div className="text-danger">
                  <h5>{validationError}</h5>
                </div>
              ) : (
                <h5 className="text-success">Data is valid!</h5>
              )}
            </div>
            <textarea
              className="form-control"
              placeholder="supplier_name    csn    sku(optional)"
              onChange={(e) => {
                this.setState({ inputString: e.target.value });
                this.validateInput(e.target.value);
              }}
              value={inputString}
              rows={15}
            />
            <Button
              onClick={this.createProducts}
              className="mt-2"
              color="primary"
            >
              Create
            </Button>
          </div>
        )}
      </div>
    );
  }
}

const validateData = (
  input: string,
  suppliers: Array<string>,
): { valid: boolean; message?: string } => {
  const rows = input.split(/\n\r?/).filter((row) => !!row.trim());
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i].trim();
    if (!row) return { valid: false, message: `Row #${i + 1} is empty!` };
    const fields = row.split('\t');
    if (fields.length < 2 || fields.length > 3)
      return {
        valid: false,
        message: `In row ${
          i + 1
        } expected from 2 to 3 parameters - SuppName, CSN, SKU(optional). Instead got ${
          fields.length
        } params - [${fields.join('][')}]`,
      };
    const [supplier_name, supplier_id] = fields;
    if (!supplier_name)
      return {
        valid: false,
        message: `In row ${i + 1} first column(supplier_name) is required!`,
      };
    if (!suppliers.includes(supplier_name)) {
      return {
        valid: false,
        message: `In row ${
          i + 1
        } column supplier must be one of valid suppliers (${suppliers.join(
          ', ',
        )}), instead got (${supplier_name})!`,
      };
    }
    if (!supplier_id)
      return {
        valid: false,
        message: `In row ${i + 1} second column(csn) is required!`,
      };
  }
  return { valid: true };
};

export default CreateMultipleProducts;
