import { FieldsConfig } from '..';
import { Client } from '../../api-clients/types';

export const clientFieldConfig: FieldsConfig = {
  Client: {
    formatForCopying: (deal: any) => {
      const client = deal.client as Client;
      return `${client.address1}, ${client.address2 ? client.address2 : ''}, ${
        client.city
      }, ${client.state}, ${client.postalCode}`;
    },
    fields: [
      {
        displayName: 'Client Name',
        type: 'input',
        apiFieldPath: 'client.name',
      },
      {
        displayName: 'Address 1',
        type: 'input',
        apiFieldPath: 'client.address1',
      },
      {
        displayName: 'Address 2',
        type: 'input',
        apiFieldPath: 'client.address2',
      },
      {
        displayName: 'City',
        type: 'input',
        apiFieldPath: 'client.city',
      },
      {
        displayName: 'State',
        type: 'input',
        apiFieldPath: 'client.state',
      },
      {
        displayName: 'Postal Code',
        type: 'input',
        apiFieldPath: 'client.postalCode',
      },
    ],
  },
};
