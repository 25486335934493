import { useContext } from 'react';
import { DealContext } from '../context/dealContext';
import { useQuery } from 'react-query';
import { Query, And, Eq, Sort } from 'rollun-ts-rql';
import HttpDatastore from 'rollun-ts-datastore';
import { DealType } from '../index';

type ActivityLogAction =
  | {
      type: 'create';
      delay: number;
      entity: {
        name: string;
        type: string;
        rowId: null;
      };
      context: Record<string, unknown>;
    }
  | {
      type: 'update';
      delay: number;
      entity: {
        name: string;
        type: string;
        rowId: string;
      };
      context: Record<string, unknown>;
    }
  | {
      type: 'button';
      delay: number;
      entity: {
        name: string;
        type: string;
        rowId: string;
      };
      context: {
        button_id: string;
      };
    }
  | {
      type: 'scenario_request';
      delay: number;
      entity: {
        name: string;
        type: string;
        rowId: string;
      };
      context: {
        scenario_id: string;
      };
    }
  | {
      type: 'remote_request';
      delay: number;
      entity: {
        name: string;
        type: string;
        rowId: string;
      };
      context: {
        url: string;
        body: Record<string, unknown>;
        method: string;
      };
    }
  | {
      type: 'megaplan_request';
      delay: number;
      entity: {
        name: string;
        type: string;
        rowId: string;
      };
      context: {
        url: string;
      };
    }
  | {
      type: 'create_megaplan';
      delay: number;
      entity: {
        name: string;
        type: string;
        rowId: null;
      };
      context: Record<string, unknown>;
    }
  | {
      type: 'update_megaplan';
      delay: number;
      entity: {
        name: string;
        type: string;
        rowId: string;
      };
      context: Record<string, unknown>;
    };

export type ActivityLogEvent = {
  action:
    | {
        type: 'create' | 'update';
        context: Record<string, unknown>;
        startedAt: string;
      }
    | {
        type: 'button';
        delay: number;
        context: {
          button_id: string;
        };
        startedAt: string;
      }
    | {
        type: 'scenario_request';
        context: {
          scenario_id: string;
        };
        startedAt: string;
      };
  entity: {
    name: string;
    type: string;
    changedFields: [] | Record<string, unknown>;
  };
};

export type ActivityLog = {
  id: string;
  parent_id?: string;
  entity_type: string;
  entity_id: string;
  item_id: string;
  action: ActivityLogAction;
  event?: ActivityLogEvent;
  user_name?: string;
  problem?: string;
  started_at: string;
  finished_at?: string;
  created_at: string;
  updated_at: string;
  lct?: string;
};

export const useGetActivityLogs = () => {
  const context = useContext(DealContext);

  const dealTypeToEntityId = {
    [DealType.Dropship]: 'dropships',
    [DealType.Pickup]: 'pickups',
    [DealType.Order]: 'orders',
    [DealType.Bag]: 'bags',
    [DealType.ReturnPickup]: 'return_pickups',
    [DealType.ReturnOrder]: 'returns',
    [DealType.ReturnDropship]: 'return_dropships',
    [DealType.ReturnBag]: 'return_bags',
    [DealType.Fba]: 'fba',
    [DealType.Problem]: 'problems',
    [DealType.Employees]: 'employees',
    [DealType.Client]: 'clients',
  };

  return useQuery<ActivityLog[]>(
    ['getActivityLogs', context?.deal.id],
    async () => {
      const datastore = new HttpDatastore(
        '/api/datastore/ActionsHistoryDataStore',
      );

      const query = new Query()
        .setQuery(
          new And([
            new Eq('entity_type', 'deal'),
            new Eq('entity_id', dealTypeToEntityId[context?.type as DealType]),
            new Eq('item_id', context?.deal.id),
          ]),
        )
        .setSort(
          new Sort({
            created_at: -1,
          }),
        );

      const result = await datastore.query(query);
      return result;
    },
  );
};
