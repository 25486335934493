import React from 'react';
import withControls from './withControls';
import { IRendererProps } from './DefaultRenderer';
import { Typography } from '@material-ui/core';

const ten = (i: number) => (i < 10 ? '0' : '') + i;

const DatetimeRenderer: React.FC<IRendererProps> = ({ value }) => {
  const milliseconds = parseInt(value, 10) * 1000;
  const date = new Date(milliseconds);
  if (date.toDateString() === 'Invalid Date') {
    return <Typography color="error">{value}</Typography>;
  }
  const YYYY = date.getFullYear(),
    MM = ten(date.getMonth() + 1),
    DD = ten(date.getDate()),
    HH = ten(date.getHours()),
    II = ten(date.getMinutes()),
    SS = ten(date.getSeconds());
  return (
    <Typography title={value}>
      <span>{`${YYYY}-${MM}-${DD} ${HH}:${II}:${SS}`}</span>
    </Typography>
  );
};
export default withControls(DatetimeRenderer, { isDatetime: true });
