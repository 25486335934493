import React from 'react';
import AbstractService, {
  AbstractServiceProps,
} from '../../../AbstractService';
import AppContainer from '../../../../layout/AppContainer/AppContainer';
import { ErrorView, Spinner } from '../../../../UI';
import { DealType } from '../GenericDeal';
import CreateProblemDeal from '../common/widgets/CreateProblemDeal';
import DropshipDeal from './components/DropshipDeal';
import { clientFieldConfig } from '../GenericDeal/config/client-field.config';
import LinkedDeals from '../common/widgets/LinkedDeals/index';
import { marketplaceNames } from '../constants/marketplace-names';
import { suppliers } from '../constants/suppliers';
import { SENDERS } from '../constants/senders';
import { srShipMethodDropship } from '../constants/sr-ship-method';
import { carriers } from '../constants/carrier';
import { enterTracknumber } from '../constants/enter-tracknumber';
import { archiveScenarioDropship } from '../constants/archive-scenario';
import { Scenarios } from '../common/widgets/Scenarios';
import { scenarios } from '../../../Table/components/TableHeaderButtons/buttons/Scenarios/scenarios';

class CRMDropshipDeal extends AbstractService<AbstractServiceProps> {
  render() {
    const { appConfig, error } = this.state;

    if (error) {
      return (
        <AppContainer services={{}}>
          <div className="w-100 h-100 d-flex flex-column justify-content-center">
            <ErrorView error={error}>
              <div className="d-flex justify-content-center p-3">
                <div
                  className="btn btn-primary cursor-pointer"
                  onClick={this.reloadHome}
                >
                  Home
                </div>
              </div>
            </ErrorView>
          </div>
        </AppContainer>
      );
    }

    if (appConfig === null) {
      return <Spinner />;
    }

    const { user, role } = this.props.userIdentity;
    return (
      <AppContainer
        currentResourceName={this.props.resourceName}
        services={this.props.availableServices}
        user={user}
        role={role}
      >
        <DropshipDeal
          fieldsConfig={{
            ...clientFieldConfig,
            Order: {
              fields: [
                {
                  displayName: 'Marketplace name',
                  type: 'select',
                  options: marketplaceNames,
                  apiFieldPath: 'mpName',
                },
                {
                  displayName: 'Mp Order Number',
                  type: 'input',
                  apiFieldPath: 'mpOrderNumber',
                },
                {
                  displayName: 'Sender',
                  type: 'select',
                  options: (SENDERS as unknown) as string[],
                  apiFieldPath: 'sender',
                },
                {
                  displayName: 'Total',
                  type: 'input',
                  apiFieldPath: 'total',
                },
              ],
            },
            'Tech fields': {
              fields: [
                {
                  displayName: 'Problem description',
                  type: 'input',
                  apiFieldPath: 'problemDescription',
                  multiline: true,
                },
                {
                  displayName: 'ArchiveScenario',
                  type: 'select',
                  options: archiveScenarioDropship,
                  apiFieldPath: 'archiveScenario',
                },
                {
                  displayName: 'Enter Tracknumber',
                  type: 'select',
                  options: enterTracknumber,
                  apiFieldPath: 'enterTracknumber',
                },
                {
                  displayName: 'RetBag',
                  type: 'input',
                  apiFieldPath: 'retBag',
                },
              ],
            },
            Supplier: {
              fields: [
                {
                  displayName: 'Sr name',
                  type: 'select',
                  options: suppliers,
                  apiFieldPath: 'srName',
                },
                {
                  displayName: 'Sr Ship Method',
                  type: 'select',
                  options: srShipMethodDropship,
                  apiFieldPath: 'srShipMethod',
                },
                {
                  displayName: 'Sr Order Number',
                  type: 'input',
                  apiFieldPath: 'srOrderNumber',
                },
                {
                  displayName: 'Sr Payment card',
                  type: 'input',
                  apiFieldPath: 'srPaymentCard',
                },
                {
                  displayName: 'Sr Order Id',
                  type: 'input',
                  apiFieldPath: 'srOrderId',
                },
                {
                  displayName: 'Sr order status',
                  type: 'input',
                  apiFieldPath: 'srOrderStatus',
                },
              ],
            },
            Tracking: {
              fields: [
                {
                  displayName: 'tracknumber',
                  type: 'input',
                  apiFieldPath: 'tracknumber',
                },
                {
                  displayName: 'Carrier',
                  type: 'select',
                  options: carriers,
                  apiFieldPath: 'carrier',
                },
                {
                  displayName: 'Date Entering Tracknumber Into Mp',
                  type: 'datetime',
                  apiFieldPath: 'dateEnteringTracknumberIntoMp',
                },
                {
                  displayName: 'Ship status',
                  type: 'input',
                  apiFieldPath: 'shipStatus',
                },
                {
                  displayName: 'Additional Tracknumbers',
                  type: 'input',
                  apiFieldPath: 'additionalTracknumbers',
                },
                {
                  displayName: 'Date label creation',
                  type: 'datetime',
                  apiFieldPath: 'dateLabelCreation',
                },
              ],
            },
          }}
          type={DealType.Dropship}
          buttons={[]}
          widgets={[
            {
              displayName: 'Create Problem Deal',
              buttonName: 'Create Problem Deal',
              component: CreateProblemDeal,
              docsLink:
                'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.s8hlgyoe9i7t',
              docsTitle: 'docs for CreateProblemDeal widget',
            },
            {
              displayName: 'Linked deals',
              buttonName: 'Linked deals',
              component: LinkedDeals,
              props: {
                linkField: 'relatedOrder',
              },
              docsLink:
                'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.hwje1ah0h4ok',
              docsTitle: 'docs for Linked deals widget',
            },
            {
              displayName: 'Scenarios',
              buttonName: 'Scenarios',
              component: Scenarios,
              props: {
                scenarios: scenarios.dropship,
              },
              docsLink:
                'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.m1wjq0kj6hxo',
              docsTitle: 'docs for Scenarios widget',
            },
          ]}
        />
      </AppContainer>
    );
  }
}

export default CRMDropshipDeal;
