import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { COLUMN_HEADER_ALIAS } from '../../util/constants';
import { noop } from '../../../../utils/common.utils';
import CopiableText from '../../../../UI/CopiableText';
import { makeStyles } from '@material-ui/core/styles';
import { Box, TextField, Theme, Typography } from '@material-ui/core';
import MuiIconButton from '../../../../UI/MuiIconButton';
import { useInViewport } from 'react-in-viewport';
import { AGGREGATE_NODES } from '../FilterEditor/NodeFieldName/NodeFieldName';

export interface ColumnHeaderProps {
  searchOption?: { name: string; value: string };
  onSearchFieldChange?: (name: string, value: string | null) => void;
  sortDirection?: -1 | 1 | null;
  // enable or disable sort for this column
  toggleSort?: (name: string, currentDirection?: -1 | 1 | null) => void;
  toggleLocalSort?: (name: string, currentDirection?: -1 | 1 | null) => void;
  onHeaderMenuToggle?: (fieldName: string) => void;
  updateColumn?: {
    label: string;
    value: string;
  };
  column: {
    name: string;
    // there are more props, passed to this component, but i don't need them
    // Example
    /*
      column: {
        editable: undefined
        editor: undefined
        formatter: undefined
        headerRenderer: props => {…}
        idx: 1
        key: "title"
        left: 80
        name: "title"
        resizable: true
        rowType: "header"
        width: 80
      }
    */
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    cursor: 'pointer',
    overflow: 'visible',
  },
  columnName: {
    color: 'darkred',
    fontWeight: theme.typography.fontWeightBold,
  },
  input: {
    marginBottom: theme.spacing(1),
    width: '100%',
  },
}));

// TODO: handle with nooooooooodles
const ColumnHeaderCell: React.FC<ColumnHeaderProps> = ({
  column: { name = '' },
  sortDirection,
  toggleSort,
  toggleLocalSort,
  searchOption,
  onSearchFieldChange = noop,
  onHeaderMenuToggle,
}) => {
  // store search option locally, to not to reload Table every time, you type one single letter
  const [currentSearchValue, setCurrentSearchValue] = useState(
    searchOption ? searchOption.value : null,
  );
  const [isEditingSearch, setEditingSearch] = useState(false);
  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport: inView } = useInViewport(ref as any);

  const handleSearchSubmit = () => {
    if (isEditingSearch) {
      if (currentSearchValue) {
        if (currentSearchValue === (searchOption && searchOption.value)) {
          setEditingSearch(false);
        } else {
          onSearchFieldChange(name, currentSearchValue.trim());
        }
      }
    } else {
      setEditingSearch(true);
    }
  };

  const handleHeaderMenuOpen = () =>
    onHeaderMenuToggle && onHeaderMenuToggle(name);

  const headerName = COLUMN_HEADER_ALIAS[name] || name;

  const truncatedHeader = () => {
    return _.truncate(
      `${headerName}${currentSearchValue ? `=${currentSearchValue}` : ''}`,
      {
        length: 17,
      },
    );
  };

  return (
    <div className={classes.root} ref={ref}>
      {inView ? (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" width="100%">
            {isEditingSearch ? (
              <TextField
                autoFocus
                className={classes.input}
                value={currentSearchValue || ''}
                placeholder="Search full match"
                onKeyUp={(e) => e.key === 'Enter' && handleSearchSubmit()}
                style={{ height: 21 }}
                onChange={(e) => setCurrentSearchValue(e.target.value)}
              />
            ) : (
              <Typography
                variant="h6"
                id="header-name"
                onClick={() => {
                  if (!toggleSort) return;
                  const filteredNodes = AGGREGATE_NODES.filter(Boolean);
                  if (filteredNodes.includes(truncatedHeader().split('(')[0])) {
                    if (!toggleLocalSort) {
                      alert("toggleLocalSort isn't defined");
                      return;
                    }
                    toggleLocalSort(name, sortDirection);
                    return;
                  }
                  toggleSort(name, sortDirection);
                }}
                title={name}
                className={classes.columnName}
              >
                {truncatedHeader()}
              </Typography>
            )}
          </Box>
          <Box color="black">
            {!isEditingSearch && sortDirection && (
              <MuiIconButton
                iconName={sortDirection === 1 ? 'sort-up' : 'sort-down'}
                width={15}
                height={15}
                onClick={() => toggleSort && toggleSort(name, sortDirection)}
              />
            )}
            {!isEditingSearch ? (
              <CopiableText textToCopy={headerName}>
                <MuiIconButton
                  color="#000"
                  iconName="copy"
                  width={13}
                  height={13}
                />
              </CopiableText>
            ) : null}
            <MuiIconButton
              onClick={handleSearchSubmit}
              width={15}
              height={15}
              iconName="search"
            />
            {onHeaderMenuToggle && (
              <MuiIconButton
                iconName="cog"
                color="#000"
                width={15}
                height={15}
                onClick={handleHeaderMenuOpen}
              />
            )}
            {isEditingSearch && (
              <MuiIconButton
                color="error"
                iconName="times"
                width={15}
                height={15}
                onClick={() => {
                  setEditingSearch(false);
                  if (searchOption) {
                    onSearchFieldChange && onSearchFieldChange(name, null);
                  }
                  setCurrentSearchValue(null);
                }}
              />
            )}
          </Box>
        </Box>
      ) : null}
    </div>
  );
};

export default ColumnHeaderCell;
