import React, { ChangeEvent, FC } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import _ from 'lodash';

interface BoxDimensionInputProps {
  units: 'INCH' | 'LBS';
  name: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const BoxDimensionInput: FC<BoxDimensionInputProps> = ({
  onChange,
  name,
  value,
  units,
}) => {
  return (
    <TextField
      fullWidth
      type="number"
      label={_.upperFirst(name)}
      value={value}
      variant="outlined"
      InputProps={{
        endAdornment: <InputAdornment position="start">{units}</InputAdornment>,
      }}
      onChange={onChange}
    />
  );
};

export default BoxDimensionInput;
