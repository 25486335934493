import { Dropship } from '../../api-clients/types';
import ApiClientsDropships from '../../api-clients/api-clients-dropships';

export const useMutateDeal = () => {
  const handleUpdateDeal = async (
    id: string,
    data: Partial<Omit<Dropship, 'id' | 'positions'>>,
  ) => {
    await new ApiClientsDropships().update(id, data);
  };

  return {
    handleUpdateDeal,
  };
};
