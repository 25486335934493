import React, { Component } from 'react';
import axios from 'axios';
import { httpErrorHandler } from '../../../utils/common.utils';
import { Button, ErrorView, Spinner } from '../../../UI';
import { ErrorType } from '../../../utils/common.types';
import { copyToClipboard } from 'rollun-ts-utils/dist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface EbayAuthResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  refresh_token_expires_in: number;
  token_type: string;
}

interface IProps {
  code: string;
  clientId: string;
  clientSecret: string;
  redirectUri: string;
}

interface IState {
  isLoading: boolean;
  error: ErrorType | null;
  ebayResponse: EbayAuthResponse | null;
}

class GenerateRefreshToken extends Component<IProps, IState> {
  state: IState = {
    isLoading: false,
    error: null,
    ebayResponse: null,
  };

  async generateRefreshToken() {
    const { code, clientSecret, clientId, redirectUri } = this.props;
    this.setState({ isLoading: true, error: null });
    try {
      const { data } = await axios.get('/api/webhook/FetchEbayRefreshToken', {
        params: {
          code,
          client_secret: clientSecret,
          client_id: clientId,
          redirect_uri: redirectUri,
        },
      });
      this.setState({ ebayResponse: data as EbayAuthResponse });
      // clean up sensible information after generating token
      localStorage.removeItem('ebay_last__clientSecret');
      localStorage.removeItem('ebay_last__redirectUri');
      localStorage.removeItem('ebay_last__clientId');
    } catch (e) {
      httpErrorHandler(e, (code, text) => {
        this.setState({ error: { code, text } });
      });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  componentDidMount() {
    this.generateRefreshToken();
  }

  render() {
    const { isLoading, error, ebayResponse } = this.state;
    return (
      <div className="mx-3 my-2">
        <h4>Generate refresh token</h4>
        {isLoading && <Spinner />}
        {error && (
          <ErrorView error={error}>
            <Button color="primary" onClick={() => this.generateRefreshToken()}>
              Retry
            </Button>
          </ErrorView>
        )}
        {ebayResponse && (
          <>
            <p className="mt-2">
              Expiring in:{' '}
              {Math.round(
                ebayResponse.refresh_token_expires_in / 60 / 60 / 24 / 30.42,
              )}{' '}
              month at{' '}
              {new Date(
                Date.now() + ebayResponse.refresh_token_expires_in,
              ).toLocaleString()}
            </p>
            <div>
              <span>Refresh token:</span>
              <Button
                className="border ml-2"
                size="sm"
                onClick={() => copyToClipboard(ebayResponse.refresh_token)}
              >
                <FontAwesomeIcon icon="copy" />
              </Button>
            </div>
            <pre className="border-radius bg-light p-2">
              {ebayResponse.refresh_token}
            </pre>
          </>
        )}
      </div>
    );
  }
}

export default GenerateRefreshToken;
