import NodeFieldName from '../NodeFieldName/NodeFieldName';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../../../../UI';

export interface PossibleNodeFieldsProps {
  fieldNames: string[];
  onAggregateNodeSelect: (name: string, type: string) => void;

  onSelectNodesAdd(fields: Array<string>): void;

  onSortNodesAdd(fields: Array<string>): void;
}

const PossibleNodeFields = ({
  fieldNames,
  onAggregateNodeSelect,
  onSelectNodesAdd,
  onSortNodesAdd,
}: PossibleNodeFieldsProps) => {
  const [selectedFields, setSelectedFields] = useState<Array<any>>([]);
  const [searchFieldQuery, setSearchFieldQuery] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  });

  const handleNodeSelect = (name: string) => {
    if (!selectedFields.find((field: string) => field === name)) {
      setSelectedFields([...selectedFields, name]);
    } else {
      setSelectedFields(
        selectedFields.filter((field: string) => name !== field),
      );
    }
  };

  const filterFieldNames = (
    fieldNames: Array<string>,
    queryString: string,
  ): Array<string> => {
    const trimmed = queryString.trim().toLowerCase();
    if (!trimmed) return fieldNames;
    return fieldNames.filter((field) => field.toLowerCase().includes(trimmed));
  };

  const filteredFields = filterFieldNames(fieldNames, searchFieldQuery);

  return (
    <div className="d-flex flex-column card transparent-border">
      <div className="card-body p-3">
        <div className="card-title title">Possible fields</div>
        <div className="my-1 d-flex justify-content-between">
          <Button
            id="add-to-select"
            className="mt-1"
            size="sm"
            color="primary"
            onClick={() => {
              if (selectedFields.length) {
                onSelectNodesAdd(selectedFields);
                setSelectedFields([]);
              }
            }}
          >
            Add to select
          </Button>
          <Button
            id="add-to-sort"
            className="mt-1"
            color="primary"
            size="sm"
            onClick={() => {
              if (selectedFields.length) {
                onSortNodesAdd(selectedFields);
                setSelectedFields([]);
              }
            }}
          >
            Add to sort
          </Button>
        </div>
        <input
          type="text"
          ref={inputRef}
          onChange={(e) => setSearchFieldQuery(e.target.value)}
          placeholder="Start typing field name..."
          className="form-control my-1"
        />
        <div className="d-flex flex-column card-text possible-nodes">
          {filteredFields.length === 0 ? (
            <p>No fields found</p>
          ) : (
            filteredFields.map((fieldName) => (
              <NodeFieldName
                onNodeSelect={handleNodeSelect}
                selected={selectedFields.find(
                  (name: string) => name === fieldName,
                )}
                fieldName={fieldName}
                key={`${fieldName}`}
                onAggregateNodeSelect={onAggregateNodeSelect}
                nodeType="default"
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default PossibleNodeFields;
