import React, { FC, useContext } from 'react';
import { BagFillingPagesEnum } from '../BagFillingPages';
import { Box, IconButton, Typography } from '@material-ui/core';
import { BagFillingContext } from '../BagFillingContext';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

interface HeaderProps {
  appName: string;
}

const Header: FC<HeaderProps> = ({ appName }) => {
  const context = useContext(BagFillingContext);

  const PageNameTitleMap: Record<BagFillingPagesEnum, string | undefined> = {
    [BagFillingPagesEnum.SetLocation]: 'Select your storage',
    [BagFillingPagesEnum.BagsList]: context.storage,
    [BagFillingPagesEnum.BagPreview]: appName,
  };

  return (
    <Box marginBottom={2}>
      <Box display="flex">
        {context.page === BagFillingPagesEnum.SetLocation ? (
          <Box width="100%">
            <Typography align="center" variant="h4">
              {appName}
            </Typography>
          </Box>
        ) : (
          <IconButton onClick={() => context.goToPreviousPage?.(context)}>
            <ArrowBackIcon />
          </IconButton>
        )}
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          {context.storage && (
            <Typography
              variant="body1"
              color="textSecondary"
              onClick={() =>
                context.goToPage?.(BagFillingPagesEnum.SetLocation)
              }
            >
              {context.storage}
            </Typography>
          )}
          {context.bagId && (
            <Typography
              variant="body1"
              color="textSecondary"
              onClick={() => context.goToPage?.(BagFillingPagesEnum.BagsList)}
            >
              {context.bagId}
            </Typography>
          )}
        </Breadcrumbs>
      </Box>
      <Typography align="center" variant="h4">
        {PageNameTitleMap[context.page || BagFillingPagesEnum.SetLocation]}
      </Typography>
    </Box>
  );
};

export default Header;
