import React from 'react';
import { MenuItem } from './AppContainer/AppContainer';
import Paper from '@material-ui/core/Paper';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { NestedMenu } from './NavMenu/TopMenu/TopMenu';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Box, Typography } from '@material-ui/core';

interface IProps {
  menus: Array<MenuItem>;
  currentPath: string;
}

const getPathInLeftMenu = (menuItems: Array<MenuItem>, currentPath: string) => {
  const find = (
    leftMenuItems: Array<MenuItem>,
    url: string,
    path: Array<MenuItem> = [],
  ): Array<MenuItem> | null => {
    for (const item of leftMenuItems) {
      if (item.url === url) {
        return path.concat(item);
      } else if (item.subMenus) {
        const newPath = find(item.subMenus, url, path.concat(item));
        if (newPath && newPath.length > 0) {
          return newPath;
        }
      }
    }
    return null;
  };
  return find(menuItems, currentPath, []);
};

const BreadCrumbs: React.FC<IProps> = ({ menus, currentPath }) => {
  const breadcrumbs = getPathInLeftMenu(menus, currentPath);

  if (!breadcrumbs) return null;

  return (
    <Box marginBottom={1}>
      <Paper elevation={3}>
        <Box paddingX={3} paddingY={1}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            {breadcrumbs.map((item, idx, { length }) => {
              const key = `${item.label}_${idx}`;
              return idx + 1 !== length ? (
                <div key={key}>
                  <NestedMenu item={item} />
                </div>
              ) : (
                <Typography variant="body1" key={key}>
                  {item.label}
                </Typography>
              );
            })}
          </Breadcrumbs>
        </Box>
      </Paper>
    </Box>
  );
};

export default BreadCrumbs;
