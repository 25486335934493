import React, { Component } from 'react';
import Select from 'react-select';

interface IState {
  scopes: Array<string>;
}

interface IProps {
  clientId: string;
  clientSecret: string;
  redirectUri: string;
}

class AgreementLink extends Component<IProps, IState> {
  SCOPE_BASE_URL = 'https://api.ebay.com/oauth';

  SCOPES = [
    '/api_scope',
    '/api_scope/sell.marketing.readonly',
    '/api_scope/sell.marketing',
    '/api_scope/sell.inventory.readonly',
    '/api_scope/sell.inventory',
    '/api_scope/sell.account.readonly',
    '/api_scope/sell.account',
    '/api_scope/sell.fulfillment.readonly',
    '/api_scope/sell.fulfillment',
    '/api_scope/sell.analytics.readonly',
    '/api_scope/sell.finances',
    '/api_scope/sell.payment.dispute',
    '/api_scope/commerce.identity.readonly',
  ];

  constructor(props: IProps) {
    super(props);
    const scopesCache = localStorage.getItem('ebay_scopes');
    this.state = {
      scopes: scopesCache ? scopesCache.split(' ') : [],
    };
  }

  generateLink = () => {
    const { clientId, redirectUri } = this.props;
    const { scopes } = this.state;
    const params = {
      client_id: clientId,
      response_type: 'code',
      redirect_uri: redirectUri,
      scope: scopes.map((scope) => this.SCOPE_BASE_URL + scope).join(' '),
    };
    return `https://auth.ebay.com/oauth2/authorize?${Object.entries(params)
      .map(([key, val]) => `${key}=${val}`)
      .join('&')}`;
  };

  render() {
    const { scopes } = this.state;
    return (
      <div className="my-2 mx-3">
        <h4>Generate agreement link:</h4>
        <div className="m-2">
          <h5>
            Select{' '}
            <a href="https://developer.ebay.com/api-docs/static/oauth-scopes.html">
              Ebay API OAuth2 Scopes
            </a>
          </h5>
          <Select
            value={scopes.map((scope) => ({
              value: this.SCOPE_BASE_URL + scope,
              label: scope,
            }))}
            placeholder="Select Ebay API OAuth2 Scopes"
            options={this.SCOPES.map((scope) => ({
              value: this.SCOPE_BASE_URL + scope,
              label: scope,
            }))}
            isMulti
            onChange={(val: any) => {
              console.log('val', val);
              const scopes = val ? val.map((val: any) => val.label) : [];
              localStorage.setItem('ebay_scopes', scopes.join(' '));
              this.setState({
                scopes: scopes,
              });
            }}
          />
        </div>
        {scopes.length > 0 && (
          <>
            <h5 className="mx-2">
              Generated <a href={this.generateLink()}>link</a>
            </h5>
            <p>
              P.S. do not forget to set ebay accepted URL in Your dev account to
              <span className="p-1 bg-light">
                {window.location.origin + window.location.pathname}
              </span>
            </p>
          </>
        )}
      </div>
    );
  }
}

export default AgreementLink;
