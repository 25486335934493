import React, { FC } from 'react';
import withControls from './withControls';
import { IRendererProps } from './DefaultRenderer';
import { Typography } from '@material-ui/core';

const JsonRenderer: FC<IRendererProps> = ({ value }) => {
  switch (typeof value) {
    case 'string':
      return (
        <Typography title={value}>
          <span>{value}</span>
        </Typography>
      );
    case 'object':
      if (!value) return null;
      const val = JSON.stringify(value);
      return (
        <Typography title={val}>
          <span>{val}</span>
        </Typography>
      );
    default:
      return (
        <Typography>
          <span>
            [type {typeof value}]: {value.toString()}
          </span>
        </Typography>
      );
  }
};

export default withControls(JsonRenderer, {
  noSearch: true,
  isJson: true,
});
