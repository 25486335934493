import React from 'react';
import AbstractService, {
  AbstractServiceProps,
} from '../../../AbstractService';
import AppContainer from '../../../../layout/AppContainer/AppContainer';
import { ErrorView, Spinner } from '../../../../UI';
import { DealType } from '../GenericDeal';
import { Employees } from './components/Employees';
import { TypeOfEmployment } from '../constants/type-of-employment';
import { Team } from '../constants/team';

class CRMEmployees extends AbstractService<AbstractServiceProps> {
  render() {
    const { appConfig, error } = this.state;

    if (error) {
      return (
        <AppContainer services={{}}>
          <div className="w-100 h-100 d-flex flex-column justify-content-center">
            <ErrorView error={error}>
              <div className="d-flex justify-content-center p-3">
                <div
                  className="btn btn-primary cursor-pointer"
                  onClick={this.reloadHome}
                >
                  Home
                </div>
              </div>
            </ErrorView>
          </div>
        </AppContainer>
      );
    }

    if (appConfig === null) {
      return <Spinner />;
    }

    const { user, role } = this.props.userIdentity;
    return (
      <AppContainer
        currentResourceName={this.props.resourceName}
        services={this.props.availableServices}
        user={user}
        role={role}
      >
        <Employees
          fieldsConfig={{
            General: {
              fields: [
                {
                  displayName: 'TypeOfEmployment',
                  type: 'select',
                  options: TypeOfEmployment,
                  apiFieldPath: 'typeOfEmployment',
                },
                {
                  displayName: 'Team',
                  type: 'select',
                  options: Team,
                  apiFieldPath: 'team',
                },
                {
                  displayName: 'Name',
                  type: 'input',
                  apiFieldPath: 'name',
                },
                {
                  displayName: 'Position',
                  type: 'input',
                  apiFieldPath: 'position',
                },
                {
                  displayName: 'File With Passwords',
                  type: 'input',
                  apiFieldPath: 'fileWithPasswords',
                },
                {
                  displayName: 'File Salary',
                  type: 'input',
                  apiFieldPath: 'fileSalary',
                },
                {
                  displayName: 'PassportDrLicense',
                  type: 'input',
                  apiFieldPath: 'passportDrLicense',
                },
                {
                  displayName: 'IdentificationNumber',
                  type: 'input',
                  apiFieldPath: 'identificationNumber',
                },
                {
                  displayName: 'W8W9Form',
                  type: 'input',
                  apiFieldPath: 'W8W9Form',
                },
                {
                  displayName: 'W8W9Form2021',
                  type: 'input',
                  apiFieldPath: 'W8W9Form2021',
                },
                {
                  displayName: 'W8W9Form2022',
                  type: 'input',
                  apiFieldPath: 'W8W9Form2022',
                },
                {
                  displayName: 'W8W9Form2023',
                  type: 'input',
                  apiFieldPath: 'W8W9Form2023',
                },
                {
                  displayName: 'KontaktiBlizkih',
                  type: 'input',
                  apiFieldPath: 'kontaktiBlizkih',
                },
                {
                  displayName: 'Date1stDayAtWork',
                  type: 'input',
                  apiFieldPath: 'date1stDayAtWork',
                },
                {
                  displayName: 'Email',
                  type: 'input',
                  apiFieldPath: 'email',
                },
                {
                  displayName: 'SlackName',
                  type: 'input',
                  apiFieldPath: 'slackName',
                },
              ],
            },
          }}
          type={DealType.Employees}
          buttons={[]}
          widgets={[]}
        />
      </AppContainer>
    );
  }
}

export default CRMEmployees;
