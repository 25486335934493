import React from 'react';
import { ErrorView, Spinner } from '../../UI';
import AbstractService, { AbstractServiceProps } from '../AbstractService';
import AppContainer from '../../layout/AppContainer/AppContainer';
import MetricUi from './components/MetricUI';

class MetricUI extends AbstractService<AbstractServiceProps> {
  render() {
    const { error, appConfig } = this.state;

    if (error) {
      return (
        <AppContainer services={{}}>
          <div className="w-100 h-100 d-flex flex-column justify-content-center">
            <ErrorView error={error}>
              <div className="d-flex justify-content-center p-3">
                <div
                  className="btn btn-primary cursor-pointer"
                  onClick={this.reloadHome}
                >
                  Home
                </div>
              </div>
            </ErrorView>
          </div>
        </AppContainer>
      );
    }

    if (!appConfig) {
      return <Spinner />;
    }

    const { user, role } = this.props.userIdentity;

    return (
      <AppContainer
        user={user}
        role={role}
        currentResourceName={this.props.resourceName}
        services={this.props.availableServices}
      >
        <MetricUi />
      </AppContainer>
    );
  }
}

export default MetricUI;
