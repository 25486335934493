import React, { useState } from 'react';
import { LABEL_PACKED } from '../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ShippingLabel } from '../utils/types';
import { Button, Card } from '../../../UI';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { scrollToTop } from '../../../utils/common.utils';

interface IProps {
  onLabelCopy(id: string): void;
  onLabelSubmit(id: string): void;

  hideProcessed: boolean;
  inputLabel?: string;
  label: ShippingLabel;
}

const Label: React.FC<IProps> = (props) => {
  const [didCopy, setDidCopy] = useState(false);

  const {
    label: { id, status, manager_note },
    onLabelCopy,
    hideProcessed,
    inputLabel,
  } = props;

  if (hideProcessed && status && status.trim()) {
    return null;
  }

  if (inputLabel) {
    if (id.indexOf(inputLabel) === -1) return null;
  }
  const { textStyle, color, icon } = getLabelsStyleProps(status);

  const iconProps = didCopy
    ? { className: 'color-green', icon: 'check' as IconName }
    : { icon: 'copy' as IconName };

  // TODO Handle 'set state of unmounted component' error

  return (
    <Card className="w-100 mb-1 p-1">
      <div className="d-flex justify-content-between align-items-center">
        <FontAwesomeIcon className={`icon ${color}`} icon={icon} />
        <div className={`${textStyle} ${color}`}>{id}</div>
        <Button
          className="border ml-2"
          onClick={() => {
            setDidCopy(true);
            setTimeout(() => setDidCopy(false), 1000);
            onLabelCopy(id);
            scrollToTop();
          }}
        >
          <FontAwesomeIcon {...iconProps} />
        </Button>
        <Button
          className="border ml-2"
          onClick={() => {
            props.onLabelSubmit(id);
          }}
          disabled={status === LABEL_PACKED}
        >
          <FontAwesomeIcon className="color-green" icon="play" />
        </Button>
      </div>
      {manager_note ? (
        <p style={{ color: '#CC0000', fontSize: '1.2.rem' }}>
          note: {manager_note}
        </p>
      ) : null}
    </Card>
  );
};

export default Label;

const getLabelsStyleProps = (status: string | null) => {
  // only if status is green (processed), hide 'copy code' button
  if (status === LABEL_PACKED) {
    return {
      textStyle: 'font-weight-bold ',
      color: ' color-green',
      icon: 'check' as IconName,
    };
  } else if (!(status && status.trim())) {
    // If status empty or null, it means - label is not processed
    return { textStyle: '', color: '', icon: 'flag' as IconName };
  } else {
    // Otherwise status is red
    return {
      textStyle: 'font-weight-bold ',
      color: ' color-red',
      icon: 'ban' as IconName,
    };
  }
};
