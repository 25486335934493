import BagsList from './components/BagsList';
import React from 'react';
import BagPreview from './components/BagPreview';
import SelectLocation from './components/SelectLocation';

enum BagFillingPagesEnum {
  BagsList = 'bags_list',
  BagPreview = 'bags_preview',
  SetLocation = 'set_select',
}

const BagFillingPages: Record<BagFillingPagesEnum, JSX.Element> = {
  [BagFillingPagesEnum.BagsList]: <BagsList />,
  [BagFillingPagesEnum.BagPreview]: <BagPreview />,
  [BagFillingPagesEnum.SetLocation]: <SelectLocation />,
};

export { BagFillingPages, BagFillingPagesEnum };
