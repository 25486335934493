import React, { FC, useContext, useState } from 'react';
import {
  Box,
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
  Snackbar,
} from '@material-ui/core';
import { DealProps, DealType } from '../index';
import _ from 'lodash';
import { DealContext } from '../context/dealContext';
import { queryClient } from '../../../../../queryClient';
import MuiButton from '../../../../../UI/MuiButton';
import { CopiedTextField } from './CopiedTextField';
import { Alert } from '@material-ui/lab';
import { Deal } from '../../api-clients/types';

interface AdditionalFieldsProps {
  dealData: Deal;
  fieldsConfig: DealProps['fieldsConfig'];
  type: DealType;
}

const hashCode = (value: string) => {
  let hash = 0;
  let i = 0;
  let chr = 0;

  if (value.length === 0) return hash;
  for (i = 0; i < value.length; i++) {
    chr = value.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

const AdditionalFields: FC<AdditionalFieldsProps> = ({
  dealData,
  fieldsConfig,
}) => {
  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const dealContext = useContext(DealContext);
  const deal = (dealContext?.deal as Deal) || dealData;

  const handleFieldUpdate = async (e: any) => {
    const { value: rawValue, name } = e.target;
    const value =
      rawValue && typeof rawValue === 'string' ? rawValue.trim() : rawValue;

    const initialValue = _.get(deal, name);
    if (initialValue === value) {
      return;
    }
    setLoading(true);

    try {
      if (name.startsWith('client.')) {
        await dealContext?.onClientUpdate?.((deal as any).contractor, {
          [name.replace('client.', '')]: value,
        });
        await queryClient.invalidateQueries('GET_DEAL_KEY');
        e.target.value = value;
        return;
      }

      await dealContext?.onDealUpdate(deal.id, {
        [name]: value || null,
      });
      await queryClient.invalidateQueries('GET_DEAL_KEY');
      e.target.value = value;
    } catch (error) {
      alert((error as Error).message);
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        padding: 16,
        paddingTop: 8,
        width: '100%',
        height: '100%',
      }}
    >
      {Object.keys(fieldsConfig).map((key) => {
        const configs = fieldsConfig[key].fields;
        const formatForCopying = fieldsConfig[key].formatForCopying;

        if (configs.length === 0) {
          return null;
        }

        return (
          <Box
            key={`${key}-${dealContext?.deal?.id}`}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
              width: '100%',
              height: '100%',
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box fontSize={24} fontWeight="bold">
                {_.upperFirst(key)}
              </Box>
              {formatForCopying && (
                <MuiButton
                  style={{
                    width: 120,
                    marginLeft: 'auto',
                  }}
                  onClick={() => {
                    const copyResult = formatForCopying(dealContext?.deal);
                    navigator.clipboard.writeText(copyResult);
                    setAlertOpen(true);
                  }}
                >
                  Copy
                </MuiButton>
              )}
            </Box>

            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              autoHideDuration={2000}
              onClose={() => setAlertOpen(false)}
              open={alertOpen}
            >
              <Alert
                severity="info"
                style={{
                  fontWeight: 'bold',
                  fontSize: 14,
                }}
              >
                Copied to clipboard
              </Alert>
            </Snackbar>

            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                width: '100%',
                height: '100%',
              }}
            >
              {configs.map((field) => {
                if (!field.apiFieldPath && !field.displayName) return <></>;
                const valueHash = hashCode(
                  JSON.stringify(_.get(deal, field.apiFieldPath) || ''),
                );
                if (field.type === 'datetime') {
                  return (
                    <TextField
                      key={`${field.apiFieldPath}-${dealContext?.deal.id}-${valueHash}`}
                      defaultValue={_.get(deal, field.apiFieldPath) || ''}
                      label={field.displayName}
                      name={field.apiFieldPath}
                      type="datetime-local"
                      onBlur={handleFieldUpdate}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  );
                }

                if (field.type === 'select') {
                  return (
                    <FormControl
                      fullWidth
                      key={`${field.apiFieldPath}-${dealContext?.deal.id}-${valueHash}`}
                    >
                      <InputLabel id={field.apiFieldPath}>
                        {field.displayName}
                      </InputLabel>
                      <Select
                        onBlur={handleFieldUpdate}
                        disabled={loading}
                        labelId={field.apiFieldPath}
                        defaultValue={_.get(deal, field.apiFieldPath) || ''}
                        style={{ minWidth: 200 }}
                        name={field.apiFieldPath}
                      >
                        {[
                          ...(field.options?.includes(
                            _.get(deal, field.apiFieldPath),
                          )
                            ? field.options
                            : [
                                ...(field.options as any[]),
                                _.get(deal, field.apiFieldPath),
                              ]),
                          '',
                        ].map((option, index) => (
                          <MenuItem value={option} key={index}>
                            {option || '<empty>'}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  );
                }

                const defaultValue = _.get(deal, field.apiFieldPath);
                const isObject =
                  typeof defaultValue === 'object' && defaultValue !== null;

                return (
                  <CopiedTextField
                    key={`${field.apiFieldPath}-${dealContext?.deal.id}-${valueHash}`}
                    onBlur={handleFieldUpdate}
                    disabled={loading}
                    size="small"
                    multiline={field.multiline}
                    label={field.displayName}
                    name={field.apiFieldPath}
                    variant="outlined"
                    defaultValue={defaultValue || ''}
                    isObject={isObject}
                    onCopyAlert={() => setAlertOpen(true)}
                  />
                );
              })}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default AdditionalFields;
