import React, { useState } from 'react';
import { Button, Dropdown } from '../../../UI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { randomString } from 'rollun-ts-utils';

export enum PrivilegeStatus {
  IMPLICIT_ENABLED = 'implicit_enabled',
  EXPLICIT_ENABLED = 'explicit_enabled',
  IMPLICIT_DISABLED = 'implicit_disabled',
  EXPLICIT_DISABLED = 'explicit_disabled',
  NOT_SET = 'not_set',
}

interface IProps {
  status: PrivilegeStatus;
  name: string;
  resourceId: string;
  roleId: string;
  ruleId: string | null;

  onChange(change: any): void;
}

const getCustomPrivilegeName = (name: string) => {
  // basically map privileges to CRUD.
  const names: { [key: string]: string } = {
    GET: 'R',
    POST: 'C',
    PUT: 'U',
    DELETE: 'D',
    HEAD: 'H',
  };
  return names[name] || '';
};

const getButtonColorFromStatus = (status: PrivilegeStatus) => {
  const colors = {
    [PrivilegeStatus.IMPLICIT_ENABLED]: 'primary',
    [PrivilegeStatus.IMPLICIT_DISABLED]: 'danger',
    [PrivilegeStatus.EXPLICIT_ENABLED]: 'primary',
    [PrivilegeStatus.EXPLICIT_DISABLED]: 'danger',
    [PrivilegeStatus.NOT_SET]: 'secondary border',
  };
  return colors[status];
};

const PrivilegeControlButton: React.FC<IProps> = ({
  name,
  ruleId,
  resourceId,
  roleId,
  onChange,
  status,
}) => {
  const [currStatus, setCurrStatus] = useState(status);
  const [currRuleId, setCurrRuleId] = useState(ruleId);

  const createRule = async (isAllow: boolean) => {
    const id = randomString(10);
    onChange({
      id,
      action: 'create',
      role_id: roleId,
      resource_id: resourceId,
      privilege_id: name,
      allow_flag: isAllow ? '1' : '0',
    });
    setCurrStatus(
      isAllow
        ? PrivilegeStatus.EXPLICIT_ENABLED
        : PrivilegeStatus.EXPLICIT_DISABLED,
    );
    setCurrRuleId(id);
  };

  const updateRule = async (isAllow: boolean) => {
    if (currRuleId) {
      onChange({
        action: 'update',
        id: currRuleId,
        allow_flag: isAllow ? '1' : '0',
      });
      setCurrStatus(
        isAllow
          ? PrivilegeStatus.EXPLICIT_ENABLED
          : PrivilegeStatus.EXPLICIT_DISABLED,
      );
    }
  };

  const deleteRule = async () => {
    if (currRuleId) {
      onChange({
        action: 'delete',
        id: currRuleId,
      });
      setCurrStatus(PrivilegeStatus.NOT_SET);
    }
  };

  const isRuleImplicit =
    status === PrivilegeStatus.IMPLICIT_DISABLED ||
    status === PrivilegeStatus.IMPLICIT_ENABLED;
  return (
    <Dropdown
      color={getButtonColorFromStatus(currStatus)}
      innerWidth={120}
      toggleButtonChildren={
        <>
          {getCustomPrivilegeName(name)} <FontAwesomeIcon icon="sort-down" />
        </>
      }
      className="p-2"
      toggleButtonClass={'mx-1 btn-sm ' + (isRuleImplicit ? 'opacity-0-5' : '')}
    >
      {isRuleImplicit || currStatus === PrivilegeStatus.NOT_SET ? (
        <div>
          <Button
            block
            size="sm"
            color="primary"
            className="border"
            onClick={() => createRule(true)}
          >
            Create allowed
          </Button>
          <Button
            block
            size="sm"
            color="danger"
            className="border"
            onClick={() => createRule(false)}
          >
            Create forbidden
          </Button>
        </div>
      ) : (
        <div>
          {currStatus === PrivilegeStatus.EXPLICIT_DISABLED ? (
            <Button
              block
              size="sm"
              color="primary"
              className="border"
              onClick={() => updateRule(true)}
            >
              Set allowed
            </Button>
          ) : (
            <Button
              block
              size="sm"
              color="danger"
              className="border"
              onClick={() => updateRule(false)}
            >
              Set forbidden
            </Button>
          )}
          <Button
            block
            size="sm"
            color="warning"
            className="border"
            onClick={deleteRule}
          >
            Remove rule
          </Button>
        </div>
      )}
    </Dropdown>
  );
};

export default PrivilegeControlButton;
