import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { useStyles } from '../utils';
import ReturnAppContext from '../context';
import { Eq, Limit, Or, Query } from 'rollun-ts-rql';
import { useQuery } from 'react-query';
import _ from 'lodash';
import {
  ReturnedItem,
  useRollunIdWithCsnViewDatastore,
  RollunIdWithCsnItem,
  useReturnedItemsDatastore,
} from '../../../hooks/datastores';
import HighlightFoundSubstring from '../../../UI/HighlightFoundSubstring';
import ProcessedItems from './ProcessedItems';
import { NO_IMAGE } from '../../../utils/common.constants';
import { formatCsnsFromItems } from '../../UnidentifiedProducts/utils';
import { Item } from '../../UnidentifiedProducts/components/IdentifiedItemInfo';
import { logger } from '../../../utils/logger';

interface ReturnedItemsProps {
  search: string;
}

const ReturnedItems: FC<ReturnedItemsProps> = ({ search }) => {
  const [returnedItemsToShow, setReturnedItemsToShow] = useState<Item[]>([]);
  const [catalogData, catalogDispatch] = useRollunIdWithCsnViewDatastore();
  const [, returnItemsDispatch] = useReturnedItemsDatastore();
  const classes = useStyles();
  const context = useContext(ReturnAppContext);
  const { error, data: processedItems } = useQuery<
    ReturnedItem[] | null,
    Error
  >('fetchReturnedItems', () =>
    returnItemsDispatch(
      'QUERY',
      new Query({
        query: new Eq('track_number', context.label),
      }),
    ),
  );

  const findItemBySearch = useCallback(
    _.debounce(async (search) => {
      const data = await catalogDispatch(
        'QUERY',
        new Query({
          query: new Or([
            new Eq('rid', search),
            new Eq('mpn', search),
            new Eq('csn', search),
          ]),
          limit: new Limit(20),
        }),
      );

      if (data) {
        const csns = formatCsnsFromItems(data);

        logger.info('ReturnApp findItemBySearch csns', csns);

        setReturnedItemsToShow(
          Object.keys(csns).map((rollunId) => ({
            csns: csns[rollunId],
            ...(data.find(
              ({ rid }) => rid === rollunId,
            ) as RollunIdWithCsnItem),
          })),
        );
      }
    }, 300),
    [],
  );

  useEffect(() => {
    if (search.length === 0) {
      setReturnedItemsToShow([]);
      return;
    }

    findItemBySearch(search);
  }, [search]);

  useEffect(() => {
    if (catalogData.result) {
      const { result } = catalogData;
      const csns = formatCsnsFromItems(result);

      logger.info('ReturnApp catalogData csns', csns);

      setReturnedItemsToShow(
        Object.keys(csns).map((rollunId) => ({
          csns: csns[rollunId],
          ...(result.find(
            ({ rid }) => rid === rollunId,
          ) as RollunIdWithCsnItem),
        })) ?? [],
      );
    }
  }, [catalogData.result]);

  const handleItemChoose = (item: RollunIdWithCsnItem) => {
    logger.info('ReturnApp returnedItemsToShow', returnedItemsToShow);
    logger.info('ReturnApp handleItemChoose item', item);
    context.setItem(item.rid, 'foundItem');
  };

  if (catalogData.status === 'loading') {
    return (
      <Grid key="loading" item xs={12}>
        <Card>
          <CardContent className={classes.cardContent}>
            <LinearProgress />
          </CardContent>
        </Card>
      </Grid>
    );
  }

  if (catalogData.error || error) {
    logger.info(`ReturnApp.ReturnedItems: error`, {
      catalogData: catalogData.error,
      error,
    });
    return (
      <Grid key="error" item xs={12}>
        <Card>
          <CardContent className={classes.cardContent}>
            <Typography variant="h5" color="error">
              {catalogData.error?.text || error}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  return (
    <>
      <ProcessedItems items={processedItems} />
      {returnedItemsToShow.length === 0 ? (
        <Grid key="no_items" item xs={12}>
          <Card>
            <CardContent className={classes.cardContent}>
              <Typography variant="h5">
                There are no items that match this query
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ) : (
        returnedItemsToShow.map((item) => (
          <Grid key={item.csn} item xs={12}>
            <Card onClick={() => handleItemChoose(item)}>
              <CardContent className={classes.cardContent}>
                <CardMedia
                  component="img"
                  className={classes.media}
                  image={item.image === '-1' ? NO_IMAGE : item.image}
                  title="Items image"
                />
                <HighlightFoundSubstring
                  substring={search}
                  value={item.csn}
                  variant="h4"
                />
                <Typography variant="body1">{item.title}</Typography>
                <HighlightFoundSubstring
                  substring={search}
                  value={item.mpn}
                  variant="body1"
                  color="textSecondary"
                />
                <HighlightFoundSubstring
                  substring={search}
                  value={item.brand}
                  variant="body1"
                  color="textSecondary"
                />
                <HighlightFoundSubstring
                  substring={search}
                  value={item.rid}
                  variant="body1"
                  color="textSecondary"
                />
                {item.csns.map((csn) => (
                  <HighlightFoundSubstring
                    substring={search}
                    key={csn.csn}
                    value={`${csn.supplier} csn: ${csn.csn}`}
                    variant="body1"
                    color="textSecondary"
                  />
                ))}
              </CardContent>
            </Card>
          </Grid>
        ))
      )}
    </>
  );
};

export default ReturnedItems;
