import { useHistory } from 'react-router-dom';
import useQueryParams from './useQueryParams';
import { useState } from 'react';
import { parse, stringify } from 'qs';

const useQueryParamState = <T extends string>(
  paramName: string,
  defaultValue?: T,
): [T, (value: T) => void, () => void] => {
  const history = useHistory();
  const params = useQueryParams();
  const [param, setParam] = useState<T>(
    ((params[paramName] ?? defaultValue) as unknown) as T,
  );

  const setParamQueryState = (value: T) => {
    const urlParams = parse(history.location.search, {
      ignoreQueryPrefix: true,
    });

    setParam(value);
    history.push(
      `?${stringify({
        ...urlParams,
        [paramName]: value.length === 0 ? undefined : value,
      })}`,
    );
  };

  const removeParamQueryState = () => {
    const urlParams = parse(history.location.search, {
      ignoreQueryPrefix: true,
    });

    delete urlParams[paramName];
    history.push(`?${stringify(urlParams)}`);
  };

  return [param, setParamQueryState, removeParamQueryState];
};

export { useQueryParamState };
