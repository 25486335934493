import HttpDatastore from 'rollun-ts-datastore';
import { Client, ClientDTO } from './types';
import { Query } from 'rollun-ts-rql';

const datastore = new HttpDatastore<ClientDTO>(
  '/api/datastore/ClientsDataStore',
);

export default class ApiClients {
  async getByQuery(query: Query): Promise<Client[]> {
    const clientsDTO = await datastore.query(query);
    if (clientsDTO.length === 0) {
      return [];
    }

    const clients = clientsDTO.map((clientDTO) =>
      this.convertDTOtoModel(clientDTO),
    );
    return clients;
  }

  async create(data: Client): Promise<Client> {
    const dataDTO = this.convertModeltoDTO(data);
    const clientDTO = await datastore.create(dataDTO);
    const client = this.convertDTOtoModel(clientDTO);

    return client;
  }

  async update(id: string, data: Partial<Client>) {
    const dataDTO = this.convertModeltoDTO(data);
    const clientDTO = await datastore.update({
      ...dataDTO,
      Id: id,
    });
    const client = this.convertDTOtoModel(clientDTO);

    return client;
  }

  async getById(id: string): Promise<Client> {
    const clientDTO = await datastore.read(id);
    if (!clientDTO) return clientDTO;
    return this.convertDTOtoModel(clientDTO);
  }

  convertModeltoDTO(data: Partial<Client>): Partial<ClientDTO> {
    return {
      Id: data.id,
      Name: data.name,
      Address1: data.address1,
      Address2: data.address2,
      City: data.city,
      State: data.state,
      PostalCode: data.postalCode,
      Icq: data.icq,
      GUID: data.GUID,
      Site: data.site,
      Email: data.email,
      Skype: data.skype,
      Gender: data.gender,
      Jabber: data.jabber,
      Phone: data.phone,
      Twitter: data.twitter,
      Birthday: data.birthday,
      Facebook: data.facebook,
      LastName: data.lastName,
      FirstName: data.firstName,
      MiddleName: data.middleName,
      PersonType: data.personType,
      Description: data.description,
      TimeCreated: data.timeCreated,
      TimeUpdated: data.timeUpdated,
      ChildContacts: data.childContacts,
      PromisingRate: data.promisingRate,
      PreferTransport: data.preferTransport,
    };
  }

  convertDTOtoModel(data: ClientDTO): Client {
    return {
      id: data.Id,
      name: data.Name,
      address1: data.Address1,
      address2: data.Address2,
      city: data.City,
      state: data.State,
      postalCode: data.PostalCode,
      icq: data.Icq,
      GUID: data.GUID,
      site: data.Site,
      email: data.Email,
      skype: data.Skype,
      gender: data.Gender,
      jabber: data.Jabber,
      phone: data.Phone,
      twitter: data.Twitter,
      birthday: data.Birthday,
      facebook: data.Facebook,
      lastName: data.LastName,
      firstName: data.FirstName,
      middleName: data.MiddleName,
      personType: data.PersonType,
      description: data.Description,
      timeCreated: data.TimeCreated,
      timeUpdated: data.TimeUpdated,
      childContacts: data.ChildContacts,
      promisingRate: data.PromisingRate,
      preferTransport: data.PreferTransport,
    };
  }
}

export const apiClients = new ApiClients();
