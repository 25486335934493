import React, { useEffect, useState } from 'react';
import { handleLoginRedirect, ServiceInfo } from './Root.app';
import _ from 'lodash';
import AppContainer from '../../layout/AppContainer/AppContainer';
import { ErrorView } from '../../UI';
import { getUserIdentity, saveBeforeLoginPath } from '../../utils/common.utils';
import { Redirect } from 'react-router-dom';
import { BEFORE_LOGIN_PATH_KEY } from '../../UI/ErrorView';
import { USER_UNAUTHORIZED } from '../Table/util/constants';

interface HomePageProps {
  services: { [key: string]: ServiceInfo };
}

/**
 * Home page, contains some dashboars
 * Shows error page, if you went to unknown service
 * @param services
 */

const HomePage = ({ services }: HomePageProps) => {
  const [userIdentity, setUserIdentity] = useState({
    user: 'loading...',
    role: 'loading...',
  });
  const { pathname } = window.location;

  useEffect(() => {
    localStorage.setItem(USER_UNAUTHORIZED, 'false');

    getUserIdentity(false)
      .then((userIdentity) => setUserIdentity(userIdentity))
      .catch();
  }, []);

  console.log('userIdentity', userIdentity);

  const beforeLoginPathSession = sessionStorage.getItem(BEFORE_LOGIN_PATH_KEY);
  console.log('beforeLoginPathSession', beforeLoginPathSession);

  if (!userIdentity.user) {
    handleLoginRedirect(userIdentity, { user: 'justtowork', role: '' });
  }

  if (beforeLoginPathSession) {
    sessionStorage.removeItem(BEFORE_LOGIN_PATH_KEY);
    return <Redirect to={beforeLoginPathSession} />;
  }

  const last403Path = localStorage.getItem('LAST_403_PATH');
  if (last403Path) {
    localStorage.removeItem('LAST_403_PATH');
    return <Redirect to={last403Path} />;
  }

  const servicesAmount = _.size(services);

  return (
    <AppContainer
      services={services}
      user={userIdentity.user}
      role={userIdentity.role}
    >
      {pathname === '/' ? (
        <>
          <h1 className="text-center">Rollun CMS</h1>
          <h3 className="text-center">Welcome aboard!</h3>
          {servicesAmount === 0 && (
            <h4 className="text-center">
              To use our software, <a href="/login/">login</a> first
            </h4>
          )}
          {/* show grafana dashboards only if user logged in and one of roles is 'user'*/}
          {servicesAmount > 0 && userIdentity.role.includes('user') && (
            <div className="mt-2" style={{ overflowX: 'scroll' }}>
              <iframe
                className="w-100"
                title="Supplier files updated dashboard"
                src="https://grafana.rollun.net/d/cTRCq2hMk/supplier-files-update?orgId=1&from=now-15m&to=now&kiosk"
                height="1000"
                frameBorder="0"
              />
            </div>
          )}
        </>
      ) : (
        <ErrorView
          error={{
            code: 404,
            text: `Path ${pathname} does not exists, or You don\`t have enough rights to see it.`,
          }}
        >
          <h5>
            <span>Try to</span>
            <a href="/login/" onClick={saveBeforeLoginPath}>
              {' '}
              login{' '}
            </a>
            <span>, or</span>
            <a href="/login/" onClick={saveBeforeLoginPath}>
              {' '}
              change{' '}
            </a>
            <span>account</span>
          </h5>
        </ErrorView>
      )}
    </AppContainer>
  );
};

export default HomePage;
