import React, { Component } from 'react';
import { ParcelType } from '../utils/types';
import { PARCEL_DATA_STORE_URL } from '../utils/constants';
import HttpDatastore from 'rollun-ts-datastore';
import { Button, Spinner, ErrorView, Card } from '../../../UI';
import ParcelCard from './ParcelCard';
import { ErrorType } from '../../../utils/common.types';
import { httpErrorHandler } from '../../../utils/common.utils';

interface IState {
  loading: boolean;
  error: ErrorType | null;
  parcels: Array<ParcelType>;
  parcelInput: string;
}

interface IProps {
  onSelect(parcel: ParcelType): void;
}

/**
 * Renders list of parcels, select one parcel to handle it.
 */

class Parcels extends Component<IProps, IState> {
  parcelDataStore = new HttpDatastore<ParcelType>(PARCEL_DATA_STORE_URL);

  state: IState = { loading: false, error: null, parcels: [], parcelInput: '' };

  componentDidMount(): void {
    this.fetchParcels();
  }

  fetchParcels = () => {
    this.setState({ loading: true, error: null });
    this.parcelDataStore
      .query()
      .then((res) => this.setState({ loading: false, parcels: res }))
      .catch((err) => {
        httpErrorHandler(err, (code, text) =>
          this.setState({ loading: false, error: { code, text } }),
        );
      });
  };

  handleParcelInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ parcelInput: e.target.value });
  };

  handleParcelInputClear = () => {
    this.setState({ parcelInput: '' });
  };

  render() {
    const { loading, error, parcels, parcelInput } = this.state;
    const { onSelect } = this.props;

    if (loading) {
      return (
        <div className="m-5">
          <Spinner />
        </div>
      );
    }

    if (error) {
      return (
        <ErrorView error={error}>
          <Button color="primary" onClick={this.fetchParcels}>
            Back
          </Button>
        </ErrorView>
      );
    }

    const parcelsFilterCallback = (parcel: ParcelType) => {
      if (!parcelInput) return true;
      const inputLower = parcelInput.toLocaleLowerCase();
      return (
        parcel.title.toLocaleLowerCase().includes(inputLower) ||
        (parcel.track_numbers || '').toLocaleLowerCase().includes(inputLower) ||
        parcel.parcel_numbers.toLocaleLowerCase().includes(inputLower)
      );
    };

    const renderedParcels = parcels
      .sort((a, b) => +b.last_updated_datetime - +a.last_updated_datetime)
      .filter(parcelsFilterCallback)
      .map((parcel) => (
        <ParcelCard key={parcel.id} parcel={parcel} onSelect={onSelect} />
      ));

    return (
      <Card className="mx-3 position-relative p-3">
        <div className="mb-2">
          <label htmlFor="search">Search</label>
          <input
            name="search"
            className="form-control"
            type="text"
            placeholder="Type in any info about parcel..."
            value={parcelInput}
            onChange={this.handleParcelInput}
          />
          <div className="d-flex justify-content-end mt-1">
            <Button
              color="primary"
              size="sm"
              onClick={this.handleParcelInputClear}
            >
              Clear
            </Button>
          </div>
        </div>
        {renderedParcels.length > 0 ? (
          <>
            <p className="font-weight-bold">Click card to select</p>
            {renderedParcels}
          </>
        ) : (
          <h5>There is no parcels</h5>
        )}
      </Card>
    );
  }
}

export default Parcels;
