export const returnLocations = [
  'ret_CN_Andriy_Zaboychenko',
  'ret_RM',
  'ret_PU',
  'ret_AU',
  'ret_WPS',
  'ret_TFT',
  'none',
];

export const returnLocationsPickup = [
  'ret_CN_Andriy_Zaboychenko',
  'ret_WPS',
  'ret_TFT',
  'none',
];

export const returnLocationsDropship = [
  'ret_RM',
  'ret_PU',
  'ret_AU',
  'ret_WPS',
  'ret_TFT',
  'none',
];
