import React, { FC, useState } from 'react';
import MuiButton from '../../../../../../UI/MuiButton';
import { TableHeaderButtonDocsDialog } from './components/TableHeaderButtonDocsDialog';

interface TableHeaderButtonDocsProps {
  dataStoreUrl?: string;
  defaultQuery?: string;
  appConfig?: any;
}

const TableHeaderButtonDocs: FC<TableHeaderButtonDocsProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <MuiButton size="xs" color="primary" onClick={() => setIsOpen(true)}>
        Docs for Buttons
      </MuiButton>
      <TableHeaderButtonDocsDialog
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
        appConfig={props?.appConfig}
      />
    </>
  );
};

export default TableHeaderButtonDocs;
