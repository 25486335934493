import React, { PureComponent } from 'react';
import {
  AbstractArrayNode,
  AbstractQueryNode,
  AbstractScalarNode,
  And,
  Eq,
  Not,
  Or,
} from 'rollun-ts-rql';
import AbstractLogicalNode from 'rollun-ts-rql/dist/nodes/logicalNodes/AbstractLogicalNode';
import NonLogicalNodeEditor from '../NonLogicalNodeEditor/NonLogicalNodeEditor';
import { Button } from '../../../../../UI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import rqlNodeFactory from '../../../../../utils/RqlNodeFactory';
import { randomString } from 'rollun-ts-utils';

interface IProps {
  path: Array<number>;
  node: AbstractQueryNode;
  fieldNames: Array<string>;
  onNodeRemove: (path: Array<number>) => void;
  onNodeChange: (path: Array<number>, newNode: AbstractQueryNode) => void;
  onNodeAdd: (path: Array<number>, newNode: AbstractQueryNode) => void;
  isPreview?: boolean;
}

interface IState {
  validationError: string | null;
  awaitingDrop: boolean;
}

const getKeyFromNodeAndPath = (
  node: AbstractQueryNode,
  path: Array<number>,
): string => {
  return `${path.join('_')}_${node.name}_${randomString(3)}`;
};

const isNodeEq = (node: AbstractQueryNode) => node instanceof Eq;
const isNestedNodeInNotIsEq = (node: AbstractQueryNode) =>
  node instanceof Not && isNodeEq(node.subNodes[0]);

/**
 * This component can render Any node
 * In case of Logical And | Or
 *            renders node, and its children as this BaseNodeEditor component
 * In case of Logical Not
 *            renders button '!' to toggle not, and than renders its child
 * In case of non-logical node
 *            delegate render to NonLogicalNodeEditor
 */

class BaseNodeEditor extends PureComponent<IProps, IState> {
  state: IState = { validationError: null, awaitingDrop: false };

  NODES_TYPES = ['and', 'or'];

  static isNodeValid = (node: And | Or): boolean => {
    return node.subNodes && node.subNodes.length >= 2;
  };

  static getDerivedStateFromProps(nextProps: IProps) {
    const { node } = nextProps;
    if (node instanceof And || node instanceof Or) {
      if (!BaseNodeEditor.isNodeValid(node)) {
        return {
          validationError: `Logical node '${node.name}' requires 2 or more inner nodes.`,
        };
      }
    }
    if (
      node instanceof Not &&
      node.subNodes[0] instanceof AbstractLogicalNode
    ) {
      if (
        !BaseNodeEditor.isNodeValid(node.subNodes[0] as AbstractLogicalNode)
      ) {
        return {
          validationError: `Logical node '${node.subNodes[0].name}' requires 2 or more inner nodes.`,
        };
      }
    }
    return { validationError: null };
  }

  handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    if (this.props.isPreview === true) return;
    e.preventDefault();
    this.setState({ awaitingDrop: true });
    if (this.DRAG_LEAVE_TIMEOUT_ID) {
      clearTimeout(this.DRAG_LEAVE_TIMEOUT_ID);
      this.DRAG_LEAVE_TIMEOUT_ID = null;
    }
  };

  DRAG_LEAVE_TIMEOUT_ID: null | number = null;

  handleDragLeave = () => {
    if (this.props.isPreview === true) return;
    console.log('drag leave');
    this.DRAG_LEAVE_TIMEOUT_ID = window.setTimeout(() => {
      this.setState({ awaitingDrop: false });
    }, 200);
  };

  handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    if (this.props.isPreview === true) return;
    const dataTransfer = e.dataTransfer;
    const nodeFieldName = dataTransfer.getData('nodefieldname');
    this.setState({ awaitingDrop: false });
    this.props.onNodeAdd(
      this.props.path,
      new Eq(nodeFieldName, 'search value'),
    );
  };

  toggleNotNode = (enableNot: boolean) => {
    const { path } = this.props;
    if (enableNot) {
      this.props.onNodeChange(path, new Not([this.props.node]));
    } else {
      this.props.onNodeRemove(path);
    }
  };

  compareChildNodes = (node1: AbstractQueryNode, node2: AbstractQueryNode) => {
    const isNode1Eq = isNodeEq(node1) || isNestedNodeInNotIsEq(node1);
    const isNode2Eq = isNodeEq(node2) || isNestedNodeInNotIsEq(node2);

    if (isNode1Eq && isNode2Eq) return 0;

    return isNode1Eq ? -1 : 1;
  };

  render() {
    const {
      node,
      path,
      fieldNames,
      onNodeChange,
      onNodeRemove,
      onNodeAdd,
    } = this.props;
    const { validationError } = this.state;

    const isLogicalNot = node instanceof Not;

    const nodeToRender = isLogicalNot ? (node as Not).subNodes[0] : node;
    const pathOfNodeToRender = isLogicalNot ? path.concat(0) : path;

    if (!nodeToRender || nodeToRender instanceof Not) {
      return (
        <div className="text-danger">
          Can`t render empty `Not`, or nested `Not`, fix your filter.
        </div>
      );
    }

    if (!(nodeToRender instanceof AbstractLogicalNode)) {
      return (
        <div className="d-flex">
          <Button
            size="sm"
            title="NOT - revert condition"
            color="default"
            onClick={() => this.toggleNotNode(!isLogicalNot)}
            style={isLogicalNot ? { color: 'var(--danger)' } : { opacity: 0.5 }}
          >
            <FontAwesomeIcon icon="exclamation" size={'2x'} />
          </Button>
          <NonLogicalNodeEditor
            node={nodeToRender as AbstractScalarNode | AbstractArrayNode}
            path={pathOfNodeToRender}
            onNodeChange={onNodeChange}
            onNodeRemove={onNodeRemove}
            fieldNames={fieldNames}
          />
        </div>
      );
    }

    return (
      <div>
        {validationError && <h5 className="text-danger">{validationError}</h5>}
        <div
          className="row"
          onDragLeaveCapture={this.handleDragLeave}
          onDropCapture={this.handleDrop}
          onDragOverCapture={this.handleDragOver}
        >
          <div className="col-2 d-flex justify-content-center flex-column align-items-center">
            <div className="d-flex">
              <Button
                size="sm"
                title="NOT - revert condition"
                color="default"
                onClick={() => this.toggleNotNode(!isLogicalNot)}
                style={
                  isLogicalNot ? { color: 'var(--danger)' } : { opacity: 0.5 }
                }
              >
                <FontAwesomeIcon icon="exclamation" size={'2x'} />
              </Button>
              <Select
                value={{
                  value: nodeToRender.name,
                  label: nodeToRender.name,
                }}
                onChange={(value: any) => {
                  onNodeChange(
                    pathOfNodeToRender,
                    rqlNodeFactory(value.value, {
                      value: nodeToRender.subNodes,
                    }),
                  );
                }}
                options={this.NODES_TYPES.map((type) => ({
                  value: type,
                  label: type,
                }))}
                className="logical-node-selector"
              />
              <Button
                color="danger"
                onClick={() => onNodeRemove(pathOfNodeToRender)}
                className="ml-2 my-1"
              >
                <FontAwesomeIcon icon="times" />
              </Button>
            </div>
          </div>
          <div className="col-10">
            {nodeToRender.subNodes
              .sort(this.compareChildNodes)
              .map((childNode, idx) => {
                const newPath = pathOfNodeToRender.concat(idx);
                return (
                  <BaseNodeEditor
                    path={newPath}
                    key={getKeyFromNodeAndPath(childNode, newPath)}
                    onNodeChange={onNodeChange}
                    onNodeRemove={onNodeRemove}
                    onNodeAdd={onNodeAdd}
                    fieldNames={fieldNames}
                    node={childNode}
                  />
                );
              })}
            {/*{awaitingDrop && <NonLogicalNodeEditor node={new Like('---', '---')}*/}
            {/*                                       path={[]}*/}
            {/*                                       onNodeChange={this.props.onNodeChange}*/}
            {/*                                       onNodeRemove={this.props.onNodeRemove}*/}
            {/*                                       fieldNames={fieldNames}/>}*/}
            <div className="m-2">
              <Button
                id="logic-and-or"
                size="sm"
                color="success"
                onClick={() => {
                  onNodeAdd(
                    pathOfNodeToRender,
                    new And([
                      new Eq(fieldNames[0], 'search value'),
                      new Eq(fieldNames[0], 'search value'),
                    ]),
                  );
                }}
              >
                Add logic node
              </Button>
              <Button
                id="new-node"
                size="sm"
                color="success"
                className="ml-2"
                onClick={() => {
                  onNodeAdd(
                    pathOfNodeToRender,
                    new Eq(fieldNames[0], 'search value'),
                  );
                }}
              >
                Add node
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BaseNodeEditor;
