export function getScenarioButtonById(id: string) {
  return scenariosButtonts.find((button) => button.id === id);
}

export const scenariosButtonts = [
  {
    id: '1',
    name: 'check tracknumber on MP',
    url: 'http://megaplan-crm/api/webhook/TrackNumberCheckOrder',
  },
  {
    id: '2',
    name: 'autohowtobuy 3',
    url: 'https://crm-callbacks.rollun.net/autohowtobuy3',
  },
  {
    id: '3',
    name: 'linked deal autocreation',
    url: 'http://megaplan-crm/api/webhook/HowToBuy4Webhook',
  },
  {
    id: '4',
    name: 'cogs',
    url: 'http://megaplan-crm/api/webhook/UpdateOrderCogsWebhook',
  },
  {
    id: '5',
    name: 'cogs new',
    url: 'http://megaplan-crm/api/webhook/CalculateProfit',
  },
  {
    id: '6',
    // TuckerRockySupplier
    name: 'autobuy AU, PU, WPS',
    url: 'http://megaplan-crm/api/webhook/AutoBuy',
  },
  {
    id: '7',
    // TuckerRockySupplier
    name: 'get tracknumber AU, PU, WPS, TFT',
    url: 'http://megaplan-crm/api/webhook/OrderStatus',
  },
  {
    id: '8',
    name: 'tracknumber to MP',
    url: 'http://megaplan-crm/api/webhook/TrackNumberToMarketplace',
  },
  {
    id: '9',
    name: 'check tracknumber in parcel',
    url: 'http://megaplan-crm/api/webhook/TrackNumberToMegaplan',
  },
  {
    id: '10',
    name: 'ship status',
    url: 'http://megaplan-crm/api/webhook/TracknumberShipStatus',
  },
  {
    id: '11',
    name: 'dimensions',
    url: 'http://megaplan-crm/api/webhook/DealDimension',
  },
  {
    id: '12',
    name: 'return app add info',
    url: 'http://megaplan-crm/api/webhook/AddReturnInfo',
  },
  {
    id: '13',
    name: 'create rof reserve',
    url: 'http://megaplan-crm/api/webhook/CreateReserve',
  },
  {
    id: '14',
    name: 'delete rof reserve and subtract',
    url: 'http://megaplan-crm/api/webhook/DeleteReserveAndSubtract',
  },
  {
    id: '15',
    name: 'delete rof reserve',
    url: 'http://megaplan-crm/api/webhook/DeleteReserve',
  },
  {
    id: '16',
    name: 'RM autobuy',
    url: 'http://megaplan-crm/api/webhook/PickUpAutoBuyRm',
  },
  {
    id: '17',
    name: 'amazon labels creation',
    url: 'http://megaplan-crm/api/webhook/AmazonLabelCreationWebhook',
  },
  {
    id: '18',
    name: 'amazon get labels by bag',
    url: 'http://megaplan-crm/api/webhook/SendBagLabels',
  },
  {
    id: '19',
    name: 'prices to pickup orders',
    url: 'http://megaplan-crm/api/webhook/AddPriceToPickup',
  },
  {
    id: '20',
    name: 'add products to bag',
    url: 'http://megaplan-crm/api/webhook/AddProductsToBag',
  },
  {
    id: '21',
    name: 'return deal creation',
    url: 'http://megaplan-crm/api/webhook/CreateReturnPackage',
  },
  {
    id: '22',
    name: 'bag filling app',
    url: 'http://megaplan-crm/api/webhook/BagFilling',
  },
  {
    id: '23',
    name: 'labels purchase',
    url: 'https://crm-callbacks.rollun.net/labelsPurchase',
  },
  {
    id: '24',
    name: 'labels merging',
    url: 'https://crm-callbacks.rollun.net/labelsMerging',
  },
];
