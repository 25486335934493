import React from 'react';
import MuiIconButton from '../../UI/MuiIconButton';
import { LoadingStatusEnum } from '../../utils/common.types';

interface ResetFilterProps {
  loadingStatus?: LoadingStatusEnum;

  onCancelSearch(): void;
}

export const ResetFilter = ({
  onCancelSearch,
  loadingStatus,
}: ResetFilterProps) => {
  const isLoading = loadingStatus === LoadingStatusEnum.loading;

  return (
    <MuiIconButton
      title="reset filter"
      color="error"
      width={40}
      height={40}
      iconName="ban"
      disabled={isLoading}
      onClick={() => onCancelSearch()}
    />
  );
};
