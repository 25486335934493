import './LoginMenu.css';
import React from 'react';
import {
  clearBrowserCache,
  saveBeforeLoginPath,
} from '../../utils/common.utils';
import MuiButton from '../../UI/MuiButton';
import { Box, makeStyles, Theme } from '@material-ui/core';
import MuiTooltip from '../../UI/MuiTooltip';

export interface LoginMenuProps {
  user?: string;
  role?: string;
}

/**
 * Just container, that displays user info, and logout/logout button
 */

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  button: {
    margin: theme.spacing(0, 0.5),
  },
}));

const LoginMenu = ({ user, role }: LoginMenuProps) => {
  const classes = useStyles();
  const buttonParams =
    !user && (!role || role === 'guest')
      ? {
          color: 'primary' as const,
          name: 'Login',
          link: '/login/',
        }
      : {
          color: 'secondary' as const,
          name: 'Logout',
          link: '/oauth/logout',
        };

  return (
    <>
      {user && (
        <MuiTooltip title={`Roles: ${role}`}>
          <Box
            id="user-name"
            fontWeight="bold"
            marginLeft={0.5}
            marginRight={0.5}
            color="white"
          >
            User: {user}
          </Box>
        </MuiTooltip>
      )}
      <MuiButton
        className={classes.button}
        onClick={() => {
          saveBeforeLoginPath();
          clearBrowserCache();
          if (buttonParams.link) window.location.pathname = buttonParams.link;
        }}
        color={buttonParams.color}
      >
        {buttonParams.name}
      </MuiButton>
    </>
  );
};

export default LoginMenu;
