import { FC, useState } from 'react';
import React from 'react';
import axios from 'axios';
import MuiButton from '../../../../../UI/MuiButton';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  makeStyles,
  Theme,
} from '@material-ui/core';
import _ from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
  dataTextField: {
    marginBottom: theme.spacing(2),
  },
}));

/**
 * Component that returns button and dialog modal for two specific tables:
 * /EbayPlaisirCreateItemTask and /EbayRollunCreateItemTask
 * and gives possibility to create multiple items for one of these tables
 * using data copied from external table
 */

const NewItemsFromTable: FC = () => {
  const tablePathname = window.location.pathname;
  const [isOpen, setIsOpen] = useState(false);
  const [tableData, setTableData] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const removeMirrorQuotes = (string: string) => {
    if (string.includes(`\"`)) {
      return string.replace(`\"`, '');
    }

    return string;
  };

  const formatTableData = (tableData: string) => {
    const [tableHeaders, ...tableRows] = tableData
      .split('\n')
      .map((row) =>
        row.split('\t').map((cellData) => removeMirrorQuotes(cellData)),
      );

    const result: Record<string, any>[] = tableRows.map((items) => {
      return items.reduce((acc, item, index) => {
        if (!tableHeaders[index]) {
          return acc;
        }

        acc[tableHeaders[index]] = item;
        return acc;
      }, {} as Record<string, any>);
    });

    return result;
  };

  const createItemsRequestsInChunks = async (
    destinationUrl: string,
    itemsToCreate: any[],
  ) => {
    setIsLoading(true);
    let promises = [];
    const chunkSize = 10;
    const itemsToCreateChunks = _.chunk(itemsToCreate, chunkSize);

    for (const items of itemsToCreateChunks) {
      if (promises.length === chunkSize) {
        await Promise.all(promises);
        promises = [];
      }

      promises.push(axios.post(destinationUrl, items));
    }
  };

  const createItems = async () => {
    if (!tableData) {
      return;
    }

    const formatedTableData = formatTableData(tableData);
    console.log(formatedTableData);

    try {
      if (tablePathname.includes('/EbayPlaisirCreateItemTask')) {
        await createItemsRequestsInChunks(
          '/api/webhook/EbayPlaisirCreateProductsFromFile',
          formatedTableData,
        );
      }

      if (tablePathname.includes('/EbayRollunCreateItemTask')) {
        await createItemsRequestsInChunks(
          '/api/webhook/EbayRollunCreateProductsFromFile',
          formatedTableData,
        );
      }
    } catch (error) {
      console.log('Create items request failed, error: ', error);
    }

    setIsOpen(false);
    setTableData('');
    setIsLoading(false);
  };

  return (
    <div>
      <MuiButton
        color="primary"
        onClick={() => setIsOpen(true)}
        loading={isLoading}
        title="Upload copied table rows"
      >
        Upload items to create
      </MuiButton>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <DialogTitle>
          Paste data from table to create multiple items
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            'The dialog window will close when all items will be uploaded'
          ) : (
            <TextField
              className={classes.dataTextField}
              type="text"
              label="External table data"
              value={tableData}
              onChange={(e) => setTableData(e.target.value)}
              fullWidth
              variant="outlined"
              multiline
              minRows={10}
            />
          )}

          <MuiButton
            id="confirm-filter"
            fullWidth
            color="success"
            onClick={() => createItems()}
          >
            Create items
          </MuiButton>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default NewItemsFromTable;
