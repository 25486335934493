import React, { PureComponent, Fragment } from 'react';
import Spinner from '../../../UI/Spinner';
import GoodsItemInfo from './GoodsItemInfo';
import { GoodsItemProps } from '../utils/LabelGoodsToList';
import OrderProblemReport from './OrderProblemReport';
import { Button, ErrorView } from '../../../UI';
import { httpErrorHandler } from '../../../utils/common.utils';
import { ErrorType, Senders } from '../../../utils/common.types';
import { getExpandedItemProps } from '../utils/getSupplierCatalogItemsProps';
import { ExpandedItemProps } from '../utils/types';
import { ModalDialog } from './ModalDialog';
import { AlertDialog } from './AlertDialog';

export interface GoodsItemComponentProps {
  senderName: Senders;
  currentItem: GoodsItemProps;
  order: { id: string; supplier: string };

  confirmProduct(): void;

  onProductProblem(report: { type: string; note: string }): void;
}

interface IState {
  action: GoodsItemAction;
  icon: string;
  disabled: boolean;
  itemCatalogProps: ExpandedItemProps | null;
  error: ErrorType;
  isModalOpen: boolean;
  isAlertOpen: boolean;
}

enum GoodsItemAction {
  PENDING = 'pending',
  GET_GOODS = 'get_goods',
  ERROR = 'error',
}

export default class GoodsItem extends PureComponent<
  GoodsItemComponentProps,
  IState
> {
  problemTypes = [
    { name: 'Other issue...', required: true },
    { name: 'Not received' },
    { name: 'Missing parts', required: true },
    { name: 'Defective product', required: true },
    { name: 'Prop65 sticker is missing', required: true },
  ];

  constructor(props: any) {
    super(props);
    this.state = {
      action: GoodsItemAction.PENDING,
      icon: '',
      disabled: false,
      itemCatalogProps: null,
      error: { code: -1, text: '' },
      isModalOpen: false,
      isAlertOpen: false,
    };
  }

  fetchGoodItem = async () => {
    const { currentItem } = this.props;
    if (currentItem) {
      try {
        this.setState({ action: GoodsItemAction.PENDING });
        const itemCatalogProps = await getExpandedItemProps(
          currentItem.rollun_id,
          currentItem.shipping_label_id,
        );
        this.setState({ action: GoodsItemAction.GET_GOODS, itemCatalogProps });
      } catch (err) {
        console.log(err);
        httpErrorHandler(err, (code, text) =>
          this.setState({
            action: GoodsItemAction.ERROR,
            error: { code, text },
          }),
        );
      }
    }
  };

  componentDidMount() {
    this.fetchGoodItem().catch();
  }

  renderSwitch(appState: GoodsItemAction) {
    const { currentItem, confirmProduct } = this.props;
    const { itemCatalogProps, error } = this.state;
    const { isProp65AlertShowed } = itemCatalogProps || {};

    switch (appState) {
      case GoodsItemAction.PENDING:
        return (
          <div className="w-100 h-100 vh-50">
            <Spinner />
          </div>
        );
      case GoodsItemAction.GET_GOODS:
        return (
          <Fragment>
            {itemCatalogProps ? (
              <div className="goods-layout">
                <div className="goods-layout">
                  <GoodsItemInfo
                    order={this.props.order}
                    scanningProgressString={`${currentItem.itemNumber}/${currentItem.quantity}`}
                    managerNote={currentItem.manager_note}
                    itemProps={itemCatalogProps}
                  />
                </div>
                <div className="w-100">
                  <Button
                    size="sm"
                    color="primary"
                    block
                    onClick={() => {
                      this.setState({
                        disabled: true,
                        icon: 'check',
                        isModalOpen: !!isProp65AlertShowed,
                      });
                      if (!isProp65AlertShowed) confirmProduct();
                    }}
                  >
                    Got it
                  </Button>
                  <OrderProblemReport
                    problemTypes={this.problemTypes}
                    onProblemSubmit={this.props.onProductProblem}
                  >
                    If You have any problems with an item, please provide
                    information about it, and 'Send problem'
                  </OrderProblemReport>
                </div>
                <ModalDialog
                  isModalOpen={this.state.isModalOpen}
                  setIsModalOpen={(value: boolean) =>
                    this.setState({
                      isModalOpen: value,
                    })
                  }
                  onConfirm={() => {
                    confirmProduct();
                    this.setState({
                      isModalOpen: false,
                    });
                  }}
                  onDecline={() => {
                    this.setState({
                      isAlertOpen: true,
                      isModalOpen: false,
                    });
                  }}
                />
                <AlertDialog
                  isAlertOpen={this.state.isAlertOpen}
                  setIsAlertOpen={(value: boolean) =>
                    this.setState({ isAlertOpen: value })
                  }
                  onClick={() => {
                    this.setState({
                      isModalOpen: false,
                      isAlertOpen: false,
                    });
                  }}
                />
              </div>
            ) : (
              <div>
                <h1>Fatal Error</h1>
                <h3>
                  There is no such product from [{this.props.senderName}], part
                  number [{this.props.currentItem.part_number}]
                </h3>
                <h3>Please contact Your manager about this problem!</h3>
                <Button
                  size="sm"
                  color="info"
                  block
                  onClick={() => {
                    this.setState({ disabled: true, icon: 'check' });
                    confirmProduct();
                  }}
                >
                  Check next
                </Button>
              </div>
            )}
          </Fragment>
        );
      case GoodsItemAction.ERROR:
        return (
          <ErrorView error={error}>
            <div className="d-flex justify-content-center">
              <Button color="primary" onClick={() => this.fetchGoodItem()}>
                Try again
              </Button>
            </div>
          </ErrorView>
        );
      default:
        return null;
    }
  }

  render() {
    return <div>{this.renderSwitch(this.state.action)}</div>;
  }
}
