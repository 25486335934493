import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import MuiButton from '../../../UI/MuiButton';
import MuiTooltip from '../../../UI/MuiTooltip';
const ImportantDoc = () => {
  const [isButtonClicked, setIsButtonClicked] = useState<boolean>(false);
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const [timesClicked, setTimesClicked] = useState<number>(0);

  return (
    <Box width="100%">
      <Typography variant="h2" align="center">
        You are very welcome to our docs
      </Typography>
      {isButtonClicked && (
        <>
          <Typography variant="body1" align="center">
            YOU GOT RICK ROLLED
          </Typography>
          <Typography variant="body1" align="center">
            This video has no controls, just stop and vibe
          </Typography>
        </>
      )}
      <Box
        width="100%"
        marginTop={2}
        display="flex"
        justifyContent="space-around"
      >
        {!isButtonClicked ? (
          <MuiButton onClick={() => setIsButtonClicked(true)}>
            Click to see docs
          </MuiButton>
        ) : (
          <MuiTooltip
            open={tooltipOpen}
            title={
              <Box fontSize={Math.min(Math.max(10, timesClicked + 10), 25)}>
                Stop clicking or hovering, you can't stop this 🙂
              </Box>
            }
            placement="top"
          >
            <div
              onMouseLeave={() => setTooltipOpen(false)}
              onMouseEnter={() => setTooltipOpen(true)}
              onClick={() => {
                setTooltipOpen(true);
                setTimesClicked((prev) => prev + 1);
              }}
            >
              <iframe
                style={{ pointerEvents: 'none' }}
                id="video-frame"
                src="https://www.youtube-nocookie.com/embed/dQw4w9WgXcQ?&enablejsapi=1&autoplay=1&controls=0&modestbranding=1"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
                allowFullScreen
                height="472"
                width="500"
              />
            </div>
          </MuiTooltip>
        )}
      </Box>
    </Box>
  );
};

export default ImportantDoc;
