import React, { Component } from 'react';
import Select from 'react-select';
import Spinner from '../../../../UI/Spinner';
import HttpDatastore from 'rollun-ts-datastore';
import Query from 'rollun-ts-rql/dist/Query';
import Eq from 'rollun-ts-rql/dist/nodes/scalarNodes/Eq';
import And from 'rollun-ts-rql/dist/nodes/logicalNodes/And';
import { Button, Card, Dialog, ErrorView } from '../../../../UI';
import { ErrorType } from '../../../../utils/common.types';
import { httpErrorHandler } from '../../../../utils/common.utils';
import { RouteRow } from '../../utils/types';
import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { Help } from '@material-ui/icons';
import MuiTooltip from '../../../../UI/MuiTooltip';
import MuiButton from '../../../../UI/MuiButton';
import { SERVICE_CONSTRUCTOR_FORM_HELPER_TEXTS } from '../../constants';

const {
  ADD_ROUTE: { NAME, METHODS, MIDDLEWARE, PATH },
} = SERVICE_CONSTRUCTOR_FORM_HELPER_TEXTS;

export type SelectorProps = { value: string; label: string };

interface IState {
  privileges: Array<SelectorProps>;
  loading: boolean;
  path: string;
  name: string;
  middleware: SelectorProps;
  error: ErrorType | null;
  modalOpen: boolean;
}

interface IProps {
  onRouteCreate(name: string, path: string): void;
}

export const privilegesVariants = [
  {
    value: 'GET',
    label: 'GET',
  },
  {
    value: 'POST',
    label: 'POST',
  },
  {
    value: 'DELETE',
    label: 'DELETE',
  },
  {
    value: 'PUT',
    label: 'PUT',
  },
  {
    value: 'PATCH',
    label: 'PATCH',
  },
  {
    value: 'HEAD',
    label: 'HEAD',
  },
];

export const middlewaresVariants = [
  {
    value: 'rollun\\Service\\TestGrid\\Middleware\\CdnSpaAction',
    label: 'Simple page',
  },
  {
    value: 'rollun\\datastore\\Middleware\\DataStoreApi',
    label: 'DataStore',
  },
  {
    value: 'rollun\\callback\\Middleware\\WebhookMiddleware',
    label: 'WebHook',
  },
  {
    value: 'rollun\\Service\\TestGrid\\Middleware\\OpenapiProxy',
    label: 'OpenApi',
  },
];

class AddRoute extends Component<IProps, IState> {
  RouteDataStore = new HttpDatastore<RouteRow>('/api/datastore/RouteTable');

  constructor(props: IProps) {
    super(props);
    this.state = {
      privileges: [
        {
          value: 'GET',
          label: 'GET',
        },
      ],
      loading: false,
      path: '/',
      name: '',
      middleware: middlewaresVariants[0],
      error: null,
      modalOpen: false,
    };
  }

  onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newState = {
      [name]: value.trim(),
      // set name of route same as route, but replace / with -
      ...(name === 'path' && {
        name: value
          .trim()
          .replace(/\//g, (match, matchIndex) => (matchIndex === 0 ? '' : '-')),
      }),
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    this.setState(newState);
  };

  _sendData = (
    path: string,
    name: string,
    middleware: string,
    methods: string,
  ) => {
    this.RouteDataStore.create({
      path,
      name,
      middleware,
      methods,
    }).then((res: any) => {
      this.setState({ loading: false });
      this.props.onRouteCreate(res.name, res.path);
    });
  };

  _checkIfDataExists = (path: string, name: string) => {
    return this.RouteDataStore.query(
      new Query({
        query: new And([new Eq('path', path), new Eq('name', name)]),
      }),
    );
  };

  onRouteCreate = () => {
    const { name, path, middleware, privileges } = this.state;
    if (
      !name ||
      !path ||
      !middleware ||
      !privileges ||
      privileges.length === 0
    ) {
      return this.setState({
        error: {
          code: 0,
          text: 'Fill all fields!',
        },
      });
    }
    let methods = '';
    privileges.forEach((el, index) => {
      methods += el.label + (index === privileges.length - 1 ? '' : ',');
    });
    this.setState({
      loading: true,
      error: null,
    });
    this._checkIfDataExists(path, name)
      .then((res) => {
        res.length > 0
          ? this.setState({
              modalOpen: true,
              loading: false,
            })
          : this._sendData(path, name, middleware.value, methods);
      })
      .catch((err) =>
        httpErrorHandler(err, (code, text) =>
          this.setState({
            loading: false,
            error: {
              code,
              text,
            },
          }),
        ),
      );
  };

  render() {
    const {
      privileges,
      path,
      name,
      middleware,
      loading,
      error,
      modalOpen,
    } = this.state;

    if (error) {
      return (
        <Card>
          <ErrorView error={error}>
            <Button
              color="primary"
              onClick={() => {
                this.setState({
                  loading: false,
                  error: null,
                });
              }}
            >
              Retry
            </Button>
          </ErrorView>
        </Card>
      );
    }

    if (loading) {
      return (
        <div className="m-5">
          <Spinner />
        </div>
      );
    }

    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Add Route</Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Path"
              value={path}
              name="path"
              onChange={this.onInputChange}
              InputProps={{
                endAdornment: (
                  <MuiTooltip title={PATH} arrow>
                    <InputAdornment position="end">
                      <Help />
                    </InputAdornment>
                  </MuiTooltip>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Name"
              value={name}
              name="name"
              onChange={this.onInputChange}
              InputProps={{
                endAdornment: (
                  <MuiTooltip title={NAME} arrow>
                    <InputAdornment position="end">
                      <Help />
                    </InputAdornment>
                  </MuiTooltip>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Middleware:</Typography>
            <Box display="flex">
              <Box width="100%" marginRight={1}>
                <Select
                  value={middleware}
                  options={middlewaresVariants}
                  onChange={(data: any) => {
                    if (!data) return;
                    this.setState({ middleware: data });
                  }}
                />
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <MuiTooltip title={MIDDLEWARE} arrow>
                  <Help />
                </MuiTooltip>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Methods:</Typography>
            <Box display="flex">
              <Box width="100%" marginRight={1}>
                <Select
                  value={privileges}
                  isMulti
                  options={privilegesVariants}
                  onChange={(data: any) => {
                    console.log(data);
                    this.setState({ privileges: data || [] });
                  }}
                />
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <MuiTooltip title={METHODS} arrow>
                  <Help />
                </MuiTooltip>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <MuiButton color="primary" fullWidth onClick={this.onRouteCreate}>
              Create
            </MuiButton>
          </Grid>
        </Grid>

        <Dialog
          title="Confirm"
          isOpen={modalOpen}
          options={{ centered: true }}
          onClose={() => this.setState({ modalOpen: false })}
          onDecline={() => this.setState({ modalOpen: false })}
          onOk={() => {
            this.props.onRouteCreate(this.state.name, this.state.path);
          }}
        >
          Route with this data:
          <div className="p-2 card border">
            <p className="ml-2">Path: {path}</p>
            <p className="ml-2">Name: {name}</p>
            <p className="ml-2">Middleware: {middleware.value}</p>
          </div>
          exists. Use it to proceed?
        </Dialog>
      </div>
    );
  }
}

export default AddRoute;
