import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from '../../../../../UI/Spinner';
import GenericDeal, { DealProps, DealType } from '../../GenericDeal';
import { ErrorView } from '../../../../../UI';
import { useGetBag } from '../hooks/useGetBag';
import MuiButton from '../../../../../UI/MuiButton';
import { Box } from '@material-ui/core';
import { useMutateItems } from '../hooks/useMutateItems';
import { useMutateDeal } from '../hooks/useMutateDeal';
import { apiClientsBag } from '../../api-clients/api-clients-bags';

const BagDeal: FC<DealProps> = ({ buttons, fieldsConfig, widgets }) => {
  const { id } = useParams<{ id: string }>();
  const { data, error, isFetching } = useGetBag(id);
  const history = useHistory();
  const { handleAddItems, handleDeleteItems } = useMutateItems();
  const { handleUpdateDeal } = useMutateDeal();

  if (isFetching && data?.id !== id) {
    return <Spinner />;
  }

  if (error && !data) {
    return (
      <ErrorView
        error={{
          text: (error as Error).message,
          code: (error as Error).message.includes('not found') ? 404 : 403,
        }}
      >
        <Box
          style={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <MuiButton onClick={() => history.goBack()}>Back</MuiButton>
          <MuiButton onClick={() => location.reload()}>Reload</MuiButton>
        </Box>
      </ErrorView>
    );
  }

  return (
    <GenericDeal
      data={data!}
      rawData={data ? apiClientsBag.convertModelToDTO(data!) : {}}
      type={DealType.Bag}
      buttons={buttons}
      fieldsConfig={fieldsConfig}
      widgets={widgets}
      onItemsAdd={handleAddItems}
      onItemsDelete={handleDeleteItems}
      onDealUpdate={handleUpdateDeal}
      availableStatuses={[
        {
          id: '17',
          name: 'Bag creation',
          color: '#D93222',
        },
        {
          id: '18',
          name: 'Bag filling',
          color: '#00B27A',
        },
        {
          id: '19',
          name: 'Placing order',
          color: '#AE31CF',
        },
        {
          id: '20',
          name: 'Payment',
          color: '#00B27A',
        },
        {
          id: '21',
          name: 'Labels creation information preparation',
          color: '#009BAA',
        },
        {
          id: '22',
          name: 'Packaging',
          color: '#FFAF4B',
        },
        {
          id: '23',
          name: 'Sending',
          color: '#84216B',
        },
        {
          id: '24',
          name: 'Archive',
          color: '#FFCC1E',
        },
      ]}
    />
  );
};

export default BagDeal;
