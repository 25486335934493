import React, { Component } from 'react';
import HttpDatastore from 'rollun-ts-datastore';
import CardView, { CardData } from './CardView';
import { Spinner, ErrorView, Card } from '../../../UI';
import { ErrorType } from '../../../utils/common.types';
import { httpErrorHandler } from '../../../utils/common.utils';
import { Grid, Theme, Typography, withStyles } from '@material-ui/core';
import MuiButton from '../../../UI/MuiButton';

export interface CardPickerProps {
  datastoreUrl: string;
  classes: {
    root: string;
    paper: string;
    error: string;
  };
}

interface IState {
  cards: Array<CardData> | null;
  error: ErrorType | null;
}

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
  },
  error: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});

class CardPicker extends Component<CardPickerProps, IState> {
  private store: HttpDatastore<CardData>;

  constructor(props: CardPickerProps) {
    super(props);
    this.store = new HttpDatastore<CardData>(props.datastoreUrl);
    this.state = {
      cards: null,
      error: null,
    };
  }

  componentDidMount(): void {
    this.fetchCards();
  }

  fetchCards = () => {
    this.setState({
      cards: null,
      error: null,
    });
    this.store
      .query()
      .then((data) => this.setState({ cards: data }))
      .catch((e) =>
        httpErrorHandler(e, (code, text) =>
          this.setState({
            error: {
              code,
              text,
            },
          }),
        ),
      );
  };

  render() {
    const {
      state: { cards, error },
    } = this;
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography variant="h2">Card picker</Typography>
        {/*<Card className='my-4'*/}
        {/*      style={{fontSize: '1.5rem'}}>*/}
        {/*  Double click card to select*/}
        {/*</Card>*/}
        {error ? (
          <Card className={classes.error}>
            <ErrorView error={error}>
              <MuiButton color="primary" onClick={this.fetchCards}>
                Try again
              </MuiButton>
            </ErrorView>
          </Card>
        ) : (
          <>
            {cards !== null ? (
              cards.length === 0 ? (
                <Typography variant="h4">There are no cards :(</Typography>
              ) : (
                <Grid container spacing={2}>
                  {cards.map((cardInfo, index) => (
                    <Grid item key={index} xs={12} md={6} lg={4} xl={4}>
                      <CardView
                        {...cardInfo}
                        onCardSelect={this.setActiveCard}
                        index={index + 1}
                      />
                    </Grid>
                  ))}
                </Grid>
              )
            ) : (
              <Spinner />
            )}
          </>
        )}
      </div>
    );
  }

  /**
   * set card with {
  id
}

   status to '1' -> enable
   * also other cards statuses are set to '0' -> disable
   * @param id
   */

  setActiveCard = (id: string) => {
    if (this.state.cards === null) return;
    this.setState({ cards: null });
    const promisesFromStore: PromiseLike<any>[] = [];
    this.state.cards.forEach((data: CardData) => {
      promisesFromStore.push(
        this.store.update({
          ...data,
          enable: data.id === id ? '1' : '0',
        }),
      );
    });
    Promise.all(promisesFromStore)
      .then(() => this.fetchCards())
      .catch((e) =>
        httpErrorHandler(e, (code, text) =>
          this.setState({
            error: {
              code,
              text,
            },
          }),
        ),
      );
  };
}

export default withStyles(styles)(CardPicker);
