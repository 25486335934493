import { MenuItem } from '../AppContainer/AppContainer';
import { LeftMenuType } from './LeftMenu/LeftMenu';

const sortMenus = (a: MenuItem, b: MenuItem): number => {
  return a.label > b.label ? 1 : -1;
};

const formatMenus = (acc: Array<LeftMenuType>, item: MenuItem) => {
  const formatted: LeftMenuType = {
    label: item.label,
    to: item.url,
    test: 'test',
    externalLink: /^https?/.test(item.url),
    ...(item.subMenus && {
      content: item.subMenus.sort(sortMenus).reduce(formatMenus, []),
    }),
  };
  return acc.concat(formatted);
};

export const normalizeMenus = (menuItems: MenuItem[]) => {
  return menuItems.sort(sortMenus).reduce(formatMenus, []);
};
