import React, { Component } from 'react';
import { FrontConfigRow } from '../../../RootApp/Root.app';

class ConfigView extends Component<{ config: FrontConfigRow }> {
  render() {
    const { config } = this.props;
    return (
      <div className="my-2 mx-3">
        <h3>Config</h3>
        <h5>{config.resource}</h5>
        <pre style={{ fontSize: '0.8rem' }}>
          {JSON.stringify(config.config, null, 2)}
        </pre>
      </div>
    );
  }
}

export default ConfigView;
