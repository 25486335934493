import ReactDOM from 'react-dom';
import React from 'react';
import RootApp from './pages/RootApp/Root.app';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { ErrorBoundary } from './UI';
import { setupIcons } from './utils/common.utils';
import theme from './themeProvider';
import { ThemeProvider } from '@material-ui/core';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './queryClient';
import LifecycleToken from './utils/lifecycle-token';
import { axiosWithLogger } from './utils/axios-with-logger';
import { axiosWithUserHeaders } from './utils/axios-with-user-headers';

setupIcons();

export interface FieldConfig {
  name: string;
  label?: string;
  isEditable?: boolean;
  minWidth?: number;
  rendererName?: string;
  editorName?: string;
  params?: any;
}

const tokenStorage = new LifecycleToken(sessionStorage);
axiosWithLogger(tokenStorage);
axiosWithUserHeaders();

// mute warnings, because barcode scanner algorithm(written not by me) produces a LOT warnings...
// may be i need to move barcode scanner to its own npm package...
// window.console.warn = () => {
// };
ReactDOM.render(
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          {/* <MobileLogger /> */}
          <RootApp />
        </BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider>
  </ErrorBoundary>,
  document.getElementById('root'),
);
