import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useUnidentifiedReturnsItemsDatastore } from '../../../hooks/datastores';
import { And, Eq, Eqn, Not, Or, Query } from 'rollun-ts-rql';
import { ErrorView, Spinner } from '../../../UI';
import IdentifiedItemInfo from './IdentifiedItemInfo';
import SelectStorage from './SelectStorage';
import MuiButton from '../../../UI/MuiButton';
import { useQueryParamState } from '../../../hooks/useQueryParamState';

export const StorageContext = React.createContext<{
  storage: string;
  setStorage: (location: string) => void;
} | null>(null);

const UnidentifiedProducts = () => {
  const [
    { result, isLoading, isError, error },
    dispatch,
  ] = useUnidentifiedReturnsItemsDatastore();
  const [storage, setStorage] = useQueryParamState('storage');

  const getUnidentifiedItems = async () => {
    if (!storage) {
      return;
    }
    await dispatch(
      'QUERY',
      new Query({
        query: new And([
          new Or([
            new Eqn('added_to_inventory'),
            new Eq('added_to_inventory', 'no'),
          ]),
          new Not([new Eqn('status_from_manager')]),
          new Eq('storage', storage),
        ]),
      }),
    );
  };

  useEffect(() => {
    getUnidentifiedItems();
  }, [storage]);

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <ErrorView error={error} />;
  }

  return (
    <StorageContext.Provider
      value={{
        storage,
        setStorage,
      }}
    >
      {storage ? (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <MuiButton color="primary" fullWidth onClick={() => setStorage('')}>
              Select another storage
            </MuiButton>
          </Grid>

          {result?.length === 0 ? (
            <Grid item xs={12}>
              <Typography align="center" variant="h5">
                No more items
              </Typography>
            </Grid>
          ) : (
            result?.map((product) => (
              <Grid item key={product.id} xs={12}>
                <IdentifiedItemInfo
                  product={product}
                  refreshList={getUnidentifiedItems}
                />
              </Grid>
            ))
          )}
        </Grid>
      ) : (
        <SelectStorage />
      )}
    </StorageContext.Provider>
  );
};

export default UnidentifiedProducts;
