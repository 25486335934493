import useHttpDataStore from '../useHttpDatastore';
import {
  STATUS_NOT_PROCESSED,
  STATUS_IN_PROCESS,
  STATUS_PROCESSED,
  SHIPMENT_TYPE_WAREHOUSE,
  SHIPMENT_TYPE_POSTAL,
} from '../../pages/BagFillingApp/hooks';

export interface ReturnBag {
  bag_number: string;
  supplier: string;
  storage: string;
  dimensions: string;
  shipment_type: typeof SHIPMENT_TYPE_WAREHOUSE | typeof SHIPMENT_TYPE_POSTAL;
  process_status:
    | typeof STATUS_NOT_PROCESSED
    | typeof STATUS_IN_PROCESS
    | typeof STATUS_PROCESSED;
  created_at: string;
  updated_at: string;
}

const useReturnBagList = () => {
  return useHttpDataStore<ReturnBag>('/api/datastore/ReturnBag');
};

export { useReturnBagList };
