import { apiClientsDropship } from '../../../api-clients/api-clients-dropships';
import { apiClientsFba } from '../../../api-clients/api-clients-fba';
import { apiClientOrders } from '../../../api-clients/api-clients-orders';
import { apiClientsPickups } from '../../../api-clients/api-clients-pickups';
import { ApiClientReturnOrders } from '../../../api-clients/api-clients-return-orders';
import { Fba, Order, Position, ReturnOrder } from '../../../api-clients/types';
import { Marketplaces, RawDataParser } from '../RawDataParser';
import {
  alertMessage,
  getNotUsedPositions,
  getPositionsForReturn,
} from '../crm-deals-utils';
import {
  returnAmazonFbaOrderSchema,
  returnAmazonUpdateOrderSchema,
  returnEbayOrderSchema,
  returnOrderSchema,
} from '../validation-deals';

const createAmazonReturnFields = [
  'mpOrderNumber',
  'mpDateOpened',
  'positions',
  'mpName',
  'labelCreator',
  'returnLocation',
  'resolution',
  'problem',
  'dealStatus',
  'srName',
  'mpRetReason',
  'contentType',
  'rma',
];

export class CrmReturns {
  private api: ApiClientReturnOrders;
  private parser: RawDataParser;
  constructor() {
    this.api = new ApiClientReturnOrders();
    this.parser = new RawDataParser();
  }

  async create(
    deal: any,
    marketplace: Marketplaces,
    retReason: string,
    dealSchema: 'return' | 'return fba',
  ) {
    const order =
      dealSchema === 'return'
        ? await apiClientOrders.getByOrderNumber(deal.mpOrderNumber)
        : await apiClientsFba.getByOrderNumber(deal.mpOrderNumber);

    if (!order) throw new Error('There is no deal to create return for');

    if (dealSchema === 'return') {
      const dropship = await apiClientsDropship.getByMpOrderNumber(
        deal.mpOrderNumber,
      );
      const pickup = await apiClientsPickups.getByMpOrderNumber(
        deal.mpOrderNumber,
      );

      deal.srName = dropship?.srName || pickup?.srName || null;
      if (!deal.srName)
        throw new Error(
          "srName don't found, pickup or dropship deal doesn't exist for this mpOrderNumber.",
        );
    }
    deal.mpRetReason = retReason;

    const returnOrder = await this.api.getByMpOrderNumber(deal.mpOrderNumber);
    let createdDeal;
    if (marketplace === 'Amazon Shoptimistic') {
      if (dealSchema === 'return fba') {
        if (returnOrder) throw new Error('Already exist in crm');
        createdDeal = await this.createAmazonFba(deal, order as Fba);
      } else {
        createdDeal = returnOrder
          ? await this.updateAmazon(deal, returnOrder, order as Order)
          : await this.createAmazon(deal, order as Order);
      }
    } else if (
      marketplace === 'Ebay Rollun' ||
      marketplace === 'Ebay Plaisir'
    ) {
      if (returnOrder) throw new Error('Already exist in crm');
      createdDeal = await this.createEbay(deal, order as Order);
    }

    if (!createdDeal) throw new Error("Deal wasn't created");
    return createdDeal;
  }

  async createFromText(
    rawData: string,
    marketplace: Marketplaces,
    retReason: string,
    dealSchema: 'return' | 'return fba',
  ) {
    const deal = await this.parser.fromText(rawData, marketplace, dealSchema);
    return this.create(deal, marketplace, retReason, dealSchema);
  }

  private async createEbay(deal: any, order: Order) {
    const validation = returnEbayOrderSchema.validate({
      ...deal,
      positions: order.no_special_items_positions,
    });
    if (validation?.error) {
      throw new Error(validation?.error?.details[0].message);
    }

    const message = ['Created successful with id: '];
    const ebayReturnDeal = await this.api.createDeal({
      returnOrder: deal,
      order,
      positions: order.no_special_items_positions,
    });
    return { deal: ebayReturnDeal, message };
  }

  private async createAmazonFba(deal: any, order: Fba) {
    const validation = returnAmazonFbaOrderSchema.validate({
      ...deal,
      positions: order.no_special_items_positions,
    });
    if (validation?.error) {
      throw new Error(validation?.error?.details[0].message);
    }

    const message = ['Created successful with id: '];

    if (!deal.mpDateOpened) {
      message.unshift('Field mpDateOpened is missing, fill it manually.');
    }

    const amazonFbaReturnDeal = await this.api.createFbaDeal({
      returnOrder: deal,
      order,
      positions: order.no_special_items_positions,
    });
    return { deal: amazonFbaReturnDeal, message };
  }

  private async createAmazon(deal: any, order: Order) {
    const positions = [...deal.positions];
    const { result: resPos, isFullQuantity } = getPositionsForReturn(
      positions,
      order.positions,
    );
    const message = [];

    if (resPos.length && !isFullQuantity)
      message.push('User returns a part of order. Pay attention to this!');

    if (
      !deal.tracknumber &&
      deal.rma &&
      deal?.resolution !== 'ReturnlessRefund'
    ) {
      message.push('Missing tracknumber. ');
    }

    const validation =
      deal?.resolution === 'ReturnlessRefund'
        ? null
        : returnOrderSchema.validate({ ...deal, positions: resPos });

    if (validation?.error) {
      throw new Error(message + validation?.error?.details[0].message);
    }

    const alertMsg =
      deal?.resolution === 'ReturnlessRefund'
        ? null
        : alertMessage(deal, createAmazonReturnFields);

    if (alertMsg) message.push(alertMsg);

    const amazonReturnDeal = await this.api.createDeal({
      returnOrder: deal,
      order,
      positions: resPos,
    });
    message.push('Created successful with id: ');
    return { deal: amazonReturnDeal, message };
  }

  async updateAmazon(deal: any, returnOrder: ReturnOrder, order: Order) {
    const positions = [...deal.positions];
    const message = [];

    if (returnOrder.tracknumber !== deal.tracknumber) {
      const returnOrderTracknumber = returnOrder.tracknumber;
      returnOrder.tracknumber = deal.tracknumber;
      await this.api.update(returnOrder.id, returnOrder);
      message.push(
        `Tracknumber was updated from ${returnOrderTracknumber} to ${deal.tracknumber}. `,
      );
    }
    if (returnOrder.mpReturnNumber !== deal.mpReturnNumber) {
      const returnOrderMpReturnNumber = returnOrder.mpReturnNumber;
      returnOrder.mpReturnNumber = deal.mpReturnNumber;
      await this.api.update(returnOrder.id, returnOrder);
      message.push(
        `MpReturnNumber was updated from ${returnOrderMpReturnNumber} to ${deal.mpReturnNumber}. `,
      );
    }

    const returnOrderFromApiClient = await this.api.getById(returnOrder.id);

    const positionsFromOrder: Position[] = [...order.positions];
    const positionsFromReturn = [...returnOrderFromApiClient.positions];

    const positionsNotUsed = getNotUsedPositions(
      positionsFromOrder,
      positionsFromReturn,
    );
    const { result: resPos } = getPositionsForReturn(
      positions,
      positionsNotUsed,
    );

    // validation
    let validation =
      deal?.resolution === 'ReturnlessRefund'
        ? null
        : returnOrderSchema.validate({ ...deal, positions: resPos });

    if (!resPos.length)
      validation = returnAmazonUpdateOrderSchema.validate({
        ...deal,
        positions: resPos,
      });

    if (validation?.error) {
      throw new Error(message + validation?.error?.details[0].message);
    }

    // update items
    if (resPos.length)
      await this.api.addOrUpdateItems(returnOrderFromApiClient.id, resPos);

    message.push('Updated successful with id: ');
    return { deal: returnOrderFromApiClient, message };
  }
}

export const crmReturns = new CrmReturns();
