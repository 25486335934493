import HttpDatastore from 'rollun-ts-datastore';
import { Offer, Position, PositionDTO } from './types';
import { And, Eq, Query } from 'rollun-ts-rql';
import ApiClientsOffers from './api-clients-offers';

const datastore = new HttpDatastore<PositionDTO>(
  '/api/datastore/PositionsDataStore',
);

export default class ApiClientsPositions {
  apiOffers: ApiClientsOffers;
  constructor() {
    this.apiOffers = new ApiClientsOffers();
  }

  async getByQuery(query: Query): Promise<Position[]> {
    const positionsDTO = await datastore.query(query);
    if (positionsDTO.length === 0) {
      return [];
    }

    const offerIds = positionsDTO.map((positionDTO) => positionDTO.OfferId);
    const offers = await this.apiOffers.getByIds(offerIds);

    const positions = positionsDTO.map((positionDTO) =>
      this.convertDTOtoModel(positionDTO, offers),
    );
    return positions;
  }

  async getById(id: string): Promise<Position> {
    const positionDTO = await datastore.read(id);
    const offers = await this.apiOffers.getByIds([positionDTO.OfferId]);
    const position = this.convertDTOtoModel(positionDTO, offers);

    return position;
  }

  async getByDealId(dealId: string): Promise<Position[]> {
    const positionsDTO = await datastore.query(
      new Query().setQuery(new Eq('DealId', dealId)),
    );

    if (positionsDTO.length === 0) {
      return [];
    }

    const offerIds = positionsDTO.map((positionDTO) => positionDTO.OfferId);
    const offers = await this.apiOffers.getByIds(offerIds);

    const positions = positionsDTO.map((positionDTO) =>
      this.convertDTOtoModel(positionDTO, offers),
    );
    return positions;
  }

  async add(
    dealId: string,
    position: Omit<Position, 'offerId' | 'dealId' | 'id'>,
  ): Promise<Position> {
    const personalOffer = await this.apiOffers.create(position);
    const positionDTO = this.convertModelToDTO({
      ...position,
      offerId: personalOffer.id,
      dealId,
    });

    return this.convertDTOtoModel(await datastore.create(positionDTO), [
      personalOffer,
    ]);
  }

  async delete(dealId: string, positionId: string): Promise<void> {
    const [position] = await datastore.query(
      new Query().setQuery(
        new And([new Eq('DealId', dealId), new Eq('Id', positionId)]),
      ),
    );

    position && (await datastore.delete(position.Id));
  }

  async update(position: Position): Promise<void> {
    const positionDTO = this.convertModelToDTOWithId(position);
    await datastore.update(positionDTO);
  }

  async updateWithOffer(position: Position & Offer) {
    await this.apiOffers.update(position.offerId, position);
  }

  convertDTOtoModel(positionDTO: PositionDTO, offers: Offer[]): Position {
    const {
      Id: id,
      DealId: dealId,
      OfferId: offerId,
      Count: quantity,
      Cost: cost,
    } = positionDTO;

    // TODO if undefined
    const offerData = offers.find((item) => item.id === offerId);
    if (!offerData)
      throw new Error('ApiClientsPositions: Offer data undefined');
    const { article, name } = offerData;

    const position: Position = {
      id,
      dealId,
      offerId,
      quantity,
      cost,
      article,
      name,
    };
    return position;
  }

  convertModelToDTOWithId(position: Position): PositionDTO {
    const { dealId, offerId, quantity, cost, id } = position;
    return {
      DealId: dealId,
      OfferId: offerId,
      Count: quantity,
      Cost: cost,
      Id: id,
    };
  }

  convertModelToDTO(position: Omit<Position, 'id'>): Omit<PositionDTO, 'id'> {
    const { dealId, offerId, quantity, cost } = position;
    return {
      DealId: dealId,
      OfferId: offerId,
      Count: quantity,
      Cost: cost,
    } as Omit<PositionDTO, 'id'>;
  }
}
