import Query from 'rollun-ts-rql/dist/Query';
import Eq from 'rollun-ts-rql/dist/nodes/scalarNodes/Eq';
import { And, Not, Select } from 'rollun-ts-rql';
import HttpDatastore from 'rollun-ts-datastore';
import {
  ExpandedItemProps,
  ProductsDimensionsRow,
  RollunCatalogRow,
} from './types';
import { RollunIdWithCsnItem } from '../../../hooks/datastores';
import { apiClientsPickups } from '../../CRMDeals/components/api-clients/api-clients-pickups';
import { apiClients } from '../../CRMDeals/components/api-clients/api-clients-clients';
import { Pickup } from '../../CRMDeals/components/api-clients/types';

const dimensionsStore = new HttpDatastore<ProductsDimensionsRow>(
  '/api/datastore/DimensionStore',
);
const rollunCatalog = new HttpDatastore<RollunCatalogRow>(
  '/api/datastore/CatalogDataStore',
);

const RidWithCsn = new HttpDatastore<RollunIdWithCsnItem>(
  '/api/datastore/RollunIdWithCsnView',
);

const allowedSenders = ['pickup_wi', 'pickup_cn'];

export const getCsnsByRid = async (rid: string) => {
  const csns = await RidWithCsn.query(
    new Query({
      query: new And([
        new Eq('rid', rid),
        new Not([new Eq('sr_name', 'RollunOffice')]),
      ]),
      select: new Select(['csn', 'sr_name']),
    }),
  );

  return csns;
};

export const getIsContractorFromCA = async (pickup: Pickup) => {
  const client = await apiClients.getById(pickup.contractor);
  return client.state === 'CA';
};

export const getExpandedItemProps = async (
  rollun_id: string,
  shipping_label_id: string,
): Promise<ExpandedItemProps> => {
  let itemCatalogProps: ExpandedItemProps = {
    id: rollun_id,
    csns: [],
  };
  const catalogItem = await rollunCatalog.read(rollun_id);
  if (!catalogItem) throw new Error(`No item with RID ${rollun_id} in catalog`);
  itemCatalogProps = {
    ...itemCatalogProps,
    brand_id: catalogItem.brand_id,
    MPN: catalogItem.manufacturer_part_number,
  };
  try {
    const dimensionsItem = await dimensionsStore.read(rollun_id);
    if (dimensionsItem)
      itemCatalogProps = {
        ...itemCatalogProps,
        ...dimensionsItem,
        photo: dimensionsItem.image == '-1' ? undefined : dimensionsItem.image,
      };
  } catch (err) {
    console.log('dimensions err', err);
  }

  try {
    const csns = await getCsnsByRid(rollun_id);
    itemCatalogProps = {
      ...itemCatalogProps,
      csns,
    };
  } catch (err) {
    console.log('supplier catalog err', err);
  }

  if (itemCatalogProps?.prop_65 === '1') {
    try {
      const [pickup] =
        (await apiClientsPickups.getByQuery(
          new Query().setQuery(new Eq('Tracknumber', shipping_label_id)),
        )) || [];

      if (!pickup)
        throw new Error("Pickup with this tracknumber doesn't exist");

      if (!allowedSenders.some((sender) => sender === pickup.sender))
        throw new Error('Sender not allowed for prop65');

      const isProp65AlertShowed = await getIsContractorFromCA(pickup);

      itemCatalogProps = {
        ...itemCatalogProps,
        isProp65AlertShowed,
      };
    } catch (err) {
      console.log('contractor err', err);
    }
  }

  return itemCatalogProps;
};
