import HttpDatastore from 'rollun-ts-datastore';
import { Order, OrderDTO, Position, SPECIAL_ITEMS_IDS, Client } from './types';
import ApiClientsPositions from './api-clients-position';
import { Query, Eq } from 'rollun-ts-rql';
import ApiClients from './api-clients-clients';
import { compareStringNumbers } from '../common/utils/compareStringNumbers';
import { ParsedOrder } from '../GenericDeal/utils/RawDataParser';

const datastore = new HttpDatastore<OrderDTO>('/api/datastore/OrdersDataStore');

export default class ApiClientsOrders {
  apiPositions: ApiClientsPositions;
  apiClients: ApiClients;
  constructor() {
    this.apiPositions = new ApiClientsPositions();
    this.apiClients = new ApiClients();
  }

  async getById(id: string): Promise<Order> {
    const orderDto = await datastore.read(id);

    if (!orderDto) {
      throw new Error(`Order with id ${id} not found`);
    }

    const positions = this.apiPositions.getByDealId(id);
    const client = this.apiClients.getById(orderDto.Contractor);

    const order = await Promise.all([positions, client]).then((data) => {
      return this.convertDTOtoModel(orderDto, ...data);
    });

    return order;
  }

  async getByOrderNumber(mpOrderNumber: string): Promise<Order | null> {
    const [orderDto] = await datastore.query(
      new Query().setQuery(new Eq('MpOrderNumber', mpOrderNumber)),
    );

    if (!orderDto) {
      return null;
    }

    const positions = this.apiPositions.getByDealId(orderDto.Id);
    const client = this.apiClients.getById(orderDto.Contractor);

    const order = await Promise.all([positions, client]).then((data) => {
      return this.convertDTOtoModel(orderDto, ...data);
    });

    return order;
  }

  async getByQuery(query: Query): Promise<Order[]> {
    const ordersDto = await datastore.query(query);
    return Promise.all(ordersDto.map((orderDto) => this.getById(orderDto.Id)));
  }

  async create(data: Omit<Order, 'id'>): Promise<Order> {
    const pickupDTO = this.convertModelToDTO(data);
    const pickup = await datastore.create(pickupDTO);

    if (data.positions) {
      await this.addItems(pickup.Id, data.positions);
    }

    return this.getById(pickup.Id);
  }

  async createDeal(deal: ParsedOrder): Promise<Order> {
    const client = await this.apiClients.create(deal.client as Client);
    deal.order.contractor = client.id;

    const orderDTO = await datastore.create(this.convertModelToDTO(deal.order));
    const positions =
      (await Promise.all(
        deal.positions.map((position) =>
          this.apiPositions.add(orderDTO.Id, position),
        ),
      )) || [];
    const order = this.convertDTOtoModel(orderDTO, positions, client);

    return order;
  }

  async addOrUpdateItems(
    id: string,
    items: Omit<Position, 'offerId' | 'dealId' | 'id'>[],
  ) {
    const deal = await this.getById(id);
    const positions = deal.positions;

    const tempSortedItems: Position[] = [];

    for (let i = 0; i < positions.length; i++) {
      const position = { ...positions[i] };

      if (
        tempSortedItems.filter(
          (item) =>
            item.article === position.article &&
            compareStringNumbers(item.cost, position.cost) &&
            item.name === position.name,
        ).length
      )
        continue;

      tempSortedItems.push(position);

      const sameItems = items.filter(
        (item) =>
          item.article === position.article &&
          compareStringNumbers(item.cost, position.cost) &&
          item.name === position.name,
      );
      if (!sameItems.length) continue;

      const allQuantity = sameItems.reduce(
        (prev, cur) => prev + +cur.quantity,
        0,
      );
      position.quantity = +position.quantity + allQuantity;

      await this.apiPositions.update(position);
    }

    const filteredItems = items.filter((item) => {
      for (let i = 0; i < tempSortedItems.length; i++) {
        const position = { ...tempSortedItems[i] };
        if (
          item.article === position.article &&
          compareStringNumbers(item.cost, position.cost) &&
          item.name === position.name
        )
          return false;
      }
      return true;
    });

    if (filteredItems.length) await this.addItems(id, filteredItems);
  }

  async update(id: string, data: Partial<Omit<Order, 'id' | 'positions'>>) {
    await datastore.update({
      ...this.convertModelToDTO(data),
      Id: id,
    });
  }

  async updateClient(id: string, data: Client) {
    await this.apiClients.update(id, data);
  }

  async updateClientByOrderId(orderId: string, data: Client) {
    const { Contractor: clientId } = await datastore.read(orderId);
    await this.apiClients.update(clientId, data);
  }

  async deleteItems(id: string, itemIds: string[]): Promise<void> {
    await Promise.all(
      itemIds.map((itemId) => this.apiPositions.delete(id, itemId)),
    );
  }

  async addItems(
    id: string,
    items: Omit<Position, 'offerId' | 'dealId' | 'id'>[],
  ): Promise<void> {
    for (const item of items) {
      await this.apiPositions.add(id, item);
    }
  }

  convertDTOtoModel(
    orderDTO: OrderDTO,
    positions: Position[],
    client: Client,
  ): Order {
    const no_special_items_positions = positions.filter(
      (position) => !SPECIAL_ITEMS_IDS.includes(position.article),
    );

    return {
      archiveScenario: orderDTO.ArchiveScenario,
      cogs: orderDTO.Cogs,
      contractor: orderDTO.Contractor,
      dateCrPayed: orderDTO.DateCrPayed,
      dateDeliverBy: orderDTO.DateDeliverBy,
      dateShipBy: orderDTO.DateShipBy,
      description: orderDTO.Description,
      finalPrice: orderDTO.FinalPrice,
      howToBuy3: orderDTO.HowToBuy3,
      howToBuy3BestShipping: orderDTO.HowToBuy3BestShipping,
      howToBuy3Result: orderDTO.HowToBuy3Result,
      howToBuy4: orderDTO.HowToBuy4,
      howToBuy4BestShipping: orderDTO.HowToBuy4BestShipping,
      howToBuy4Result: orderDTO.HowToBuy4Result,
      id: orderDTO.Id,
      isDraft: orderDTO.IsDraft,
      isPaid: orderDTO.IsPaid,
      mpClientId: orderDTO.MpClientId,
      mpName: orderDTO.MpName,
      mpOrderNote: orderDTO.MpOrderNote,
      mpOrderNumber: orderDTO.MpOrderNumber,
      mpShipMethod: orderDTO.MpShipMethod,
      mpShipTemplate: orderDTO.MpShipTemplate,
      name: orderDTO.Name,
      owner: orderDTO.Owner,
      problem: orderDTO.Problem,
      problemDescription: orderDTO.ProblemDescription,
      profit: orderDTO.Profit,
      status: orderDTO.Status,
      timeCreated: orderDTO.TimeCreated,
      timeUpdated: orderDTO.TimeUpdated,
      tracknumber: orderDTO.Tracknumber,
      tracknumbersInMp: orderDTO.TracknumbersInMp,
      sender: orderDTO.Sender,
      positions: positions,
      no_special_items_positions: no_special_items_positions,
      statusName: orderDTO.StatusName,
      client: client,
    };
  }

  convertModelToDTO(
    order: Partial<Omit<Order, 'id' | 'positions' | 'client'>>,
  ): Partial<Omit<OrderDTO, 'Id'>> {
    return {
      ArchiveScenario: order.archiveScenario,
      Cogs: order.cogs,
      Contractor: order.contractor,
      DateCrPayed: order.dateCrPayed,
      DateDeliverBy: order.dateDeliverBy,
      DateShipBy: order.dateShipBy,
      Description: order.description,
      FinalPrice: order.finalPrice,
      HowToBuy3: order.howToBuy3,
      HowToBuy3BestShipping: order.howToBuy3BestShipping,
      HowToBuy3Result: order.howToBuy3Result,
      HowToBuy4: order.howToBuy4,
      HowToBuy4BestShipping: order.howToBuy4BestShipping,
      HowToBuy4Result: order.howToBuy4Result,
      IsDraft: order.isDraft,
      IsPaid: order.isPaid,
      MpClientId: order.mpClientId,
      MpName: order.mpName,
      MpOrderNote: order.mpOrderNote,
      MpOrderNumber: order.mpOrderNumber,
      MpShipMethod: order.mpShipMethod,
      MpShipTemplate: order.mpShipTemplate,
      Name: order.name,
      Owner: order.owner,
      Problem: order.problem,
      ProblemDescription: order.problemDescription,
      Profit: order.profit,
      Status: order.status,
      TimeCreated: order.timeCreated,
      TimeUpdated: order.timeUpdated,
      Tracknumber: order.tracknumber,
      TracknumbersInMp: order.tracknumbersInMp,
      StatusName: order.statusName,
      Sender: order.sender,
    };
  }
}

export const apiClientOrders = new ApiClientsOrders();
