import HttpDatastore from 'rollun-ts-datastore';
import { Fba, FbaDTO, Position, SPECIAL_ITEMS_IDS } from './types';
import { Query, Eq } from 'rollun-ts-rql';
import ApiClients from './api-clients-clients';
import ApiClientsPositions from './api-clients-position';

const datastore = new HttpDatastore<FbaDTO>('/api/datastore/FbaDataStore');
const ordersDatastore = new HttpDatastore<FbaDTO>(
  '/api/datastore/FbaOrdersDataStore',
);

export class ApiClientsFba {
  apiPositions: ApiClientsPositions;
  apiClients: ApiClients;
  constructor() {
    this.apiPositions = new ApiClientsPositions();
    this.apiClients = new ApiClients();
  }

  async getById(id: string): Promise<Fba> {
    const fbaDTO = await datastore.read(id);

    if (!fbaDTO) {
      throw new Error(`Fba with id ${id} not found`);
    }

    const positions = await this.apiPositions.getByDealId(id);

    const fba = this.convertDTOtoModel(fbaDTO, positions);
    return fba;
  }

  async update(id: string, data: Partial<Fba>) {
    await datastore.update({
      ...this.convertModelToDTO({ ...data, id }),
    });
  }

  async create(data: Omit<Fba, 'id'>): Promise<Fba> {
    const pickupDTO = this.convertModelToDTO(data);
    const pickup = await datastore.create(pickupDTO);

    return this.getById(pickup.Id);
  }

  async getByOrderNumber(mpOrderNumber: string): Promise<Fba | null> {
    const [fbaDto] = await ordersDatastore.query(
      new Query().setQuery(new Eq('MpOrderNumber', mpOrderNumber)),
    );

    if (!fbaDto) {
      return null;
    }

    const positions = await this.apiPositions.getByDealId(fbaDto.Id);

    const fba = this.convertDTOtoModel(fbaDto, positions);
    return fba;
  }

  convertDTOtoModel(fbaDTO: FbaDTO, positions: Position[]): Fba {
    const no_special_items_positions = positions.filter(
      (position) => !SPECIAL_ITEMS_IDS.includes(position.article),
    );

    return {
      id: fbaDTO.Id,
      dataZakaza: fbaDTO.DataZakaza,
      activeWorkflow: fbaDTO.ActiveWorkflow,
      shipmentId: fbaDTO.ShipmentId,
      trekNomer: fbaDTO.TrekNomer,
      edd: fbaDTO.Edd,
      prepTsentrPts: fbaDTO.PrepTsentrPts,
      prepCenterWorkOrderId: fbaDTO.PrepCenterWorkOrderId,
      dataPostupleniyaVPrepTsentr: fbaDTO.DataPostupleniyaVPrepTsentr,
      primechaniePts: fbaDTO.PrimechaniePts,
      nomerSdelkiVDelovode: fbaDTO.NomerSdelkiVDelovode,
      dataOtgruzki: fbaDTO.DataOtgruzki,
      srOrderNumber: fbaDTO.SrOrderNumber,
      srName: fbaDTO.SrName,
      type: fbaDTO.Type,
      srPaymentCard: fbaDTO.SrPaymentCard,
      mpUaId: fbaDTO.MpUaId,
      status: fbaDTO.Status,
      statusName: fbaDTO.StatusName,
      timeCreated: fbaDTO.TimeCreated,
      timeUpdated: fbaDTO.TimeUpdated,
      positions: positions,
      no_special_items_positions: no_special_items_positions,
    } as Fba;
  }

  convertModelToDTO(fba: Partial<Fba>): FbaDTO {
    return {
      Id: fba.id,
      DataZakaza: fba.dataZakaza,
      ActiveWorkflow: fba.activeWorkflow,
      ShipmentId: fba.shipmentId,
      TrekNomer: fba.trekNomer,
      Edd: fba.edd,
      PrepTsentrPts: fba.prepTsentrPts,
      PrepCenterWorkOrderId: fba.prepCenterWorkOrderId,
      DataPostupleniyaVPrepTsentr: fba.dataPostupleniyaVPrepTsentr,
      PrimechaniePts: fba.primechaniePts,
      NomerSdelkiVDelovode: fba.nomerSdelkiVDelovode,
      DataOtgruzki: fba.dataOtgruzki,
      SrOrderNumber: fba.srOrderNumber,
      SrName: fba.srName,
      Type: fba.type,
      SrPaymentCard: fba.srPaymentCard,
      MpUaId: fba.mpUaId,
      Status: fba.status,
      StatusName: fba.statusName,
      TimeCreated: fba.timeCreated,
      TimeUpdated: fba.timeUpdated,
    } as FbaDTO;
  }
}

export const apiClientsFba = new ApiClientsFba();
