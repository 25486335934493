import React, { FC } from 'react';
import PasteUploaderFieldOptionEdit from './PasteUploaderFieldOption.edit';
import Typography from '@material-ui/core/Typography';
import { randomString } from 'rollun-ts-utils';
import {
  CopyPastePasteAfterField,
  CopyPasteUploaderParams,
} from '../../../../../Table/components/CopyPasteUploaders/CopyPasteUploader';

interface PasteUploaderFieldOptionsEditProps {
  pasteUploaderParams: CopyPasteUploaderParams;
  onUpdate: (value: any, path: string) => void;
}

const PasteUploaderFieldOptionsEdit: FC<PasteUploaderFieldOptionsEditProps> = ({
  pasteUploaderParams,
  onUpdate,
}) => {
  return (
    <>
      <PasteUploaderFieldOptionEdit
        title="Field names:"
        emptyTitle="No fields names..."
        value={pasteUploaderParams.fieldNames}
        name="fieldNames"
        onUpdate={onUpdate}
      />
      <PasteUploaderFieldOptionEdit
        title="Field names compare by:"
        emptyTitle="No fields names...(default is all fields)"
        value={pasteUploaderParams.fieldNamesToCompare}
        name="fieldNamesToCompare"
        onUpdate={onUpdate}
      />
      <PasteUploaderFieldOptionEdit
        title="Field names to replace with null if empty:"
        emptyTitle="No fields names...(default is all fields)"
        value={pasteUploaderParams.fieldNamesToReplaceWithNullIfEmpty}
        name="fieldNamesToReplaceWithNullIfEmpty"
        onUpdate={onUpdate}
      />
      <PasteUploaderFieldOptionEdit
        title="Paste after field with delimiter:"
        emptyTitle="No fields names...(default is none)"
        value={pasteUploaderParams.pasteAfterField}
        name="pasteAfterField"
        defaultValue={{
          fieldName: 'field_name',
          value: 'value',
          delimiter: '#',
        }}
        render={({ fieldName, value, delimiter }: CopyPastePasteAfterField) => (
          <Typography key={randomString(3)}>
            {`${fieldName}${delimiter}${value}`}
          </Typography>
        )}
        onUpdate={onUpdate}
      />
    </>
  );
};

export default PasteUploaderFieldOptionsEdit;
