import React from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import MuiTooltip from '../../../UI/MuiTooltip';

export interface CardViewProps extends CardData {
  onCardSelect: (id: string) => void;
}

export interface CardData {
  id: string;
  enable: '0' | '1'; // backend quirk
  name?: string;
  index: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const CardView: React.FC<CardViewProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const onDBClick = () => {
    if (props.enable === '0') {
      props.onCardSelect(props.id);
    }
  };
  return (
    <MuiTooltip title="Double click to select this card">
      <Card
        onDoubleClick={onDBClick}
        className={classes.root}
        style={{
          color:
            props.enable === '1'
              ? theme.palette.getContrastText(theme.palette.info.main)
              : '',
          backgroundColor: props.enable === '1' ? theme.palette.info.main : '',
        }}
      >
        <Typography variant="h5">XXXXXXXXXX{props.id}</Typography>
        <Typography variant="body1">
          {props.name ? props.name : `Card ${props.index}`}
        </Typography>
      </Card>
    </MuiTooltip>
  );
};

export default CardView;
