import React, { FC } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useGetAllVersionsOfSecret } from '../hooks/useGetAllVersionsOfSecret';
import { ErrorView, Spinner } from '../../../UI/index';
import { useGetSecretById } from '../hooks/useGetSecretById';

interface AllSecretVersionsProps {
  id: string;
}

const ClickToView: FC<{ id: string; version?: number }> = ({ id, version }) => {
  const versionString = version ? `${version}` : undefined;
  const { data: value, isLoading, error } = useGetSecretById(id, versionString);
  const [show, setShow] = React.useState(false);

  return (
    <Box
      onClick={() => {
        if (!show) setShow(true);
      }}
      style={{
        fontSize: 15,
        cursor: 'pointer',
      }}
    >
      {show
        ? isLoading
          ? 'Loading...'
          : error
          ? (error as Error).message
          : value
          ? value.value
          : '*****'
        : '*****'}
    </Box>
  );
};

const AllSecretVersions: FC<AllSecretVersionsProps> = ({ id }) => {
  const { data: secretVersions, error, isLoading } = useGetAllVersionsOfSecret(
    id,
  );

  if (error) {
    return (
      <ErrorView
        error={{
          text: `Error while fetching data for secret: ${id}`,
          code: 500,
        }}
      />
    );
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="left">
            <Box fontWeight="fontWeightBold" fontSize="15px">
              Id
            </Box>
          </TableCell>
          <TableCell align="left">
            <Box fontWeight="fontWeightBold" fontSize="15px">
              Value
            </Box>
          </TableCell>
          <TableCell align="left">
            <Box fontWeight="fontWeightBold" fontSize="15px">
              Version
            </Box>
          </TableCell>
          <TableCell align="left">
            <Box fontWeight="fontWeightBold" fontSize="15px">
              Created At
            </Box>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(secretVersions || []).map(({ version, id, createdAt }) => (
          <TableRow key={`${id}-${version}`}>
            <TableCell>
              <Box fontSize="15px">{id}</Box>
            </TableCell>
            <TableCell>
              <ClickToView id={id} version={version} />
            </TableCell>
            <TableCell>
              <Box fontSize="15px">{version}</Box>
            </TableCell>
            <TableCell>
              <Box fontSize="15px">
                {new Date(createdAt)
                  .toISOString()
                  .slice(0, 19)
                  .replace('T', ' ')}
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default AllSecretVersions;
