import React, { FC, useEffect } from 'react';
import {
  ReturnedItem,
  RollunIdWithCsnItem,
  useRollunIdWithCsnViewDatastore,
} from '../../../hooks/datastores';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Theme,
  Typography,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { makeStyles } from '@material-ui/core/styles';
import { In, Query } from 'rollun-ts-rql';

interface ProcessedItemsProps {
  items?: ReturnedItem[] | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  processedCard: {
    cursor: 'not-allowed',
    backgroundColor: theme.palette.grey['300'],
  },
  doneIcon: {
    color: theme.palette.success.main,
  },
}));

const ProcessedItems: FC<ProcessedItemsProps> = ({ items }) => {
  const classes = useStyles();
  const [{ result, error }, dispatch] = useRollunIdWithCsnViewDatastore();

  const getProcessedItemsWithInfo = (
    items: ReturnedItem[],
  ): RollunIdWithCsnItem[] => {
    if (!result) {
      return [];
    }

    return items
      .map((item) => result.find(({ rid }) => rid === item.rid))
      .filter((item) => item !== undefined) as RollunIdWithCsnItem[];
  };

  useEffect(() => {
    if (!items || items.length === 0) {
      return;
    }

    dispatch(
      'QUERY',
      new Query({
        query: new In(
          'rid',
          items.map((item) => item.rid),
        ),
      }),
    );
  }, [items]);

  if (error) {
    return (
      <Grid key="error_processed_items" item xs={12}>
        Error getting processed items
      </Grid>
    );
  }

  if (!items || !result) {
    return null;
  }

  return (
    <>
      <Typography variant="h4">Processed:</Typography>
      {getProcessedItemsWithInfo(items).map((item, index) => (
        <Grid key={index} item xs={12}>
          <Card className={classes.processedCard}>
            <CardContent>
              <Box display="flex" justifyContent="space-between" height="100%">
                <Box>
                  <Typography variant="h4">{item.csn}</Typography>
                  <Typography variant="body1">{item.brand}</Typography>
                  <Typography variant="body2">{item.mpn}</Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <DoneIcon className={classes.doneIcon} fontSize="large" />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </>
  );
};

export default ProcessedItems;
