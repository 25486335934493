import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import MuiButton from '../../../UI/MuiButton';
import ReturnedItems from './ReturnedItems';
import ReturnAppContext from '../context';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { useReturnsDatastore } from '../../../hooks/datastores';
import { Eq, Limit, Query } from 'rollun-ts-rql';

const LabelWasFound = () => {
  const [searchInput, setSearchInput] = useState('');
  const [search, setSearch] = useState('');
  const context = useContext(ReturnAppContext);
  const [returnInfo, returnsDispatch] = useReturnsDatastore();

  useEffect(() => {
    returnsDispatch(
      'QUERY',
      new Query({
        query: new Eq('track_number', context.label),
        limit: new Limit(1),
      }),
    );
  }, []);

  return (
    <Grid container spacing={1}>
      {returnInfo.result?.[0]?.mp_return_reason === 'damaged' && (
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            All Items from this return, has to be put in damaged inventory
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <MuiButton
          color="primary"
          id="open-scanner"
          fullWidth
          onClick={() => context.setPage('notFoundItem')}
        >
          Product is not found
        </MuiButton>
      </Grid>
      <Grid item xs={9}>
        <TextField
          variant="outlined"
          label="Enter product ID and click search"
          fullWidth
          value={searchInput}
          onChange={({ target: { value } }) => setSearchInput(value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" title="scan">
                <IconButton onClick={() => context.setPage('scanItem')}>
                  <PhotoCameraIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <MuiButton
          style={{ height: '52px' }}
          fullWidth
          color="success"
          id="open-scanner"
          onClick={() => setSearch(searchInput)}
        >
          search
        </MuiButton>
      </Grid>
      <ReturnedItems search={search} />
    </Grid>
  );
};

export default LabelWasFound;
