import { useRollunIdWithCsnViewDatastore } from '../../../hooks/datastores';
import { useEffect } from 'react';
import { Eq, Query } from 'rollun-ts-rql';

const useItemInfo = (rid: string) => {
  const [
    { status, result, error },
    dispatch,
  ] = useRollunIdWithCsnViewDatastore();

  useEffect(() => {
    dispatch(
      'QUERY',
      new Query({
        query: new Eq('rid', rid),
      }),
    );
  }, [rid]);

  return { status, result: result ? result[0] : null, error };
};

export { useItemInfo };
