import React, { FormEvent } from 'react';
import { TextField, Box, useTheme } from '@material-ui/core';
import MuiButton from '../../../UI/MuiButton';
import Axios from 'axios';

const deleteCookie = (cookieName: string) => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

    if (name === cookieName) {
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }
};

const LoginForm = () => {
  const theme = useTheme();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    deleteCookie('PHPSESSID');
    const formData = new FormData(e.currentTarget);

    try {
      const res = await Axios.head('/api/datastore/UserFrontConfig', {
        headers: {
          Authorization: `Basic ${btoa(
            `${formData.get('username')}:${formData.get('password')}`,
          )}`,
        },
      });

      const cookie = JSON.parse(res.headers.get('set-cookie') || '');

      if (!cookie || !document.cookie) {
        throw new Error('No cookie set on server');
      }

      window.location.assign('https://rollun.net');
    } catch (e) {
      alert(
        'Could not log in with username and password. Try logging with Google!' +
          ' ' +
          (e as Error).message,
      );
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{ width: '100%', marginTop: theme.spacing(25) }}
    >
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        width="100%"
        style={{ gap: theme.spacing(2) }}
      >
        <TextField
          fullWidth
          variant="outlined"
          id="user-name"
          name="username"
          type="text"
          label="Username"
        />
        <TextField
          fullWidth
          variant="outlined"
          id="password"
          name="password"
          type="password"
          label="Password"
        />
        <MuiButton type="submit" fullWidth color="primary">
          Login
        </MuiButton>
      </Box>
    </form>
  );
};

export default LoginForm;
