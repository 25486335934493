import React, { FC, useEffect, useRef, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import {
  alpha,
  Grow,
  InputBase,
  List,
  ListItem,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core';
import { zIndexes } from '../themeProvider';
import { includesCaseInsensitive } from '../pages/ReturnApp/utils';
import { Link } from 'react-router-dom';

const maxWidth = 250;

const useStyles = makeStyles((theme: Theme) => ({
  search: {
    color: 'white',
    position: 'relative' as const,
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    width: '100%',
    maxWidth,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
    zIndex: zIndexes.biggest + 1,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute' as const,
    pointerEvents: 'none' as const,
    [theme.breakpoints.down('sm')]: {
      color: 'white',
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    marginTop: theme.spacing(0.3),
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: maxWidth - 100,
    '&:focus': {
      width: maxWidth,
    },
  },
  optionList: {
    position: 'absolute',
    maxHeight: '250px',
    maxWidth,
    minWidth: maxWidth,
    overflowY: 'scroll',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      width: maxWidth,
    },
  },
  item: {
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
}));

type Options = { value: string; label: string }[];

interface InputWithSelectProps {
  options: Options;
  value: string;
  label: string;
}

const InputWithSelect: FC<InputWithSelectProps> = ({
  options,
  value = '',
  label = 'Type something...',
}) => {
  const classes = useStyles();
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const [filteredOptions, setFilteredOptions] = useState<Options>(
    options.filter(({ label, value }) => label && value),
  );
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (timeoutRef?.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleLoseFocus = () => {
    setIsOptionsOpen(false);
    setCurrentValue('');
  };

  const handleBlur = () => {
    timeoutRef.current = setTimeout(handleLoseFocus, 100);
  };

  const handleFocus = () => {
    setIsOptionsOpen(true);
    if (timeoutRef?.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(e.target.value);
    setFilteredOptions(
      options.filter(
        ({ label, value }) =>
          label &&
          value &&
          (includesCaseInsensitive(label, currentValue) ||
            includesCaseInsensitive(value, currentValue)),
      ),
    );
  };

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={label}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        onChange={handleInput}
        value={currentValue}
        inputProps={{ 'aria-label': 'search' }}
      />
      <Grow in={isOptionsOpen} mountOnEnter unmountOnExit>
        <Paper className={classes.optionList}>
          <List>
            {filteredOptions.length === 0 ? (
              <ListItem>No options</ListItem>
            ) : (
              filteredOptions.map(({ value, label }) => (
                <Link key={value} to={value} color="primary">
                  <ListItem className={classes.item}>{label}</ListItem>
                </Link>
              ))
            )}
          </List>
        </Paper>
      </Grow>
    </div>
  );
};

export default InputWithSelect;
