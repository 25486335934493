import { useReturnBagItems } from '../../../hooks/datastores';
import { useEffect } from 'react';
import { Eq, Query } from 'rollun-ts-rql';

const useBagInfo = (bagId: string) => {
  const [{ status, result, error }, dispatch] = useReturnBagItems();

  useEffect(() => {
    dispatch(
      'QUERY',
      new Query({
        query: new Eq('bag', bagId),
      }),
    );
  }, [bagId]);

  return {
    status,
    result,
    error,
  };
};

export { useBagInfo };
