import AbstractEditor, { IEditorProps } from './AbstractEditor';
import React, { ChangeEvent } from 'react';
import { TextField } from '@material-ui/core';

interface IState {
  date: Date;
}

export default class DatetimeEditor extends AbstractEditor<IState> {
  constructor(props: IEditorProps) {
    super(props);
    this.state = {
      date: new Date(parseInt(props.value, 10) * 1000) || new Date(),
    };
  }

  handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    this.setState({ date: new Date(e.target.value) });

  render() {
    return (
      <TextField
        ref={this.inputRef}
        type={'datetime-local'}
        value={this.toDatetimeLocal(this.state.date)}
        onChange={this.handleChange}
        style={{ margin: '0 auto' }}
      />
    );
  }

  getValue() {
    return {
      [this.props.column.key]: String(this.state.date.getTime() / 1000),
    };
  }

  toDatetimeLocal(date: Date) {
    const ten = (i: number) => (i < 10 ? '0' : '') + i,
      YYYY = date.getFullYear(),
      MM = ten(date.getMonth() + 1),
      DD = ten(date.getDate()),
      HH = ten(date.getHours()),
      II = ten(date.getMinutes()),
      SS = ten(date.getSeconds());
    return `${YYYY}-${MM}-${DD}T${HH}:${II}:${SS}`;
  }
}
