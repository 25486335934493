import React, { FC, useContext, useState } from 'react';
import Box from '@material-ui/core/Box';
import {
  makeStyles,
  Theme,
  useMediaQuery,
  Typography,
  Link,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import MuiButton from '../../../../../UI/MuiButton';
import { LeftMenuContext } from '../../../../../layout/AppContainer/AppContainer';
import { Spinner } from '../../../../../UI/index';
import { DealType } from '..';
import { useGetProblemDeals } from '../hooks/useGetProblemDeals';

const useStyles = makeStyles<Theme, { isLeftMenuShown: boolean }>((theme) => ({
  root: {
    backgroundColor: 'white',
    display: 'flex',
    gap: theme.spacing(1),
    transition: (props) =>
      props.isLeftMenuShown
        ? 'left 0.5s cubic-bezier(0, 0, 0.2, 1) 0ms, width 0.5s cubic-bezier(0, 0, 0.2, 1) 0ms'
        : 'left 0.5s cubic-bezier(0.4, 0, 0.6, 1) 0ms, width 0.5s cubic-bezier(0.4, 0, 0.6, 1) 0ms', // same as in LeftMenu
  },
}));

const MarketplaceProblems: FC<{
  name: string;
  shortName: string;
  problemDeals: Record<DealType, { id: string; problem: string }[]>;
  openState: {
    open: boolean;
    setOpen: (open: boolean, name: string) => void;
    name: string;
  };
}> = ({ name, shortName, problemDeals, openState }) => {
  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );
  const isPhone = useMediaQuery('(max-width: 500px)');
  const problemDealsCount = Object.values(problemDeals).flat().length;

  return (
    <>
      <MuiButton
        onClick={() => openState.setOpen(true, name)}
        disabled={openState.open || problemDealsCount === 0}
        key={name}
        style={{
          minWidth: isTablet ? 80 : 215,
          height: 33.42,
          justifyContent: 'space-between',
        }}
      >
        {isTablet ? shortName : name}
        <Box
          style={{
            color: 'white',
            backgroundColor: problemDealsCount > 0 ? 'red' : undefined,
            borderRadius: '50%',
            border: `1px solid ${problemDealsCount > 0 ? 'red' : 'white'}`,
            width: 24,
            height: 24,
          }}
        >
          {problemDealsCount}
        </Box>
      </MuiButton>
      {openState.open && openState.name === name && (
        <Dialog
          open={openState.open}
          onClose={() => openState.setOpen(false, name)}
        >
          <DialogTitle>
            <Typography variant="h4">{name}</Typography>
          </DialogTitle>
          <DialogContent
            style={{
              minWidth: isPhone ? 'none' : '400px',
            }}
          >
            {problemDealsCount > 0 ? (
              <Box
                style={{
                  maxHeight: '250px',
                  overflowY: 'scroll',
                  overflowX: 'auto',
                  whiteSpace: 'nowrap',
                }}
              >
                {Object.entries(problemDeals).map(([dealType, deals]) => (
                  <Box key={dealType}>
                    <Typography variant="h5">{dealType}</Typography>
                    {deals.length ? (
                      deals.map((deal) => (
                        <Box key={deal.id}>
                          <Typography variant="h6">
                            <Link href={`/crm/deals/${dealType}s/${deal.id}`}>
                              #{deal.id}
                            </Link>{' '}
                            {deal.problem}
                          </Typography>
                        </Box>
                      ))
                    ) : (
                      <Typography variant="h6">-</Typography>
                    )}
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography variant="h5">No problems found</Typography>
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

const ProblemComponent: FC = () => {
  const leftMenuContext = useContext(LeftMenuContext);
  const classes = useStyles({
    isLeftMenuShown: leftMenuContext.isOpen,
  });
  const { data, error, isLoading, refetch } = useGetProblemDeals();
  const [openState, setOpenState] = useState<{
    open: boolean;
    name: string;
  }>({
    open: false,
    name: '',
  });

  const setOpen = (open: boolean, name: string) => {
    setOpenState({ open, name });
  };

  if (error && !isLoading) {
    return <MuiButton onClick={() => refetch()}>Try Again</MuiButton>;
  }

  if (isLoading || !data) {
    return <Spinner size={24} />;
  }

  return (
    <Box className={classes.root}>
      {Object.keys(data).map((key) => (
        <MarketplaceProblems
          key={key}
          name={key}
          shortName={key
            .split(' ')
            .map((word) => word[0])
            .join('')}
          problemDeals={
            data[key as keyof typeof data] as Record<
              DealType,
              { id: string; problem: string }[]
            >
          }
          openState={{
            open: openState.open,
            setOpen,
            name: openState.name,
          }}
        />
      ))}
    </Box>
  );
};

export const ProblemReporter: FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  return isOpen ? (
    <ProblemComponent />
  ) : (
    <MuiButton
      onClick={() => setIsOpen(true)}
      style={{
        height: 34,
      }}
    >
      Problem Reporter
    </MuiButton>
  );
};
