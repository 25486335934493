import { useReturnBagList } from '../../../hooks/datastores';
import { useEffect } from 'react';
import { Query, Select } from 'rollun-ts-rql';

const useStorageNames = () => {
  const [{ result, status, error }, dispatch] = useReturnBagList();

  useEffect(() => {
    dispatch(
      'QUERY',
      new Query({
        select: new Select(['storage']),
      }),
    );
  }, []);

  return {
    result,
    status,
    error,
  };
};

export { useStorageNames };
