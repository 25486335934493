import React from 'react';
import AbstractService, {
  AbstractServiceProps,
} from '../../../AbstractService';
import AppContainer from '../../../../layout/AppContainer/AppContainer';
import { ErrorView, Spinner } from '../../../../UI';
import { DealType } from '../GenericDeal';
import FindItemsByCns from './widgets/FindItemsByCSN/FindItemsByCns';
import BagDeal from './components/BagDeal';
import { marketplaceNames } from '../constants/marketplace-names';
import { suppliers } from '../constants/suppliers';
import { bagSenders } from '../constants/senders';
import { carriers } from '../constants/carrier';
import { bagStatus } from '../constants/bag-status';
import { type } from '../constants/type';
import { archiveScenarioBag } from '../constants/archive-scenario';
import { Scenarios } from '../common/widgets/Scenarios';
import { scenarios } from '../../../Table/components/TableHeaderButtons/buttons/Scenarios/scenarios';
import CreateProblemDeal from '../common/widgets/CreateProblemDeal';

class CRMBagDeal extends AbstractService<AbstractServiceProps> {
  render() {
    const { appConfig, error } = this.state;

    if (error) {
      return (
        <AppContainer services={{}}>
          <div className="w-100 h-100 d-flex flex-column justify-content-center">
            <ErrorView error={error}>
              <div className="d-flex justify-content-center p-3">
                <div
                  className="btn btn-primary cursor-pointer"
                  onClick={this.reloadHome}
                >
                  Home
                </div>
              </div>
            </ErrorView>
          </div>
        </AppContainer>
      );
    }

    if (appConfig === null) {
      return <Spinner />;
    }

    const { user, role } = this.props.userIdentity;
    return (
      <AppContainer
        currentResourceName={this.props.resourceName}
        services={this.props.availableServices}
        user={user}
        role={role}
      >
        <BagDeal
          fieldsConfig={{
            Order: {
              fields: [
                {
                  displayName: 'Mp name',
                  type: 'select',
                  options: marketplaceNames,
                  apiFieldPath: 'mpName',
                },
                {
                  displayName: 'Type',
                  type: 'select',
                  options: type,
                  apiFieldPath: 'type',
                },
                {
                  displayName: 'Sender',
                  type: 'select',
                  options: (bagSenders as unknown) as string[],
                  apiFieldPath: 'sender',
                },
                {
                  displayName: 'Bag status',
                  type: 'select',
                  options: bagStatus,
                  apiFieldPath: 'bagStatus',
                },
              ],
            },
            Supplier: {
              fields: [
                {
                  displayName: 'Supplier name',
                  type: 'select',
                  options: suppliers,
                  apiFieldPath: 'srName',
                },
                {
                  displayName: 'Supplier order number',
                  type: 'input',
                  apiFieldPath: 'srOrderNumber',
                },
                {
                  displayName: 'Supplier payment card',
                  type: 'input',
                  apiFieldPath: 'srPaymentCard',
                },
                {
                  displayName: 'Supplier items price',
                  type: 'input',
                  apiFieldPath: 'srItemsPrice',
                },
                {
                  displayName: 'Supplier order ID',
                  type: 'input',
                  apiFieldPath: 'srOrderId',
                },
              ],
            },
            'Tech fields': {
              fields: [
                {
                  displayName: 'Problem description',
                  type: 'input',
                  apiFieldPath: 'problemDescription',
                  multiline: true,
                },
                {
                  displayName: 'Archive scenario',
                  type: 'select',
                  options: archiveScenarioBag,
                  apiFieldPath: 'archiveScenario',
                },
                {
                  displayName: 'Ret Bag',
                  type: 'input',
                  apiFieldPath: 'retBag',
                },
              ],
            },
            Tracking: {
              fields: [
                {
                  displayName: 'Tracking number',
                  type: 'input',
                  apiFieldPath: 'tracknumber',
                },
                {
                  displayName: 'Carrier',
                  type: 'select',
                  options: carriers,
                  apiFieldPath: 'carrier',
                },
                {
                  displayName: 'Ship status',
                  type: 'input',
                  apiFieldPath: 'shipStatus',
                },
              ],
            },
          }}
          type={DealType.Bag}
          buttons={[]}
          widgets={[
            {
              displayName: 'Create Problem Deal',
              buttonName: 'Create Problem Deal',
              component: CreateProblemDeal,
              docsLink:
                'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.s8hlgyoe9i7t',
              docsTitle: 'docs for CreateProblemDeal widget',
            },
            {
              buttonName: 'Find items by CSN',
              displayName: 'Find items by CSN',
              component: FindItemsByCns,
              docsTitle: 'docs for FindItemsByCns widget',
              docsLink:
                'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.5oalmi7h7a87',
            },
            {
              buttonName: 'Scenarios',
              displayName: 'Scenarios',
              component: Scenarios,
              props: {
                scenarios: scenarios.bag,
              },
              docsTitle: 'docs for Scenarios widget',
              docsLink:
                'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.m1wjq0kj6hxo',
            },
          ]}
        />
      </AppContainer>
    );
  }
}

export default CRMBagDeal;
