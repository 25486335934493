export const SOURCES_OF_COMS = [
  '-',
  'return request',
  'e-mail',
  'case',
  'chargeback',
  'don`t have communication',
] as const;

export const WE_WAIT_FROM_OPTIONS = [
  '-',
  'client',
  'Rockymountain',
  'marketplace',
  'USPS',
  'prep-center',
  'Autodist',
  'PartsUnlimited',
  'other',
] as const;

export const WE_WAIT_FOR_OPTIONS = [
  '-',
  'scanning a label',
  'receiving a product',
  'sending a replacement',
  'response',
  'response from warranty or support team',
  'response from package team',
  'receiving a photo',
  'other',
] as const;

export const WE_MUST_DO_OPTIONS = [
  '-',
  'create a label',
  'message to the customer',
  'message to the carrier',
  'message to the supplier',
  'message to the marketplace',
  'message to prep centre',
  'void a label',
  'send dropship',
  'other',
] as const;
