import { Order } from '../../api-clients/types';
import ApiClientsOrders from '../../api-clients/api-clients-orders';
import { useQueryClient } from 'react-query';

export const useMutateDeal = () => {
  const queryClient = useQueryClient();

  const handleUpdateDeal = async (
    id: string,
    data: Partial<Omit<Order, 'id' | 'positions'>>,
  ) => {
    await new ApiClientsOrders().update(id, data);
    await queryClient.refetchQueries('GET_DEAL_KEY');
  };

  return {
    handleUpdateDeal,
  };
};
