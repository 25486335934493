import { Component } from 'react';
import Query from 'rollun-ts-rql/dist/Query';
import { DataStoreInterface } from 'rollun-ts-datastore';
import HttpDatastore from 'rollun-ts-datastore';
import { LocalDatastore } from 'rollun-ts-datastore';
import _ from 'lodash';
import { makeDefaultQuery } from './TableContainer';
import { appendQuery } from '../../../../utils/query.utils';
import AbstractQueryNode from 'rollun-ts-rql/dist/nodes/AbstractQueryNode';
import Select from 'rollun-ts-rql/dist/nodes/Select';
import {
  httpErrorHandler,
  noop,
  ProgressCallback,
  sendRequestsInChunks,
} from '../../../../utils/common.utils';
import { LoadingStatusEnum } from '../../../../utils/common.types';
import { Limit } from 'rollun-ts-rql';
import { COLUMN_HEADER_ALIAS } from '../../util/constants';
import { replaceAliasesInQuery, replaceAliasInObject } from '../../util';
import axios from 'axios';

export interface AbstractQueryEditorAppState {
  query: Query;
  error: { text: string; code: number };
  data: Array<any>;
  dataWithId: Array<any>;
  loadingStatus: LoadingStatusEnum;
  idFieldHidden?: boolean;
}

export interface AbstractQueryEditorAppProps {
  datastoreUrl: string;
  idField?: string;
  isLocal?: boolean;
  initialLocalDataStore?: DataStoreInterface;

  onChangeQuery?(query: Query): void;

  getTableReloader?(updater: () => void): void;
}

/**
 * Base component for every Component, that need to change query.
 * It implements some methods for editing data and loading it
 */

abstract class AbstractTableWithFilter<
  P extends AbstractQueryEditorAppProps,
  S extends AbstractQueryEditorAppState
> extends Component<P, S> {
  multiCreateSupported = false;
  datastore: DataStoreInterface<any>;
  // field name with aliases
  fieldNames: Array<string> = [];
  // field names without aliases
  actualFieldNames: Array<string> = [];
  selectedItemID = '';
  selectedItemIndex: number | null = null;
  // for some reason ReactDataGrid calls onGridRowsUpdated 2 times on row update
  // so i need to keep last updated value to prevent multiple updates

  constructor(props: P) {
    super(props);
    if (props.getTableReloader) {
      props.getTableReloader(this.refreshTable);
    }
    this.datastore = new HttpDatastore<any>(props.datastoreUrl, {
      idField: props.idField || 'id',
    });
  }

  addIdField = () => {
    const query = _.cloneDeep(this.state.query);
    if (
      query.selectNode.fields.some(
        (field: string) => field === this.props.idField,
      )
    )
      return;

    query.selectNode.fields.unshift(this.props.idField);
    this.setQuery(query);
  };

  loadInitDataForLocalDs = async () => {
    this.setState({ loadingStatus: LoadingStatusEnum.loading });
    const { initialLocalDataStore } = this.props;
    if (initialLocalDataStore) {
      this.datastore = initialLocalDataStore as LocalDatastore;
      const data = await initialLocalDataStore.query();
      const count = await initialLocalDataStore.count();
      console.log('data', { data, count });
      this.setState({
        loadingStatus:
          count === 0 ? LoadingStatusEnum.empty : LoadingStatusEnum.loaded,
        data: data,
      });
    } else {
      this.datastore
        .query()
        .then((res) => {
          this.datastore = new LocalDatastore<any>({
            initialData: res,
            idField: this.props.idField || 'id',
          });
          this.setState({
            loadingStatus:
              res.length > 0
                ? LoadingStatusEnum.loaded
                : LoadingStatusEnum.empty,
            data: res,
          });
        })
        .catch(() => {
          this.datastore = new LocalDatastore<any>({
            idField: this.props.idField || 'id',
          });
          this.setState({ loadingStatus: LoadingStatusEnum.empty, data: [] });
        })
        .finally(() => this.refreshTable());
    }
  };

  /**
   * getting Table fieldNames needs to be done by single request,
   * because i don't have fieldNames in configs, and also, if Table is loaded with default query
   * query editor will receive wrong fieldNames.
   */

  setFieldNames = async () => {
    try {
      const res = await this.datastore.query(
        new Query({ limit: new Limit(1, 0) }),
      );
      this.actualFieldNames = res[0] ? Object.keys(res[0]) : [];
      // apply aliases
      this.fieldNames = this.actualFieldNames.map(
        (name) => COLUMN_HEADER_ALIAS[name] || name,
      );
    } catch (err) {
      console.log('Fields fetching error', err);
      this.fieldNames = [];
    }
  };

  checkIfMultiCreateSupported = async () => {
    // TODO add logic for local datastore
    const { datastoreUrl } = this.props;
    if (!datastoreUrl) return false;
    try {
      const { headers } = await axios.head(datastoreUrl, { method: 'HEAD' });
      return !!Object.keys(headers).find(
        (key) => key.toLowerCase() === 'x_multi_create',
      );
    } catch (e) {
      console.log('error', e);
    }
    return false;
  };

  initData = async () => {
    this.multiCreateSupported = true;
    if (this.props.isLocal) {
      await this.loadInitDataForLocalDs();
    } else {
      this.multiCreateSupported = await this.checkIfMultiCreateSupported();
      this.refreshTable();
    }
    await this.setFieldNames();
  };

  componentDidMount(): void {
    this.initData().catch((err) =>
      httpErrorHandler(err, (code, text) => {
        this.setState({
          loadingStatus: LoadingStatusEnum.error,
          error: { code, text },
        });
      }),
    );
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>): void {
    if (!_.isEqual(prevState.query, this.state.query)) {
      // console.log('Not Equal', prevState.query, this.state.query);
      this.refreshTable();
    } else {
      // console.log('Equal', prevState.query, this.state.query);
    }
  }

  refreshTable = async () => {
    if (this.state.loadingStatus !== LoadingStatusEnum.loading) {
      this.setState({ loadingStatus: LoadingStatusEnum.loading });
    }
    try {
      if (this.fieldNames.length === 0) {
        await this.setFieldNames();
      }
      const query = _.cloneDeep(this.state.query);

      if (
        query.selectNode &&
        !query?.groupNode &&
        this.props.idField &&
        !query.selectNode.fields.includes(this.props.idField)
      ) {
        query.selectNode.fields.push(this.props.idField);
        this.setState({
          idFieldHidden: true,
        });
      } else {
        this.setState({
          idFieldHidden: false,
        });
      }
      console.log('this.props', {
        props: this.props,
        query: _.cloneDeep(query),
        queryChanged: replaceAliasesInQuery(query, this.actualFieldNames),
      });

      const res = await this.datastore.query(
        this.props.isLocal
          ? query
          : replaceAliasesInQuery(query, this.actualFieldNames),
      );
      console.log('res', res);

      const resWithoutId = _.cloneDeep(res).map((obj) => {
        delete obj[this.props.idField];
        return obj;
      });

      this.setState({
        data: this.state.idFieldHidden ? resWithoutId : res,
        dataWithId: res,
        loadingStatus:
          res.length > 0 ? LoadingStatusEnum.loaded : LoadingStatusEnum.empty,
      });
    } catch (err) {
      httpErrorHandler(err, (code, text) =>
        this.setState({
          error: { text, code },
          data: [],
          loadingStatus: LoadingStatusEnum.error,
        }),
      );
    }
  };

  updateItem = (rowIndex: number, columnIndex: number, value: string) => {
    const fieldName = Object.keys(this.state.data[0])[columnIndex];
    const id =
      this.selectedItemID ||
      this.state.dataWithId[rowIndex][this.props.idField];
    const idField = (this.props.idField as any) || 'id';
    const oldValue = this.state.data[rowIndex][fieldName];
    if (oldValue === value) return;

    this.datastore
      .update(
        replaceAliasInObject(
          { [idField]: id, [fieldName]: value },
          this.actualFieldNames,
        ),
      )
      .then(() => this.refreshTable())
      .catch((err) =>
        httpErrorHandler(err, (code, text) =>
          this.setState({
            error: { text, code },
            loadingStatus: LoadingStatusEnum.error,
          }),
        ),
      );
  };

  setQuery = (query: Query) => {
    if (this.props.onChangeQuery) {
      this.props.onChangeQuery(query);
    }
    if (!_.isEqual(query, this.state.query)) {
      this.setState({
        query: query,
        loadingStatus: LoadingStatusEnum.loading,
      });
    }
  };

  setSelectedItemId = (currentRow: any) => {
    const idField = this.props.idField || 'id';
    this.selectedItemID = currentRow[idField];
    this.selectedItemIndex = this.state.data.indexOf(currentRow);
  };

  resetQuery = () => {
    const oldQuery = this.state.query;
    const newQuery = makeDefaultQuery();

    this.setQuery(newQuery);

    // Force update data, because equality of queries
    // prevents loadData in componentDidUpdate
    if (_.isEqual(oldQuery, newQuery)) {
      this.refreshTable();
    }
  };

  appendQuery = (node: AbstractQueryNode) => {
    this.setQuery(appendQuery(node, this.state.query));
  };

  itemsCreate = async (data: Array<Record<string, unknown>>) => {
    return this.datastore
      .create(
        data.map((row) => replaceAliasInObject(row, this.actualFieldNames)),
      )
      .then(() => this.refreshTable());
  };

  itemCreate = (data: Record<string, unknown>) => {
    this.datastore
      .create(replaceAliasInObject(data, this.actualFieldNames))
      .then(() => this.refreshTable())
      .catch((err) =>
        httpErrorHandler(err, (code, text) =>
          this.setState({
            error: { text, code },
            loadingStatus: LoadingStatusEnum.error,
          }),
        ),
      );
  };

  /**
   * itemDelete and itemsDelete both takes progress callback,
   * witch receives 2 params:
   * 		{current} -> processed items count
   * 		{total} -> total amount of items to process
   * @param progressCallback
   */
  itemDelete = async (progressCallback: ProgressCallback = noop) => {
    try {
      await this.datastore.delete(
        this.selectedItemID ||
          (this.selectedItemIndex &&
            this.state.dataWithId[this.selectedItemIndex][this.props.idField]),
      );
      progressCallback(1, 0, 1);
      this.selectedItemID = '';
      this.selectedItemIndex = null;
      await this.refreshTable();
    } catch (e) {
      httpErrorHandler(e, (code, text) => {
        progressCallback(0, 1, 1, { error: text });
        this.setState({
          error: { text, code },
          loadingStatus: LoadingStatusEnum.error,
        });
      });
    }
  };

  /**
   * itemDelete and itemsDelete both takes progress callback,
   * witch takes number as parameter, and represent progress in percentage (0-100)
   * @param progressCallback
   */
  itemsDelete = (progressCallback: ProgressCallback) => {
    const idField = (this.props.idField as any) || 'id';
    const query = _.cloneDeep(this.state.query);
    query.selectNode = new Select([idField]);
    query.limitNode = undefined;
    this.setState({ loadingStatus: LoadingStatusEnum.loading });
    this.datastore
      .query(query)
      .then((ids: Array<{ [key: string]: string }>) => {
        sendRequestsInChunks<string>(
          ids.map((id) => ({
            data: id[idField],
            handler: (id) => this.datastore.delete(id),
          })),
          progressCallback,
        )
          .then(() => this.refreshTable())
          .catch((err) =>
            httpErrorHandler(err, () => {
              return;
            }),
          );
      })
      .catch(() => {
        this.setState({
          error: { text: 'Could not request ant data', code: 500 },
          loadingStatus: LoadingStatusEnum.error,
        });
      });
  };
}

export default AbstractTableWithFilter;
