import React, { FC, useState } from 'react';
import Spinner from '../../../../UI/Spinner';
import { downloadAsCSV, randomString } from 'rollun-ts-utils/dist';
import {
  CSVData,
  CSVRowObject,
  CSVValue,
} from 'rollun-ts-utils/dist/arrayToCSV';
import { OnUploadData } from '../Table/TableWithFilter';
import _ from 'lodash';
import MuiIconButton from '../../../../UI/MuiIconButton';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';
import MuiButton from '../../../../UI/MuiButton';

export type ValidatorParam = { field: string; type: string; param?: string };

export enum DataParsers {
  DEFAULT_DATA_PARSER = 'DefaultDataParser',
  PARCEL_ITEMS_PARSER = 'ParcelItemsParser',
  INJECT_UPDATE_TIMESTAMP = 'InjectUpdateTimestamp',
  INJECT_UPDATED_AT = 'InjectUpdatedAt',
  INJECT_AMAZON_MSIN = 'InjectAmazonMsin',
  INJECT_WITH_ID = 'InjectWithId',
  INJECT_WITH_ID_AND_DATEDAY = 'InjectWithIdAndDateDay',
  INJECT_WITH_DATEDAY = 'InjectWithDateDay',
}

export enum CopyPasterDelimiters {
  Hash = '#',
  Colon = ':',
}

export enum CopyPastePasteAfterValues {
  IsoStringDate = 'ISO',
  YearMonthDay = 'yyyymmdd',
}

export type CopyPastePasteAfterField = {
  fieldName: string;
  value: CopyPastePasteAfterValues;
  delimiter: CopyPasterDelimiters;
};

export interface CopyPasteUploaderParams {
  pasteAfterField: CopyPastePasteAfterField[];
  fieldNames: string[];
  fieldNamesToCompare?: string[];
  fieldNamesToReplaceWithNullIfEmpty?: string[];
  rewrite?: boolean;
  noId?: boolean; // assumes first column is id and drops it
  inputSeparator?: string; // column separator symbol
  validators?: Array<ValidatorParam>;
  customDataParser?: DataParsers;
  // instead of new Paste uploader that uses multi create,
  // use old uploader tht send 1 request at the time
  isLegacy?: boolean;
}

interface CopyPasteUploaderProps {
  handleCreateItems: OnUploadData;
  popupTitle?: string;
  isDisabled: boolean;
  isMultiCreateSupported?: boolean;
  params: CopyPasteUploaderParams;
}

export const DATA_PARSERS_MAP: {
  [K in DataParsers]: (
    csv: string,
    fieldNames: string[],
    inputSeparator?: string,
  ) => CSVData;
} = {
  [DataParsers.PARCEL_ITEMS_PARSER]: parseBarcodeItems,
  [DataParsers.INJECT_UPDATE_TIMESTAMP]: injectUpdateTimestamp,
  [DataParsers.DEFAULT_DATA_PARSER]: convertCSVToDataStoreRecords,
  [DataParsers.INJECT_UPDATED_AT]: injectUpdatedAt,
  [DataParsers.INJECT_AMAZON_MSIN]: injectAmazonMsin,
  [DataParsers.INJECT_WITH_ID]: injectWithId,
  [DataParsers.INJECT_WITH_ID_AND_DATEDAY]: injectWithIdAndDateDay,
  [DataParsers.INJECT_WITH_DATEDAY]: injectWithDateDay,
};

const CopyPasteUploader: FC<CopyPasteUploaderProps> = ({
  params,
  handleCreateItems,
  isMultiCreateSupported,
  isDisabled,
}) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(0);
  const [fail, setFail] = useState(0);
  const [dataToLoadLength, setDataToLoadLength] = useState(0);
  const [dataToUpload, setDataToUpload] = useState('');
  const [failedItems, setFailedItems] = useState<any[]>([]);
  const isErrors = success + fail >= dataToLoadLength && fail > 0;

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const onUploadProgress = (
    success: number,
    fail: number,
    total: number,
    failedItems?: Array<any>,
  ) => {
    if (success + fail < total) {
      setSuccess(success);
      setFail(fail);
      setDataToLoadLength(total);
    } else {
      setLoading(false);
      setSuccess(success);
      setFail(fail);
      setFailedItems(failedItems || []);
    }
  };

  const getDataParserFromConfig = () => {
    const { customDataParser } = params;
    return (
      (customDataParser && DATA_PARSERS_MAP[customDataParser]) ||
      convertCSVToDataStoreRecords
    );
  };

  const onUploadItems = (value: string) => {
    console.log('value before', value);
    const { noId, fieldNames, inputSeparator, validators } = params;
    const processedFieldNames = noId ? fieldNames.slice(1) : fieldNames;
    console.log('value processed', processedFieldNames);
    let records;
    try {
      records = getDataParserFromConfig()(
        value,
        processedFieldNames,
        inputSeparator,
      );
    } catch (e) {
      console.log('value parsing failed', processedFieldNames);
      if (e instanceof Error) {
        setError(e.toString());
      }
      return;
    }
    console.log('value succeded', processedFieldNames);

    setError('');
    setLoading(true);

    if (validators) {
      const { valid, msg } = lineValidator(
        processedFieldNames,
        validators,
        records,
      );
      console.log('value validators = true', valid, msg);
      if (valid) {
        handleCreateItems(records, onUploadProgress, !isMultiCreateSupported);
      } else {
        setError(msg || 'unknown error');
        setLoading(false);
      }
    } else {
      console.log('value validators = false', processedFieldNames);
      handleCreateItems(records, onUploadProgress, !isMultiCreateSupported);
    }
  };

  const onFailedItemsUpload = () => {
    downloadAsCSV(failedItems);
  };

  return (
    <div>
      <MuiIconButton
        title="copy/paste uploader"
        color="primary"
        width={40}
        height={40}
        iconName="upload"
        disabled={isDisabled}
        onClick={handleOpenDialog}
      />
      <Dialog
        open={isDialogOpen}
        fullWidth
        maxWidth="lg"
        onClose={handleCloseDialog}
      >
        <DialogTitle>
          Copy/paste uploader
          {isMultiCreateSupported ? '' : ' (OLD)'}, duplicated items will not be
          created again.
        </DialogTitle>
        <DialogContent>
          <Box marginBottom={1}>
            <Typography variant="h6">
              Paste data to upload. Valid data - columns separated by \t (same
              as tab) and rows separated by \n (next line, same as enter)
            </Typography>
          </Box>
          {error !== '' ? (
            <Typography variant="h5" color="error">
              Error: {error}
            </Typography>
          ) : null}
          {isErrors ? (
            <Box textAlign="center" margin={2}>
              <Typography variant="h5">
                {fail} of {dataToLoadLength} records did not load successfully
              </Typography>
              <Typography variant="body1">
                You can download failed items as CSV, or try load one more time
              </Typography>
            </Box>
          ) : isLoading ? (
            <Box
              margin={2}
              textAlign="center"
              display="flex"
              flexDirection="column"
              height={200}
              justifyContent="space-between"
            >
              <Spinner />
              <Typography variant="h5">
                Total: {success + fail}/{dataToLoadLength}
              </Typography>
              <Typography variant="h6">succeed: {success}</Typography>
              <Typography color="error" variant="h6">
                fail: {fail}
              </Typography>
            </Box>
          ) : (
            <TextField
              variant="outlined"
              label="Data to upload"
              fullWidth
              onChange={(e) => setDataToUpload(e.target.value)}
              multiline
              rows={30}
            />
          )}
        </DialogContent>
        <DialogActions>
          {isErrors ? (
            <>
              <MuiButton
                color="primary"
                fullWidth
                onClick={onFailedItemsUpload}
              >
                Download
              </MuiButton>
              <MuiButton
                fullWidth
                onClick={() => {
                  setLoading(true);
                  setSuccess(0);
                  setFail(0);
                  handleCreateItems(failedItems, onUploadProgress);
                }}
                color="success"
              >
                Try again
              </MuiButton>
              <MuiButton
                fullWidth
                color="secondary"
                onClick={() => {
                  setLoading(false);
                  setDialogOpen(false);
                  setSuccess(0);
                  setFail(0);
                }}
              >
                Cancel
              </MuiButton>
            </>
          ) : (
            <>
              <MuiButton
                fullWidth
                color="success"
                onClick={() => onUploadItems(dataToUpload)}
                disabled={isLoading}
              >
                Upload
              </MuiButton>
              <MuiButton
                fullWidth
                color="secondary"
                onClick={() => {
                  setError('');
                  setLoading(false);
                  setDialogOpen(false);
                }}
                disabled={isLoading}
              >
                Cancel
              </MuiButton>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const getValidator = (
  params: ValidatorParam,
): ((str: CSVValue) => boolean) => {
  switch (params.type) {
    case 'regexp':
      return (str) => new RegExp(params.param || '').test(str.toString());
    case 'positive_int':
      return (str) => {
        if (typeof str === 'number') return str > 0;
        const val = parseInt(str);
        return !isNaN(val) && val > 0;
      };
    default:
      return () => true;
  }
};

export const notEmptyFieldsCounter = (obj: CSVRowObject) => {
  let size = 0;
  for (const field in obj) {
    const val = obj[field];
    if (typeof val === 'string' && val.trim() !== '') {
      size += 1;
    }
  }
  return size;
};

export const lineValidator = (
  fieldNames: Array<string>,
  params: Array<ValidatorParam>,
  records: CSVData,
): { valid: boolean; msg?: string } => {
  const validators: { [key: string]: (str: CSVValue) => boolean } = {};
  for (const param of params) {
    validators[param.field] = getValidator(param);
  }
  const fieldsAmount = _.size(fieldNames);
  for (let i = 0; i < records.length; i++) {
    const fieldsAmountInRecords = notEmptyFieldsCounter(records[i]);
    if (fieldsAmount !== fieldsAmountInRecords) {
      return {
        valid: false,
        msg: `Line ${
          i + 1
        }: Expected ${fieldsAmount} fields, but provided ${fieldsAmountInRecords}.`,
      };
    }
    for (const field in records[i]) {
      const row = records[i];
      if (_.isArray(row)) continue;
      const value = row[field];
      if (validators[field]) {
        if (!validators[field](value)) {
          return {
            valid: false,
            msg: `Line ${
              i + 1
            }: Field [${field}] with value [${value}] is not valid!`,
          };
        }
      }
    }
  }
  return { valid: true };
};

/**
 * Default data Parsers
 * @param csv
 * @param fieldNames
 * @param inputSeparator
 */

export function convertCSVToDataStoreRecords(
  csv: string,
  fieldNames: string[],
  inputSeparator = '\t',
): CSVData {
  const parsedValue: CSVData = [];
  const splitValue = csv.split('\n').filter((line) => line.trim());
  splitValue.forEach(function (row) {
    const items = row.split(inputSeparator);
    const result: { [key: string]: string } = {};
    fieldNames.forEach((column, index) => {
      // @ts-expect-error
      result[column] =
        items[index] === undefined || items[index] === 'null'
          ? null
          : items[index];
    });
    parsedValue.push(result);
  });
  return parsedValue;
}

/**
 * Data Parser for Barcode Items (Parcel service)
 * First row in this data is meta data row, that includes total amounts of items in boxes
 * This parser already contains validation, it throws string with error description.
 */

export function parseBarcodeItems(
  csv: string,
  fieldNames: string[],
  inputSeparator = '\t',
): CSVData {
  const firstRowIndexEnd = csv.indexOf('\n');
  const metaData = csv
    .slice(0, firstRowIndexEnd)
    .trim()
    .split(inputSeparator)
    .map((n) => +n || 0);
  const restBoxesFieldNames = metaData.map(
    (_, index) => `Box${index + 1}quantity`,
  );
  const actualParsedData = convertCSVToDataStoreRecords(
    csv.slice(firstRowIndexEnd + 1),
    ['FNSKU', 'Rockypart', 'Description', 'Imagelink'].concat(
      restBoxesFieldNames,
    ),
  );

  // Count amount for every 'box' field for every record
  const actualItemsInBox = metaData.map(() => 0);
  actualParsedData.forEach((row) => {
    if (_.isArray(row)) return;
    restBoxesFieldNames.forEach((fieldName, index) => {
      const value = row[fieldName];
      const itemsInBox = typeof value === 'string' ? parseInt(value) : value;
      actualItemsInBox[index] += itemsInBox || 0;
    });
  });

  // test if total amount provided in meta data, matches actual amount
  actualItemsInBox.forEach((item, index) => {
    if (item !== metaData[index]) {
      throw `Quantity in ${restBoxesFieldNames[index]} field must be ${metaData[index]} but it is ${item}`;
    }
  });
  return actualParsedData;
}

/**
 * This data parser just adds update_timestamp field and puts current client timestamp.
 * @param csv
 * @param fieldNames
 * @param inputSeparator
 */

export function injectUpdateTimestamp(
  csv: string,
  fieldNames: string[],
  inputSeparator = '\t',
): CSVData {
  const update_timestamp = Math.floor(Date.now() / 1000);
  return convertCSVToDataStoreRecords(csv, fieldNames, inputSeparator).map(
    (row) => ({
      ...row,
      ...(!_.isArray(row) && row.update_timestamp
        ? // inject timestamp only if it does not provided
          { update_timestamp: row.update_timestamp }
        : { update_timestamp }),
    }),
  );
}

export function injectUpdatedAt(
  csv: string,
  fieldNames: string[],
  inputSeparator = '\t',
): CSVData {
  const updated_at = Math.floor(Date.now() / 1000);
  return convertCSVToDataStoreRecords(csv, fieldNames, inputSeparator).map(
    (row) => ({
      ...row,
      ...(!_.isArray(row) && row.updated_at
        ? // inject timestamp only if it does not provided
          { updated_at: row.updated_at }
        : { updated_at }),
    }),
  );
}

export function injectAmazonMsin(
  csv: string,
  fieldNames: string[],
  inputSeparator = '\t',
): CSVData {
  return convertCSVToDataStoreRecords(csv, fieldNames, inputSeparator).map(
    (row) => ({
      ...row,
      id: randomString(40),
      asinist: '1',
      decision: 'no',
      operator: 'manager',
      date: new Date()
        .toISOString()
        .replace('T', ' ')
        .replace('Z', '')
        .slice(0, -4),
    }),
  );
}

export function injectWithId(
  csv: string,
  fieldNames: string[],
  inputSeparator = '\t',
): CSVData {
  return convertCSVToDataStoreRecords(csv, fieldNames, inputSeparator).map(
    (row) => ({
      ...row,
      id: randomString(40),
    }),
  );
}

export function injectWithIdAndDateDay(
  csv: string,
  fieldNames: string[],
  inputSeparator = '\t',
): CSVData {
  return convertCSVToDataStoreRecords(csv, fieldNames, inputSeparator).map(
    (row) => ({
      ...row,
      id: randomString(40),
      TimeCreated: new Date()
        .toISOString()
        .replace('T', ' ')
        .replace('Z', '')
        .slice(0, -13),
    }),
  );
}

export function injectWithDateDay(
  csv: string,
  fieldNames: string[],
  inputSeparator = '\t',
): CSVData {
  return convertCSVToDataStoreRecords(csv, fieldNames, inputSeparator).map(
    (row) => ({
      ...row,
      TimeCreated: new Date()
        .toISOString()
        .replace('T', ' ')
        .replace('Z', '')
        .slice(0, -13),
    }),
  );
}

export default CopyPasteUploader;
