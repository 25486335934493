import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Typography,
  useTheme,
} from '@material-ui/core';
import MuiButton from '../../../UI/MuiButton';
import { includesCaseInsensitive, useStyles } from '../utils';
import ReturnAppContext from '../context';
import { And, Eq, Query, Or } from 'rollun-ts-rql';
import { useReturnsDatastore, ReturnedLabel } from '../../../hooks/datastores';

interface ReturnedLabelsProps {
  search: string;
}

const ReturnedLabels: FC<ReturnedLabelsProps> = ({ search }) => {
  const [
    { isLoading, isError, error },
    returnsDispatch,
  ] = useReturnsDatastore();
  const [returnedLabels, setReturnedLabels] = useState<ReturnedLabel[]>([]);
  const [currentReturn, setCurrentReturn] = useState<ReturnedLabel | null>(
    null,
  );
  const {
    palette: { success, secondary },
  } = useTheme();
  const classes = useStyles();
  const context = useContext(ReturnAppContext);

  const filteredReturnedLabels = useMemo(() => {
    if (search.length === 0) {
      return returnedLabels.filter((label) => label.status === 'not processed');
    }

    return returnedLabels.filter(
      ({ track_number, client_name, mp_return_number }) =>
        includesCaseInsensitive(track_number, search) ||
        includesCaseInsensitive(client_name, search) ||
        includesCaseInsensitive(mp_return_number, search),
    );
  }, [returnedLabels, search]);

  const getLabels = async () => {
    const data = await returnsDispatch(
      'QUERY',
      new Query({
        query: new And([
          new Or([
            new Eq('status', 'not processed'),
            new Eq('status', 'problem'),
          ]),
          new Eq('location', context.location),
        ]),
      }),
    );
    if (data) {
      setReturnedLabels(data);
    }
  };

  useEffect(() => {
    getLabels();
  }, []);

  const onYes = () => {
    context.setSupplier(currentReturn?.supplier);
    context.setLabel(currentReturn?.track_number || '', 'possibleItem');
  };

  if (isLoading) {
    return (
      <Grid key="loading" item xs={12}>
        <Card>
          <CardContent className={classes.cardContent}>
            <LinearProgress />
          </CardContent>
        </Card>
      </Grid>
    );
  }

  if (isError) {
    return (
      <Grid key="error" item xs={12}>
        <Card>
          <CardContent className={classes.cardContent}>
            <Typography variant="body1" color="error">
              {error?.text}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  if (filteredReturnedLabels.length === 0) {
    return (
      <Grid key="no_option" item xs={12}>
        <Card>
          <CardContent className={classes.cardContent}>
            <Typography variant="body1" color="textSecondary">
              No option available
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  return (
    <>
      {[...filteredReturnedLabels]
        // show delivered first
        .sort((l1) => (l1.delivered === 'yes' ? -1 : 1))
        .map((label) => (
          <Grid key={label.track_number} item xs={12}>
            <Box
              border={2}
              borderRadius={4}
              borderColor={
                label.delivered === 'yes' ? success.main : secondary.main
              }
            >
              <Card onClick={() => setCurrentReturn(label)}>
                <CardContent className={classes.cardContent}>
                  <Typography variant="h4">{label.track_number}</Typography>
                  <Typography variant="body1" color="textSecondary">
                    {label.client_name}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    {label.mp_return_number}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        ))}
      <Dialog open={!!currentReturn} onClose={() => setCurrentReturn(null)}>
        <DialogTitle>Are you sure you received this return?</DialogTitle>
        <DialogContent dividers>
          {Object.entries(currentReturn || {}).map(([key, value]) => (
            <Box key={key} display="flex">
              <Box fontWeight="bold">{key}</Box>
              <Typography variant="body1">{': ' + value}</Typography>
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <MuiButton color="primary" onClick={() => onYes()}>
            Yes
          </MuiButton>
          <MuiButton color="error" onClick={() => setCurrentReturn(null)}>
            No
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReturnedLabels;
