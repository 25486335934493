import React, { FC, useContext, useState } from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import MuiButton from '../../../UI/MuiButton';
import { Dimension } from './SetDimensions';
import { useBoxDimension } from '../hooks';
import BoxDimensionInput from './BoxDimensionInput';
import MuiIconButton from '../../../UI/MuiIconButton';
import { BagFillingContext } from '../BagFillingContext';

interface BoxCardProps {
  onSubmit: (dimension: Dimension, index: string) => void;
  onRemove: (index: string) => void;
  index: number;
  id: string;
}

const BoxCard: FC<BoxCardProps> = ({ onSubmit, onRemove, index, id }) => {
  const [dimension, setDimension] = useBoxDimension();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const context = useContext(BagFillingContext);

  return (
    <Card>
      <CardContent>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={1}
        >
          <Typography variant="h5" align="center">
            {`Bag id: ${context.bagId}, ${
              isSubmitted
                ? `Box(${index + 1}) is submitted`
                : `Box(${index + 1})`
            }`}
          </Typography>
          <MuiIconButton onClick={() => onRemove(id)} iconName="times" />
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <BoxDimensionInput
              units="INCH"
              name="width"
              value={dimension.width}
              onChange={(e) => setDimension('width', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <BoxDimensionInput
              units="INCH"
              name="length"
              value={dimension.length}
              onChange={(e) => setDimension('length', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <BoxDimensionInput
              units="INCH"
              name="height"
              value={dimension.height}
              onChange={(e) => setDimension('height', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <BoxDimensionInput
              units="LBS"
              name="weight"
              value={dimension.weight}
              onChange={(e) => setDimension('weight', e.target.value)}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <MuiButton
          fullWidth
          color="success"
          type="submit"
          disabled={Object.values(dimension).some((d) => !d)}
          onClick={() => {
            setIsSubmitted(true);
            onSubmit(dimension, id);
          }}
        >
          Submit box size
        </MuiButton>
      </CardActions>
    </Card>
  );
};

export default BoxCard;
