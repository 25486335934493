import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from '../../../../../UI/Spinner';
import GenericDeal, { DealProps, DealType } from '../../GenericDeal';
import { ErrorView } from '../../../../../UI';
import MuiButton from '../../../../../UI/MuiButton';
import { Box } from '@material-ui/core';
import { useMutateItems } from '../hooks/useMutateItems';
import { useMutateDeal } from '../hooks/useMutateDeal';
import { useGetProblem } from '../hooks/useGetProblem';
import { apiClientsProblems } from '../../api-clients/api-clients-problems';
import { clientFieldConfig } from '../../GenericDeal/config/client-field.config';

export const ProblemDeal: FC<DealProps> = ({
  buttons,
  fieldsConfig,
  widgets,
}) => {
  const { id } = useParams<{ id: string }>();
  const { data, error, isFetching } = useGetProblem(id);
  const history = useHistory();
  const { handleAddItems, handleDeleteItems } = useMutateItems();
  const { handleUpdateDeal } = useMutateDeal();

  if (isFetching && data?.id !== id) {
    return <Spinner />;
  }

  if (error && !data) {
    return (
      <ErrorView
        error={{
          text: (error as Error).message,
          code: (error as Error).message.includes('not found') ? 404 : 403,
        }}
      >
        <Box
          style={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <MuiButton onClick={() => history.goBack()}>Back</MuiButton>
          <MuiButton onClick={() => location.reload()}>Reload</MuiButton>
        </Box>
      </ErrorView>
    );
  }

  return (
    <GenericDeal
      data={data!}
      rawData={data ? apiClientsProblems.convertModelToDTO(data!) : {}}
      type={DealType.Problem}
      buttons={buttons}
      fieldsConfig={{
        ...(data?.client && clientFieldConfig),
        ...fieldsConfig,
      }}
      widgets={widgets}
      onItemsAdd={handleAddItems}
      onItemsDelete={handleDeleteItems}
      onDealUpdate={handleUpdateDeal}
      availableStatuses={[]}
    />
  );
};
