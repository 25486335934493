import ApiClientsOrders from '../../../api-clients/api-clients-orders';
import { Order } from '../../../api-clients/types';
import { Marketplaces, ParsedOrder, RawDataParser } from '../RawDataParser';
import { validatePositions } from '../raw-data-parser-utils';

export class CrmOrders {
  private api: ApiClientsOrders;
  private parser: RawDataParser;
  constructor() {
    this.api = new ApiClientsOrders();
    this.parser = new RawDataParser();
  }

  async create(deal: any, marketplace: Marketplaces) {
    await validatePositions(deal.positions);
    const order = await this.api.getByOrderNumber(deal.order.mpOrderNumber);
    let createdDeal;

    if (marketplace === 'Amazon Shoptimistic') {
      createdDeal = await this.createAmazon(deal, order);
    } else if (
      marketplace === 'Ebay Rollun' ||
      marketplace === 'Ebay Plaisir'
    ) {
      createdDeal = await this.createEbay(deal, order);
    }
    if (!createdDeal) throw new Error("Deal wasn't created");
    return createdDeal;
  }

  async createFromText(rawData: string, marketplace: Marketplaces) {
    const deal = await this.parser.fromText(rawData, marketplace, 'order');
    return this.create(deal, marketplace);
  }

  private async createEbay(deal: ParsedOrder, order: Order | null) {
    const message = ['Created successful with id: '];
    if (order) throw new Error('This deal already exist');
    const dealCreated = await this.api.createDeal(deal);
    return { deal: dealCreated, message };
  }

  private async createAmazon(deal: ParsedOrder, order: Order | null) {
    const message = ['Created successful with id: '];
    if (order) {
      const createAnyway = confirm('This deal already exist, create anyway?');

      if (createAnyway) {
        const dealCreated = await this.api.createDeal(deal);
        return { deal: dealCreated, message };
      }
      throw new Error('Deal creating canceled');
    }
    const dealCreated = await this.api.createDeal(deal);
    return { deal: dealCreated, message };
  }
}

export const crmOrders = new CrmOrders();
