import React, { Component } from 'react';
import ReactSelect from 'react-select';
import { ErrorView, Spinner } from '../../../UI';
import {
  CompatibleVehicle,
  ErrorType,
  Supplier,
  SUPPLIER_NAMES,
} from '../../../utils/common.types';
import { downloadDataWithContentType } from 'rollun-ts-utils/dist';
import InputForm from './InputForm';
import { getFileExchangeByEpidAndRID } from '../utils/getFileExchangeByEpidAndRID';
import { getFileExchangeByRollunId } from '../utils/getFileExchangeByRollunId';
import { parse } from 'json2csv';
import HttpDatastore from 'rollun-ts-datastore';
import _ from 'lodash';
import { And, Contains, Eq, In, Or, Query, Select } from 'rollun-ts-rql';
import {
  EbayMarketplace,
  ExchangeFileFormattor,
  stopWords,
  UniformSupplier,
} from '../utils';
import { getFileExchangeByRollunIdAndSrName } from '../utils/getFileExchangeByRollunIdAndSrName';
import {
  Box,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import MuiButton from '../../../UI/MuiButton';

interface IState {
  currentMarketplace: EbayMarketplace;
  currentSupplier: Supplier;
  fetchType: { value: string; label: string };
  ePIDsAndRollunIDs: Array<{ ePID?: string; rollunId: string }>;
  isLoading: boolean;
  error: ErrorType | null;
  progress: string | null;
  fileExchangeData: Array<any>;
}

/**
 * Refactor this to 2 components rendered in tabs, and common code share via HOC
 */

const classes = (theme: Theme) => ({
  select: {
    marginRight: theme.spacing(1),
    width: 200,
  },
  inputs: {
    display: 'flex',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
  },
});

const SUPPLIERS: UniformSupplier[] = [
  'Autodist',
  'PartsUnlimited',
  'RockyMountain',
  'TuckerRocky',
];

class PrepareListingsDataContainer extends Component<WithStyles, IState> {
  MARKETPLACES: Array<EbayMarketplace> = ['Plaisir', 'Rollun'];

  SUPPLIER_OPTIONS = [
    ...SUPPLIER_NAMES,
    'Uniform (Rollun id and Supplier name)',
  ];

  FETCHING_DATA_OPTIONS = [
    {
      value: 'by_rollun_id_and_epid',
      label: 'Fetch file data by epid and Rollun ID',
    },
    {
      value: 'by_rollun_id',
      label: 'Fetch file data by Rollun ID',
    },
    {
      value: 'by_rollun_id_and_sr_name',
      label: 'Fetch file data by Rollud Id and Supplier name',
    },
  ];

  ROLLUN_IDS_CACHE_NAME = 'ROLLUN_IDS_TO_POST_CACHE';

  ebayPlaisirInv = new HttpDatastore<{ rid: string }>(
    `/api/datastore/EbayPlaisirInventory`,
  );
  ebayRollunInv = new HttpDatastore<{ rid: string }>(
    `/api/datastore/EbayRollunInventory`,
  );

  constructor(props: WithStyles) {
    super(props);
    const cachedRollunIds = localStorage.getItem(this.ROLLUN_IDS_CACHE_NAME);
    this.state = {
      currentMarketplace: this.MARKETPLACES[0],
      currentSupplier: SUPPLIER_NAMES[0],
      fetchType: this.FETCHING_DATA_OPTIONS[0],
      ePIDsAndRollunIDs: cachedRollunIds ? JSON.parse(cachedRollunIds) : [],
      isLoading: false,
      error: null,
      progress: null,
      fileExchangeData: [],
    };
  }

  isLineValid = (columns: Array<string>, idx: number) => {
    const {
      fetchType: { value },
    } = this.state;
    if (value === 'by_rollun_id_and_sr_name') {
      let errorMessage = '';
      if (columns.length !== 2) {
        errorMessage = `2 columns required at line ${idx + 1}, but got ${
          columns.length
        }`;
      }

      const [rollunId, supplierName] = columns;

      const isRollunIdValid = rollunId.length === 5;
      const isSupplierNameValid = SUPPLIERS.some(
        (supplier) => supplier === supplierName,
      );

      if (!isRollunIdValid) {
        errorMessage += ` . Rollun id must be 5 characters long`;
      }

      if (!isSupplierNameValid) {
        errorMessage += ` . Supplier name must be one of ['Autodist', 'TuckerRocky', 'PartsUnlimited', 'RockyMountain']`;
      }

      if (errorMessage.length !== 0) {
        return {
          valid: false,
          msg: errorMessage,
        };
      }
    }

    if (value === 'by_rollun_id_and_epid' && columns.length !== 2)
      return {
        valid: false,
        msg: `2 columns required at line ${idx + 1}, but got ${
          columns.length
        }.`,
      };
    return { valid: true };
  };

  getFileExchangeData = async () => {
    this.setState({
      isLoading: true,
      progress: null,
      error: null,
    });
    const handlers: { [key: string]: ExchangeFileFormattor } = {
      by_rollun_id_and_epid: getFileExchangeByEpidAndRID,
      by_rollun_id: getFileExchangeByRollunId,
      by_rollun_id_and_sr_name: getFileExchangeByRollunIdAndSrName,
    };

    const {
      currentSupplier,
      currentMarketplace,
      fetchType: { value },
    } = this.state;

    const { data, error } = await handlers[value](
      this.state.ePIDsAndRollunIDs,
      {
        marketplace: currentMarketplace,
        supplier: currentSupplier,
      },
      (status) => this.setState({ progress: status }),
    );
    this.setState({
      isLoading: false,
      error,
      fileExchangeData: data,
    });
  };

  async fetchMarketplaceActiveRids(
    rids: Array<string>,
  ): Promise<{ rollun: Array<string>; plaisir: Array<string> }> {
    const fetch = (rids: Array<string>, ds: HttpDatastore<{ rid: string }>) =>
      ds
        .query(
          new Query()
            .setQuery(
              new And([new In('rid', rids), new Eq('mp_status', 'active')]),
            )
            .setSelect(new Select(['rid'])),
        )
        .then((res) => res.map(({ rid }) => rid));

    let rollun: Array<string> = [];
    let plaisir: Array<string> = [];

    for (const chunk of _.chunk(rids, 300)) {
      const [currRollun, currPlaisir] = await Promise.all([
        fetch(chunk, this.ebayRollunInv),
        fetch(chunk, this.ebayPlaisirInv),
      ]);
      rollun = rollun.concat(currRollun);
      plaisir = plaisir.concat(currPlaisir);
    }

    return {
      rollun,
      plaisir,
    };
  }

  async fetchDontSellItems(rids: Array<string>): Promise<Array<string>> {
    const { currentMarketplace } = this.state;

    // I am sure that this code will work, but TS is not, so i am
    // taking action and flexing on TS.
    // @ts-expect-error
    const ds: HttpDatastore<{ rid: string }> = this[
      `ebay${currentMarketplace}Inv`
    ];

    let result: Array<string> = [];

    for (const chunk of _.chunk(rids, 300)) {
      result = result.concat(
        await ds
          .query(
            new Query()
              .setQuery(
                new And([
                  new In('rid', chunk),
                  new Or([
                    new Contains('tags', '#dont_sell#'),
                    new Contains('tags', '#not_selling#'),
                  ]),
                ]),
              )
              .setSelect(new Select(['rid'])),
          )
          .then((res) => res.map(({ rid }) => rid)),
      );
    }
    return result;
  }

  fetchRestrictedBrands(): Promise<Array<{ brand_id: string }>> {
    const ds = new HttpDatastore('/api/datastore/RestrictedBrandsDataStore');

    return ds.query<{ brand_id: string }>(
      new Query().setSelect(new Select(['brand_id'])),
    );
  }

  filterFailed = async (
    data: Array<any>,
  ): Promise<{ failed: Array<any>; succeed: Array<any> }> => {
    const rids = data.map((item) => item['CustomLabel']);
    const [activeRids, restrictedBrands, dontSellItems] = await Promise.all([
      this.fetchMarketplaceActiveRids(rids),
      this.fetchRestrictedBrands(),
      this.fetchDontSellItems(rids),
    ]);

    const isValidBrand = (brand: string) => {
      if (!brand) return false;
      const cleanBrand = brand.replace(/\s/g, '').toUpperCase();
      return !restrictedBrands.some(({ brand_id }) => brand_id === cleanBrand);
    };

    // Next check apply only if such field exist in row
    const optionalExistValidator = (
      fieldName: string,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      cond = (value: any) => true,
    ) => (row: any) => (fieldName in row ? cond(row[fieldName]) : true);
    const existValidator = (
      fieldName: string,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      cond = (value: any) => true,
    ) => (row: any) => cond(row[fieldName]);

    // validators for item row  in result file, returns true, if row is valid
    const validators: { [name: string]: (item: any) => boolean } = {
      StartPriceCheck: existValidator(
        '*StartPrice',
        (value) => value != -1 && value !== '-',
      ),
      QuantityCheck: existValidator('*Quantity', (value) => +value > 0),
      CustomLabelCheck: existValidator('CustomLabel', (l) => !!l),
      DescriptionCheck: existValidator('*Description', (d) => d && d.trim()),
      FormatCheck: existValidator('*Format'),
      DurationCheck: existValidator('*Duration'),
      LocationCheck: existValidator('*Location'),
      DispatchTimeMaxCheck: existValidator('*DispatchTimeMax'),
      ReturnsAcceptedOptionCheck: existValidator('*ReturnsAcceptedOption'),
      TitleLength80Check: existValidator(
        '*Title',
        (title) => (title ?? '').length <= 80,
      ),
      TitleStopWordsCheck: existValidator(
        '*Title',
        (title) =>
          !stopWords.some((stopWord) => {
            const stopWordRegex = new RegExp(`((^| )${stopWord}( |$))`, 'gi');
            return stopWordRegex.test(title);
          }),
      ),
      DescriptionStopWordsCheck: existValidator(
        '*Description',
        (desc) =>
          !stopWords.some((stopWord) => {
            const stopWordRegex = new RegExp(`((^| )${stopWord}( |$))`, 'gi');
            return stopWordRegex.test(desc);
          }),
      ),
      PickURLCheck: optionalExistValidator('PicURL', (p) => !!p),
      CategoryCheck: optionalExistValidator('*Category', (c) => !!c),
      TitleCheck: optionalExistValidator('*Title', (t) => !!t),
      ConditionCheck: optionalExistValidator('*ConditionID'),
      RestrictedBrandCheck: optionalExistValidator('*C:Brand', isValidBrand),
      MPNCheck: optionalExistValidator('*C:Manufacturer Part Number'),
      // disable this check for now
      // IsEbayRollunActiveCheck: optionalExistValidator(
      //   'CustomLabel',
      //   (rid) => !activeRids.rollun.includes(rid),
      // ),
      IsEbayPlaisirActiveCheck: optionalExistValidator(
        'CustomLabel',
        (rid) => !activeRids.plaisir.includes(rid),
      ),
      DontSellTagsCheck: optionalExistValidator(
        'CustomLabel',
        (rid) => !dontSellItems.includes(rid),
      ),
    };

    return data.reduce(
      (acc, row) => {
        const notPassedValidators = Object.entries(validators).filter(
          ([, validator]) => !validator(row),
        );

        notPassedValidators.length === 0
          ? acc.succeed.push(row)
          : acc.failed.push({
              FailReason: `Row didn't pass validator(s): ${notPassedValidators
                .map(([name]) => name)
                .join(', ')}. `,
              ...row,
            });

        return acc;
      },
      {
        failed: [],
        succeed: [],
      },
    );
  };

  withInlinedCompatibles = (data: Array<any>): Array<any> => {
    return data
      .map((item) => {
        if (!item.VehiclesCompatibles) return item;
        // // add compatibles as lines after main line
        // // example:
        // //  field1, field2, Relationship, RelationshipDetails,                            field3
        // //  value1, value2,             ,                    ,                            value3
        // //        ,       ,Compatibility, Make=make|Model=model|Submodel=submodel|Year=year
        // //        ,       ,Compatibility, Make=make|Model=model|Submodel=submodel|Year=year
        // //        ,       ,Compatibility, Make=make|Model=model|Submodel=submodel|Year=year

        const compatibles = item.VehiclesCompatibles;
        delete item.VehiclesCompatibles;
        const columns = Object.keys(item);
        const compatiblesLines = compatibles.map(
          ({ make, year, model_slim, submodel }: CompatibleVehicle) => {
            return columns.reduce((acc: { [key: string]: string }, column) => {
              if (column === 'Relationship') {
                acc[column] = 'Compatibility';
              } else if (column === 'RelationshipDetails') {
                acc[
                  column
                ] = `Make=${make}|Model=${model_slim}|Submodel=${submodel}|Year=${year}`;
              } else {
                acc[column] = '';
              }
              return acc;
            }, {});
          },
        );
        return [item, ...compatiblesLines];
      })
      .flat();
  };

  downloadFileExchange = async ({ filterFailed = false } = {}) => {
    if (filterFailed) {
      const { failed, succeed } = await this.filterFailed(
        this.state.fileExchangeData,
      );
      if (failed.length !== 0) {
        downloadDataWithContentType(
          parse(this.withInlinedCompatibles(failed)),
          'text/csv',
          `fileExchangeFailed.csv`,
        );
      }
      if (succeed.length !== 0) {
        downloadDataWithContentType(
          parse(this.withInlinedCompatibles(succeed)),
          'text/csv',
          `fileExchangeSucceed.csv`,
        );
      }
    } else {
      const data = this.state.fileExchangeData;
      downloadDataWithContentType(
        parse(
          data.length ? this.withInlinedCompatibles(data) : [{ noItems: '' }],
        ),
        'text/csv',
        'fileExchange.csv',
      );
    }
  };

  prepareData = (data: Array<Array<string>>) => {
    const {
      fetchType: { value },
    } = this.state;
    if (value === 'by_rollun_id_and_epid') {
      return data.map(([ePID, rollunId]) => ({
        ePID,
        rollunId,
      }));
    }
    if (value === 'by_rollun_id') {
      return data.map(([rollunId]) => ({ rollunId }));
    }
    if (value === 'by_rollun_id_and_sr_name') {
      return data.map(([rollunId, supplier]) => ({
        rollunId,
        supplier,
      }));
    }
    return [];
  };

  getPlaceholder = (type: string) => {
    if (type === 'by_rollun_id_and_epid') {
      return 'Paste ePIDs and Rollun IDs';
    }
    if (type === 'by_rollun_id') {
      return 'Paste Rollun Ids separated by line breaks';
    }
    if (type === 'by_rollun_id_and_sr_name') {
      return 'Paste Rollun ids with supplier name separated by line breaks';
    }
    return undefined;
  };

  render() {
    const {
      fetchType,
      isLoading,
      error,
      progress,
      currentMarketplace,
      fileExchangeData,
      currentSupplier,
    } = this.state;
    const { classes } = this.props;

    return (
      <Box padding={1}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Box className={classes.inputs}>
            <Box>
              <label htmlFor="mp">Market place</label>
              <ReactSelect
                inputId="mp"
                onChange={(value: any) =>
                  this.setState({
                    currentMarketplace: value.value as EbayMarketplace,
                  })
                }
                className={classes.select}
                value={{
                  value: currentMarketplace,
                  label: currentMarketplace,
                }}
                options={this.MARKETPLACES.map((mp) => ({
                  value: mp,
                  label: mp,
                }))}
              />
            </Box>
            <Box>
              <label htmlFor="supplier">Supplier</label>
              <ReactSelect
                onChange={(value: any) => {
                  if (value.value === 'Uniform (Rollun id and supplier name)') {
                    this.setState({
                      currentSupplier: value.value,
                      fetchType: this.FETCHING_DATA_OPTIONS[2],
                    });
                    return;
                  }
                  this.setState({ currentSupplier: value.value });
                }}
                className={classes.select}
                value={{
                  value: currentSupplier,
                  label: currentSupplier,
                }}
                options={[
                  ...SUPPLIER_NAMES,
                  'Uniform (Rollun id and supplier name)',
                ].map((name) => ({
                  value: name,
                  label: name,
                }))}
              />
            </Box>
            <Box>
              <label htmlFor="fetch">The way of fetching data</label>
              <ReactSelect
                className={classes.select}
                isDisabled={
                  // @ts-expect-error
                  this.state.currentSupplier ===
                  'Uniform (Rollun id and supplier name)'
                }
                onChange={(value: any) => this.setState({ fetchType: value })}
                value={fetchType}
                options={this.FETCHING_DATA_OPTIONS}
              />
            </Box>
          </Box>
          <InputForm
            isLineValid={this.isLineValid}
            placeholder={this.getPlaceholder(fetchType.value)}
            onDataChange={(data) => {
              this.setState({ ePIDsAndRollunIDs: this.prepareData(data) });
            }}
          />
          <MuiButton
            color="primary"
            disabled={isLoading}
            onClick={this.getFileExchangeData}
          >
            Create File exchange
          </MuiButton>
        </form>
        <div>
          <Box marginY={2}>
            {isLoading && <Spinner />}
            <Typography align="center">{progress}</Typography>
          </Box>
          {error && <ErrorView error={error} />}
        </div>
        {!isLoading && fileExchangeData.length !== 0 && (
          <Box display="flex" marginTop={2}>
            <MuiButton
              color="primary"
              onClick={() => this.downloadFileExchange()}
              disabled={isLoading}
              title="Download file exchange"
            >
              Download File Exchange
            </MuiButton>
            <Box marginLeft={1}>
              <MuiButton
                color="primary"
                onClick={() =>
                  this.downloadFileExchange({ filterFailed: true })
                }
                disabled={isLoading}
              >
                Download File Exchange without failed
              </MuiButton>
            </Box>
          </Box>
        )}
      </Box>
    );
  }
}

export default withStyles(classes)(PrepareListingsDataContainer);
