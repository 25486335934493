import React, { Component } from 'react';
import { ErrorType } from '../../../../utils/common.types';
import { httpErrorHandler } from '../../../../utils/common.utils';
import Select from 'react-select';
import { Button, ErrorView, Spinner } from '../../../../UI';
import { createProductsFromSupplier } from '../../utils/createProductFromSupplier';

interface IState {
  isLoading: boolean;
  error: ErrorType | null;
  brandsIds: Array<string>;
  selectedSupplier: string;
  result: {
    RollunID: string;
    BrandID: string;
    MPN: string;
    CSN: string;
    SupplierName: string;
  } | null;
}

interface IProps {
  suppliersNames: Array<string>;
}

class CreateOneProduct extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoading: false,
      error: null,
      brandsIds: [],
      result: null,
      selectedSupplier: props.suppliersNames[0] || 'No suppliers in list...',
    };
  }

  onSupplierNameSelect = (val: any) => {
    this.setState({ selectedSupplier: val.value });
  };

  onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    createProductsFromSupplier([
      {
        // TODO i dont known how to get data from form event without ts error...
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        supplier_id: e.target.csn.value,
        supplier_name: this.state.selectedSupplier,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        ...(e.target['amazon-sku'].value && {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          sku: e.target['amazon-sku'].value,
        }),
      },
    ])
      .then((res) => {
        if (res.error) {
          this.setState({
            error: {
              text: `${res.error} ${res.message || ''}`,
              code: res.status || 500,
            },
          });
        } else if (res.problems.length > 0) {
          const { reason, itemTask = null } = res.problems[0];
          const { supplier_name, supplier_id, sku } = itemTask || {};
          this.setState({
            error: {
              text: `${reason}. SuppName: [${supplier_name}], CSN: [${supplier_id}], SKU: [${sku}]`,
              code: -1,
            },
          });
        } else {
          const {
            id: RollunID,
            brand_id: BrandID,
            manufacturer_part_number: MPN,
            supplier_id: CSN,
            supplier_name: SupplierName,
          } = res.items[0];
          this.setState({
            result: {
              RollunID,
              BrandID,
              MPN,
              CSN,
              SupplierName,
            },
          });
        }
        console.log(res);
      })
      .catch((err) =>
        httpErrorHandler(err, (code, text) => {
          this.setState({ error: { code, text } });
        }),
      )
      .finally(() => this.setState({ isLoading: false }));
  };

  render() {
    const { selectedSupplier, error, result, isLoading } = this.state;

    if (isLoading) {
      return (
        <div className="py-5">
          <Spinner />
        </div>
      );
    }

    if (error) {
      return (
        <ErrorView error={error}>
          <Button
            color="primary"
            onClick={() => this.setState({ error: null, isLoading: false })}
          >
            Retry
          </Button>
        </ErrorView>
      );
    }

    if (result) {
      return (
        <div className="my-2 align-items-center">
          <div style={{ maxWidth: 300, margin: '0 auto' }}>
            <h3 className="text-center">Product created!</h3>
            {Object.entries(result).map(([key, val]) => {
              return (
                <h5 className="my-1 d-flex justify-content-between" key={key}>
                  <span>{key}:</span>
                  <span>{val}</span>
                </h5>
              );
            })}
            <Button
              color="primary"
              block
              className="mt-2"
              onClick={() => this.setState({ result: null })}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    }

    return (
      <form onSubmit={this.onFormSubmit}>
        <h5 className="my-2">Select supplier</h5>
        <Select
          value={{ value: selectedSupplier, label: selectedSupplier }}
          className="my-2"
          required
          options={this.props.suppliersNames.map((name) => ({
            value: name,
            label: name,
          }))}
          onChange={this.onSupplierNameSelect}
        />
        <h5 className="my-2">CSN - Catalog Supplier Number</h5>
        <input
          type="text"
          placeholder="Enter CSN here..."
          required
          className="form-control my-1"
          name="csn"
        />
        <h5>
          Optional amazon sku. If provided - ID to SKU connection will be
          created
        </h5>
        <input
          type="text"
          placeholder="Enter Amazon SKU here..."
          className="form-control my-1"
          name="amazon-sku"
        />
        <Button color="primary" className="my-2" type="submit">
          Create
        </Button>
      </form>
    );
  }
}

export default CreateOneProduct;
