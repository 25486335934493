import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import { randomString } from 'rollun-ts-utils';
import { Button, HiddenInput } from '../../../../../../UI';
import Select from 'react-select';
import { VALIDATORS_NAMES } from '../../../../constants';
import { Box, Typography } from '@material-ui/core';
import { ValidatorParam } from '../../../../../Table/components/CopyPasteUploaders/CopyPasteUploader';

interface SelectValidatorsProps {
  validators?: ValidatorParam[];
  fieldNames: string[];
  onUpdate: (value: any, path: string) => void;
}

const SelectValidators: FC<SelectValidatorsProps> = ({
  validators,
  fieldNames,
  onUpdate,
}) => {
  return (
    <Box>
      <Typography variant="h6">Validators:</Typography>
      {!validators || validators.length === 0 ? (
        <Typography variant="h6">No validators...</Typography>
      ) : (
        <Box>
          {validators.map((validator, idx) => {
            return (
              <Card key={randomString(3)} style={{ minWidth: 300 }}>
                <Button
                  block
                  onClick={() =>
                    onUpdate(
                      (validators || []).filter((_, i) => i !== idx),
                      '.validators',
                    )
                  }
                  color="danger"
                >
                  Delete
                </Button>
                <div className="d-flex justify-content-between mt-1">
                  <h5 className="mt-2 mr-1">Type:</h5>
                  <Select
                    value={{
                      value: validator.type || 'empty',
                      label: validator.type || 'empty',
                    }}
                    className="col-md-6 mb-2 text-dark"
                    options={VALIDATORS_NAMES.map((name) => ({
                      value: name,
                      label: name,
                    }))}
                    onChange={(value: any) => {
                      onUpdate(value.value, `.validators[${idx}].type`);
                    }}
                  />
                </div>
                <div className="d-flex justify-content-between mt-1">
                  <h5 className="mt-2 mr-1">Field:</h5>
                  <Select
                    value={{
                      value: validator.field || '',
                      label: validator.field || '',
                    }}
                    className="col-md-6 mb-2 text-dark"
                    options={(fieldNames || []).map((name) => ({
                      value: name,
                      label: name,
                    }))}
                    onChange={(value: any) => {
                      onUpdate(value.value, `.validators[${idx}].field`);
                    }}
                  />
                </div>
                <div className="d-flex justify-content-between mt-1">
                  <h5 className="mt-2 mr-1">Param:</h5>
                  <HiddenInput
                    initValue={validator.param}
                    onSubmit={(str) =>
                      onUpdate(str, `.validators[${idx}].param`)
                    }
                  />
                </div>
              </Card>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default SelectValidators;
