import { FluidFormField } from '../UI/FluidForm';
import { FC, useState } from 'react';
import React from 'react';
import { FluidForm } from '../UI';
import MuiIconButton from '../UI/MuiIconButton';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

export interface NewItemCreatorDialogProps {
  formConfig: FluidFormField[];
  isDisabled?: boolean;
  title?: string;

  onFormSubmit(data: Record<string, unknown>): void;
}

const NewItemCreatorDialog: FC<NewItemCreatorDialogProps> = ({
  formConfig,
  title,
  isDisabled,
  onFormSubmit,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleFormSubmit = (data: Record<string, unknown>) => {
    console.log('sended data: ', data);
    onFormSubmit(data);
    setIsOpen(false);
  };

  return (
    <div>
      <MuiIconButton
        title="Create New item"
        color="success"
        width={40}
        height={40}
        iconName="plus-square"
        disabled={isDisabled}
        onClick={() => setIsOpen(true)}
      />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <DialogTitle>{title || 'Create new item'}</DialogTitle>
        <DialogContent>
          <FluidForm formConfig={formConfig} onFormSubmit={handleFormSubmit} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default NewItemCreatorDialog;
