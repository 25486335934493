import { SUPPLIERS } from '../../../../../constants/suppliers';
import { SHIPPING_TYPES } from '../../../../../constants/shipping-types';

const specialConditions: Record<string, string[]> = {
  pu_ds_a: ['UPS Ground'],
  pu_ds_b: ['UPS Ground'],
  pu_ds_c: ['UPS Ground'],
  pu_ds_d: ['UPS Ground'],
  pu_ds_e: ['UPS Ground'],
  tr_ds_a: ['UPS Ground'],
};

export function getAbbreviationBySupplierName(supplierName: string) {
  const correctSupplier =
    supplierName === 'RockyMountainKit' ? 'RockyMountain' : supplierName;
  const supplierInfo = getSupplierInfoBySupplierName(correctSupplier);
  return supplierInfo?.abbreviation as string;
}

export function getShippingOptionsBySupplierName(
  supplierName: string,
  fullSupplierName: string,
) {
  if (fullSupplierName in specialConditions) {
    return specialConditions[fullSupplierName];
  }

  if (fullSupplierName.startsWith('pickup')) {
    return SHIPPING_TYPES.pickup;
  }

  const correctSupplier =
    supplierName === 'RockyMountainKit' ? 'RockyMountain' : supplierName;
  const supplierInfo = getSupplierInfoBySupplierName(correctSupplier);
  console.log(fullSupplierName, supplierInfo);

  const shippingTypes = (supplierInfo?.shippingTypes as string[]) || [];

  if (shippingTypes.length === 0) {
    return /pickup/gi.test(supplierName)
      ? SHIPPING_TYPES.pickup
      : SHIPPING_TYPES.dropship;
  }

  return shippingTypes;
}

export function getSupplierInfoBySenderName(warehouse: string) {
  return SUPPLIERS.find(
    ({ senders }) =>
      senders.length !== 0 && senders.includes(warehouse.toLowerCase()),
  );
}

export function getSupplierInfoByWarehouseName(warehouse: string) {
  return SUPPLIERS.find(
    ({ virtual_warehouse }) => virtual_warehouse === warehouse,
  );
}

export function getSupplierInfoBySupplierName(supplier: string) {
  return SUPPLIERS.find(({ id }) => id === supplier);
}

export function getSupplierInfoByAbbreviationName(abbr: string) {
  return SUPPLIERS.find(({ abbreviation }) => abbreviation === abbr);
}
