import { Component } from 'react';
import { FieldConfig } from '../index';
import { match, RouteProps, RouterProps } from 'react-router';
import { Eq, Query } from 'rollun-ts-rql';
import HttpDatastore from 'rollun-ts-datastore';
import { CopyPasteUploaderParams } from './Table/components/CopyPasteUploaders/CopyPasteUploader';
import { WebHookConfig } from './Table/components/webHookActivator/WebhookActivator';
import { ServiceInfo, UserIdentity } from './RootApp/Root.app';
import { httpErrorHandler } from '../utils/common.utils';
import { ErrorType } from '../utils/common.types';
import { isJSON } from 'rollun-ts-utils/dist';
import { CACHE_NAME_PREFIX } from './Table/util/constants';

export type AppType =
  | 'PrepareListingsData'
  | 'HelpPage'
  | 'CatalogUtils'
  | 'grid'
  | 'BarcodeApp'
  | 'cardPicker'
  | 'ControlCenter'
  | 'BrandsMapper'
  | 'ServiceConstructor'
  | 'EditableTree'
  | 'ACLRulesEditor'
  | 'ParcelApp'
  | 'EbayRefreshToken'
  | 'BarcodeScannerDemo'
  | 'AddNewUserApp'
  | 'MergingBrands'
  | 'MetricUI'
  | 'UnidentifiedProductsApp'
  | 'BagFillingApp'
  | 'ReturnApp';

export interface TableHeaderButton {
  name:
    | 'RofInventoryUpdater'
    | 'DownloadClickNShipInfo'
    | 'NewItemsFromTable'
    | 'CreateSelectedItems'
    | 'GetRidsByBrandAndMPN'
    | 'HowToReturn'
    | 'HowToReturnV3'
    | 'CreateBag'
    | 'UploadPartNumbersToDeals'
    | 'DealsToDSPackaging'
    | 'DownloadBagInfo'
    | 'DownloadPickUpInfo'
    | 'DownloadSrCatalogPartnumbers'
    | 'DownloadReturnListDeals'
    | 'RowsHighlighter'
    | 'CreateDeal'
    | 'Scenarios'
    | 'UpdateEmptySuppliers'
    | 'AmazonMsinRidCheck'
    | 'ClearMpProblems'
    | 'TableHeaderButtonDocs';
}

export interface HeaderConfig {
  additionalHeaderComponent: 'CRMControls';
}

export interface GridParams {
  userNote?: string;
  isDataStoreLocal?: boolean;
  defaultQuery?: string;
  datastoreUrl: string;
  urlFromPathParam?: boolean;
  idField?: string;
  enableDeleteAll?: boolean;
  enableDeleteItem?: boolean;
  fieldsConfig?: FieldConfig[];
  pasteUploaderParams?: CopyPasteUploaderParams;
  hooks?: WebHookConfig[];
  tableHeaderButtons?: TableHeaderButton[];
  createItemFieldsConfig?: Array<CreateFormFieldConfig>;
  header?: HeaderConfig;
}

export interface AppConfig {
  sessionStorageName?: string;
  appType: AppType;
  appName: string;
  appPath: string;
  appParams: {
    EbayRefreshTokenParams?: {
      agreementLink: string;
    };
    GraphEditorParams?: any;
    NodeRedWorkflowParams?: {
      sourceDatastoreUrl?: string;
      destDatastoreUrl?: string;
      nodeRedInputNodeId?: string;
    };
    HelpPageParams?: {
      // help text as markdown
      userNote: string;
    };
    // userNote?: string;
    // isDataStoreLocal?: boolean;
    // defaultQuery?: string;
    // datastoreUrl: string;
    // urlFromPathParam?: boolean;
    // idField?: string;
    // enableDeleteAll?: boolean;
    // enableDeleteItem?: boolean;
    // fieldsConfig?: FieldConfig[];
    // pasteUploaderParams?: CopyPasteUploaderParams;
    // hooks?: WebHookConfig[];
    // createItemFieldsConfig?: Array<CreateFormFieldConfig>
    CatalogUtils?: any;
    CreateProductFromSupplier?: any;
    gridParams?: GridParams;
    treeNameField?: string;
    treeDataField?: string;
    BarcodeAppParams?: any;
    cardPickerParams?: {
      datastoreUrl: string;
    };
    ControlCenterParams?: {
      hooks?: WebHookConfig[];
    };
    BrandsMapperParams?: {
      brandsGridParams?: AppConfig['appParams']['gridParams'];
      unknownBrandsGridParams?: AppConfig['appParams']['gridParams'];
    };
    ServiceConstructorParams?: any;
    EditableTreeParams?: {
      treeNameField?: string;
      treeDataField?: string;
      datastoreUrl: string;
    };
    ACLRulesEditorParams?: any;
    ParcelAppParams?: any;
    // TODEL
    // DataHandlerEditor?: {},
    // TODEL
    // ProviderTree?: {},
    // TODEL
    SchemaEditor__test?: any;
    // TODEL
    // EditServiceConfig?: {}
  };
}

interface IState {
  appConfig: AppConfig | null;
  error: ErrorType | null;
}

export interface GridParams {
  userNote?: string;
  isDataStoreLocal?: boolean;
  defaultQuery?: string;
  datastoreUrl: string;
  urlFromPathParam?: boolean;
  idField?: string;
  enableDeleteAll?: boolean;
  enableDeleteItem?: boolean;
  fieldsConfig?: FieldConfig[];
  pasteUploaderParams?: CopyPasteUploaderParams;
  hooks?: WebHookConfig[];
  createItemFieldsConfig?: Array<CreateFormFieldConfig>;
  tagsUpdaterParams?: TagsUpdaterParams;
  openInBpmnEditor?: boolean;
}

export interface TagsUpdaterParams {
  enableTagsUpdater: boolean;
  parsedFormat?: boolean;
}

export interface CreateFormFieldConfig {
  field: string;
  editorName?: string; // one of (selector)
  options?: Array<string>; // only for selector
}

export interface AbstractServiceProps
  extends RouteProps,
    Omit<RouterProps, 'children'> {
  resourceName: string;
  availableServices: { [key: string]: ServiceInfo };
  userIdentity: UserIdentity;
  match?: match<{ id: string }>;
}

/**
 * Base component for every service.
 * It loads service config, via {appName}
 * Also, it caches configs to local storage
 */
// TODO refactor AbstractService to contain common error handling
class AbstractService<P extends AbstractServiceProps> extends Component<
  P,
  IState
> {
  datastore = new HttpDatastore<{
    id: string;
    resource: string;
    config: AppConfig;
  }>('/api/datastore/UserFrontConfig');

  state: IState = { appConfig: null, error: null };

  /**
   * get service config, form cache.
   * in case, cache does not exists, component will fetch config on its own.
   */

  async _getAppConfig(): Promise<AppConfig> {
    const cache = localStorage.getItem(
      CACHE_NAME_PREFIX + this.props.resourceName,
    );
    if (cache && isJSON(cache)) {
      return JSON.parse(cache);
    }
    return this.datastore
      .query(new Query({ query: new Eq('resource', this.props.resourceName) }))
      .then((res) => {
        // For now any request to this ds returns all containing data,
        // So, when backend supports Query, need to remove this code,
        // and just return res[0].
        const currService =
          res.length === 1
            ? res[0]
            : res.find((el) => el.resource === this.props.resourceName);
        if (currService) {
          localStorage.setItem(
            CACHE_NAME_PREFIX + this.props.resourceName,
            JSON.stringify(currService.config),
          );
          return currService.config;
        }
        throw Error(
          `There is no config for "${this.props.resourceName}" in our database`,
        );
      });
  }

  async _getConfig() {
    try {
      const appConfig = await this._getAppConfig();
      this.setState({ appConfig });
    } catch (e) {
      httpErrorHandler(e, (code, text) =>
        this.setState({ error: { code, text } }),
      );
    }
  }

  componentDidMount() {
    this._getConfig().catch((e) => {
      httpErrorHandler(e, (code, text) =>
        this.setState({ error: { code, text } }),
      );
    });
  }

  reloadHome = () => {
    this.setState({ error: null, appConfig: null });
    localStorage.removeItem(CACHE_NAME_PREFIX + this.props.resourceName);
    // add little delay, to let user know that something is happening
    // in some cases, reload is as fast, so you can't see reloading process.
    setTimeout(
      () =>
        this._getConfig().catch((e) => {
          httpErrorHandler(e, (code, text) =>
            this.setState({ error: { code, text } }),
          );
        }),
      300,
    );
  };
}

export default AbstractService;
