import { createTheme } from '@material-ui/core';
import { green, orange, red } from '@material-ui/core/colors';

export const zIndexes = {
  low: 10,
  aboveNavigation: 1100,
  biggest: 1000000000,
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#6477d8',
      dark: '#2b4ca6',
      light: '#98a6ff',
    },
    secondary: {
      main: '#f53',
      dark: '#bd563e',
      light: '#ffb697',
    },
    success: green,
    warning: orange,
    error: red,
  },
  overrides: {
    MuiDrawer: {
      paperAnchorDockedLeft: {
        height: `calc(100% - ${14 * 8}px)`,
        marginTop: 8 * 8,
        marginBottom: 8 * 8,
      },
    },
    MuiDialog: {
      paper: {
        zIndex: zIndexes.biggest,
      },
    },
    MuiTooltip: {
      popper: {
        zIndex: zIndexes.biggest,
      },
    },
    MuiTypography: {
      body2: {
        fontSize: 12,
      },
    },
  },
});

export const getBackgroundColor = (offset = 0) => {
  return `rgb(${52 + offset},${58 + offset},${64 + offset})`;
};

export default theme;
