import React, { FC, useState } from 'react';
import BrandsForm from './BrandsForm';
import { noop } from '../../../utils/common.utils';
import { Card, makeStyles, Theme, Box, Typography } from '@material-ui/core';
import DefaultEditor from '../../Table/components/Table/Cell/Editors/DefaultEditor';
import TableContainer from '../../Table/components/Table/TableContainer';
import { AppConfig } from '../../AbstractService';
import _ from 'lodash';

const BRAND_ALIASES_DATA_STORE = '/api/datastore/brandAliasesStatistic';

const useStyles = makeStyles((theme: Theme) => ({
  brandsFormWrap: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
    padding: theme.spacing(1.5),
  },
}));

interface IProps {
  currentResourceName: string;
  appConfig: AppConfig;
}

const MergeBrands: FC<IProps> = ({ currentResourceName, appConfig }) => {
  const [selectedItem, setSelectedItem] = useState();
  const descriptionConfigPath = 'appParams.MergingBrandsParams.userNote';
  const userNote = _.get(appConfig, descriptionConfigPath);
  const classes = useStyles();

  return (
    <Box>
      <TableContainer
        appName={currentResourceName}
        onRowSelect={(row: any) => {
          setSelectedItem(row);
        }}
        columnsConfig={{
          mapping: {
            renderer: DefaultEditor,
            isEditable: false,
          },
        }}
        enableDeleteItem={true}
        dataStoreUrl={BRAND_ALIASES_DATA_STORE}
        onChangeQuery={noop}
        descriptionConfigPath={`config.${descriptionConfigPath}`}
        currentResourceName={currentResourceName}
        userNote={userNote}
      />
      <Card className={classes.brandsFormWrap}>
        <Typography variant="h5">Brands form</Typography>
        <BrandsForm selectedBrand={selectedItem} />
      </Card>
    </Box>
  );
};

export default MergeBrands;
