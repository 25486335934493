import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from '../../../../../UI/Spinner';
import GenericDeal, { DealProps, DealType } from '../../GenericDeal';
import { useGetOrderDeal } from '../hooks/useGetOrderDeal';
import { ErrorView } from '../../../../../UI';
import { useMutateDeal } from '../hooks/useMutateDeal';
import { Box } from '@material-ui/core';
import MuiButton from '../../../../../UI/MuiButton';
import { ApiClientsFba } from '../../api-clients/api-clients-fba';

const FbaDeal: FC<DealProps> = ({ buttons, fieldsConfig, widgets }) => {
  const { id } = useParams<{ id: string }>();
  const { data, error, isFetching } = useGetOrderDeal(id);
  const { handleUpdateDeal } = useMutateDeal();
  const history = useHistory();

  if ((isFetching && !data) || !data) {
    return <Spinner />;
  }

  if (error && !data) {
    return (
      <ErrorView
        error={{
          text: (error as Error).message,
          code: (error as Error).message.includes('not found') ? 404 : 403,
        }}
      >
        <Box
          style={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <MuiButton onClick={() => history.goBack()}>Back</MuiButton>
          <MuiButton onClick={() => location.reload()}>Reload</MuiButton>
        </Box>
      </ErrorView>
    );
  }

  return (
    <GenericDeal
      fieldsConfig={fieldsConfig}
      buttons={buttons}
      data={data}
      type={DealType.Fba}
      widgets={widgets}
      dealConfig={{
        disableStatusSelector: false,
        disableItemsTable: true,
      }}
      rawData={data ? new ApiClientsFba().convertModelToDTO(data!) : {}}
      onDealUpdate={handleUpdateDeal}
      availableStatuses={[
        {
          id: '39',
          name: 'Order was placed',
          color: '#FFC107',
        },
        {
          id: '40',
          name: 'Track-numbers getting',
          color: '#20ff07',
        },
        {
          id: '41',
          name: 'Prep center getting',
          color: '#07d2ff',
        },
        {
          id: '42',
          name: 'Prep center',
          color: '#4507ff',
        },
        {
          id: '43',
          name: 'Received',
          color: '#ff07d2',
        },
        {
          id: '44',
          name: 'Problem',
          color: '#ff0707',
        },
        {
          id: '45',
          name: 'FBA getting',
          color: '#5b3131',
        },
        {
          id: '46',
          name: 'FBA',
          color: '#595a42',
        },
        {
          id: '47',
          name: 'Sold out',
          color: '#c2dfea',
        },
        {
          id: '48',
          name: 'Removal',
          color: '#9d7272',
        },
        {
          id: '49',
          name: 'Canceled',
          color: '#cd98ff',
        },
      ]}
    />
  );
};

export default FbaDeal;
