import React from 'react';
import AbstractEditor, { IEditorProps } from './AbstractEditor';
import { InputBase, Theme, withStyles } from '@material-ui/core';

export interface IEditorState {
  value: string | null;
}

const classes = (theme: Theme) => ({
  root: {
    backgroundColor: 'lightgray',
    width: '100%',
    padding: theme.spacing(1),
    border: theme.palette.primary.main,
  },
});

class DefaultEditor extends AbstractEditor<IEditorState> {
  constructor(props: IEditorProps) {
    super(props);
    this.state = { value: props.value };
  }

  getInputNode() {
    return this.inputRef.current;
  }

  getValue() {
    return { [this.props.column.key]: this.state.value };
  }

  finishEditing = () => {
    // must ignore type error, because in my case values can be null.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    this.props.onCommit(this.state.value);
    this.props.onCommitCancel();
  };

  handleInputState = (e: KeyboardEvent) => {
    switch (e.code) {
      case 'Enter':
        return this.finishEditing();
      case 'Escape':
        return this.props.onCommitCancel();
    }
  };

  componentDidMount() {
    document.addEventListener('keyup', this.handleInputState);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleInputState);
  }

  handleValueChange(value: string) {
    this.setState({ value: value === 'null' ? null : value });
  }

  render() {
    const { value } = this.state;
    const { classes } = this.props;

    return (
      <InputBase
        className={classes.root}
        fullWidth
        value={value === null ? 'null' : value}
        onChange={(e) => this.handleValueChange(e.target.value)}
        onBlur={this.finishEditing}
        ref={this.inputRef}
      />
    );
  }
}

export default withStyles(classes)(DefaultEditor);
