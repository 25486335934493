import React from 'react';
import AppContainer from '../../layout/AppContainer/AppContainer';
import WebHookActivatorList from '../Table/components/webHookActivator/WebhookActivatorList';
import AbstractService, { AbstractServiceProps } from '../AbstractService';
import { ErrorView, Spinner } from '../../UI';

class ControlCenterApp extends AbstractService<AbstractServiceProps> {
  render() {
    const { appConfig, error } = this.state;

    if (error) {
      return (
        <AppContainer services={{}}>
          <div className="w-100 h-100 d-flex flex-column justify-content-center">
            <ErrorView error={error}>
              <div className="d-flex justify-content-center p-3">
                <div
                  className="btn btn-primary cursor-pointer"
                  onClick={this.reloadHome}
                >
                  Home
                </div>
              </div>
            </ErrorView>
          </div>
        </AppContainer>
      );
    }

    if (appConfig === null) {
      return <Spinner />;
    }

    if (!appConfig.appParams.ControlCenterParams) {
      throw new Error(`Field ControlCenterParams is mandatory in appParams`);
    }

    const { hooks = [] } = appConfig.appParams.ControlCenterParams;

    const {
      availableServices,
      userIdentity: { user, role },
    } = this.props;
    return (
      <AppContainer
        services={availableServices}
        currentResourceName={this.props.resourceName}
        user={user}
        role={role}
      >
        <h3 className="text-center my-3">Control Center</h3>
        <WebHookActivatorList hooks={hooks} />
      </AppContainer>
    );
  }
}

export default ControlCenterApp;
