import React from 'react';
import { Box, DialogContent, Typography } from '@material-ui/core';
import AddableIconButton from './AddableIconButton';
import { Checkbox, Dialog } from '../../../../../UI';
import { useState } from 'react';
import { TagsUpdaterParams } from '../../../../AbstractService';

interface TagsUpdaterEditorProps {
  tagsUpdaterParams?: TagsUpdaterParams;
  deleteByPath: (name: string) => void;
  updateByPath: (name: string, newValue: any) => void;
}

export const TagsUpdaterEditor = ({
  tagsUpdaterParams,
  deleteByPath,
  updateByPath,
}: TagsUpdaterEditorProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <AddableIconButton
        name="tagsUpdaterParams.enableTagsUpdater"
        icon="tags"
        color="info"
        value={tagsUpdaterParams?.enableTagsUpdater}
        defaultValue={true}
        deleteByPath={deleteByPath}
        updateByPath={updateByPath}
        onClick={() => setIsOpen(true)}
      />
      <Dialog isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <DialogContent>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              style={{
                fontSize: 18,
              }}
            >
              parsedFormat:
            </Typography>
            <Checkbox
              initValue={!!tagsUpdaterParams?.parsedFormat}
              onChange={(value: boolean) => {
                const pathInGridParams = `appParams.gridParams.tagsUpdaterParams.parsedFormat`;
                value
                  ? updateByPath && updateByPath(pathInGridParams, true)
                  : deleteByPath && deleteByPath(pathInGridParams);
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
