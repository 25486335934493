import React, { FC } from 'react';
import { Box, Card, IconButton, Typography } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AllSecretVersions from './AllSecretVersions';

interface SecretRowProps {
  id: string;
  version: number;
  selected: boolean;
  onClick: (id: string | undefined) => void;
}

const SecretRow: FC<SecretRowProps> = ({ id, version, onClick, selected }) => {
  return (
    <Card
      key={id}
      elevation={1}
      style={{
        width: '100%',
        padding: 8,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        onClick={() => onClick(selected ? undefined : id)}
        style={{
          width: '100%',
          gap: 8,
          padding: 8,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flex={30}
        >
          <Typography>{`Name: ${id}`}</Typography>
          <Typography>{`Latest version: ${version}`}</Typography>
        </Box>
        <IconButton
          style={{
            flex: 1,
          }}
        >
          {selected ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>

      {selected && <AllSecretVersions id={id} />}
    </Card>
  );
};

export default SecretRow;
