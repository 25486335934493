import { FC, useContext, useState } from 'react';
import React from 'react';
import axios from 'axios';
import MuiButton from '../../../../../UI/MuiButton';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { useEffect } from 'react';
import HttpDatastore from 'rollun-ts-datastore';
import ReactSelect from 'react-select';
import { GlobalErrorContext } from '../../../../RootApp/Root.app';
import _ from 'lodash';
import { useCurrentQuery } from '../../../../../hooks/useCurrentQuery';

const useStyle = makeStyles((theme: Theme) => ({
  dialog: {
    minHeight: '160px',
  },
  processStatus: {
    margin: theme.spacing(2.5, 0),
  },
  error: {
    color: theme.palette.error.main,
    margin: theme.spacing(2.5, 0),
  },
}));

interface CreateSelectedItemsProps {
  defaultQuery: string | undefined;
  dataStoreUrl: string;
}

type ItemsToCreate =
  | {
      rid: string;
      mln: string;
    }[]
  | [];

type Marketplace = 'Ebay Plaisir' | 'Ebay Rollun';
const marketplaces = ['Ebay Plaisir', 'Ebay Rollun'];

const marketplaceAndUrlForRequest = {
  'Ebay Plaisir': '/api/webhook/EbayPlaisirCreateItemsForMarketplace',
  'Ebay Rollun': '/api/webhook/EbayRollunCreateItemsForMarketplace',
};

/**
 *  Button and modal window with uploading progress info
 *  that allows manager to select items she wants to upload
 *  to one of 'Ebay Rollun' or 'Ebay Plaisir' marketplaces
 */

const CreateSelectedItems: FC<CreateSelectedItemsProps> = ({
  defaultQuery,
  dataStoreUrl,
}) => {
  const globalAlert = useContext(GlobalErrorContext);

  const [status, setStatus] = useState('Fetching items for creation...');
  const [marketplaceForItems, setMarketplaceForItems] = useState(
    marketplaces[0],
  );
  const [itemsToCreate, setItemsToCreate] = useState<ItemsToCreate>([]);
  const [isProgressDialogOpen, setIsProgressDialogOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState('' as any);
  const [uploadedItemsCount] = useState(0);
  const tablesIdFieldName = 'id';
  const classes = useStyle();
  const currentQuery = useCurrentQuery();

  const fetchItemsToCreateInChunks = async (
    defaultQuery: string | undefined,
    dataStoreUrl: string,
  ) => {
    const datastore = new HttpDatastore<any>(dataStoreUrl, {
      idField: tablesIdFieldName,
    });

    const dataToCreate = await datastore.query(currentQuery);

    return dataToCreate.map((item) => ({ mln: item.id, rid: item.id }));
  };

  useEffect(() => {
    fetchItemsToCreateInChunks(defaultQuery, dataStoreUrl)
      .then((res) => {
        setItemsToCreate(res);
        setStatus(`Count of items to create: ${res.length}`);
      })
      .catch((error) => console.log(error));
  }, [defaultQuery]);

  const createItemsRequestsInChunks = async (
    destinationUrl: string,
    itemsToCreate: any[],
  ) => {
    const chunks = _.chunk(itemsToCreate, 10);
    const chunksOfChunks = _.chunk(chunks, 10);
    let errors: (string | null)[] = [];

    for (const chunk of chunksOfChunks) {
      const res = await Promise.allSettled(
        chunk.map((chunk) => axios.post(destinationUrl, { items: chunk })),
      );
      console.log('res', res);

      errors = errors.concat(
        res.map((r) => {
          if (r.status === 'rejected') {
            const lct = r?.reason?.config?.headers?.['lifecycleToken'] || '';
            const lifecycleToken = lct ? `LifecycleToken: ${lct}` : '';
            const errorText = `${r?.reason?.response?.data?.error}. ${lifecycleToken}`;

            return r?.reason?.response?.data?.error ? errorText : r.reason;
          }

          const errMsgs: any[] = [];

          const messages = r?.value?.data?.messages;

          if (messages?.length) {
            messages?.map((msg: any) => {
              if (msg?.level === 'error') {
                errMsgs.push(msg.text || '');
              }
            });
          }

          if (errMsgs?.length) {
            return errMsgs.join(', ');
          }

          return null;
        }),
      );
    }

    return errors.filter(Boolean) as string[];
  };

  const createItems = async () => {
    setIsUploading(true);
    const destinationUrl =
      marketplaceAndUrlForRequest[marketplaceForItems as Marketplace];

    try {
      const errors = await createItemsRequestsInChunks(
        destinationUrl,
        itemsToCreate,
      );

      if (errors?.length) {
        throw new Error(errors.join('\n'));
      }

      console.log('errors', errors);

      globalAlert?.showAlert(
        `${itemsToCreate.length} items were successfully uploaded to ${marketplaceForItems}`,
        'success',
      );
    } catch (e) {
      console.log('Error while uploading items: ', e);
      console.log('e', e);

      setStatus('Error while uploading items, contact site admin');
      setError(e as string);
      setIsUploading(false);
    }
  };

  return (
    <div>
      <MuiButton
        color="success"
        onClick={() => setIsProgressDialogOpen(true)}
        title="Create items selected by filter"
      >
        Create selected items
      </MuiButton>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isProgressDialogOpen}
        onClose={() => setIsProgressDialogOpen(false)}
      >
        <DialogTitle>{status}</DialogTitle>
        <DialogContent className={classes.dialog}>
          Choose marketplace for selected items:
          <ReactSelect
            inputId="mp"
            onChange={(value: any) => setMarketplaceForItems(value.value)}
            value={{
              value: marketplaceForItems,
              label: marketplaceForItems,
            }}
            options={marketplaces.map((mp) => ({
              value: mp,
              label: mp,
            }))}
          />
          {isUploading && (
            <Box className={classes.processStatus}>
              Uploading process started. Uploaded {uploadedItemsCount} items of{' '}
              {itemsToCreate.length}
            </Box>
          )}
          {error && (
            <Box className={classes.error}>
              {error.toString()}
              <br />
              {error?.messages?.[0]?.text}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <MuiButton
            color="success"
            onClick={createItems}
            loading={isUploading}
            disabled={isUploading || itemsToCreate.length === 0}
            title="Create items selected by filter"
          >
            Start creation process
          </MuiButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateSelectedItems;
