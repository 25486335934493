import React from 'react';
import { ErrorType } from '../utils/common.types';
import { AbstractServiceProps, AppConfig } from './AbstractService';
import AppContainer from '../layout/AppContainer/AppContainer';
import { ErrorView, Spinner } from '../UI';
import { Eq, Query } from 'rollun-ts-rql';
import HttpDatastore from 'rollun-ts-datastore';
import { isJSON } from 'rollun-ts-utils';
import { httpErrorHandler } from '../utils/common.utils';
import { CACHE_NAME_PREFIX } from './Table/util/constants';

interface RenderPropsServiceProps {
  renderService: (state: AppConfig, props: AbstractServiceProps) => JSX.Element;
}

interface IState {
  appConfig: AppConfig | null;
  error: ErrorType | null;
}

class RenderPropsService extends React.Component<
  RenderPropsServiceProps & AbstractServiceProps,
  IState
> {
  datastore = new HttpDatastore<{
    id: string;
    resource: string;
    config: AppConfig;
  }>('/api/datastore/UserFrontConfig');

  state: IState = { appConfig: null, error: null };

  /**
   * get service config, form cache.
   * in case, cache does not exists, component will fetch config on its own.
   */

  async _getAppConfig(): Promise<AppConfig> {
    const cache = localStorage.getItem(
      CACHE_NAME_PREFIX + this.props.resourceName,
    );
    if (cache && isJSON(cache)) {
      return JSON.parse(cache);
    }
    return this.datastore
      .query(new Query({ query: new Eq('resource', this.props.resourceName) }))
      .then((res) => {
        // For now any request to this ds returns all containing data,
        // So, when backend supports Query, need to remove this code,
        // and just return res[0].
        const currService =
          res.length === 1
            ? res[0]
            : res.find((el) => el.resource === this.props.resourceName);
        if (currService) {
          localStorage.setItem(
            CACHE_NAME_PREFIX + this.props.resourceName,
            JSON.stringify(currService.config),
          );
          return currService.config;
        }
        throw Error(
          `There is no config for "${this.props.resourceName}" in our database`,
        );
      });
  }

  async _getConfig() {
    try {
      const appConfig = await this._getAppConfig();
      this.setState({ appConfig });
    } catch (e) {
      httpErrorHandler(e, (code, text) =>
        this.setState({ error: { code, text } }),
      );
    }
  }

  componentDidMount() {
    this._getConfig().catch((e) => {
      httpErrorHandler(e, (code, text) =>
        this.setState({ error: { code, text } }),
      );
    });
  }

  reloadHome = () => {
    this.setState({ error: null, appConfig: null });
    localStorage.removeItem(CACHE_NAME_PREFIX + this.props.resourceName);
    // add little delay, to let user know that something is happening
    // in some cases, reload is as fast, so you can't see reloading process.
    setTimeout(
      () =>
        this._getConfig().catch((e) => {
          httpErrorHandler(e, (code, text) =>
            this.setState({ error: { code, text } }),
          );
        }),
      300,
    );
  };
  render() {
    const { appConfig, error } = this.state;

    if (error) {
      return (
        <AppContainer services={{}}>
          <div className="w-100 h-100 d-flex flex-column justify-content-center">
            <ErrorView error={error}>
              <div className="d-flex justify-content-center p-3">
                <div
                  className="btn btn-primary cursor-pointer"
                  onClick={this.reloadHome}
                >
                  Home
                </div>
              </div>
            </ErrorView>
          </div>
        </AppContainer>
      );
    }

    if (appConfig === null) {
      return <Spinner />;
    }

    if (!appConfig.appParams) {
      throw new Error(
        `Service config with path ${appConfig.appPath} must have appParams field`,
      );
    }

    return this.props.renderService(appConfig, this.props);
  }
}

export default RenderPropsService;
