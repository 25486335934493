import React from 'react';
import {
  AbstractServiceProps,
  AppConfig,
  GridParams,
} from '../AbstractService';
import { getCreateFormConfig, parseColumnsConfig } from './util/config.utils';
import AppContainer from '../../layout/AppContainer/AppContainer';
import TableHeaderButtonContainer from './components/TableHeaderButtons/TableHeaderButtonContainer';
import TableContainer from './components/Table/TableContainer';
import { QueryStringifier } from 'rollun-ts-rql';
import RenderPropsService from '../RenderPropsService';

export class TableApp extends React.Component<AbstractServiceProps> {
  renderTable(appConfig: AppConfig, props: AbstractServiceProps): JSX.Element {
    const serviceParams = appConfig.appParams.gridParams || ({} as GridParams);

    const {
      userIdentity: { user, role },
      resourceName,
      availableServices,
    } = props;
    const {
      idField,
      enableDeleteAll,
      enableDeleteItem,
      hooks = [],
      urlFromPathParam,
      isDataStoreLocal,
      userNote,
      fieldsConfig = [],
      createItemFieldsConfig = [],
      pasteUploaderParams,
      tableHeaderButtons = [],
      header,
      tagsUpdaterParams = {
        enableTagsUpdater: false,
      },
      openInBpmnEditor,
    } = serviceParams;

    console.log(serviceParams);
    const columnsConfig = parseColumnsConfig(fieldsConfig);
    const formConfig = getCreateFormConfig(createItemFieldsConfig);
    let { datastoreUrl } = serviceParams;
    if (urlFromPathParam && props.match) {
      const { match } = props;
      datastoreUrl += match.params.id;
    }
    let defaultQuery = serviceParams.defaultQuery;
    if (props.location && props.location.search) {
      const { search } = props.location;
      const query = search;
      defaultQuery = query[0] === '?' ? query.slice(1) : query;
    }
    const { appName } = appConfig;
    const formattedUserNote = (userNote || '').replace(/;/g, '\n');
    console.log({ appName, appConfig });

    return (
      <AppContainer
        user={user}
        role={role}
        currentResourceName={resourceName}
        services={availableServices}
      >
        <div>
          <TableHeaderButtonContainer
            buttons={tableHeaderButtons}
            hooks={hooks}
            dataStoreUrl={datastoreUrl}
            defaultQuery={defaultQuery}
            appConfig={appConfig}
          />
          <TableContainer
            appName={appName}
            onChangeQuery={(query) => {
              if (props.location) {
                props.history.push({
                  search: '?' + QueryStringifier.stringify(query),
                });
              }
            }}
            currentResourceName={props.resourceName}
            formConfig={formConfig}
            isLocal={isDataStoreLocal}
            defaultQuery={defaultQuery}
            dataStoreUrl={datastoreUrl}
            columnsConfig={columnsConfig}
            pasteUploaderParams={pasteUploaderParams}
            enableDeleteAll={enableDeleteAll}
            idField={idField}
            userNote={formattedUserNote}
            enableDeleteItem={enableDeleteItem}
            header={header}
            tagsUpdaterParams={tagsUpdaterParams}
            openInBpmnEditor={openInBpmnEditor}
          />
        </div>
      </AppContainer>
    );
  }

  render() {
    return (
      <RenderPropsService
        {...this.props}
        renderService={this.renderTable.bind(this)}
      />
    );
  }
}

export default TableApp;
