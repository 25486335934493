import React, { Component } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import SuppliersMapping from './SuppliersMapping';
import _ from 'lodash/fp';
import ReactDataGridAdapter from '../../../Table/components/Table/ReactDataGridAdapter';
import { LoadingStatusEnum } from '../../../../utils/common.types';
import NoData from '../../../Table/components/Table/NoData';

class SupplierMappingContainer extends Component {
  render() {
    return (
      <Tabs id="supplier-mapping-tabs" defaultActiveKey="first" className="m-2">
        <Tab eventKey="first" title="Find Rollun ID">
          <SuppliersMapping
            hookUrl="/api/webhook/SuppliersMappingFindRollunId"
            renderResponse={(res) => (
              <RenderSuppliersMappingFindRollunIdResponse response={res} />
            )}
            title="Find all RollunIDs by MPN & Brand"
          />
        </Tab>
        <Tab eventKey="second" title="Find Suppliers CSNs">
          <SuppliersMapping
            hookUrl="/api/webhook/SuppliersMappingFindSuppliersCsn"
            renderResponse={(res) => (
              <RenderSuppliersMappingFindSuppliersCsnResponse response={res} />
            )}
            title="Find all CSNs by MPN & Brand"
          />
        </Tab>
      </Tabs>
    );
  }
}

const RenderSuppliersMappingFindRollunIdResponse: React.FC<{
  response: any;
}> = ({ response }) => {
  return (
    <div className="m-2">
      <ReactDataGridAdapter data={[response]} minHeight={300} />
    </div>
  );
};

// TODO replace any with actual types
const RenderSuppliersMappingFindSuppliersCsnResponse: React.FC<{
  response: any;
}> = ({ response }) => {
  const formatToTable = (data: any) => {
    return Object.entries(data).reduce(
      (acc: Array<any>, [supplier, mappings]: any) => {
        if (_.size(mappings) === 0) return acc;
        // in some cases mappings can be object, in some, array... strange php serialization
        const values = Object.values(mappings);
        return acc.concat(
          values.map((value: any) => ({
            supplier,
            s_id: value.s_id,
            s_mpn: value.s_mpn,
            s_brand_id: value.s_brand_id,
          })),
        );
      },
      [],
    );
  };
  const dataToRender = formatToTable(response);
  return (
    <div className="m-2">
      <h4>Results:</h4>
      {dataToRender.length === 0 ? (
        <NoData>Empty</NoData>
      ) : (
        <ReactDataGridAdapter
          data={dataToRender}
          minHeight={200}
          loadingStatus={LoadingStatusEnum.loaded}
        />
      )}
    </div>
  );
};

export default SupplierMappingContainer;
