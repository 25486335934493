import HttpDatastore from 'rollun-ts-datastore';
import {
  Pickup,
  PickupDTO,
  Position,
  Client,
  SPECIAL_ITEMS_IDS,
  GetByQueryOptions,
} from './types';
import ApiClientsPositions from './api-clients-position';
import { Query, Eq, In } from 'rollun-ts-rql';
import ApiClients from './api-clients-clients';
import { compareStringNumbers } from '../common/utils/compareStringNumbers';
import _ from 'lodash';

const datastore = new HttpDatastore<PickupDTO>(
  '/api/datastore/PickupsDataStore',
);

export default class ApiClientsPickups {
  apiPositions: ApiClientsPositions;
  apiClients: ApiClients;

  constructor() {
    this.apiPositions = new ApiClientsPositions();
    this.apiClients = new ApiClients();
  }

  async getById(id: string): Promise<Pickup> {
    const pickupDTO = await datastore.read(id);

    if (!pickupDTO) {
      throw new Error(`Pickup with id ${id} not found`);
    }

    const positions = this.apiPositions.getByDealId(id);
    const client = pickupDTO.Contractor
      ? this.apiClients.getById(pickupDTO.Contractor)
      : ((null as unknown) as Client);

    const order = await Promise.all([positions, client]).then((data) => {
      return this.convertDTOtoModel(pickupDTO, ...data);
    });
    return order;
  }

  async getByMpOrderNumber(mpOrderNumber: string): Promise<Pickup | null> {
    const pickups = await datastore.query(
      new Query().setQuery(new Eq('MpOrderNumber', mpOrderNumber)),
    );

    const [pickupDTO] = pickups.filter(
      (pickup) =>
        (pickup.StatusName === 'Archive' &&
          pickup.ArchiveScenario !== 'wrong' &&
          pickup.ArchiveScenario !== 'paid_not_shipped') ||
        pickup.StatusName !== 'Archive',
    );

    if (!pickupDTO) {
      return null;
    }

    const positions = this.apiPositions.getByDealId(pickupDTO.Id);
    const client = pickupDTO.Contractor
      ? this.apiClients.getById(pickupDTO.Contractor)
      : ((null as unknown) as Client);

    const order = await Promise.all([positions, client]).then((data) => {
      return this.convertDTOtoModel(pickupDTO, ...data);
    });
    return order;
  }

  async getByQuery(
    query: Query,
    options?: GetByQueryOptions,
  ): Promise<Pickup[]> {
    const queryWithoutSelect = ({
      ...query,
      selectNode: undefined as any,
      _selectNode: undefined as any,
    } as unknown) as Query;
    const PickupsDTO = await datastore.query(queryWithoutSelect);
    if (!PickupsDTO.length) return [];

    const dealIds = PickupsDTO.map((PickupDTO) => PickupDTO.Id);
    const dealIdsChunks = _.chunk(dealIds, 100);

    const clientIds = PickupsDTO.map(
      (PickupDTO) => PickupDTO.Contractor,
    ).filter(Boolean);
    const clientIdsChunks = _.chunk(clientIds, 100);

    const positions = options?.excludePositions
      ? []
      : (
          await Promise.all(
            dealIdsChunks.map((dealIdsChunks) =>
              this.apiPositions.getByQuery(
                new Query().setQuery(new In('DealId', dealIdsChunks)),
              ),
            ),
          )
        ).flat();

    const clients = (
      await Promise.all(
        clientIdsChunks.map((clientChukn) =>
          this.apiClients.getByQuery(
            new Query().setQuery(new In('Id', clientChukn)),
          ),
        ),
      )
    ).flat();

    return PickupsDTO.map((PickupDTO) => {
      const dealPositions = positions.filter(
        (position) => position.dealId === PickupDTO.Id,
      );
      const client = clients.find(
        (client) => client.id === PickupDTO.Contractor,
      );
      return this.convertDTOtoModel(PickupDTO, dealPositions, client as Client);
    });
  }

  async deleteItems(id: string, itemIds: string[]): Promise<void> {
    await Promise.all(
      itemIds.map((itemId) => this.apiPositions.delete(id, itemId)),
    );
  }

  async create(data: Omit<Pickup, 'id'>): Promise<Pickup> {
    const pickupDTO = this.convertModelToDTO(data);
    const pickup = await datastore.create(pickupDTO);

    if (data.positions) {
      await this.addItems(pickup.Id, data.positions);
    }

    return this.getById(pickup.Id);
  }

  async addItems(
    id: string,
    items: Omit<Position, 'offerId' | 'dealId' | 'id'>[],
  ): Promise<void> {
    for (const item of items) {
      await this.apiPositions.add(id, item);
    }
  }

  async update(id: string, data: Partial<Omit<Pickup, 'id' | 'positions'>>) {
    await datastore.update({
      ...this.convertModelToDTO(data),
      Id: id,
    });
  }

  async addOrUpdateItems(
    id: string,
    items: Omit<Position, 'offerId' | 'dealId' | 'id'>[],
  ) {
    const deal = await this.getById(id);
    const positions = deal.positions;

    const tempSortedItems: Position[] = [];

    for (let i = 0; i < positions.length; i++) {
      const position = { ...positions[i] };

      if (
        tempSortedItems.filter(
          (item) =>
            item.article === position.article &&
            compareStringNumbers(item.cost, position.cost) &&
            item.name === position.name,
        ).length
      )
        continue;

      tempSortedItems.push(position);

      const sameItems = items.filter(
        (item) =>
          item.article === position.article &&
          compareStringNumbers(item.cost, position.cost) &&
          item.name === position.name,
      );
      if (!sameItems.length) continue;

      const allQuantity = sameItems.reduce(
        (prev, cur) => prev + +cur.quantity,
        0,
      );
      position.quantity = +position.quantity + allQuantity;

      await this.apiPositions.update(position);
    }

    const filteredItems = items.filter((item) => {
      for (let i = 0; i < tempSortedItems.length; i++) {
        const position = { ...tempSortedItems[i] };
        if (
          item.article === position.article &&
          compareStringNumbers(item.cost, position.cost) &&
          item.name === position.name
        )
          return false;
      }
      return true;
    });

    if (filteredItems.length) await this.addItems(id, filteredItems);
  }

  convertDTOtoModel(
    pickupDTO: PickupDTO,
    positions: Position[],
    client: Client,
  ): Pickup {
    const {
      Id: id,
      BagLink: bagLink,
      SrName: srName,
      MpName: mpName,
      ProblemDescription: problemDescription,
      TimeCreated: timeCreated,
      TimeUpdated: timeUpdated,
      Tracknumber: tracknumber,
      MpOrderNumber: mpOrderNumber,
      SrOrderNumber: srOrderNumber,
      SrOrderId: srOrderId,
      SrShipMethod: srShipMethod,
      Carrier: carrier,
      Sender: sender,
      Status: status,
      Owner: owner,
      EnterTracknumber: enterTracknumber,
      StatusName: statusName,
      Contractor: contractor,
      Weight: weight,
      Dimensions: dimensions,
      Total: total,
      ArchiveScenario: archiveScenario,
      WasPacked: wasPacked,
      DateRofReservation: dateRofReservation,
      DateRofSubtraction: dateRofSubtraction,
      DateEnteringTracknumberIntoMp: dateEnteringTracknumberIntoMp,
      RelatedOrder: relatedOrder,
      ShipStatus: shipStatus,
      LabelProvider: labelProvider,
    } = pickupDTO;

    const no_special_items_positions = positions.filter(
      (position) => !SPECIAL_ITEMS_IDS.includes(position.article),
    );

    const pickup: Pickup = {
      id,
      bagLink,
      positions,
      srName,
      mpName,
      problemDescription,
      timeCreated,
      timeUpdated,
      tracknumber,
      mpOrderNumber,
      srOrderNumber,
      srOrderId,
      srShipMethod,
      carrier,
      sender,
      status,
      owner,
      enterTracknumber,
      no_special_items_positions,
      statusName,
      contractor,
      weight,
      dimensions,
      total,
      archiveScenario,
      wasPacked,
      dateRofReservation,
      dateRofSubtraction,
      dateEnteringTracknumberIntoMp,
      relatedOrder,
      shipStatus,
      client,
      labelProvider,
    };
    return pickup;
  }

  convertModelToDTO(
    pickup: Partial<Omit<Pickup, 'id' | 'positions' | 'client'>>,
  ): Partial<Omit<PickupDTO, 'Id'>> {
    return {
      BagLink: pickup.bagLink,
      SrName: pickup.srName,
      MpName: pickup.mpName,
      ProblemDescription: pickup.problemDescription,
      TimeCreated: pickup.timeCreated,
      Tracknumber: pickup.tracknumber,
      MpOrderNumber: pickup.mpOrderNumber,
      SrOrderNumber: pickup.srOrderNumber,
      SrShipMethod: pickup.srShipMethod,
      Carrier: pickup.carrier,
      Sender: pickup.sender,
      EnterTracknumber: pickup.enterTracknumber,
      Owner: pickup.owner,
      Status: pickup.status,
      StatusName: pickup.statusName,
      Weight: pickup.weight,
      Dimensions: pickup.dimensions,
      Total: pickup.total,
      ArchiveScenario: pickup.archiveScenario,
      WasPacked: pickup.wasPacked,
      DateRofReservation: pickup.dateRofReservation,
      DateRofSubtraction: pickup.dateRofSubtraction,
      Contractor: pickup.contractor,
      DateEnteringTracknumberIntoMp: pickup.dateEnteringTracknumberIntoMp,
      RelatedOrder: pickup.relatedOrder,
      LabelProvider: pickup.labelProvider,
    };
  }
}

export const apiClientsPickups = new ApiClientsPickups();
