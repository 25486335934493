import { useContext, useEffect, useState } from 'react';
import {
  ReturnBag,
  useReturnBagItems,
  useReturnBagList,
} from '../../../hooks/datastores';
import {
  And,
  Eq,
  Query,
  Gt,
  Sort,
  Select,
  AggregateFunctionNode,
  GroupBy,
} from 'rollun-ts-rql';
import { BagFillingContext } from '../BagFillingContext';
import ms from 'ms';

const SHIPMENT_TYPE_POSTAL = 'waiting';
const SHIPMENT_TYPE_WAREHOUSE = 'no_need';
const STATUS_PROCESSED = 'processed';
const STATUS_NOT_PROCESSED = 'not processed';
const STATUS_IN_PROCESS = 'in process';

type BagsWithItemCount = { bag: string; 'count(rid)': number }[];

const useBagsList = () => {
  const [{ status, error }, dispatch] = useReturnBagList();
  const [, itemsDispatch] = useReturnBagItems();
  const [fullBagsInfo, setFullBagsInfo] = useState<
    (ReturnBag & { itemsCount: number })[] | null
  >(null);
  const context = useContext(BagFillingContext);

  useEffect(() => {
    const getCountOfItemsByBag = async () => {
      const [bags, bagsWithCount] = await Promise.all([
        dispatch(
          'QUERY',
          new Query({
            query: new And([
              new Eq('storage', context.storage),
              new Gt(
                'updated_at',
                new Date(Date.now() - ms('30 days')).toISOString(),
              ),
            ]),
            sort: new Sort({
              updated_at: -1,
            }),
          }),
        ) as any,
        (itemsDispatch(
          'QUERY',
          new Query({
            select: new Select([
              'bag',
              new AggregateFunctionNode('count', 'rid'),
            ]),
            group: new GroupBy(['bag']),
          }),
        ) as unknown) as BagsWithItemCount,
      ]);

      if (!bags || !bagsWithCount) {
        return;
      }

      setFullBagsInfo(
        bags.map((bag: any) => ({
          ...bag,
          itemsCount:
            bagsWithCount.find(
              (bagWithCount) => bagWithCount.bag === bag.bag_number,
            )?.['count(rid)'] || 0,
        })),
      );
    };
    getCountOfItemsByBag();
  }, []);

  return {
    status,
    result: fullBagsInfo,
    error,
  };
};

export {
  useBagsList,
  SHIPMENT_TYPE_POSTAL,
  SHIPMENT_TYPE_WAREHOUSE,
  STATUS_IN_PROCESS,
  STATUS_NOT_PROCESSED,
  STATUS_PROCESSED,
};
