import React, { PureComponent, ReactNode } from 'react';
import {
  downloadAsCSV,
  downloadAsJSON,
  downloadAsText,
} from 'rollun-ts-utils/dist';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import MuiIconButton from '../../../../UI/MuiIconButton';

export interface CsvDownloaderProps {
  title?: string;
  fileName?: string;
  getData: () => Array<any>;
  isDisabled?: boolean;
  type: 'CSV' | 'JSON' | 'TEXT';
}

export default class DataDownloader extends PureComponent<CsvDownloaderProps> {
  render(): ReactNode {
    const { type } = this.props;
    return (
      <MuiIconButton
        title={'download as ' + type}
        color="primary"
        width={40}
        height={40}
        iconName={this.getIconName()}
        disabled={this.props.isDisabled}
        onClick={this.onFileDownload}
      />
    );
  }

  getIconName = (): IconProp => {
    const map: { [key: string]: IconProp } = {
      CSV: 'download',
      JSON: 'cloud-download-alt',
      TEXT: 'file-download',
    };
    return map[this.props.type];
  };

  onFileDownload = () => {
    const { type } = this.props;
    if (type === 'CSV') {
      return downloadAsCSV(this.props.getData());
    } else if (type === 'JSON') {
      return downloadAsJSON(this.props.getData());
    } else if (type === 'TEXT') {
      return downloadAsText(this.props.getData());
    } else {
      alert(`Downloading file with type ${type} is not available!`);
    }
  };
}
