import React from 'react';
import { Button } from '../../../UI';
import { TextField, Theme, WithStyles, withStyles } from '@material-ui/core';
import _ from 'lodash';

const styles = (theme: Theme) => ({
  input: {
    marginBottom: theme.spacing(1),
  },
});

interface IProps extends WithStyles {
  treeNameField: string | undefined;
  treeDataField: string | undefined;
  onNodeCreate: (title: string, subtitle: string) => void;
}

class CreateNewNode extends React.Component<IProps> {
  state = {
    title: '',
    subtitle: '',
  };

  handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    const { title, subtitle } = this.state;

    this.props.onNodeCreate(title, subtitle);
    this.setState({ title: '', subtitle: '' });
  };

  render() {
    const { treeDataField, treeNameField, classes } = this.props;
    const { title, subtitle } = this.state;

    return (
      <div className="col-12 col-md-6">
        <h5>Add new node</h5>
        <div>
          <TextField
            className={classes.input}
            size="small"
            label={_.upperFirst(treeNameField)}
            type="text"
            name="title"
            fullWidth={true}
            variant="outlined"
            onChange={this.handleInput}
            value={title}
          />

          {treeDataField ? (
            <TextField
              size="small"
              className={classes.input}
              label={_.upperFirst(treeDataField)}
              type="text"
              name="subtitle"
              fullWidth={true}
              variant="outlined"
              onChange={this.handleInput}
              value={subtitle}
            />
          ) : null}
          <div className="d-flex justify-content-end">
            <Button
              className="mt-1"
              color="primary"
              onClick={this.handleSubmit}
            >
              Create
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CreateNewNode);
