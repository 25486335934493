import React, { FC, useContext, useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import ReturnAppContext from '../context';
import HttpDatastore from 'rollun-ts-datastore';
import { Alike, Eq, Limit, Or, Query } from 'rollun-ts-rql';
import _ from 'lodash';
import MuiButton from '../../../UI/MuiButton';
import { rqlStartsWith } from '../utils';
import { useRollunIdWithCsnViewDatastore } from '../../../hooks/datastores';
import { BarcodeScanner } from '../../../lib/barcode-scanner/BarcodeScanner';
import { logger } from '../../../utils/logger';
import { BarcodeInput } from '../../../UI/BarcodeInput';

interface ScanItemPageProps {
  type: 'item' | 'label';
}

const returnsDatastore = new HttpDatastore('/api/datastore/ReturnsList');

const ScanItemPage: FC<ScanItemPageProps> = ({ type }) => {
  const [error, setError] = useState<string>('');
  const context = useContext(ReturnAppContext);
  const [foundReturn, setFoundReturn] = useState<any[]>([]);
  const [codeToFind, setCodeToFind] = useState<string>('');
  const [, catalogDispatch] = useRollunIdWithCsnViewDatastore();

  const getItemByCode = async (code: string): Promise<any[]> => {
    const data = await catalogDispatch(
      'QUERY',
      new Query({
        query: new Or([
          new Alike('rid', rqlStartsWith(code)),
          new Alike('brand', rqlStartsWith(code)),
          new Alike('mpn', rqlStartsWith(code)),
          new Alike('csn', rqlStartsWith(code)),
        ]),
        limit: new Limit(1),
      }),
    );
    return data || [];
  };

  const getLabelByCode = (code: string) => {
    return returnsDatastore.query(
      new Query({
        query: new Eq('track_number', code),
        limit: new Limit(1),
      }),
    );
  };

  const checkIfLabelOrItemExists = async (code: string) => {
    try {
      const data = await (type === 'item'
        ? getItemByCode(code)
        : getLabelByCode(code));

      setFoundReturn(data);
    } catch (e) {
      setError(`This ${type} was not found in database. Code: ${code}`);
    }
  };

  const onBarcodeDetectHandler = async (code: string) => {
    await checkIfLabelOrItemExists(code);
    setCodeToFind(code);
  };

  useEffect(() => {
    const checkCurrentLabel = async (code: string) => {
      try {
        const data = await (type === 'item'
          ? getItemByCode(code)
          : getLabelByCode(code));

        const found: any = `found${_.upperFirst(type)}`;
        const notFound: any = `notFound${_.upperFirst(type)}`;

        logger.info('ReturnAppTest ScanItemPageTest foundReturn in useEffect', {
          data,
          foundReturn,
          type,
          code,
          codeToFind,
        });

        if (
          type === 'label' &&
          data[0].expected_rid &&
          typeof data[0].expected_rid === 'string'
        ) {
          context.setItem(foundReturn[0].expected_rid, 'foundItem');
          return;
        }

        context.setItem(code, data.length === 0 ? notFound : found);
      } catch (e) {
        setError(`This ${type} was not found in database. Code: ${code}`);
      }
    };

    if (foundReturn.length === 0 && codeToFind.length > 0) {
      checkCurrentLabel(codeToFind);
    }
  }, [foundReturn, codeToFind]);

  return (
    <Box
      height="100%"
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
    >
      <Box>
        {error && (
          <Typography variant="subtitle1" color="error" align="center">
            {error}
          </Typography>
        )}
        <BarcodeScanner
          onResult={(code: string) => onBarcodeDetectHandler(code)}
        />
        <BarcodeInput
          onSubmit={async (barcode) => {
            await checkIfLabelOrItemExists(barcode);
          }}
          barcode={codeToFind}
          onClear={() => {
            setCodeToFind('');
          }}
          onChange={(barcode) => {
            setCodeToFind(barcode);
          }}
        />
      </Box>
      <Dialog
        open={foundReturn.length !== 0}
        onClose={() => setFoundReturn([])}
      >
        <DialogTitle>Are you sure you received this return?</DialogTitle>
        <DialogContent dividers>
          {Object.entries(foundReturn[0] || {}).map(([key, value]) => (
            <Box key={key} display="flex">
              <Box fontWeight="bold">{key}</Box>
              <Typography variant="body1">{': ' + value}</Typography>
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <MuiButton
            color="primary"
            onClick={() => {
              logger.info('ReturnAppTest ScanItemPageTest foundReturn', {
                foundReturn,
                type,
              });
              if (type === 'item') {
                context.setItem(foundReturn[0].rid || '', 'foundItem');
                return;
              }

              if (
                foundReturn[0].expected_rid &&
                typeof foundReturn[0].expected_rid === 'string'
              ) {
                context.setLabel(foundReturn[0].track_number || '');
                context.setItem(foundReturn[0].expected_rid, 'foundItem');
                return;
              }

              context.setLabel(foundReturn[0].track_number || '', 'foundLabel');
            }}
          >
            Yes
          </MuiButton>
          <MuiButton color="error" onClick={() => setFoundReturn([])}>
            No
          </MuiButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ScanItemPage;
