import _ from 'lodash';
import HttpDatastore from 'rollun-ts-datastore';
import { In, Query, Select } from 'rollun-ts-rql';
import { httpErrorHandlerPromised, noop } from '../../../utils/common.utils';
import Axios from 'axios';
import { ExchangeFileFormattor } from './index';
import { CompatibleVehicle, Supplier } from '../../../utils/common.types';
import { fetchRockyMountainData } from './fetchRockyMountainData';
import { fetchTuckerRockyData } from './fetchTuckerRockyData';
import { fetchPartsUnlimitedData } from './fetchPartsUnlimitedData';

export type FileExchangeDataByRollunId = {
  [key: string]: {
    name: string;
    mpn: string;
    color: string;
    size: string;
    brand: string;
    upc: string;
    photo: string;
    qty: number;
    vehicles: Array<CompatibleVehicle>;
    ct_rollun_price: number;
    ct_plaisir_price: number;
    description: string;
    categoryId: string;
  };
};

const EbayCompatibles = new HttpDatastore(
  '/api/datastore/EbayCompatibleVehiclesAllView',
);

let compatiblesCache: Array<CompatibleVehicle & { epid: string }> = [];

export const formatTitle = (title: Array<string>, maxLength = 80): string => {
  let formattedString = title[0] || '';

  // Testing base case, if first element length is bigger than maxLength
  if (formattedString.length > maxLength) {
    const [first] = formattedString.split(/\s+/);

    if (first.length > maxLength) {
      return first.slice(0, maxLength);
    }

    return formatTitle([first], maxLength);
  }

  for (const str of title.slice(1)) {
    const temp = `${formattedString} ${str}`;

    if (temp.length > maxLength) {
      return formattedString;
    }

    formattedString += ` ${str}`;
  }

  return formattedString;
};

export const fetchCompatibles = async (
  compatibles: Array<{ rollunId: string; compatibleId: string }>,
): Promise<Array<CompatibleVehicle & { rollunId: string }>> => {
  if (compatiblesCache.length === 0) {
    const q = new Query({
      select: new Select(['epid', 'make', 'model_slim', 'submodel', 'year']),
    });
    while (true) {
      const compatiblesChunk = await EbayCompatibles.query(
        q.getNextLimit(20000),
      );
      if (compatiblesChunk.length === 0) break;
      compatiblesCache = compatiblesCache.concat(compatiblesChunk);
    }
  }

  return compatibles.reduce(
    (
      acc: Array<CompatibleVehicle & { rollunId: string }>,
      { rollunId, compatibleId },
    ) => {
      const cachedVehicle = compatiblesCache.find(
        ({ epid }) => epid === compatibleId,
      );
      if (!cachedVehicle) return acc;
      return acc.concat({
        rollunId,
        ...cachedVehicle,
      });
    },
    [],
  );
};

export const fetchCategories = async (
  items: Array<{ rollun_id: string; name: string }>,
): Promise<
  Array<{ rollun_id: string; category_id: string; category_name: string }>
> => {
  let result: Array<{
    rollun_id: string;
    category_id: string;
    category_name: string;
  }> = [];

  console.log('fetchCategories function working....');
  console.log('items param: ', items);

  const chunks = _.chunk(items, 10);
  for (const chunk of chunks) {
    const chunkResult = await Promise.all(
      chunk.map(async ({ rollun_id, name }) => {
        try {
          console.log('encodeURIComponent(name)::::', name);

          const {
            data: { id, name: category_name },
          } = await Axios.get(
            '/api/webhook/findCategoryByKeywords?keywords=' +
              encodeURIComponent(name),
          );

          console.log(
            'data returned by webhook /api/webhook/findCategoryByKeywords?keywords=',
            { id, name: category_name },
          );

          return { rollun_id, category_id: id, category_name };
        } catch (e) {
          return { rollun_id, category_id: '', category_name: '' };
        }
      }),
    );

    result = result.concat(chunkResult);
  }
  return result;
};

const prices = new HttpDatastore('/api/datastore/DataForFileExchange');
export const fetchCommonItemsData = async (
  rids: Array<string>,
): Promise<Array<any>> => {
  if (rids.length === 0) return [];
  return prices.query(
    new Query({
      query: new In('id', rids),
    }),
  );
};

const dimensionsStore = new HttpDatastore('/api/datastore/DimensionStore');
export const fetchImages = async (
  rids: Array<string>,
): Promise<Array<{ rollunId: string; image: string | null }>> => {
  const data = await dimensionsStore.query(
    new Query({
      select: new Select(['id', 'image']),
      query: new In('id', rids),
    }),
  );
  return data.map(({ id, image }) => ({
    rollunId: id,
    image: image == '-1' ? null : image,
  }));
};

const getLocationFromSupplier = (supplier: Supplier) => {
  if (supplier === 'RockyMountain' || supplier === 'TuckerRocky') {
    return 'KY 40391|UT 84651';
  }
  if (supplier === 'PartsUnlimited') {
    return 'NC 28790';
  }
  return '';
};

export const getFileExchangeByRollunId: ExchangeFileFormattor = async (
  data,
  { marketplace, supplier = 'RockyMountain' },
  progress = noop,
) => {
  try {
    const dataFetchers: {
      [key in Supplier]: (
        rid: Array<string>,
        progress: (p: string) => void,
      ) => Promise<FileExchangeDataByRollunId>;
    } = {
      RockyMountain: fetchRockyMountainData,
      TuckerRocky: fetchTuckerRockyData,
      PartsUnlimited: fetchPartsUnlimitedData,
    };

    const supplierData = await dataFetchers[supplier](
      data.map(({ rollunId }) => rollunId),
      progress,
    );

    progress('All data fetched, formatting File exchange');
    const fileExchange = data.map(({ rollunId }) => {
      const {
        name,
        brand,
        mpn,
        categoryId,
        vehicles,
        qty,
        description,
        ct_rollun_price,
        ct_plaisir_price,
        photo,
        upc,
        size,
        color,
      } = supplierData[rollunId];

      return {
        '*Action(SiteID=eBayMotors|Country=US|Currency=USD|Version=745|CC=UTF-8)':
          'AddFixedPriceItem',
        InventoryTrackingMethod: 'SKU',
        CustomLabel: rollunId,
        '*Category': categoryId ? +categoryId : '',
        StoreCategory: '',
        '*Title': name,
        Subtitle: '',
        Relationship: '',
        RelationshipDetails: '',
        '*ConditionID': '1000',
        'C:<item specific name>': '',
        '*C:Brand': brand,
        'C:Size': size,
        '*C:Manufacturer Part Number': mpn,
        'C:Type': '',
        'C:Color': color,
        'C:Warranty': 'Unspecified Length',
        'C:Custom Bundle': '',
        'C:Bundle Description': '',
        'C:Modified Item': '',
        'C:Modification Description': '',
        'C:Non-Domestic Product': '',
        'Product:UPC': upc || '',
        'C:California Prop 65 Warning':
          'WARNING: Cancer and Reproductive Harm - www.P65Warnings.ca.gov',
        'C:Country/Region of Manufacture': '',
        PicURL: photo,
        GalleryType: '',
        '*Description': description,
        '*Format': 'FixedPrice',
        '*Duration': 'GTC',
        '*StartPrice':
          marketplace === 'Rollun' ? ct_rollun_price : ct_plaisir_price,
        BuyItNowPrice: '',
        '*Quantity': qty >= 5 ? 5 : 0,
        PayPalAccepted: '',
        PayPalEmailAddress: '',
        ImmediatePayRequired: '',
        PaymentInstructions: '',
        '*Location': getLocationFromSupplier(supplier),
        ShippingType: 'Flat',
        'ShippingService-1:Option': 'ShippingMethodStandard',
        'ShippingService-1:Cost': '0',
        'ShippingService-1:AdditionalCost': '0',
        'ShippingService-2:Option': '',
        'ShippingService-2:Cost': '',
        '*DispatchTimeMax': '1',
        PromotionalShippingDiscount: '',
        ShippingDiscountProfileID: '',
        DomesticRateTable: '',
        '*ReturnsAcceptedOption': 'ReturnsAccepted',
        ReturnsWithinOption: 'Days_30',
        RefundOption: 'MoneyBack',
        ShippingCostPaidByOption: 'Seller',
        AdditionalDetails: '',
        UseTaxTable: '1',
        ShippingProfileName: 'Flat:Standard Shipp(Free),1 business day',
        ReturnProfileName: 'Returns Accepted,Seller,30 Days,Money Back',
        PaymentProfileName: 'eBay Payments:eBay Payments',
        VehiclesCompatibles: vehicles,
      };
    });
    progress('File exchange is ready.');
    return { data: fileExchange, error: null };
  } catch (e) {
    console.log(e);
    const error = await httpErrorHandlerPromised(e);
    return { data: [], error };
  }
};
