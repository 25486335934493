import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../../../UI';

interface IProps {
  onOk(): void;

  resourceId: string;
  name: string;
  path: string;
}

export const ServiceCreated = ({ onOk, resourceId, name, path }: IProps) => {
  return (
    <div className="py-4 d-flex justify-content-center flex-column align-items-center">
      <h3>
        Service successfully created!{' '}
        <FontAwesomeIcon className="color-green" icon="check-circle" />
      </h3>
      <h4 className="font-weight-bold">Created service params: </h4>
      <div>
        <h5>Resource id: {resourceId}</h5>
        <h5>Name: {name}</h5>
        <h5>
          Path: <a href={path}>{path}</a>
        </h5>
      </div>
      <Button className="mt-2" color="primary" onClick={onOk}>
        OK
      </Button>
    </div>
  );
};

export default ServiceCreated;
