import React, { FC, useContext, useState } from 'react';
import LoginMenu from '../LoginMenu/LoginMenu';
import { LeftMenuContext, MenuItem } from '../AppContainer/AppContainer';
import { Link } from 'react-router-dom';
import './Header.css';
import { ServiceInfo } from '../../pages/RootApp/Root.app';
import { makeStaticCDNPath } from '../../utils/common.utils';
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  useMediaQuery,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { drawerWidth } from '../../utils/common.constants';
import MuiLeftMenu from '../NavMenu/LeftMenu/MuiLeftMenu';
import MuiSelect from '../NavMenu/TopMenu/TopMenu';
import { getBackgroundColor } from '../../themeProvider';
import InputWithSelect from '../../UI/InputWithSelect';
import { normalizeMenus } from '../NavMenu/utils';

export interface HeaderProps {
  user?: string;
  role?: string;
  items: Array<MenuItem>;
  loading: boolean;
  services: { [key: string]: ServiceInfo };
  disableLeftMenu: boolean;
}

/**
 * Header, used by AppContainer
 * Contains logo ans some menus
 */

const useStyles = makeStyles((theme: Theme) => ({
  innerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row' as const,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  rightMenuIcon: {
    marginLeft: theme.spacing(1),
  },
  rightDrawer: {
    backgroundColor: getBackgroundColor(),
    minWidth: drawerWidth,
    maxWidth: drawerWidth,
  },
  list: {
    zIndex: 1,
    marginTop: theme.spacing(8),
    maxWidth: drawerWidth,
  },
  paper: {
    backgroundColor: getBackgroundColor(),
    minWidth: drawerWidth,
    maxWidth: drawerWidth,
    width: drawerWidth,
  },
  loginSection: {
    display: 'flex',
  },
  rightMenu: {
    '& a, p': {
      color: 'white',
    },
  },
  search: {
    maxWidth: '260px',
    margin: theme.spacing(0, 0.5),
    flexDirection: 'column',
    alignItems: 'center',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const Header: FC<HeaderProps> = ({
  user,
  role,
  items,
  disableLeftMenu,
  services,
  loading,
}) => {
  const [expanded, setExpanded] = useState(false);
  const leftMenu = useContext(LeftMenuContext);
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const onMenuToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Box flexGrow={1}>
      <AppBar
        position="sticky"
        className={classes.appBar}
        style={{ backgroundColor: getBackgroundColor() }}
      >
        <Toolbar>
          <IconButton
            style={{ display: disableLeftMenu ? 'none' : '' }}
            onClick={() => leftMenu.setIsOpen()}
          >
            <MenuIcon style={{ color: 'white' }} />
          </IconButton>
          <Box flexGrow={1} marginLeft={3}>
            <Link to="/">
              <img
                id={'logo'}
                style={{ maxHeight: '1.75rem' }}
                src={makeStaticCDNPath('/images/logo.png')}
                alt="rollun logo"
              />
            </Link>
          </Box>
          <Box
            padding={2}
            display={isMobile ? 'none' : 'flex'}
            justifyContent="flex-end"
            flexGrow={1}
            alignItems="center"
          >
            <MuiSelect services={services} loading={loading} />
            <Box marginLeft={1} display="flex" alignItems="center">
              <LoginMenu user={user} role={role} />
            </Box>
          </Box>
          <IconButton
            className={classes.rightMenuIcon}
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={onMenuToggle}
          >
            <MenuIcon color="primary" />
          </IconButton>
          <Drawer
            className={classes.list}
            open={expanded}
            anchor="right"
            onClose={onMenuToggle}
            classes={{
              paper: classes.rightDrawer,
            }}
          >
            <Box padding={2} display="flex" flexDirection="column">
              <LoginMenu user={user} role={role} />
              <Box
                marginTop={2}
                display={!isMobile ? 'none' : 'flex'}
                justifyContent="center"
              >
                <InputWithSelect
                  options={Object.entries(services)
                    .filter(([key]) => key !== window.location.pathname)
                    .map(([key, value]) => ({
                      value: key,
                      label: value.name,
                    }))}
                  value=""
                  label="Type something..."
                />
              </Box>
            </Box>
            <Box marginTop={1} className={classes.rightMenu}>
              <MuiLeftMenu menuItems={normalizeMenus(items)} />
            </Box>
          </Drawer>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
