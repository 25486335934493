import React, { useEffect, useState } from 'react';
import { Roles } from '../../AddNewUser/types';
import { Box } from '@material-ui/core';
import Select from 'react-select';
import { Query, Select as RqlSelect, Eq } from 'rollun-ts-rql';
import HttpDatastore from 'rollun-ts-datastore';
import { RolesUpdater } from './RolesUpdater';
import { DeleteUser } from './DeleteUser';

interface User {
  id: string;
  name: string;
}

const userDataStore = new HttpDatastore('/api/datastore/userDataStore');
const roleDataStore = new HttpDatastore('/api/datastore/userRoleDataStore');

export const UserSelect = ({ rolesList }: any) => {
  const [users, setUsers] = useState<User[]>([]);
  const [roles, setRoles] = useState<Roles | null>(null);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  useEffect(() => {
    userDataStore
      .query(new Query().setSelect(new RqlSelect(['id', 'name'])))
      .then((res) => setUsers(res));
  }, []);

  useEffect(() => {
    setRoles(null);
    if (!selectedUser) return;
    roleDataStore
      .query(
        new Query({
          query: new Eq('user_id', selectedUser?.id),
        }),
      )
      .then((roles) =>
        setRoles(
          roles.map(({ roles_id }) => ({ role: roles_id, checked: true })),
        ),
      );
  }, [selectedUser]);

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
      }}
    >
      <Box
        style={{
          width: '320px',
        }}
      >
        <Select
          value={{ value: selectedUser?.id, label: selectedUser?.name }}
          defaultValue={{ value: '', label: '' } as any}
          options={users.map((user: any) => ({
            value: user.id,
            label: user.name,
          }))}
          autosize={true}
          onChange={(e) =>
            setSelectedUser({ id: e.value, name: e.label } as User)
          }
        />
      </Box>
      {selectedUser && roles && (
        <>
          <RolesUpdater
            userRoles={roles}
            rolesList={rolesList}
            userId={selectedUser.id}
          />
          <DeleteUser selectedUser={selectedUser} />
        </>
      )}
    </Box>
  );
};
