/* tslint:disable */
/* eslint-disable */
/**
 * CRMClients
 * This API implements low-level access to \'Client\' entity Offers CRUD functionality.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface Client
 */
export interface Client {
  /**
   * Unique identificator of order deal (generated by server).
   * @type {string}
   * @memberof Client
   */
  id: string;
  /**
   *
   * @type {ClientPostRequestAddress}
   * @memberof Client
   */
  address: ClientPostRequestAddress;
  /**
   *
   * @type {ClientPostRequestContactInfo}
   * @memberof Client
   */
  contactInfo: ClientPostRequestContactInfo;
  /**
   * When entity was created
   * @type {string}
   * @memberof Client
   */
  createdAt: string;
  /**
   * When entity was created
   * @type {string}
   * @memberof Client
   */
  updatedAt: string;
}
/**
 *
 * @export
 * @interface ClientListResult
 */
export interface ClientListResult {
  /**
   *
   * @type {Array<Client>}
   * @memberof ClientListResult
   */
  data?: Array<Client>;
  /**
   *
   * @type {Array<Message>}
   * @memberof ClientListResult
   */
  messages?: Array<Message>;
}
/**
 *
 * @export
 * @interface ClientPostRequest
 */
export interface ClientPostRequest {
  /**
   *
   * @type {ClientPostRequestAddress}
   * @memberof ClientPostRequest
   */
  address: ClientPostRequestAddress;
  /**
   *
   * @type {ClientPostRequestContactInfo}
   * @memberof ClientPostRequest
   */
  contactInfo: ClientPostRequestContactInfo;
}
/**
 *
 * @export
 * @interface ClientPostRequestAddress
 */
export interface ClientPostRequestAddress {
  /**
   *
   * @type {string}
   * @memberof ClientPostRequestAddress
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof ClientPostRequestAddress
   */
  street1: string;
  /**
   *
   * @type {string}
   * @memberof ClientPostRequestAddress
   */
  street2: string;
  /**
   *
   * @type {string}
   * @memberof ClientPostRequestAddress
   */
  zip: string;
  /**
   *
   * @type {string}
   * @memberof ClientPostRequestAddress
   */
  state: string;
}
/**
 *
 * @export
 * @interface ClientPostRequestContactInfo
 */
export interface ClientPostRequestContactInfo {
  /**
   *
   * @type {string}
   * @memberof ClientPostRequestContactInfo
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof ClientPostRequestContactInfo
   */
  phoneNumber: string;
}
/**
 *
 * @export
 * @interface ClientPutRequest
 */
export interface ClientPutRequest {
  /**
   *
   * @type {ClientPostRequestAddress}
   * @memberof ClientPutRequest
   */
  address?: ClientPostRequestAddress;
  /**
   *
   * @type {ClientPostRequestContactInfo}
   * @memberof ClientPutRequest
   */
  contactInfo?: ClientPostRequestContactInfo;
}
/**
 *
 * @export
 * @interface ClientResult
 */
export interface ClientResult {
  /**
   *
   * @type {Client}
   * @memberof ClientResult
   */
  data?: Client;
  /**
   *
   * @type {Array<Message>}
   * @memberof ClientResult
   */
  messages?: Array<Message>;
}
/**
 * Message field is not required
 * @export
 * @interface ErrorResult
 */
export interface ErrorResult {
  /**
   *
   * @type {Array<Message>}
   * @memberof ErrorResult
   */
  messages?: Array<Message>;
}
/**
 *
 * @export
 * @interface Message
 */
export interface Message {
  /**
   *
   * @type {string}
   * @memberof Message
   */
  level?: MessageLevelEnum;
  /**
   * You can expose this enum for all your errors UNDEFINED - Any undefined message type LOGGER_MESSAGE - Same as undefined
   * @type {string}
   * @memberof Message
   */
  type?: MessageTypeEnum;
  /**
   * Message, that describes what went wrong
   * @type {string}
   * @memberof Message
   */
  text?: string;
}

export const MessageLevelEnum = {
  Emergency: 'emergency',
  Alert: 'alert',
  Critical: 'critical',
  Error: 'error',
  Warning: 'warning',
  Notice: 'notice',
  Info: 'info',
} as const;

export type MessageLevelEnum = typeof MessageLevelEnum[keyof typeof MessageLevelEnum];
export const MessageTypeEnum = {
  Undefined: 'UNDEFINED',
  LoggerMessage: 'LOGGER_MESSAGE',
} as const;

export type MessageTypeEnum = typeof MessageTypeEnum[keyof typeof MessageTypeEnum];

/**
 *
 * @export
 * @interface SuccessResult
 */
export interface SuccessResult {
  /**
   *
   * @type {object}
   * @memberof SuccessResult
   */
  data?: object;
  /**
   *
   * @type {Array<Message>}
   * @memberof SuccessResult
   */
  messages?: Array<Message>;
}

/**
 * ClientsApi - axios parameter creator
 * @export
 */
export const ClientsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get list of Order Deals
     * @summary Get list of Order Deals
     * @param {string} [rql]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [sortBy]
     * @param {'asc' | 'desc'} [sortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsGet: async (
      rql?: string,
      limit?: number,
      offset?: number,
      sortBy?: string,
      sortOrder?: 'asc' | 'desc',
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (rql !== undefined) {
        localVarQueryParameter['rql'] = rql;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sortBy'] = sortBy;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sortOrder'] = sortOrder;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get info about specific Client
     * @param {string} id Id of resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsIdGet: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('clientsIdGet', 'id', id);
      const localVarPath = `/clients/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update client by id
     * @param {string} id
     * @param {ClientPutRequest} clientPutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsIdPut: async (
      id: string,
      clientPutRequest: ClientPutRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('clientsIdPut', 'id', id);
      // verify required parameter 'clientPutRequest' is not null or undefined
      assertParamExists('clientsIdPut', 'clientPutRequest', clientPutRequest);
      const localVarPath = `/clients/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        clientPutRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a Client
     * @param {ClientPostRequest} clientPostRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsPost: async (
      clientPostRequest: ClientPostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'clientPostRequest' is not null or undefined
      assertParamExists('clientsPost', 'clientPostRequest', clientPostRequest);
      const localVarPath = `/clients`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        clientPostRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ClientsApi - functional programming interface
 * @export
 */
export const ClientsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ClientsApiAxiosParamCreator(configuration);
  return {
    /**
     * Get list of Order Deals
     * @summary Get list of Order Deals
     * @param {string} [rql]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [sortBy]
     * @param {'asc' | 'desc'} [sortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsGet(
      rql?: string,
      limit?: number,
      offset?: number,
      sortBy?: string,
      sortOrder?: 'asc' | 'desc',
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ClientListResult>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsGet(
        rql,
        limit,
        offset,
        sortBy,
        sortOrder,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get info about specific Client
     * @param {string} id Id of resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsIdGet(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResult>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsIdGet(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update client by id
     * @param {string} id
     * @param {ClientPutRequest} clientPutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsIdPut(
      id: string,
      clientPutRequest: ClientPutRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResult>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsIdPut(
        id,
        clientPutRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create a Client
     * @param {ClientPostRequest} clientPostRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsPost(
      clientPostRequest: ClientPostRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResult>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsPost(
        clientPostRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ClientsApi - factory interface
 * @export
 */
export const ClientsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ClientsApiFp(configuration);
  return {
    /**
     * Get list of Order Deals
     * @summary Get list of Order Deals
     * @param {string} [rql]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [sortBy]
     * @param {'asc' | 'desc'} [sortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsGet(
      rql?: string,
      limit?: number,
      offset?: number,
      sortBy?: string,
      sortOrder?: 'asc' | 'desc',
      options?: any,
    ): AxiosPromise<ClientListResult> {
      return localVarFp
        .clientsGet(rql, limit, offset, sortBy, sortOrder, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get info about specific Client
     * @param {string} id Id of resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsIdGet(id: string, options?: any): AxiosPromise<ClientResult> {
      return localVarFp
        .clientsIdGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update client by id
     * @param {string} id
     * @param {ClientPutRequest} clientPutRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsIdPut(
      id: string,
      clientPutRequest: ClientPutRequest,
      options?: any,
    ): AxiosPromise<ClientResult> {
      return localVarFp
        .clientsIdPut(id, clientPutRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create a Client
     * @param {ClientPostRequest} clientPostRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsPost(
      clientPostRequest: ClientPostRequest,
      options?: any,
    ): AxiosPromise<ClientResult> {
      return localVarFp
        .clientsPost(clientPostRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ClientsApi - object-oriented interface
 * @export
 * @class ClientsApi
 * @extends {BaseAPI}
 */
export class ClientsApi extends BaseAPI {
  /**
   * Get list of Order Deals
   * @summary Get list of Order Deals
   * @param {string} [rql]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [sortBy]
   * @param {'asc' | 'desc'} [sortOrder]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientsApi
   */
  public clientsGet(
    rql?: string,
    limit?: number,
    offset?: number,
    sortBy?: string,
    sortOrder?: 'asc' | 'desc',
    options?: AxiosRequestConfig,
  ) {
    return ClientsApiFp(this.configuration)
      .clientsGet(rql, limit, offset, sortBy, sortOrder, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get info about specific Client
   * @param {string} id Id of resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientsApi
   */
  public clientsIdGet(id: string, options?: AxiosRequestConfig) {
    return ClientsApiFp(this.configuration)
      .clientsIdGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update client by id
   * @param {string} id
   * @param {ClientPutRequest} clientPutRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientsApi
   */
  public clientsIdPut(
    id: string,
    clientPutRequest: ClientPutRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientsApiFp(this.configuration)
      .clientsIdPut(id, clientPutRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a Client
   * @param {ClientPostRequest} clientPostRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientsApi
   */
  public clientsPost(
    clientPostRequest: ClientPostRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClientsApiFp(this.configuration)
      .clientsPost(clientPostRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
