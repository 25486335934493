import React, { FC, useState, useContext } from 'react';
import Box from '@material-ui/core/Box';
import { LABEL_CREATORS } from './constants';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import MuiButton from '../../../../../../UI/MuiButton';
import { Pickup } from '../../../api-clients/types';
import { DealContext } from '../../../GenericDeal/context/dealContext';
import Axios from 'axios';
import { UploadBase64FileButton } from '../../../../../../UI/UploadBase64FileButton';
import { LabelCard } from './components/LabelCard';
import { useGetLabels } from './hooks/useGetLabels';
import { Spinner } from '../../../../../../UI';
import { Alert } from '@material-ui/lab';
import { downloadDataWithContentType } from 'rollun-ts-utils';

type SelectConfig = {
  label: string;
  value: string;
  setter: (value: string) => void;
  options: string[];
};

const useStyles = makeStyles(() => ({
  input: {
    top: 'auto',
    left: 'auto',
  },
  width: {
    minWidth: 200,
    width: 200,
  },
}));

const UploadLabel: FC = () => {
  const classes = useStyles();
  const dealContext = useContext(DealContext);
  const { data: labels, isFetching } = useGetLabels(dealContext?.deal.id);
  const [result, setResult] = useState<{
    status: 'success' | 'error' | 'idle';
    message?: string;
    code?: string;
  }>({ status: 'idle' });
  const [loading, setLoading] = useState(false);
  const [labelCreator, setLabelCreator] = useState<
    typeof LABEL_CREATORS[number]
  >('-');
  const [date, setDate] = useState('');
  const [cost, setCost] = useState('');
  const [labelData, setLabelData] = useState({
    file: '',
    name: '',
  });

  const selectConfigs: SelectConfig[] = [
    {
      label: 'Label creators',
      value: labelCreator,
      setter: (value) => setLabelCreator(value as any),
      options: (LABEL_CREATORS as unknown) as string[],
    },
  ];

  const makeUploadLabelsPayload = async () => {
    const deal = dealContext?.deal as Pickup;
    return {
      carrier: deal.carrier,
      labelCreator: labelCreator === '-' ? '' : labelCreator,
      dealId: deal.id,
      tracknumber: deal.tracknumber,
      label: labelData.file,
      date,
      cost: Number(cost),
    };
  };

  const makeSelect = ({ label, value, setter, options }: SelectConfig) => (
    <FormControl
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      key={label}
    >
      <Box className={classes.width}>
        <InputLabel id={label} className={classes.input}>
          {label}
        </InputLabel>
        <Select
          labelId={label}
          value={value}
          className={classes.width}
          onChange={(e) => setter(e.target.value as string)}
        >
          {(options as any[]).map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </FormControl>
  );

  const handleUploadLabel = async () => {
    setLoading(true);
    setResult({ status: 'idle' });

    try {
      await Axios.post(
        'https://crm-callbacks.rollun.net/labelsDatastore',
        await makeUploadLabelsPayload(),
      );
      setResult({ status: 'success' });
    } catch (e) {
      setResult({
        status: 'error',
        message: (e as any).message,
        code: (e as any).statusCode,
      });
    }

    setDate('');
    setLabelData({ file: '', name: '' });
    setLabelCreator('-');
    setCost('');

    setLoading(false);
  };

  if (isFetching && !labels) {
    return <Spinner />;
  }

  return (
    <Box
      style={{
        gap: 8,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
      }}
    >
      {result.status === 'idle' ? (
        <>
          {!!labels && !!labels.length && (
            <Alert severity="info" variant="standard">
              <Typography>
                This pickup already has the uploaded labels
              </Typography>
              <MuiButton
                variant="contained"
                onClick={() => {
                  for (let i = 0; i < labels.length; i++) {
                    if (labels[i]?.Label) {
                      downloadDataWithContentType(
                        Buffer.from(labels[i].Label, 'base64'),
                        'application/pdf',
                        `${labels[i]?.Tracknumber || labels[i]?.Id}.pdf`,
                      );
                    }
                  }
                }}
              >
                Download linked labels
              </MuiButton>
            </Alert>
          )}
          <TextField
            label="Tracknumber"
            value={(dealContext?.deal as Pickup).tracknumber}
            disabled={true}
            className={classes.width}
          />
          <TextField
            label="DealId"
            value={dealContext?.deal.id}
            disabled={true}
            className={classes.width}
          />
          {selectConfigs.map((config) => makeSelect(config))}
          <TextField
            label="Label created date"
            type="date"
            value={date}
            className={classes.width}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => setDate(event.target.value)}
          />
          <TextField
            label="Cost"
            type="number"
            value={cost}
            className={classes.width}
            onChange={(event) => setCost(event.target.value)}
          />
          {labelData.name && (
            <LabelCard
              className={classes.width}
              labelName={labelData.name}
              removeLabel={() => setLabelData({ file: '', name: '' })}
            />
          )}
          <UploadBase64FileButton
            className={classes.width}
            setBase64Data={setLabelData}
          >
            Upload File
          </UploadBase64FileButton>
          <Box>
            <MuiButton
              color="success"
              style={{ width: 200 }}
              onClick={handleUploadLabel}
              disabled={
                loading || !(date && cost && labelData.file && labelCreator)
              }
            >
              Upload
            </MuiButton>
          </Box>
        </>
      ) : result.status === 'success' ? (
        <Box>
          <Typography>Uploaded successful</Typography>
          <MuiButton onClick={() => setResult({ status: 'idle' })}>
            Go back
          </MuiButton>
        </Box>
      ) : result.status === 'error' ? (
        <Box>
          <Typography>Error: {result?.message}</Typography>
          <Typography>Code: {result?.code}</Typography>
          <MuiButton onClick={() => setResult({ status: 'idle' })}>
            Go back
          </MuiButton>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default UploadLabel;
