import React, { FC, useContext, useState } from 'react';
import {
  getHowToBuyOptions,
  HowToBuyOptionData,
  HowToBuyOptionsResult,
} from '../HowToBuy4/utils/getHowToBuyOptions';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { DealContext } from '../../../../GenericDeal/context/dealContext';
import HowToBuyRow from './components/HowToBuyRow';
import MuiButton from '../../../../../../../UI/MuiButton';
import {
  Order,
  Dropship,
  Pickup,
  SPECIAL_ITEMS_IDS,
  Position,
} from '../../../../api-clients/types';
import { getSupplierInfoBySenderName } from '../HowToBuy4/utils/supplierUtils';
import { apiClientsDropship } from '../../../../api-clients/api-clients-dropships';
import { apiClientsPickups } from '../../../../api-clients/api-clients-pickups';
import { httpErrorHandlerPromised } from '../../../../../../../utils/common.utils';
import { Link } from 'react-router-dom';
import { Query, Eq, And, Eqn } from 'rollun-ts-rql';
import { uniqBy } from 'lodash';
import {
  GenericHowToBuyVersion,
  ShippingInfo,
} from './components/ShippingInfo';

interface GenericHowToBuyProps {
  howToBuyOptions: ReturnType<typeof getHowToBuyOptions>;
  version: GenericHowToBuyVersion;
}

const getShortVirtualWarehouseName = (WHName: string) => {
  if (
    WHName.indexOf('pickup_ut') === 0 ||
    WHName.indexOf('pickup_ky') === 0 ||
    WHName.indexOf('pickup_tx') === 0
  ) {
    return WHName.replace(/(_RM|_ROF)$/, '');
  }
  return WHName;
};

const GenericHowToBuy: FC<GenericHowToBuyProps> = ({
  howToBuyOptions: { howToBuyOptions, bestShipping },
  version,
}) => {
  const dealContext = useContext(DealContext);
  const deal = dealContext?.deal as Order;
  console.log({ deal, dealContext });
  const uniqueItems = uniqBy(
    deal.no_special_items_positions,
    ({ article }) => article,
  );
  const uniqueItemsWithShipping = uniqBy(
    deal.positions.filter(
      (position) =>
        // we also need to include shipping price
        !SPECIAL_ITEMS_IDS.filter((id) => id !== '11112').includes(
          position.article,
        ),
    ),
    ({ article }) => article,
  );

  const [loading, setLoading] = useState(false);
  const [createdDeals, setCreatedDeals] = useState<
    {
      success: boolean;
      name: string;
      error: string | null;
      dealId?: string;
      type: string;
    }[]
  >([]);
  const [selectedItems, setSelectedItems] = useState<
    {
      warehouse: string;
      rid: string;
      shippingMethod: string;
      enterTracknumber: string;
      labelProvider: string;
    }[]
  >([]);

  const canCreateDeal = selectedItems.length === uniqueItems?.length;

  const reportHowToBuyBestShippingAlgorithmResult = async (
    howToBuy: {
      warehouse: string;
      rid: string;
      shippingMethod: string;
      enterTracknumber: string;
      labelProvider: string;
    }[],
    bestShipping: HowToBuyOptionsResult['bestShipping'],
  ) => {
    // logic for 2 or more options is not provided
    if (Object.keys(howToBuy).length !== 1) {
      return;
    }

    const data = howToBuy[0];

    const isBestShippAvailable = !!bestShipping;

    const isManagerAgreeWithBestShipping =
      isBestShippAvailable &&
      data.warehouse === bestShipping?.deliverySender &&
      data.shippingMethod === bestShipping?.shippingMethodName;

    const howToBuy3Result = JSON.stringify({
      manager_result: isBestShippAvailable
        ? isManagerAgreeWithBestShipping
        : null,
      manager_sr_virtual: getShortVirtualWarehouseName(data.warehouse),
      manager_sr_ship_method: data.shippingMethod,
      // add flag, if there was 'Priority Mail cubic' as best shipping, but manager did not select it.
      ...(!isManagerAgreeWithBestShipping &&
        isBestShippAvailable &&
        bestShipping?.shippingMethodName === 'Priority Mail cubic' && {
          manager_did_not_select_priority_mail_cubic: true,
        }),
    });

    try {
      await dealContext?.onDealUpdate?.(deal.id, {
        howToBuy3Result,
      });
    } catch (e) {
      console.log(e);
      alert(`
        Could not save howToBuy3Result: ${(e as any).message}
        Report this issue to dev pls.
        best shipping - ${JSON.stringify(bestShipping)}
        manager choice - ${JSON.stringify(howToBuy3Result)}
       `);
    }
  };

  const howToBuyToDeals = (
    howToBuy: {
      warehouse: string;
      rid: string;
      shippingMethod: string;
      enterTracknumber: string;
      labelProvider: string;
    }[],
  ) => {
    const howToBuyGrouppedByWarehouse = howToBuy.reduce((acc, data) => {
      const existingItem = acc.find(
        (item) => item.warehouse === data.warehouse,
      );

      if (!existingItem) {
        acc.push({
          ...data,
          rids: [data.rid],
        });
        return acc;
      }

      existingItem.rids.push(data.rid);

      return acc;
    }, [] as any[]);

    const result: any[] = [];

    for (const {
      warehouse,
      rids,
      shippingMethod,
      enterTracknumber,
      labelProvider,
    } of howToBuyGrouppedByWarehouse) {
      const {
        id: fullSupplierName,
        supplier_type,
      } = getSupplierInfoBySenderName(warehouse)!;

      let supplierName = fullSupplierName.replace(
        /(pickup(ut|ky|tx|ny|wi|nc|cn)|fast|long|short|)/gi,
        '',
      );

      if (supplierName === 'PartsUnlimitedPickup') {
        supplierName = 'PartsUnlimited';
      }

      if (supplierName === 'TuckerRockyPickup') {
        supplierName = 'TuckerRocky';
      }

      const getSenderOrVirtualWarehouse = () => {
        return warehouse.replace(/_rof/i, '');
      };

      const actualItems = uniqueItems?.filter((item) =>
        rids.includes(item.article),
      );

      const sender = getSenderOrVirtualWarehouse();

      const carriers = ['usps', 'ups', 'fedex', 'ontrac'];
      const [carrier, ...rest] = shippingMethod.split(' ');
      if (
        sender.startsWith('pickup') &&
        carriers.includes(carrier.toLowerCase())
      ) {
        result.push({
          mpName: deal.mpName,
          mpOrderNumber: deal.mpOrderNumber,
          srShipMethod: rest.join(' '),
          enterTracknumber: enterTracknumber,
          labelProvider: labelProvider,
          type: supplier_type,
          srName: supplierName,
          contractor: deal.contractor,
          carrier,
          relatedOrder: deal.id,
          owner: deal.owner,
          sender,
          positions: actualItems.map((item) => ({
            ...item,
            cost: 0,
          })),
        });

        continue;
      }

      result.push({
        mpName: deal.mpName,
        mpOrderNumber: deal.mpOrderNumber,
        srShipMethod: shippingMethod,
        enterTracknumber: enterTracknumber,
        labelProvider: labelProvider,
        type: supplier_type,
        srName: supplierName,
        contractor: deal.contractor,
        relatedOrder: deal.id,
        owner: deal.owner,
        sender,
        positions: actualItems.map((item) => ({
          ...item,
          cost: 0,
        })),
      });
    }

    return result;
  };

  const findItemFromDealInDeals = (
    srcDeal: Order,
    dealsToSearch: (Pickup | Dropship)[],
  ) => {
    const { positions: srcPositions } = srcDeal;

    for (const deal of dealsToSearch) {
      const { no_special_items_positions: positions = [] } = deal;

      console.log('positions', positions, srcPositions);
      const sameItem = positions.find((pos) =>
        srcPositions.find((srcPos) => srcPos.article === pos.article),
      );
      if (sameItem)
        return {
          dealId: deal.id,
          itemId: sameItem.offerId,
          rollunId: sameItem.article || 'No rollun ID',
        };
    }
    return null;
  };

  const checkForDuplicates = async (dealPayload: any, supplierName: string) => {
    const type: 'DROPSHIP' | 'PICKUP' = dealPayload.type;
    const apiClient =
      type === 'DROPSHIP' ? apiClientsDropship : apiClientsPickups;
    const query = new Query().setQuery(
      new And([
        new Eq('RelatedOrder', dealPayload.relatedOrder),
        new Eqn('ArchiveScenario'),
      ]),
    );
    const linkedDeals = [
      ...(await apiClientsPickups.getByQuery(query)),
      ...(await apiClientsDropship.getByQuery(query)),
    ];
    console.log('linkedDeals', linkedDeals, dealPayload);
    const sameItem = findItemFromDealInDeals(dealPayload, linkedDeals);
    let failedDeals: { supplierName: string }[] = [];

    if (
      sameItem &&
      !confirm(`
          Attempt to create deal
          for supplier [${supplierName}] failed!
          Linked deal(id: ${sameItem?.dealId})
          already has item(id: ${sameItem?.itemId}, rollun_id: ${sameItem?.rollunId}).
          Create deal anyway?
      `)
    ) {
      failedDeals = failedDeals.concat({ supplierName: supplierName });
      return true;
    }
    const mpName = dealPayload.mpNmae;
    const mpOrderNumber = dealPayload.mpOrderNumber;
    const createdDealsIds = await apiClient.getByQuery(
      new Query().setQuery(
        new And([
          new Eq('MpName', mpName),
          new Eq('MpOrderNumber', mpOrderNumber),
          new Eq('SrName', supplierName),
        ]),
      ),
    );

    if (
      createdDealsIds.length > 0 &&
      !confirm(`
          Deals with
          MP Name - ${mpName}
          MP Order Num - ${mpOrderNumber}
          Sr Name - ${supplierName}
          already exists!
          Ids: ${(createdDealsIds as any[]).map((deal) => deal.id).join(', ')}
          Create deal anyway?
      `)
    ) {
      failedDeals = failedDeals.concat({ supplierName: supplierName });
      return true;
    }

    console.log('failedDeals', failedDeals);
  };

  const checkNegativeProfit = (mpDeals: Order[]) => {
    let sum = 0;

    for (const selectedItem of selectedItems) {
      const deal = mpDeals.find((deal) =>
        deal.positions.some(
          (pos: Position) => pos.article === selectedItem.rid,
        ),
      );

      const warehouse = howToBuyOptions[selectedItem.warehouse].find(
        (wh) => wh.rollunId === selectedItem.rid,
      );
      const position = deal?.positions.find(
        (pos: Position) => pos.article === selectedItem.rid,
      );

      if (!!position) {
        sum += position.quantity * (warehouse?.price || 0);
      }
    }

    if (version === 'HowToBuy') {
      const selectedItem = selectedItems[0];
      const howToBuy4 =
        typeof deal?.howToBuy4 === 'string'
          ? JSON.parse(deal?.howToBuy4)
          : deal?.howToBuy4;

      const shipOptions = ((howToBuy4 as unknown) as HowToBuyOptionData)
        ?.items_data[selectedItem.rid]?.ship_options;

      const option = shipOptions.find(
        (opt) =>
          opt.sender === selectedItem.warehouse &&
          opt.Sr_ship_method === selectedItem.shippingMethod,
      );

      if (option?.price_ship) {
        sum += option.price_ship;
      }
    }

    const profit = +deal.profit;

    if (profit < sum) {
      const createDealAnyway = confirm(`
        Total price with Shipping(${sum.toFixed(2)}) 
        more than Profit(${profit.toFixed(2)}).
        Create deal anyway?
      `);

      if (!createDealAnyway) {
        return true;
      }
    }
    return false;
  };

  const handleCreateDeal = async () => {
    setLoading(true);
    console.log(selectedItems);
    const mpDeals = howToBuyToDeals(selectedItems);

    const isNegativeProfit = checkNegativeProfit(mpDeals);
    if (isNegativeProfit) {
      return;
    }

    const results: any[] = [];

    for (const dealPayload of mpDeals) {
      const supplierName = dealPayload.srName;
      const shipMethod = dealPayload.srShipMethod;
      try {
        if (await checkForDuplicates(dealPayload, supplierName)) {
          results.push({
            success: false,
            name: `Deal with supplier ${supplierName}, ship method ${shipMethod}`,
            error: 'Canceled by user',
          });
          continue;
        }

        const apiClient =
          dealPayload.type === 'DROPSHIP'
            ? apiClientsDropship
            : apiClientsPickups;

        if (dealPayload.type === 'DROPSHIP') {
          delete dealPayload.labelProvider;
        }

        const newDeal = await apiClient.create(dealPayload);
        results.push({
          success: true,
          name: `Deal with supplier ${supplierName}, ship method ${shipMethod}`,
          dealId: newDeal.id,
          type: dealPayload.type,
        });
      } catch (e) {
        results.push({
          success: false,
          name: `Deal with supplier ${supplierName}, ship method ${shipMethod}`,
          error: (await httpErrorHandlerPromised(e)).text,
        });
      }
    }

    await reportHowToBuyBestShippingAlgorithmResult(
      selectedItems,
      bestShipping,
    );

    setCreatedDeals(results);
    setLoading(false);
  };

  const dealPrice = uniqueItemsWithShipping.reduce(
    (acc, item) => acc + +item.cost * +item.quantity,
    0,
  );

  const isEbayMarketplace = deal.mpName.includes('Ebay');
  const clientPaidInfo = isEbayMarketplace
    ? `Client paid(${dealPrice.toFixed(2)}$) - 10% = ${(
        dealPrice * 0.9
      ).toFixed(2)}$`
    : `Client paid(${dealPrice.toFixed(2)}$) - 12% = ${(
        dealPrice * 0.88
      ).toFixed(2)}$`;

  console.log(howToBuyOptions, createdDeals);

  return (
    <Box
      style={{
        overflowY: 'scroll',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
      }}
    >
      <ShippingInfo
        version={version}
        bestShipping={bestShipping}
        deal={deal}
        howToBuyOptions={howToBuyOptions}
        rawDeal={dealContext?.rawDeal}
        uniqueItems={uniqueItems}
      />
      {createdDeals.length > 0 &&
        createdDeals.map((deal) =>
          deal.success ? (
            <Typography key={deal.dealId}>
              <Link
                to={`/crm/deals/${deal.type.toLowerCase()}s/${deal.dealId}`}
              >
                {deal.name}
              </Link>
            </Typography>
          ) : (
            <Typography
              key={deal?.dealId}
            >{`Creating ${deal.name} failed with error - ${deal.error}`}</Typography>
          ),
        )}
      {uniqueItems?.length === 0 && (
        <div>There are no products in this deal</div>
      )}
      <Typography>{clientPaidInfo}</Typography>
      {Object.entries(howToBuyOptions).length === 0 ? (
        <div>Item is out of stock</div>
      ) : (
        createdDeals.length === 0 &&
        !loading && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Supplier\\Rollun ID</TableCell>
                  {uniqueItems?.map((product) => (
                    <TableCell
                      key={product.article}
                    >{`${product.article} * ${product.quantity}`}</TableCell>
                  ))}
                  <TableCell>Shipping</TableCell>
                  <TableCell>LabelProvider</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(howToBuyOptions).map(
                  ([warehouse, data], index) => (
                    <HowToBuyRow
                      version={version}
                      key={index}
                      isPickup={
                        getSupplierInfoBySenderName(warehouse)
                          ?.supplier_type === 'PICKUP' &&
                        version === 'CreateLinkedDeals'
                      }
                      onRowClick={(
                        rid: string,
                        incomingWarehouse: string,
                        shippingMethod: string,
                        enterTracknumber: string,
                        labelProvider: string,
                      ) =>
                        setSelectedItems((prev) => {
                          const existingItem = prev.find(
                            (item) => item.rid === rid,
                          );

                          if (existingItem?.warehouse === incomingWarehouse) {
                            return prev.filter((item) => item.rid !== rid);
                          }

                          if (existingItem?.warehouse !== incomingWarehouse) {
                            return [
                              ...prev.filter((item) => item.rid !== rid),
                              {
                                rid,
                                warehouse: incomingWarehouse,
                                shippingMethod,
                                enterTracknumber,
                                labelProvider,
                              },
                            ];
                          }

                          return [
                            ...prev,
                            {
                              warehouse,
                              rid,
                              shippingMethod,
                              enterTracknumber,
                              labelProvider,
                            },
                          ];
                        })
                      }
                      onShippingMethodChange={(warehouse, shippingMethod) => {
                        setSelectedItems((prev) => {
                          const existingItems = prev.filter(
                            (item) => item.warehouse === warehouse,
                          );

                          if (existingItems.length > 0) {
                            return [
                              ...prev.filter(
                                (item) => item.warehouse !== warehouse,
                              ),
                              ...existingItems.map((item) => ({
                                ...item,
                                shippingMethod,
                              })),
                            ];
                          }

                          return prev;
                        });
                      }}
                      selectedItems={selectedItems}
                      onLabelProviderChange={(warehouse, labelProvider) =>
                        setSelectedItems((prev) => {
                          const existingItems = prev.filter(
                            (item) => item.warehouse === warehouse,
                          );

                          if (existingItems.length > 0) {
                            return [
                              ...prev.filter(
                                (item) => item.warehouse !== warehouse,
                              ),
                              ...existingItems.map((item) => ({
                                ...item,
                                labelProvider,
                              })),
                            ];
                          }

                          return prev;
                        })
                      }
                      warehouse={warehouse}
                      howToBuyOption={data}
                      bestShipping={bestShipping}
                    />
                  ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )
      )}
      <MuiButton
        style={{
          width: 150,
        }}
        color="success"
        disabled={!canCreateDeal || loading}
        onClick={handleCreateDeal}
      >
        Create
      </MuiButton>
    </Box>
  );
};

export default GenericHowToBuy;
