import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { BagFillingContext } from '../BagFillingContext';
import { useProgramPages } from '../hooks';
import { BagFillingPages } from '../BagFillingPages';
import { useQueryParamState } from '../../../hooks/useQueryParamState';
import Header from './Header';

interface BagFillingProps {
  appName: string;
}

const BagFilling: FC<BagFillingProps> = ({ appName }) => {
  const {
    currentPage,
    goToPreviousPage,
    goToNextPage,
    goToPage,
  } = useProgramPages();
  const [bagId, setBagId] = useQueryParamState('bagId');
  const [location, setLocation] = useQueryParamState('location');

  return (
    <BagFillingContext.Provider
      value={{
        bagId,
        setBagId,
        goToPage,
        goToNextPage,
        goToPreviousPage,
        page: currentPage,
        storage: location,
        setStorage: setLocation,
      }}
    >
      <Box maxWidth={500} margin="auto" marginBottom={5}>
        <Header appName={appName} />
        {BagFillingPages[currentPage]}
      </Box>
    </BagFillingContext.Provider>
  );
};

export default BagFilling;
