import { randomString } from 'rollun-ts-utils';

interface LifecycleTokenStorage {
  getItem: (name: string) => string | null;
  setItem: (key: string, value: string) => void;
}

export default class LifecycleToken {
  public static TokenName = 'lifecycle_token';
  public static ParentTokenName = 'parent_lifecycle_token';

  constructor(private storage: LifecycleTokenStorage) {}

  private static generateLCToken(prefix: 'ui-request' | 'ui-session') {
    const uniqueToken = randomString(
      30,
      'QWERTYUIOPASDFGHJKLZXCVBNM0123456789',
    );
    return `${prefix}-${uniqueToken}`;
  }

  public static isValidLCToken(token: string | null) {
    return typeof token === 'string';
  }

  public getSessionToken() {
    let lcToken = this.storage.getItem(LifecycleToken.ParentTokenName);

    // keep the same token for the session
    if (!lcToken || !LifecycleToken.isValidLCToken(lcToken)) {
      lcToken = LifecycleToken.generateLCToken('ui-session');
      this.storage.setItem(LifecycleToken.ParentTokenName, lcToken);
    }

    return lcToken;
  }

  public getRequestToken() {
    return LifecycleToken.generateLCToken('ui-request');
  }
}
