import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import {
  middlewaresVariants,
  privilegesVariants,
  SelectorProps,
} from '../CreateService/AddRoute';
import {
  TextField,
  Grid,
  Typography,
  Box,
  CircularProgress,
  useTheme,
  InputAdornment,
} from '@material-ui/core';
import Select from 'react-select';
import MuiButton from '../../../../UI/MuiButton';
import * as yup from 'yup';
import { FormikHelpers } from 'formik/dist/types';
import { useRouteDatastore } from '../../../../hooks/datastores';
import { replaceSlashWithDash } from '../EditService/grid/RulesEditor';
import CreateResource from './CreateResource';
import { And, Eq, Query } from 'rollun-ts-rql';
import { GlobalErrorContext } from '../../../RootApp/Root.app';
import { CreateServiceWithResourceContext } from './CreateServiceWithResource';
import MuiTooltip from '../../../../UI/MuiTooltip';
import { Help } from '@material-ui/icons';
import { SERVICE_CONSTRUCTOR_FORM_HELPER_TEXTS } from '../../constants';

const {
  ADD_ROUTE: { NAME, MIDDLEWARE, PATH },
} = SERVICE_CONSTRUCTOR_FORM_HELPER_TEXTS;

interface InitialValues {
  name: string;
  path: string;
  privileges: SelectorProps[];
}

const CreateRouteSchema = yup.object().shape({
  name: yup.string().max(150).min(1).required(),
  path: yup
    .string()
    .max(150)
    .min(1)
    .required()
    .matches(
      /^\/.*(?!\/$)$/,
      'The path must start with forward slash and not end with one',
    ),
  privileges: yup
    .array()
    .required()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      }),
    )
    .min(1, 'The privileges cannot be empty'),
});

const CreateServiceWithResource = () => {
  const [{ status, error }, dispatch] = useRouteDatastore();
  const [routeCreatedName, setRouteCreatedName] = useState('');
  const globalError = useContext(GlobalErrorContext);
  const createService = useContext(CreateServiceWithResourceContext);
  const theme = useTheme();

  const checkIfExists = async (name: string, path: string) => {
    const res = await dispatch(
      'QUERY',
      new Query({
        query: new And([new Eq('path', path), new Eq('name', name)]),
      }),
    );

    return res && res.length !== 0;
  };

  const handleSubmit = async (
    { path, name, privileges }: InitialValues,
    { setSubmitting }: FormikHelpers<InitialValues>,
  ) => {
    if (await checkIfExists(name, path)) {
      globalError?.showAlert(
        'The route already exists. Cannot create one.',
        'error',
      );
      return;
    }

    const methods = privileges.reduce(
      (acc, curr, index) =>
        acc + curr.label + (index === privileges.length - 1 ? '' : ','),
      '',
    );

    const res = await dispatch('CREATE', {
      path,
      middleware: middlewaresVariants[0].value,
      name,
      methods,
    });

    // create route for datastore
    const datastoreRoute = await dispatch('CREATE', {
      path: '/api/datastore' + path,
      middleware: middlewaresVariants[1].value,
      name: 'api-datastore-' + name,
      methods,
    });

    createService?.setServiceConfig(
      {
        name,
        path,
        datastore: {
          routeId: (datastoreRoute && datastoreRoute[0]?.id) || '',
          resourceId: '',
        },
      },
      'create-route',
    );
    createService?.setRouteId((res && res[0].id) || '', 'create-route');
    setRouteCreatedName((res && res[0].name) || '');
    setSubmitting(false);
  };

  useEffect(() => {
    setRouteCreatedName('');
  }, [error]);

  const initialValues: InitialValues = {
    name: '',
    path: '',
    privileges: [],
  };

  return (
    <Box maxWidth={500} margin="auto">
      <Box marginTop={2} marginBottom={3}>
        <Typography variant="h5">Create a route</Typography>
      </Box>
      <Formik
        initialValues={initialValues}
        validationSchema={CreateRouteSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          errors,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <Form onSubmit={handleSubmit} translate="none">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled={routeCreatedName.length !== 0}
                  label="Path of the page"
                  helperText={errors.path ? errors.path : ''}
                  error={!!errors.path}
                  fullWidth
                  value={values.path}
                  name="path"
                  placeholder="Path of the page"
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue('name', replaceSlashWithDash(e.target.value));
                  }}
                  onBlur={handleBlur}
                  InputProps={{
                    endAdornment: (
                      <MuiTooltip title={PATH} arrow>
                        <InputAdornment position="end">
                          <Help />
                        </InputAdornment>
                      </MuiTooltip>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={routeCreatedName.length !== 0}
                  label="Name of the page"
                  helperText={errors.name ? errors.name : ''}
                  error={!!errors.name}
                  fullWidth
                  value={values.name}
                  name="name"
                  placeholder="Name of the page"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    endAdornment: (
                      <MuiTooltip title={NAME} arrow>
                        <InputAdornment position="end">
                          <Help />
                        </InputAdornment>
                      </MuiTooltip>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">Middleware:</Typography>
                <Box>
                  <Box width="100%" display="flex">
                    <Box width="100%" marginRight={1}>
                      <Select
                        name="privileges"
                        options={privilegesVariants}
                        isDisabled={routeCreatedName.length !== 0}
                        value={values.privileges}
                        onChange={(value) =>
                          setFieldValue('privileges', value || [])
                        }
                        onBlur={handleBlur}
                        isMulti={true}
                      />
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <MuiTooltip title={MIDDLEWARE} arrow>
                        <Help />
                      </MuiTooltip>
                    </Box>
                  </Box>
                  <Box color={theme.palette.error.dark} fontSize="0.75rem">
                    {errors.privileges}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {error && (
                  <Typography align="center" color="error">
                    {error.text}
                  </Typography>
                )}
                <MuiButton
                  color="success"
                  type="submit"
                  fullWidth
                  disabled={
                    routeCreatedName.length !== 0 ||
                    isSubmitting ||
                    !(isValid && dirty)
                  }
                >
                  {status === 'loading' ? (
                    <CircularProgress size="1.5rem" />
                  ) : (
                    'Create route'
                  )}
                </MuiButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      {routeCreatedName.length !== 0 && (
        <CreateResource name={routeCreatedName} />
      )}
    </Box>
  );
};

export default CreateServiceWithResource;
