import AggregateFunctionNode from 'rollun-ts-rql/dist/nodes/aggregateNodes/AggregateFunctionNode';
import React, { FC } from 'react';

export interface NodeFieldProps {
  fieldName: string | AggregateFunctionNode;
  nodeType: 'default' | 'selectnode';
  onAggregateNodeSelect?: (name: string, type: string) => void;
  onNodeSelect?(name: string): void;
  selected?: boolean;
}

export const AGGREGATE_NODES = ['', 'min', 'max', 'avg', 'count', 'sum'];

const NodeFieldName: FC<NodeFieldProps> = ({
  fieldName,
  nodeType,
  onAggregateNodeSelect,
  onNodeSelect,
  selected,
}) => {
  const handleSelectField = () => {
    if (onNodeSelect && !(fieldName instanceof AggregateFunctionNode)) {
      onNodeSelect(fieldName);
    }
  };

  const handleAggregateNodeSelect = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const nodeFieldName =
      fieldName instanceof AggregateFunctionNode ? fieldName.field : fieldName;
    if (onAggregateNodeSelect) {
      onAggregateNodeSelect(`${nodeFieldName}`, e.target.value);
    }
  };

  const renderAggregateNodeSelector = () => {
    return (
      <select
        id="select"
        onChange={handleAggregateNodeSelect}
        onClick={(e) => e.stopPropagation()}
        value={''}
        className="custom-select w-25"
      >
        {AGGREGATE_NODES.map((node) => {
          return (
            <option key={node} value={node}>
              {node}
            </option>
          );
        })}
      </select>
    );
  };

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    const dataTransfer = event.dataTransfer;

    if (!dataTransfer) {
      throw new Error('Datatransfer is null or undefined');
    }

    const nodeFieldName = getNodeName(fieldName);

    dataTransfer.setData('nodefieldname', nodeFieldName);
    dataTransfer.setData(nodeType, '');
  };

  const getNodeName = (node: string | AggregateFunctionNode) => {
    return node instanceof AggregateFunctionNode
      ? `${node.function}(${node.field})`
      : node;
  };

  return (
    <div
      draggable
      onDragStart={handleDragStart}
      className={`border-default d-flex justify-content-between py-1 pb-2 px-2 mb-1 header-cell-font ${
        selected ? 'border-valid' : ''
      }`}
      onClick={handleSelectField}
    >
      <div className="d-flex justify-content-center flex-column">
        {getNodeName(fieldName)}
      </div>
      {onAggregateNodeSelect && renderAggregateNodeSelector()}
    </div>
  );
};

export default NodeFieldName;
