import { Query, GroupBy } from 'rollun-ts-rql';
import _ from 'lodash';
import AbstractNode from 'rollun-ts-rql/dist/nodes/AbstractNode';
import AbstractQueryNode from 'rollun-ts-rql/dist/nodes/AbstractQueryNode';
import AbstractLogicalNode from 'rollun-ts-rql/dist/nodes/logicalNodes/AbstractLogicalNode';
import Select from 'rollun-ts-rql/dist/nodes/Select';
import And from 'rollun-ts-rql/dist/nodes/logicalNodes/And';
import Sort from 'rollun-ts-rql/dist/nodes/Sort';
import Limit from 'rollun-ts-rql/dist/nodes/Limit';
import { QueryNodeNames } from '../pages/Table/components/FilterEditor/DropToRemoveNodeField/DropToRemoveNodeField';

/**
 * Some util functions to manipulate query object
 * all of them return new Query object to prevent mutation
 */

const _addQueryNodeToQuery = (node: AbstractQueryNode, query: Query): Query => {
  const oldQuery = _.cloneDeep(query);
  let newQueryQueryNode: AbstractQueryNode;
  if (oldQuery.queryNode) {
    newQueryQueryNode = new And([oldQuery.queryNode, node]);
  } else {
    newQueryQueryNode = node;
  }
  return new Query({
    select: oldQuery.selectNode,
    sort: oldQuery.sortNode,
    limit: oldQuery.limitNode,
    query: newQueryQueryNode,
  });
};

const _addNotQueryNodeToQuery = (node: AbstractNode, query: Query): Query => {
  const currentQuery = _.cloneDeep(query);
  switch (true) {
    case node instanceof Select:
      return new Query({
        select: node as Select,
        sort: currentQuery.sortNode,
        limit: currentQuery.limitNode,
        query: currentQuery.queryNode,
        group: currentQuery.groupNode,
      });

    case node instanceof Sort:
      return new Query({
        select: currentQuery.selectNode,
        sort: node as Sort,
        limit: currentQuery.limitNode,
        query: currentQuery.queryNode,
        group: currentQuery.groupNode,
      });

    case node instanceof Limit:
      return new Query({
        select: currentQuery.selectNode,
        sort: currentQuery.sortNode,
        limit: node as Limit,
        query: currentQuery.queryNode,
        group: currentQuery.groupNode,
      });
    case node instanceof GroupBy:
      return new Query({
        select: currentQuery.selectNode,
        sort: currentQuery.sortNode,
        limit: currentQuery.limitNode,
        query: currentQuery.queryNode,
        group: node as GroupBy,
      });
    default:
      return currentQuery;
  }
};

export const appendQuery = (node: AbstractNode, query: Query): Query => {
  return node instanceof AbstractQueryNode
    ? _addQueryNodeToQuery(node, query)
    : _addNotQueryNodeToQuery(node, query);
};

export const setQueryNode = (
  node: AbstractQueryNode | undefined,
  query: Query,
): Query => {
  const oldQuery = _.cloneDeep(query);
  if (oldQuery && oldQuery?.limitNode && oldQuery?.limitNode?.offset) {
    oldQuery.limitNode.offset = 0;
  }
  return new Query({
    select: oldQuery.selectNode,
    sort: oldQuery.sortNode,
    limit: oldQuery.limitNode,
    group: oldQuery.groupNode,
    query: node,
  });
};

export const getNodeFromPath = (path: Array<number>, query: Query) => {
  if (path.length === 0) return null;
  let currentNode = query.queryNode;
  path.forEach((pathFragment: number, index: number) => {
    if (index === 0) {
      return;
    }
    if (currentNode instanceof AbstractLogicalNode) {
      currentNode = currentNode.subNodes[pathFragment];
    } else {
      throw new Error(`Invalid path: ${path.join(' ')}`);
    }
  });
  return currentNode;
};

export const removeNode = (queryNodeName: string, query: Query): Query => {
  if (queryNodeName === QueryNodeNames.select) {
    return new Query({
      sort: query.sortNode,
      limit: query.limitNode,
      query: query.queryNode,
    });
  } else if (queryNodeName === QueryNodeNames.sort) {
    return new Query({
      select: query.selectNode,
      limit: query.limitNode,
      query: query.queryNode,
    });
  } else if (queryNodeName === QueryNodeNames.limit) {
    return new Query({
      select: query.selectNode,
      sort: query.sortNode,
      query: query.queryNode,
    });
  } else if (queryNodeName === QueryNodeNames.query) {
    return new Query({
      select: query.selectNode,
      sort: query.sortNode,
      limit: query.limitNode,
    });
  }
  return _.cloneDeep(query);
};

export const removeNodeAtPath = (path: Array<number>, query: Query): Query => {
  if (path.length === 1 && path[0] === 0) {
    return removeNode(QueryNodeNames.query, query);
  } else {
    const parentNodePath = path.slice(0, path.length - 1);
    const parentNode = getNodeFromPath(parentNodePath, query);
    if (parentNode instanceof AbstractLogicalNode) {
      parentNode.removeNode(path[path.length - 1]);
    } else {
      throw new Error(`Node with path ${path.join(' ')} doesn't have children`);
    }
    return query;
  }
};

export const addNodeAtPath = (
  node: AbstractQueryNode,
  path: number[],
  query: Query,
): Query => {
  const parentNode = getNodeFromPath(path, query);
  if (parentNode instanceof AbstractLogicalNode) {
    parentNode.addNode(node);
    return _.cloneDeep(query);
  } else if (parentNode === null) {
    return new Query({
      query: node,
    });
  } else {
    throw new Error(`Node with path ${path.join(' ')} can't have children`);
  }
};

export const replaceNodeAtPath = (
  node: AbstractQueryNode,
  path: Array<number>,
  query: Query,
): Query => {
  if (!node) return removeNodeAtPath(path, query);
  if (!query.queryNode) return query;
  if (path.length === 1 && path[0] === 0) return setQueryNode(node, query);
  if (!(query.queryNode instanceof AbstractLogicalNode)) {
    throw new Error(`Node with path ${path.join(' ')} can't have children`);
  }
  const newQuery = _.cloneDeep(query);
  let parent: Array<AbstractQueryNode> = newQuery.queryNode.subNodes;
  path.forEach((idx, i) => {
    // skip first iteration, because we already have first parent
    if (i === 0) return;
    // skip the last iteration, because we need parent of element to replace.
    if (i === path.length - 1) return;
    parent = (parent[idx] as AbstractLogicalNode).subNodes;
  });
  parent[path[path.length - 1]] = node;
  return newQuery;
};
