import React from 'react';
import DefaultRenderer, { IRendererProps } from './DefaultRenderer';
import withControls from './withControls';

const ExternalLinkInControlRenderer = ({ value, name }: IRendererProps) => {
  return <DefaultRenderer name={name} value={value} />;
};

export default withControls(ExternalLinkInControlRenderer, {
  externalLink: true,
});
