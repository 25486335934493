import React, { FC } from 'react';
import { makeStaticCDNPath } from '../utils/common.utils';

interface RollunLogoProps {
  width?: number;
  height?: number;
}

const RollunLogo: FC<RollunLogoProps> = ({ width, height }) => {
  return (
    <img
      src={makeStaticCDNPath('/images/logo.png')}
      alt="Rollun logo"
      width={width}
      height={height}
    />
  );
};

export default RollunLogo;
