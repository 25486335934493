import React, { useState } from 'react';
import { Card, Checkbox, Dialog, HiddenInput } from '../../../../../UI';
import { FieldConfig } from '../../../../../index';
import _ from 'lodash/fp';
import './FieldsConfig.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getActualRenderer } from '../../../../Table/util/config.utils';
import Select from 'react-select';
import { EDITORS_NAMES, RENDERERS_NAMES } from '../../../constants';
import { makeStaticCDNPath } from '../../../../../utils/common.utils';

interface IProps {
  fieldsConfig?: Array<FieldConfig>;
  onUpdate: (newConfig: Array<FieldConfig> | any, path?: string) => void;
}

const FieldsConfigEdit: React.FC<IProps> = ({ fieldsConfig, onUpdate }) => {
  console.log(fieldsConfig);
  return (
    <Card noMargin className="my-2 position-relative">
      {(!fieldsConfig || fieldsConfig.length === 0) && (
        <h4>There are no fields to config...</h4>
      )}
      <div className="fields-configs-root">
        {fieldsConfig &&
          fieldsConfig.map((field, idx) => (
            <div className="fields-config-root p-2 " key={idx + field.name}>
              <div className="header-text position-relative">
                <HiddenInput
                  initValue={field.name}
                  onSubmit={(str) => onUpdate(str, `[${idx}].name`)}
                />
                <div
                  className="control-button-toggle-icon cursor-pointer"
                  style={{ fontSize: '0.8rem' }}
                  onClick={() =>
                    onUpdate((fieldsConfig || []).filter((_, i) => i !== idx))
                  }
                >
                  <FontAwesomeIcon
                    icon="minus"
                    size="1x"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="fields-configs-root">
        {fieldsConfig &&
          fieldsConfig.map((field, idx) => {
            return (
              <FieldConfigEdit
                key={idx + field.name}
                field={field}
                onUpdate={(newVal) => onUpdate(newVal, `[${idx}]`)}
              />
            );
          })}
      </div>
      <div
        className="control-button-toggle-icon cursor-pointer"
        style={{ fontSize: '0.8rem' }}
        onClick={() => onUpdate((fieldsConfig || []).concat({ name: 'name' }))}
      >
        <FontAwesomeIcon icon="plus" size="1x" className="text-success" />
      </div>
    </Card>
  );
};

const FieldConfigEdit: React.FC<{
  field: FieldConfig;
  onUpdate: (newVal: any) => void;
}> = ({ field, onUpdate }) => {
  const [isOpen, setOpen] = useState(false);
  const [fieldConfig, setFieldConfig] = useState(field);
  const Renderer = fieldConfig.rendererName
    ? getActualRenderer(fieldConfig.rendererName)
    : ({ value }: { value: any }) => <div>{value}</div>;
  const updateLocalFieldConfig = (newVal: any, path: string) => {
    setFieldConfig(
      newVal || newVal === false
        ? _.update(path)(() => newVal)(fieldConfig)
        : _.unset(path)(fieldConfig),
    );
  };
  return (
    <>
      <div className="fields-config-root p-2 position-relative">
        <Renderer
          name={field.name}
          value={getContentFromRendererType(fieldConfig.rendererName)}
        />
        <div
          className="control-button-toggle-icon cursor-pointer"
          style={{ zIndex: 123 }}
          onClick={() => setOpen(true)}
        >
          <FontAwesomeIcon icon="cog" size="1x" />
        </div>
      </div>
      <Dialog
        isOpen={isOpen}
        onOk={() => {
          setOpen(false);
          onUpdate(fieldConfig);
        }}
        title="Edit cell params"
        onClose={() => setOpen(false)}
      >
        <div>
          <div className="d-flex justify-content-between">
            <h4 className="my-1 mr-2">minWidth: </h4>
            <HiddenInput
              onSubmit={(str) => {
                str
                  ? !isNaN(+str) &&
                    +str > 0 &&
                    updateLocalFieldConfig(+str, `minWidth`)
                  : updateLocalFieldConfig(str, `.minWidth`);
              }}
              placeholder="Min width..."
              initValue={`${field.minWidth || ''}`}
            />
          </div>
          <div className="d-flex justify-content-between mt-2">
            <h4 className="my-1 mr-2">isEditable: </h4>
            <Checkbox
              className="mt-1"
              initValue={field.isEditable !== false}
              onChange={(value) => updateLocalFieldConfig(value, `isEditable`)}
            />
          </div>
          <div className="d-flex justify-content-between">
            <h4 className="my-1 mr-2">rendererName: </h4>
            <Select
              value={{
                value: fieldConfig.rendererName || 'empty',
                label: fieldConfig.rendererName || 'empty',
              }}
              className="col-md-6 mb-2 text-dark"
              options={RENDERERS_NAMES.map((name) => ({
                value: name,
                label: name,
              }))}
              onChange={(value: any) => {
                value.value === 'empty'
                  ? updateLocalFieldConfig(null, `rendererName`)
                  : updateLocalFieldConfig(value.value, `rendererName`);
              }}
            />
          </div>
          <div className="d-flex justify-content-between">
            <h4 className="my-1 mr-2">editorName: </h4>
            <Select
              value={{
                value: fieldConfig.editorName || 'empty',
                label: fieldConfig.editorName || 'empty',
              }}
              className="col-md-6 mb-2 text-dark"
              options={EDITORS_NAMES.map((name) => ({
                value: name,
                label: name,
              }))}
              onChange={(value: any) => {
                value.value === 'empty'
                  ? updateLocalFieldConfig(null, `editorName`)
                  : updateLocalFieldConfig(value.value, `editorName`);
              }}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

const getContentFromRendererType = (rendererName?: string): any => {
  switch (rendererName) {
    case 'datetime':
      return 1583233077;
    case 'stringDate':
      return '2020-01-02';
    case 'json':
      return { awesome: 'props', int: 123, bool: false, array: ['1', '2'] };
    case 'link':
      return 'https://rollun.net';
    case 'imageLink':
      // our logo
      return makeStaticCDNPath('images/new-year-logo.png');
    case 'status':
      return 'Status';
    case 'bool':
      return 1;
    default:
      return 'Awesome content';
  }
};

export default React.memo(
  FieldsConfigEdit,
  (prevProps: IProps, nextProps: IProps) =>
    _.isEqual(prevProps.fieldsConfig, nextProps.fieldsConfig),
);
