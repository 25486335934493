import React, { FC } from 'react';
import { useTheme, Box } from '@material-ui/core';
import WebHookActivatorList from '../webHookActivator/WebhookActivatorList';
import { WebHookConfig } from '../webHookActivator/WebhookActivator';
import { TableHeaderButton } from '../../../AbstractService';
import TableHeaderButtonsList from './TableHeaderButtonsList';

interface TableHeaderButtonContainerProps {
  hooks: WebHookConfig[];
  buttons: TableHeaderButton[];
  dataStoreUrl?: string;
  defaultQuery?: string | undefined;
  appConfig?: any;
}

// Component to render additional table buttons
const TableHeaderButtonContainer: FC<TableHeaderButtonContainerProps> = ({
  hooks,
  buttons,
  dataStoreUrl,
  defaultQuery,
  appConfig,
}) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ gap: theme.spacing(1), flexWrap: 'wrap' }}
    >
      {hooks.length > 0 && <WebHookActivatorList isSlim hooks={hooks} />}
      {buttons.length > 0 && (
        <TableHeaderButtonsList
          buttons={buttons}
          dataStoreUrl={dataStoreUrl}
          defaultQuery={defaultQuery}
          appConfig={appConfig}
        />
      )}
    </Box>
  );
};

export default TableHeaderButtonContainer;
