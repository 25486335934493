/* tslint:disable */
/* eslint-disable */
/**
 * CRMEventHandler
 * Manifest description
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Action
 */
export interface Action {
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    'type': ActionTypeEnum;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Action
     */
    'context': { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof Action
     */
    'delay'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ActionTypeEnum {
    Create = 'create',
    Update = 'update',
    ScenarioRequest = 'scenario_request',
    MegaplanRequest = 'megaplan_request',
    RemoteRequest = 'remote_request',
    Button = 'button'
}

/**
 * 
 * @export
 * @interface ActionEntity
 */
export interface ActionEntity {
    /**
     * 
     * @type {string}
     * @memberof ActionEntity
     */
    'rowId': string;
    /**
     * 
     * @type {string}
     * @memberof ActionEntity
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ActionEntity
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ActionWithEntity
 */
export interface ActionWithEntity {
    /**
     * 
     * @type {Action}
     * @memberof ActionWithEntity
     */
    'action': Action;
    /**
     * 
     * @type {ActionEntity}
     * @memberof ActionWithEntity
     */
    'entity': ActionEntity;
}
/**
 * 
 * @export
 * @interface ActionsResponse
 */
export interface ActionsResponse {
    /**
     * 
     * @type {Array<ActionWithEntity>}
     * @memberof ActionsResponse
     */
    'data': Array<ActionWithEntity>;
    /**
     * 
     * @type {Array<Message>}
     * @memberof ActionsResponse
     */
    'messages'?: Array<Message>;
}
/**
 * 
 * @export
 * @interface CompletedAction
 */
export interface CompletedAction {
    /**
     * mysql date-time
     * @type {string}
     * @memberof CompletedAction
     */
    'startedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompletedAction
     */
    'type': CompletedActionTypeEnum;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof CompletedAction
     */
    'context': { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof CompletedAction
     */
    'delay'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum CompletedActionTypeEnum {
    Create = 'create',
    Update = 'update',
    ScenarioRequest = 'scenario_request',
    MegaplanRequest = 'megaplan_request',
    RemoteRequest = 'remote_request',
    Button = 'button'
}

/**
 * 
 * @export
 * @interface Entity
 */
export interface Entity {
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    'name': string;
}
/**
 * Message field is not required
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {Array<Message>}
     * @memberof ErrorResponse
     */
    'messages'?: Array<Message>;
}
/**
 * 
 * @export
 * @interface EventEntity
 */
export interface EventEntity {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof EventEntity
     */
    'row': { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof EventEntity
     */
    'changedFields'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof EventEntity
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof EventEntity
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface EventPostRequest
 */
export interface EventPostRequest {
    /**
     * 
     * @type {string}
     * @memberof EventPostRequest
     */
    'lifecycleToken': string;
    /**
     * 
     * @type {EventEntity}
     * @memberof EventPostRequest
     */
    'entity': EventEntity;
    /**
     * 
     * @type {CompletedAction}
     * @memberof EventPostRequest
     */
    'action': CompletedAction;
}
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'level'?: MessageLevelEnum;
    /**
     * Message, that describes what went wrong
     * @type {string}
     * @memberof Message
     */
    'text'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MessageLevelEnum {
    Emergency = 'emergency',
    Alert = 'alert',
    Critical = 'critical',
    Error = 'error',
    Warning = 'warning',
    Notice = 'notice',
    Info = 'info'
}

/**
 * 
 * @export
 * @interface SuccessResponse
 */
export interface SuccessResponse {
    /**
     * 
     * @type {object}
     * @memberof SuccessResponse
     */
    'data': object;
    /**
     * 
     * @type {Array<Message>}
     * @memberof SuccessResponse
     */
    'messages'?: Array<Message>;
}

/**
 * EventApi - axios parameter creator
 * @export
 */
export const EventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Handle event
         * @param {EventPostRequest} eventPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventHandlerPost: async (eventPostRequest: EventPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventPostRequest' is not null or undefined
            assertParamExists('eventHandlerPost', 'eventPostRequest', eventPostRequest)
            const localVarPath = `/eventHandler`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventApi - functional programming interface
 * @export
 */
export const EventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Handle event
         * @param {EventPostRequest} eventPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventHandlerPost(eventPostRequest: EventPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventHandlerPost(eventPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventApi - factory interface
 * @export
 */
export const EventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventApiFp(configuration)
    return {
        /**
         * 
         * @summary Handle event
         * @param {EventPostRequest} eventPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventHandlerPost(eventPostRequest: EventPostRequest, options?: any): AxiosPromise<ActionsResponse> {
            return localVarFp.eventHandlerPost(eventPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventApi - object-oriented interface
 * @export
 * @class EventApi
 * @extends {BaseAPI}
 */
export class EventApi extends BaseAPI {
    /**
     * 
     * @summary Handle event
     * @param {EventPostRequest} eventPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public eventHandlerPost(eventPostRequest: EventPostRequest, options?: AxiosRequestConfig) {
        return EventApiFp(this.configuration).eventHandlerPost(eventPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


