import React, { useState } from 'react';
import { Dialog } from '../../../../../../UI';
import { IRendererProps } from './DefaultRenderer';

let longPressTimeout: number | null = null;

const ImageLinkRenderer: React.FC<IRendererProps> = ({ value }) => {
  const [isOpen, setOpen] = useState(false);
  if (!value) {
    return <span>Empty</span>;
  }
  if (isOpen) {
    return (
      <Dialog
        isOpen={true}
        options={{ centered: true }}
        onClose={() => setOpen(false)}
        title="Image"
      >
        <div className="d-flex justify-content-center">
          <img src={value} alt="" style={{ maxHeight: 800, maxWidth: 600 }} />
        </div>
      </Dialog>
    );
  }

  return (
    <a
      href={value}
      onMouseDown={() =>
        (longPressTimeout = window.setTimeout(() => setOpen(true), 200))
      }
      onMouseUp={() => longPressTimeout && clearTimeout(longPressTimeout)}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div
        style={{ maxWidth: 300, maxHeight: 150 }}
        className="d-flex justify-content-center"
      >
        <img src={value} alt="" style={{ maxWidth: 300, maxHeight: 150 }} />
      </div>
    </a>
  );
};

export default ImageLinkRenderer;
