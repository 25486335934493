import { useQuery } from 'react-query';
import { secretsApi } from '../api-client';

export const GET_SECRET_BY_ID = 'GET_SECRET_BY_ID';

export const useGetSecretById = (id: string, version?: string) => {
  return useQuery([GET_SECRET_BY_ID, id, version], async () => {
    const { data } = await secretsApi.secretsControllerGetSecretById(
      id,
      version,
    );
    return data;
  });
};
