import React, { FC } from 'react';
import { Box, TableCell, TableRow } from '@material-ui/core';
import { Metric } from '../types';

interface IProps {
  value: Metric | null | undefined;
}

const MetricInfo: FC<IProps> = ({ value }) => {
  return !value ? (
    <TableRow>
      <TableCell>null</TableCell>
      <TableCell>null</TableCell>
      <TableCell>null</TableCell>
    </TableRow>
  ) : (
    <TableRow>
      <TableCell>
        <Box fontSize="15px">{value.value}</Box>
      </TableCell>
      <TableCell>
        <Box fontSize="15px">
          {new Date(value.timestamp * 1000)
            .toISOString()
            .slice(0, 19)
            .replace('T', ' ')}
        </Box>
      </TableCell>
      <TableCell>
        <Box fontSize="15px">{value.info}</Box>
      </TableCell>
    </TableRow>
  );
};

export default MetricInfo;
