import _ from 'lodash';
import { RollunCatalogRow } from '../../BarcodeApp/utils/types';
import { In, Query } from 'rollun-ts-rql';
import axios from 'axios';
import HttpDatastore from 'rollun-ts-datastore';
import { ExchangeFileFormattor, getDescription } from './index';

interface CalculatedEbayItemPrice {
  all_cost: number;
  calculated_price: number;
  ebay_fee_fix: number;
  ebay_fee_percent: number;
  margin: number;
  markup: number;
  posting_selling_price: number;
  profit: number;
  rent_fix: number;
  rent_percent: number;
  rollun_id: string;
  shipping_cost: number;
  supplier: string;
  supplier_price: number;
  supplier_quantity: number;
  type_posting_selling_price: string;
}

const catalog = new HttpDatastore<RollunCatalogRow>(
  '/api/datastore/CatalogDataStore',
);

const fetchCatalogBrandAndMpn = async (
  ids: Array<string>,
): Promise<Array<RollunCatalogRow>> => {
  return catalog.query(new Query({ query: new In('id', ids) }));
};

const fetchPrices = async (
  ids: Array<string>,
): Promise<Array<CalculatedEbayItemPrice>> => {
  const { data: prices } = await axios.post(
    '/api/webhook/EbayRidPriceCalculator',
    {
      rids: ids,
    },
  );
  return prices;
};

export const getFileExchangeByEpidAndRID: ExchangeFileFormattor = async (
  data,
  config,
  progress,
) => {
  try {
    const ridsChunks = _.chunk(
      data.map(({ rollunId }) => rollunId),
      100,
    );
    let prices: Array<CalculatedEbayItemPrice> = [];
    let brandAndMpns: Array<RollunCatalogRow> = [];
    for (let i = 0, len = ridsChunks.length; i < len; i++) {
      const chunk = ridsChunks[i];
      progress &&
        progress(`Fetching ${i * 100 + chunk.length} of ${data.length} items`);
      const [currentPrices, currentBrandAndMpns] = await Promise.all([
        fetchPrices(chunk),
        fetchCatalogBrandAndMpn(chunk),
      ]);
      prices = prices.concat(currentPrices);
      brandAndMpns = brandAndMpns.concat(currentBrandAndMpns);
    }
    progress && progress('All data fetched, formatting File exchange');
    const fileExchange = data.map((item) => {
      const calculatedRollunId = prices.find(
        (el) => el.rollun_id === item.rollunId,
      );
      const brandAndMpn = brandAndMpns.find((el) => el.id === item.rollunId);

      const { posting_selling_price = '-', supplier_quantity = '-' } =
        calculatedRollunId || {};

      return {
        '*Action(SiteID=eBayMotors|Country=US|Currency=USD|Version=745|CC=UTF-8)':
          'AddFixedPriceItem',
        InventoryTrackingMethod: 'SKU',
        CustomLabel: item.rollunId,
        '*ConditionID': '1000',
        '*Description': `<div>${getDescription({
          brand: brandAndMpn ? brandAndMpn.brand_id : '-',
          mpn: brandAndMpn ? brandAndMpn.manufacturer_part_number : '-',
        }).replace(/\n/g, '</br>')}</div>`,
        '*Format': 'FixedPrice',
        '*Duration': 'GTC',
        '*StartPrice': posting_selling_price,
        '*Quantity': supplier_quantity,
        '*Location': 'Springville,UT',
        ShippingType: 'Flat',
        'ShippingService-1:Option': 'ShippingMethodStandard',
        'ShippingService-1:Cost': '0',
        'ShippingService-1:AdditionalCost': '0',
        ShippingProfileName: 'Flat:Standard Shipp(Free),1 business day',
        ReturnProfileName: 'Returns Accepted,Seller,30 Days,Money Back',
        PaymentProfileName: 'eBay Payments:eBay Payments',
        '*DispatchTimeMax': '1',
        '*ReturnsAcceptedOption': 'ReturnsAccepted',
        ReturnsWithinOption: 'Days_30',
        ShippingCostPaidByOption: 'Seller',
        'Product:IncludePrefilledItemInformation': '1',
        'Product:IncludeStockPhotoURL': '1',
        'Product:EPID': item.ePID || '',
      };
    });
    progress && progress('File exchange is ready.');
    return { data: fileExchange, error: null };
  } catch (err) {
    console.log(err);
    return { data: [], error: { text: (err as Error).message, code: 0 } };
  }
};
