import React from 'react';
import Card from './Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

interface IProps {
  initValue?: string;
  value?: string;
  placeholder?: string;
  type?: string;
  // TODO add more input types types
  onSubmit?: (str: string) => void;
  inputClassName?: string;
  containerClassName?: string;
  as?: string;
  preventBlurOnEnter?: boolean;
}

interface IState {
  input: string;
  isFocus: boolean;
}

/**
 * This input appears as simple text node(h5) if unfocused,
 * and as input if focused.
 */

class HiddenInput extends React.Component<IProps, IState> {
  inputRef = React.createRef<HTMLInputElement>();

  constructor(props: IProps) {
    super(props);
    this.state = {
      input: props.initValue || props.value || '',
      isFocus: false,
    };
  }

  onInputBlur = () => {
    if (!this.inputRef.current) return;
    this.inputRef.current.blur();
    this.setState({ isFocus: false });
    if (this.props.onSubmit) {
      this.props.onSubmit(this.state.input);
    }
  };

  onFocus = () => {
    this.setState({ isFocus: true });
  };

  // focus after render, because render will blur input element
  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
    if (!prevState.isFocus && this.inputRef.current) {
      this.inputRef.current.focus();
      const { placeholder = '' } = this.props;
      const { input } = this.state;
      this.inputRef.current.setSelectionRange(
        0,
        input.length || placeholder.length,
      );
    }
  }

  onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ input: e.target.value });
  };

  onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !this.props.preventBlurOnEnter &&
      (e.key == 'Enter' || e.key === 'Escape')
    ) {
      this.onInputBlur();
    }
  };

  render() {
    const { input, isFocus } = this.state;
    const {
      type = 'text',
      placeholder = 'Type something...',
      inputClassName = '',
      containerClassName = '',
      as,
    } = this.props;

    const Input: any = as ? as : 'input';

    // input need to be hidden by css, because inputRef won't be used as ref for input,
    // until it is rendered.
    return (
      <>
        <Input
          type={type || 'text'}
          onKeyDown={this.onKeyDown}
          ref={this.inputRef}
          style={{ display: isFocus ? 'block' : 'none' }}
          placeholder={placeholder}
          className={'form-control ' + inputClassName}
          onBlur={this.onInputBlur}
          onChange={this.onInput}
          value={input}
        />
        <Card
          onClick={this.onFocus}
          className={'cursor-pointer ' + containerClassName}
          noMargin
          style={{ display: isFocus ? 'none' : 'block' }}
        >
          <pre
            className="d-flex justify-content-between mb-0"
            style={{ fontSize: '1.2rem' }}
          >
            {_.truncate(input, { length: 20 }) || (
              <span style={{ opacity: 0.5 }}>{placeholder}</span>
            )}
            <FontAwesomeIcon icon="edit" className="ml-2" />
          </pre>
        </Card>
      </>
    );
  }
}

export default HiddenInput;
