import React from 'react';
import { ParcelItemType } from '../utils/types';
import { Button } from '../../../UI';

interface IProps {
  item: ParcelItemType;

  onItemFinish(): void;
}

const ParcelItemView: React.FC<IProps> = ({ item, onItemFinish }) => {
  const {
    fnsku,
    image_link,
    parcel_id,
    part_number,
    quantity,
    description,
  } = item;
  let parsedQuantity;
  let debugParsingError = '';
  try {
    parsedQuantity = JSON.parse(quantity);
  } catch (e) {
    debugParsingError = (e as Error).toString();
    parsedQuantity = {};
  }

  return (
    <div className="d-flex justify-content-center align-items-center flex-column mt-2">
      <div className="shadow p-3">
        <div className="d-flex justify-content-around">
          <div
            className="mt-2 d-flex justify-content-center flex-column"
            style={{ fontSize: '1rem' }}
          >
            <h2>Item</h2>
            <p>FNSKU: {fnsku}</p>
            <p>Parcel ID: {parcel_id}</p>
            <p>Part number: {part_number}</p>
          </div>
          <img
            src={image_link}
            className="ml-2"
            alt="item"
            style={{ width: '60vw', maxWidth: 400 }}
          />
        </div>
        {description ? (
          <div style={{ fontSize: '1.5rem' }}>Description: {description}</div>
        ) : null}
        <div>
          {Object.entries(parsedQuantity).map(([key, value]) => (
            <div
              style={{ fontSize: '1rem' }}
              className="p-3 mt-2 border-default d-block"
              key={key}
            >
              {key}: {value}
            </div>
          ))}
          {debugParsingError && <p>[DEBUG] {debugParsingError}</p>}
        </div>
        <div className="d-flex justify-content-end">
          <Button color="primary" onClick={onItemFinish} className="mt-1">
            Finish
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ParcelItemView;
