import useHttpDataStore from '../useHttpDatastore';

export interface ReturnBagItem {
  id: number;
  rid: string;
  found: 'yes' | 'no';
  bag: string;
  problem: string | null;
  inventory: string;
  item_id: number | null;
}

const useReturnBagItems = () => {
  return useHttpDataStore<ReturnBagItem>('/api/datastore/ReturnBagItems');
};

export { useReturnBagItems };
