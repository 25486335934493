import { FC, useState } from 'react';
import React from 'react';
import axios from 'axios';
import { downloadAsCSV } from 'rollun-ts-utils/dist';
import MuiButton from '../../../../../UI/MuiButton';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  makeStyles,
  Theme,
} from '@material-ui/core';
import _ from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
  dataTextField: {
    marginBottom: theme.spacing(2),
  },
}));

/**
 * Component that returns button and dialog modal for two specific tables:
 * /EbayPlaisirCreateItemTask and /EbayRollunCreateItemTask
 * and gives possibility to create multiple items for one of these tables
 * using data copied from external table
 */

const GetRidsByBrandAndMpn: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState('');
  const [status, setStatus] = useState('');
  const classes = useStyles();

  const parseData = (data: string) => {
    const rows = data.split('\n');

    return rows.map((row: string) => {
      const separator = row.includes('\t') ? '\t' : ' ';
      const [brand, mpn] = row.split(separator);

      return { brand, mpn };
    });
  };

  const getRids = async (data: string) => {
    const brandsAndMPNs = parseData(data);
    const chunkSize = 50;
    const brandsAndMPNsChunks = _.chunk(brandsAndMPNs, chunkSize);
    const result = [];

    setStatus('Receiving rids. Wait until file will be downloaded');

    for (const branndsAndMpnsChunk of brandsAndMPNsChunks) {
      const { data } = await axios.post('/api/webhook/GetRidsByBrandIDAndMPN', {
        products: branndsAndMpnsChunk,
      });

      result.push(...data.data);
      setStatus(`Got ${result.length} rids of ${brandsAndMPNs.length}`);
    }

    const brandsMPNsRids = result.map((product) => ({
      rid: product.rid,
      brand: product.brand,
      mpn: product.mpn,
    }));

    downloadAsCSV(brandsMPNsRids, 'rid brand mpn.csv');
    setStatus('done');
  };

  return (
    <div>
      <MuiButton
        color="primary"
        onClick={() => setIsOpen(true)}
        title="Upload copied table rows"
      >
        Get RIDs by brandIDs + MPNs
      </MuiButton>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <DialogTitle>
          {'Paste brandIds and MPNs to get RIDs. See data example below :)'}
        </DialogTitle>
        <DialogContent>
          BrandIds and MPNs rows can be splited by both, tabs or spaces. And
          each pair should be on a new line
        </DialogContent>
        <DialogContent>
          {status || (
            <TextField
              className={classes.dataTextField}
              type="text"
              label="DURABLUE 201154"
              value={data}
              onChange={(e) => setData(e.target.value)}
              fullWidth
              variant="outlined"
              multiline
              minRows={10}
            />
          )}

          <MuiButton
            id="confirm-filter"
            fullWidth
            color="success"
            loading={!!status && status !== 'done'}
            onClick={() => getRids(data)}
          >
            Get RIDS
          </MuiButton>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default GetRidsByBrandAndMpn;
