import React, { FC, useEffect, useRef, useState } from 'react';
import { Button, Spinner } from '../../../../UI';
import ConfigForm, { Config } from './ConfigForm';
import axios, { AxiosError, AxiosInstance } from 'axios';
import { downloadAsCSV, downloadAsJSON, wait } from 'rollun-ts-utils/dist';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import TableWithFilter from '../Table/TableWithFilter';
import HttpDatastore, { LocalDatastore } from 'rollun-ts-datastore/dist';
import { Query } from 'rollun-ts-rql';
import _ from 'lodash';
import MuiIconButton from '../../../../UI/MuiIconButton';
import { Box, Card, Theme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  nodeRedInteropContainer: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    padding: theme.spacing(1),
  },
  nodeRedInteropHeader: {
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  nodeRedInteropBody: {
    padding: theme.spacing(1),
  },
  nodeRedInteropInfo: {
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontSize: '1rem',
  },
}));

interface IProps {
  data: Array<any>;
  datastoreURL: string;
  scrollIntoViewOnMount: boolean;
  query: Query;
  onChangeQuery(query: Query): void;
}

const DEFAULT_CHUNK_SIZE = 3000;

const NodeRedInterop: FC<IProps> = ({
  query,
  data,
  onChangeQuery,
  datastoreURL,
  scrollIntoViewOnMount,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [progress, setProgress] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [result, setResult] = useState<any | null>(null);
  const [failedResult, setFailedResult] = useState<any | null>(null);
  const rootRef = useRef<HTMLDivElement>(null);
  const [isInfoOpened, toggleInfoBlock] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (scrollIntoViewOnMount && rootRef && rootRef.current) {
      rootRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [rootRef]);

  const sendRowByRow = async (
    nodeRedClient: AxiosInstance,
    data: Array<any>,
    { nodeRedPipelineInputURL, delay }: Config,
  ) => {
    let success = 0;
    let failed = 0;
    for (const row of data) {
      try {
        const { data: response } = await nodeRedClient.post(
          nodeRedPipelineInputURL,
          row,
        );
        setResult((result: any) => (result || []).concat(response));
        setProgress(
          `success - ${++success} failed - ${failed} of ${data.length}`,
        );
        await wait(+delay);
      } catch (err) {
        console.log('error', err);
        setProgress(
          `success - ${success} failed - ${++failed} of ${data.length}`,
        );
        setFailedResult((result: any) =>
          (result || []).concat({
            message: (err as Error).message,
            response: (err as AxiosError).response,
          }),
        );
      }
    }
  };

  const sendDataToNodeRED = async (
    nodeRedClient: AxiosInstance,
    data: Array<any>,
    config: Config,
  ) => {
    if (config.requestType === 'single') {
      await sendRowByRow(nodeRedClient, data, config);
    }
    if (config.requestType === 'chunk') {
      await sendRowByRow(
        nodeRedClient,
        _.chunk(data, +config.chunkSize),
        config,
      );
    }
    if (config.requestType === 'all') {
      await sendAllAtOnce(nodeRedClient, data, config);
    }
  };

  async function fetchAll(query?: Query): Promise<Array<any>> {
    let res: any[] = [];
    for await (const chunk of fetchDataInChunks(DEFAULT_CHUNK_SIZE, query)) {
      res = res.concat(chunk);
    }
    return res;
  }

  async function* fetchDataInChunks(
    chunkSize: number,
    _query?: Query,
  ): AsyncGenerator<any[]> {
    console.log('fetchDataInChunks');
    const query = _query ? _.cloneDeep(_query) : new Query();
    query.limitNode = undefined;
    const datastore = new HttpDatastore(datastoreURL);
    let chunkNum = 0;
    setProgress('Fetching 0 rows from datastore');
    while (true) {
      console.log('fetchDataInChunks.chunkNum', chunkNum);
      const result = await datastore.query(query.getNextLimit(chunkSize));
      setProgress(`Fetched ${chunkSize * ++chunkNum} rows from datastore`);
      yield result;
      if (result.length !== chunkSize) {
        break;
      }
    }
  }

  const sendAllAtOnce = async (
    nodeRedClient: AxiosInstance,
    data: Array<any>,
    { nodeRedPipelineInputURL }: Config,
  ) => {
    setProgress('Sending all data at once...');
    try {
      const { data: response } = await nodeRedClient.post(
        nodeRedPipelineInputURL,
        data,
      );
      setResult(Array.isArray(response) ? response : [response]);
    } catch (err) {
      console.log(err);
      setFailedResult({
        message: (err as Error).message,
        response: (err as AxiosError).response,
      });
    }
  };

  async function* fetchData(
    { sourceDataType, chunkSize, requestType }: Config,
    query: Query,
  ): AsyncGenerator<Array<any>> {
    if (sourceDataType === 'current_filter') {
      yield data;
      return;
    }
    if (sourceDataType === 'current_filter_no_limit') {
      if (requestType === 'all') {
        yield await fetchAll(query);
        return;
      }
      yield* fetchDataInChunks(+chunkSize || DEFAULT_CHUNK_SIZE, query);
      return;
    }
    if (sourceDataType === 'full_table') {
      if (requestType === 'all') {
        yield await fetchAll(query);
        return;
      }
      yield* fetchDataInChunks(+chunkSize || DEFAULT_CHUNK_SIZE);
      return;
    }
  }

  const handleStart = async (config: Config) => {
    setLoading(true);
    setProgress('');
    setError(null);
    setResult(null);
    setFailedResult(null);
    try {
      const nodeRedClient = axios.create({
        baseURL: config.nodeRedInstanceURL,
        timeout: +config.timeout,
      });
      console.log('start', config);
      let i = 0;
      for await (const chunk of fetchData(config, query)) {
        console.log(`fetched chunk #${++i} of length ${chunk.length}`, chunk);
        await sendDataToNodeRED(nodeRedClient, chunk, config);
      }
    } catch (err) {
      console.log(err);
      setError('Caught unknown error: ' + (err as Error).message);
    } finally {
      setLoading(false);
      setProgress('');
    }
  };

  return (
    <Card className={classes.nodeRedInteropContainer}>
      <div ref={rootRef}>
        <Card className={classes.nodeRedInteropHeader}>
          <h4>
            Simple Node RED interop
            <MuiIconButton
              title={'node-red interop info'}
              color="info"
              width={40}
              height={40}
              iconName="info-circle"
              onClick={() => toggleInfoBlock(!isInfoOpened)}
            />
          </h4>
          {isInfoOpened && (
            <Box className={classes.nodeRedInteropInfo}>
              - it sends data from table one by one, or in chunks to selected
              Node RED instance <br /> - response from Node RED will be expected
              in no more than
              <b> Timeout</b> milliseconds <br /> - if response from Node RED
              won't be received in
              <b> Timeout</b> milliseconds, assumed error.
            </Box>
          )}
        </Card>
        <Card className={classes.nodeRedInteropBody}>
          <ConfigForm
            disabled={isLoading}
            onChangeQuery={onChangeQuery}
            datastoreUrl={datastoreURL}
            handleSubmit={handleStart}
          />
          {error && <h3 className="text-danger">Error: {error}</h3>}
          {isLoading && (
            <div className="d-flex">
              <h4 className="my-2">Progress: {progress}</h4>
              <div className="ml-2">
                <Spinner size={30} />
              </div>
            </div>
          )}
          {!isLoading && (result || failedResult) && (
            <div>
              <h4>
                Results: {(result || []).length} succeed and{' '}
                {(failedResult || []).length} failed
              </h4>
              <Tabs id="result-visualise" defaultActiveKey="table">
                <Tab eventKey="table" title="View as table">
                  <div className="p-1 border-default">
                    <h3>Results table</h3>
                    {Array.isArray(result) &&
                    result.findIndex((el) => !el) === -1 ? (
                      <TableWithFilter
                        appName="Results as table"
                        isLocal
                        datastoreUrl=""
                        initialLocalDataStore={
                          new LocalDatastore({
                            initialData: result,
                            idField: result[0]
                              ? Object.keys(result[0])[0]
                              : 'id',
                          })
                        }
                      />
                    ) : (
                      <h4 className="text-danger">
                        Cannot display data as table, because it must be an
                        array of objects.
                      </h4>
                    )}
                  </div>
                </Tab>
                <Tab eventKey="raw" title="View raw results">
                  <div className="d-flex">
                    <div
                      style={{
                        fontSize: '1.2rem',
                        width: 150,
                      }}
                      className="mt-1"
                    >
                      Download succeed
                    </div>
                    <Button
                      size="sm"
                      color="primary"
                      className="ml-2"
                      onClick={() =>
                        downloadAsJSON(result || [], 'success.json')
                      }
                    >
                      {' '}
                      As JSON
                    </Button>
                    <Button
                      size="sm"
                      color="primary"
                      className="ml-2"
                      onClick={() => downloadAsCSV(result || [], 'success.csv')}
                    >
                      {' '}
                      As CSV
                    </Button>
                  </div>
                  <div className="d-flex">
                    <div
                      style={{
                        fontSize: '1.2rem',
                        width: 150,
                      }}
                      className="mt-1"
                    >
                      Download failed
                    </div>
                    <Button
                      size="sm"
                      color="primary"
                      className="ml-2"
                      onClick={() =>
                        downloadAsJSON(failedResult || [], 'failed.json')
                      }
                    >
                      {' '}
                      As JSON
                    </Button>
                    <Button
                      size="sm"
                      color="primary"
                      className="ml-2"
                      onClick={() =>
                        downloadAsCSV(failedResult || [], 'failed.csv')
                      }
                    >
                      {' '}
                      As CSV
                    </Button>
                  </div>
                  <pre
                    style={{
                      maxHeight: 800,
                      overflow: 'scroll',
                    }}
                  >
                    {result && JSON.stringify(result.slice(0, 1000), null, 2)}
                    {result && result.length > 1000 && (
                      <h4>
                        Showing 0-1000 items, to see all, download as file
                      </h4>
                    )}
                  </pre>
                </Tab>
              </Tabs>
            </div>
          )}
        </Card>
      </div>
    </Card>
  );
};

export default NodeRedInterop;
