import React, { FC } from 'react';
import MuiButton, { MuiButtonProps } from './MuiButton';

interface UploadFileButtonProps extends MuiButtonProps {
  handleFileUpload: (event: any) => void;
  accept: string;
  className?: string;
}

export const UploadFileButton: FC<UploadFileButtonProps> = (props) => {
  const { handleFileUpload, accept, children, className = '' } = props;

  return (
    <MuiButton
      className={className}
      variant="contained"
      component="label"
      {...props}
    >
      {children}
      <input type="file" hidden accept={accept} onChange={handleFileUpload} />
    </MuiButton>
  );
};
