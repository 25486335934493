import { useQuery } from 'react-query';
import { Contains, In, Query } from 'rollun-ts-rql';
import HttpDatastore from 'rollun-ts-datastore';
import ApiClientsPickups from '../../../../api-clients/api-clients-pickups';
import { Pickup } from '../../../../api-clients/types';
import { useContext } from 'react';
import { DealContext } from '../../../../GenericDeal/context/dealContext';

export const GET_LINKED_DEALS_KEY = 'GET_LINKED_DEALS_KEY';

const supplierMappingDatastore = new HttpDatastore(
  '/api/datastore/SupplierMappingDataStore',
);

export const useGetLinkedDeals = (
  setRidsToCsnMapping: (mapping: { rid: string; csns: string[] }[]) => void,
) => {
  const dealContext = useContext(DealContext);

  const fetchRollunIdsByPartNumber = async (rids: string[]) => {
    const query = new Query().setQuery(new In('rollun_id', rids));

    return await supplierMappingDatastore.query(query);
  };

  return useQuery<Pickup[]>(
    GET_LINKED_DEALS_KEY,
    async () => {
      return new ApiClientsPickups().getByQuery(
        new Query().setQuery(new Contains('BagLink', dealContext!.deal.id)),
      );
    },
    {
      onSuccess: async (data) => {
        const rids = data
          .map((deal) =>
            (deal.no_special_items_positions || []).map(
              (product) => product.article,
            ),
          )
          .flat() as string[];
        if (rids.length === 0) {
          return;
        }

        const mapping = await fetchRollunIdsByPartNumber(rids);

        setRidsToCsnMapping(
          mapping.map((item) => {
            const ridMapping = mapping.filter(
              (el) => el.rollun_id === item.rollun_id,
            );

            return {
              rid: item.rollun_id,
              csns: ridMapping.map((match) => match.supplier_id),
            };
          }),
        );
      },
    },
  );
};
