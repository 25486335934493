import { useState } from 'react';
import { Dimension } from '../components/SetDimensions';

type UseBoxDimension = () => [
  Dimension,
  (name: keyof Dimension, value: string) => void,
];

const useBoxDimension: UseBoxDimension = () => {
  const [dimension, setDimension] = useState<Dimension>({
    weight: '',
    height: '',
    length: '',
    width: '',
  });

  const handleChange = (name: keyof Dimension, value: string) => {
    setDimension({
      ...dimension,
      [name]: value.replace(/^0+/, ''),
    });
  };

  return [dimension, handleChange];
};

export { useBoxDimension };
