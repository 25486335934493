import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, ErrorView, Card } from '../../../../UI';
import { httpErrorHandler } from '../../../../utils/common.utils';
import { ErrorType } from '../../../../utils/common.types';
import axios from 'axios';
import { downloadAsCSV, downloadAsJSON } from 'rollun-ts-utils';
import { Box } from '@material-ui/core';
import MuiButton from '../../../../UI/MuiButton';

export interface WebHookConfig {
  name: string;
  url: string;
  description?: string;
  dontWaitUntilResolve?: boolean;
  isSlim?: boolean;
  downloadResultAsCSV?: boolean; // default as JSON
}

const WebHookActivator: React.FC<WebHookConfig> = ({
  isSlim = false,
  url,
  name,
  description,
  downloadResultAsCSV = false,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorType | null>(null);
  const [result, setResult] = useState<any | null>(null);

  const activateWebhook = async () => {
    setError(null);
    setLoading(true);
    try {
      const { data, status } = await axios.get(url, { timeout: 120000 });
      if (data && data.error) {
        return setError({ code: data.status || status, text: data.error });
      }
      setResult(data);
    } catch (err) {
      httpErrorHandler(err, (code, text) => setError({ code, text }));
    } finally {
      setLoading(false);
    }
  };

  if (isSlim) {
    return (
      <Box display="flex">
        <MuiButton
          disabled={isLoading}
          color="primary"
          title={`${url}\n${description || ''}`}
          onClick={activateWebhook}
        >
          {name}
        </MuiButton>
        {error && (
          <Dropdown
            color="danger"
            toggleButtonClass="m-0 ml-2"
            innerWidth={300}
            renderToggle={({ onClick }) => (
              <span
                onClick={onClick}
                className="mx-1 cursor-pointer text-danger"
              >
                <FontAwesomeIcon icon={'exclamation-circle'} size="2x" />
              </span>
            )}
          >
            <ErrorView error={error} />
          </Dropdown>
        )}
        {result && (
          <span
            className="mx-2 text-success cursor-pointer"
            title={JSON.stringify(result, null, 2)}
          >
            <FontAwesomeIcon icon="check" size="2x" />
          </span>
        )}
        {!isLoading && result && (
          <span
            className="mx-2 text-info cursor-pointer"
            title={`Download result as ${downloadResultAsCSV ? 'CSV' : 'JSON'}`}
            onClick={() => {
              downloadResultAsCSV
                ? downloadAsCSV(
                    Array.isArray(result) ? result : [result],
                    `${name}_result.csv`,
                  )
                : downloadAsJSON(result, `${name}_result.csv`);
            }}
          >
            <FontAwesomeIcon icon="download" size="2x" />
          </span>
        )}
        {isLoading && (
          <span className="mx-1">
            <FontAwesomeIcon icon={'cog'} spin size={'2x'} />
          </span>
        )}
      </Box>
    );
  }

  return (
    <Card className="p-3" style={{ width: 300 }}>
      <div>
        <h5 className="mb-3">{name}</h5>
        {description && <p>{description}</p>}
        <div className="d-flex flex-row">
          <MuiButton
            disabled={isLoading}
            color="primary"
            title={url}
            onClick={activateWebhook}
          >
            Use
          </MuiButton>
          {isLoading && (
            <span className="mx-2">
              <FontAwesomeIcon icon="cog" spin size="3x" />
            </span>
          )}
          {result && (
            <span
              className="mx-2 text-success cursor-pointer"
              title={JSON.stringify(result, null, 2)}
            >
              <FontAwesomeIcon icon="check" size="3x" />
            </span>
          )}
          {error && (
            <Dropdown
              color="danger"
              toggleButtonClass="m-0 ml-2"
              innerWidth={300}
              renderToggle={({ onClick }) => (
                <MuiButton onClick={onClick} color="error" className="mx-2">
                  <FontAwesomeIcon icon={'exclamation-circle'} />
                </MuiButton>
              )}
            >
              <ErrorView error={error} />
            </Dropdown>
          )}
        </div>
      </div>
    </Card>
  );
};

export default WebHookActivator;
