import React, { ReactNode, FC, useRef, useEffect } from 'react';

interface IProps {
  onClickOutside?: (e: Event) => void;
  onClick?: () => void;
  children: ReactNode | Array<ReactNode>;
}

/**
 * Wrap component, detects click outside of itself
 */

const ClickOutside: FC<IProps> = (props) => {
  const rootElementRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: Event) => {
    if (
      rootElementRef.current &&
      !rootElementRef.current.contains(e.target as Node)
    ) {
      props.onClickOutside && props.onClickOutside(e);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <div ref={rootElementRef} onClick={() => props.onClick && props.onClick()}>
      {props.children}
    </div>
  );
};

export default ClickOutside;
