import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { FC } from 'react';
import ItemsDimensions from './ItemDimensions';

const ItemsCards: FC<{ data: any[] }> = ({ data }) => {
  const uniqueItems: any[] = data.reduce((acc, item) => {
    const existingItem = acc.find((i: { id: string }) => i.id === item.id);

    if (existingItem) {
      return acc;
    }

    acc.push(item);
    return acc;
  }, []);

  if (uniqueItems.length === 0) {
    return <Box>No items found</Box>;
  }

  return (
    <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
      {uniqueItems.map((item) => (
        <Box
          style={{
            padding: '8px',
            width: '260px',
            boxShadow:
              '0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%)',
            borderRadius: '4px',
            height: 650,
            maxHeight: 650,
          }}
        >
          <img
            width="100%"
            style={{
              maxHeight: 250,
              maxWidth: 240,
            }}
            src={item.photo}
          />
          {item.id ? (
            <>
              {item.name && (
                <Typography variant="h5">
                  {item.name === 'No name found' ? item.title : item.name}
                </Typography>
              )}
              <Typography variant="h5">{item.megaplanName}</Typography>
              <Typography variant="body1">{`Rollun ID: ${item.id}`}</Typography>
              <Typography variant="body1">{`Brand: ${item.brand_id}`}</Typography>
              <Typography variant="body1">{`Mpn: ${item.manufacturer_part_number}`}</Typography>
              <ItemsDimensions
                width={+item.width}
                height={+item.height}
                length={+item.length}
                weight={item.weight}
                rollunId={item.id}
                title={item.name}
              />
              {item.shipWeight && (
                <Typography variant="body1">{`ship weight: ${item.shipWeight}`}</Typography>
              )}
            </>
          ) : (
            <>
              <Typography variant="h5">{item.megaplanName}</Typography>
              <Typography variant="body1">{`There is not item with:`}</Typography>
              <Typography variant="body1">{`Rollun ID(article): ${
                (item.originalMegaplanArticle === '-1'
                  ? '-'
                  : item.originalMegaplanArticle) || 'empty'
              }`}</Typography>
              <Typography variant="body1">{`Name: ${item.megaplanName}`}</Typography>
              <Typography variant="body1">{`In rollun catalog`}</Typography>
            </>
          )}
          <Box>
            <Typography variant="body1">CSNs for this item:</Typography>
            {item.itemPartNumbers.length === 0 ? (
              <Typography variant="body1">
                No matches found for this item
              </Typography>
            ) : (
              item.itemPartNumbers.map((pn: any) => (
                <Box>
                  <Typography variant="body1">{`${pn.supplier_name}: ${pn.supplier_id}`}</Typography>
                </Box>
              ))
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ItemsCards;
