import useHttpDataStore from '../useHttpDatastore';

export interface RollunIdWithCsnItem {
  rid: string;
  brand: string;
  mpn: string;
  csn: string;
  title: string;
  image: string | '-1';
  weight: string;
  sr_name: string;
}

const useRollunIdWithCsnViewDatastore = () => {
  return useHttpDataStore<RollunIdWithCsnItem>(
    '/api/datastore/RollunIdWithCsnView',
  );
};

export { useRollunIdWithCsnViewDatastore };
