import React from 'react';

interface CardProps {
  className?: string;
  noMargin?: boolean;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const Card: React.FC<CardProps> = ({
  children,
  className = '',
  style = {},
  onClick,
  noMargin = false,
}) => {
  return (
    <div
      className={
        'material-shadow-sm p-2 border-radius ' +
        (!noMargin ? ' m-2 ' : '') +
        className
      }
      onClick={onClick}
      style={style}
    >
      {children}
    </div>
  );
};

export default Card;
