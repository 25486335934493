import React from 'react';
import AbstractService, { AbstractServiceProps } from '../AbstractService';
import AppContainer from '../../layout/AppContainer/AppContainer';
import { ErrorView, Spinner } from '../../UI';
import { Tab, Tabs } from 'react-bootstrap';
import CreateProductFromSupplierContainer from './components/CreateRollunID/CreateProductFromSupplierContainer';
import './styles/style.css';
import SupplierMappingContainer from './components/SupplierMapping/SupplierMappingContainer';

/**
 * CatalogUtils set of utils to help with Catalog
 */

class CatalogUtils extends AbstractService<AbstractServiceProps> {
  render() {
    const { appConfig, error } = this.state;

    if (error) {
      return (
        <AppContainer services={{}}>
          <div className="w-100 h-100 d-flex flex-column justify-content-center">
            <ErrorView error={error}>
              <div className="d-flex justify-content-center p-3">
                <div
                  className="btn btn-primary cursor-pointer"
                  onClick={this.reloadHome}
                >
                  Home
                </div>
              </div>
            </ErrorView>
          </div>
        </AppContainer>
      );
    }

    if (appConfig === null) {
      return <Spinner />;
    }

    const { user, role } = this.props.userIdentity;
    const { appName } = appConfig;
    return (
      <AppContainer
        currentResourceName={this.props.resourceName}
        services={this.props.availableServices}
        user={user}
        role={role}
      >
        <h1 className="m-2 text-center">{appName}</h1>
        <div className="m-2">
          <Tabs id="catalog-utils-tabs" defaultActiveKey="first">
            <Tab eventKey="first" title="Create Rollun ID">
              <CreateProductFromSupplierContainer />
            </Tab>
            <Tab eventKey="second" title="Supplier Mapping">
              <SupplierMappingContainer />
            </Tab>
            <Tab eventKey="third" title="Generate Supplier Mapping">
              <SupplierMappingContainer />
            </Tab>
          </Tabs>
        </div>
      </AppContainer>
    );
  }
}

export default CatalogUtils;
