import React from 'react';
import { ErrorView, Spinner } from '../../UI';
import AbstractService, { AbstractServiceProps } from '../AbstractService';
import AppContainer from '../../layout/AppContainer/AppContainer';
import MergeBrands from './components/MergeBrands';

class MergingBrands extends AbstractService<AbstractServiceProps> {
  render() {
    const { appConfig, error } = this.state;
    const { resourceName, availableServices, userIdentity } = this.props;

    if (error) {
      return (
        <AppContainer services={{}}>
          <div className="w-100 h-100 d-flex flex-column justify-content-center">
            <ErrorView error={error}>
              <div className="d-flex justify-content-center p-3">
                <div
                  className="btn btn-primary cursor-pointer"
                  onClick={this.reloadHome}
                >
                  Home
                </div>
              </div>
            </ErrorView>
          </div>
        </AppContainer>
      );
    }

    if (appConfig === null) {
      return <Spinner />;
    }

    if (!appConfig.appParams) {
      throw new Error(
        `Service config with path ${appConfig.appPath} must have appParams field`,
      );
    }
    const { user, role } = userIdentity;

    return (
      <AppContainer
        user={user}
        role={role}
        currentResourceName={resourceName}
        services={availableServices}
      >
        <MergeBrands appConfig={appConfig} currentResourceName={resourceName} />
      </AppContainer>
    );
  }
}

export default MergingBrands;
