import { useQuery } from 'react-query';
import { Query, In, Select } from 'rollun-ts-rql';
import HttpDatastore from 'rollun-ts-datastore';
import { Position } from '../../api-clients/types';

const supplierMappingDatastore = new HttpDatastore(
  '/api/datastore/SupplierMappingDataStore',
);
const rmInventoryDatastore = new HttpDatastore(
  '/api/datastore/RockyMountainInventoryCacheDataStore',
);
const auInventoryDatastore = new HttpDatastore(
  '/api/datastore/AutodistInventoryCacheDataStore',
);
const catalogDatastore = new HttpDatastore('/api/datastore/CatalogDataStore');
const dimensionsDatastore = new HttpDatastore('/api/datastore/DimensionStore');

const getSuppliersPartNumbers = async (rollunIds: string[]) => {
  const query = new Query()
    .setQuery(new In('rollun_id', rollunIds))
    .setSelect(new Select(['rollun_id', 'supplier_id', 'supplier_name']));

  return await supplierMappingDatastore.query(query);
};

const fetchRMTitlesByPartNumbers = async (pns: string[]) => {
  if (pns.length === 0) return [];

  const query = new Query()
    .setQuery(new In('prodno', pns))
    .setSelect(new Select(['prodno', 'name']));
  let data: any[] = [];
  try {
    data = await rmInventoryDatastore.query(query);
  } catch (err) {
    console.log('fetchRMTitlesByPartNumbers err', err);
    data = [];
  }

  return data.map((el) => ({
    supplier_id: el.prodno,
    name: el.name,
  }));
};

const fetchRMTitles = async (formattedItems: any[]) => {
  const rockyPartNumbers = formattedItems.reduce((acc, el) => {
    return acc.concat(
      el.itemPartNumbers
        .filter((item: any) => item.supplier_name === 'RockyMountain')
        .map((item: any) => item.supplier_id),
    );
  }, []);
  try {
    return await fetchRMTitlesByPartNumbers(rockyPartNumbers);
  } catch (e) {
    console.log('err', e);
  }
  return [];
};

const fetchAuInfo = async (formattedItems: any[]) => {
  const auPartNumbers = formattedItems.reduce((acc, el) => {
    return acc.concat(
      el.itemPartNumbers
        .filter((item: any) => item.supplier_name === 'Autodist')
        .map((item: any) => item.supplier_id),
    );
  }, []);

  if (auPartNumbers.length === 0) {
    return formattedItems;
  }

  try {
    const query = new Query()
      .setQuery(new In('item_sku', auPartNumbers))
      .setSelect(new Select(['item_sku', 'ship_weight']));
    const data = await auInventoryDatastore.query(query);

    return formattedItems.map((item: any) => {
      const itemCsns = item.itemPartNumbers.map(
        ({ supplier_id }: any) => supplier_id,
      );
      const auItem = data.find(({ item_sku }: any) =>
        itemCsns.includes(item_sku),
      ) as any;
      console.log(auItem, item);
      if (auItem) {
        return {
          ...item,
          shipWeight: auItem.ship_weight,
        };
      }

      return item;
    });
  } catch (e) {
    console.log('err', e);
  }
  return [];
};

const fetchTitles = async (data: any) => {
  const titles = await fetchRMTitles(data);
  return data.map((el: any) => {
    const { name } =
      titles.find((item) =>
        el.itemPartNumbers.find(
          (pn: any) => item.supplier_id === pn.supplier_id,
        ),
      ) || {};
    return {
      ...el,
      name: name || 'No name found',
    };
  });
};

export const useGetItemsData = (positions: Position[]) => {
  const GET_ITEMS_DATA_KEY = `GET_${positions
    .map(({ article }) => article)
    .sort()
    .join('_')}_ITEMS_DATA_KEY`;

  const fetchData = async () => {
    if (positions.length === 0) return [];

    const query = new Query().setQuery(
      new In(
        'id',
        positions.map(({ article }) => article),
      ),
    );

    const rollunItems = await catalogDatastore.query(query);
    const rollunItemsDimensions = await dimensionsDatastore.query(query);

    const suppliersPartNumbers = await getSuppliersPartNumbers(
      positions.map((item) => item.article),
    );

    const data = positions.map((item) => {
      const rollunItem = rollunItems.find((el) => item.article === el.id);
      const rollunItemDimensions =
        rollunItemsDimensions.find((el) => item.article === el.id) || {};
      const itemPartNumbers = suppliersPartNumbers.filter(
        (pn) => pn.rollun_id === item.article,
      );

      return {
        name: 'Loading...',
        itemPartNumbers,
        ...(rollunItemDimensions && {
          ...rollunItemDimensions,
          photo: rollunItemDimensions.image,
        }),
        ...(rollunItem && rollunItem),
      };
    });

    const dataWithImagesAndNames = await fetchTitles(data);
    const dataWithAdditionalInfo = await fetchAuInfo(dataWithImagesAndNames);

    return dataWithAdditionalInfo;
  };

  return useQuery<any>(GET_ITEMS_DATA_KEY, fetchData, { staleTime: 3600000 });
};
