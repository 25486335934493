import React, { FC } from 'react';
import withControls from './withControls';
import { IRendererProps } from './DefaultRenderer';

const LinkRenderer: FC<IRendererProps> = ({ value }) => {
  return (
    <a href={value}>
      <span>{value}</span>
    </a>
  );
};

export default withControls(LinkRenderer);
