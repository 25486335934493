import { ErrorType } from '../utils/common.types';
import { Query } from 'rollun-ts-rql';
import { useMemo, useState } from 'react';
import HttpDatastore from 'rollun-ts-datastore';
import { httpErrorHandlerPromised } from '../utils/common.utils';

type HttpDatastoreActions = 'QUERY' | 'UPDATE' | 'CREATE' | 'DELETE';
type HttpDatastorePayload<T> = Query | Partial<T> | string;
export type HttpDatastoreStatus = 'loading' | 'loaded' | 'error';

export interface HttpDatastoreState<T = any> {
  result: T[] | null;
  error: ErrorType | null;
  status: HttpDatastoreStatus;
  isError: boolean;
  isLoading: boolean;
}

const useHttpDataStore = <T extends any>(
  url: string,
): [
  HttpDatastoreState<T>,
  (
    type: HttpDatastoreActions,
    payload: HttpDatastorePayload<T>,
  ) => Promise<T[] | null>,
] => {
  const datastore = useMemo(() => new HttpDatastore<T>(url), [url]);
  const [state, setState] = useState<HttpDatastoreState<T>>({
    result: null,
    error: null,
    status: 'loaded',
    isError: false,
    isLoading: false,
  });

  const dispatch = async (
    type: HttpDatastoreActions,
    payload: HttpDatastorePayload<T>,
  ): Promise<T[] | null> => {
    try {
      setState({
        ...state,
        status: 'loading',
        isLoading: true,
        isError: false,
      });
      const data = await reducer(type, payload);
      setState({
        ...state,
        result: data,
        status: 'loaded',
        isLoading: false,
      });
      return data;
    } catch (e) {
      const { code, text } = await httpErrorHandlerPromised(e);
      setState({
        ...state,
        error: {
          code,
          text,
        },
        status: 'error',
        isError: true,
        isLoading: false,
      });
      return null;
    }
  };

  const reducer = async (
    type: HttpDatastoreActions,
    payload: Query | Partial<T> | string,
  ): Promise<T[]> => {
    switch (type) {
      case 'QUERY':
        return await datastore.query(payload as Query);
      case 'CREATE':
        return [await datastore.create(payload as Partial<T>)];
      case 'UPDATE':
        return [await datastore.update(payload as Partial<T>)];
      case 'DELETE':
        return [await datastore.delete(payload as string)];
    }
  };

  return [{ ...state }, dispatch];
};

export default useHttpDataStore;
