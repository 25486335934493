import { Box } from '@material-ui/core';
import React, { ComponentType, FC, useState } from 'react';
import MuiButton, { MuiColors } from '../../../../../UI/MuiButton';
import WidgetContainer from './WidgetContainer';

export type WidgetProps = {
  buttonName: string;
  buttonColor?: MuiColors;
  displayName: string;
  docsLink?: string;
  docsTitle?: string;
  component: ComponentType<{
    setDisplayName: (displayName: string) => void;
    props: Record<string, unknown>;
  }>;
  props?: Record<string, unknown>;
};

interface DealButtonsProps {
  widgets: WidgetProps[];
}

const DealWidgets: FC<DealButtonsProps> = ({ widgets }) => {
  const [openedWidgets, setOpenedWidgets] = useState<string[]>([]);
  const [displayNames, setDisplayNames] = useState<
    {
      displayName: string;
      customName: string;
    }[]
  >(
    widgets.map(({ buttonName }) => ({
      displayName: buttonName,
      customName: buttonName,
    })),
  );

  const handleOpenWidget = (widgetName: string) => {
    setOpenedWidgets((prev) => {
      if (prev.includes(widgetName)) {
        return prev.filter((name) => name !== widgetName);
      }
      return [...prev, widgetName];
    });
  };

  const getDisplayName = (widgetName: string) => {
    const displayName = displayNames.find(
      (name) => name.displayName === widgetName,
    );
    return displayName?.customName || widgetName;
  };

  return (
    <Box display="flex" style={{ gap: 8 }} flexDirection="column">
      <Box
        flexWrap="wrap"
        style={{
          display: 'flex',
          gap: 8,
        }}
      >
        {widgets.map(({ buttonName, buttonColor }) => (
          <MuiButton
            color={buttonColor}
            key={buttonName}
            disabled={openedWidgets.includes(buttonName)}
            variant="contained"
            onClick={() => handleOpenWidget(buttonName)}
            style={{ minWidth: 184 }}
          >
            {getDisplayName(buttonName)}
          </MuiButton>
        ))}
      </Box>
      {widgets.map((widget, index) => (
        <WidgetContainer
          widget={widget}
          key={index}
          onClose={() => handleOpenWidget(widget.buttonName)}
          isOpen={openedWidgets.includes(widget.buttonName)}
          props={widget.props}
          setDisplayName={(displayName: string) => {
            setDisplayNames((prev) => {
              const newNames = prev.map((name) => {
                if (name.displayName === widget.buttonName) {
                  return {
                    ...name,
                    customName: displayName,
                  };
                }
                return name;
              });
              return newNames;
            });
          }}
        />
      ))}
    </Box>
  );
};

export default DealWidgets;
