import { Fba } from '../../api-clients/types';
import { ApiClientsFba } from '../../api-clients/api-clients-fba';

export const useMutateDeal = () => {
  const handleUpdateDeal = async (id: string, data: Partial<Fba>) => {
    await new ApiClientsFba().update(id, data);
  };

  return {
    handleUpdateDeal,
  };
};
