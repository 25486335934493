import React, { FC, useEffect, useState } from 'react';
import { AppConfig } from '../../../../AbstractService';
import RulesTreeEditor from '../../../../ACLRulesEditor/components/RulesTreeEditor';
import { Query, In } from 'rollun-ts-rql';
import {
  Box,
  LinearProgress,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { ErrorView } from '../../../../../UI';
import MuiButton from '../../../../../UI/MuiButton';
import CreateResourceDs from './CreateResourceDs';
import { merge } from '../../../../../utils/common.utils';
import {
  useResourceDatastore,
  useRouteDatastore,
} from '../../../../../hooks/datastores';

interface RulesEditorProps {
  appParams: AppConfig;
}

export const replaceSlashWithDash = (str: string | undefined) => {
  if (!str) {
    return '';
  }

  return str
    .split('/')
    .filter((s) => !!s)
    .join('-');
};

const RulesEditor: FC<RulesEditorProps> = ({ appParams }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const [{ result, error, status }, dispatch] = useResourceDatastore();
  const [
    { result: routes, error: routeError, status: routeStatus },
    routeDispatch,
  ] = useRouteDatastore();

  const fetchRules = async () => {
    await dispatch(
      'QUERY',
      new Query({
        query: new In('name', [
          replaceSlashWithDash(appParams.appPath),
          replaceSlashWithDash(
            appParams.appParams.gridParams?.datastoreUrl || '',
          ),
        ]),
      }),
    );
    await routeDispatch(
      'QUERY',
      new Query({
        query: new In('name', [
          replaceSlashWithDash(appParams.appPath),
          replaceSlashWithDash(
            appParams.appParams.gridParams?.datastoreUrl || '',
          ),
        ]),
      }),
    );
  };

  useEffect(() => {
    fetchRules();
  }, [openDialog]);

  if (status === 'loading' || routeStatus === 'loading') {
    return <LinearProgress />;
  }

  if (status === 'error' || routeStatus === 'error') {
    return <ErrorView error={error || routeError} />;
  }

  if (result && routes && result.length === 0 && result.length === 0) {
    return <div>No config found. You need to create one</div>;
  }

  return (
    <Box display="flex" flexDirection={isTablet ? 'column' : 'row'}>
      {merge(result, routes, 'name').length === 1 && (
        <>
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <MuiButton color="success" onClick={() => setOpenDialog(true)}>
              Create resource for datastore
            </MuiButton>
          </Box>
          <CreateResourceDs
            isOpen={openDialog}
            setIsOpen={() => setOpenDialog(false)}
            appParams={appParams}
          />
        </>
      )}
      {merge(routes, result, 'name').map(
        (res: { id: string; name: string }) => (
          <div key={res.id}>
            <Typography variant="h4" align="center">
              {res.name}
            </Typography>
            <RulesTreeEditor resourceId={res.id} />
          </div>
        ),
      )}
    </Box>
  );
};

export default RulesEditor;
