import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';

const LoginMessageDisplayer = () => {
  const [message, setMessage] = useState<{
    type: 'error' | 'success';
    text: string | null;
  }>({
    type: 'success',
    text: null,
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('registrationState')) {
      const isRegistered = queryParams.get('registrationState');
      if (isRegistered === 'success') {
        setMessage({
          type: 'success',
          text: 'Registration request sent. Contact site admin',
        });
      } else {
        setMessage({
          type: 'error',
          text: 'Registration failed. Contact site admin',
        });
      }
    }
    if (queryParams.has('loginState')) {
      const loginState = queryParams.get('loginState');
      if (loginState === 'fail') {
        setMessage({
          type: 'error',
          text: 'Login failed. Contact site admin',
        });
      }
    }
  });

  return message.text?.length === 0 ? null : (
    <Typography
      variant="body1"
      color={message.type === 'error' ? 'error' : 'textPrimary'}
    >
      {message.text}
    </Typography>
  );
};

export default LoginMessageDisplayer;
