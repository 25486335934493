import React from 'react';
import './Checkbox.css';

interface IProps {
  onChange?: (value: boolean) => void;
  className?: string;
  initValue?: boolean;
}

const Checkbox: React.FC<IProps> = ({
  onChange,
  className = '',
  initValue = false,
}) => {
  return (
    <label className={'checkbox__container ' + className}>
      <input
        type="checkbox"
        defaultChecked={initValue}
        onChange={(e) => onChange && onChange(e.target.checked)}
      />
      <span className="checkbox__mark" />
    </label>
  );
};

export default Checkbox;
