import React, { useEffect, useRef, useState } from 'react';
import { BrowserMultiFormatReader, IScannerControls } from '@zxing/browser';
import { Result, BarcodeFormat } from '@zxing/library';
import { Button } from '../../UI';
import { logger } from '../../utils/logger';

export type BarcodeScannerProps = {
  onResult?: (code: string, type: string) => void;
  resultFormatter?: (
    code: string,
    type: string,
  ) => { code: string; type: string };
};

// https://github.com/samdutton/simpl/blob/gh-pages/getusermedia/sources/js/main.js

const w = (window as unknown) as Window & { stream: any };

const codeReader = new BrowserMultiFormatReader(new Map(), {
  delayBetweenScanAttempts: 100,
});
const cameraCacheKey = 'scannerCameraId';

export const BarcodeScanner: React.FC<BarcodeScannerProps> = (props) => {
  const ref = useRef<HTMLVideoElement>(null); // ref => { current: null }
  const [cameras, setCameras] = useState([]);
  const [error, setError] = useState('');
  const [results, setResults] = useState<
    { text: string; format: string; idx: number }[]
  >([]);
  useEffect(() => {
    logger.info('scanner-v2: useEffect()', {});
    const promise = getStream(getCameraIdFromCache())
      .then(getDevices)
      .then(gotDevices)
      .then(decodeFromStream)
      .catch(handleError);

    return () => {
      logger.info('scanner-v2: useEffect().return', {});
      if (w.stream) {
        logger.info('scanner-v2: useEffect().return.cleanup stream', {});

        w.stream.getTracks().forEach((track: any) => {
          logger.info(
            'scanner-v2: useEffect().return.cleanup cleanUpRunningTrack()',
            { track },
          );
          track.stop();
        });
        w.stream = undefined;
      }
      promise.then((c) => c && c.stop()).catch();
      logger.info('scanner-v2: useEffect().return.done', {});
    };
  }, []);

  function persistCameraId(cameraId: string) {
    localStorage.setItem(cameraCacheKey, cameraId);
  }

  function getCameraIdFromCache() {
    return localStorage.getItem(cameraCacheKey) || undefined;
  }

  async function decodeFromStream(): Promise<IScannerControls | undefined> {
    logger.info('scanner-v2: decodeFromStream()', {});
    if (!ref.current) {
      return;
    }

    // when onResult callback is provided we stop after first decoded
    // result. If no, assume it is testing mode, and just print
    // results as an overlay
    if (props.onResult) {
      const result = await codeReader.decodeOnceFromVideoElement(ref.current);
      logger.info('scanner-v2: decodeFromStream().decodeOnceFromVideoElement', {
        result: result.getText(),
        format: BarcodeFormat[result.getBarcodeFormat()],
      });

      onResult(result);

      if (props.resultFormatter) {
        const { code, type } = props.resultFormatter(
          result.getText(),
          BarcodeFormat[result.getBarcodeFormat()],
        );
        props.onResult(code, type);
        return;
      }

      props.onResult(
        result.getText(),
        BarcodeFormat[result.getBarcodeFormat()],
      );
      return;
    }

    const control = await codeReader.decodeFromVideoElement(
      ref.current,
      onResult,
    );

    return control;
  }

  function onResult(result: Result | undefined) {
    logger.info('scanner-v2: onResult()', {
      resultDefined: !!result,
      result: result?.getText(),
      format: result ? BarcodeFormat[result.getBarcodeFormat()] : '',
    });
    if (!result) {
      return;
    }
    const text = result.getText();
    const format = BarcodeFormat[result.getBarcodeFormat()];

    setResults((results) => {
      const { idx } = results[results.length - 1] || { idx: 0 };

      if (results.length >= 5) {
        return results.slice(1).concat({ text, format, idx: idx + 1 });
      }
      return results.concat({ text, format, idx: idx + 1 });
    });
  }

  function getDevices() {
    // AFAICT in Safari this only gets default devices until gUM is called :/
    return navigator.mediaDevices.enumerateDevices();
  }

  function gotDevices(deviceInfos: any) {
    setCameras(
      deviceInfos.filter((deviceInfo: any) => deviceInfo.kind === 'videoinput'),
    );
    logger.info('scanner-v2: gotDevices()', { deviceInfos });
  }

  function getStream(cameraId?: string) {
    logger.info('scanner-v2: getStream()', { cameraId });
    if (w.stream) {
      w.stream.getTracks().forEach((track: any) => {
        logger.info('scanner-v2: cleanUpRunningTrack()', {});
        track.stop();
      });
    }
    const constraints = {
      video: {
        deviceId: cameraId ? { exact: cameraId } : undefined,
        width: {
          ideal: 1920,
          max: 1920,
        },
        height: {
          ideal: 1080,
          max: 1080,
        },
      },
    };

    logger.info('scanner-v2: getStream().gum', { constraints });
    return navigator.mediaDevices
      .getUserMedia(constraints)
      .then(gotStream)
      .catch(handleError);
  }

  function gotStream(stream: MediaStream) {
    logger.info('scanner-v2: gotStream()', {});
    w.stream = stream; // keep stream around to close later

    ref.current && (ref.current.srcObject = stream);
  }

  function handleError(error: any) {
    setError(error.message);
    logger.error('scanner-v2:error', {
      error: error.message,
      errorStack: error.stack,
      cameras,
      results,
      cameraId: getCameraIdFromCache(),
    });
  }

  if (error) {
    logger.info('scanner-v2: error', { error });

    return (
      <div>
        <pre className="text-danger">{error}</pre>
        <Button color="info" onClick={() => setError('')}>
          Back
        </Button>
      </div>
    );
  }

  return (
    <div>
      <div className="select">
        <label htmlFor="videoSource" className="mr-1">
          Video source:
        </label>
        <select
          id="videoSource"
          onChange={(e) => {
            persistCameraId(e.target.value);
            getStream(e.target.value);
          }}
        >
          {cameras.map((camera: any) => (
            <option
              selected={camera.deviceId === getCameraIdFromCache()}
              key={camera.deviceId}
              value={camera.deviceId}
            >
              {camera.label}
            </option>
          ))}
        </select>
      </div>
      <div className="position-relative">
        <div
          className="position-absolute"
          style={{ top: 10, left: 10, color: 'red' }}
        >
          {results.length && (
            <p>Labels shows without concat in final format.</p>
          )}
          {results.map(({ text, format, idx }) => (
            <p className="mb-0" key={idx}>
              #{idx}: {text} {format}
            </p>
          ))}
        </div>
        <video ref={ref} autoPlay muted playsInline></video>
      </div>
    </div>
  );
};
