import _ from 'lodash';
import { noop } from '../../../utils/common.utils';
import HttpDatastore from 'rollun-ts-datastore';
import { In, Query, Select } from 'rollun-ts-rql';
import {
  fetchCategories,
  fetchCommonItemsData,
  fetchCompatibles,
  FileExchangeDataByRollunId,
  formatTitle,
} from './getFileExchangeByRollunId';
import { CompatibleVehicle } from '../../../utils/common.types';
import { isJSON } from 'rollun-ts-utils';

const RMCatalog = new HttpDatastore(
  '/api/datastore/RockyMountainInventoryCacheDataStore',
);
const fetchItemsData = async (rids: Array<string>): Promise<Array<any>> => {
  return RMCatalog.query(
    new Query({
      select: new Select([
        'rollun_id',
        's_mpn',
        'name',
        'color',
        'size',
        'brand',
        'upc',
        'picture',
        'prodno',
        's_quantity',
        'vehicles',
      ]),
      query: new In('rollun_id', rids),
    }),
  );
};

const RMParser = new HttpDatastore(
  '/api/datastore/RockyMountainParsedProducts',
);
const fetchParserData = async (csns: Array<string>): Promise<Array<any>> => {
  if (csns.length === 0) return [];
  return RMParser.query(
    new Query({
      select: new Select(['id', 'details_html']),
      query: new In('id', csns),
    }),
  );
};

export const prepareDescription = (raw = '') => {
  return `<div>${raw.replace(/<\/?a.*?>/gi, '').replace(/\n/g, '</br>')}</div>`;
};

export const fetchRockyMountainData = async (
  rollunIds: Array<string>,
  progressCB: (p: string) => void = noop,
): Promise<FileExchangeDataByRollunId> => {
  progressCB('Starting fetching data for RockyMountain...');

  const chunks = _.chunk(rollunIds, 100);

  let commonItemsData: Array<any> = [];
  let itemsData: Array<any> = [];
  let parserData: Array<any> = [];

  let categoriesData: Array<{
    rollun_id: string;
    category_id: string;
    category_name: string;
  }> = [];
  let compatibles: Array<CompatibleVehicle & { rollunId: string }> = [];

  for (let i = 0, len = chunks.length; i < len; i++) {
    const chunk = chunks[i];
    progressCB &&
      progressCB(
        `Fetching ${i * 100 + chunk.length} of ${rollunIds.length} items`,
      );
    const [currentCommonItemsData, currentItemData] = await Promise.all([
      fetchCommonItemsData(chunk),
      fetchItemsData(chunk),
    ]);

    const [
      currentParserData,
      currentCategoriesData,
      currentCompatibles,
    ] = await Promise.all([
      fetchParserData(currentItemData.map(({ prodno }) => prodno)),
      fetchCategories(
        currentItemData.map(({ rollun_id, name }) => ({ rollun_id, name })),
      ),
      fetchCompatibles(
        currentCommonItemsData
          .map(({ compatibles, id: rollunId }) => {
            return (compatibles !== '-1' && isJSON(compatibles)
              ? (Object.values(JSON.parse(compatibles)) as Array<string>)
              : []
            ).map((compatibleId) => ({ rollunId, compatibleId }));
          })
          .flat(),
      ),
    ]);

    compatibles = compatibles.concat(currentCompatibles);
    commonItemsData = commonItemsData.concat(currentCommonItemsData);
    itemsData = itemsData.concat(currentItemData);
    parserData = parserData.concat(currentParserData);
    categoriesData = categoriesData.concat(currentCategoriesData);
  }

  return rollunIds.reduce((acc: FileExchangeDataByRollunId, rollunId) => {
    const {
      s_mpn = '',
      name = '',
      color = '',
      size = '',
      brand = '',
      upc = '',
      picture = '',
      prodno = '',
      s_quantity = 0,
    } = itemsData.find(({ rollun_id }) => rollun_id === rollunId) || {};

    const { ct_rollun_price = 0, ct_plaisir_price = 0 } =
      commonItemsData.find(({ id }) => id === rollunId) || {};

    const { details_html = '' } =
      parserData.find(({ id }) => id === prodno) || {};

    const { category_id = '' } =
      categoriesData.find(({ rollun_id }) => rollun_id === rollunId) || {};

    acc[rollunId] = {
      name: formatTitle([name, s_mpn, size, color]),
      brand,
      mpn: s_mpn,
      color,
      size,
      upc,
      photo: (picture == '-1' ? '' : picture) || '',
      categoryId: category_id,
      ct_plaisir_price,
      ct_rollun_price,
      description: prepareDescription(details_html),
      qty: +s_quantity,
      vehicles: compatibles.filter(({ rollunId: rid }) => rid === rollunId),
    };
    return acc;
  }, {});
};
