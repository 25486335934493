import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import Header from './components/Header';

const Client: FC = ({}) => {
  return (
    <Box display="flex" flexDirection="column" style={{ gap: 8 }}>
      <Header />
    </Box>
  );
};

export default Client;
