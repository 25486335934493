import React, { Component } from 'react';
import {
  IEditorProps,
  IEditorState,
} from '../../ServiceConstructor/components/EditService/EditService';
import { ConfirmButton } from '../../../UI';
import { AppConfig } from '../../AbstractService';
import HttpDatastore from 'rollun-ts-datastore/dist';
import { FrontConfigRow } from '../../RootApp/Root.app';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import _ from 'lodash/fp';
import { CACHE_NAME_PREFIX } from '../../Table/util/constants';

class EditHelpPage extends Component<IEditorProps, IEditorState> {
  mdParser = new MarkdownIt();

  constructor(props: IEditorProps) {
    super(props);
    this.state = {
      config: props.config,
      isLoading: false,
      isError: false,
    };
  }

  servicesConfigDS = new HttpDatastore<FrontConfigRow>(
    '/api/datastore/frontConfigDataStore',
  );

  handleConfigSave = async (newConfig: AppConfig) => {
    this.setState({ isLoading: true });
    const { onConfigSave } = this.props;
    onConfigSave && onConfigSave(newConfig);
    this.servicesConfigDS
      .update({ id: this.props.serviceID, config: newConfig })
      // update cache
      .then((res) =>
        localStorage.setItem(
          CACHE_NAME_PREFIX + res.resource,
          JSON.stringify(res.config),
        ),
      )
      .catch((err) => {
        console.log('err', err);
        this.setState({ isError: true });
      })
      .finally(() => this.setState({ isLoading: false }));
  };

  handleTextChange = (data: { text: string; html: string }) => {
    const newConfig = _.update('appParams.HelpPageParams.userNote')(
      () => data.text,
    )(this.state.config);

    this.setState({ config: newConfig });
  };

  render() {
    const { isLoading, config } = this.state;

    const { HelpPageParams } = config.appParams;
    if (!HelpPageParams) {
      throw new Error(`HelpPageParams is required in appParams!`);
    }
    return (
      <div>
        <div className="d-flex my-2 justify-content-between">
          <h3>Help Page edit</h3>
          <ConfirmButton
            className="btn-primary"
            disabled={isLoading}
            modalBody={
              <h4>Are You absolutely sure, this changes cant be undone?</h4>
            }
            onConfirm={() => this.handleConfigSave(config)}
          >
            Save
          </ConfirmButton>
        </div>
        <MdEditor
          value={HelpPageParams.userNote}
          renderHTML={(text) => this.mdParser.render(text)}
          onChange={this.handleTextChange}
        />
      </div>
    );
  }
}

export default EditHelpPage;
