import React from 'react';
import withControls from './withControls';
import { Typography } from '@material-ui/core';
import JsonRenderer from './JsonRenderer';

export interface IRendererProps {
  value: any;
  name: string;
  row?: { [key: string]: any };
}

const possibleTimestampColumnNames = [
  '_at',
  'update',
  'timestamp',
  'datetime',
  'time',
  'date',
];

export const isValidTimestamp = (value: string, name: string) => {
  if (value.length === 10 && new Date(+value).toString() !== 'Invalid Date') {
    const colName = name.toLowerCase();
    return (
      colName &&
      possibleTimestampColumnNames.find((possibleName) =>
        colName.includes(possibleName),
      )
    );
  }
  return false;
};

const DefaultRenderer: React.FC<IRendererProps> = (props) => {
  const { value } = props;
  if (value === null) {
    return (
      <Typography
        color="textSecondary"
        style={{ opacity: 0.5 }}
        title="Cell has value `null`"
      >
        <span>null</span>
      </Typography>
    );
  }
  if (value === undefined) {
    return (
      <Typography
        color="textSecondary"
        style={{ opacity: 0.5 }}
        title="Cell has value `undefined`"
      >
        <span>undefined</span>
      </Typography>
    );
  }
  if (value === '') {
    return (
      <Typography
        color="textSecondary"
        style={{ opacity: 0.5 }}
        title="Cell has empty string value`"
      >
        <span>empty</span>
      </Typography>
    );
  }

  if (typeof value === 'object') {
    return <JsonRenderer {...props} />;
  }

  return (
    <Typography variant="body2" title={value}>
      <span>{value}</span>
    </Typography>
  );
};

export default withControls(DefaultRenderer);
