import { FC } from 'react';
import { TableHeaderButton } from '../../../../AbstractService';
import DownloadClickNShipInfo from './DownloadClickNShipInfo';
import RofInventoryUpdater from './RofInventoryUpdater';
import NewItemsFromTable from './NewItemsFromTable';
import CreateSelectedItems from './CreateSelectedItems';
import GetRidsByBrandAndMpn from './GetRidsByBrandAndMPN';
import ClearMpProblems from './ClearMpProblems';
import HowToReturn from './HowToReturn';
import HowToReturnV3 from './HowToReturnV3';
import TableHeaderButtonDocs from './TableHeaderButtonDocs';

export interface HeaderButton {
  name: TableHeaderButton['name'];
  component: FC<any>;
}

const buttons: HeaderButton[] = [
  { name: 'RofInventoryUpdater', component: RofInventoryUpdater },
  { name: 'DownloadClickNShipInfo', component: DownloadClickNShipInfo },
  { name: 'NewItemsFromTable', component: NewItemsFromTable },
  { name: 'CreateSelectedItems', component: CreateSelectedItems },
  { name: 'GetRidsByBrandAndMPN', component: GetRidsByBrandAndMpn },
  { name: 'ClearMpProblems', component: ClearMpProblems },
  { name: 'HowToReturn', component: HowToReturn },
  { name: 'HowToReturnV3', component: HowToReturnV3 },
  { name: 'TableHeaderButtonDocs', component: TableHeaderButtonDocs },
];

export default buttons;
