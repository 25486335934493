export const FILTERS_DATASTORE_URL = '/api/datastore/test_filters_db';

export const COLUMN_HEADER_ALIAS: { [key: string]: string } = {
  supplier_id: 'csn',
};

export const ROLES_INHERITANCE = 'ROLES_INHERITANCE';

export const CACHE_NAME_PREFIX = '__SERVICE_CACHE__';

export const PUT_RULES_NAME_PREFIX = '__PUT_RULES_CACHE__';

export const USER_FRONT_CONFIG = 'USER_FRONT_CONFIG';

export const USER_UNAUTHORIZED = 'USER_UNAUTHORIZED';

export const CACHED_INFO_TIMESTAMP = 'CACHED_INFO_TIMESTAMP';
