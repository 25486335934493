import { nameFieldWithoutMpOrderId } from '../../api-clients/api-clients-return-orders';
import { Position } from '../../api-clients/types';

export function getNotUsedPositions(
  positionsFromOrder: Position[],
  positionsFromReturn: Position[],
): Position[] {
  const positionsNotUsed: (Position | null)[] = positionsFromOrder.map(
    (orderPosition) => {
      const returnPosition = positionsFromReturn.find(
        (returnPosition) =>
          returnPosition.article === orderPosition.article &&
          returnPosition.cost === orderPosition.cost &&
          nameFieldWithoutMpOrderId(returnPosition) ===
            nameFieldWithoutMpOrderId(orderPosition),
      );

      if (returnPosition) {
        if (returnPosition.quantity < orderPosition.quantity) {
          positionsFromReturn = positionsFromReturn.filter(
            (p) => p !== returnPosition,
          );
          return {
            ...orderPosition,
            quantity: orderPosition.quantity - returnPosition.quantity,
          };
        } else if (orderPosition.quantity === returnPosition.quantity) {
          positionsFromReturn = positionsFromReturn.filter(
            (p) => p !== returnPosition,
          );
          return null;
        } else {
          return orderPosition;
        }
      } else {
        return orderPosition;
      }
    },
  );

  return positionsNotUsed.filter((pos): pos is Position => pos !== null);
}

export function getPositionsForReturn(
  parsedPositions: Position[],
  positionsNotUsed: Position[],
) {
  let isFullQuantity = true;
  const result = parsedPositions
    .map((item) => {
      const posIndex = positionsNotUsed.findIndex(
        (pos) => item.article === pos.article && item.quantity <= pos.quantity,
      );
      if (posIndex !== -1) {
        const pos = positionsNotUsed[posIndex];
        positionsNotUsed.splice(posIndex, 1);
        if (pos.quantity > item.quantity) isFullQuantity = false;
        return { ...item, name: pos.name, cost: pos.cost };
      }
      return null;
    })
    .filter((pos): pos is Position => pos !== null);
  return { result, isFullQuantity };
}

export function alertMessage(deal: any, requiredFields: string[]) {
  const messageFields: string[] = [];

  for (const field of requiredFields) {
    if (deal?.[field]) continue;
    messageFields.push(field);
  }

  if (!messageFields.length) return;
  return `Not filled fields: ${messageFields.join(', ')}.`;
}
