export const scenarios = [
  {
    id: '1',
    name: 'order shipped',
  },
  {
    id: '2',
    name: 'check tracknumber in parcel',
    url: 'http://megaplan-crm/api/webhook/TrackNumberCheckOrder',
  },
  {
    id: '3',
    name: 'autohowtobuy 3',
    url: 'https://crm-callbacks.rollun.net/autohowtobuy3',
  },
  {
    id: '4',
    name: 'linked deal autocreation',
    url: 'http://megaplan-crm/api/webhook/HowToBuy4Webhook',
  },
  {
    id: '5',
    name: 'cogs',
    url: 'http://megaplan-crm/api/webhook/UpdateOrderCogsWebhook',
  },
  {
    id: '6',
    name: 'date label creation from carrier',
  },
  {
    id: '7',
    name: 'check linked order deal',
    url: 'http://megaplan-crm/api/webhook/CheckLinkedDeal',
  },
  {
    id: '8',
    // TuckerRockySupplier
    name: 'autobuy AU, PU, WPS',
    url: 'http://megaplan-crm/api/webhook/AutoBuy',
  },
  {
    id: '9',
    // TuckerRockySupplier
    name: 'get tracknumber AU, PU, WPS, TFT',
    url: 'http://megaplan-crm/api/webhook/OrderStatus',
  },
  {
    id: '10',
    name: 'TR get prices and tracknumbers',
    url: 'http://megaplan-crm/api/webhook/OrderStatus',
  },
  {
    id: '11',
    name: 'tracknumber to MP',
    url: 'http://megaplan-crm/api/webhook/TrackNumberToMarketplace',
  },
  {
    id: '12',
    name: 'tracknumber to crm',
    url: 'http://megaplan-crm/api/webhook/TrackNumberToMegaplan',
  },
  {
    id: '13',
    name: 'ship status',
    url: 'http://megaplan-crm/api/webhook/TracknumberShipStatus',
  },
  {
    id: '14',
    name: 'delivered',
  },
  {
    id: '15',
    name: 'return app add info',
    url: 'http://megaplan-crm/api/webhook/AddReturnInfo',
  },
  {
    id: '16',
    name: 'dimensions',
    url: 'http://megaplan-crm/api/webhook/DealDimension',
  },
  {
    id: '17',
    name: 'problem description',
  },
  {
    id: '18',
    name: 'create rof reserve',
    url: 'http://megaplan-crm/api/webhook/CreateReserve',
  },
  {
    id: '19',
    name: 'delete rof reserve and subtract',
    url: 'http://megaplan-crm/api/webhook/DeleteReserveAndSubtract',
  },
  {
    id: '20',
    // TuckerRockySupplier
    name: 'autobuy AU, PU, WPS',
    url: 'http://megaplan-crm/api/webhook/AutoBuy',
  },
  {
    id: '21',
    name: 'RM autobuy',
    url: 'http://megaplan-crm/api/webhook/PickUpAutoBuyRm',
  },
  {
    id: '22',
    name: 'amazon labels creation',
    url: 'http://megaplan-crm/api/webhook/AmazonLabelCreationWebhook',
  },
  {
    id: '23',
    name: 'amazon get labels by bag',
    url: 'http://megaplan-crm/api/webhook/SendBagLabels',
  },
  {
    id: '24',
    name: 'prices to pickup orders',
    url: 'http://megaplan-crm/api/webhook/AddPriceToPickup',
  },
  {
    id: '25',
    name: 'add products to bag',
    url: 'http://megaplan-crm/api/webhook/AddProductsToBag',
  },
  {
    id: '26',
    name: 'return deal creation',
    url: 'http://megaplan-crm/api/webhook/CreateReturnPackage',
  },
  {
    id: '27',
    name: 'item not shipped',
  },
  {
    id: '28',
    name: 'was not shipped',
  },
  {
    id: '29',
    name: 'bag filling app',
    url: 'http://megaplan-crm/api/webhook/BagFilling',
  },
  {
    id: '30',
    name: 'refund notification dropship',
  },
  {
    id: '32',
    name: 'was refunded',
  },
  {
    id: '33',
    name: 'was not refunded',
  },
  {
    id: '34',
    name: 'ship status',
    url: 'http://megaplan-crm/api/webhook/TracknumberShipStatus',
  },
  {
    id: '35',
    name: 'new correct item',
  },
  {
    id: '36',
    name: 'new incorrect item',
  },
  {
    id: '37',
    name: 'correct with problem',
  },
  {
    id: '38',
    name: 'incorrect with problem',
  },
  {
    id: '39',
    name: 'was refunded',
  },
  {
    id: '40',
    name: 'was not refunded',
  },
  {
    id: '41',
    name: 'was not received',
  },
  {
    id: '42',
    name: 'was not shipped',
  },
  {
    id: '43',
    name: 'check refund',
  },
  {
    id: '44',
    name: 'to Archive',
  },
  {
    id: '45',
    name: 'refund notification pickup',
  },
  {
    id: '46',
    name: 'duplicates check',
    url: 'https://crm-callbacks.rollun.net/dublicatescheck',
  },
  {
    id: '47',
    name: 'date check',
  },
];

export function getScenarioById(
  scenarioId: string,
):
  | {
      name: string;
      url?: string;
      id: string;
    }
  | undefined {
  return scenarios.find(({ id }) => id === scenarioId);
}
