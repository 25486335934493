import Select from 'rollun-ts-rql/dist/nodes/Select';
import Sort, { SortOptions } from 'rollun-ts-rql/dist/nodes/Sort';
import DropToRemoveNodeField, {
  QueryNodeNames,
} from '../DropToRemoveNodeField/DropToRemoveNodeField';
import PossibleNodeFields from '../PossibleNodeFields/PossibleNodeFields';
import React, { FC, useState } from 'react';
import SelectNodeEditor from '../SelectNodeEditor/SelectNodeEditor';
import SortNodeEditor from '../SortNodeEditor/SortNodeEditor';
import Query from 'rollun-ts-rql/dist/Query';
import _ from 'lodash';
import HttpDatastore from 'rollun-ts-datastore';
import NewFilterCreatorDialog from '../../../../../forms/NewFilterCreatorDialog';
import {
  QueryStringifier,
  AggregateFunctionNode,
  GroupBy,
} from 'rollun-ts-rql';
import { appendQuery, setQueryNode } from '../../../../../utils/query.utils';
import QueryNodeEditor from '../QueryNodeEditor/QueryNodeEditor';
import { FILTERS_DATASTORE_URL } from '../../../util/constants';
import { Button, Spinner } from '../../../../../UI';
import { FilterRow } from '../../Table/FiltersPreview';
import { encodeRqlString } from '../../../../../utils/common.utils';
import { randomString } from 'rollun-ts-utils/dist';
import { Box } from '@material-ui/core';

export type QueryNodes = 'selectNode' | 'sortNode' | 'limitNode' | 'queryNode';

export interface QueryEditorProps {
  fieldNames: string[];
  query: Query;
  onApplyQuery: (query: Query) => void;
  appName: string;
  idField?: string;
}

const FilterEditor: FC<QueryEditorProps> = ({
  fieldNames,
  query: propsQuery,
  appName,
  onApplyQuery,
  idField,
}) => {
  const [query, setQuery] = useState(propsQuery);
  const [loading, setLoading] = useState(false);

  const addAggregateNode = (name: string, type: string) => {
    const newQuery = _.cloneDeep(query);
    // if select node is empty, just add aggregate
    if (!newQuery.selectNode) {
      newQuery.selectNode = new Select([new AggregateFunctionNode(type, name)]);
      setQuery(newQuery);
      return;
    }

    const current = newQuery.selectNode.fields.find(
      (node: string | AggregateFunctionNode) => {
        if (node instanceof AggregateFunctionNode) {
          return node.function === type && node.field === name;
        }
        return false;
      },
    );
    // if this node exists in list, return
    if (current) return;
    newQuery.selectNode.fields.push(new AggregateFunctionNode(type, name));
    const notAggregateFields = newQuery.selectNode.fields.filter(
      (node: string | AggregateFunctionNode) =>
        !(node instanceof AggregateFunctionNode),
    );
    if (notAggregateFields.length) {
      newQuery.groupNode = new GroupBy(notAggregateFields);
    }
    setQuery(newQuery);
  };

  const SaveFilterDialog = () => {
    const formConfig = [{ field: 'name' }];
    return (
      <NewFilterCreatorDialog
        title="Save filter"
        formConfig={formConfig}
        onFormSubmit={(data: { name: string }) => {
          const payload = {
            name: data.name,
            table_name: appName,
            id: randomString(40),
            rql: encodeRqlString(QueryStringifier.stringify(query)),
          };
          setLoading(true);

          try {
            new HttpDatastore<FilterRow>(FILTERS_DATASTORE_URL).create(payload);
            onApplyQuery(query);
          } catch (e) {}
          setLoading(false);
        }}
      />
    );
  };

  const onSortNodeChange = (sortOptions: SortOptions) => {
    const sortOptionsNames = Object.keys(sortOptions);
    const lastSortOption = sortOptionsNames[sortOptionsNames.length - 1];

    setQuery((oldQuery) => appendQuery(new Sort(sortOptions), oldQuery));

    if (!query.selectNode) {
      onSelectNodeChange([lastSortOption]);
      return;
    }

    if (
      !query.selectNode.fields.find((field: string) => field === lastSortOption)
    ) {
      onSelectNodeChange([...query.selectNode.fields, lastSortOption]);
    }
  };

  const onSelectNodeChange = (fields: (string | AggregateFunctionNode)[]) => {
    const toGroupFields = fields.filter(
      (field) => !(field instanceof AggregateFunctionNode),
    );

    setQuery((oldQuery) => {
      const newQuery = appendQuery(new Select(fields), oldQuery);

      if (toGroupFields.length && toGroupFields.length !== fields.length) {
        newQuery.groupNode = new GroupBy(toGroupFields);
        // Remove limit node on adding GroupBy node, cause not every ds supports that
        // newQuery.limitNode = undefined;
      }

      return newQuery;
    });
  };

  const removeNode = (queryNodeName: QueryNodeNames | QueryNodeNames[]) => {
    const newQuery: Query = _.cloneDeep(query);
    if (_.isArray(queryNodeName)) {
      for (const name of queryNodeName) {
        newQuery[(name + 'Node') as QueryNodes] = undefined;
      }
    } else {
      newQuery[(queryNodeName + 'Node') as QueryNodes] = undefined;
    }
    setQuery(newQuery);
  };

  /**
   * Removes field from query by type
   * @param fieldName
   * @param nodeType
   * @return {Query} instance without field to remove;
   */

  const removeFieldFromNode = (
    fieldName: string,
    nodeType: QueryNodeNames.select | QueryNodeNames.sort,
  ): Query => {
    switch (nodeType) {
      case QueryNodeNames.select:
        const field = query.selectNode.fields.find(
          (field: string | AggregateFunctionNode) => {
            if (field instanceof AggregateFunctionNode) {
              return `${field.function}(${field.field})` === fieldName;
            }
            return fieldName === field;
          },
        );
        if (field) {
          const newFields = query.selectNode.fields.filter(
            (field: string | AggregateFunctionNode) => {
              if (field instanceof AggregateFunctionNode) {
                return `${field.function}(${field.field})` !== fieldName;
              }
              return fieldName !== field;
            },
          );
          const newQuery = _.cloneDeep(query);
          if (newFields.length > 0) {
            newQuery.selectNode.fields = newFields;
          } else {
            newQuery.selectNode = undefined;
          }
          // if no aggregate nodes left, remove GroupBy node
          if (
            newFields.reduce(
              (acc: number, curr: string | AggregateFunctionNode) => {
                return curr instanceof AggregateFunctionNode ? acc + 1 : acc;
              },
              0,
            ) === 0
          ) {
            newQuery.groupNode = undefined;
          }
          return newQuery;
        }

        break;
      case QueryNodeNames.sort:
        if (query.sortNode.sortOptions.hasOwnProperty(fieldName)) {
          const newQuery = _.cloneDeep(query);
          delete newQuery.sortNode.sortOptions[fieldName];
          if (_.size(newQuery.sortNode.sortOptions) === 0) {
            newQuery.sortNode = undefined;
          }
          return newQuery;
        }
        break;
      default:
        console.error(`Unknown node type (${nodeType})`);
        return new Query({});
    }
    return new Query({});
  };

  if (loading) {
    return (
      <Box width="100%" height="50%">
        <Spinner />
      </Box>
    );
  }

  return (
    <div className="d-flex flex-column mb-3 w-100" tabIndex={10}>
      <div className="d-flex flex-column m-0 w-100 non-query-editors">
        <div className="p-0">
          <DropToRemoveNodeField
            onNodeFieldRemove={(fieldName, nodeType) => {
              const query = removeFieldFromNode(fieldName, nodeType);
              setQuery(query);
            }}
          />
        </div>
        <div className="d-flex mt-1">
          <div className="col-4 p-0">
            <PossibleNodeFields
              fieldNames={fieldNames}
              onSelectNodesAdd={onSelectNodeChange}
              onSortNodesAdd={(fields) => {
                onSortNodeChange(
                  fields.reduce(
                    (acc, curr) => ({
                      ...acc,
                      [curr]: 1,
                    }),
                    {},
                  ),
                );
              }}
              onAggregateNodeSelect={addAggregateNode}
            />
          </div>
          <div className="col-4 p-0">
            <SelectNodeEditor
              node={query.selectNode as Select}
              onRemove={() =>
                removeNode([QueryNodeNames.select, QueryNodeNames.group])
              }
              onSelectNodeChange={onSelectNodeChange}
              fieldNames={fieldNames}
              idField={idField}
            />
          </div>
          <div className="col-4 p-0">
            <SortNodeEditor
              node={query.sortNode as Sort}
              onRemove={() => removeNode(QueryNodeNames.sort)}
              onSortNodeChange={onSortNodeChange}
            />
          </div>
        </div>
      </div>
      <QueryNodeEditor
        currentQueryNode={query.queryNode}
        fieldNames={fieldNames}
        setQueryNode={(node) => {
          const newQuery = setQueryNode(node, query);
          setQuery(newQuery);
        }}
      />
      <div className="d-flex">
        <div className="col-6">
          <SaveFilterDialog />
        </div>
        <div className="col-6">
          <Button
            id="apply-filter"
            color="primary"
            block
            onClick={() => onApplyQuery(query)}
          >
            Apply filter
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FilterEditor;
