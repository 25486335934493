import { Bag } from '../../api-clients/types';
import ApiClientsBags from '../../api-clients/api-clients-bags';

export const useMutateDeal = () => {
  const handleUpdateDeal = async (
    id: string,
    data: Partial<Omit<Bag, 'id' | 'positions'>>,
  ) => {
    await new ApiClientsBags().update(id, data);
  };

  return {
    handleUpdateDeal,
  };
};
