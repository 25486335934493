import React, { FC } from 'react';
import { Page, Document, Text, StyleSheet } from '@react-pdf/renderer';
import { Item } from './IdentifiedItemInfo';

interface PdfDocumentProps {
  item: Item | null;
}

const styles = StyleSheet.create({
  text: {
    fontWeight: 'thin',
    fontSize: 9,
  },
});

const PdfDocument: FC<PdfDocumentProps> = ({ item }) => {
  return (
    <Document>
      <Page size="A10">
        <Text style={styles.text}>MPN: {item?.mpn}</Text>
        <Text style={styles.text}>Brand: {item?.brand}</Text>
        {item?.csns.map(({ supplier, csn }) => (
          <Text style={styles.text} key={csn}>{`${supplier} csn ${csn}`}</Text>
        ))}
      </Page>
    </Document>
  );
};

export default PdfDocument;
