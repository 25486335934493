import React from 'react';
import AbstractEditor, { IEditorProps } from './AbstractEditor';
import Tags from '../../../../../../UI/Tags';
import { EditorContext } from '../../../../../../layout/AppContainer/AppContainer';

interface IState {
  tags: Array<string>;
}

class TagsEditor extends AbstractEditor<IState> {
  static contextType = EditorContext;
  constructor(props: IEditorProps) {
    super(props);
    this.state = {
      tags: !props.value
        ? []
        : typeof props.value === 'string'
        ? JSON.parse(props.value)
        : props.value,
    };
  }

  getValue() {
    const { tags } = this.state;
    const stringifyTags =
      typeof this.context.tagsUpdaterParams.parsedFormat === 'boolean'
        ? !this.context.tagsUpdaterParams.parsedFormat
        : true;

    return {
      [this.props.column.key]:
        tags.length > 0 ? (stringifyTags ? JSON.stringify(tags) : tags) : null,
    };
  }

  getInputNode(): HTMLInputElement | null | undefined {
    return undefined;
  }

  render() {
    return (
      <Tags
        autoFocus
        initialTags={this.state.tags}
        onChange={(tags) => this.setState({ tags })}
      />
    );
  }
}

export default TagsEditor;
