import React, { FC } from 'react';

interface GoogleIconProps {
  width?: number;
  height?: number;
}
const GoogleIcon: FC<GoogleIconProps> = ({ width = 20, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1713 9.30776H17.5V9.27317H10V12.6065H14.7096C14.0225 14.5469 12.1763 15.9398 10 15.9398C7.23876 15.9398 5.00001 13.7011 5.00001 10.9398C5.00001 8.17859 7.23876 5.93984 10 5.93984C11.2746 5.93984 12.4342 6.42067 13.3171 7.20609L15.6742 4.84901C14.1858 3.46192 12.195 2.60651 10 2.60651C5.39792 2.60651 1.66667 6.33776 1.66667 10.9398C1.66667 15.5419 5.39792 19.2732 10 19.2732C14.6021 19.2732 18.3333 15.5419 18.3333 10.9398C18.3333 10.3811 18.2758 9.83567 18.1713 9.30776Z"
        fill="#FFC107"
      />
      <path
        d="M2.6275 7.06109L5.36542 9.06901C6.10625 7.23484 7.90042 5.93984 10 5.93984C11.2746 5.93984 12.4342 6.42067 13.3171 7.20609L15.6742 4.84901C14.1858 3.46192 12.195 2.60651 10 2.60651C6.79917 2.60651 4.02334 4.41359 2.6275 7.06109Z"
        fill="#FF3D00"
      />
      <path
        d="M10 19.2732C12.1525 19.2732 14.1083 18.4494 15.5871 17.1098L13.0079 14.9273C12.1713 15.5611 11.1313 15.9398 10 15.9398C7.8325 15.9398 5.99209 14.5577 5.29875 12.629L2.58125 14.7227C3.96042 17.4215 6.76125 19.2732 10 19.2732Z"
        fill="#4CAF50"
      />
      <path
        d="M18.1713 9.30778H17.5V9.27319H10V12.6065H14.7096C14.3796 13.5386 13.78 14.3424 13.0067 14.9278C13.0071 14.9274 13.0075 14.9274 13.0079 14.9269L15.5871 17.1094C15.4046 17.2753 18.3333 15.1065 18.3333 10.9399C18.3333 10.3811 18.2758 9.83569 18.1713 9.30778Z"
        fill="#1976D2"
      />
    </svg>
  );
};

export default GoogleIcon;
