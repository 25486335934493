import React, { FC, Suspense, useContext, useEffect, useState } from 'react';
import { Spinner } from '../../../../UI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import './exerciser.css';
import JSONATAModal from '../../../../UI/JSONATAModal';
import { zIndexes } from '../../../../themeProvider';
import { IconButton } from '@material-ui/core';
import { JSONataContext } from './JSONataContext';

const Exerciser = React.lazy(() => import('./Exerciser'));

export type JSONataPayload =
  | Record<string, unknown>
  | Array<any>
  | string
  | number;
type Size = {
  width: number;
  height: number;
};
interface IProps {
  isDisabled?: boolean;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  data?: JSONataPayload;
  width?: number;
  height?: number;
  iconWidth?: number;
  iconHeight?: number;
  isVisible: boolean;
  title?: string;
}

export const JSONataInteropModal: FC<IProps> = () => {
  const [size, setSize] = useState<Size>({
    width: window.innerWidth,
    height: window.innerHeight - 115,
  });
  const { data, isOpen, setIsOpen } = useContext(JSONataContext)!;

  const toggle = () => {
    const isNotOpened = !isOpen;

    console.trace('toogle');
    setIsOpen(isNotOpened);
  };

  const displayClass = isOpen ? '' : ' d-none';

  const handleResize = () => {
    setSize({
      width: window.innerWidth,
      height: window.innerHeight - 115,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      console.log('unmount');
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <JSONATAModal>
      <div
        id="exerciser"
        className={displayClass}
        style={{
          left: 0,
          top: 0,
          position: 'absolute',
          marginTop: 53,
          width: size.width,
          height: size.height,
          zIndex: zIndexes.biggest,
        }}
      >
        <IconButton
          className="position-absolute link-hover"
          style={{
            right: 10,
            top: 2,
            margin: 'auto 5px',
            padding: 0,
          }}
          onClick={toggle}
        >
          <FontAwesomeIcon icon={faTimes} size="2x" color="white" />
        </IconButton>
        {!isOpen ? null : (
          <Suspense fallback={<Spinner />}>
            <Exerciser key={JSON.stringify(data)} data={data} />
          </Suspense>
        )}
      </div>
    </JSONATAModal>
  );
};
