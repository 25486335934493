import React, { Component } from 'react';
import HttpDatastore from 'rollun-ts-datastore';
import { Query, Eq } from 'rollun-ts-rql';
import { Spinner, ErrorView } from '../../../UI';
import SortableTree, { ExtendedNodeData, TreeItem } from 'react-sortable-tree';
import {
  httpErrorHandler,
  optimizeChanges,
  rowTableDataToTree,
} from '../../../utils/common.utils';
import { FlatTreeItem } from '../../EditableTree/components/EditableTree';
import PrivilegeControlButton, {
  PrivilegeStatus,
} from './PrivilegeControlButton';
import { ComponentAction, ErrorType } from '../../../utils/common.types';
import { randomString } from 'rollun-ts-utils/dist';
import MuiButton from '../../../UI/MuiButton';

export interface Rule {
  id: string;
  role_id: string;
  resource_id: string;
  privilege_id: string;
  allow_flag: string;
  status: PrivilegeStatus;
  isImplicit: boolean;
}

interface Role {
  id: string;
  name: string;
  parent_id: string | null;
}

interface IProps {
  resourceId: string;
}

type Change = {
  id: string;
  action: 'create' | 'update' | 'delete';
};

interface IState {
  action: ComponentAction;
  error: ErrorType;
  changes: Change[];
}

export const RULES_DS = 'api/datastore/ruleDataStore';
export const ROLES_DS = 'api/datastore/roleDataStore';

/**
 * Service for editing ACL rules for all resources
 * Basically it is simple Table, but with special editor for every resource
 */

class RulesTreeEditor extends Component<IProps, IState> {
  state: IState = {
    action: ComponentAction.LOADED,
    error: { code: -1, text: '' },
    changes: [],
  };

  isComponentMounted = false;

  rolesTree: Array<TreeItem> = [];

  rulesData: Array<Rule> = [];
  rolesData: Array<Role> = [];

  PRIVILEGES_LIST = ['POST', 'GET', 'PUT', 'DELETE', 'HEAD'];

  rulesDS = new HttpDatastore<Rule>(RULES_DS);
  rolesDS = new HttpDatastore<Role>(ROLES_DS);

  componentDidMount(): void {
    this.isComponentMounted = true;
    this.fetchData().then();
  }

  componentWillUnmount(): void {
    this.isComponentMounted = false;
  }

  fetchData = async () => {
    this.setState({ action: ComponentAction.LOADING });
    try {
      const roles = await this.fetchRoles();
      const rules = await this.fetchRules();
      // Prevent updating unmounted component
      if (this.isComponentMounted) {
        this.rolesTree = rowTableDataToTree(
          (roles as unknown) as FlatTreeItem[],
          { labelField: 'name' },
        );
        this.rolesData = roles;
        this.rulesData = rules;
        this.setState({ action: ComponentAction.LOADED });
      }
    } catch (e) {
      httpErrorHandler(e, (code, text) =>
        this.setState({ action: ComponentAction.ERROR, error: { code, text } }),
      );
    }
  };

  fetchRoles = () => {
    return this.rolesDS.query(new Query({}));
  };

  fetchRules = () => {
    return this.rulesDS.query(
      new Query({
        query: new Eq('resource_id', this.props.resourceId),
      }),
    );
  };

  _getRoleIdByName = (name: string) => {
    // if role is not found in rolesData assume title as id
    const role = this.rolesData.find((role) => role.name === name) || {
      id: name as string,
    };
    return role.id;
  };

  _renderNodePrivilegesControls = (data: ExtendedNodeData) => {
    const { title } = data.node;
    const currentRoleId = this._getRoleIdByName(title as string);
    const currentRoleRules = this.rulesData.filter(
      (rule) => rule.role_id === currentRoleId,
    );
    const buttons = this.PRIVILEGES_LIST.map((privilege) => {
      let status: PrivilegeStatus = PrivilegeStatus.NOT_SET;
      const explicitRule = currentRoleRules.find(
        (rule) =>
          rule.role_id === currentRoleId &&
          rule.privilege_id === privilege &&
          !rule.isImplicit,
      );
      if (explicitRule) {
        status =
          explicitRule.allow_flag === '1'
            ? PrivilegeStatus.EXPLICIT_ENABLED
            : PrivilegeStatus.EXPLICIT_DISABLED;
        // set status to original rules data, to keep track of parent nodes status
        this.rulesData = this.rulesData.map((rule) =>
          rule.id === explicitRule.id ? { ...rule, status } : rule,
        );
      } else {
        const { parentNode } = data;
        if (parentNode) {
          const { title: parentTitle } = parentNode;
          const parentNodeRule = this.rulesData.find(
            (rule) =>
              rule.role_id === parentTitle && rule.privilege_id === privilege,
          );
          if (parentNodeRule) {
            if (
              parentNodeRule.status === PrivilegeStatus.EXPLICIT_ENABLED ||
              parentNodeRule.status === PrivilegeStatus.IMPLICIT_ENABLED
            ) {
              status = PrivilegeStatus.IMPLICIT_ENABLED;
            } else if (
              parentNodeRule.status === PrivilegeStatus.EXPLICIT_DISABLED ||
              parentNodeRule.status === PrivilegeStatus.IMPLICIT_DISABLED
            ) {
              status = PrivilegeStatus.IMPLICIT_DISABLED;
            } else {
              status = PrivilegeStatus.NOT_SET;
            }
            // add implicit node to rulesData to be able to check parent node status even if
            // there is no such rule in DS
            this.rulesData.push({
              id: randomString(),
              status,
              isImplicit: true,
              privilege_id: privilege,
              allow_flag: '1',
              role_id: title as string,
              resource_id: this.props.resourceId,
            });
          }
        }
      }
      const ruleId = explicitRule ? explicitRule.id : null;
      return (
        <PrivilegeControlButton
          resourceId={this.props.resourceId}
          roleId={currentRoleId}
          ruleId={ruleId}
          name={privilege}
          status={status}
          onChange={(change: Change) =>
            this.setState(({ changes }) => ({
              changes: [...(changes || []), change],
            }))
          }
        />
      );
    });
    return { buttons };
  };

  saveChanges = async () => {
    this.setState({ action: ComponentAction.LOADING });
    this.setState({ changes: [] });
    const { changes } = this.state;
    console.log('changes', changes);
    try {
      for (const change of optimizeChanges(changes)) {
        const res =
          change.action === 'delete'
            ? await this.rulesDS.delete(change.id)
            : await this.rulesDS[change.action](
                this.changeWithoutAction(change),
              );
        console.log('change results', res);
      }
      await this.fetchData();
    } catch (e) {
      httpErrorHandler(e, (code, text) =>
        this.setState({
          action: ComponentAction.ERROR,
          error: {
            code,
            text,
          },
        }),
      );
    }
  };

  changeWithoutAction = (change: Change) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { action, ...rest } = change;
    return rest;
  };

  render() {
    const { action, error, changes } = this.state;
    if (action === ComponentAction.LOADING) {
      return (
        <div className="my-5">
          <Spinner />
        </div>
      );
    }

    if (action === ComponentAction.ERROR) {
      return (
        <ErrorView error={error}>
          <div className="d-flex justify-content-center">
            <MuiButton color="primary" onClick={() => this.fetchData()}>
              Back
            </MuiButton>
          </div>
        </ErrorView>
      );
    }

    return (
      <div>
        <h3 className="ml-5">Legend</h3>
        <div className="row mx-3 my-2">
          {[
            { className: 'bg-primary', title: 'Explicit enabled' },
            { className: 'bg-primary opacity-0-5', title: 'Inherited enabled' },
            { className: 'bg-danger', title: 'Explicit disabled' },
            { className: 'bg-danger opacity-0-5', title: 'Inherited disabled' },
            { className: 'bg-secondary', title: 'Not Set' },
          ].map((item) => (
            <div className="d-flex m-1" key={item.title}>
              <div
                className={`border ${item.className}`}
                style={{ width: 50, borderRadius: 3 }}
              />
              <div className="mx-2"> - {item.title}</div>
            </div>
          ))}
        </div>
        <MuiButton
          color="success"
          style={{ marginLeft: 12 }}
          onClick={this.saveChanges}
          disabled={changes.length === 0}
        >
          Save changes {changes.length !== 0 ? `(${changes.length})` : ''}
        </MuiButton>
        <SortableTree
          isVirtualized={true}
          treeData={this.rolesTree}
          canDrag={() => false}
          style={{ height: 700 }}
          getNodeKey={({ node }: { node: TreeItem }) => node.node_id}
          onChange={(treeData: Array<TreeItem>) => {
            console.log('change', treeData);
          }}
          onMoveNode={({ node, nextPath }: any) => {
            console.log('move', node, nextPath);
          }}
          generateNodeProps={this._renderNodePrivilegesControls}
        />
      </div>
    );
  }
}

export default RulesTreeEditor;
