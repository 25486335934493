import { Client } from '../../api-clients/types';
import { apiClients } from '../../api-clients/api-clients-clients';

export const useMutateClient = () => {
  const handleUpdateClient = async (id: string, data: Partial<Client>) => {
    await apiClients.update(id, data);
  };

  return {
    handleUpdateClient,
  };
};
