import HttpDatastore from 'rollun-ts-datastore';
import { In, Query, Select } from 'rollun-ts-rql';
import { fetchCategories } from './getFileExchangeByRollunId';
import _ from 'lodash';
import { UniformSupplier } from './index';

const fetchItemsDataBySupplierName = async (
  rids: string[],
  datastore: HttpDatastore,
  fields: string[],
) => {
  return datastore.query(
    new Query({
      select: new Select(fields),
      query: new In('rollun_id', rids),
    }),
  );
};

const prices = new HttpDatastore('/api/datastore/DataForFileExchange');
export const fetchCommonItemsData = async (
  rids: Array<string>,
): Promise<Array<any>> => {
  if (rids.length === 0) return [];
  return prices.query(
    new Query({
      query: new In('id', rids),
    }),
  );
};

const fetchAndFormatCommonDataBySupplier = async (
  rids: string[],
  datastore: HttpDatastore,
  fields: string[],
) => {
  return Promise.all([
    fetchItemsDataBySupplierName(rids, datastore, fields),
    fetchCommonItemsData(rids),
  ]);
};

const fetchAndFormatCategories = async (
  categories: any[],
  formatName: string,
) => {
  const formatedCategories = categories.map(({ rollun_id, ...item }) => ({
    rollun_id,
    name: item[formatName],
  }));

  return fetchCategories(formatedCategories);
};

const fetchCategoriesWithCompatiblesBySupplier = async (
  rids: string[],
  datastore: HttpDatastore,
  supplierName: UniformSupplier,
  fields: string[],
  formatName: string,
) => {
  const [itemData, prices] = await fetchAndFormatCommonDataBySupplier(
    rids,
    datastore,
    fields,
  );

  const categories = await fetchAndFormatCategories(itemData, formatName);

  const itemsWithPrices = _.map(itemData, (data) =>
    _.merge(data, _.find(prices, { id: data.rollun_id })),
  );

  const finalData = _.map(itemsWithPrices, (data) =>
    _.merge(data, _.find(categories, { rollun_id: data.rollun_id })),
  );

  return finalData;
};

export default fetchCategoriesWithCompatiblesBySupplier;
