import { Box, Card, Typography } from '@material-ui/core';
import MuiIconButton from '../../../../../../../UI/MuiIconButton';
import React, { FC } from 'react';

interface LabelCardProps {
  labelName: string;
  removeLabel: () => void;
  className?: string;
}

export const LabelCard: FC<LabelCardProps> = ({
  labelName,
  removeLabel,
  className = '',
}) => {
  return (
    <Card
      className={className}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box
        style={{
          padding: '10px',
          textOverflow: 'ellipsis',
        }}
      >
        <Typography
          style={{
            textOverflow: 'ellipsis',
            maxWidth: '140px',
            overflow: 'hidden',
          }}
        >
          {labelName}
        </Typography>
      </Box>
      <MuiIconButton onClick={removeLabel} color="error" iconName="times" />
    </Card>
  );
};
