import React, { FC } from 'react';
import PasteUploaderEdit from './PasteUploader.edit';
import Card from '@material-ui/core/Card';
import { Box, useTheme } from '@material-ui/core';
import MuiIconButton from '../../../../../UI/MuiIconButton';
import { GridParams } from '../../../../AbstractService';
import AddableIconButton from './AddableIconButton';
import { TagsUpdaterEditor } from './TagsUpdaterEditor';

interface TableButtonsEditProps {
  gridParams: GridParams;
  deleteByPath: (name: string) => void;
  updateByPath: (name: string, newValue: any) => void;
}

const TableButtonsEdit: FC<TableButtonsEditProps> = ({
  gridParams,
  deleteByPath,
  updateByPath,
}) => {
  const theme = useTheme();

  const handlePasteUploaderUpdate = (params: any, path?: string) => {
    const absolutePath = `appParams.gridParams.pasteUploaderParams${
      path || ''
    }`;
    params ? updateByPath(absolutePath, params) : deleteByPath(absolutePath);
  };

  return (
    <Card>
      <Box display="flex" justifyContent="space-between" marginY={1}>
        <Box display="flex" style={{ gap: theme.spacing(1) }}>
          <MuiIconButton color="primary" iconName="filter" />
          <MuiIconButton color="primary" iconName="sync-alt" />
          <MuiIconButton color="success" iconName="plus-square" />
          <AddableIconButton
            name="enableDeleteItem"
            icon="trash-alt"
            color="error"
            value={gridParams.enableDeleteItem}
            defaultValue={true}
            deleteByPath={deleteByPath}
            updateByPath={updateByPath}
          />
          <AddableIconButton
            name="enableDeleteAll"
            icon="radiation-alt"
            color="error"
            value={gridParams.enableDeleteAll}
            defaultValue={true}
            deleteByPath={deleteByPath}
            updateByPath={updateByPath}
          />
          <MuiIconButton color="primary" iconName="download" />
          <PasteUploaderEdit
            pasteUploaderParams={gridParams.pasteUploaderParams}
            onUpdate={handlePasteUploaderUpdate}
          />
          <AddableIconButton
            name="userNote"
            icon="info-circle"
            color="info"
            value={gridParams.userNote}
            defaultValue="Awesome Table!"
            deleteByPath={deleteByPath}
            updateByPath={updateByPath}
          />
          <TagsUpdaterEditor
            tagsUpdaterParams={gridParams.tagsUpdaterParams}
            deleteByPath={deleteByPath}
            updateByPath={updateByPath}
          />
        </Box>
      </Box>
    </Card>
  );
};

export default TableButtonsEdit;
