import React from 'react';
import { ParcelType } from '../utils/types';
import { Card } from '../../../UI';

interface IProps {
  parcel: ParcelType;
  onSelect?: (parcel: ParcelType) => void;
  showDataTime?: boolean;
}

const ParcelCard: React.FC<IProps> = ({ parcel, onSelect, showDataTime }) => {
  const {
    id,
    title,
    created_datetime,
    last_updated_datetime,
    parcel_numbers = 'empty',
    track_numbers = 'empty',
  } = parcel;
  return (
    <Card
      className="w-100 my-2 cursor-pointer"
      noMargin
      style={{ fontSize: '1rem' }}
      onClick={() => onSelect && onSelect(parcel)}
    >
      <div className="card-body">
        <div className="card-title d-flex">
          {title.trim() ? (
            <>
              <div className="col-6">
                <p className="font-weight-bold">Title:</p>
                <p className="ml-2">{title}</p>
              </div>
              <div className="col-6">
                <p className="font-weight-bold mb-1">ID</p>
                <p className="mb-2">{id}</p>
              </div>
            </>
          ) : null}
        </div>
        {showDataTime ? (
          <>
            <div className="row my-2">
              <div className="col-6">
                <p className="font-weight-bold mb-1">Created at:</p>
                <p className="mb-2">
                  {new Date(+created_datetime * 1000).toDateString()}
                </p>
              </div>
              <div className="col-6">
                <p className="font-weight-bold mb-1">Updated at:</p>
                <p className="mb-2">
                  {new Date(+last_updated_datetime * 1000).toDateString()}
                </p>
              </div>
            </div>
          </>
        ) : null}
        <div className="row mb-3">
          <div className="col-md-6">
            {parcel_numbers ? (
              <div>
                <p className="font-weight-bold">Parcel numbers</p>
                <p>{parcel_numbers}</p>
              </div>
            ) : (
              <p className="font-weight-bold">No parcels numbers</p>
            )}
          </div>
          <div className="col-md-6">
            {track_numbers ? (
              <div className="mt-2">
                <p className="font-weight-bold">Track numbers</p>
                <p>{track_numbers}</p>
              </div>
            ) : (
              <p className="font-weight-bold">No track numbers</p>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ParcelCard;
