import React from 'react';
import AppContainer from '../../layout/AppContainer/AppContainer';
import AbstractService, { AbstractServiceProps } from '../AbstractService';
import { Bpmn } from './components/Bpmn';

export class BpmnPage extends AbstractService<AbstractServiceProps> {
  render() {
    const { user, role } = this.props.userIdentity;
    return (
      <AppContainer
        disableLeftMenu
        currentResourceName={this.props.resourceName}
        services={this.props.availableServices}
        user={user}
        role={role}
        fullHeight
      >
        <Bpmn />
      </AppContainer>
    );
  }
}
