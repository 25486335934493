import React, { ChangeEvent, Component, KeyboardEvent } from 'react';
import HttpDatastore from 'rollun-ts-datastore';
import { formatDate } from '../utils';
import { Button, Spinner, Dialog, ErrorView } from '../../../UI';
import { ErrorType, Senders, Supplier } from '../../../utils/common.types';
import { httpErrorHandler } from '../../../utils/common.utils';
import { RollunCatalogRow, SupplierMappingRow } from '../utils/types';
import { And, Select, Eq, Query } from 'rollun-ts-rql';
import { BarcodeScanner } from '../../../lib/barcode-scanner/BarcodeScanner';

enum ExcessGoodsAction {
  PENDING,
  SCAN_EXCESS,
  ERROR,
  EMPTY,
}

enum ExcessScanTypes {
  PRODUCT_WITH_MPN = 'Manufacturer part number',
  PRODUCT_WITH_RM_PN = 'Rocky mountain part number',
  PRODUCT_WITH_PU_PN = 'Parts Unlimited part numbers',
  PRODUCT_WITH_TR_PN = 'Tucker Rocky part numbers',
  SHIPPING_LABEL = 'Shipping Label',
}

interface IProps {
  orders: Array<string>;
  senderName: Senders;

  onGoodsProcessingComplete(): void;
}

interface IState {
  action: ExcessGoodsAction;
  scannedExcess: Array<string>;
  inputBarcode: string;
  selectedExcessType: ExcessScanTypes;
  modalOpen: boolean;
  error: ErrorType;
}

class ScanExcess extends Component<IProps, IState> {
  excessOptions = [
    ExcessScanTypes.PRODUCT_WITH_MPN,
    ExcessScanTypes.PRODUCT_WITH_RM_PN,
    ExcessScanTypes.PRODUCT_WITH_PU_PN,
    ExcessScanTypes.PRODUCT_WITH_TR_PN,
    ExcessScanTypes.SHIPPING_LABEL,
  ];

  constructor(props: IProps) {
    super(props);
    this.state = {
      action: ExcessGoodsAction.SCAN_EXCESS,
      scannedExcess: [],
      inputBarcode: '',
      modalOpen: false,
      selectedExcessType: this.excessOptions[0],
      error: { code: -1, text: '' },
    };
  }

  RMCatalog = new HttpDatastore<any>(
    '/api/datastore/RockyMountainInventoryCacheDataStore',
  );
  PUCatalog = new HttpDatastore<any>(
    '/api/datastore/PartsUnlimitedInventoryCacheDataStore',
  );
  TRCatalog = new HttpDatastore<any>(
    '/api/datastore/TuckerInventoryCacheDataStore',
  );

  excessProductStore = new HttpDatastore(
    '/api/datastore/excessProductsDataStore',
  );
  rollunCatalog = new HttpDatastore<RollunCatalogRow>(
    '/api/datastore/CatalogDataStore',
  );
  supplierMapping = new HttpDatastore<SupplierMappingRow>(
    '/api/datastore/SupplierMappingDataStore',
  );

  excessShippingLabelDataStore = new HttpDatastore(
    '/api/datastore/excessShippingLabelDataStore',
  );
  shippingLabelDataStore = new HttpDatastore(
    '/api/datastore/shippingLabelDataStore',
  );

  scanTypeToSuppMap: { [key in ExcessScanTypes]: Supplier | null } = {
    [ExcessScanTypes.PRODUCT_WITH_MPN]: null,
    [ExcessScanTypes.PRODUCT_WITH_RM_PN]: 'RockyMountain',
    [ExcessScanTypes.PRODUCT_WITH_PU_PN]: 'PartsUnlimited',
    [ExcessScanTypes.PRODUCT_WITH_TR_PN]: 'TuckerRocky',
    [ExcessScanTypes.SHIPPING_LABEL]: null,
  };

  scanTypeToSupplier(type: ExcessScanTypes): Supplier | null {
    return this.scanTypeToSuppMap[type];
  }

  supplierToScanType(): ExcessScanTypes {
    return ExcessScanTypes.PRODUCT_WITH_MPN;
    // const entry = Object.entries(this.scanTypeToSuppMap).find(
    //   ([, value]) => value === supp,
    // );
    // if (!entry) throw new Error(`Not found scan options for ${supp} supplier`);
    // return entry[0] as ExcessScanTypes;
  }

  async sendCurrentGoodsToStore(inputBarcode: string, status: string) {
    const { modalOpen, action, selectedExcessType, scannedExcess } = this.state;
    const { orders } = this.props;
    if (modalOpen) {
      this.setState({ modalOpen: false });
    }
    if (action !== ExcessGoodsAction.PENDING) {
      this.setState({ action: ExcessGoodsAction.PENDING });
    }
    const date = new Date();

    const excessProduct: { [key: string]: string | number | string[] } = {
      id: date.getTime(),
      orders_id: orders,
      status,
      date: formatDate(date),
      supplier: this.scanTypeToSupplier(selectedExcessType) || '',
    };

    const partNumberFieldName =
      selectedExcessType === ExcessScanTypes.PRODUCT_WITH_MPN
        ? 'manufacturer_part_number'
        : 'supplier_part_number';

    excessProduct[partNumberFieldName] = inputBarcode;
    if (selectedExcessType === ExcessScanTypes.PRODUCT_WITH_MPN) {
      const part_number = await this._getPartNumberByMPN(inputBarcode);
      if (part_number) {
        excessProduct.supplier_part_number = part_number;
      }
    }
    this.excessProductStore
      .create(excessProduct)
      .then((data) => {
        const part_number =
          data.supplier_part_number || data.manufacturer_part_number;
        this.setState({
          action: ExcessGoodsAction.SCAN_EXCESS,
          scannedExcess: scannedExcess.concat(part_number),
          inputBarcode: '',
        });
      })
      .catch((err) =>
        httpErrorHandler(err, (code, text) =>
          this.setState({
            action: ExcessGoodsAction.ERROR,
            error: { code, text },
          }),
        ),
      );
  }

  async _getPartNumberByMPN(mpn: string): Promise<string | null> {
    const mpnFetchers: {
      [key in Supplier]: (mpn: string) => Promise<string | null>;
    } = {
      RockyMountain: async (mpn) => {
        const [item] = await this.RMCatalog.query(
          new Query({
            select: new Select(['prodno']),
            query: new Eq('mf_id', mpn),
          }),
        );
        return item ? item.prodno : null;
      },
      PartsUnlimited: async (mpn) => {
        const [item] = await this.PUCatalog.query(
          new Query({
            select: new Select(['part_number']),
            query: new Eq('vendor_part_number', mpn),
          }),
        );
        return item ? item.part_number : null;
      },
      TuckerRocky: async (mpn) => {
        const [item] = await this.TRCatalog.query(
          new Query({
            select: new Select(['item']),
            query: new Eq('vendor_part', mpn),
          }),
        );
        return item ? item.item : null;
      },
    };

    return mpnFetchers['RockyMountain'](mpn).catch((err) => {
      console.log(err);
      return null;
    });
  }

  isProductExists = async (
    type: ExcessScanTypes,
    barcode: string,
  ): Promise<boolean> => {
    switch (type) {
      case ExcessScanTypes.PRODUCT_WITH_MPN:
        const catalogItem = await this.rollunCatalog.query(
          new Query({
            query: new Eq('manufacturer_part_number', barcode),
          }),
        );
        return catalogItem.length > 0;
      case ExcessScanTypes.PRODUCT_WITH_RM_PN:
      case ExcessScanTypes.PRODUCT_WITH_PU_PN:
      case ExcessScanTypes.PRODUCT_WITH_TR_PN:
        const supplierPNItem = await this.supplierMapping.query(
          new Query({
            query: new And([
              new Eq('supplier_id', barcode),
              new Eq('supplier_name', this.scanTypeToSupplier(type)),
            ]),
          }),
        );
        return supplierPNItem.length > 0;
      default:
        return false;
    }
  };

  checkExcessShippingProduct = (
    selectedExcessType: ExcessScanTypes,
    barcode: string,
  ) => {
    this.isProductExists(selectedExcessType, barcode)
      .then((exists) => {
        exists
          ? this.sendCurrentGoodsToStore(barcode, 'In catalog')
          : this.setState({ modalOpen: true, action: ExcessGoodsAction.EMPTY });
      })
      .catch((err) =>
        httpErrorHandler(err, (code, text) =>
          this.setState({
            action: ExcessGoodsAction.ERROR,
            error: { code, text },
          }),
        ),
      );
  };

  sendExcessLabel = (barcode: string, status: string) => {
    const date = new Date();
    this.excessShippingLabelDataStore
      .create({
        id: date.getTime(),
        shipping_label_id: barcode,
        date: formatDate(date),
        orders_id: this.props.orders,
        status,
      })
      .then(() => {
        this.setState(({ scannedExcess }) => ({
          action: ExcessGoodsAction.SCAN_EXCESS,
          modalOpen: false,
          scannedExcess: scannedExcess.concat(barcode),
          inputBarcode: '',
        }));
      })
      .catch((err) =>
        httpErrorHandler(err, (code, text) =>
          this.setState({
            action: ExcessGoodsAction.ERROR,
            modalOpen: false,
            error: { code, text },
          }),
        ),
      );
  };

  checkShippingLabel = (barcode: string) => {
    this.shippingLabelDataStore
      .has(barcode)
      .then((res) => {
        res
          ? this.sendExcessLabel(barcode, 'In catalog')
          : this.setState({ modalOpen: true, action: ExcessGoodsAction.EMPTY });
      })
      .catch((err) =>
        httpErrorHandler(err, (code, text) =>
          this.setState({
            action: ExcessGoodsAction.ERROR,
            error: { code, text },
          }),
        ),
      );
  };

  handleExcessAccept = (barcode: string) => {
    const { scannedExcess, selectedExcessType } = this.state;
    if (!barcode || scannedExcess.includes(barcode))
      return this.setState({
        action: ExcessGoodsAction.SCAN_EXCESS,
        inputBarcode: '',
      });
    this.setState({ action: ExcessGoodsAction.PENDING, inputBarcode: barcode });
    if (selectedExcessType === ExcessScanTypes.SHIPPING_LABEL) {
      this.checkShippingLabel(barcode);
    } else {
      this.checkExcessShippingProduct(selectedExcessType, barcode);
    }
  };

  renderSwitch(action: ExcessGoodsAction) {
    const { inputBarcode, error } = this.state;

    switch (action) {
      case ExcessGoodsAction.PENDING:
        return (
          <div className="m-5">
            <Spinner />
          </div>
        );
      case ExcessGoodsAction.SCAN_EXCESS:
        return (
          <>
            <h5>Scan excess</h5>
            <div className="stripe">
              <BarcodeScanner
                onResult={this.handleExcessAccept}
                resultFormatter={(code, type) => ({
                  type,
                  code:
                    this.state.selectedExcessType ===
                    ExcessScanTypes.SHIPPING_LABEL
                      ? code.length > 22
                        ? code.slice(code.length - 22)
                        : code
                      : code,
                })}
              />
            </div>
            <div>
              <select
                className="custom-select my-2"
                value={this.state.selectedExcessType}
                onChange={(e) => {
                  this.setState({
                    selectedExcessType: e.target.value as ExcessScanTypes,
                  });
                }}
              >
                {this.excessOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <input
                type="text"
                className="form-control form-control-sm barcode-input mb-2"
                value={inputBarcode}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  this.setState({ inputBarcode: event.target.value });
                }}
                onKeyPress={(event: KeyboardEvent<HTMLInputElement>) => {
                  if (event.key === 'Enter')
                    this.handleExcessAccept(inputBarcode.trim());
                }}
              />
              <Button
                color="primary"
                block
                disabled={!this.state.inputBarcode.trim()}
                onClick={() => {
                  this.handleExcessAccept(inputBarcode.trim());
                }}
              >
                Accept
              </Button>
              <Button
                color="danger"
                block
                onClick={this.props.onGoodsProcessingComplete}
              >
                No more
              </Button>
            </div>
            <div className="mt-2">
              {this.state.scannedExcess.map((good) => (
                <div className="py-1" key={good}>
                  {good}
                </div>
              ))}
            </div>
          </>
        );
      case ExcessGoodsAction.ERROR:
        return (
          <ErrorView error={error}>
            <Button
              color="primary"
              onClick={() =>
                this.setState({ action: ExcessGoodsAction.SCAN_EXCESS })
              }
            >
              Back
            </Button>
          </ErrorView>
        );
      case ExcessGoodsAction.EMPTY:
        return null;
      default:
        return <div>Unknown action: [{ExcessGoodsAction[action]}]</div>;
    }
  }

  handleModalConfirm = () => {
    const barcode = this.state.inputBarcode.trim();
    if (this.state.selectedExcessType === ExcessScanTypes.SHIPPING_LABEL) {
      this.sendExcessLabel(barcode, 'Not in catalog');
    } else {
      this.sendCurrentGoodsToStore(barcode, 'Not in catalog');
    }
  };

  render() {
    const excessItemName =
      this.state.selectedExcessType === ExcessScanTypes.SHIPPING_LABEL
        ? 'Label'
        : 'Product';

    return (
      <>
        <div className="main-layout">
          {this.renderSwitch(this.state.action)}
        </div>
        <Dialog
          title={`No such ${excessItemName.toLocaleLowerCase()} in catalog`}
          options={{ centered: true }}
          isOpen={this.state.modalOpen}
          onDecline={() =>
            this.setState({
              modalOpen: false,
              action: ExcessGoodsAction.SCAN_EXCESS,
              inputBarcode: '',
            })
          }
          onClose={() =>
            this.setState({
              modalOpen: false,
              action: ExcessGoodsAction.SCAN_EXCESS,
            })
          }
          onOk={this.handleModalConfirm}
          submitBtnText="Yes, add"
        >
          <p>
            {excessItemName} with this {this.state.selectedExcessType} (
            {this.state.inputBarcode.trim()}) hasn't been found
          </p>
          <p>Add it anyway?</p>
        </Dialog>
      </>
    );
  }
}

export default ScanExcess;
