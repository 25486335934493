import React from 'react';
import AbstractService, { AbstractServiceProps } from '../AbstractService';
import ErrorView from '../../UI/ErrorView';
import Spinner from '../../UI/Spinner';
import AppContainer from '../../layout/AppContainer/AppContainer';
import EditableTree from './components/EditableTree';

class EditableTreeApp extends AbstractService<AbstractServiceProps> {
  render() {
    const { appConfig, error } = this.state;

    if (error) {
      return (
        <AppContainer services={{}}>
          <div className="w-100 h-100 d-flex flex-column justify-content-center">
            <ErrorView error={error}>
              <div className="d-flex justify-content-center p-3">
                <div
                  className="btn btn-primary cursor-pointer"
                  onClick={this.reloadHome}
                >
                  Home
                </div>
              </div>
            </ErrorView>
          </div>
        </AppContainer>
      );
    }

    if (appConfig === null) {
      return <Spinner />;
    }

    if (!appConfig.appParams.EditableTreeParams) {
      throw new Error(`Filed EditableTreeParams is mandatory in configs.`);
    }

    const { user, role } = this.props.userIdentity;
    const { resourceName } = this.props;
    const {
      datastoreUrl,
      treeNameField,
      treeDataField,
    } = appConfig.appParams.EditableTreeParams;

    return (
      <AppContainer
        user={user}
        role={role}
        currentResourceName={this.props.resourceName}
        services={this.props.availableServices}
      >
        <EditableTree
          dataStoreURL={datastoreUrl}
          appName={resourceName}
          treeNameField={treeNameField}
          treeDataField={treeDataField}
        />
      </AppContainer>
    );
  }
}

export default EditableTreeApp;
