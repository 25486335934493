import { UniformSupplier } from './index';
import { httpErrorHandlerPromised, noop } from '../../../utils/common.utils';
import HttpDatastore from 'rollun-ts-datastore';
import { In, Query, Select } from 'rollun-ts-rql';
import _ from 'lodash';
import {
  fetchInventoryDataFromAu,
  fetchInventoryDataFromPu,
  fetchInventoryDataFromRm,
  fetchInventoryDataFromTr,
} from './fetchDataBySuppliers';

export type Dimensions = {
  id: string;
  title_ebay: string;
  composed_description: string;
  size: string;
  color: string;
};

export type HTMLDescription = {
  id: string;
  description_html: string;
};

export type FeaturedImages = {
  rid: string;
  uploaded_images: { processed: string }[] | null;
};

type UploadedProccesedImages = {
  rid: string;
  uploaded_images: string[];
};

type CentralCatalogMPNs = {
  id: string;
  manufacturer_part_number: string;
};

const DimensionDataStore = new HttpDatastore<Dimensions>(
  '/api/datastore/DimensionStore',
);
const DescriptionDataStore = new HttpDatastore<HTMLDescription>(
  '/api/datastore/ProviderCacheItemsDescriptionStoreView',
);
const FeaturedImagesDataStore = new HttpDatastore<FeaturedImages>(
  '/api/datastore/FeaturedImagesStore',
);
const centralCatalogDatastore = new HttpDatastore<CentralCatalogMPNs>(
  '/api/datastore/CatalogDataStore',
);

export type InventoryData = {
  id: string;
  brand: string;
  mpn: string;
  upc: string;
  category_id: string;
  ct_rollun_price?: string;
  ct_plaisir_price?: string;
  s_quantity: string;
};

const getFieldsBySupplierName = (supplierName: UniformSupplier, data: any) => {
  return {
    brand: data[columnNameBySupplier[supplierName].brand],
    mpn: data[columnNameBySupplier[supplierName].mpn],
    upc: data[columnNameBySupplier[supplierName].upc],
  };
};

const columnNameBySupplier: {
  [key in UniformSupplier]: {
    brand: string;
    mpn: string;
    upc: string;
  };
} = {
  Autodist: {
    brand: 's_brand_id',
    mpn: 'manufacturer_number',
    upc: 'upc',
  },
  RockyMountain: {
    brand: 'brand',
    mpn: 'mf_id',
    upc: 'upc',
  },
  TuckerRocky: {
    brand: 'brand',
    mpn: 'vendor_part',
    upc: 'upc',
  },
  PartsUnlimited: {
    brand: 'brand_name',
    mpn: 'vendor_part_number',
    upc: 'upc_code',
  },
};

const formatFinalData = (
  data: any[],
  supplierName: UniformSupplier,
): InventoryData[] => {
  return data.map((item) => {
    const uniformData = getFieldsBySupplierName(supplierName, item);

    return {
      id: item.rollun_id,
      s_quantity: item.s_quantity,
      category_id: item.category_id,
      ct_plaisir_price: item.ct_plaisir_price,
      ct_rollun_price: item.ct_rollun_price,
      ...uniformData,
    };
  });
};

const getInfoFromInventoryDataStore = async (
  rollunIds: string[],
  supplierName: UniformSupplier,
) => {
  const fetchers: {
    [key in UniformSupplier]: (rollunIds: string[]) => Promise<InventoryData[]>;
  } = {
    Autodist: fetchInventoryDataFromAu,
    RockyMountain: fetchInventoryDataFromRm,
    PartsUnlimited: fetchInventoryDataFromPu,
    TuckerRocky: fetchInventoryDataFromTr,
  };

  return formatFinalData(await fetchers[supplierName](rollunIds), supplierName);
};

const getDataFromDimensionStore = async (
  rollunIds: string[],
): Promise<Dimensions[]> => {
  return DimensionDataStore.query(
    new Query({
      select: new Select(['id', 'title_ebay', 'size', 'composed_description']),
      query: new In('id', rollunIds),
    }),
  );
};

const getFeaturedImages = async (
  rollunIds: string[],
): Promise<UploadedProccesedImages[]> => {
  const featuredImages = await FeaturedImagesDataStore.query(
    new Query({
      query: new In('rid', rollunIds),
    }),
  );

  return featuredImages.map((featuredImage) => {
    if (!featuredImage.uploaded_images) {
      return {
        ...featuredImage,
        uploaded_images: [],
      };
    }

    return {
      ...featuredImage,
      uploaded_images: [
        ...featuredImage.uploaded_images.map(
          (uploadedImage) => uploadedImage.processed,
        ),
      ],
    };
  });
};

const getHtmlDesciption = async (
  rollunIds: string[],
): Promise<HTMLDescription[]> => {
  return DescriptionDataStore.query(
    new Query({
      select: new Select(['id', 'description_html']),
      query: new In('id', rollunIds),
    }),
  );
};

const getMissedMPNs = async (rids: string[]): Promise<CentralCatalogMPNs[]> => {
  return centralCatalogDatastore.query(
    new Query({
      select: new Select(['id', 'manufacturer_part_number']),
      query: new In('id', rids),
    }),
  );
};

const fetchMissedMPNsFromCentralCatalog = async (
  rids: string[],
  progressCB: (p: string) => void = noop,
) => {
  try {
    const result: CentralCatalogMPNs[] = [];
    const ridsInChunks = _.chunk(rids, 100);

    for (const ridsChunk of ridsInChunks) {
      progressCB && progressCB(`Fetching missed mpns for ${rids.length} items`);

      result.push(...(await getMissedMPNs(ridsChunk)));
    }

    return {
      data: result,
      error: null,
    };
  } catch (e) {
    const error = await httpErrorHandlerPromised(e);
    return {
      data: [],
      error,
    };
  }
};

export const fetchUniformDataBySupplierName = async (
  data: Record<UniformSupplier, string[]>,
  progressCB: (p: string) => void = noop,
) => {
  try {
    let result: (Dimensions &
      HTMLDescription &
      UploadedProccesedImages &
      InventoryData & { supplier: UniformSupplier })[] = [];

    for (const supplier in data) {
      const supplierData = data[supplier as UniformSupplier];
      const chunks = _.chunk(supplierData, 100);

      const dimensions: Dimensions[] = [];
      const descriptions: HTMLDescription[] = [];
      const inventoryInfos: InventoryData[] = [];
      const featuredImages: UploadedProccesedImages[] = [];

      for (let i = 0, len = chunks.length; i < len; i++) {
        const chunk = chunks[i];
        progressCB &&
          progressCB(
            `Fetching ${i * 100 + chunk.length} of ${
              supplierData.length
            } items`,
          );

        const [
          currentDimensions,
          currentDescription,
          currentInventoryInfo,
          currentFeaturedImages,
        ] = await Promise.all([
          getDataFromDimensionStore(chunk),
          getHtmlDesciption(chunk),
          getInfoFromInventoryDataStore(chunk, supplier as UniformSupplier),
          getFeaturedImages(chunk),
        ]);

        dimensions.push(...currentDimensions);
        descriptions.push(...currentDescription);
        inventoryInfos.push(...currentInventoryInfo);
        featuredImages.push(...currentFeaturedImages);
      }

      result.push(
        ...supplierData
          .map((rollunId) => {
            const dimension = dimensions.find(
              ({ id }) => id === rollunId,
            ) as Dimensions;
            const description = descriptions.find(
              ({ id }) => id === rollunId,
            ) as HTMLDescription;
            const inventoryInfo = inventoryInfos.find(
              ({ id }) => id === rollunId,
            ) as InventoryData;
            const featuredImage = featuredImages.find(
              ({ rid }) => rid === rollunId,
            ) as UploadedProccesedImages;

            return _.merge(
              dimension,
              description,
              inventoryInfo,
              featuredImage,
              {
                supplier: supplier as UniformSupplier,
              },
            );
          })
          .filter((item) => !!item),
      );
    }

    const ridsWithoutMPN = result.filter(({ mpn }) => !mpn).map(({ id }) => id);

    if (!ridsWithoutMPN.length) {
      return {
        data: result,
        error: null,
      };
    }

    const ridsWithMPNs = await fetchMissedMPNsFromCentralCatalog(
      ridsWithoutMPN,
      progressCB,
    );

    if (ridsWithMPNs.error) {
      throw new Error(ridsWithMPNs.error.text);
    }

    result = result.map((supplierDataObj) => {
      const ridWithMissedMPN = ridsWithMPNs.data.find(
        (ridWithMPN) =>
          ridWithMPN.manufacturer_part_number === supplierDataObj.mpn,
      );

      if (supplierDataObj.mpn || !ridWithMissedMPN) {
        return supplierDataObj;
      }

      return {
        ...supplierDataObj,
        mpn: ridWithMissedMPN.manufacturer_part_number,
      };
    });

    return {
      data: result,
      error: null,
    };
  } catch (e) {
    const error = await httpErrorHandlerPromised(e);
    return {
      data: [],
      error,
    };
  }
};
