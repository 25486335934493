import useHttpDataStore from '../useHttpDatastore';

export interface CatalogItem {
  id: string;
  brand_id: string;
  manufacturer_part_number: string;
  tmp_tags: string[] | null;
}

const useCatalogDatastore = () => {
  return useHttpDataStore<CatalogItem>('/api/datastore/CatalogDataStore');
};

export { useCatalogDatastore };
