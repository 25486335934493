import React, { FC, ReactNode, useState } from 'react';
import { Dialog } from './';

interface IProps {
  children: string | ReactNode | ReactNode[];
  modalBody: string | ReactNode | ReactNode[];
  className?: string;
  modalTitle?: string;
  declineBtnText?: string;
  submitBtnText?: string;
  disabled?: boolean;

  onDecline?(): void;

  onConfirm?(): void;

  onClick?(modalOpenCallback: () => void): void;
}

/**
 * Button with confirmation modal
 * @param props {IProps}
 */

const ConfirmButton: FC<IProps> = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const {
    children,
    modalBody,
    onConfirm,
    onDecline,
    className = '',
    modalTitle,
    onClick,
    declineBtnText = 'Decline',
    submitBtnText = 'Ok',
    disabled,
  } = props;

  const onButtonClick = onClick
    ? () => onClick(() => setModalOpen(true))
    : () => setModalOpen(true);

  return (
    <>
      <button
        className={'btn ' + className}
        onClick={onButtonClick}
        disabled={disabled}
      >
        {children}
      </button>
      {modalOpen ? (
        <Dialog
          isOpen={modalOpen}
          options={{ centered: true }}
          title={modalTitle || 'Confirm'}
          onOk={() => {
            setModalOpen(false);
            if (onConfirm) {
              onConfirm();
            }
          }}
          submitBtnText={submitBtnText}
          declineBtnText={declineBtnText}
          onDecline={() => {
            setModalOpen(false);
            if (onDecline) {
              onDecline();
            }
          }}
          onClose={() => setModalOpen(false)}
        >
          {modalBody}
        </Dialog>
      ) : null}
    </>
  );
};

export default ConfirmButton;
