import React, { FC, useEffect, useState } from 'react';
import {
  ActivityLog,
  useGetActivityLogs,
} from '../../hooks/useGetActivityLogs';
import { Box, Typography } from '@material-ui/core';
import { ErrorView } from '../../../../../../UI';
import { ActivityLogComponent } from './ActivityLogComponent';

const Logs: FC<{ logs?: ActivityLog[] }> = ({ logs }) => {
  if (!logs) {
    return <></>;
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        overflowY: 'auto',
      }}
    >
      {logs.map((log, index) => (
        <ActivityLogComponent key={index} log={log} />
      ))}
    </Box>
  );
};

export const ActivityLogs: FC = () => {
  const { data, isLoading, error } = useGetActivityLogs();
  const [problemLogs, setProblemLogs] = useState<any[]>([]);

  useEffect(() => {
    const res = data?.filter((log) => log.problem);
    setProblemLogs(res || []);
  }, [data]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <ErrorView
        error={{
          text: 'Error while loading activity logs',
          code: 500,
        }}
      />
    );
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
      }}
    >
      {!!problemLogs.length && (
        <>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            Activity Logs with Problems
          </Typography>
          <Typography variant="h6" color="error" style={{ fontWeight: 'bold' }}>
            Total problems quantity: {problemLogs.length}
          </Typography>
          <Logs logs={problemLogs} />
        </>
      )}
      <Typography variant="h5" style={{ fontWeight: 'bold' }}>
        Activity Logs
      </Typography>
      <Logs logs={data} />
    </Box>
  );
};
