import axios from 'axios';
import LifecycleToken from './lifecycle-token';
import { logger } from './logger';

const ignoreUrls = [
  '/api/openapi/LogsIngestor/v1/ingest',
  '/api/openapi/RollunSecretManager/v1',
];

export function axiosWithLogger(tokenStorage: LifecycleToken) {
  axios.interceptors.request.use((config) => {
    const requestToken = tokenStorage.getRequestToken();
    const sessionToken = tokenStorage.getSessionToken();

    const configWithToken = {
      ...config,
      headers: {
        ...config.headers,
        lifecycleToken: requestToken,
        parentLifecycleToken: sessionToken,
      },
    };

    if (!ignoreUrls.some((url) => new RegExp(url).test(config.url || ''))) {
      const method = config.method as string;

      const headers = {
        ...configWithToken.headers.common,
        ...(method in configWithToken.headers &&
          configWithToken.headers[method]),
      };

      logger.info('Request to server', {
        url: config.url,
        method,
        headers,
        data: config.data,
        lifecycleToken: requestToken,
        parentLifecycleToken: sessionToken,
        userIdentity: JSON.parse(
          sessionStorage.getItem('CACHED_USER_IDENTITY') || 'null',
        ),
      });
    }

    return configWithToken;
  });
  axios.interceptors.response.use(
    (response) => {
      const ignoreUrls = ['/api/openapi/LogsIngestor/v1/ingest'];

      if (
        !ignoreUrls.some((url) =>
          new RegExp(url).test(response.config.url || ''),
        )
      ) {
        logger.info('Response from server', {
          url: response.config.url,
          method: response.config.method,
          headers: response.config.headers,
          // data: response.data,
          status: response.status,
          statusText: response.statusText,
          lifecycleToken: response.config.headers.lifecycleToken,
          parentLifecycleToken: response.config.headers.parentLifecycleToken,
          userIdentity: JSON.parse(
            sessionStorage.getItem('CACHED_USER_IDENTITY') || 'null',
          ),
        });
      }

      return response;
    },
    (e) => {
      const ignoreUrls = ['/api/openapi/LogsIngestor/v1/ingest'];

      if (!ignoreUrls.some((url) => new RegExp(url).test(e.config.url || ''))) {
        logger.error('Error from server', {
          url: e.config.url,
          method: e.config.method,
          headers: e.config.headers,
          data: e.response?.data,
          status: e.response?.status,
          statusText: e.response?.statusText,
          lifecycleToken: e.config.headers.lifecycleToken,
          parentLifecycleToken: e.config.headers.parentLifecycleToken,
          userIdentity: JSON.parse(
            sessionStorage.getItem('CACHED_USER_IDENTITY') || 'null',
          ),
        });
      }

      throw e;
    },
  );
}
