import In from 'rollun-ts-rql/dist/nodes/arrayNodes/In';
import Out from 'rollun-ts-rql/dist/nodes/arrayNodes/Out';
import And from 'rollun-ts-rql/dist/nodes/logicalNodes/And';
import Or from 'rollun-ts-rql/dist/nodes/logicalNodes/Or';
import Not from 'rollun-ts-rql/dist/nodes/logicalNodes/Not';
import Alike from 'rollun-ts-rql/dist/nodes/scalarNodes/Alike';
import Like from 'rollun-ts-rql/dist/nodes/scalarNodes/Like';
import Eq from 'rollun-ts-rql/dist/nodes/scalarNodes/Eq';
import Ge from 'rollun-ts-rql/dist/nodes/scalarNodes/Ge';
import Gt from 'rollun-ts-rql/dist/nodes/scalarNodes/Gt';
import Le from 'rollun-ts-rql/dist/nodes/scalarNodes/Le';
import Lt from 'rollun-ts-rql/dist/nodes/scalarNodes/Lt';
import Ne from 'rollun-ts-rql/dist/nodes/scalarNodes/Ne';
import Contains from 'rollun-ts-rql/dist/nodes/scalarNodes/Contains';
import AlikeGlob from 'rollun-ts-rql/dist/nodes/scalarNodes/AlikeGlob';
import LikeGlob from 'rollun-ts-rql/dist/nodes/scalarNodes/LikeGlob';
import AbstractQueryNode from 'rollun-ts-rql/dist/nodes/AbstractQueryNode';
import { Eqf, Eqn, Eqt, Ie } from 'rollun-ts-rql';
import AbstractLogicalNode from 'rollun-ts-rql/dist/nodes/logicalNodes/AbstractLogicalNode';
import _ from 'lodash';

export interface RqlNodeFactoryParams {
  // field name
  field?: string;
  // value for node
  // for Scalar - string | number, for logical - AbstractQueryNode[], for array -> array of string | number
  value?: any;
}

const LOGICAL_NODES: {
  [key: string]: (subNodes: any) => AbstractLogicalNode;
} = {
  and: (subNodes) => new And(subNodes),
  or: (subNodes) => new Or(subNodes),
  not: (subNodes) => new Not(subNodes),
};

const OTHER_NODES: {
  [key: string]: (
    field: string,
    value: string | Array<string> | any,
  ) => AbstractQueryNode;
} = {
  in: (field, value) =>
    new In(field, _.isArray(value) ? value : ('' + value || '').split(',')),
  out: (field, value) =>
    new Out(field, _.isArray(value) ? value : ('' + value || '').split(',')),
  in__break_line_separator: (field, value) =>
    new In(
      field,
      _.isArray(value)
        ? value
        : ('' + value || '')
            .split('\n')
            .map((str: string) => str.trim())
            .filter((str: string) => !!str),
    ),
  out__break_line_separator: (field, value) =>
    new In(
      field,
      _.isArray(value)
        ? value
        : ('' + value || '')
            .split('\n')
            .map((str: string) => str.trim())
            .filter((str: string) => !!str),
    ),
  like: (field, value) => new Like(field, value),
  alike: (field, value) => new Alike(field, value),
  eq: (field, value) => new Eq(field, value),
  ge: (field, value) => new Ge(field, value),
  gt: (field, value) => new Gt(field, value),
  le: (field, value) => new Le(field, value),
  lt: (field, value) => new Lt(field, value),
  ne: (field, value) => new Ne(field, value),
  eqt: (field) => new Eqt(field),
  eqf: (field) => new Eqf(field),
  eqn: (field) => new Eqn(field),
  ie: (field) => new Ie(field),
  contains: (field, value) => new Contains(field, value),
  alikeGlob: (field, value) => new AlikeGlob(field, value),
  likeGlob: (field, value) => new LikeGlob(field, value),
  empty: (field) => new Or([new Eq(field, 'null()'), new Eqn(field)]),
};

/**
 *
 * @param name
 * @param params
 */

function rqlNodeFactory(
  name: string,
  params: RqlNodeFactoryParams,
): AbstractQueryNode {
  const logicalNodeFactory = LOGICAL_NODES[name];
  if (logicalNodeFactory) {
    return logicalNodeFactory(params.value);
  }
  const otherNodeFactory = OTHER_NODES[name];
  if (otherNodeFactory) {
    return otherNodeFactory(params.field || 'like', params.value);
  }
  throw new Error(`Unknown node ${name}`);
}

export default rqlNodeFactory;
