export const prepTsentrPts = [
  'Наталья',
  'MarthaNC',
  'AndriaKY',
  'LatashaTX',
  'MorganUT',
  'DavidNY',
  'CarolynKY',
  'ArianaWI',
];
