import React from 'react';
import { Box } from '@material-ui/core';

export const TabPanel = ({
  value,
  index,
  children,
}: {
  value: number;
  index: number;
  children: any;
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};
