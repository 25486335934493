import React from 'react';
import withControls from './withControls';
import { IRendererProps } from './DefaultRenderer';

const StringDateRenderer: React.FC<IRendererProps> = ({ value }) => {
  if (value) {
    const res = value.match(/\d*-\d*-\d*/);
    return <span>{res}</span>;
  } else {
    return <span>no data</span>;
  }
};
export default withControls(StringDateRenderer);
