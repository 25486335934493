import React, { useEffect, useState } from 'react';
import Description from '../../../UI/Description';
import EditHelpPage from './HelpPage.edit';
import { AppConfig } from '../../AbstractService';
import HttpDatastore from 'rollun-ts-datastore';
import { FrontConfigRow } from '../../RootApp/Root.app';
import { Query, Eq } from 'rollun-ts-rql';
import { ErrorType } from '../../../utils/common.types';
import { Button, ErrorView, Spinner } from '../../../UI';
import { httpErrorHandler } from '../../../utils/common.utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
  initHelpText: string;
  config: AppConfig;
  // name of resource, representing HelpPage.
  resourceName: string;
}

const frontConfigDS = new HttpDatastore<FrontConfigRow>(
  '/api/datastore/frontConfigDataStore',
);

const HelpPageContainer: React.FC<IProps> = ({
  initHelpText,
  config,
  resourceName,
}) => {
  const [isEdit, setEdit] = useState(false);
  const [serviceId, setServiceId] = useState<null | string>(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<null | ErrorType>(null);
  const [helpText, setHelpText] = useState(initHelpText);

  const fetchResourceId = async () => {
    try {
      setError(null);
      setLoading(true);
      // assume, the if u got to  this page, you %100 have config in Table
      const [row] = await frontConfigDS.query(
        new Query({
          query: new Eq('resource', resourceName),
        }),
      );
      setServiceId(row.id);
    } catch (err) {
      httpErrorHandler(err, (code, text) => setError({ code, text }));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchResourceId();
  }, []);

  if (isEdit) {
    return (
      <div>
        <div className="d-flex justify-content-end">
          <Button size="sm" title="Edit page" onClick={() => setEdit(false)}>
            <FontAwesomeIcon icon="cog" />
          </Button>
        </div>
        {error && (
          <ErrorView error={error}>
            <Button color="primary" onClick={() => fetchResourceId()}>
              Try again
            </Button>
          </ErrorView>
        )}
        {isLoading ? (
          <div>
            <Spinner />
          </div>
        ) : (
          serviceId && (
            <EditHelpPage
              config={config}
              onConfigSave={(config) =>
                setHelpText(
                  config.appParams.HelpPageParams
                    ? config.appParams.HelpPageParams.userNote
                    : '',
                )
              }
              serviceID={serviceId}
            />
          )
        )}
      </div>
    );
  }

  return (
    <div>
      <div className="d-flex justify-content-end">
        <Button size="sm" onClick={() => setEdit(true)}>
          <FontAwesomeIcon icon="cog" />
        </Button>
      </div>
      <Description source={helpText} />
    </div>
  );
};

export default HelpPageContainer;
