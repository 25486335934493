import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { FC, useState } from 'react';
import HttpDatastore from 'rollun-ts-datastore';
import { Query, Eq } from 'rollun-ts-rql';

interface DeleteUserProps {
  selectedUser: {
    id: string;
    name: string;
  };
}

const userDataStore = new HttpDatastore('/api/datastore/userDataStore');
const roleDataStore = new HttpDatastore('/api/datastore/userRoleDataStore');

export const DeleteUser: FC<DeleteUserProps> = ({ selectedUser }) => {
  const [open, setOpen] = useState(false);
  const { id: userId, name } = selectedUser;
  const [userName, setUserName] = useState('');

  const deleteUserRoles = async () => {
    const roles = await roleDataStore.query(
      new Query({
        query: new Eq('user_id', userId),
      }),
    );
    return Promise.all(roles.map(({ id }) => roleDataStore.delete(id)));
  };

  const deleteUser = async () => {
    await deleteUserRoles();
    return userDataStore.delete(userId);
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        color="secondary"
        variant="contained"
        style={{
          maxWidth: '160px',
        }}
      >
        Delete user
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          Are you sure that you want to delete this user?
        </DialogTitle>
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
          }}
        >
          <Typography>Write username to confirm deletion: {name}</Typography>
          <TextField
            variant="outlined"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            size="small"
          />
          <Button
            disabled={userName !== name}
            onClick={() => deleteUser()}
            color="secondary"
            variant="contained"
          >
            Confirm deletion
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};
