import React, { ComponentClass, PureComponent, ReactNode } from 'react';
import md5 from 'md5';
import Tags from './Tags';
import MuiButton from './MuiButton';
import { Box, TextField } from '@material-ui/core';
import { RendererName } from '../pages/ServiceConstructor/constants/index';

export interface EditorProps {
  value: string;

  onChange(newValue: string): void;
}

export interface FluidFormField {
  field: string;
  label?: string;
  editor?: ComponentClass<EditorProps> | React.FC<EditorProps>;
  rendererName?: RendererName;

  isEditable?: boolean;
  isDisabled?: boolean;

  validator?(value: string): { valid: boolean; message?: string };
}

export interface FluidFormProps {
  formConfig: FluidFormField[];
  initialValues?: { [key: string]: string } | null;

  onFormSubmit(formData: Record<string, unknown>): void;
}

export interface FormFieldData {
  value: string;
  valid?: boolean;
  message?: string;
}

interface IState {
  fields: { [key: string]: FormFieldData };
  disabled: boolean;
}

const excludeDateFields = ['handling_time'];
// TODO Refactor this component

export default class FluidForm extends PureComponent<FluidFormProps, IState> {
  constructor(props: FluidFormProps) {
    super(props);
    const fields: { [key: string]: FormFieldData } = {};
    if (this.props.initialValues) {
      Object.entries(this.props.initialValues).forEach(([key, value]) => {
        fields[key] = { value };
      });
    }
    this.state = {
      fields,
      disabled: true,
    };
  }

  UNSAFE_componentWillUpdate(_: FluidFormProps, nextState: IState) {
    const { fields } = nextState;

    for (const field in fields) {
      if (!fields.hasOwnProperty(field)) {
        continue;
      }

      for (const key in fields[field]) {
        if (!fields[field].hasOwnProperty(key) || key !== 'valid') {
          break;
        }

        if (!fields[field][key]) {
          this.setState({ disabled: true });
          break;
        }

        this.setState({ disabled: false });
      }
    }
  }

  render(): ReactNode {
    const { disabled } = this.state;
    const { formConfig } = this.props;

    return (
      <Box display="flex" flexDirection="column">
        {formConfig.map((formField) => (
          <div key={formField.field}>
            <Box marginBottom={2}>{this.getEditorForFormField(formField)}</Box>
          </div>
        ))}

        <MuiButton
          id="confirm-filter"
          fullWidth
          color="success"
          disabled={disabled}
          onClick={() => {
            const filterFormData: { [key: string]: string } = {};

            Object.entries(this.state.fields).forEach((entry) => {
              const [key, { value }] = entry;
              filterFormData[key] = value;
            });

            if (
              filterFormData.name &&
              !filterFormData.id &&
              this.state.fields['id']
            ) {
              filterFormData.id = md5(filterFormData.name);
            }

            this.props.onFormSubmit(Object.assign({}, filterFormData));
            this.setState({});
          }}
        >
          Confirm
        </MuiButton>
      </Box>
    );
  }

  private getEditorForFormField(formField: FluidFormField): ReactNode {
    const { fields } = this.state;

    if (formField.editor && formField.rendererName !== 'json') {
      const Editor = formField.editor;

      return (
        <Editor
          value={fields[formField.field] ? fields[formField.field].value : ''}
          onChange={(value: string) => {
            let fieldData: FormFieldData = {
              value,
              valid: true,
            };
            if (formField.validator) {
              fieldData = { ...fieldData, ...formField.validator(value) };
            }
            this.setState({
              fields: { ...fields, ...{ [formField.field]: fieldData } },
            });
          }}
        />
      );
    }

    let formControlType = '';

    if (/tag/i.test(formField.field)) {
      return (
        <Tags
          onChange={(val) =>
            this.setState({
              fields: {
                ...fields,
                [formField.field]: {
                  valid: true,
                  value: JSON.stringify(val),
                },
              },
            })
          }
        />
      );
    }

    if (
      /(date|time)/i.test(formField.field) &&
      !excludeDateFields.includes(formField.field)
    ) {
      formControlType = 'date';
    }

    const fieldIsEmpty = !(
      fields[formField.field] && fields[formField.field].value
    );
    const isValid = fields[formField.field] && fields[formField.field].valid;

    return (
      <TextField
        type={formControlType}
        fullWidth
        variant="outlined"
        label={
          formControlType === 'date' ? '' : formField.label || formField.field
        }
        helperText={fields[formField.field] && fields[formField.field].message}
        error={!fieldIsEmpty && !isValid}
        disabled={formField.isDisabled}
        onChange={(event) => {
          let value: any = event.target.value;
          if (
            formControlType === 'date' &&
            formField.rendererName !== 'stringDate'
          ) {
            value = Math.ceil(+new Date(value) / 1000);
          }
          let fieldData: FormFieldData = {
            valid: true,
            value,
          };
          if (formField.validator) {
            fieldData = { ...fieldData, ...formField.validator(value) };
          }
          this.setState({
            fields: {
              ...fields,
              [formField.field]: fieldData,
            },
          });
        }}
      />
    );
  }
}
