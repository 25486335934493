import { Box, Link, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { getScenarioButtonById } from '../../constants/buttons';
import { getScenarioById } from '../../constants/scenarios';
import { removeObjectFieldByValue } from '../../utils/activity-logs-utils';
import { ActivityLog } from '../../hooks/useGetActivityLogs';
import { ActivityValue } from './ActivityValue';

export const ActionComponent: FC<{ log: ActivityLog }> = ({ log }) => {
  if (['create', 'update'].includes(log.action.type)) {
    return <Typography>{`${log.action.type}d`}</Typography>;
  }

  if (log.action.type === 'button') {
    const button = getScenarioButtonById(log.action.context.button_id);
    return (
      <Typography>
        launched{' '}
        <Link href={button?.url}>{button?.name || 'unknown button'}</Link>
      </Typography>
    );
  }

  if (log.action.type === 'scenario_request') {
    const scenario = getScenarioById(log.action.context.scenario_id);
    return (
      <Typography>
        was set{' '}
        {scenario?.url ? (
          <Link href={scenario.url}>
            {scenario?.name || 'unknown scenario'}
          </Link>
        ) : (
          <>{scenario?.name || 'unknown scenario'}</>
        )}
      </Typography>
    );
  }

  if (
    log.action.type === 'update_megaplan' &&
    !log.event?.entity?.changedFields
  ) {
    const changedFields = Object.entries(
      removeObjectFieldByValue(log.action.context, log.action.entity.rowId),
    );

    return (
      <>
        <Typography>{log.action.type}</Typography>
        {changedFields.map(([field, value], index) => (
          <Box
            style={{
              marginLeft: '8px',
              display: 'flex',
              flexDirection: 'row',
              gap: 4,
            }}
            key={index}
          >
            <Typography>{field}</Typography>
            <Typography style={{ fontWeight: 'bold' }}>
              {'changed value to'}
            </Typography>
            {
              <ActivityValue
                value={
                  typeof value === 'object'
                    ? JSON.stringify(value)
                    : String(value)
                }
              />
            }
          </Box>
        ))}
      </>
    );
  }

  return (
    <Typography>
      {log.action.context.url ? (
        <Link href={log.action.context.url as string}>{log.action.type}</Link>
      ) : (
        <>{log.action.type}</>
      )}
    </Typography>
  );
};
