type UseQueryParamsType = Record<string, string>;

const useQueryParams = (): UseQueryParamsType => {
  const href = window.location.href.split('?');

  if (href.length === 1) {
    return {};
  }

  const [, url] = href;
  const result: Record<string, string> = {};

  for (const param of url.split('&')) {
    const [key, value] = param.split('=');

    result[key] = value;
  }

  return result;
};

export default useQueryParams;
