import React, { Component, ReactNode } from 'react';
import { Button, ErrorView, Spinner } from '../../../../UI';
import { ErrorType } from '../../../../utils/common.types';
import { httpErrorHandler } from '../../../../utils/common.utils';
import axios from 'axios';

interface IState {
  isLoading: boolean;
  error: ErrorType | null;
  result: any | null;
}

interface IProps {
  title: string;
  hookUrl: string;
  renderResponse: (res: any) => ReactNode;
}

class SuppliersMapping extends Component<IProps, IState> {
  state: IState = { isLoading: false, error: null, result: null };

  fetchRollunId = async (mpn: string, brand: string) => {
    const result = await axios.post(this.props.hookUrl, {
      mpn,
      brand,
    });
    return result.data;
  };

  onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ isLoading: true, error: null, result: null });

    try {
      // TODO i dont known how to get data from form event without ts error...
      const result = await this.fetchRollunId(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        e.target.mpn.value,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        e.target.brand.value,
      );
      if (!result || result.error) {
        throw new Error(result ? result.error : 'Mapping not found');
      }
      this.setState({ result });
    } catch (e) {
      httpErrorHandler(e, (code, text) => {
        this.setState({ error: { code, text } });
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading, error, result } = this.state;
    const { title } = this.props;

    if (isLoading) {
      return (
        <div className="py-5">
          <Spinner />
        </div>
      );
    }

    if (error) {
      return (
        <ErrorView error={error}>
          <Button
            color="primary"
            onClick={() => this.setState({ error: null, isLoading: false })}
          >
            Retry
          </Button>
        </ErrorView>
      );
    }

    return (
      <div className="m-2">
        <h5>{title}</h5>
        <form onSubmit={(e) => this.onFormSubmit(e)}>
          <input
            className="form-control my-2"
            name="mpn"
            type="text"
            required
            placeholder="MPN"
          />
          <input
            className="form-control my-2"
            name="brand"
            type="text"
            required
            placeholder="Brand"
          />
          <Button type="submit" color="primary">
            Find
          </Button>
        </form>
        {result && this.props.renderResponse(result)}
      </div>
    );
  }
}

export default SuppliersMapping;
