const calculateQuantityDefault = (warehouseNameToQuantityMap: any) => {
  console.log('warehouseNameToQuantityMap', warehouseNameToQuantityMap);
  return Object.keys(warehouseNameToQuantityMap).reduce(
    (acc, curr) => acc + +warehouseNameToQuantityMap[curr],
    0,
  );
};

const calculateQuantityRof = (warehouseNameToQuantityMap: any) => {
  const keys = Object.keys(warehouseNameToQuantityMap);
  const mainQuantities = keys.filter((key) => key.includes('main'));
  const reserveQuantities = keys.filter((key) => key.includes('reserve'));

  const calculateActualQuantity = (acc: any, curr: any) => {
    // main quantity format - main_tx_qty
    const [, state] = curr.split('_');
    const reserve = reserveQuantities.find((reserve) =>
      reserve.includes(state),
    );

    return (
      acc +
      (+warehouseNameToQuantityMap[curr] -
        +warehouseNameToQuantityMap[reserve as any])
    );
  };

  return mainQuantities.reduce(calculateActualQuantity, 0);
};

export type CreateLinkedDealsSupplierConfig = {
  url: string;
  quantityFields: string[];
  calculateQuantity: (warehouseNameToQuantityMap: any) => number;
  quantityFieldsMapper: (fields: string[]) => string[];
  ridField: string;
  priceField: string;
};

export const SUPPLIER_CONFIG: Record<
  string,
  CreateLinkedDealsSupplierConfig
> = {
  Wps: {
    url: '/api/datastore/WpsInventoryCacheDataStore',
    quantityFields: ['s_quantity'],
    // transform all fields to one -> all
    quantityFieldsMapper: () => ['all'],
    calculateQuantity: calculateQuantityDefault,
    ridField: 'rollun_id',
    priceField: 's_price_in',
  },
  Autodist: {
    url: '/api/datastore/AutodistInventoryCacheDataStore',
    quantityFields: [
      'warehouse_1_quantity',
      'warehouse_2_quantity',
      'warehouse_3_quantity',
    ],
    // transform all fields to one -> all
    quantityFieldsMapper: () => ['all'],
    calculateQuantity: calculateQuantityDefault,
    ridField: 'rollun_id',
    priceField: 's_price_in',
  },
  PartsUnlimited: {
    url: '/api/datastore/PartsUnlimitedInventoryCacheDataStore',
    quantityFields: [
      'nc_availability',
      'nv_availability',
      'ny_availability',
      'tx_availability',
      'wi_availability',
    ],
    // transform field nc_availability -> nc to map with senders
    quantityFieldsMapper: (fields) =>
      fields.map((field) => field.split('_').at(0)) as string[],
    calculateQuantity: calculateQuantityDefault,
    ridField: 'rollun_id',
    priceField: 's_price_in',
  },
  PartsUnlimitedPickupNc: {
    url: '/api/datastore/PartsUnlimitedInventoryCacheDataStore',
    quantityFields: ['nc_availability'],
    // transform field nc_availability -> nc to map with senders
    quantityFieldsMapper: (fields) =>
      fields.map((field) => field.split('_').at(0)) as string[],
    calculateQuantity: calculateQuantityDefault,
    ridField: 'rollun_id',
    priceField: 's_price_in',
  },
  PartsUnlimitedPickupWi: {
    url: '/api/datastore/PartsUnlimitedInventoryCacheDataStore',
    quantityFields: ['wi_availability'],
    // transform field nc_availability -> nc to map with senders
    quantityFieldsMapper: (fields) =>
      fields.map((field) => field.split('_').at(0)) as string[],
    calculateQuantity: calculateQuantityDefault,
    ridField: 'rollun_id',
    priceField: 's_price_in',
  },
  RockyMountain: {
    url: '/api/datastore/RockyMountainInventoryCacheDataStore',
    quantityFields: ['qty_ut', 'qty_ky'],
    // transform field qty_ut -> ut to map with senders
    quantityFieldsMapper: (fields) =>
      fields.map((field) => field.split('_').at(-1)) as string[],
    calculateQuantity: calculateQuantityDefault,
    ridField: 'rollun_id',
    priceField: 's_price_in',
  },
  RockyMountainKit: {
    url: '/api/datastore/RockyMountainInventoryCacheDataStore',
    quantityFields: ['kit_qty'],
    // transform field kit_qty -> kit to map with senders
    quantityFieldsMapper: (fields) =>
      fields.map((field) => field.split('_').at(0)) as string[],
    calculateQuantity: calculateQuantityDefault,
    ridField: 'rollun_id',
    priceField: 's_price_in',
  },
  RockyMountainPickupUt: {
    url: '/api/datastore/RockyMountainInventoryCacheDataStore',
    quantityFields: ['qty_ut'],
    // transform field qty_ut -> pickup_ut to map with senders
    quantityFieldsMapper: (fields) =>
      fields.map((field) => field.split('_').at(-1)) as string[],
    calculateQuantity: calculateQuantityDefault,
    ridField: 'rollun_id',
    priceField: 's_price_in',
  },
  RockyMountainPickupKy: {
    url: '/api/datastore/RockyMountainInventoryCacheDataStore',
    quantityFields: ['qty_ky'],
    // transform field qty_ky -> pickup_ky to map with senders
    quantityFieldsMapper: (fields) =>
      fields.map((field) => field.split('_').at(-1)) as string[],
    calculateQuantity: calculateQuantityDefault,
    ridField: 'rollun_id',
    priceField: 's_price_in',
  },
  Turn14: {
    url: '/api/datastore/TurnInventoryCacheDataStore',
    quantityFields: ['pa_qty', 'in_qty', 'tx_qty', 'nv_qty'],
    quantityFieldsMapper: (fields) =>
      fields.map((field) => field.split('_').at(-1)) as string[],
    calculateQuantity: calculateQuantityDefault,
    ridField: 'rollun_id',
    priceField: 's_price_in',
  },
  // TuckerRocky: {
  //   url: '/api/datastore/TuckerInventoryCacheDataStore',
  //   quantityFields: ['tx_qty', 'pa_qty', 'il_qty', 'fl_qty', 'ca_qty'],
  //   // transform field tx_qty -> tx to map with senders
  //   quantityFieldsMapper: (fields) =>
  //     fields.map((field) => field.split('_').at(0)) as string[],
  //   calculateQuantity: calculateQuantityDefault,
  //   ridField: 'rollun_id',
  //   priceField: 'best_price',
  // },
  // TuckerRockyPickupTx: {
  //   url: '/api/datastore/TuckerInventoryCacheDataStore',
  //   quantityFields: ['tx_qty'],
  //   // transform field tx_qty -> tx to map with senders
  //   quantityFieldsMapper: (fields) =>
  //     fields.map((field) => field.split('_').at(0)) as string[],
  //   calculateQuantity: calculateQuantityDefault,
  //   ridField: 'rollun_id',
  //   priceField: 'best_price',
  // },
  RollunOfficePickupKyRof: {
    url: '/api/datastore/RollunOfficeInventoryCacheDataStore',
    quantityFields: [
      // need to calculate diference between main and reserve qty
      'main_ky_qty',
      'reserve_main_ky_qty',
    ],
    quantityFieldsMapper: (fields) => fields,
    calculateQuantity: calculateQuantityRof,
    ridField: 'rollun_id',
    priceField: 'price',
  },
  RollunOfficePickupUtRof: {
    url: '/api/datastore/RollunOfficeInventoryCacheDataStore',
    quantityFields: [
      // need to calculate diference between main and reserve qty
      'main_ut_qty',
      'reserve_main_ut_qty',
    ],
    quantityFieldsMapper: (fields) => fields,
    calculateQuantity: calculateQuantityRof,
    ridField: 'rollun_id',
    priceField: 'price',
  },
  RollunOfficePickupTxRof: {
    url: '/api/datastore/RollunOfficeInventoryCacheDataStore',
    quantityFields: [
      // need to calculate diference between main and reserve qty
      'main_tx_qty',
      'reserve_main_tx_qty',
    ],
    quantityFieldsMapper: (fields) => fields,
    calculateQuantity: calculateQuantityRof,

    ridField: 'rollun_id',
    priceField: 'price',
  },
  RollunOfficePickupNcRof: {
    url: '/api/datastore/RollunOfficeInventoryCacheDataStore',
    quantityFields: [
      // need to calculate diference between main and reserve qty
      'main_nc_qty',
      'reserve_main_nc_qty',
    ],
    quantityFieldsMapper: (fields) => fields,
    calculateQuantity: calculateQuantityRof,
    ridField: 'rollun_id',
    priceField: 'price',
  },
  RollunOfficePickupNyRof: {
    url: '/api/datastore/RollunOfficeInventoryCacheDataStore',
    quantityFields: [
      // need to calculate diference between main and reserve qty
      'main_ny_qty',
      'reserve_main_ny_qty',
    ],
    quantityFieldsMapper: (fields) => fields,
    calculateQuantity: calculateQuantityRof,
    ridField: 'rollun_id',
    priceField: 'price',
  },
  RollunOfficePickupWiRof: {
    url: '/api/datastore/RollunOfficeInventoryCacheDataStore',
    quantityFields: [
      // need to calculate diference between main and reserve qty
      'main_wi_qty',
      'reserve_main_wi_qty',
    ],
    quantityFieldsMapper: (fields) => fields,
    calculateQuantity: calculateQuantityRof,
    ridField: 'rollun_id',
    priceField: 'price',
  },
  RollunOfficePickupCnRof: {
    url: '/api/datastore/RollunOfficeInventoryCacheDataStore',
    quantityFields: [
      // need to calculate diference between main and reserve qty
      'main_cn_qty',
      'reserve_main_cn_qty',
    ],
    quantityFieldsMapper: (fields) => fields,
    calculateQuantity: calculateQuantityRof,
    ridField: 'rollun_id',
    priceField: 'price',
  },
};
