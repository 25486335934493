import React, { FC } from 'react';
import withControls from './withControls';
import { IRendererProps } from './DefaultRenderer';

export interface IExternalLinkRenderProps extends IRendererProps {
  config?: {
    // example - '/api/test/{value}/test/'
    // value is a placeholder for the value of the cell
    pattern?: string;
  };
}

const addStartingSlash = (v: string) => (v[0] === '/' ? v : `/${v}`);

const ExternalLinkRenderer: FC<IExternalLinkRenderProps> = ({
  value,
  config,
}) => {
  const link = config?.pattern
    ? config?.pattern.replace('{value}', value)
    : addStartingSlash(value);

  return (
    <a target="_blank" href={link}>
      <span>{value}</span>
    </a>
  );
};

export default withControls(ExternalLinkRenderer);
