/* tslint:disable */
/* eslint-disable */
/**
 * LogsIngestor
 * An API to ingest logs, via HTTP. Mostly used by frontend apps. </br></br> </br></br> Notes - </br></br> The backend side will accept an array of logs, and will use logger to persist messages to ElasticSearch </br> The \'ingest\' endpoint will not throw 500, if some of the logs were not persisted successfully (event if all). Some retry mechanism should be implemente on the backend side. If we are not able to persist message after a reasonable amount of retries, just log to SDTOUT. </br> The \'ingest\' should not be syncronous, to not introduce long running http requests and big latency calls. If that is possible, return 204 to client immidiatelly, and process logs in a background. </br> Ingest logs schema reflects logger interface (not sure about datetime). 
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from "./configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';

export const BASE_PATH = "http://rollun-net-prvt/openapi/LogsIngestor/v1".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: AxiosRequestConfig;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}
