import React, { FC, useState } from 'react';
import { copyToClipboard } from 'rollun-ts-utils/dist';
import MuiTooltip from './MuiTooltip';

interface IProps {
  textToCopy: string;
  children: JSX.Element;
}

const CopiableText: FC<IProps> = ({ textToCopy, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <MuiTooltip open={open} title={'Copied!'} arrow>
      <span
        onClick={() => {
          setOpen(true);
          copyToClipboard(textToCopy);
          setTimeout(() => setOpen(false), 2000);
        }}
      >
        {children}
      </span>
    </MuiTooltip>
  );
};

export default CopiableText;
