import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from '../../../../../UI/Spinner';
import GenericDeal, { DealProps, DealType } from '../../GenericDeal';
import { ErrorView } from '../../../../../UI';
import { useGetReturnBag } from '../hooks/useGetReturnBag';
import MuiButton from '../../../../../UI/MuiButton';
import { Box } from '@material-ui/core';
import { useMutateItems } from '../hooks/useMutateItems';
import { useMutateDeal } from '../hooks/useMutateDeal';
import { apiClientsReturnBags } from '../../api-clients/api-clients-return-bags';

export const ReturnBagDeal: FC<DealProps> = ({
  buttons,
  fieldsConfig,
  widgets,
}) => {
  const { id } = useParams<{ id: string }>();
  const { data, error, isFetching } = useGetReturnBag(id);
  const history = useHistory();
  const { handleAddItems, handleDeleteItems } = useMutateItems();
  const { handleUpdateDeal } = useMutateDeal();

  if (isFetching && data?.id !== id) {
    return <Spinner />;
  }

  if (error && !data) {
    return (
      <ErrorView
        error={{
          text: (error as Error).message,
          code: (error as Error).message.includes('not found') ? 404 : 403,
        }}
      >
        <Box
          style={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <MuiButton onClick={() => history.goBack()}>Back</MuiButton>
          <MuiButton onClick={() => location.reload()}>Reload</MuiButton>
        </Box>
      </ErrorView>
    );
  }

  return (
    <GenericDeal
      data={data!}
      rawData={data ? apiClientsReturnBags.convertModelToDTO(data!) : {}}
      type={DealType.ReturnBag}
      buttons={buttons}
      fieldsConfig={fieldsConfig}
      widgets={widgets}
      onItemsAdd={handleAddItems}
      onItemsDelete={handleDeleteItems}
      onDealUpdate={handleUpdateDeal}
      availableStatuses={[
        {
          id: '63',
          name: 'Bag_filling',
          color: '#E71773',
        },
        {
          id: '64',
          name: 'Bag_preparation',
          color: '#84216B',
        },
        {
          id: '65',
          name: 'Returns_getting',
          color: '#215B33',
        },
        {
          id: '66',
          name: 'Label_creation',
          color: '#003F77',
        },
        {
          id: '67',
          name: 'Info_sending',
          color: '#F28411',
        },
        {
          id: '68',
          name: 'Items_return',
          color: '#4F3BFF',
        },
        {
          id: '69',
          name: 'Waiting_for_refund',
          color: '#00B27A',
        },
        {
          id: '70',
          name: 'Archive',
          color: '#FCEC25',
        },
      ]}
    />
  );
};
