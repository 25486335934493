import React, { FC, useContext, useEffect, useState } from 'react';
import MuiIconButton from '../../../../../UI/MuiIconButton';
import { DealType } from '../index';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Box,
} from '@material-ui/core';
import MuiButton from '../../../../../UI/MuiButton';
import { Spinner } from '../../../../../UI';
import { DealContext } from '../context/dealContext';
import HttpDatastore from 'rollun-ts-datastore';
import { Eq, Query } from 'rollun-ts-rql';
import { queryClient } from '../../../../../queryClient';
import { apiClientsOffer } from '../../api-clients/api-clients-offers';

interface AddItemModalProps {
  type: DealType;
}

const AddItemModal: FC<AddItemModalProps> = ({}) => {
  const dealContext = useContext(DealContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [price, setPrice] = useState<number | undefined>();
  const [quantity, setQuantity] = useState<number | undefined>(1);
  const [rid, setRid] = useState('');
  const [mpOrderItemId, setMpOrderItemId] = useState('');
  const [asin, setAsin] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [addItemByType, setAddItemByType] = useState<'rid' | 'asin'>('rid');
  const [isMpOrderItemIdRequired, setIsMpOrderItemIdRequired] = useState(true);

  useEffect(() => {
    const isRequired =
      addItemByType === 'rid' && (rid === '11111' || rid === '11112');
    setIsMpOrderItemIdRequired(!isRequired);
  }, [rid, addItemByType]);

  const checkIfInCatalog = async (rollunId: string) => {
    return await new HttpDatastore('/api/datastore/CatalogDataStore').read(
      rollunId,
    );
  };

  const fetchRollunIdByASIN = async () => {
    const items = await new HttpDatastore(
      '/api/datastore/AmazonMsinRidAccordance',
    ).query(new Query().setQuery(new Eq('msin', asin)));

    if (items.length > 0) {
      return items[0].rid;
    }

    throw new Error(`Item with SKU ${asin} not found in Rollun Catalog`);
  };

  const checkIfExistsInMP = async (rid: string) => {
    return await apiClientsOffer.getByRID(rid);
  };

  const handleNewItemCreate = async () => {
    setIsLoading(true);

    try {
      const itemRid =
        addItemByType === 'rid' ? rid : await fetchRollunIdByASIN();

      const catalogItem = await checkIfInCatalog(itemRid);
      if (!catalogItem) {
        throw new Error(`Couldn't find Rollun ID [${itemRid}]`);
      }

      const isInMegaplan = await checkIfExistsInMP(itemRid);
      if (
        !isInMegaplan &&
        !confirm(`
          Product not found in Megaplan catalog, add it?
      `)
      ) {
        throw new Error(
          `Couldn't find Product in Megaplan by article [${itemRid}]`,
        );
      }

      await dealContext?.onItemsAdd?.(dealContext?.deal.id, [
        {
          cost: price?.toString() || '1',
          quantity: quantity || 0,
          article: itemRid,
          name: `${catalogItem.brand_id}  ${
            catalogItem.manufacturer_part_number
          }${
            isMpOrderItemIdRequired && mpOrderItemId ? `  ${mpOrderItemId}` : ''
          }`,
        },
      ]);
      await queryClient.invalidateQueries('GET_DEAL_KEY');
    } catch (e) {
      alert((e as any)?.message || 'Something went wrong');
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setAsin('');
    setRid('');
    setPrice(0);
    setQuantity(1);
    setMpOrderItemId('');
  };

  return (
    <>
      <MuiIconButton
        iconName="plus-square"
        color="success"
        title="Add new item to deal"
        onClick={() => setIsModalOpen(true)}
      />
      <Dialog open={isModalOpen} onClose={handleClose}>
        <DialogTitle
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        ></DialogTitle>
        {isLoading ? (
          <Spinner />
        ) : (
          <DialogContent>
            <Box
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography>
                Add new item by {addItemByType === 'rid' ? 'Rollun Id' : 'ASIN'}
              </Typography>{' '}
              <FormControl fullWidth style={{ width: 150 }}>
                <InputLabel id="add-by-type">Add new item by</InputLabel>
                <Select
                  labelId="add-by-type"
                  defaultValue="rid"
                  onChange={(e) =>
                    setAddItemByType(e.target.value as 'rid' | 'asin')
                  }
                  style={{ width: 150 }}
                >
                  <MenuItem value="rid">Rollun Id</MenuItem>
                  <MenuItem value="asin">ASIN</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {addItemByType === 'rid' ? (
              <TextField
                id="rid"
                label="Item RID"
                type="text"
                value={rid}
                onChange={(e) => setRid(e.target.value)}
                fullWidth
                required
              />
            ) : (
              <TextField
                id="asin"
                label="Item ASIN"
                type="text"
                value={asin}
                onChange={(e) => setAsin(e.target.value)}
                fullWidth
                required
              />
            )}
            <TextField
              id="quantity"
              label="Quantity"
              type="number"
              value={quantity}
              onChange={(e) => {
                if (e.target.value === '') {
                  setQuantity(undefined);
                  return;
                }

                setQuantity(+e.target.value);
              }}
              fullWidth
              required
            />
            <TextField
              id="price"
              label="Item price"
              type="number"
              value={price}
              onChange={(e) => {
                if (e.target.value === '') {
                  setPrice(undefined);
                  return;
                }

                setPrice(+e.target.value);
              }}
              fullWidth
              required
            />
            {isMpOrderItemIdRequired && (
              <TextField
                id="mpOrderItemId"
                label="Mp order Item id"
                value={mpOrderItemId}
                onChange={(e) => setMpOrderItemId(e.target.value)}
                fullWidth
                required
              />
            )}
          </DialogContent>
        )}
        <DialogActions>
          <MuiButton
            style={{
              width: 100,
            }}
            onClick={handleClose}
            color="error"
          >
            Cancel
          </MuiButton>
          <MuiButton
            onClick={handleNewItemCreate}
            style={{
              width: 100,
            }}
            color="success"
            /* prettier-ignore */
            disabled={!((rid || asin) && (typeof price === 'number' && price >= 0) && quantity && (!isMpOrderItemIdRequired || mpOrderItemId)) || isLoading}
          >
            Add
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddItemModal;
