import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  LinkConfig,
  PatternConfig,
  cutLinkToFit,
  getLinksFromPatterns,
} from '../../utils';
import MuiIconButton from '../../../../../../UI/MuiIconButton';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: '#f5f5f9', // Customize this to change the tooltip's background color
    fontSize: 14,
  },
  customWidth: {
    maxWidth: 500,
  },
  noMaxWidth: {
    maxWidth: 'none',
  },
}));

interface LinkControlConfig {
  patterns: (string | PatternConfig)[];
}

interface LinkControlProps {
  config?: LinkControlConfig;
  value: string;
  row: any;
}

const LinkControl = ({ config, row }: LinkControlProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  const handleTooltipClose = () => {
    timeoutId.current = setTimeout(() => {
      setOpen(false);
    }, 500);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const links = getLinksFromPatterns(config?.patterns, row);

  const filteredLinks = links.filter(
    (link): link is string => link !== undefined,
  );

  if (!filteredLinks.length) {
    return (
      <MuiIconButton
        color={'#000'}
        iconName={faLink}
        width={13}
        height={13}
        disabled
      />
    );
  }

  const url = filteredLinks[0];

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      title={
        <div>
          {filteredLinks.map((link: string | LinkConfig, index: number) =>
            typeof link === 'string' ? (
              <div key={index}>
                <span>
                  <a target="_blank" href={link}>
                    {cutLinkToFit(link, 36)}
                  </a>
                </span>
              </div>
            ) : (
              <div key={index}>
                <span>
                  <a target="_blank" href={link.link}>
                    {link.name}
                  </a>
                </span>
              </div>
            ),
          )}
        </div>
      }
      interactive
      onClose={handleTooltipClose}
      open={open}
      onOpen={handleTooltipOpen}
      enterDelay={800}
      enterNextDelay={800}
      placement="bottom-start"
    >
      <span>
        <a href={url} target="_blank">
          <MuiIconButton iconName={faLink} width={13} height={13} />
        </a>
      </span>
    </Tooltip>
  );
};

export default LinkControl;
