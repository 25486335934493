import React, { useContext } from 'react';
import ReturnAppContext from '../context';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';

const GoBackButton = () => {
  const context = useContext(ReturnAppContext);

  return (
    <IconButton onClick={() => context.goBack(context.page)}>
      <ArrowBackIcon />
    </IconButton>
  );
};

export default GoBackButton;
