import React, { useContext } from 'react';
import { CreateServiceWithResourceContext } from './CreateServiceWithResource';
import RulesTreeEditor from '../../../ACLRulesEditor/components/RulesTreeEditor';
import { Box, Typography } from '@material-ui/core';
import MuiButton from '../../../../UI/MuiButton';

const SetRules = () => {
  const createService = useContext(CreateServiceWithResourceContext);

  if (
    !(
      createService?.resourceId &&
      createService?.routeId &&
      createService?.serviceConfig?.datastore.resourceId &&
      createService?.serviceConfig?.datastore.routeId
    )
  ) {
    return (
      <Typography variant="h5" color="error">
        Something went wrong. Reload the page and try again
      </Typography>
    );
  }

  const handleNextStep = () => {
    createService.setResourceId(createService.resourceId, 'add-config');
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Resources rules</Typography>
        <MuiButton onClick={handleNextStep} color="primary">
          Next step
        </MuiButton>
      </Box>
      <Box display="flex">
        <Box>
          <Typography variant="h5">Page rules</Typography>
          <RulesTreeEditor resourceId={createService.resourceId} />
        </Box>
        <Box>
          <Typography variant="h5">Datastore rules</Typography>
          <RulesTreeEditor
            resourceId={createService.serviceConfig?.datastore.resourceId}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SetRules;
