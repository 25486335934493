import React, { Component, FC } from 'react';
import AsyncSelect from 'react-select/async-creatable';
import { OptionsType, OptionTypeBase } from 'react-select/src/types';
import { REACT_SELECT_ANY } from '../utils/common.types';
import HttpDatastore from 'rollun-ts-datastore';
import _ from 'lodash';
import { Contains, Limit, Or, Query, Select } from 'rollun-ts-rql';
import { noop } from '../utils/common.utils';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { zIndexes } from '../themeProvider';
import MuiButton from './MuiButton';
import { Formik } from 'formik';
import * as yup from 'yup';

type Tag = {
  id: string;
  description: string;
  deprecated: 1 | 0;
  tags: string;
  alias: string | null;
};

interface IProps {
  onChange?: (tags: Array<string>) => void;
  placeholder?: string;
  isDisabled?: boolean;
  autoFocus?: boolean;
  initialTags?: Array<string>;
}

interface IState {
  selectedTags: Array<string>;
  tagToCreate: string;
  isModalOpen: boolean;
  isValidInputs: boolean;
}

export const isTagField = (name: string) =>
  ['tag'].some((n) => new RegExp(n, 'i').test(name));

export default class Tags extends Component<IProps, IState> {
  tagsStore = new HttpDatastore<Tag>('/api/datastore/Tags');
  loadOptions = _.debounce(
    (input: string, cb: (options: OptionsType<OptionTypeBase>) => void) => {
      const q = input
        ? new Query().setQuery(
            new Or()
              .addNode(new Contains('id', input.trim()))
              .addNode(new Contains('tags', input.trim()))
              .addNode(new Contains('description', input.trim())),
          )
        : new Query().setLimit(new Limit(20, 0));

      q.setSelect(new Select(['id', 'description']));

      this.tagsStore
        .query(q)
        .then((res) => {
          cb(
            res.map(({ id, description }) => ({
              value: id,
              label: `${id} (${description})`,
            })),
          );
        })
        .catch(() => cb([]));
    },
    300,
  );

  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedTags: props.initialTags || [],
      tagToCreate: '',
      isModalOpen: false,
      isValidInputs: true,
    };
  }

  handleCreateTag = (value: string) => {
    this.toggleModal();
    this.setState({ tagToCreate: value });
  };

  handleChange = (value: REACT_SELECT_ANY) => {
    this.setTags(
      value ? value.map(({ value }: { value: string }) => value) : [],
    );
  };

  addTag = (value: string) => {
    this.setTags(this.state.selectedTags.concat(value));
  };

  flushNewTagToDS = async (value: string, description: string) => {
    this.tagsStore
      .create({
        id: value,
        description: description,
      })
      .catch((err) => console.error(err));
  };

  setTags = (tags: Array<string>) => {
    const { onChange = noop } = this.props;
    this.setState({ selectedTags: tags });
    onChange(tags);
  };

  toggleModal = () => {
    this.setState(({ isModalOpen }) => ({ isModalOpen: !isModalOpen }));
  };

  formatCreateLabel = (value: string) => {
    return (
      <Box textAlign="center" fontWeight="bold">
        Create `{value}` tag
      </Box>
    );
  };

  render() {
    const { selectedTags, isModalOpen, tagToCreate } = this.state;
    const {
      placeholder = 'Start typing tag name...',
      isDisabled = false,
      autoFocus = false,
    } = this.props;

    return (
      <div style={{ zIndex: zIndexes.biggest + 1 }}>
        <AsyncSelect
          loadOptions={this.loadOptions}
          autoFocus={autoFocus}
          isDisabled={isDisabled}
          placeholder={placeholder}
          onCreateOption={this.handleCreateTag}
          isMulti
          menuPortalTarget={document.body}
          styles={{
            menu: (base) => ({
              ...base,
              zIndex: 1000000000000000000,
              position: 'absolute',
            }),
            menuPortal: (base) => ({
              ...base,
              zIndex: 1000000000000000000,
              position: 'absolute',
            }),
          }}
          value={selectedTags.map((tag) => ({
            value: tag,
            label: tag,
          }))}
          onChange={this.handleChange}
          formatCreateLabel={this.formatCreateLabel}
          createOptionPosition="first"
          defaultOptions
        />
        <Dialog open={isModalOpen} onClose={() => this.toggleModal()}>
          <DialogTitle>Create new Tag</DialogTitle>
          <TagForm
            tagName={tagToCreate}
            addTag={this.addTag}
            toggleModal={this.toggleModal}
            flushNewTagToDS={this.flushNewTagToDS}
          />
        </Dialog>
      </div>
    );
  }
}

const useStyle = makeStyles(() => ({
  createTagForm: {
    width: '506px',
  },
}));

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .test(
      'wrapped in ##',
      'Tag must be wrapped in # symbols f.e.: #tag#',
      function (value) {
        if (value && !(value[0] === '#' && value[value.length - 1] === '#')) {
          return this.createError();
        }

        return true;
      },
    )
    .test(
      'no whitespaces',
      'Replace whitespace with dash (#my tag# --> #my_tag#)',
      function (value) {
        if (value && value.includes(' ')) {
          return this.createError();
        }

        return true;
      },
    )
    .min(5, 'Tag name is too short (min 5 letters)')
    .max(30, 'Tag name is too long (max 30 letters)')
    .required('Tag name is required'),
  description: yup
    .string()
    .min(10, 'Description is too short (min 10 letters)')
    .max(500, 'Description is too long (max 500 letters)')
    .required('Description is required'),
});

interface TagForm {
  tagName: string;
  addTag: (value: string) => void;
  toggleModal: () => void;
  flushNewTagToDS: (value: string, description: string) => Promise<void>;
}

const TagForm: FC<TagForm> = ({
  tagName,
  addTag,
  toggleModal,
  flushNewTagToDS,
}) => {
  const classes = useStyle();

  const handleCreateTagFormSubmit = (values: {
    name: string;
    description: string;
  }) => {
    flushNewTagToDS(values.name, values.description).then(() => {
      toggleModal();
      addTag(values.name);
    });
  };

  return (
    <Formik
      initialValues={{
        name: tagName,
        description: '',
      }}
      onSubmit={(values) => handleCreateTagFormSubmit(values)}
      validationSchema={validationSchema}
      validateOnBlur
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        isValid,
        handleSubmit,
      }) => (
        <form className={classes.createTagForm} onSubmit={handleSubmit}>
          <DialogContent>
            <Box marginY={1}>
              <TextField
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={errors.name}
                error={!!errors.name}
                variant="outlined"
                name="name"
                label="Tag name"
                fullWidth
              />
            </Box>
            <Box marginY={1}>
              <TextField
                value={values.description}
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={errors.description}
                error={!!errors.description}
                variant="outlined"
                name="description"
                label="Tag description"
                fullWidth
                multiline
                rows={10}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <MuiButton
              type="submit"
              color="primary"
              disabled={!isValid}
              fullWidth
            >
              Create
            </MuiButton>
          </DialogActions>
        </form>
      )}
    </Formik>
  );
};
