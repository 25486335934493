import { useMemo, useState } from 'react';
import {
  UpdateData,
  UpdateFormData,
  UpdateRofSign,
} from '../components/RofUpdaterDialog';
import HttpDatastore from 'rollun-ts-datastore/dist';
import _ from 'lodash';
import { In, Query, Select } from 'rollun-ts-rql';
import { locations } from '../components/RofUpdaterForm';
import { httpErrorHandlerPromised } from '../../../../../utils/common.utils';

export interface RofInventoryItem {
  rollun_id: string;
  main_ky_qty: string;
  main_tx_qty: string;
  main_ut_qty: string;
  main_nc_qty: string;
  main_wi_qty: string;
  main_ny_qty: string;
  main_cn_qty: string;
  reserve_ky_qty: string;
  reserve_tx_qty: string;
  reserve_ut_qty: string;
  reserve_nc_qty: string;
}

type UpdateRofPromiseData = {
  price?: number;
  rollun_id: string;
  update_timestamp: number;
} & Partial<Record<typeof locations[number], number>>;

const useRofInventoryDatastore = () => {
  return useMemo(
    () =>
      new HttpDatastore<RofInventoryItem>(
        '/api/datastore/RollunOfficeInventoryCacheDataStore',
      ),
    [],
  );
};

const createChunk = (data: UpdateData[], chunkSize = 5) => {
  return _.chunk(data, chunkSize);
};

const createUpdatePromises = (
  rofDatastore: HttpDatastore,
  data: UpdateRofPromiseData[],
  options: { isCreate?: boolean },
) => {
  return data.map((updateData) =>
    rofDatastore[options.isCreate ? 'create' : 'update'](updateData),
  );
};

const createExistingItemsQuery = (data: UpdateFormData) => {
  return new Query()
    .setSelect(new Select([data.location, 'price', 'rollun_id']))
    .setQuery(
      new In(
        'rollun_id',
        data.data.map(({ rid }) => rid),
      ),
    );
};

const calculateNewQuantity = (
  oldQty: number,
  sign: UpdateRofSign,
  updateQty: number,
) => {
  const signProcessors: Record<
    UpdateRofSign,
    (oldQty: number, updateQty: number) => number
  > = {
    '+': (oldQty, updateQty) => oldQty + updateQty,
    '-': (oldQty, updateQty) => {
      const newQty = oldQty - updateQty;

      return newQty < 0 ? 0 : newQty;
    },
    '=': (oldQty, updateQty) => updateQty,
  };

  return signProcessors[sign](oldQty, updateQty);
};

const updateDataToUpdateRofPromiseData = (
  updateData: UpdateData[],
): UpdateRofPromiseData[] => {
  return updateData.map(({ rid, price, quantity, qtyField }) => ({
    ...(price ? { price } : {}),
    [qtyField]: quantity,
    update_timestamp: Math.floor(Date.now() / 1000),
    rollun_id: rid,
  }));
};

const calculateUpdateDataBasedOnExisting = (
  updateData: UpdateData[],
  existingItems: RofInventoryItem[],
) => {
  const calculateNewPrice = (
    acc: UpdateRofPromiseData[],
    { rid, price, quantity, sign, qtyField }: UpdateData,
  ) => {
    const existingItem = existingItems.find((item) => item.rollun_id === rid);

    if (!existingItem) {
      return acc;
    }

    acc.push({
      rollun_id: rid,
      [qtyField]: calculateNewQuantity(+existingItem[qtyField], sign, quantity),
      update_timestamp: Math.floor(Date.now() / 1000),
      ...(price ? { price } : {}),
    });
    return acc;
  };

  return updateData.reduce<UpdateRofPromiseData[]>(calculateNewPrice, []);
};

const useUpdateRofItems = (): [
  { type: string; description: string },
  (data: UpdateFormData) => Promise<void>,
  (data: UpdateFormData) => Promise<void>,
] => {
  const rofDatastore = useRofInventoryDatastore();
  const [status, setStatus] = useState({
    type: 'idle',
    description: '',
  });

  const getExistingItemsData = (data: UpdateFormData) => {
    const itemsQuery = createExistingItemsQuery(data);
    return rofDatastore.query(itemsQuery);
  };

  const getNotExistingItems = (
    chunk: UpdateData[],
    oldChunk: RofInventoryItem[],
  ) => {
    return chunk
      .filter(({ rid }) => !oldChunk.some(({ rollun_id }) => rollun_id === rid))
      .map(({ rid }) => rid);
  };

  const base = async (data: UpdateFormData, isCreate: boolean) => {
    try {
      const notExistingItems: string[] = [];
      const chunkSize = 5;
      const dataChunks = createChunk(data.data, chunkSize);

      for (const [index, chunk] of dataChunks.entries()) {
        setStatus({
          type: 'loading',
          description: `Processing ${index * chunkSize}/${
            dataChunks.length * chunkSize
          } items`,
        });
        const oldItemsData = await getExistingItemsData({
          ...data,
          data: chunk,
        });

        const updateDataChunk = isCreate
          ? updateDataToUpdateRofPromiseData(chunk)
          : calculateUpdateDataBasedOnExisting(chunk, oldItemsData);

        notExistingItems.push(...getNotExistingItems(chunk, oldItemsData));
        console.log(updateDataChunk);
        await Promise.all(
          createUpdatePromises(rofDatastore, updateDataChunk, { isCreate }),
        );
      }

      const statusMessage =
        notExistingItems.length === 0 || isCreate
          ? `Processed ${dataChunks.length * chunkSize} items`
          : `Some item are not present in table, to create them,
             you need to paste info about this items,
             and click 'Create' button:\n
             ${notExistingItems.join()}`;

      setStatus({
        type: 'idle',
        description: statusMessage,
      });
    } catch (e) {
      const { text } = await httpErrorHandlerPromised(e);
      setStatus({
        type: 'error',
        description: text,
      });
    }
  };

  return [status, (data) => base(data, false), (data) => base(data, true)];
};

export { useUpdateRofItems };
