import React, { Component } from 'react';
import { Button } from '../../../../UI';
import RulesTreeEditor from '../../../ACLRulesEditor/components/RulesTreeEditor';

interface IProps {
  createdResourceId: string;

  onRulesCreated(): void;
}

class AddRule extends Component<IProps> {
  render() {
    return (
      <div>
        <div className="my-2 d-flex justify-content-between">
          <h3>ACL Rules</h3>
          <Button onClick={this.props.onRulesCreated} color="primary">
            Done
          </Button>
        </div>
        <RulesTreeEditor resourceId={this.props.createdResourceId} />;
      </div>
    );
  }
}

export default AddRule;
