import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import { WebHookConfig } from '../../../../Table/components/webHookActivator/WebhookActivator';
import MuiIconButton from '../../../../../UI/MuiIconButton';
import { Box, Typography, useTheme } from '@material-ui/core';
import { HiddenInput, Checkbox } from '../../../../../UI';
import MuiButton from '../../../../../UI/MuiButton';

interface WebhookEditProps {
  config: WebHookConfig;
  index: number;
  totalLength: number;
  hooks: WebHookConfig[];
  swapHooks: (index1: number, index2: number) => void;
  onHooksUpdate: (
    hooks: Array<WebHookConfig> | string | boolean | null,
    path?: string,
  ) => void;
}

const MoveWebhookButtons: FC<{
  index: number;
  totalLength: number;
  swapHooks: (index1: number, index2: number) => void;
}> = ({ index, totalLength, swapHooks }) => {
  const getAlignmentForArrow = (index: number, totalLength: number) => {
    if (index === 0) {
      return 'flex-end';
    }

    if (index === totalLength - 1) {
      return 'flex-start';
    }

    return 'space-between';
  };

  return (
    <Box
      display="flex"
      justifyContent={getAlignmentForArrow(index, totalLength)}
    >
      {index !== 0 && (
        <MuiIconButton
          onClick={() => swapHooks(index, index - 1)}
          iconName="arrow-left"
        />
      )}
      {index !== totalLength - 1 && (
        <MuiIconButton
          onClick={() => swapHooks(index, index + 1)}
          iconName="arrow-right"
        />
      )}
    </Box>
  );
};

const WebhookEdit: FC<WebhookEditProps> = ({
  config: { name, url, description, downloadResultAsCSV, dontWaitUntilResolve },
  totalLength,
  index,
  swapHooks,
  onHooksUpdate,
  hooks,
}) => {
  const theme = useTheme();

  return (
    <Card style={{ minWidth: 350, maxWidth: 350, padding: theme.spacing(1) }}>
      <MoveWebhookButtons
        index={index}
        totalLength={totalLength}
        swapHooks={swapHooks}
      />
      <HiddenInput
        initValue={name}
        onSubmit={(str) => str && onHooksUpdate(str, `[${index}].name`)}
        placeholder="WebHook card name..."
      />
      <HiddenInput
        initValue={url}
        onSubmit={(str) => str && onHooksUpdate(str, `[${index}].url`)}
        placeholder="WebHook url"
      />
      <HiddenInput
        initValue={description || ''}
        as={'textarea'}
        onSubmit={(str) => str && onHooksUpdate(str, `[${index}].description`)}
        placeholder="WebHook description"
      />
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6">DontWaitUntilResolve:</Typography>
        <Checkbox
          onChange={(value) =>
            value
              ? onHooksUpdate(value, `[${index}].dontWaitUntilResolve`)
              : onHooksUpdate(value, `[${index}].dontWaitUntilResolve`)
          }
          initValue={!!dontWaitUntilResolve}
        />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6">
          Download result as CSV (default JSON):
        </Typography>
        <Checkbox
          onChange={(value) =>
            value
              ? onHooksUpdate(value, `[${index}].downloadResultAsCSV`)
              : onHooksUpdate(value, `[${index}].downloadResultAsCSV`)
          }
          initValue={!!downloadResultAsCSV}
        />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <MuiButton color="primary">Use</MuiButton>
        <MuiButton
          onClick={() =>
            onHooksUpdate((hooks || []).filter((_, i) => i !== index))
          }
          color="error"
        >
          Delete
        </MuiButton>
      </Box>
    </Card>
  );
};

export default WebhookEdit;
