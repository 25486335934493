import { Eq, Query } from 'rollun-ts-rql';
import { useMemo } from 'react';
import HttpDatastore from 'rollun-ts-datastore/dist';

const useStorageByLocation = () => {
  const datastore = useMemo(
    () => new HttpDatastore('/api/datastore/ReturnLocations'),
    [],
  );

  return async (location: string) => {
    try {
      const [storage] = await datastore.query(
        new Query({
          query: new Eq('name', location),
        }),
      );

      return storage?.storages || 'unkown';
    } catch (e) {
      return 'unkown';
    }
  };
};

export { useStorageByLocation };
