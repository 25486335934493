import React from 'react';

interface HowToReturnContext {
  version: 'v2' | 'v3';
  basePath: '/api/openapi/HowToReturn/v2' | '/api/openapi/ReturnsModule/v3';
}

const HowToReturnContext = React.createContext<HowToReturnContext | null>(null);

export default HowToReturnContext;
