import { makeStyles } from '@material-ui/core/styles';
import { useMemo } from 'react';

const useItemCardStyles = () => {
  const useStyles = useMemo(
    () =>
      makeStyles(() => ({
        foundItem: {
          opacity: 0.5,
        },
        notFoundItem: {},
      })),
    [],
  );

  return useStyles();
};

export { useItemCardStyles };
