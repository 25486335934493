import HttpDatastore from 'rollun-ts-datastore';
import { Position, SPECIAL_ITEMS_IDS, ReturnBagDTO, ReturnBag } from './types';
import ApiClientsPositions from './api-clients-position';
import { Query } from 'rollun-ts-rql';
import { compareStringNumbers } from '../common/utils/compareStringNumbers';

const datastore = new HttpDatastore<ReturnBagDTO>(
  '/api/datastore/ReturnBagsDataStore',
);

export default class ApiReturnBags {
  apiPositions: ApiClientsPositions;
  constructor() {
    this.apiPositions = new ApiClientsPositions();
  }

  async getById(id: string): Promise<ReturnBag> {
    const bagDTO = await datastore.read(id);

    if (!bagDTO) {
      throw new Error(`Return bag with id ${id} not found`);
    }

    const positions = this.apiPositions.getByDealId(id);

    const bag = Promise.all([bagDTO, positions]).then((data) => {
      return this.convertDTOtoModel(...data);
    });
    return bag;
  }

  async getByQuery(query: Query): Promise<ReturnBag[]> {
    const bagsDTO = await datastore.query(query);
    const positions = await Promise.all(
      bagsDTO.map((bagDTO) => this.apiPositions.getByDealId(bagDTO.Id)),
    );

    return bagsDTO.map((bagDTO, index) =>
      this.convertDTOtoModel(bagDTO, positions[index]),
    );
  }

  async update(id: string, data: Partial<Omit<ReturnBag, 'id' | 'positions'>>) {
    return await datastore.update({
      ...this.convertModelToDTO(data),
      Id: id,
    });
  }

  async addOrUpdateItems(
    id: string,
    items: Omit<Position, 'offerId' | 'dealId' | 'id'>[],
  ) {
    const deal = await this.getById(id);
    const positions = deal.positions;

    const tempSortedItems: Position[] = [];

    for (let i = 0; i < positions.length; i++) {
      const position = { ...positions[i] };

      if (
        tempSortedItems.filter(
          (item) =>
            item.article === position.article &&
            compareStringNumbers(item.cost, position.cost) &&
            item.name === position.name,
        ).length
      )
        continue;

      tempSortedItems.push(position);

      const sameItems = items.filter(
        (item) =>
          item.article === position.article &&
          compareStringNumbers(item.cost, position.cost) &&
          item.name === position.name,
      );
      if (!sameItems.length) continue;

      const allQuantity = sameItems.reduce(
        (prev, cur) => prev + +cur.quantity,
        0,
      );
      position.quantity = +position.quantity + allQuantity;

      await this.apiPositions.update(position);
    }

    const filteredItems = items.filter((item) => {
      for (let i = 0; i < tempSortedItems.length; i++) {
        const position = { ...tempSortedItems[i] };
        if (
          item.article === position.article &&
          compareStringNumbers(item.cost, position.cost) &&
          item.name === position.name
        )
          return false;
      }
      return true;
    });

    if (filteredItems.length) await this.addItems(id, filteredItems);
  }

  async deleteItems(id: string, itemIds: string[]): Promise<void> {
    await Promise.all(
      itemIds.map((itemId) => this.apiPositions.delete(id, itemId)),
    );
  }

  async addItems(
    id: string,
    items: Omit<Position, 'offerId' | 'dealId' | 'id'>[],
  ): Promise<void> {
    for (const item of items) {
      await this.apiPositions.add(id, item);
    }
  }

  async create(data: Omit<ReturnBag, 'id'>): Promise<ReturnBag> {
    const pickupDTO = this.convertModelToDTO(data);
    const pickup = await datastore.create(pickupDTO);

    if (data.positions) {
      await this.addItems(pickup.Id, data.positions);
    }

    return this.getById(pickup.Id);
  }

  convertDTOtoModel(
    returnBagDTO: ReturnBagDTO,
    positions: Position[],
  ): ReturnBag {
    const no_special_items_positions = positions.filter(
      (position) => !SPECIAL_ITEMS_IDS.includes(position.article),
    );
    return {
      id: returnBagDTO.Id,
      archiveScenario: returnBagDTO.ArchiveScenario,
      GUID: returnBagDTO.GUID,
      name: returnBagDTO.Name,
      contractor: returnBagDTO.Contractor,
      timeCreated: returnBagDTO.TimeCreated,
      timeUpdated: returnBagDTO.TimeUpdated,
      owner: returnBagDTO.Owner,
      isDraft: returnBagDTO.IsDraft,
      isPaid: returnBagDTO.IsPaid,
      finalPrice: returnBagDTO.FinalPrice,
      parselInfo: returnBagDTO.ParselInfo,
      mpName: returnBagDTO.MpName,
      programId: returnBagDTO.ProgramId,
      storage: returnBagDTO.Storage,
      srName: returnBagDTO.SrName,
      tracknumber: returnBagDTO.Tracknumber,
      carrier: returnBagDTO.Carrier,
      srReturnNumber: returnBagDTO.SrReturnNumber,
      problemDescription: returnBagDTO.ProblemDescription,
      bagStatus: returnBagDTO.BagStatus,
      infoLink: returnBagDTO.InfoLink,
      manager: returnBagDTO.Manager,
      mpUaId: returnBagDTO.MpUaId,
      programName: returnBagDTO.ProgramName,
      status: returnBagDTO.Status,
      statusName: returnBagDTO.StatusName,
      positions,
      no_special_items_positions,
    };
  }

  convertModelToDTO(
    returnBag: Partial<Omit<ReturnBag, 'id' | 'positions'>>,
  ): Partial<Omit<ReturnBagDTO, 'Id'>> {
    return {
      GUID: returnBag.GUID,
      ArchiveScenario: returnBag.archiveScenario,
      Name: returnBag.name,
      Contractor: returnBag.contractor,
      TimeCreated: returnBag.timeCreated,
      TimeUpdated: returnBag.timeUpdated,
      Owner: returnBag.owner,
      IsDraft: returnBag.isDraft,
      IsPaid: returnBag.isPaid,
      FinalPrice: returnBag.finalPrice,
      ParselInfo: returnBag.parselInfo,
      MpName: returnBag.mpName,
      ProgramId: returnBag.programId,
      Storage: returnBag.storage,
      SrName: returnBag.srName,
      Tracknumber: returnBag.tracknumber,
      Carrier: returnBag.carrier,
      SrReturnNumber: returnBag.srReturnNumber,
      ProblemDescription: returnBag.problemDescription,
      BagStatus: returnBag.bagStatus,
      Manager: returnBag.manager,
      InfoLink: returnBag.infoLink,
      MpUaId: returnBag.mpUaId,
      ProgramName: returnBag.programName,
      Status: returnBag.status,
      StatusName: returnBag.statusName,
    };
  }
}

export const apiClientsReturnBags = new ApiReturnBags();
