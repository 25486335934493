import { useQuery } from 'react-query';
import { useContext } from 'react';
import { DealContext } from '../../../../GenericDeal/context/dealContext';
import { Eq, Query } from 'rollun-ts-rql';
import { DealType } from '../../../../GenericDeal/index';
import _ from 'lodash';
import { apiClientOrders } from '../../../../api-clients/api-clients-orders';
import { apiClientsReturnOrders } from '../../../../api-clients/api-clients-return-orders';

export const useGetLinkedDeals = (
  setDisplayName: (displayName: string) => void,
  linkField?: string,
  apiClients?: {
    customLinkField?: string;
    type: DealType;
    apiClient: {
      getByQuery: <T>(query: Query) => Promise<T>;
    };
  }[],
) => {
  const dealContext = useContext(DealContext);

  return useQuery({
    queryKey: 'GET_LINKED_DEALS',
    queryFn: async () => {
      if (!apiClients) {
        const id = (dealContext?.deal as any)[linkField as string];
        const linkedDeal = linkField?.includes('Return')
          ? await apiClientsReturnOrders.getById(id)
          : await apiClientOrders.getById(id);

        return [
          {
            id,
            type: linkField?.includes('Return')
              ? DealType.ReturnOrder
              : DealType.Order,
            statusName: linkedDeal?.statusName,
          },
        ];
      }

      const query = (linkField: string | undefined, id: string | undefined) =>
        new Query().setQuery(new Eq(_.upperFirst(linkField), id));

      const results: { id: string; statusName: string; type: DealType }[] = [];

      for (const client of apiClients) {
        const res = (await client.apiClient.getByQuery(
          query(
            client.customLinkField || linkField,
            (dealContext?.deal as any)[linkField || 'id'],
          ),
        )) as {
          id: string;
          statusName: string;
        }[];

        results.push(
          ...res.map(({ id, statusName }) => ({
            id,
            statusName,
            type: client.type,
          })),
        );
      }

      return results;
    },
    onSuccess: (data) => {
      setDisplayName(`Linked Deals (${data.length})`);
    },
    retry: 0,
  });
};
