import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import { Button, Dialog, ErrorView, Spinner } from '../../../UI';
import { ErrorType } from '../../../utils/common.types';
import HttpDatastore from 'rollun-ts-datastore';
import { Roles, User } from '../types';
import { httpErrorHandlerPromised } from '../../../utils/common.utils';

interface IProps {
  submittedUserRoles: boolean;
  roles: Roles;
  user: User;
  onSubmit: () => void;
}

const userDataStore = new HttpDatastore<User>('api/datastore/userDataStore');
const userRoleDataStore = new HttpDatastore('api/datastore/userRoleDataStore');

const CreateNewUser: React.FC<IProps> = ({
  submittedUserRoles,
  roles,
  user,
  onSubmit,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorType>();
  const [isCreated, setIsCreated] = useState(false);

  const createUser = async () => {
    if (isCreated) {
      setModalOpen(false);
      onSubmit();
      return;
    }

    if (!!error) {
      setModalOpen(false);
      onSubmit();
      return;
    }

    setLoading(true);
    let newUser: User | null = null;
    let userRoles = [];

    try {
      newUser = await userDataStore.create(user);
      const { id } = newUser;
      userRoles = await Promise.all(
        roles.map((role) =>
          userRoleDataStore.create({
            id: `${id}_${role.role}`,
            user_id: id,
            roles_id: role.role,
          }),
        ),
      );
      setIsCreated(true);
    } catch (e) {
      setLoading(false);
      const res = await httpErrorHandlerPromised(e);
      setError({
        code: res.code,
        text: res.text ?? 'unknown error message',
      });
      if (newUser) {
        const { id } = newUser;
        await Promise.all(
          userRoles.map((role) =>
            userRoleDataStore.delete(`${id}_${role.role}`),
          ),
        );
        await userDataStore.delete(newUser.id);
      }
    }
    setLoading(false);
  };

  return submittedUserRoles ? (
    <div>
      <Card className="inner-card popup-animation">
        <Button
          type="button"
          color="primary"
          onClick={() => setModalOpen(true)}
        >
          Create New User
        </Button>
      </Card>
      <Dialog
        title="Add New User"
        options={{ centered: true }}
        isOpen={modalOpen}
        onDecline={() => {
          if (!!error) {
            onSubmit();
          }

          setModalOpen(false);
        }}
        onClose={() => {
          if (!!error) {
            onSubmit();
          }

          setModalOpen(false);
        }}
        onOk={() => {
          createUser();
        }}
        submitBtnText="Ok"
        declineBtnText="Cancel"
      >
        {isCreated ? (
          <div>User has been created. Close the modal</div>
        ) : loading ? (
          <Spinner />
        ) : !!error ? (
          <ErrorView error={error} />
        ) : (
          <div>
            <div>User id: {user.id}</div>
            <div>Username: {user.name}</div>
            <p>
              <strong>This user will have rights of: </strong>
            </p>
            {roles.map((role, index) => (
              <div key={`${role.role}`}>
                <div>
                  {index + 1}. {role.role}
                </div>
              </div>
            ))}
            <div className="text-center">
              <strong>Is it correct?</strong>
            </div>
          </div>
        )}
      </Dialog>
    </div>
  ) : null;
};

// create user datastore (create) password hash -bcrypt
export default CreateNewUser;
