import { Position } from '../../api-clients/types';
import ApiClientsBags from '../../api-clients/api-clients-bags';

export const useMutateItems = () => {
  const handleAddItems = async (
    id: string,
    items: Omit<Position, 'offerId' | 'dealId' | 'id'>[],
  ) => {
    await new ApiClientsBags().addOrUpdateItems(id, items);
  };

  const handleDeleteItems = async (id: string, itemIds: string[]) => {
    await new ApiClientsBags().deleteItems(id, itemIds);
  };

  return {
    handleAddItems,
    handleDeleteItems,
  };
};
