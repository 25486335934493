import React, { Component } from 'react';
import _ from 'lodash';

interface IProps {
  isLineValid(
    columns: Array<any>,
    idx: number,
  ): { valid: boolean; msg?: string };

  onDataChange(data: Array<any>): void;

  placeholder?: string;
}

interface IState {
  data: Array<any>;
  validationError: null | string;
}

class InputForm extends Component<IProps, IState> {
  state: IState = {
    data: [],
    validationError: null,
  };

  handleEPIDsAndRollunIDsChange = _.debounce((str: string) => {
    const {
      isLineValid = () => ({ valid: true, msg: '' }),
      onDataChange,
    } = this.props;

    try {
      this.setState({ validationError: null });
      const parsedData = str
        .split(/\n\r?/)
        .map((row) => row.trim())
        .filter((row) => !!row)
        .map((row, idx) => {
          const columns = row.split(/\s+/);
          const { valid, msg = '' } = isLineValid(columns, idx);
          if (!valid) {
            throw new Error(msg);
          }
          return columns;
        });
      onDataChange(parsedData);
    } catch (err) {
      this.setState({ validationError: (err as Error).message });
    }
  }, 300);

  render() {
    const { validationError } = this.state;
    const {
      placeholder = 'Paste data here. Valid data is - columns separated by tabs, and row separated by line breaks',
    } = this.props;

    return (
      <div className="my-2">
        {validationError && (
          <h5 className="text-danger">Data is not valid! {validationError}</h5>
        )}
        <textarea
          onChange={(e) => this.handleEPIDsAndRollunIDsChange(e.target.value)}
          className="form-control mb-2"
          rows={7}
          placeholder={placeholder}
        />
      </div>
    );
  }
}

export default InputForm;
