import React, { useState } from 'react';
import { makeStyles, Paper, TextField, Box } from '@material-ui/core';
import MuiButton from '../../../../../UI/MuiButton';
import { useParams } from 'react-router-dom';
import { clientsApi } from '../hooks/useGetClient';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    padding: 8,
    width: 300,
  },
  inputs: {
    display: 'flex',
    gap: 16,
    flexDirection: 'column',
  },
}));

interface ContactInfoProps {
  contactInfo: {
    phoneNumber: string;
    email: string;
  };
}

const ContactInfo = ({ contactInfo }: ContactInfoProps) => {
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();

  const [phoneNumber, setPhoneNumber] = useState(contactInfo.phoneNumber);
  const [email, setEmail] = useState(contactInfo.email);

  const handleSave = async () => {
    const data = {
      contactInfo: {
        phoneNumber,
        email,
      },
    };

    try {
      await clientsApi.clientsIdPut(id, data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Paper elevation={1} className={classes.root}>
      <Box className={classes.inputs}>
        <TextField
          fullWidth
          value={phoneNumber}
          label="Phone number"
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <TextField
          fullWidth
          value={email}
          label="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </Box>

      <MuiButton
        fullWidth
        color="success"
        onClick={handleSave}
        disabled={
          phoneNumber === contactInfo.phoneNumber && email === contactInfo.email
        }
      >
        Save Contact Info
      </MuiButton>
    </Paper>
  );
};

export default ContactInfo;
