import React, { FC, useState } from 'react';
import {
  Box,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import MuiButton from '../../../UI/MuiButton';

interface FullScreenImageOnClickProps {
  src: string;
}

const useStyles = makeStyles({
  media: {
    height: 250,
    img: {
      maxWidth: '100%',
      maxHeight: '100%',
      display: 'block',
    },
  },
  image: {
    width: window.innerWidth - 200,
    height: window.innerHeight - 200,
    objectFit: 'scale-down',
  },
});

const FullScreenImageOnClick: FC<FullScreenImageOnClickProps> = ({ src }) => {
  const classes = useStyles();
  const [isImageFullScreen, setIsImageFullScreen] = useState(false);

  return isImageFullScreen ? (
    <Dialog open={isImageFullScreen} fullWidth={true}>
      <DialogTitle>Items image</DialogTitle>
      <DialogContent>
        <Box width="100%" display="flex" justifyContent="center">
          <img src={src} className={classes.image} alt="Items card" />
        </Box>
      </DialogContent>
      <DialogActions>
        <MuiButton
          onClick={() => setIsImageFullScreen(false)}
          color="error"
          fullWidth={true}
        >
          Close
        </MuiButton>
      </DialogActions>
    </Dialog>
  ) : (
    <CardMedia
      component="img"
      className={classes.media}
      image={src}
      title="Items image"
      onClick={() => setIsImageFullScreen(true)}
    />
  );
};

export default FullScreenImageOnClick;
