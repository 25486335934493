import React from 'react';
import AbstractService, {
  AbstractServiceProps,
} from '../../../AbstractService';
import AppContainer from '../../../../layout/AppContainer/AppContainer';
import { ErrorView, Spinner } from '../../../../UI';
import { DealType } from '../GenericDeal';
import { ReturnBagDeal } from './components/ReturnBagDeal';
import { suppliers } from '../constants/suppliers';
import { carriers } from '../constants/carrier';
import { returnBagStatus } from '../constants/bag-status';
import { FindOriginalDealsByRids } from '../Bag/widgets/FindItemsByRidAndCSN/FindItemsByRidAndCSN';
import { storage } from '../constants/storage';
import { Scenarios } from '../common/widgets/Scenarios/index';
import { scenarios } from '../../../Table/components/TableHeaderButtons/buttons/Scenarios/scenarios';
import { RGASaver } from './widgets/RGASaver/RGASaver';
import { archiveScenarioReturnBag } from '../constants/archive-scenario';

export class CRMReturnBagDeal extends AbstractService<AbstractServiceProps> {
  render() {
    const { appConfig, error } = this.state;

    if (error) {
      return (
        <AppContainer services={{}}>
          <div className="w-100 h-100 d-flex flex-column justify-content-center">
            <ErrorView error={error}>
              <div className="d-flex justify-content-center p-3">
                <div
                  className="btn btn-primary cursor-pointer"
                  onClick={this.reloadHome}
                >
                  Home
                </div>
              </div>
            </ErrorView>
          </div>
        </AppContainer>
      );
    }

    if (appConfig === null) {
      return <Spinner />;
    }

    const { user, role } = this.props.userIdentity;
    return (
      <AppContainer
        currentResourceName={this.props.resourceName}
        services={this.props.availableServices}
        user={user}
        role={role}
      >
        <ReturnBagDeal
          fieldsConfig={{
            Supplier: {
              fields: [
                {
                  displayName: 'Supplier name',
                  type: 'select',
                  options: suppliers,
                  apiFieldPath: 'srName',
                },
                {
                  displayName: 'Supplier Return Number',
                  type: 'input',
                  apiFieldPath: 'srReturnNumber',
                  multiline: true,
                },
              ],
            },
            'Tech fields': {
              fields: [
                {
                  displayName: 'Storage',
                  type: 'select',
                  options: storage,
                  apiFieldPath: 'storage',
                },
                {
                  displayName: 'Archive scenario',
                  type: 'select',
                  options: archiveScenarioReturnBag,
                  apiFieldPath: 'archiveScenario',
                },
                {
                  displayName: 'Bag Status',
                  type: 'select',
                  options: returnBagStatus,
                  apiFieldPath: 'bagStatus',
                },
                {
                  displayName: 'Parsel Info',
                  type: 'input',
                  // options: parselInfo,
                  apiFieldPath: 'parselInfo',
                },
                {
                  displayName: 'Problem description',
                  type: 'input',
                  apiFieldPath: 'problemDescription',
                  multiline: true,
                },
                {
                  displayName: 'Info link',
                  type: 'input',
                  apiFieldPath: 'infoLink',
                },
              ],
            },
            Tracking: {
              fields: [
                // {
                //   displayName: 'Parcel Measure',
                //   type: 'input',
                //   apiFieldPath: 'parcelMeasure',
                // },
                {
                  displayName: 'Tracknumber',
                  type: 'input',
                  apiFieldPath: 'tracknumber',
                },
                {
                  displayName: 'Carrier',
                  type: 'select',
                  options: carriers,
                  apiFieldPath: 'carrier',
                },
                // {
                //   displayName: 'Ship status',
                //   type: 'select',
                //   options: shipStatus,
                //   apiFieldPath: 'shipStatus',
                // },
              ],
            },
          }}
          type={DealType.ReturnBag}
          buttons={[]}
          widgets={[
            {
              buttonName: 'Find original deals by RIDs or CSNs',
              displayName: 'Find original deals by RIDs or CSNs',
              component: FindOriginalDealsByRids,
              docsLink:
                'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.s20xwpofdhyn',
              docsTitle: 'docs for FindOriginalDealsByRids widget',
            },
            {
              buttonName: 'RGA Saver',
              displayName: 'RGA Saver',
              component: RGASaver,
              docsLink:
                'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.decuvgeszvpr',
              docsTitle: 'docs for RGASaver widget',
            },
            {
              displayName: 'Scenarios',
              buttonName: 'Scenarios',
              component: Scenarios,
              props: {
                scenarios: scenarios.returnBag,
              },
              docsLink:
                'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.m1wjq0kj6hxo',
              docsTitle: 'docs for Scenarios widget',
            },
          ]}
        />
      </AppContainer>
    );
  }
}
