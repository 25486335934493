export function formatName(name: string) {
  return name
    .split(/\s+/)
    .map((word) => {
      const newWord = word
        .split('-')
        .map(
          (namePart) =>
            (namePart[0] || '').toUpperCase() +
            (namePart.slice(1) || '').toLowerCase(),
        )
        .join('-');
      return (
        (newWord[0] || '').toUpperCase() +
        (newWord.slice(1) || '').toLowerCase()
      );
    })
    .join(' ');
}
