import React, { FC } from 'react';
import { HiddenInput } from '../../../../../../UI';
import { randomString } from 'rollun-ts-utils';
import { Box, Typography, useTheme } from '@material-ui/core';
import MuiButton from '../../../../../../UI/MuiButton';

interface PasteUploaderOptionEditProps {
  name: string;
  title: string;
  emptyTitle: string;
  defaultValue?: any;
  value?: any[];
  render?: (value: any) => JSX.Element;

  onUpdate: (value: any, path: string) => void;
}

const PasteUploaderFieldOptionEdit: FC<PasteUploaderOptionEditProps> = ({
  value,
  name,
  title,
  defaultValue = 'field_name',
  emptyTitle,
  render,
  onUpdate,
}) => {
  const theme = useTheme();

  const defaultRender = (fieldName: string, idx: number) => (
    <HiddenInput
      initValue={fieldName}
      key={randomString(3)}
      onSubmit={(str) =>
        str
          ? onUpdate(str, `.${name}[${idx}]`)
          : onUpdate(
              value?.filter((_, i) => i !== idx),
              `.${name}`,
            )
      }
    />
  );

  return (
    <Box display="flex" flexWrap="wrap" style={{ gap: theme.spacing(0.5) }}>
      <Typography variant="h6">{title}</Typography>
      {!value || value.length === 0 ? (
        <Typography variant="h6">{emptyTitle}</Typography>
      ) : (
        value.map(render || defaultRender)
      )}
      <MuiButton
        onClick={() => onUpdate((value || []).concat(defaultValue), `.${name}`)}
        color="success"
      >
        Add
      </MuiButton>
    </Box>
  );
};

export default PasteUploaderFieldOptionEdit;
