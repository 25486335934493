import React, { Component } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import NewIdealBrand from './NewIdealBrand';
import NewBrandMapping from './NewBrandMapping';

interface IProps {
  selectedItem: { id: string; brand: string } | null;

  onActionComplete(): void;
}

interface IState {
  useAliasAsBrand: boolean;
  brandInput: string;
}

class BrandsMapperAction extends Component<IProps, IState> {
  state: IState = { useAliasAsBrand: false, brandInput: '' };

  render() {
    const { selectedItem } = this.props;

    return (
      <Tabs id="brands-mapper-action-select" defaultActiveKey="first">
        <Tab eventKey="first" title="Add Brand Mapping">
          <NewBrandMapping
            selectedItem={selectedItem}
            onActionComplete={this.props.onActionComplete}
          />
        </Tab>
        <Tab eventKey="second" title="Add new ideal Brand">
          <NewIdealBrand
            selectedItem={selectedItem}
            onActionComplete={this.props.onActionComplete}
          />
        </Tab>
      </Tabs>
    );
  }
}

export default BrandsMapperAction;
