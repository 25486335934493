import React from 'react';
import { CircularProgress } from '@material-ui/core';

/**
 * Just stub for loading content,
 * render it, when You need to wait for something
 */

const Spinner: React.FC<{ size?: number }> = ({ size = 60 }) => (
  <div
    style={{
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <CircularProgress size={size} />
  </div>
);

export default Spinner;
