import React, { Component } from 'react';
import Spinner from '../../../UI/Spinner';
import HttpDatastore from 'rollun-ts-datastore';
import {
  BRAND_MAP_DATA_STORE,
  UNKNOWN_BRANDS_DATA_STORE,
} from './BrandsMapper';
import { Button, ErrorView } from '../../../UI';
import { ErrorType } from '../../../utils/common.types';
import { httpErrorHandler } from '../../../utils/common.utils';

interface IProps {
  selectedItem: { id: string; brand: string } | null;

  onActionComplete(): void;
}

interface IState {
  useAliasAsBrand: boolean;
  brandInput: string;
  loading: boolean;
  error: ErrorType | null;
}

class NewIdealBrand extends Component<IProps, IState> {
  state: IState = {
    useAliasAsBrand: false,
    brandInput: '',
    loading: false,
    error: null,
  };

  BrandsDataStore = new HttpDatastore(BRAND_MAP_DATA_STORE);
  UnknownBrandsDataStore = new HttpDatastore(UNKNOWN_BRANDS_DATA_STORE);

  handleBrandInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ brandInput: e.target.value });
  };

  handleCheckboxSwitch = () => {
    if (!this.props.selectedItem) return;
    if (!this.state.useAliasAsBrand) {
      this.setState({
        useAliasAsBrand: true,
        brandInput: this.props.selectedItem.brand,
      });
    } else {
      this.setState({ useAliasAsBrand: false, brandInput: '' });
    }
  };

  _newIdealBrandSameAsAlias = async (brand: string, id: string) => {
    await this.UnknownBrandsDataStore.delete(id);
    return this.BrandsDataStore.create({ alias: brand, brand_id: null });
  };

  _newIdealBrandCustomName = async (brand: string, id: string) => {
    if (!this.props.selectedItem) return;
    const {
      selectedItem: { brand: alias },
    } = this.props;
    const newBrand: any = await this._newIdealBrandSameAsAlias(brand, id);
    await this.BrandsDataStore.create({ brand_id: newBrand.id, alias });
  };

  handleNewBrandCreate = () => {
    const { brandInput, useAliasAsBrand } = this.state;
    if (!this.props.selectedItem) return;
    this.setState({ loading: true });
    const {
      selectedItem: { id },
    } = this.props;
    const promise: Promise<any> = useAliasAsBrand
      ? this._newIdealBrandSameAsAlias(brandInput, id)
      : this._newIdealBrandCustomName(brandInput, id);
    promise
      .then(() => this.props.onActionComplete())
      .catch((e) =>
        httpErrorHandler(e, (code, text) =>
          this.setState({ error: { code, text }, loading: false }),
        ),
      );
  };

  render() {
    const { selectedItem } = this.props;

    const { useAliasAsBrand, brandInput, error, loading } = this.state;

    if (!selectedItem) return <div>No item selected!</div>;

    if (error) {
      return (
        <ErrorView error={error}>
          <Button
            color="primary"
            onClick={() => this.setState({ error: null, loading: false })}
          >
            Back
          </Button>
        </ErrorView>
      );
    }

    if (loading) {
      return (
        <div className="my-5">
          <Spinner />
        </div>
      );
    }

    return (
      <>
        <div className="my-2">
          Brand:
          <input
            type="text"
            disabled={useAliasAsBrand}
            value={brandInput || ''}
            onChange={this.handleBrandInput}
            className="form-control"
          />
          Use alias as brand{' '}
          <input
            type="checkbox"
            onChange={() => this.handleCheckboxSwitch()}
            className="custom-checkbox"
          />
        </div>
        <div>
          Alias:
          <input
            type="text"
            className="form-control"
            disabled
            defaultValue={selectedItem.brand}
          />
        </div>
        <div className="d-flex justify-content-end">
          <Button
            className="m-1"
            color="primary"
            onClick={this.handleNewBrandCreate}
          >
            Create
          </Button>
        </div>
      </>
    );
  }
}

export default NewIdealBrand;
