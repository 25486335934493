import React from 'react';
import AbstractService, { AbstractServiceProps } from '../AbstractService';
import { ErrorView, Spinner } from '../../UI';
import AppContainer from '../../layout/AppContainer/AppContainer';
import ImportantDoc from './components/ImportantDoc';

class ImportantDocs extends AbstractService<AbstractServiceProps> {
  render() {
    const { appConfig, error } = this.state;

    if (error) {
      return (
        <AppContainer services={{}}>
          <div className="w-100 h-100 d-flex flex-column justify-content-center">
            <ErrorView error={error}>
              <div className="d-flex justify-content-center p-3">
                <div
                  className="btn btn-primary cursor-pointer"
                  onClick={this.reloadHome}
                >
                  Home
                </div>
              </div>
            </ErrorView>
          </div>
        </AppContainer>
      );
    }

    if (appConfig === null) {
      return <Spinner />;
    }

    const { user, role } = this.props.userIdentity;
    return (
      <AppContainer
        currentResourceName={this.props.resourceName}
        services={this.props.availableServices}
        user={user}
        role={role}
      >
        <ImportantDoc />
      </AppContainer>
    );
  }
}

export default ImportantDocs;
