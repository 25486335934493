import React, { FC, useEffect, useState } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';
import { Button, ErrorView } from '../../../UI';
import _ from 'lodash';
import Dialog from '../../../UI/Dialog';
import {
  Container,
  Step,
  StepLabel,
  Stepper,
  Typography,
  makeStyles,
  Box,
} from '@material-ui/core';
import axios from 'axios';
import {
  httpErrorHandler,
  httpErrorHandlerPromised,
} from '../../../utils/common.utils';
import { ErrorType } from '../../../utils/common.types';
import PreviousButton from './PreviousButton';
import NextButton from './NextButton';
import { downloadAsCSV } from 'rollun-ts-utils/dist';
import CustomAlert from '../../../UI/CustomAlert';
import MuiTooltip from '../../../UI/MuiTooltip';

const useStyles = makeStyles(() => ({
  brandInputsWrap: {
    width: '100%',
    display: 'flex',
    columnGap: '20px',
  },
  inputWrap: {
    flexGrow: 1,
  },
  mergeButtons: {
    width: '100%',
    display: 'flex',
    columnGap: '20px',
  },
}));

interface IProps {
  selectedBrand: any;
}

interface MigrateBrandExplain {
  errors: any[];
  warnings: any[];
  successes: any[];
  items_change: any[];
  dead_items: any[];
  black_list: any[];
}

const MIGRATE_BRAND_TO_ALIASES = '/api/webhook/MigrateBrandToAliases';
const MIGRATE_BRAND_TO_ALIASES_EXPLAIN =
  '/api/webhook/MigrateBrandToAliasesExplain';

const BrandsForm: FC<IProps> = ({ selectedBrand }) => {
  const [ignoreWarnings, setIgnoreWarnings] = useState(false);
  const [mergeSucceed, setMergeSucceed] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [brandFrom, setBrandFrom] = useState('');
  const [brandTo, setBrandTo] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [loadingStep, setLoadingStep] = useState(false);
  const [mergeBrandInfo, setMergeBrandInfo] = useState<any>({});
  const [mergeBrandChanges, setMergeBrandChanges] = useState<any>({});
  const [errorStep, setErrorStep] = useState<ErrorType | null>(null);
  const classes = useStyles();

  const steps = [
    'Check brand info',
    'Check merge brand conflict',
    'Changes preview',
    'Merge result',
  ];

  useEffect(() => {
    setBrandFrom(
      selectedBrand && selectedBrand.alias_candidate
        ? selectedBrand.alias_candidate
        : '',
    );
    setBrandTo(selectedBrand && selectedBrand.alias ? selectedBrand.alias : '');
  }, [selectedBrand]);

  const closeModal = () => {
    setModalOpen(false);
    setCurrentStep(0);
    setErrorStep(null);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setModalOpen(true);
  };

  const mergeBrands = async () => {
    setLoadingStep(true);
    setCurrentStep((currentStep) => currentStep + 1);
    try {
      await axios.post(MIGRATE_BRAND_TO_ALIASES, {
        first: brandFrom,
        second: brandTo,
        ignore_warning: ignoreWarnings,
      });
      setMergeSucceed(true);
    } catch (e) {
      const { code, text } = await httpErrorHandlerPromised(e);
      setErrorStep({
        code,
        text,
      });
      setMergeSucceed(false);
    }
    setLoadingStep(false);
    setSnackBarOpen(true);
  };

  const swapBrandNames = () => {
    setBrandTo(brandFrom);
    setBrandFrom(brandTo);
  };

  const handleExplainMigrate = async () => {
    setLoadingStep(true);
    try {
      const res = await axios.post<MigrateBrandExplain>(
        MIGRATE_BRAND_TO_ALIASES_EXPLAIN,
        {
          first: brandFrom,
          second: brandTo,
        },
      );

      setMergeBrandInfo(res.data);
    } catch (e) {
      httpErrorHandler(e, (code, text) => {
        setErrorStep({
          code,
          text,
        });
      });
      setMergeSucceed(false);
      setSnackBarOpen(true);
    }
    setLoadingStep(false);
    setCurrentStep((currentStep) => currentStep + 1);
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <div>
            <div className="mb-4">
              <Typography variant="subtitle1">
                First brand: <strong>{brandFrom}</strong>
              </Typography>
              <Typography variant="subtitle1">
                Second brand: <strong>{brandTo}</strong>
              </Typography>
              <Typography variant="subtitle1">
                Ignore warnings: <strong>{`${ignoreWarnings}`}</strong>
              </Typography>
            </div>
            <div
              style={{
                display: 'grid',
                columnGap: '10px',
                gridTemplateColumns: 'repeat(2, 1fr)',
              }}
            >
              <PreviousButton disabled={true} />
              <NextButton
                loadingStep={loadingStep}
                onClick={handleExplainMigrate}
              />
            </div>
          </div>
        );
      case 1:
        return (
          <div>
            <div
              style={{
                maxHeight: '300px',
                overflowX: 'scroll',
              }}
            >
              {mergeBrandInfo.errors.length === 0 ? (
                <div>All good, no errors</div>
              ) : (
                mergeBrandInfo.errors.map((error: any, index: number) => (
                  <div key={index}>
                    <pre>{JSON.stringify(error, null, 2)}</pre>
                    resolve conflict before merging brands
                    <br />
                  </div>
                ))
              )}
              <br />
              <div>
                {mergeBrandInfo.warnings.length !== 0 ? (
                  <div>
                    Warnings:{' '}
                    {mergeBrandInfo.warnings.map(
                      (warning: any, index: number) => (
                        <div key={index}>
                          <pre>{JSON.stringify(warning, null, 2)}</pre>
                          resolve warning conflicts before merging brands, but
                          you can merge anyways if you have ignore warning
                          checkbox checked
                          <br />
                        </div>
                      ),
                    )}
                  </div>
                ) : null}
              </div>
            </div>
            <div
              style={{
                display: 'grid',
                columnGap: '10px',
                gridTemplateColumns: 'repeat(2, 1fr)',
              }}
            >
              <PreviousButton
                onClick={() => setCurrentStep((currentStep) => currentStep - 1)}
              />
              <NextButton
                loadingStep={loadingStep}
                onClick={async () => {
                  setMergeBrandChanges({
                    successes: mergeBrandInfo.successes,
                    changes: mergeBrandInfo.items_change,
                    aliases: mergeBrandInfo.aliases,
                    deadItems: mergeBrandInfo.dead_items,
                  });
                  setCurrentStep((currentStep) => currentStep + 1);
                }}
                disabled={
                  mergeBrandInfo.errors.length !== 0 ||
                  (mergeBrandInfo.warnings.length !== 0 && !ignoreWarnings)
                }
              />
            </div>
          </div>
        );
      case 2:
      case 3:
        return (
          <div>
            <div
              style={{
                maxHeight: '300px',
                overflowX: 'scroll',
              }}
            >
              <div className="h5">Brand from: {mergeBrandInfo.from}</div>
              <div className="h5">Brand to: {mergeBrandInfo.to}</div>
              {Object.keys(mergeBrandChanges).map((changeName) => (
                <div key={changeName}>
                  {changeName}:
                  {mergeBrandChanges[changeName].length === 0 ? (
                    ' []'
                  ) : (
                    <pre>
                      {JSON.stringify(mergeBrandChanges[changeName], null, 2)}
                    </pre>
                  )}
                </div>
              ))}
            </div>
            <div className="w-100 d-flex justify-content-between flex-column">
              {Object.keys(mergeBrandChanges).map((changeName) => (
                <div key={changeName} className="w-100 mb-2">
                  <Button
                    className="w-100"
                    color="primary"
                    id={mergeBrandChanges[changeName]}
                    onClick={() =>
                      downloadAsCSV(
                        mergeBrandChanges[changeName],
                        `${changeName}.csv`,
                      )
                    }
                  >
                    Download {changeName} as csv file
                  </Button>
                </div>
              ))}
              {currentStep === 2 ? (
                <div className="w-100 mb-2">
                  <Button
                    className="w-100"
                    color="success"
                    id="final-merge"
                    onClick={() => mergeBrands()}
                  >
                    Merge
                  </Button>
                </div>
              ) : (
                <div className="w-100 mb-2">
                  <Button
                    className="w-100"
                    color="danger"
                    id="final-merge"
                    onClick={() => closeModal()}
                  >
                    Close
                  </Button>
                </div>
              )}
            </div>
          </div>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center h-100">
      <Form onSubmit={handleSubmit}>
        <Box className={classes.brandInputsWrap}>
          <MuiTooltip
            className={classes.inputWrap}
            title="Brand alias candidate"
            arrow
          >
            <Form.Group controlId="brandFrom">
              <Form.Label>First brand</Form.Label>
              <Form.Control
                type="text"
                placeholder="Brand name"
                value={brandFrom}
                onChange={(e: React.FormEvent<FormControlProps>) => {
                  setBrandFrom((e.target as any).value);
                }}
              />
            </Form.Group>
          </MuiTooltip>
          <MuiTooltip className={classes.inputWrap} title="Brand alias" arrow>
            <Form.Group controlId="brandTo">
              <Form.Label>Second brand</Form.Label>
              <Form.Control
                type="text"
                placeholder="Brand name"
                value={brandTo}
                onChange={(e: React.FormEvent<FormControlProps>) => {
                  setBrandTo((e.target as any).value);
                }}
              />
            </Form.Group>
          </MuiTooltip>
        </Box>
        <Form.Group controlId="ignoreWarnings">
          <Form.Check
            type="checkbox"
            label="Ignore warnings"
            onChange={() =>
              setIgnoreWarnings((ignoreWarnings) => !ignoreWarnings)
            }
          />
        </Form.Group>
        <Box className={classes.mergeButtons}>
          <Button
            color="primary"
            type="submit"
            id="form-submit"
            className="w-100"
            disabled={_.isEmpty(brandFrom) || _.isEmpty(brandTo)}
          >
            Merge
          </Button>
          <Button
            color="primary"
            id="swap-brand-names"
            className="w-100"
            onClick={() => swapBrandNames()}
            disabled={_.isEmpty(brandFrom) || _.isEmpty(brandTo)}
          >
            Swap brand names
          </Button>
        </Box>
      </Form>
      <Dialog
        title="Confirm"
        isOpen={modalOpen}
        options={{
          centered: true,
          size: 3,
        }}
        onClose={closeModal}
        onDecline={closeModal}
        declineBtnText="Cancel"
      >
        <Stepper activeStep={currentStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Container>
          {errorStep ? (
            <ErrorView error={errorStep} />
          ) : (
            getStepContent(currentStep)
          )}
        </Container>
      </Dialog>
      <CustomAlert
        isOpen={snackBarOpen}
        onClose={() => setSnackBarOpen(false)}
        msg={
          mergeSucceed
            ? 'The brands has successfully been merged'
            : 'The brands has not been merged due to an error'
        }
        alertType={mergeSucceed ? 'success' : 'error'}
      />
    </div>
  );
};

export default BrandsForm;
