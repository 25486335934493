import { Query, QueryStringifier, RqlParser } from 'rollun-ts-rql';
import { COLUMN_HEADER_ALIAS } from './constants';
import { encodeRqlString } from '../../../utils/common.utils';
import _ from 'lodash';

export const replaceAliasesInQuery = (
  q: Query,
  fieldNames: Array<string>,
): Query => {
  let queryWithoutAlias = QueryStringifier.stringify(q);
  for (const [value, alias] of Object.entries(COLUMN_HEADER_ALIAS)) {
    if (fieldNames.includes(alias)) continue;
    queryWithoutAlias = queryWithoutAlias.replace(
      encodeRqlString(alias),
      encodeRqlString(value),
    );
  }
  return new RqlParser().parse(queryWithoutAlias);
};

export const replaceAliasInObject = (
  o: Record<string, unknown>,
  fieldNames: Array<string>,
): Record<string, unknown> => {
  const aliasesEntries = Object.entries(COLUMN_HEADER_ALIAS);
  return _.mapKeys(o, (_, key) => {
    if (fieldNames.includes(key)) return key;
    const aliasEntry = aliasesEntries.find(([, value]) => value === key);
    return aliasEntry ? aliasEntry[0] : key;
  });
};

/**
 * Casts value to string if it is not a number or something like - 01234
 * otherwise number will be returned
 * @param value
 * @param options
 */

export const castValueForSearch = (
  value: string,
  options?: { asNumber?: boolean },
): number | string => {
  if (
    options?.asNumber &&
    +value < Number.MAX_SAFE_INTEGER &&
    +value > Number.MIN_SAFE_INTEGER
  ) {
    return +value;
  }

  if (options?.asNumber === false) {
    return value;
  }

  if (
    typeof +value === 'number' &&
    (+value > Number.MAX_SAFE_INTEGER || +value < Number.MIN_SAFE_INTEGER)
  ) {
    return value;
  }

  return value !== '0' && (_.startsWith(value, '0') || isNaN(+value))
    ? value
    : +value;
};
