import { Tooltip, withStyles } from '@material-ui/core';
import { zIndexes } from '../themeProvider';

const MuiTooltip = withStyles({
  tooltip: {
    fontSize: '1.1rem',
    zIndex: zIndexes.biggest,
  },
  popper: {
    zIndex: zIndexes.biggest,
  },
})(Tooltip);

export default MuiTooltip;
