import React, { Component } from 'react';
import { Query, Select } from 'rollun-ts-rql';
import HttpDatastore from 'rollun-ts-datastore';
import _ from 'lodash';
import { httpErrorHandlerPromised } from '../utils/common.utils';
import { downloadAsCSV } from 'rollun-ts-utils/dist';
import Tags from './Tags';
import MuiIconButton from './MuiIconButton';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  withStyles,
  WithStyles,
  Select as MuiSelect,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Typography,
  DialogActions,
} from '@material-ui/core';
import MuiButton from './MuiButton';

interface IProps extends WithStyles {
  query: Query;
  idField: string;
  fieldNames: Array<string>;
  datastoreURL: string;
  stringifyTags: boolean;
}

type TagAction = 'add' | 'remove';

interface IState {
  isOpen: boolean;
  errors: Array<string> | null;
  isLoading: boolean;
  progress: string | null;
  tagFieldName: string;
  action: TagAction;
  tags: Array<string>;
  ignoreLimit: boolean;
}

const classes = () => ({
  root: {
    display: 'flex',
    width: '100%',
    minHeight: 250,
    flexDirection: 'column' as const,
    justifyContent: 'space-between',
  },
});

class TagsUpdater extends Component<IProps, IState> {
  private tagsDS: HttpDatastore;

  constructor(props: IProps) {
    super(props);

    this.tagsDS = new HttpDatastore(props.datastoreURL, {
      idField: props.idField,
    });

    this.state = {
      isOpen: false,
      errors: null,
      isLoading: false,
      progress: null,
      tagFieldName:
        props.fieldNames.find((field) => field.includes('tag')) ||
        props.fieldNames[0],
      action: 'add',
      tags: [],
      ignoreLimit: false,
    };
  }

  static getDerivedStateFromProps(newProps: IProps, prevState: IState) {
    // if tagFieldName not set, update tagFieldName from  newProps.fieldNames
    if (!prevState.tagFieldName) {
      return {
        tagFieldName:
          newProps.fieldNames.find((field) => field.includes('tag')) ||
          newProps.fieldNames[0],
      };
    }
    return null;
  }

  updateProgress = (progress: string) => this.setState({ progress });

  updateTags = async (row: {
    [key: string]: any;
  }): Promise<{ success: boolean; message?: string }> => {
    const { idField, stringifyTags } = this.props;
    const { tagFieldName, tags, action } = this.state;

    try {
      const oldTags: Array<string> =
        typeof row[tagFieldName] === 'string'
          ? row[tagFieldName] === ''
            ? []
            : JSON.parse(row[tagFieldName])
          : row[tagFieldName] || [];

      const newTags =
        action === 'add'
          ? // add new tags, if they does not exist in old tags
            oldTags.concat(
              tags.filter(
                (newTag) => !oldTags.find((oldTag) => oldTag === newTag),
              ),
            )
          : // filter out new tags from old tags
            oldTags.filter(
              (oldTag) => !tags.find((newTag) => newTag === oldTag),
            );

      if (newTags.length !== oldTags.length) {
        await this.tagsDS.update({
          [idField]: row[idField],
          [tagFieldName]: stringifyTags ? JSON.stringify(newTags) : newTags,
        });
      }
      return { success: true };
    } catch (e) {
      const { text } = await httpErrorHandlerPromised(e);
      return {
        success: false,
        message: `Error while updating item with ${idField} ${row[idField]}: ${text}`,
      };
    }
  };

  handleTagsUpdate = async () => {
    this.updateProgress('Fetching data by filter');
    const { query, idField } = this.props;
    const { tagFieldName, ignoreLimit } = this.state;
    const queryCopy = _.cloneDeep(query);
    // remove limit
    if (ignoreLimit) {
      queryCopy.limitNode = undefined;
    }
    queryCopy.selectNode = new Select([idField, tagFieldName]);
    let errors: Array<string> = [];
    try {
      const data = await this.tagsDS.query(queryCopy);
      const chunks = _.chunk(data, 5);

      for (let i = 0, len = chunks.length; i < len; i++) {
        this.updateProgress(`Updating chunk(5 items) #${i + 1}/${len}...`);
        const chunkResult = await Promise.all(chunks[i].map(this.updateTags));
        errors = errors.concat(
          chunkResult
            .filter(({ success }) => !success)
            .map(({ message }) => message || 'Unknown error'),
        );
      }

      this.updateProgress('Done, You can close the window now.');
    } catch (err) {
      console.log(err);
      this.updateProgress('Global error.');
      const { text } = await httpErrorHandlerPromised(err);
      errors.push(`Global error: ${text}`);
    } finally {
      if (errors.length > 0) {
        this.setState({ errors });
      }
    }
  };

  handleDownloadErrors = () => {
    downloadAsCSV(
      (this.state.errors || []).map((error) => ({ error })),
      'errors_while_updating_tags.csv',
    );
  };

  handleIgnoreLimit = () => {
    this.setState(({ ignoreLimit }) => ({ ignoreLimit: !ignoreLimit }));
  };

  render() {
    const { fieldNames, classes } = this.props;

    const {
      isOpen,
      isLoading,
      errors,
      progress,
      tagFieldName,
      action,
      ignoreLimit,
    } = this.state;

    if (!isOpen) {
      return (
        <MuiIconButton
          title="Update tags"
          color="#000"
          width={40}
          height={40}
          iconName="tags"
          onClick={() => this.setState({ isOpen: true })}
        />
      );
    }

    return (
      <Dialog
        open={isOpen}
        onClose={() =>
          this.setState({
            isOpen: false,
            progress: null,
            tags: [],
          })
        }
      >
        <DialogTitle>
          Update tags for multiple items for current filter without limit
        </DialogTitle>
        <DialogContent className={classes.root}>
          <FormControl variant="outlined">
            <InputLabel id="field-name">Field name</InputLabel>
            <MuiSelect
              value={tagFieldName}
              onChange={(e) => {
                this.setState({ tagFieldName: e.target.value as string });
              }}
              labelId="field-name"
              variant="outlined"
              label="Field name"
              disabled={isLoading}
            >
              {fieldNames.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </MuiSelect>
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel id="add-or-remove">Add or remove</InputLabel>
            <MuiSelect
              disabled={isLoading}
              value={action}
              onChange={(e) => {
                this.setState({ action: e.target.value as TagAction });
              }}
              labelId="add-or-remove"
              variant="outlined"
              label="Add or remove"
            >
              {['add', 'remove'].map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </MuiSelect>
          </FormControl>
          <Tags
            onChange={(tags) => this.setState({ tags })}
            placeholder="Start typing tag name..."
            isDisabled={isLoading}
          />
          <Box width="100%">
            <Box textAlign="center">
              {ignoreLimit ? 'Limit is ignored' : 'Limit is not ignored'}
            </Box>
            <MuiButton
              fullWidth
              color="secondary"
              onClick={() => this.handleIgnoreLimit()}
            >
              Ignore limit
            </MuiButton>
          </Box>
          {errors && (
            <Box>
              <Typography variant="h5" color="error" align="center">
                There was some errors during update
              </Typography>
              <MuiButton
                color="primary"
                fullWidth
                onClick={() => this.handleDownloadErrors()}
              >
                Download errors
              </MuiButton>
            </Box>
          )}
          {progress && <Typography variant="h5">{progress}</Typography>}
        </DialogContent>
        <DialogActions>
          <MuiButton
            color="primary"
            fullWidth
            disabled={isLoading}
            onClick={() => this.handleTagsUpdate()}
          >
            {action}
          </MuiButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(classes)(TagsUpdater);
