import React, { FC } from 'react';
import List from '@material-ui/core/List';
import { Box, makeStyles } from '@material-ui/core';
import { LeftMenuType } from './LeftMenu';
import LeftMenuChildrens from './LeftMenuChildrens';

const useStyles = makeStyles(() => ({
  leftMenuContainer: {
    height: '100%',
  },
}));

interface IProps {
  menuItems: LeftMenuType[];
}

const MuiLeftMenu: FC<IProps> = ({ menuItems }) => {
  const classes = useStyles();

  return (
    <Box className={classes.leftMenuContainer}>
      <List>
        <LeftMenuChildrens menuItems={menuItems} leftPadding={10} />
      </List>
    </Box>
  );
};

export default MuiLeftMenu;
