import React, { FC } from 'react';
import {
  CopyPasteUploaderParams,
  DataParsers,
} from '../../../../../Table/components/CopyPasteUploaders/CopyPasteUploader';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { MenuItem, Select, useTheme } from '@material-ui/core';

interface SelectCustomParserProps {
  pasteUploaderParams: CopyPasteUploaderParams;
  onUpdate: (value: any, path: string) => void;
}

const SelectCustomParser: FC<SelectCustomParserProps> = ({
  pasteUploaderParams,
  onUpdate,
}) => {
  const dataParsers = Object.values(DataParsers);
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center" style={{ gap: theme.spacing(1) }}>
      <Typography variant="h6">Custom data parser: </Typography>
      <Select
        variant="outlined"
        value={pasteUploaderParams.customDataParser || dataParsers[0]}
        onChange={(e) => {
          onUpdate(
            e.target.value === dataParsers[0] ? undefined : e.target.value,
            '.customDataParser',
          );
        }}
      >
        {dataParsers.map((parser) => (
          <MenuItem value={parser}>{parser}</MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default SelectCustomParser;
