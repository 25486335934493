import { Typography } from '@material-ui/core';
import { isObject } from 'lodash';
import React, { FC } from 'react';
import { isJSONObject } from 'rollun-ts-utils';

interface ActivityValueProps {
  value: string;
}

export const ActivityValue: FC<ActivityValueProps> = ({ value }) => {
  return isJSONObject(value) ? (
    <pre
      style={{
        margin: 0,
      }}
    >
      {JSON.stringify(JSON.parse(value), null, 2)}
    </pre>
  ) : isObject(value) ? (
    <Typography>{JSON.stringify(value)}</Typography>
  ) : (
    <Typography>{value}</Typography>
  );
};
