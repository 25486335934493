import React, { useState } from 'react';
import { CopyPasteUploaderParams } from '../../../../Table/components/CopyPasteUploaders/CopyPasteUploader';
import _ from 'lodash/fp';
import AddableIconButton from './AddableIconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContent } from '@material-ui/core';
import PasteUploaderFieldOptionsEdit from './CopyPasteUploaderEdit/PasteUploaderFieldOptions.edit';
import PasteUploaderOptionsEdit from './CopyPasteUploaderEdit/PasteUploaderOptions.edit';
import SelectCustomParser from './CopyPasteUploaderEdit/SelectCustomParser';
import SelectValidators from './CopyPasteUploaderEdit/SelectValidators';

interface IProps {
  pasteUploaderParams?: CopyPasteUploaderParams;
  onUpdate: (
    params: CopyPasteUploaderParams | string | null | any,
    path?: string,
  ) => void;
}

const PasteUploaderEdit: React.FC<IProps> = ({
  pasteUploaderParams,
  onUpdate,
}) => {
  const [isOpen, setOpen] = useState(false);

  const updatePasteUploader = () => {
    pasteUploaderParams ? onUpdate(null) : onUpdate({ fieldNames: ['name'] });
  };

  return (
    <>
      <AddableIconButton
        name="pasteUploaderParams"
        icon="upload"
        color="primary"
        value={pasteUploaderParams}
        defaultValue={true}
        handleUpdate={updatePasteUploader}
        onClick={() => setOpen(true)}
      />
      <Dialog open={isOpen} onClose={() => setOpen(false)}>
        <DialogTitle>Edit paste uploader params</DialogTitle>
        {pasteUploaderParams && (
          <DialogContent>
            <PasteUploaderFieldOptionsEdit
              pasteUploaderParams={pasteUploaderParams}
              onUpdate={onUpdate}
            />
            <PasteUploaderOptionsEdit
              pasteUploaderParams={pasteUploaderParams}
              onUpdate={onUpdate}
            />
            <SelectCustomParser
              pasteUploaderParams={pasteUploaderParams}
              onUpdate={onUpdate}
            />

            <SelectValidators
              validators={pasteUploaderParams.validators}
              fieldNames={pasteUploaderParams.fieldNames}
              onUpdate={onUpdate}
            />
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};

export default React.memo(
  PasteUploaderEdit,
  (prevProps: IProps, nextProps: IProps) =>
    _.isEqual(prevProps.pasteUploaderParams, nextProps.pasteUploaderParams),
);
