import React, { Component } from 'react';
import { AppConfig } from '../../../../AbstractService';
import { Card, HiddenInput } from '../../../../../UI';
import _ from 'lodash/fp';
import '../index.css';
import HttpDatastore from 'rollun-ts-datastore/dist';
import { FrontConfigRow } from '../../../../RootApp/Root.app';
import WebHooksEdit from './WebHooks.edit';
import Paginator from '../../../../../controls/Paginator/Paginator';
import FieldsConfigEdit from './FieldsConfig.edit';
import { IEditorProps, IEditorState } from '../EditService';
import {
  Rule,
  RULES_DS,
} from '../../../../ACLRulesEditor/components/RulesTreeEditor';
import RulesEditor from './RulesEditor';
import { Box, Typography } from '@material-ui/core';
import MuiButton from '../../../../../UI/MuiButton';
import TableButtonsEdit from './TableButtons.edit';
import TableHeaderButtons from './TableHeaderButtons';

class GridEdit extends Component<IEditorProps, IEditorState> {
  constructor(props: IEditorProps) {
    super(props);
    this.state = {
      config: props.config,
      isLoading: false,
      isError: false,
    };
  }

  servicesConfigDS = new HttpDatastore<FrontConfigRow>(
    '/api/datastore/frontConfigDataStore',
  );
  rulesDS = new HttpDatastore<Rule>(RULES_DS);

  updateByPath = (path: string, newValue: any) => {
    this.setState({
      config: _.update(path)(() => newValue)(this.state.config),
    });
  };

  deleteByPath = (path: string) => {
    this.setState({ config: _.unset(path)(this.state.config) });
  };

  handleConfigSave = (newConfig: AppConfig) => {
    this.setState({ isLoading: true, isError: false });
    this.servicesConfigDS
      .update({ id: this.props.serviceID, config: newConfig })
      .catch((err) => {
        console.log('err', err);
        this.setState({ isError: true });
      })
      .finally(() => this.setState({ isLoading: false }));
  };

  render() {
    const { config, isLoading, isError } = this.state;
    const gridParams = config.appParams.gridParams;

    if (!gridParams) {
      return <h3>Couldn't find gridParams field in appParams in config.</h3>;
    }
    return (
      <Box>
        <Box display="flex" justifyContent="space-between" marginY={1}>
          <Typography variant="h4">Table edit</Typography>
          <MuiButton
            color={isError ? 'error' : 'primary'}
            disabled={isLoading}
            onClick={() => this.handleConfigSave(config)}
          >
            Save
          </MuiButton>
        </Box>
        <TableHeaderButtons
          buttons={gridParams.tableHeaderButtons}
          updateByPath={this.updateByPath}
        />
        <WebHooksEdit
          hooks={gridParams.hooks}
          onHooksUpdate={(hooks, path?) => {
            const absolutePath = `appParams.gridParams.hooks${path || ''}`;
            hooks
              ? this.updateByPath(absolutePath, hooks)
              : this.deleteByPath(absolutePath);
          }}
        />
        <Card noMargin className="d-flex justify-content-between my-2">
          <HiddenInput
            initValue={config.appName}
            inputClassName="w-50"
            onSubmit={(str) => this.updateByPath('appName', str)}
          />
          <MuiButton color="info">Filters table</MuiButton>
        </Card>
        {gridParams.userNote && (
          <Card noMargin className="my-2">
            <h4 className="mt-2">User note:</h4>
            <HiddenInput
              initValue={gridParams.userNote.replace(/;/g, '\n')}
              as={'textarea'}
              preventBlurOnEnter
              onSubmit={(str) =>
                str
                  ? this.updateByPath(
                      'appParams.gridParams.userNote',
                      str.replace(/\r?\n/g, ';'),
                    )
                  : this.deleteByPath('appParams.gridParams.userNote')
              }
            />
          </Card>
        )}
        <TableButtonsEdit
          gridParams={gridParams}
          deleteByPath={this.deleteByPath}
          updateByPath={this.updateByPath}
        />
        <FieldsConfigEdit
          onUpdate={(newConfig, path = '') => {
            console.log('new Config', newConfig);
            const absolutePath = `appParams.gridParams.fieldsConfig${path}`;
            newConfig || (Array.isArray(newConfig) && newConfig.length > 0)
              ? this.updateByPath(absolutePath, newConfig)
              : this.deleteByPath(absolutePath);
          }}
          fieldsConfig={gridParams.fieldsConfig}
        />
        <Paginator pageSizeOptions={[20, 50, 100]} />
        <h3>Other settings:</h3>
        <h4 className="mt-2">Datastore URL:</h4>
        <HiddenInput
          initValue={gridParams.datastoreUrl}
          onSubmit={(str) =>
            str
              ? this.updateByPath('appParams.gridParams.datastoreUrl', str)
              : this.deleteByPath('appParams.gridParams.datastoreUrl')
          }
        />
        <h4 className="mt-2">ID Field:</h4>
        <HiddenInput
          initValue={gridParams.idField || 'id'}
          onSubmit={(str) =>
            str === 'id'
              ? this.deleteByPath('appParams.gridParams.idField')
              : this.updateByPath('appParams.gridParams.idField', str)
          }
        />
        <RulesEditor appParams={config} />
      </Box>
    );
  }
}

export default GridEdit;
