import { FluidFormField } from '../UI/FluidForm';
import { PureComponent, ReactNode } from 'react';
import React from 'react';
import { Button, FluidForm, Dialog } from '../UI';

export interface NewFilterCreatorDialogProps {
  formConfig: FluidFormField[];
  isDisabled?: boolean;
  title?: string;

  onFormSubmit(data: Record<string, unknown>): void;
}

export default class NewFilterCreatorDialog extends PureComponent<NewFilterCreatorDialogProps> {
  state: { isOpen: boolean } = { isOpen: false };

  render(): ReactNode {
    const { formConfig, title } = this.props;

    return (
      <div>
        <Button
          id="save-filter"
          block
          color="success"
          onClick={() => this.handleOpenDialog()}
        >
          Save filter
        </Button>
        <Dialog
          isOpen={this.state.isOpen}
          title={title}
          onClose={() => this.handleCloseDialog()}
          options={{
            size: 2,
            centered: true,
          }}
        >
          <FluidForm formConfig={formConfig} onFormSubmit={this.onFormSubmit} />
        </Dialog>
      </div>
    );
  }

  protected onFormSubmit = (data: Record<string, unknown>) => {
    this.props.onFormSubmit(data);
    this.handleCloseDialog();
  };

  protected handleCloseDialog() {
    this.setState({ isOpen: false });
  }

  protected handleOpenDialog() {
    this.setState({ isOpen: true });
  }
}
