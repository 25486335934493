import { Employees } from '../../api-clients/types';
import { ApiClientsEmployees } from '../../api-clients/api-clients-employees';

export const useMutateEmployee = () => {
  const handleUpdateEmployee = async (id: string, data: Partial<Employees>) => {
    await new ApiClientsEmployees().update(id, data);
  };

  return {
    handleUpdateEmployee,
  };
};
