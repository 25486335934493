export const bagStatus = [
  'placed order',
  'completed order',
  'changed statuses',
  'prepared information',
  'packed bag',
];

export const returnBagStatus = [
  'return_requested',
  'bag_containings_sent',
  'packed_bag',
  'info_sent',
  'shipped',
  'brought',
  'refunded',
];
