import React, { useContext } from 'react';
import { ErrorView, Spinner } from '../../../UI';
import { Grid } from '@material-ui/core';
import MuiButton from '../../../UI/MuiButton';
import _ from 'lodash';
import { BagFillingContext } from '../BagFillingContext';
import { useStorageNames } from '../hooks';
import { BagFillingPagesEnum } from '../BagFillingPages';

const SelectLocation = () => {
  const { result, error, status } = useStorageNames();
  const context = useContext(BagFillingContext);

  const handleSetStorage = (storage: string) => {
    context.setStorage?.(storage);
    context.goToPage?.(BagFillingPagesEnum.BagsList);
  };

  if (status === 'loading') {
    return <Spinner />;
  }

  if (error) {
    return <ErrorView error={error} />;
  }

  return (
    <Grid container spacing={2}>
      {_.uniq((result || []).map(({ storage }) => storage))
        .filter((storage) => !!storage)
        .map((storage, index) => (
          <Grid item xs={12} key={index}>
            <MuiButton fullWidth onClick={() => handleSetStorage(storage)}>
              {storage}
            </MuiButton>
          </Grid>
        ))}
    </Grid>
  );
};

export default SelectLocation;
