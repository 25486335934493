import React from 'react';
import withControls from './withControls';
import { IRendererProps } from './DefaultRenderer';
import { Typography } from '@material-ui/core';

export interface IStatusRenderProps extends IRendererProps {
  config: {
    // every key is color for status
    // if Array -> several values need to be rendered with color
    // if String -> regexp for matching values
    // Example:
    //       fieldsConfig: {name: 'status', rendererName: 'status', params: {'green': ['PACKED'], 'red': '^[a-zA-Z 0-9]+$'}}
    [key: string]: Array<string> | string;
  };
}

const StatusRenderer: React.FC<IStatusRenderProps> = ({
  value = '',
  config = {},
}) => {
  if (value === null) return <Typography>null</Typography>;

  const statusesWithValuesArrays = Object.entries(config).filter(
    ([, value]) => typeof value !== 'string',
  );
  const statusesWithSingleValue = Object.entries(config).filter(
    ([, value]) => typeof value === 'string',
  );
  let color = '';
  // First check if value matches with provided key statuses
  for (const [key, statusesArray] of statusesWithValuesArrays) {
    if (statusesArray.includes(value)) {
      color = key;
      break;
    }
  }
  // if color not found, use regexp
  if (!color) {
    for (const [key, reqexp] of statusesWithSingleValue) {
      if (typeof reqexp === 'string' && new RegExp(reqexp).test(value)) {
        color = key;
      }
    }
  }

  return (
    <Typography style={{ color }}>
      <span>{value}</span>
    </Typography>
  );
};

export default withControls<IStatusRenderProps>(StatusRenderer);
