import React, { FC } from 'react';
import { Checkbox } from '../../../../../../UI';
import { Box, Typography, useTheme } from '@material-ui/core';

interface PasteUploaderOptionEditProps {
  name: string;
  value?: boolean;
  onUpdate: (value: boolean, path: string) => void;
}

const PasteUploaderOptionEdit: FC<PasteUploaderOptionEditProps> = ({
  name,
  value,
  onUpdate,
}) => {
  const theme = useTheme();

  return (
    <Box display="flex" style={{ gap: theme.spacing(1) }}>
      <Typography variant="h6">{name}: </Typography>
      <Checkbox
        onChange={(value) => onUpdate(value, `.${name}`)}
        initValue={value}
      />
    </Box>
  );
};

export default PasteUploaderOptionEdit;
