import React, { FC, useState } from 'react';
import {
  Box,
  Select,
  TextField,
  Checkbox,
  MenuItem,
  useTheme,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import MuiButton from '../../../../../UI/MuiButton';
import { ErrorMessage, Formik } from 'formik';
import { UpdateData, UpdateFormData, UpdateRofSign } from './RofUpdaterDialog';
import { FormikHelpers } from 'formik/dist/types';

export const locations = [
  'main_ky_qty',
  'main_tx_qty',
  'main_ut_qty',
  'main_nc_qty',
  'main_wi_qty',
  'main_ny_qty',
  'main_cn_qty',
] as const;

interface RofUpdaterFormProps {
  onUpdateSubmit: (data: UpdateFormData) => void;
  onCreateSubmit: (data: UpdateFormData) => void;
}

type InitialValues = {
  location: typeof locations[number];
  useNewPrice: boolean;
  data: string;
};

const parseData = (
  data: string,
  options: { useNewPrice?: boolean; qtyField: typeof locations[number] },
) => {
  const { useNewPrice, qtyField } = options;

  return data
    .split('\n')
    .map((row) => row.split('\t'))
    .reduce((acc, curr) => {
      const [rid, value, price] = curr;
      // omit the first char, because it will be - '`'
      const [, sign, ...quantity] = value;

      return [
        ...acc,
        {
          rid,
          sign: sign as UpdateRofSign,
          qtyField,
          quantity: +quantity.join(''),
          price: useNewPrice && price ? +price : null,
        },
      ];
    }, [] as UpdateData[]);
};

const RofUpdaterForm: FC<RofUpdaterFormProps> = ({
  onCreateSubmit,
  onUpdateSubmit,
}) => {
  const theme = useTheme();
  const [isCreate, setIsCreate] = useState(false);

  const doesPriceExists = (data: UpdateData[]) => {
    return data.every(({ price }) => price !== null);
  };

  const handleSubmit = (
    values: InitialValues,
    { setErrors }: FormikHelpers<InitialValues>,
  ) => {
    const { useNewPrice, data, location } = values;
    const updateData = parseData(data, { useNewPrice, qtyField: location });

    if (isCreate && !doesPriceExists(updateData)) {
      return setErrors({
        data: 'Could not create items without price',
      });
    }

    (isCreate ? onCreateSubmit : onUpdateSubmit)({
      location,
      useNewPrice,
      data: updateData,
    });
  };

  return (
    <Formik
      initialValues={
        {
          location: locations[0],
          useNewPrice: false,
          data: '',
        } as InitialValues
      }
      onSubmit={handleSubmit}
    >
      {({ values, handleChange, handleSubmit, submitForm }) => (
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Box
            display="flex"
            flexDirection="column"
            style={{ gap: theme.spacing(1) }}
          >
            <Select
              fullWidth
              value={values.location}
              label="Location"
              name="location"
              onChange={handleChange}
            >
              {locations.map((location) => (
                <MenuItem key={location} value={location}>
                  {location}
                </MenuItem>
              ))}
            </Select>
            <FormControlLabel
              control={
                <Checkbox
                  value={values.useNewPrice}
                  name="useNewPrice"
                  onChange={handleChange}
                />
              }
              label="Use new price"
            />
            <TextField
              multiline
              placeholder="Input your data to update"
              label="Update data"
              name="data"
              rows={10}
              fullWidth
              variant="outlined"
              value={values.data}
              onChange={handleChange}
            />
            <ErrorMessage
              name="data"
              render={(errorMessage) => (
                <Typography variant="h6" color="error">
                  {errorMessage}
                </Typography>
              )}
            />
            <MuiButton
              color="primary"
              fullWidth
              onClick={() => {
                setIsCreate(false);
                submitForm();
              }}
            >
              Update
            </MuiButton>
            <MuiButton
              color="success"
              fullWidth
              onClick={() => {
                setIsCreate(true);
                submitForm();
              }}
            >
              Create
            </MuiButton>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default RofUpdaterForm;
