import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';
import {
  RollunIdWithCsnItem,
  UnidentifiedReturnsItem,
  useRollunIdWithCsnViewDatastore,
} from '../../../hooks/datastores';
import { Query, Eq } from 'rollun-ts-rql';
import { formatCsnsFromItems, isUrl } from '../utils';
import { NO_IMAGE } from '../../../utils/common.constants';
import MuiButton, { MuiColors } from '../../../UI/MuiButton';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { ErrorView } from '../../../UI';
import FullScreenImageOnClick from '../../ReturnApp/components/FullScreenImageOnClick';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfDocument from './PdfDocument';
import AddToInventoryItem from './AddToInventoryItem';

interface IdentifiedItemInfoProps {
  product: UnidentifiedReturnsItem;
  refreshList: () => void;
}

export type Csn = Record<'supplier' | 'csn', string>;
export type Item = RollunIdWithCsnItem & {
  csns: Csn[];
};

const useStyles = makeStyles(() => ({
  media: {
    height: 250,
    img: {
      maxWidth: '100%',
      maxHeight: '100%',
      display: 'block',
    },
  },
}));

const IdentifiedItemInfo: FC<IdentifiedItemInfoProps> = ({
  refreshList,
  product: { rid, status_from_manager, photo, id },
}) => {
  const itemNotAvailable = useMemo(
    () => !rid || status_from_manager === 'rubbish',
    [],
  );
  const isProblemItem = useMemo(
    () =>
      status_from_manager === 'rubbish' || status_from_manager === 'damaged',
    [],
  );

  const [
    { result, error, isError, isLoading },
    dispatch,
  ] = useRollunIdWithCsnViewDatastore();
  const [item, setItem] = useState<Item | null>(null);
  const classes = useStyles();

  const getButtonNameBasedOnStatusFromManager = (
    status: 'ROF' | 'rubbish' | 'damaged' | null,
  ) => {
    return `Put to ${status === 'ROF' ? 'ROF' : `the ${status}`}`;
  };

  const getColorBasedOnStatusFromManager = (
    status: 'ROF' | 'rubbish' | 'damaged',
  ) => {
    const config: Record<string, MuiColors> = {
      ROF: 'success',
      rubbish: 'error',
      damaged: 'warning',
    };

    return config[status];
  };

  const getImageUrl = (
    photo: string,
    item: RollunIdWithCsnItem | null,
    { isProblem }: { isProblem: boolean },
  ) => {
    if (!item || itemNotAvailable) {
      return isUrl(photo) ? photo : NO_IMAGE;
    }

    return isProblem ? photo : item.image;
  };

  useEffect(() => {
    if (itemNotAvailable) {
      return;
    }

    dispatch(
      'QUERY',
      new Query({
        query: new Eq('rid', rid),
      }),
    );
  }, []);

  useEffect(() => {
    if (!result || result.length === 0) {
      return;
    }

    const [itemsInfo] = result;

    const csns = formatCsnsFromItems(result);

    setItem({
      ...itemsInfo,
      csns: csns[itemsInfo.rid],
    });
  }, [result]);

  if (isLoading) {
    return (
      <Card>
        <Skeleton height={150} />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Card>
    );
  }

  if (isError) {
    return <ErrorView error={error} />;
  }

  return (
    <Card>
      {itemNotAvailable ? (
        <CardMedia
          image={getImageUrl(photo, item, { isProblem: isProblemItem })}
          className={classes.media}
        />
      ) : (
        <FullScreenImageOnClick
          src={getImageUrl(photo, item, { isProblem: isProblemItem })}
        />
      )}
      <CardContent>
        {itemNotAvailable ? (
          <Typography variant="body1" align="center">
            {!isProblemItem
              ? 'Product was not identified by manager'
              : 'This product is non-returnable'}
          </Typography>
        ) : (
          <>
            <Typography variant="body1">Barcode label: {id}</Typography>
            <Typography variant="body1">Rollun ID: {item?.rid}</Typography>
            <Typography variant="body1">Brand: {item?.brand || '-'}</Typography>
            <Typography variant="body1">Mpn: {item?.mpn || '-'}</Typography>
            <Typography variant="body1">Title: {item?.title || '-'}</Typography>
            <Typography variant="body1">Weight: {item?.weight}</Typography>
            {item?.csns.map(({ supplier, csn }) => (
              <Typography variant="body1" key={csn}>
                {supplier} csn: {csn}
              </Typography>
            ))}
          </>
        )}
      </CardContent>
      <CardActions>
        <Box display="flex" flexDirection="column" width="100%">
          {itemNotAvailable || isProblemItem ? null : (
            <PDFDownloadLink
              document={<PdfDocument item={item} />}
              fileName={`${item?.rid}.pdf`}
            >
              {({ loading }) => (
                <MuiButton color="primary" fullWidth>
                  {loading ? 'Loading document...' : 'Download Pdf'}
                </MuiButton>
              )}
            </PDFDownloadLink>
          )}
          <Box marginTop={1}>
            <AddToInventoryItem
              id={id}
              refreshList={refreshList}
              color={getColorBasedOnStatusFromManager(status_from_manager)}
              title={getButtonNameBasedOnStatusFromManager(status_from_manager)}
            />
          </Box>
        </Box>
      </CardActions>
    </Card>
  );
};

export default IdentifiedItemInfo;
