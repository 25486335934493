import React, { PureComponent, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export enum QueryNodeNames {
  select = 'select',
  sort = 'sort',
  limit = 'limit',
  query = 'query',
  group = 'group',
}

export interface DropToRemoveNodeFieldProps {
  onNodeFieldRemove(
    fieldName: string,
    nodeName: QueryNodeNames.select | QueryNodeNames.sort,
  ): void;
}

export default class DropToRemoveNodeField extends PureComponent<DropToRemoveNodeFieldProps> {
  state: { awaitingDrop: boolean; validDropTarget: boolean } = {
    awaitingDrop: false,
    validDropTarget: false,
  };

  render(): ReactNode {
    const { awaitingDrop, validDropTarget } = this.state;

    return (
      <div
        className={`card h-100 m-0 cursor-pointer ${
          awaitingDrop
            ? validDropTarget
              ? 'border-valid'
              : 'border-invalid'
            : ''
        }`}
        onDragOver={this.checkDropPossibility}
        onDragLeave={this.disableDropTarget}
        onDrop={(event) => {
          this.removeDroppedNodeFromSelectedNodes(event);
          this.disableDropTarget();
        }}
      >
        <div className="card-body p-3 h-100">
          <span className="title card-title">Drop or click for remove</span>
          <div className="card-text d-flex justify-content-center align-items-center icon-aligner">
            <div>
              <FontAwesomeIcon icon={'trash'} size={'3x'} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  checkDropPossibility = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (
      event.dataTransfer &&
      event.dataTransfer.types.indexOf('nodefieldname') !== -1
    ) {
      this.setState({ awaitingDrop: true, validDropTarget: true });
      return false;
    }
    this.setState({ awaitingDrop: true, validDropTarget: false });
    return true;
  };

  disableDropTarget = () => {
    this.setState({ awaitingDrop: false, validDropTarget: false });
  };

  removeDroppedNodeFromSelectedNodes = (
    event: React.DragEvent<HTMLDivElement>,
  ) => {
    const dataTransfer = event.dataTransfer;
    if (dataTransfer) {
      const nodeFieldName = dataTransfer.getData('nodefieldname');
      if (dataTransfer.types.indexOf('selectnode') !== -1) {
        this.props.onNodeFieldRemove(nodeFieldName, QueryNodeNames.select);
      } else if (dataTransfer.types.indexOf('sortnode') !== -1) {
        this.props.onNodeFieldRemove(nodeFieldName, QueryNodeNames.sort);
      }
    } else {
      throw new Error('Datatransfer is not defined');
    }
  };
}
