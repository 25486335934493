import React, { FC, useState } from 'react';
import MuiButton from '../../../../../UI/MuiButton';
import RofUpdaterDialog from '../components/RofUpdaterDialog';

const RofInventoryUpdater: FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <MuiButton size="xs" color="primary" onClick={() => setIsOpen(true)}>
        Rof Updater
      </MuiButton>
      <RofUpdaterDialog isOpen={isOpen} closeModal={() => setIsOpen(false)} />
    </>
  );
};

export default RofInventoryUpdater;
