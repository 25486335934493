import { PureComponent, ReactNode } from 'react';
import { Modal, Button } from 'react-bootstrap';
import React from 'react';
import { zIndexes } from '../themeProvider';

export interface DialogProps {
  isOpen: boolean;
  title?: string;
  options?: {
    size?: 1 | 2 | 3 | 4;
    centered?: boolean;
  };

  onClose?(): void;

  onOk?(): void;

  onOkButton?: React.ReactNode;

  submitBtnText?: string;

  onDecline?(): void;

  declineBtnText?: string;
  hideCloseBtn?: boolean;
}

class Dialog extends PureComponent<DialogProps> {
  render(): ReactNode {
    return (
      <Modal
        show={this.props.isOpen}
        onHide={() => {
          this.props.onClose && this.props.onClose();
        }}
        style={{
          zIndex: zIndexes.biggest,
        }}
        centered={this.props.options && this.props.options.centered}
        size={this.getModalSize()}
      >
        <Modal.Header closeButton={!this.props.hideCloseBtn}>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.props.children}</Modal.Body>
        {!this.props.isOpen ? null : this.props.onOk ? (
          <Modal.Footer>
            {this.props.onOkButton ? (
              this.props.onOkButton
            ) : (
              <Button
                variant="primary"
                onClick={() => {
                  if (this.props.onOk) {
                    this.props.onOk();
                  } else {
                    this.props.onClose && this.props.onClose();
                  }
                }}
              >
                {this.props.submitBtnText || 'Ok'}
              </Button>
            )}
            {this.props.onDecline ? (
              <Button
                color="secondary"
                onClick={() => {
                  if (this.props.onDecline) {
                    this.props.onDecline();
                  }
                }}
              >
                {this.props.declineBtnText || 'Decline'}
              </Button>
            ) : null}
          </Modal.Footer>
        ) : null}
      </Modal>
    );
  }

  // TODO rewrite this to Modal sizes (sm | lg | xl)
  protected getModalSize(): 'sm' | undefined | 'lg' | 'xl' {
    const sizeFlag =
      this.props.options && this.props.options.size
        ? this.props.options.size
        : 2;
    const sizeFlags: Array<'sm' | undefined | 'lg' | 'xl'> = [
      'sm',
      undefined,
      'lg',
      'xl',
    ];
    return sizeFlags[sizeFlag - 1];
  }
}

export default Dialog;
