import { logger } from '../../../../../../../../utils/logger';
import Axios from 'axios';

// TODO check this file

export async function addInventoryInfo(
  howToBuyOptions: Record<string, any>,
): Promise<Record<string, any>> {
  const options = Object.entries(howToBuyOptions);
  const uniqRid = new Set();
  const ridMapping: Record<string, any> = {};

  for (const [, option] of options) {
    for (let i = 0; i < option.length; i++) {
      const opt = option[i];
      const rid = opt['rollunId'];
      uniqRid.add(rid);
    }
  }

  for (const rid of uniqRid) {
    const { data } = await Axios.get(
      `/api/webhook/VictualSupplierItemInfo?rid=${rid}`,
    );
    ridMapping[rid as string] = data;
  }

  try {
    for (const [, option] of options) {
      for (let i = 0; i < option.length; i++) {
        const opt = option[i];
        const rid = opt['rollunId'];
        const data = ridMapping[rid];

        for (const dataKey in data) {
          if (!data[dataKey].warehouses.length) {
            continue;
          }

          const res = data[dataKey].warehouses.reduce((acc: any, curr: any) => {
            acc[curr.id] = curr.qty;
            return acc;
          }, {});

          if (howToBuyOptions[dataKey]) {
            howToBuyOptions[dataKey].forEach((item: any) => {
              if (item.rollunId === rid) {
                item.inventoryQuantity = res;
              }
            });
          }
        }
      }
    }
  } catch (err) {
    logger.error('addInventoryInfo', err);
  }

  return Object.fromEntries(options);
}
