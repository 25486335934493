import React, { FC, useContext, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
} from '@material-ui/core';
import MuiButton from '../../../UI/MuiButton';
import { ErrorView, Spinner } from '../../../UI';
import ReturnAppContext from '../context';
import { useReturnsDatastore } from '../../../hooks/datastores/useReturnsDatastore';
import { Query, Eq, Limit, Select } from 'rollun-ts-rql';
import FullScreenImageOnClick from './FullScreenImageOnClick';
import {
  useRollunIdWithCsnViewDatastore,
  RollunIdWithCsnItem,
  useCatalogDatastore,
} from '../../../hooks/datastores';
import SubmitItem from './SubmitItem';
import DialogWithPDF from './DialogWithPdf';
import { isJSON } from 'rollun-ts-utils';
import { useQueryParamState } from '../../../hooks/useQueryParamState';

const PossibleItem: FC<any> = () => {
  const [{ result, isLoading, error }, returnsDispatch] = useReturnsDatastore();
  const [, ridWithCsnDispatch] = useRollunIdWithCsnViewDatastore();
  const [, catalogDispatch] = useCatalogDatastore();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const context = useContext(ReturnAppContext);
  const [item, setItem] = useState<any>(null);
  const [isPdfDownloaded, setIsPdfdownloaded] = useState(false);
  const [processingRid, setProcessingRid] = useQueryParamState<string>(
    'processingRid',
    '',
  );

  const getItemRollunIdWithCsn = async (rid: string) => {
    try {
      return await ridWithCsnDispatch(
        'QUERY',
        new Query({
          query: new Eq('rid', rid),
        }),
      );
    } catch (e) {
      return [];
    }
  };

  const getCatalogItemByRollunId = async (rid: string) => {
    try {
      return catalogDispatch(
        'QUERY',
        new Query({
          query: new Eq('id', rid),
          select: new Select(['id', 'brand_id', 'manufacturer_part_number']),
        }),
      );
    } catch (e) {
      return [];
    }
  };

  useEffect(() => {
    const getItemByRid = async (rid: string) => {
      const [dimensions, rollunInfo] = await Promise.all([
        getItemRollunIdWithCsn(rid),
        getCatalogItemByRollunId(rid),
      ]);

      if (!dimensions?.length || !rollunInfo?.length) {
        return;
      }

      const itemWithCommonData = dimensions[0];
      const csns = dimensions.reduce((acc, item: RollunIdWithCsnItem) => {
        acc[item.sr_name] = item.csn;

        return acc;
      }, {} as { [key: string]: string });

      const item = {
        ...itemWithCommonData,
        ...rollunInfo[0],
        csns: csns,
      };

      setItem(item);
    };

    const getReturnItem = async () => {
      const data = await returnsDispatch(
        'QUERY',
        new Query({
          query: new Eq('track_number', context.label),
          limit: new Limit(10),
        }),
      );

      const expectedRidsRaw = data?.[0].expected_rid;

      if (!expectedRidsRaw) {
        context.setPage('foundLabel');
        return;
      }

      const expectedRids: string | string[] = isJSON(expectedRidsRaw)
        ? JSON.parse(expectedRidsRaw)
        : expectedRidsRaw;

      if (!Array.isArray(expectedRids)) {
        getItemByRid(expectedRids);
        return;
      }

      if (processingRid === '') {
        setProcessingRid('0');
      }

      getItemByRid(expectedRids[+processingRid || 0]);
    };

    getReturnItem();
  }, []);

  const handleNextItem = () => {
    setProcessingRid((+processingRid + 1).toString());
    setIsPdfdownloaded(false);
    setIsDialogOpen(false);
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorView error={error} />;
  }

  const isItemDamaged =
    !!result && !!result[0] && result[0].mp_return_reason === 'damaged';

  return (
    <Box>
      <Typography variant="h5">Possible item to return:</Typography>
      {item ? (
        isPdfDownloaded ? (
          <>
            <Box marginBottom={1}>
              <MuiButton
                fullWidth
                onClick={() => context.setPage('notFoundItem')}
                color="error"
              >
                Not found item
              </MuiButton>
            </Box>
            <SubmitItem
              item={item}
              isDamaged={isItemDamaged}
              handleNextItem={handleNextItem}
              processingRid={processingRid}
            />
          </>
        ) : (
          <>
            <Card elevation={3}>
              <CardActionArea>
                <FullScreenImageOnClick src={item.image} />
                <CardContent>
                  <Typography variant="body1">Rollun ID: {item.rid}</Typography>
                  <Typography variant="body1">Title: {item.title}</Typography>
                  <Typography variant="body1">Brand: {item.brand}</Typography>
                  <Typography variant="body1">Mpn: {item.mpn}</Typography>
                  <Typography variant="body1">Weight: {item.weight}</Typography>
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    CSNs for this item:
                  </Typography>
                  {Object.entries(item.csns).map(([srName, csn]) => (
                    <Typography variant="body1" style={{ marginLeft: '10px' }}>
                      {srName}: {csn}
                    </Typography>
                  ))}
                </CardContent>
              </CardActionArea>
              <CardActions>
                <MuiButton
                  color="success"
                  fullWidth
                  onClick={() => setIsDialogOpen(true)}
                  loading={!item}
                  disabled={!item}
                >
                  This item
                </MuiButton>
                <MuiButton
                  color="error"
                  fullWidth
                  onClick={() => context.setPage('foundLabel')}
                >
                  Not this item
                </MuiButton>
              </CardActions>
            </Card>
            <DialogWithPDF
              item={item}
              open={isDialogOpen}
              setIsDialogOpen={setIsDialogOpen}
              setIsPdfdownloaded={setIsPdfdownloaded}
            />
          </>
        )
      ) : (
        <Spinner />
      )}
    </Box>
  );
};

export default PossibleItem;
