import React, { useContext, FC, useEffect, useState } from 'react';
import GenericHowToBuy from '../Generic/GenericHowToBuy';
import {
  getHowToBuyOptions,
  HowToBuyOptionsResult,
} from './utils/getHowToBuyOptions';
import { Spinner } from '../../../../../../../UI/index';
import { DealContext } from '../../../../GenericDeal/context/dealContext';
import { Order } from '../../../../api-clients/types';
import { Typography } from '@material-ui/core';

const HowToBuy4: FC = () => {
  const dealContext = useContext(DealContext);
  const deal = dealContext?.deal as Order | undefined;
  const [error, setError] = useState('');

  const [howToBuy, setHowToBuy] = useState<HowToBuyOptionsResult | undefined>();

  const isHTBFieldValid = (field: string | undefined) => {
    if (!field) {
      return false;
    }

    if (field === 'null' || field === '{}') {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (deal) {
      try {
        setError('');
        if (!isHTBFieldValid(deal.howToBuy4)) {
          throw new Error('No how to buy options');
        }

        const howToBuyOptions = getHowToBuyOptions(
          ((deal.howToBuy4 as unknown) as string) || '',
          'sender',
          deal.howToBuy4BestShipping,
          deal.no_special_items_positions,
        );
        setHowToBuy(howToBuyOptions);
      } catch (e) {
        console.log(e);
        setError('Error: ' + (e as Error).message);
      }
    }
  }, [deal]);

  if (error) {
    return <Typography variant="h6">{error}</Typography>;
  }

  if (!deal || !howToBuy) {
    return <Spinner />;
  }

  return <GenericHowToBuy howToBuyOptions={howToBuy} version="HowToBuy" />;
};

export default HowToBuy4;
