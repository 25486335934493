import { ReactNode } from 'react';
import CardPicker from './components/CardPicker';
import AppContainer from '../../layout/AppContainer/AppContainer';
import React from 'react';
import AbstractService, { AbstractServiceProps } from '../AbstractService';
import Spinner from '../../UI/Spinner';
import { ErrorView } from '../../UI';

/**
 * Component fro Service with {appType} cardPicker
 * It renders a bunch of cards. You can select one of it,
 * one card can be selected at the time
 */

export default class CardPickerApp extends AbstractService<AbstractServiceProps> {
  render(): ReactNode {
    const { appConfig, error } = this.state;
    if (error) {
      return (
        <AppContainer services={{}}>
          <div className="w-100 h-100 d-flex flex-column justify-content-center">
            <ErrorView error={error}>
              <div className="d-flex justify-content-center p-3">
                <div
                  className="btn btn-primary cursor-pointer"
                  onClick={this.reloadHome}
                >
                  Home
                </div>
              </div>
            </ErrorView>
          </div>
        </AppContainer>
      );
    }
    if (appConfig === null) {
      return <Spinner />;
    }

    if (!appConfig.appParams.cardPickerParams) {
      throw new Error(`Field cardPickerParams is mandatory in appProps`);
    }

    const { user, role } = this.props.userIdentity;
    const { datastoreUrl } = appConfig.appParams.cardPickerParams;

    return (
      <AppContainer
        user={user}
        role={role}
        currentResourceName={this.props.resourceName}
        services={this.props.availableServices}
      >
        <CardPicker datastoreUrl={datastoreUrl} />
      </AppContainer>
    );
  }
}
