import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from '../../../../../UI/Spinner';
import GenericDeal, { DealProps, DealType } from '../../GenericDeal';
import { ErrorView } from '../../../../../UI';
import { Box } from '@material-ui/core';
import MuiButton from '../../../../../UI/MuiButton';
import { ApiClientsEmployees } from '../../api-clients/api-clients-employees';
import { useGetEmployee } from '../hooks/useGetEmployee';
import { useMutateEmployee } from '../hooks/useMutateEmployee';

export const Employees: FC<DealProps> = ({
  buttons,
  fieldsConfig,
  widgets,
}) => {
  const { id } = useParams<{ id: string }>();
  const { data, error, isFetching } = useGetEmployee(id);
  const { handleUpdateEmployee } = useMutateEmployee();
  const history = useHistory();

  if ((isFetching && !data) || !data) {
    return <Spinner />;
  }

  if (error && !data) {
    return (
      <ErrorView
        error={{
          text: (error as Error).message,
          code: (error as Error).message.includes('not found') ? 404 : 403,
        }}
      >
        <Box
          style={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <MuiButton onClick={() => history.goBack()}>Back</MuiButton>
          <MuiButton onClick={() => location.reload()}>Reload</MuiButton>
        </Box>
      </ErrorView>
    );
  }

  return (
    <GenericDeal
      fieldsConfig={fieldsConfig}
      buttons={buttons}
      data={data}
      type={DealType.Employees}
      widgets={widgets}
      dealConfig={{
        disableStatusSelector: false,
        disableItemsTable: true,
      }}
      rawData={data ? new ApiClientsEmployees().convertModelToDTO(data!) : {}}
      onDealUpdate={handleUpdateEmployee}
      availableStatuses={[
        {
          id: '35',
          name: 'Hiring',
          color: '#4F3BFF',
        },
        {
          id: '36',
          name: 'Work',
          color: '#00B27A',
        },
        {
          id: '37',
          name: 'Firing',
          color: '#FCEC25',
        },
        {
          id: '38',
          name: 'Fired',
          color: '#84216B',
        },
      ]}
    />
  );
};
