import HttpDatastore from 'rollun-ts-datastore/dist';
import { DimensionItem } from '../../../../../hooks/datastores';
import { In, Query, Select } from 'rollun-ts-rql';

export type Dimension = {
  rid: string;
  width: string | null;
  height: string | null;
  length: string | null;
  weight: string | null;
};

export const defaultDimensions = {
  width: '-1',
  height: '-1',
  length: '-1',
  weight: '-1',
};

export async function fetchDimensions(rids: string[]): Promise<Dimension[]> {
  const dimensionsStore = new HttpDatastore<DimensionItem>(
    '/api/datastore/DimensionStore',
  );

  const dimensions = await dimensionsStore.query(
    new Query({
      select: new Select(['id', 'width', 'height', 'length', 'weight']),
      query: new In('id', [...new Set(rids)]),
    }),
  );

  return rids.map((rid) => ({
    rid,
    ...(dimensions.find(({ id }) => id === rid) || defaultDimensions),
  }));
}
