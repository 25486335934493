import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { BpmnEntity } from './types';
import { Spinner } from '../../../UI';

interface BpmnDialogProps {
  isDialogOpen: boolean;
  handleCloseDialog: () => void;
  fileNames: BpmnEntity[] | null;
  handleAutocompeteFileSelection: (
    event: any,
    value: BpmnEntity | null,
  ) => void;
  autocompleteFile: BpmnEntity | null;
  handleSelectFile: () => void;
  status: 'loading' | 'loaded' | 'idle';
}

export const BpmnDialog = (props: BpmnDialogProps) => {
  const {
    isDialogOpen,
    handleCloseDialog,
    fileNames,
    handleAutocompeteFileSelection,
    autocompleteFile,
    handleSelectFile,
    status,
  } = props;
  return (
    <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
      <DialogTitle>Select file name</DialogTitle>
      <DialogContent>
        {status === 'loading' ? (
          <Box style={{ width: 300, height: 80 }}>
            <Spinner />
          </Box>
        ) : (
          <Autocomplete
            options={fileNames || []}
            getOptionLabel={(option: BpmnEntity) => option.file_name}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="File name" variant="outlined" />
            )}
            onChange={handleAutocompeteFileSelection}
            value={autocompleteFile}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSelectFile}
          color="primary"
          disabled={!autocompleteFile}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
