import React, { FC } from 'react';
import { Typography, TypographyProps } from '@material-ui/core';

interface HighlightFoundSubstringProps extends TypographyProps {
  substring: string;
  value: string;
}

const HighlightFoundSubstring: FC<HighlightFoundSubstringProps> = ({
  substring,
  value,
  ...props
}) => {
  if (value.indexOf(substring) === -1) {
    return <Typography {...props}>{value}</Typography>;
  }

  return (
    <Typography {...props}>
      {value.slice(0, value.indexOf(substring))}
      <span style={{ backgroundColor: 'yellow' }}>
        {value.slice(
          value.indexOf(substring),
          value.indexOf(substring) + substring.length,
        )}
      </span>
      {value.slice(value.indexOf(substring) + substring.length, value.length)}
    </Typography>
  );
};

export default HighlightFoundSubstring;
