import React from 'react';
import { BpmnDiagram } from './BpmnDiagram';
import MuiButton from '../../../UI/MuiButton';
import { UploadFileButton } from '../../../UI/UploadFileButton';
import X2JS from 'x2js';
import { Box } from '@material-ui/core';
import HttpDatastore from 'rollun-ts-datastore';
import { Query } from 'rollun-ts-rql';
import { BpmnEntity } from './types';
import { BpmnDialog } from './BpmnDialog';

const x2js = new X2JS();

const BpmnDatastore = new HttpDatastore<BpmnEntity>(
  '/api/datastore/BpmnSchemas',
);

interface IState {
  diagramXML: null | string;
  isDialogOpen: boolean;
  fileNames: null | BpmnEntity[];
  selectedFile: null | BpmnEntity;
  autocompleteFile: null | BpmnEntity;
  status: 'loading' | 'loaded' | 'idle';
}

export class Bpmn extends React.Component<any, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      diagramXML: null,
      isDialogOpen: false,
      fileNames: null,
      selectedFile: null,
      autocompleteFile: null,
      status: 'idle',
    };
  }

  componentDidUpdate(prevProps: any, prevState: IState) {
    if (
      prevState.fileNames !== this.state.fileNames &&
      !this.state.selectedFile &&
      !!this.state.fileNames
    ) {
      const searchParams = new URLSearchParams(window.location.search);
      const file_id = searchParams.get('id');
      const entity = this.state.fileNames.find((file) => file.id === file_id);

      if (entity && !this.state.selectedFile) {
        this.setState({ selectedFile: entity });
        this.handleSelectFile(entity);
      }
    }
  }

  componentDidMount(): void {
    (async () => {
      const result = await BpmnDatastore.query(new Query());
      this.setState({ fileNames: result });
    })();
  }

  handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const xmlContent = e.target.result;
      this.setState({ diagramXML: xmlContent });
    };
    reader.readAsText(file);
  };

  downloadAsJson = () => {
    const json = x2js.xml2js(`<?xml version="1.0" encoding="UTF-8"?>
    <bpmn2:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd" id="sample-diagram" targetNamespace="http://bpmn.io/schema/bpmn">
      <bpmn2:process id="Process_1" isExecutable="false">
        <bpmn2:startEvent id="StartEvent_1"/>
      </bpmn2:process>
      <bpmndi:BPMNDiagram id="BPMNDiagram_1">
        <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1">
          <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
            <dc:Bounds height="36.0" width="36.0" x="412.0" y="240.0"/>
          </bpmndi:BPMNShape>
        </bpmndi:BPMNPlane>
      </bpmndi:BPMNDiagram>
    </bpmn2:definitions>`);
    const xml = x2js.js2xml(json);
    this.setState({ diagramXML: xml });
  };

  downloadFromDatastore = async () => {
    this.setState({ isDialogOpen: true, fileNames: [], status: 'loading' });
    try {
      const result = await BpmnDatastore.query(new Query());
      this.setState({ fileNames: result, status: 'loaded' });
    } catch {
      this.setState({ status: 'idle' });
      alert('Some error, close and open this dialog window again');
    }
  };

  handleSelectFile = (entityProp?: BpmnEntity) => {
    const { autocompleteFile, selectedFile } = this.state;

    if (autocompleteFile) {
      this.setState({ selectedFile: autocompleteFile });
    }

    const entity = autocompleteFile || selectedFile || entityProp;

    if (!entity || !entity?.id) {
      this.setState({ isDialogOpen: false });
      alert('Error: diagram not loaded, reload page and try again');
      return;
    }

    const diagram = x2js.js2xml(entity?.file);
    const url = new URL(window.location as any);
    url.searchParams.set('id', entity?.id);

    window.history.replaceState({}, '', url as any);
    this.setState({ diagramXML: diagram, isDialogOpen: false });
  };

  handleAutocompeteFileSelection = (event: any, value: BpmnEntity | null) => {
    this.setState({ autocompleteFile: value });
  };

  render() {
    const { isDialogOpen, diagramXML, selectedFile, status } = this.state;
    return (
      <>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
          }}
        >
          <UploadFileButton handleFileUpload={this.handleFileChange} accept="">
            Upload schema
          </UploadFileButton>
          <MuiButton onClick={this.downloadAsJson}>
            Create new diagram
          </MuiButton>
          <MuiButton
            disabled={this.state.isDialogOpen}
            onClick={this.downloadFromDatastore}
          >
            Download from datastore
          </MuiButton>
        </Box>
        <BpmnDialog
          isDialogOpen={isDialogOpen}
          status={status}
          handleCloseDialog={() => this.setState({ isDialogOpen: false })}
          fileNames={this.state.fileNames}
          handleAutocompeteFileSelection={this.handleAutocompeteFileSelection}
          autocompleteFile={this.state.autocompleteFile}
          handleSelectFile={this.handleSelectFile}
        />
        {diagramXML && (
          <BpmnDiagram
            diagramXML={diagramXML}
            diagramDSEntity={selectedFile}
            key={selectedFile?.id}
            datastore={BpmnDatastore}
          />
        )}
      </>
    );
  }
}
