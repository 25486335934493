import React, { PureComponent } from 'react';
import Spinner from '../../../UI/Spinner';
import HttpDatastore from 'rollun-ts-datastore';
import GoodsItemInfo from './GoodsItemInfo';
import { GoodsItemProps } from '../utils/LabelGoodsToList';
import _ from 'lodash';
import { Button, ErrorView } from '../../../UI';
import { ErrorType, Senders } from '../../../utils/common.types';
import { httpErrorHandler } from '../../../utils/common.utils';
import { getExpandedItemProps } from '../utils/getSupplierCatalogItemsProps';
import { ExpandedItemProps } from '../utils/types';

export interface WrongPackageItemProps {
  currentItem: GoodsItemProps;
  onAccept: (problemWithGood: boolean) => void;
  allGoodScanned: boolean;
  senderName: Senders;
  order: { id: string; supplier: string };
}

interface ItemParameters {
  length: string;
  width: string;
  height: string;
}

enum Dimensions {
  length = 'length',
  width = 'width',
  height = 'height',
  weight = 'weight',
}

enum WrongPackageItemAction {
  PENDING = 'pending',
  GET_GOODS = 'get_goods',
  ERROR = 'error',
}

interface IState {
  action: WrongPackageItemAction;
  icon: string;
  disabled: boolean;
  itemCatalogProps: ExpandedItemProps | null;
  currentItemWeight: string;
  currentItemDimensions: ItemParameters;
  error: ErrorType;
}

export default class WrongPackageItem extends PureComponent<
  WrongPackageItemProps,
  IState
> {
  currentItemOldDimensions: ItemParameters = {
    length: '',
    width: '',
    height: '',
  };
  currentItemOldWeight = '';

  state: IState = {
    action: WrongPackageItemAction.PENDING,
    icon: '',
    disabled: false,
    itemCatalogProps: null,
    currentItemWeight: '',
    currentItemDimensions: { length: '', width: '', height: '' },
    error: { code: -1, text: '' },
  };

  productsProblemStore = new HttpDatastore(
    '/api/datastore/productsProblemDataStore',
  );

  async fetchItemProps() {
    const { currentItem } = this.props;
    try {
      this.setState({ action: WrongPackageItemAction.PENDING });
      const itemProps = await getExpandedItemProps(
        currentItem.rollun_id,
        currentItem.shipping_label_id,
      );
      const dimensions = {
        length: itemProps.length || '',
        width: itemProps.width || '',
        height: itemProps.height || '',
      };
      const weight = itemProps.weight || '';
      this.setState({
        itemCatalogProps: itemProps,
        currentItemWeight: weight,
        currentItemDimensions: dimensions,
        action: WrongPackageItemAction.GET_GOODS,
      });
    } catch (err) {
      httpErrorHandler(err, (code, text) =>
        this.setState({
          action: WrongPackageItemAction.ERROR,
          error: { code, text },
        }),
      );
    }
  }

  componentDidMount() {
    this.fetchItemProps().catch();
  }

  componentDidUpdate(prevProps: Readonly<WrongPackageItemProps>): void {
    if (
      prevProps.currentItem.part_number !== this.props.currentItem.part_number
    ) {
      this.fetchItemProps().catch();
    }
  }

  _newProblemRequest(problem: any) {
    return this.productsProblemStore.create(problem);
  }

  problemFactory = (
    type: string,
    data: ItemParameters | { weight: string },
  ) => {
    const currentTime = Date.now();
    return {
      id: currentTime,
      problem_type: type,
      part_number: this.props.currentItem.part_number,
      data,
      create_timestamp: Math.floor(currentTime / 1000),
      shipping_label_id: this.props.currentItem.shipping_label_id,
    };
  };

  sendNewItemInfo = () => {
    this.setState({ action: WrongPackageItemAction.PENDING });
    const promises = [];
    let problemWithGood = false;
    if (!_.isEqual(this.currentItemOldWeight, this.state.currentItemWeight)) {
      const newProblem = this.problemFactory('weight', {
        weight: this.state.currentItemWeight,
      });
      promises.push(this._newProblemRequest(newProblem));
      problemWithGood = true;
    }
    if (
      !_.isEqual(
        this.state.currentItemDimensions,
        this.currentItemOldDimensions,
      )
    ) {
      const newProblem = this.problemFactory(
        'dimensions',
        this.state.currentItemDimensions,
      );
      promises.push(this._newProblemRequest(newProblem));
      problemWithGood = true;
    }
    Promise.all(promises)
      .then(() => this.props.onAccept(problemWithGood))
      .catch(() => this.props.onAccept(problemWithGood));
  };

  handleChangeItemParams(type: Dimensions | 'weight', value: string) {
    if (type === 'weight') {
      this.setState({ currentItemWeight: value });
    } else {
      this.setState({
        currentItemDimensions: {
          ...this.state.currentItemDimensions,
          [type]: value,
        },
      });
    }
  }

  _renderChangeItemInfoForm() {
    const {
      currentItemDimensions: { length, width, height },
      currentItemWeight,
    } = this.state;

    const inputProps = {
      [Dimensions.length]: { value: length, content: '   L' },
      [Dimensions.width]: { value: width, content: '   W' },
      [Dimensions.height]: { value: height, content: '   H' },
    };

    return (
      <div>
        <h5>Change weight and dimensions, in case they are wrong</h5>
        <div className="d-flex">
          <div>
            <div className="change-good-title mb-2">Weight:</div>
            <div className="change-good-title">Dimensions:</div>
          </div>
          <div>
            <div className="mb-2">
              <span className="change-good-name">LBS </span>
              <input
                className="form-control change-good-input"
                type="number"
                value={currentItemWeight}
                onChange={(e) =>
                  this.handleChangeItemParams('weight', e.target.value)
                }
              />
            </div>
            <div>
              {Object.entries(inputProps).map(([key, value]) => {
                return (
                  <div key={key}>
                    <span className="change-good-name">
                      <pre className="d-inline">{value.content}</pre>
                    </span>
                    <input
                      className="form-control change-good-input"
                      type="number"
                      value={value.value}
                      onChange={(e) =>
                        this.handleChangeItemParams(
                          key as Dimensions,
                          e.target.value,
                        )
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSwitch(appState: WrongPackageItemAction) {
    const { currentItem } = this.props;
    const { itemCatalogProps, error } = this.state;
    switch (appState) {
      case WrongPackageItemAction.PENDING:
        return (
          <div className="w-100 h-100 vh-50">
            <Spinner />
          </div>
        );
      case WrongPackageItemAction.GET_GOODS:
        return (
          <div>
            {itemCatalogProps ? (
              <div className="goods-layout">
                <div className="goods-layout">
                  <GoodsItemInfo
                    order={this.props.order}
                    managerNote={currentItem.manager_note}
                    itemProps={itemCatalogProps}
                    scanningProgressString={`${currentItem.itemNumber}/${currentItem.quantity}`}
                  />
                </div>
              </div>
            ) : null}
            {this._renderChangeItemInfoForm()}
            <Button
              color="primary"
              block
              onClick={this.sendNewItemInfo}
              className="mt-3"
            >
              Accept
            </Button>
          </div>
        );
      case WrongPackageItemAction.ERROR:
        return (
          <>
            <ErrorView error={error}>
              <Button color="primary">Back</Button>
            </ErrorView>
          </>
        );
    }
  }

  render() {
    return this.props.allGoodScanned
      ? null
      : this.renderSwitch(this.state.action);
  }
}
