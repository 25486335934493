import React, { Component, FormEvent } from 'react';
import { Query, Select } from 'rollun-ts-rql';
import _ from 'lodash';
import HttpDatastore from 'rollun-ts-datastore';
import { httpErrorHandlerPromised } from '../../../../utils/common.utils';
import { downloadAsCSV } from 'rollun-ts-utils';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import MuiSelect from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import MuiButton from '../../../../UI/MuiButton';

interface IProps {
  datastoreURL: string;
  fieldNames: Array<string>;
  idField: string;
  currentQuery: Query;
  updateFieldName: string;
}

interface IState {
  updateFieldName: string;
  isLoading: boolean;
  errors: Array<string>;
  progress: string;
}

class MultiRowUpdate extends Component<IProps, IState> {
  private ds: HttpDatastore<any>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      updateFieldName:
        props.updateFieldName !== props.idField ? props.updateFieldName : '',
      isLoading: false,
      errors: [],
      progress: '',
    };

    this.ds = new HttpDatastore(props.datastoreURL, { idField: props.idField });
  }

  setProgress(str: string) {
    this.setState({ progress: str });
  }

  setErrors(newErrors: Array<string> | string) {
    this.setState(({ errors }) => ({
      errors: Array.isArray(newErrors) ? newErrors : errors.concat(newErrors),
    }));
  }

  handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = new FormData(e.target as HTMLFormElement);
    const { idField = 'id' } = this.props;
    const { updateFieldName } = this.state;

    const value = form.get('update-value');
    const processedValue = value === 'null' ? null : value;

    const query = _.cloneDeep(this.props.currentQuery);
    query.setSelect(new Select([idField]));
    this.setProgress('Fetch data by current filter...');
    let data: any[] = [];
    this.setState({ isLoading: true });
    try {
      const limit = query.limitNode._limit;

      if (limit && limit > 100) {
        this.setProgress('');
        this.setErrors(
          `Limit is too big. Please, use limit less than 100 or remove limit.`,
        );
        this.setState({ isLoading: false });

        return;
      }

      data = await this.ds.query(query);
    } catch (e) {
      const { code, text } = await httpErrorHandlerPromised(e);
      this.setProgress('');
      this.setErrors(`Error while fetching data from table: [${code}] ${text}`);
      return;
    }

    const errors = [];
    for (let i = 0, len = data.length; i < len; i++) {
      this.setProgress(`Updating ${i + 1} of ${len} item...`);

      const item = data[i];
      try {
        await this.ds.update({
          ...item,
          [updateFieldName]: processedValue,
        });
      } catch (e) {
        const { code, text } = await httpErrorHandlerPromised(e);
        errors.push(
          `Error while updating item #${i + 1} with ${idField}=${
            item[idField]
          }, ${updateFieldName}=${processedValue}: [${code}] ${text}`,
        );
      }
    }
    this.setProgress('All done!');
    this.setErrors(errors);
    this.setState({ isLoading: false });
  };

  render() {
    const { updateFieldName, isLoading, errors, progress } = this.state;
    const { fieldNames } = this.props;

    console.log(updateFieldName);
    return (
      <>
        <DialogContent>
          <Box marginBottom={1}>
            <Typography variant="h6">
              <Box fontWeight="bold" component="span">
                IMPORTANT
              </Box>{' '}
              current filter will be used without{' '}
              <Box fontWeight="bold" component="span">
                limit
              </Box>
            </Typography>
            <Typography variant="h6">Select field to update:</Typography>
          </Box>

          <Box marginBottom={1}>
            <MuiSelect
              fullWidth
              variant="outlined"
              value={updateFieldName}
              disabled={isLoading}
              label="Select field..."
              onChange={(e) =>
                this.setState({ updateFieldName: e.target.value as string })
              }
            >
              {fieldNames.map((field) => (
                <MenuItem key={field} value={field}>
                  {field}
                </MenuItem>
              ))}
            </MuiSelect>
          </Box>

          <form onSubmit={this.handleFormSubmit}>
            <TextField type="hidden" value={updateFieldName} required />
            <Typography variant="h6">
              Change current value for column {updateFieldName} in rows to:
            </Typography>
            <TextField
              type="text"
              name="update-value"
              label="Enter value. To set empty, just leave input empty"
              fullWidth
              variant="outlined"
            />
            {progress && <Typography variant="h6">{progress}</Typography>}
            <Box marginTop={1}>
              <MuiButton
                color="primary"
                disabled={!updateFieldName || isLoading}
                type="submit"
                fullWidth
              >
                Update
              </MuiButton>
            </Box>
          </form>
          {errors.length > 0 && (
            <Box>
              <Typography variant="h6" color="error">
                There were some errors ({errors.length}) while updating.
              </Typography>
              <MuiButton
                color="error"
                fullWidth
                disabled={isLoading}
                onClick={() =>
                  downloadAsCSV(
                    errors.map((error) => ({ error })),
                    'errors.csv',
                  )
                }
              >
                Download errors
              </MuiButton>
            </Box>
          )}
        </DialogContent>
      </>
    );
  }
}

export default MultiRowUpdate;
