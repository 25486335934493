import React, { useMemo, useState } from 'react';
import MuiButton from '../../../../../UI/MuiButton';
import {
  Theme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import MuiIconButton from '../../../../../UI/MuiIconButton';
import { Alert } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import { logger } from '../../../../../utils/logger';
import LifecycleToken from '../../../../../utils/lifecycle-token';
import { crmReturns } from '../utils/createDeals/CrmReturns';
import { crmOrders } from '../utils/createDeals/CrmOrders';
import { isJSON } from 'rollun-ts-utils';
import { returnReason } from '../../constants/return-reason';
import { DealSchemas } from '../utils/RawDataParser';

export const marketplaces = [
  'Amazon Shoptimistic',
  'Ebay Rollun',
  'Ebay Plaisir',
  // 'Walmart Rollun',
] as const;

type Marketplaces = typeof marketplaces[number];

export const dealSchemas = {
  'Amazon Shoptimistic': ['order', 'return', 'return fba'] as const,
  'Ebay Rollun': ['order', 'return'] as const,
  'Ebay Plaisir': ['order', 'return'] as const,
};

function isResponse(obj: any): obj is Response {
  return 'ok' in obj && 'url' in obj && 'status' in obj && 'statusText' in obj;
}

const useStyles = makeStyles(() => ({
  message: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  alert: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
}));

export const lcTokenStore = new LifecycleToken(sessionStorage);

export const CreateDealFromRawData = () => {
  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );
  const [isOpen, setIsOpen] = useState(false);
  const [marketplace, setMarketplace] = useState<Marketplaces>(marketplaces[0]);
  const [dealSchema, setDealSchema] = useState<
    typeof dealSchemas[Marketplaces][number]
  >(dealSchemas[marketplace][0]);
  const [retReason, setRetReason] = useState('');
  const [rawData, setRawData] = useState('');
  const [isDisabledCreate, setIsDisabledCreate] = useState(false);
  const [alert, setAlert] = useState<{
    isError: boolean;
    isOpen: boolean;
    msg: string[];
    hint: string;
    error: string;
    id: string;
    lctoken: string;
  }>({
    isError: false,
    isOpen: false,
    msg: [],
    hint: '',
    error: '',
    id: '',
    lctoken: '',
  });
  const classes = useStyles();

  const isReturn = useMemo(() => {
    return dealSchema === 'return' || dealSchema === 'return fba';
  }, [dealSchema]);

  const setDefaultAlert = () => {
    setAlert({
      isError: false,
      isOpen: false,
      msg: [],
      hint: '',
      error: '',
      id: '',
      lctoken: '',
    });
  };

  const hintHandler = () => {
    if (
      isReturn &&
      (marketplace === 'Ebay Plaisir' || marketplace === 'Ebay Rollun')
    ) {
      return "Don't forget to set returnLocation.";
    }
    return '';
  };

  const handleDealCreate = async () => {
    setDefaultAlert();
    setIsDisabledCreate(true);
    try {
      const dealData =
        isReturn && dealSchema !== 'order'
          ? await crmReturns.createFromText(
              rawData,
              marketplace as any,
              retReason,
              dealSchema,
            )
          : await crmOrders.createFromText(rawData, marketplace as any);

      const { deal: orderDeal, message } = dealData;

      setAlert({
        isError: false,
        isOpen: true,
        msg: message,
        hint: hintHandler(),
        id: orderDeal.id,
        error: '',
        lctoken: '',
      });
      setRawData('');
    } catch (e) {
      const lctoken = lcTokenStore.getSessionToken();
      console.log('error in createDealFromRawData', e);
      let error = '';
      if (isResponse(e)) {
        error = `Code: ${e.status} URL: ${e.url} Text: ${e.statusText}`;
      } else if (e instanceof Error) {
        error = e.message;
      } else {
        error = JSON.stringify(e);
      }
      setAlert({
        isError: true,
        isOpen: true,
        msg: ['Error during creation.'],
        hint: '',
        error,
        id: '',
        lctoken,
      });

      const errorContext = isJSON(error)
        ? typeof JSON.parse(error) === 'object' &&
          !Array.isArray(JSON.parse(error))
          ? { ...JSON.parse(error), rawData }
          : { error: JSON.parse(error), rawData }
        : { error, rawData };

      logger.error(`CreateDealFromRawData, handleDealCreate()`, errorContext);
    } finally {
      setIsDisabledCreate(false);
    }
  };

  return (
    <>
      {isTablet ? (
        <MuiIconButton
          onClick={() => setIsOpen(true)}
          title="Create New deal"
          color="success"
          width={40}
          height={40}
          iconName="plus-square"
        />
      ) : (
        <MuiButton
          onClick={() => setIsOpen(true)}
          color="success"
          style={{
            minWidth: 184,
          }}
        >
          Create Deal from Raw Data
        </MuiButton>
      )}
      <Dialog
        open={isOpen}
        onClose={() => {
          setDefaultAlert();
          setRawData('');
          setIsOpen(false);
        }}
      >
        <DialogTitle>Create Deal from Raw Data</DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            style={{
              gap: 8,
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="marketplace">Marketplace</InputLabel>
              <Select
                labelId="marketplace"
                value={marketplace}
                onChange={(e) => {
                  const value = e.target.value as Marketplaces;
                  const schema = dealSchemas[value];
                  setMarketplace(value);
                  if (!schema.includes(dealSchema as any))
                    setDealSchema(schema[0]);
                }}
                style={{ minWidth: 275 }}
              >
                {marketplaces.map((marketplace) => (
                  <MenuItem value={marketplace} key={marketplace}>
                    {marketplace}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="deal-schema">Deal schema</InputLabel>
              <Select
                labelId="deal-schema"
                value={dealSchema}
                onChange={(e) => {
                  setDealSchema(e.target.value as DealSchemas);
                }}
                style={{ minWidth: 275 }}
              >
                {((dealSchemas[marketplace] as unknown) as any[]).map(
                  (schema) => (
                    <MenuItem value={schema} key={schema}>
                      {schema}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>

            <Alert
              severity={alert.isError ? 'error' : 'success'}
              elevation={6}
              variant="filled"
              hidden={!alert.isOpen}
              className={classes.alert}
            >
              {alert.isError && !alert.id ? (
                <Box display="flex" flexDirection="column">
                  <Typography className={classes.message}>
                    {alert.msg}
                  </Typography>
                  <Typography className={classes.message}>
                    {alert.error}
                  </Typography>
                  <Typography className={classes.message}>
                    LifecycleToken: {alert.lctoken}
                  </Typography>
                </Box>
              ) : (
                <Box display="flex" flexDirection="column">
                  <Box>
                    {alert.msg.length ? (
                      alert.msg.map((value) => (
                        <Typography className={classes.message} key={value}>
                          {value}
                        </Typography>
                      ))
                    ) : (
                      <></>
                    )}
                    <Link
                      style={{
                        color: '#3f96f2',
                        fontSize: 'bold',
                      }}
                      to={`/crm/deals/${isReturn ? 'returns' : 'orders'}/${
                        alert.id
                      }`}
                    >
                      {alert.id}
                    </Link>
                  </Box>
                  <Typography className={classes.message}>
                    {alert.hint}
                  </Typography>
                </Box>
              )}
            </Alert>

            {isReturn && (
              <FormControl fullWidth>
                <InputLabel id="ret-reason">Marketplace Ret Reason</InputLabel>
                <Select
                  labelId="ret-reason"
                  value={retReason}
                  onChange={(e) => {
                    setRetReason(e.target.value as string);
                  }}
                  style={{ minWidth: 275 }}
                >
                  {[...returnReason, ''].map((reason) => (
                    <MenuItem value={reason} key={reason}>
                      {reason || '<empty>'}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <TextField
              fullWidth
              label="Raw data"
              multiline
              variant="outlined"
              minRows={10}
              maxRows={30}
              value={rawData}
              onChange={(e) => setRawData(e.target.value)}
            />

            <MuiButton
              color="success"
              onClick={handleDealCreate}
              disabled={rawData.length === 0 || isDisabledCreate}
              style={{
                color: rawData.length === 0 ? 'gray' : 'white',
              }}
            >
              Create Deal
            </MuiButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
