import { useQuery } from 'react-query';
import { Query, Eq, Select } from 'rollun-ts-rql';
import { logger } from '../../../utils/logger';
import HttpDatastore from 'rollun-ts-datastore';

const shippingLabelDataStore = new HttpDatastore(
  '/api/datastore/shippingLabelDataStore',
);

export const getLinkedDealsQuantity = (id: string) => {
  return useQuery<number | string | undefined, any>(
    'getLinkedDealsQuantity' + id,
    async () => {
      try {
        const data =
          (await shippingLabelDataStore.query(
            new Query({
              query: new Eq('united_order_id', id),
              select: new Select(['united_order_id']),
            }),
          )) || [];
        return data.length;
      } catch (err) {
        logger.error('getLinkedDealsQuantity error', { err });
        return 'Error';
      }
    },
    {
      retry: 0,
    },
  );
};
