import React from 'react';
import MuiIconButton from './MuiIconButton';

interface DocsLinkProps {
  link: string;
  title: string;
}

export const DocsLink = ({ link, title }: DocsLinkProps) => {
  const openInNewTab = () => {
    window.open(link);
  };

  return (
    <MuiIconButton
      color="info"
      title={title}
      width={40}
      height={40}
      iconName="info-circle"
      onClick={openInNewTab}
    />
  );
};
