import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { FC } from 'react';
import MuiButton from '../../../UI/MuiButton';

interface AlertDialogProps {
  isAlertOpen: boolean;
  setIsAlertOpen: (value: boolean) => void;
  onClick: () => void;
}

export const AlertDialog: FC<AlertDialogProps> = (props) => {
  const { isAlertOpen, setIsAlertOpen, onClick } = props;
  return (
    <Dialog open={isAlertOpen} onClose={() => setIsAlertOpen(false)}>
      <DialogTitle>Apply sticker or report your problem</DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <MuiButton
          color="primary"
          style={{
            color: '#fff',
            minWidth: '200px',
            width: '200px',
          }}
          onClick={onClick}
        >
          Ok
        </MuiButton>
      </DialogContent>
    </Dialog>
  );
};
