import { FC, ReactNode } from 'react';
import ReactDOM from 'react-dom';

interface IProps {
  children: ReactNode;
}

const JSONATAContainer = document.getElementById('jsonata') as Element;

const JSONATAModal: FC<IProps> = ({ children }) => {
  return ReactDOM.createPortal(children, JSONATAContainer);
};

export default JSONATAModal;
