import React, { FC, ReactNode, useState } from 'react';
import Header from './components/Header';
import { Box } from '@material-ui/core';
import { WebHookConfig } from '../../../Table/components/webHookActivator/WebhookActivator';
import DealButtons from './components/DealButtons';
import DealInfo from './components/DealInfo';
import { WidgetProps } from './components/DealWidgets';
import { DealContext } from './context/dealContext';
import { Client, Deal, Position } from '../api-clients/types';
import { JSONataInteropModal } from '../../../Table/components/JSONataInterop/JSONataInteropModal';
import { JSONataProvider } from '../../../Table/components/JSONataInterop/JSONataContext';

export enum DealType {
  Bag = 'bag',
  Order = 'order',
  Pickup = 'pickup',
  Dropship = 'dropship',
  ReturnOrder = 'return',
  ReturnPickup = 'returnPickup',
  ReturnBag = 'returnBag',
  ReturnDropship = 'returnDropship',
  Problem = 'problem',
  Fba = 'fba',
  Employees = 'employees',
  Client = 'client',
}

export type FieldConfig = {
  apiFieldPath: string;
  displayName: string;
  type: 'input' | 'select' | 'datetime';
  group?: string;
  multiline?: boolean;
  options?: (string | number)[];
  required?: boolean;
  helperText?: string;
  regexp?: RegExp;
  trim?: boolean;
};

export type FieldsConfig = Record<string, FieldsConfigWithCopy>;

interface FieldsConfigWithCopy {
  fields: FieldConfig[];
  formatForCopying?: (deal: any) => string;
}

export interface DealProps {
  type: DealType;
  widgets: WidgetProps[];
  buttons: (
    | {
        component: ReactNode;
      }
    | WebHookConfig
  )[];
  fieldsConfig: FieldsConfig;
}

export interface DealConfig {
  disableItemsTable: boolean;
  disableStatusSelector: boolean;
}

export interface GenericDealProps {
  type: DealType;
  widgets: WidgetProps[];
  buttons: (
    | {
        component: ReactNode;
      }
    | WebHookConfig
  )[];
  fieldsConfig: FieldsConfig;
  data: Deal;
  // same deal but in 'raw' format, as in API. Useful,
  // when you need to send deal to the backend in exact format
  // for example for logging
  rawData: Record<string, any>;
  availableStatuses?: {
    id: string;
    name: string;
    color?: string;
  }[];

  onItemsAdd?: (
    id: string,
    positions: Omit<Position, 'offerId' | 'dealId' | 'id'>[],
  ) => Promise<void>;
  onItemsDelete?: (id: string, positionIds: string[]) => Promise<void>;

  onDealUpdate: <T>(id: string, deal: Partial<T>) => Promise<void>;
  onClientUpdate?: (id: string, client: Partial<Client>) => Promise<void>;
  dealConfig?: DealConfig;
}

const GenericDeal: FC<GenericDealProps> = ({
  type,
  buttons,
  fieldsConfig,
  widgets,
  data,
  rawData,
  availableStatuses = [],
  onDealUpdate,
  onItemsAdd,
  onItemsDelete,
  onClientUpdate,
  dealConfig = {
    disableItemsTable: false,
    disableStatusSelector: false,
  },
}) => {
  const [dealState, setDealState] = useState({
    deal: data,
    rawDeal: rawData,
  });

  return (
    <DealContext.Provider
      value={{
        type,
        onDealUpdate,
        onItemsAdd,
        onItemsDelete,
        onClientUpdate,
        ...dealState,
        setDealState,
      }}
    >
      <Box
        style={{
          height: '100%',
        }}
      >
        <Box display="flex" flexDirection="column" style={{ gap: 8 }}>
          <JSONataProvider initialValue={null}>
            <JSONataInteropModal isVisible={false} />
            <Header
              deal={data}
              type={type}
              dealConfig={dealConfig}
              availableStatuses={availableStatuses}
            />
            <DealButtons buttons={buttons} />
            <DealInfo
              deal={data}
              fieldsConfig={fieldsConfig}
              type={type}
              widgets={widgets}
              dealConfig={dealConfig}
            />
          </JSONataProvider>
        </Box>
      </Box>
    </DealContext.Provider>
  );
};

export default GenericDeal;
