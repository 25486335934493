import { useQuery } from 'react-query';
import HttpDatastore from 'rollun-ts-datastore';
import { Query, Eq } from 'rollun-ts-rql';
const GET_LABEL = 'GET_LABEL';

const datastore = new HttpDatastore('/api/datastore/LabelsDataStore');

export const useGetLabels = (id: string | undefined) => {
  return useQuery(
    [GET_LABEL, id],
    async () => {
      if (!id) return;
      return await datastore.query(
        new Query({
          query: new Eq('DealId', id),
        }),
      );
    },
    {
      retry: 0,
    },
  );
};
