export const returnCondition = [
  'new_correct_item',
  'new_incorrect_item',
  'correct_with_problem',
  'incorrect_with_problem',
  'was_not_refunded',
  'was_refunded',
  'was_not_received',
  'was_not_shipped',
];

export const returnConditionPickup = [
  'new_correct_item',
  'new_incorrect_item',
  'correct_with_problem',
  'incorrect_with_problem',
  'was_not_received',
  'was_not_shipped',
];

export const returnConditionDropship = [
  'was_not_shipped',
  'was_refunded',
  'was_not_refunded',
];
