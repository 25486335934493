import React from 'react';
import AbstractService, {
  AbstractServiceProps,
} from '../../../AbstractService';
import AppContainer from '../../../../layout/AppContainer/AppContainer';
import { ErrorView, Spinner } from '../../../../UI';
import { DealType } from '../GenericDeal';
import HowToBuy4 from './widgets/HowToBuy/HowToBuy4/HowToBuy4';
import CreateLinkedDealsV2 from './widgets/HowToBuy/CreateLinkedDealsV2/CreateLinkedDealsV2';
import CreateLinkedDealsV3 from './widgets/HowToBuy/CreateLinkedDealsV3/CreateLinkedDealsV3';
import CreateProblemDeal from '../common/widgets/CreateProblemDeal/index';
import OrderDeal from './components/OrderDeal';
import { clientFieldConfig } from '../GenericDeal/config/client-field.config';
import LinkedDeals from '../common/widgets/LinkedDeals/index';
import { marketplaceNames } from '../constants/marketplace-names';
import { shipMethods } from '../constants/ship-methods';
import { archiveScenarioOrder } from '../constants/archive-scenario';
import { apiClientsPickups } from '../api-clients/api-clients-pickups';
import { apiClientsDropship } from '../api-clients/api-clients-dropships';
import { Scenarios } from '../common/widgets/Scenarios';
import { scenarios } from '../../../Table/components/TableHeaderButtons/buttons/Scenarios/scenarios';
import { apiClientsProblems } from '../api-clients/api-clients-problems';

class CRMOrderDeal extends AbstractService<AbstractServiceProps> {
  render() {
    const { appConfig, error } = this.state;

    if (error) {
      return (
        <AppContainer services={{}}>
          <div className="w-100 h-100 d-flex flex-column justify-content-center">
            <ErrorView error={error}>
              <div className="d-flex justify-content-center p-3">
                <div
                  className="btn btn-primary cursor-pointer"
                  onClick={this.reloadHome}
                >
                  Home
                </div>
              </div>
            </ErrorView>
          </div>
        </AppContainer>
      );
    }

    if (appConfig === null) {
      return <Spinner />;
    }

    const { user, role } = this.props.userIdentity;
    return (
      <AppContainer
        currentResourceName={this.props.resourceName}
        services={this.props.availableServices}
        user={user}
        role={role}
      >
        <OrderDeal
          fieldsConfig={{
            ...clientFieldConfig,
            Marketplace: {
              fields: [
                {
                  displayName: 'Mp name',
                  type: 'select',
                  options: marketplaceNames,
                  apiFieldPath: 'mpName',
                },
                {
                  displayName: 'Mp client id',
                  type: 'input',
                  apiFieldPath: 'mpClientId',
                },
                {
                  displayName: 'Mp order number',
                  type: 'input',
                  apiFieldPath: 'mpOrderNumber',
                },
                {
                  displayName: 'Mp ship method',
                  type: 'select',
                  options: shipMethods,
                  apiFieldPath: 'mpShipMethod',
                },
                {
                  displayName: 'Mp ship template',
                  type: 'input',
                  apiFieldPath: 'mpShipTemplate',
                },
                {
                  displayName: 'Mp order note',
                  type: 'input',
                  apiFieldPath: 'mpOrderNote',
                },
                {
                  displayName: 'Date Cr paid',
                  type: 'datetime',
                  apiFieldPath: 'dateCrPayed',
                },
                {
                  displayName: 'Date ship by',
                  type: 'datetime',
                  apiFieldPath: 'dateShipBy',
                },
                {
                  displayName: 'Date deliver by',
                  type: 'datetime',
                  apiFieldPath: 'dateDeliverBy',
                },
                {
                  displayName: 'COGS',
                  type: 'input',
                  apiFieldPath: 'cogs',
                },
                {
                  displayName: 'Profit',
                  type: 'input',
                  apiFieldPath: 'profit',
                },
                {
                  displayName: 'Tracknumbers in MP',
                  type: 'input',
                  apiFieldPath: 'tracknumber',
                },
              ],
            },
            'Tech fields': {
              fields: [
                {
                  displayName: 'Problem description',
                  type: 'input',
                  apiFieldPath: 'problemDescription',
                  multiline: true,
                },
                {
                  displayName: 'archive scenario',
                  type: 'select',
                  options: archiveScenarioOrder,
                  apiFieldPath: 'archiveScenario',
                },
              ],
            },
            HowToBuy: {
              fields: [
                {
                  displayName: 'How to buy 4',
                  type: 'input',
                  apiFieldPath: 'howToBuy4',
                },
                {
                  displayName: 'How to buy 4 result',
                  type: 'input',
                  apiFieldPath: 'howToBuy4Result',
                },
                {
                  displayName: 'How to buy 4 best shipping',
                  type: 'input',
                  apiFieldPath: 'howToBuy4BestShipping',
                },
                {
                  displayName: 'How to buy 3 result',
                  type: 'input',
                  apiFieldPath: 'howToBuy3Result',
                },
              ],
            },
          }}
          type={DealType.Order}
          buttons={[]}
          widgets={[
            {
              displayName: 'How to buy',
              buttonName: 'How to buy',
              component: HowToBuy4,
              docsLink:
                'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.3npx25jn0rm',
              docsTitle: 'docs for HowToBuy widget',
            },
            {
              displayName: 'Create Linked Deals V2',
              buttonName: 'Create Linked Deals V2',
              component: CreateLinkedDealsV2,
              docsLink:
                'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.7djzd4om7m16',
              docsTitle: 'docs for CreateLinkedDealsV2 widget',
            },
            {
              displayName: 'Create Linked Deals V3',
              buttonName: 'Create Linked Deals V3',
              component: CreateLinkedDealsV3,
              docsLink:
                'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.oauoijnr70us',
              docsTitle: 'docs for CreateLinkedDealsV3 widget',
            },
            {
              displayName: 'Create Problem Deal',
              buttonName: 'Create Problem Deal',
              component: CreateProblemDeal,
              docsLink:
                'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.s8hlgyoe9i7t',
              docsTitle: 'docs for CreateProblemDeal widget',
            },
            {
              displayName: 'Linked deals',
              buttonName: 'Linked deals',
              component: LinkedDeals,
              docsLink:
                'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.hwje1ah0h4ok',
              docsTitle: 'docs for Linked deals widget',
              props: {
                linkField: 'id',
                apiClients: [
                  {
                    type: DealType.Pickup,
                    apiClient: apiClientsPickups,
                    customLinkField: 'relatedOrder',
                  },
                  {
                    type: DealType.Dropship,
                    apiClient: apiClientsDropship,
                    customLinkField: 'relatedOrder',
                  },
                  {
                    type: DealType.Problem,
                    apiClient: apiClientsProblems,
                    customLinkField: 'relatedDeal',
                  },
                ],
              },
            },
            {
              displayName: 'Scenarios',
              buttonName: 'Scenarios',
              component: Scenarios,
              props: {
                scenarios: scenarios.order,
              },
              docsLink:
                'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.m1wjq0kj6hxo',
              docsTitle: 'docs for Scenarios widget',
            },
          ]}
        />
      </AppContainer>
    );
  }
}

export default CRMOrderDeal;
