import React from 'react';
import { Box } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';

const columns = [
  { field: 'id', headerName: 'ID', flex: 1 },
  {
    field: 'price',
    headerName: 'Price',
    type: 'number',
    flex: 1,
    editable: true,
  },
];

const rows = [
  { id: 1, price: 35 },
  { id: 2, price: 42 },
  { id: 3, price: 44 },
];

const DealTable = ({}) => {
  return (
    <Box
      style={{
        height: '100%',
        minHeight: 500,
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        style={{
          height: '100%',
        }}
      />
    </Box>
  );
};

export default DealTable;
