import React, { useContext, useEffect } from 'react';
import { useUnidentifiedReturnsItemsDatastore } from '../../../hooks/datastores';
import { ErrorView, Spinner } from '../../../UI';
import { Query, Select } from 'rollun-ts-rql';
import { Grid, Typography } from '@material-ui/core';
import MuiButton from '../../../UI/MuiButton';
import _ from 'lodash';
import { StorageContext } from './UnidentifiedProducts';

const SelectStorage = () => {
  const [
    { result, isLoading, error },
    dispatch,
  ] = useUnidentifiedReturnsItemsDatastore();
  const context = useContext(StorageContext);
  useEffect(() => {
    dispatch(
      'QUERY',
      new Query({
        select: new Select(['storage']),
      }),
    );
  }, []);
  const handleSetStorage = (location: string) => {
    context?.setStorage(location);
  };
  if (error) {
    return <ErrorView error={error} />;
  }
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          Select your storage
        </Typography>
      </Grid>
      {result ? (
        _.uniq(result.map(({ storage }) => storage))
          .filter((storage) => !!storage)
          .map((storage, index) => (
            <Grid item xs={12} key={index}>
              <MuiButton fullWidth onClick={() => handleSetStorage(storage)}>
                {storage}
              </MuiButton>
            </Grid>
          ))
      ) : (
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            No storages were found
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default SelectStorage;
