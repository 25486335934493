export const shipStatus = [
  'unknown',
  'pre_transit',
  'in_transit',
  'out_for_delivery',
  'delivered',
  'available_for_pickup',
  'return_to_sender',
  'failure',
  'cancelled',
  'error',
];
