import React from 'react';
import { FC, useState } from 'react';
import { TextField, Box, Typography } from '@material-ui/core';
import { ErrorView } from '../../../../../UI';
import MuiButton from '../../../../../UI/MuiButton';

interface DimensionStrokeProps {
  isEditing: boolean;
  dimensionName: string;
  dimensionValue: string;
  editDimension: (dimensionName: string, dimensionValue: string) => void;
}

const DimensionStroke: FC<DimensionStrokeProps> = ({
  isEditing,
  dimensionName,
  dimensionValue,
  editDimension,
}) => {
  return isEditing ? (
    <TextField
      style={{
        margin: '4px 0',
      }}
      inputProps={{
        style: {
          padding: 8,
        },
      }}
      label={dimensionName}
      variant="outlined"
      value={dimensionValue}
      fullWidth
      placeholder={`Enter new ${dimensionName}`}
      onChange={({ target }) => editDimension(dimensionName, target.value)}
    />
  ) : (
    <Typography variant="body1">
      {`${dimensionName}: ${dimensionValue}`}
    </Typography>
  );
};

const ItemsDimensions: FC<any> = ({
  width = 0,
  height = 0,
  length = 0,
  weight,
}) => {
  const [oldDimensions] = useState({
    width,
    height,
    length,
    weight,
  });
  const [newDimensions, setNewDimensions] = useState({
    width,
    height,
    length,
    weight,
  });
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isEditing, setEditing] = useState(false);

  const uploadNewDimensions = async () => {
    setLoading(true);
    setError(null);
    try {
      // const { width, height, length, weight } = newDimensions;
      // const [side_min, side_middle, side_max] = Object.values([
      //   width,
      //   height,
      //   length,
      // ]).sort((a, b) => a - b);
      // setOldDimension(newDimensions);
    } catch (e) {
      console.log(e);
      setError((e as Error).message || 'Unknown error');
    } finally {
      setLoading(false);
      setEditing(false);
    }
  };

  const dimensionsChanged =
    Object.values(oldDimensions).join('|') !==
    Object.values(newDimensions).join('|');

  return (
    <>
      {Object.entries(dimensionsChanged ? newDimensions : oldDimensions).map(
        ([key, value]) => (
          <DimensionStroke
            isEditing={isEditing}
            dimensionName={key}
            dimensionValue={value}
            editDimension={(dimensionName, dimensionValue) =>
              setNewDimensions({
                ...newDimensions,
                [dimensionName]: dimensionValue,
              })
            }
          />
        ),
      )}
      {error && (
        <ErrorView
          error={{
            text: error || '',
            code: 500,
          }}
        />
      )}
      {isEditing ? (
        <Box style={{ width: '100%' }}>
          <MuiButton
            style={{ width: '60%' }}
            disabled={isLoading}
            color="success"
            onClick={uploadNewDimensions}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </MuiButton>
          <MuiButton
            style={{ width: '40%' }}
            disabled={isLoading}
            color="error"
            onClick={() => {
              setNewDimensions(oldDimensions);
              setEditing(false);
            }}
          >
            Cancel
          </MuiButton>
        </Box>
      ) : (
        <MuiButton color="primary" onClick={() => setEditing(true)}>
          Edit dimensions
        </MuiButton>
      )}
    </>
  );
};

export default ItemsDimensions;
