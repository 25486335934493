import React, { FC, useState, useContext } from 'react';
import Box from '@material-ui/core/Box';
import {
  SOURCES_OF_COMS,
  WE_MUST_DO_OPTIONS,
  WE_WAIT_FOR_OPTIONS,
  WE_WAIT_FROM_OPTIONS,
} from './constants';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Link,
  makeStyles,
} from '@material-ui/core';
import MuiButton from '../../../../../../UI/MuiButton';
import { httpErrorHandlerPromised } from '../../../../../../utils/common.utils';
import { apiClientsProblems } from '../../../api-clients/api-clients-problems';
import { Bag, Dropship, Order, Pickup } from '../../../api-clients/types';
import { DealContext } from '../../../GenericDeal/context/dealContext';
import { DealType } from '../../../GenericDeal';
import { apiClientsBag } from '../../../api-clients/api-clients-bags';

type SelectConfig = {
  label: string;
  value: string;
  setter: (value: string) => void;
  options: string[];
};

const useStyles = makeStyles(() => ({
  input: {
    top: 'auto',
    left: 'auto',
  },
}));

const CreateProblemDeal: FC = () => {
  const classes = useStyles();
  const dealContext = useContext(DealContext);

  const [sourceOfCom, setSourceOfCom] = useState<
    typeof SOURCES_OF_COMS[number]
  >('-');
  const [weWaitFrom, setWeWaitFrom] = useState<
    typeof WE_WAIT_FROM_OPTIONS[number]
  >('-');
  const [weWaitFor, setWeWaitFor] = useState<
    typeof WE_WAIT_FOR_OPTIONS[number]
  >('-');
  const [weMustDo, setWeMustDo] = useState<typeof WE_MUST_DO_OPTIONS[number]>(
    '-',
  );
  const [createdProblemDealId, setCreatedProblemDealId] = useState('');
  const [loading, setLoading] = useState(false);

  const selectConfigs: SelectConfig[] = [
    {
      label: 'Source of communication',
      value: sourceOfCom,
      setter: (value) => setSourceOfCom(value as any),
      options: (SOURCES_OF_COMS as unknown) as string[],
    },
    {
      label: 'We wait from',
      value: weWaitFrom,
      setter: (value) => setWeWaitFrom(value as any),
      options: (WE_WAIT_FROM_OPTIONS as unknown) as string[],
    },
    {
      label: 'We wait for',
      value: weWaitFor,
      setter: (value) => setWeWaitFor(value as any),
      options: (WE_WAIT_FOR_OPTIONS as unknown) as string[],
    },
    {
      label: 'We must do',
      value: weMustDo,
      setter: (value) => setWeMustDo(value as any),
      options: (WE_MUST_DO_OPTIONS as unknown) as string[],
    },
  ];

  const makeSelect = ({ label, value, setter, options }: SelectConfig) => (
    <FormControl
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box style={{ minWidth: 200, width: 200 }}>
        <InputLabel id={label} className={classes.input}>
          {label}
        </InputLabel>
        <Select
          labelId={label}
          value={value}
          style={{ minWidth: 200, width: 200 }}
          onChange={(e) => setter(e.target.value as string)}
        >
          {(options as any[]).map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </FormControl>
  );

  const getPickupOrderNumber = async () => {
    const bagDealId = (dealContext?.deal as Pickup).bagLink;

    if (!bagDealId) {
      return 'Bag link field is not valid!';
    }

    try {
      const bagDeal = await apiClientsBag.getById(bagDealId);

      return bagDeal.srOrderNumber || 'Empty SR Order number';
    } catch (err) {
      return (err as Error).message || 'Error while fetching bag deal!';
    }
  };

  const makeProblemDealPayload = async () => {
    const deal = dealContext?.deal as Order | Pickup | Dropship | Bag;
    const type = dealContext?.type as DealType;

    let SROrderNumber = undefined;

    // get SROrderNumber from bag deal
    if (type === DealType.Pickup) {
      SROrderNumber = await getPickupOrderNumber();
    } else if (type === DealType.Dropship) {
      SROrderNumber = (deal as Dropship).srOrderNumber;
    } else if (type === DealType.Bag) {
      SROrderNumber = (deal as Bag).srOrderNumber;
    }

    return {
      marketplace1: deal.mpName,
      ...(dealContext?.type !== DealType.Bag && {
        mpOrderNumber: (deal as Order | Pickup | Dropship).mpOrderNumber,
        contractor: (deal as Order | Pickup | Dropship).contractor,
      }),
      //do not transfer SrOrderNumber if it is Order deal.
      ...(dealContext?.type !== DealType.Order && {
        SrOrderNumber: SROrderNumber,
      }),
      '4wewaitfrom': weWaitFrom === '-' ? '' : weWaitFrom,
      '4wewaitfor': weWaitFor === '-' ? '' : weWaitFor,
      '4wemustdo': weMustDo === '-' ? '' : weMustDo,
      sourceOfCommunication: sourceOfCom === '-' ? '' : sourceOfCom,
      positions: deal.no_special_items_positions,
      relatedDeal: deal.id,
    };
  };

  const handleProblemDealCreation = async () => {
    setLoading(true);

    try {
      const { id } = await apiClientsProblems.create(
        await makeProblemDealPayload(),
      );
      setCreatedProblemDealId(id);
    } catch (e) {
      alert((await httpErrorHandlerPromised(e)).text);
    }

    setLoading(false);
  };

  return (
    <Box
      style={{
        gap: 8,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
      }}
    >
      {selectConfigs.map((config) => makeSelect(config))}
      <Box>
        <MuiButton
          color="success"
          style={{ width: 200 }}
          onClick={handleProblemDealCreation}
          disabled={loading}
        >
          Create
        </MuiButton>
        {createdProblemDealId && (
          <Typography>
            Problem deal created with id:{' '}
            <Link href={`/crm/deals/problems/${createdProblemDealId}`}>
              {createdProblemDealId}
            </Link>
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default CreateProblemDeal;
