import _ from 'lodash';
import { noop } from '../../../utils/common.utils';
import HttpDatastore from 'rollun-ts-datastore';
import {
  fetchCategories,
  fetchCommonItemsData,
  fetchCompatibles,
  FileExchangeDataByRollunId,
  formatTitle,
} from './getFileExchangeByRollunId';
import { CompatibleVehicle } from '../../../utils/common.types';
import { isJSON } from 'rollun-ts-utils';
import { In, Query, Select } from 'rollun-ts-rql';

const PUCatalog = new HttpDatastore(
  '/api/datastore/PartsUnlimitedInventoryCacheDataStore',
);

const fetchItemsData = async (rids: Array<string>): Promise<Array<any>> => {
  return PUCatalog.query(
    new Query({
      select: new Select([
        'rollun_id',
        'part_number',
        's_name',
        'brand_name',
        'color',
        'size',
        'vendor_part_number',
        'upc_code',
        's_quantity',
      ]),
      query: new In('rollun_id', rids),
    }),
  );
};

const DealerProducts = new HttpDatastore(
  '/api/datastore/DealerProductsDataStore',
);
const fetchDealerProducts = async (
  csns: Array<string>,
): Promise<Array<any>> => {
  if (csns.length === 0) return [];
  return DealerProducts.query(
    new Query({
      select: new Select([
        'csn',
        'color',
        'size',
        'image',
        'features',
        'title',
      ]),
      query: new In('csn', csns),
    }),
  );
};

export const fetchPartsUnlimitedData = async (
  rollunIds: Array<string>,
  progressCB: (p: string) => void = noop,
): Promise<FileExchangeDataByRollunId> => {
  progressCB('Starting fetching data for PartsUnlimited...');

  const chunks = _.chunk(rollunIds, 100);

  let commonItemsData: Array<any> = [];
  let itemsData: Array<any> = [];
  let dealerProducts: Array<any> = [];

  let categoriesData: Array<{
    rollun_id: string;
    category_id: string;
    category_name: string;
  }> = [];
  let compatibles: Array<CompatibleVehicle & { rollunId: string }> = [];

  for (let i = 0, len = chunks.length; i < len; i++) {
    const chunk = chunks[i];
    progressCB(
      `Fetching ${i * 100 + chunk.length} of ${rollunIds.length} items`,
    );
    const [currentCommonItemsData, currentItemData] = await Promise.all([
      fetchCommonItemsData(chunk),
      fetchItemsData(chunk),
    ]);

    const [
      currentDealerProducts,
      currentCategoriesData,
      currentCompatibles,
    ] = await Promise.all([
      fetchDealerProducts(
        currentItemData.map(({ part_number }) => part_number),
      ),
      fetchCategories(
        currentItemData.map(({ rollun_id, s_name: name }) => ({
          rollun_id,
          name,
        })),
      ),
      fetchCompatibles(
        currentCommonItemsData
          .map(({ compatibles, id: rollunId }) => {
            return (compatibles !== '-1' && isJSON(compatibles)
              ? (Object.values(JSON.parse(compatibles)) as Array<string>)
              : []
            ).map((compatibleId) => ({ rollunId, compatibleId }));
          })
          .flat(),
      ),
    ]);

    dealerProducts = dealerProducts.concat(currentDealerProducts);
    compatibles = compatibles.concat(currentCompatibles);
    commonItemsData = commonItemsData.concat(currentCommonItemsData);
    itemsData = itemsData.concat(currentItemData);
    categoriesData = categoriesData.concat(currentCategoriesData);
  }

  return rollunIds.reduce((acc: FileExchangeDataByRollunId, rollunId) => {
    const {
      vendor_part_number = '',
      s_name = '',
      brand_name = '',
      upc_code = '',
      part_number = '',
      s_quantity = 0,
    } = itemsData.find(({ rollun_id }) => rollun_id === rollunId) || {};

    const {
      ct_price = 0,
      // ct_rollun_price  = 0,
      // ct_plaisir_price = 0
    } = commonItemsData.find(({ id }) => id === rollunId) || {};

    const { color, size, image = '', features = '', title = '' } =
      dealerProducts.find(({ csn }) => csn === part_number) || {};

    const { category_id = '' } =
      categoriesData.find(({ rollun_id }) => rollun_id === rollunId) || {};

    acc[rollunId] = {
      name: formatTitle([s_name, brand_name, color, size]),
      brand: brand_name,
      mpn: vendor_part_number,
      color,
      size,
      upc: upc_code,
      photo: (image == '-1' ? '' : image) || '',
      categoryId: category_id,
      ct_plaisir_price: ct_price,
      ct_rollun_price: ct_price,
      description: `${features} ${title}`,
      qty: +s_quantity,
      vehicles: compatibles.filter(({ rollunId: rid }) => rid === rollunId),
    };
    return acc;
  }, {});
};
