import { useQuery } from 'react-query';
import { secretsApi } from '../api-client';

export const GET_ALL_VERSIONS_OF_SECRET_KEY = 'GET_ALL_VERSIONS_OF_SECRET_KEY';

export const useGetAllVersionsOfSecret = (id: string) => {
  return useQuery(GET_ALL_VERSIONS_OF_SECRET_KEY, async () => {
    const {
      data,
    } = await secretsApi.secretsControllerGetAllVersionsOfSecretById(id);
    return data;
  });
};
