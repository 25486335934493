import { useContext } from 'react';
import { BagFillingContext } from '../BagFillingContext';
import { BagFillingPagesEnum } from '../BagFillingPages';

const useBagSelect = () => {
  const context = useContext(BagFillingContext);

  return (bagId: string) => {
    context.setBagId?.(bagId);
    context.goToPage?.(BagFillingPagesEnum.BagPreview);
  };
};

export { useBagSelect };
