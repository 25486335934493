import React from 'react';
import CRMControls from './components/CRMControls';

const components = {
  CRMControls: CRMControls,
};

export function HeaderComponentContainer({
  component,
}: {
  component: keyof typeof components;
}) {
  const Component = components[component];

  return Component ? <Component /> : <></>;
}
