import React, { useContext, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import ReturnedLabels from './ReturnedLabels';
import ReturnAppContext from '../context';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

const ReturnedLabelsPage = () => {
  const [searchInput, setSearchInput] = useState('');
  const context = useContext(ReturnAppContext);

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            id="find-label"
            label="Find return"
            value={searchInput}
            onChange={({ target: { value } }) => setSearchInput(value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => context.setPage('scanLabel')}>
                    <PhotoCameraIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <ReturnedLabels search={searchInput} />
      </Grid>
    </Box>
  );
};

export default ReturnedLabelsPage;
