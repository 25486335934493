import React, { PureComponent, ReactNode } from 'react';
import { Button } from './index';
import ClickOutside from './ClickOutside';
import { zIndexes } from '../themeProvider';

interface IState {
  isDropDownOpen: boolean;
}

interface IProps {
  color?: string;
  className?: string;
  toggleButtonChildren?: string | ReactNode | ReactNode[];
  toggleButtonClass?: string;
  renderToggle?: (props: { onClick: () => void }) => ReactNode;
  innerWidth?: number;
  toggleOnHover?: boolean;
  leftOffset?: number;
  topOffset?: number;
}

/**
 * Custom dropdown, takes as children dropdown options.
 *
 */

class Dropdown extends PureComponent<IProps, IState> {
  state = { isDropDownOpen: false };

  handleClick = () => {
    this.setState((prevState) => ({
      isDropDownOpen: !prevState.isDropDownOpen,
    }));
  };

  lastHoverTimeoutId: null | number = null;

  handleHover = (hover: boolean) => {
    if (hover) {
      // clear scheduled close dropdown after time, if mouse returned to dd.
      if (this.lastHoverTimeoutId) {
        clearTimeout(this.lastHoverTimeoutId);
        this.lastHoverTimeoutId = null;
      }
      this.setState({ isDropDownOpen: hover });
    } else {
      // add a little delay before close after mouse out, to prevent accidental leave from dropdown
      this.lastHoverTimeoutId = window.setTimeout(() => {
        this.setState({ isDropDownOpen: hover });
      }, 100);
    }
  };

  handleOutsideClick = () => {
    if (this.state.isDropDownOpen) {
      this.setState({ isDropDownOpen: false });
    }
  };

  render() {
    const { isDropDownOpen } = this.state;
    const {
      className = '',
      toggleButtonChildren = '',
      renderToggle,
      innerWidth = 200,
      color = 'primary',
      toggleButtonClass = '',
      toggleOnHover = false,
      topOffset = 10,
      leftOffset = 50,
    } = this.props;
    return (
      <ClickOutside onClickOutside={this.handleOutsideClick}>
        <div
          className="position-relative "
          onMouseLeave={() => toggleOnHover && this.handleHover(false)}
          onMouseOver={() => toggleOnHover && this.handleHover(true)}
        >
          {renderToggle ? (
            renderToggle({ onClick: this.handleClick })
          ) : (
            <Button
              color={color}
              onClick={() => {
                this.handleClick();
              }}
              className={'m-2 ' + toggleButtonClass}
            >
              {toggleButtonChildren}
            </Button>
          )}
          {isDropDownOpen ? (
            <div
              className={
                'position-absolute material-shadow border-radius m-1 d-flex  ' +
                className
              }
              style={{
                top: topOffset,
                left: leftOffset,
                width: innerWidth,
                zIndex: zIndexes.biggest,
                background: 'white',
              }}
            >
              {this.props.children}
            </div>
          ) : null}
        </div>
      </ClickOutside>
    );
  }
}

export default Dropdown;
