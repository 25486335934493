export const shipMethods = [
  'Standard',
  'Expedited',
  'FreeEconomy',
  'NextDay',
  'SecondDay',
  'Value',
  'International',
  'Overnight',
  'Pickup',
  'Other',
];
