import React, { ReactNode } from 'react';

interface IProps {
  id: string;
  shippingClass: string;
  totalGoods: number;
  unitedOrderId: string;
  children: ReactNode[] | ReactNode;
}

const shippingClassColors: { [key: string]: string } = {
  'First-Class Package Service': '#85929E',
};

const LabelInfo: React.FC<IProps> = ({
  id,
  shippingClass,
  totalGoods,
  unitedOrderId,
}) => {
  const color = shippingClassColors[shippingClass] || '#7D3C98';
  return (
    <div style={{ minWidth: 250 }} className="odd-child-dark-bg">
      <p className="p-2 label-goods-props d-flex justify-content-between">
        <b>Tracking ID:</b> {id}
      </p>
      <p
        className="p-2 label-goods-props d-flex justify-content-between"
        style={{ color }}
      >
        <b>Shipping class:</b> {shippingClass}
      </p>
      <p className="p-2 label-goods-props d-flex justify-content-between">
        <b>Total goods:</b> {totalGoods}
      </p>
      <p className="p-2 label-goods-props d-flex justify-content-between">
        <b>Order number:</b> {unitedOrderId}
      </p>
    </div>
  );
};

export default LabelInfo;
