import { ReturnOrder } from '../../api-clients/types';
import { ApiClientReturnOrders } from '../../api-clients/api-clients-return-orders';

export const useMutateDeal = () => {
  const handleUpdateDeal = async (
    id: string,
    data: Partial<Omit<ReturnOrder, 'id' | 'positions'>>,
  ) => {
    await new ApiClientReturnOrders().update(id, data);
  };

  return {
    handleUpdateDeal,
  };
};
