import { createContext } from 'react';
import { DealType } from '../index';
import { Deal, Client, Position } from '../../api-clients/types';

export type DealContext = {
  deal: Deal;
  rawDeal: Record<string, any>;

  type: DealType;

  onItemsAdd?: (
    id: string,
    positions: Omit<Position, 'offerId' | 'dealId' | 'id'>[],
  ) => Promise<void>;
  onItemsDelete?: (id: string, positionIds: string[]) => Promise<void>;

  onDealUpdate: <T>(id: string, deal: Partial<T>) => Promise<void>;
  onClientUpdate?: (id: string, client: Partial<Client>) => Promise<void>;
  setDealState: React.Dispatch<React.SetStateAction<any>>;
};

export const DealContext = createContext<DealContext | null>(null);
