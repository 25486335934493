import { useContext, useEffect } from 'react';
import { GlobalErrorContext } from '../../RootApp/Root.app';
import { ReturnBag, useReturnBagList } from '../../../hooks/datastores';
import { Eq, Query } from 'rollun-ts-rql';
import { STATUS_PROCESSED } from './useBagsList';
import { BagFillingContext } from '../BagFillingContext';
import { BagFillingPagesEnum } from '../BagFillingPages';
import { Dimension } from '../components/SetDimensions';
import { ErrorType } from '../../../utils/common.types';
import { HttpDatastoreStatus } from '../../../hooks/useHttpDatastore';

type UseDoneProcessingBag = (
  bagId?: string,
) => [
  {
    result: ReturnBag | null;
    error: ErrorType | null;
    status: HttpDatastoreStatus;
  },
  (dimensions: Dimension[]) => void,
  () => void,
];

const useDoneProcessingBag: UseDoneProcessingBag = (bagId?: string) => {
  const errorContext = useContext(GlobalErrorContext);
  const context = useContext(BagFillingContext);
  const [{ status, error, result }, dispatch] = useReturnBagList();

  useEffect(() => {
    if (!bagId) return;

    dispatch(
      'QUERY',
      new Query({
        query: new Eq('bag_number', bagId),
      }),
    );
  }, []);

  const goToBagsListAfterSubmit = () => {
    context.goToPage?.(BagFillingPagesEnum.BagsList);
    errorContext?.showAlert('Bag has successfully been processed', 'success');
  };

  const submitDimensions = async (dimensions: Dimension[]) => {
    if (!bagId) {
      errorContext?.showAlert('Bag id is not specified', 'error');
      return;
    }

    await dispatch('UPDATE', {
      bag_number: bagId,
      dimensions: JSON.stringify(
        dimensions.map(({ width, weight, height, length }) => ({
          width,
          weight,
          height,
          length,
        })),
      ),
      process_status: STATUS_PROCESSED,
    });

    goToBagsListAfterSubmit();
  };

  const submitWithNoDimensions = async () => {
    await dispatch('UPDATE', {
      bag_number: bagId,
      process_status: STATUS_PROCESSED,
    });

    goToBagsListAfterSubmit();
  };

  return [
    { result: result ? result[0] : null, error, status },
    submitDimensions,
    submitWithNoDimensions,
  ];
};

export { useDoneProcessingBag };
