import React, { useContext, useState } from 'react';
import { Box, MenuItem, Select, TextField } from '@material-ui/core';
import MuiButton from '../../../../../../UI/MuiButton';
import { convertCSVToDataStoreRecords } from '../../../../../Table/components/CopyPasteUploaders/CopyPasteUploader';
import { useParams } from 'react-router-dom';
import { DealContext } from '../../../GenericDeal/context/dealContext';
import { Alert } from '@material-ui/lab';
import { lcTokenStore } from '../../../GenericDeal/components/CreateDealFromRawData';

const suppliersHandler = {
  RockyMountain: {
    save: async (
      update: <T>(id: string, deal: Partial<T>) => Promise<void>,
      data: string,
      id: string,
    ) => {
      const res = convertCSVToDataStoreRecords(data, [
        'csn',
        'quantity',
        'returnNumber',
      ]);
      await update(id, {
        srReturnNumber: JSON.stringify(res),
      });
      return JSON.stringify(res);
    },
  },
  Autodist: {
    save: async (
      update: <T>(id: string, deal: Partial<T>) => Promise<void>,
      data: string,
      id: string,
    ) => {
      const res = convertCSVToDataStoreRecords(data, [
        'rga',
        'csn',
        'quantity',
      ]);
      await update(id, {
        srReturnNumber: JSON.stringify(res),
      });
      return JSON.stringify(res);
    },
  },
  TuckerRocky: {
    save: async (
      update: <T>(id: string, deal: Partial<T>) => Promise<void>,
      data: string,
      id: string,
    ) => {
      await update(id, {
        srReturnNumber: JSON.stringify([data]),
      });
      return JSON.stringify([data]);
    },
  },
  PartsUnlimited: {
    save: async (
      update: <T>(id: string, deal: Partial<T>) => Promise<void>,
      data: string,
      id: string,
    ) => {
      await update(id, {
        srReturnNumber: JSON.stringify([data]),
      });
      return JSON.stringify([data]);
    },
  },
};

type Supplier = keyof typeof suppliersHandler;

const suppliers: Supplier[] = [
  'RockyMountain',
  'Autodist',
  'TuckerRocky',
  'PartsUnlimited',
];

export const RGASaver = () => {
  const [supplier, setSupplier] = useState<Supplier>(suppliers[0]);
  const [rawData, setRawData] = useState('');
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const dealContext = useContext(DealContext);
  if (!dealContext) return <></>;

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
      }}
    >
      <Alert
        elevation={6}
        variant="filled"
        hidden={!error && !success}
        severity={error ? 'error' : 'success'}
        style={{
          fontSize: '14px',
          fontWeight: 'bold',
          width: 'fit-content',
          minWidth: '150px',
        }}
      >
        {error || success}
      </Alert>
      <Select
        onChange={(e: any) => setSupplier(e.target.value)}
        style={{
          minWidth: 120,
          width: 'fit-content',
        }}
        value={supplier}
      >
        {suppliers.map((spr: Supplier) => (
          <MenuItem
            key={spr}
            value={spr}
            style={{
              minWidth: 120,
            }}
          >
            {spr}
          </MenuItem>
        ))}
      </Select>
      <TextField
        fullWidth
        label="Raw data"
        multiline
        variant="outlined"
        minRows={1}
        maxRows={10}
        size="small"
        value={rawData}
        onChange={(e) => setRawData(e.target.value)}
      />
      <MuiButton
        disabled={loading || !rawData.length}
        style={{
          width: '120px',
        }}
        onClick={async () => {
          setLoading(true);
          setError('');
          setSuccess('');
          try {
            const srReturnNumber = await suppliersHandler[supplier].save(
              dealContext.onDealUpdate,
              rawData,
              id,
            );
            setSuccess('Saved value: ' + srReturnNumber);
            setRawData('');
          } catch (err) {
            const lifecycleToken = lcTokenStore.getSessionToken();
            setError(
              `Error: ${
                (err as Error).message
              }. LifecycleToken: ${lifecycleToken}`,
            );
          }
          setLoading(false);
        }}
      >
        Save
      </MuiButton>
    </Box>
  );
};
