import useHttpDataStore from '../useHttpDatastore';
export interface ReturnedLabel {
  track_number: string;
  client_name: string;
  mp_return_number: string;
  note: string;
  delivered: 'no' | 'yes';
  sr_virtual_name: string;
  status: string;
  location: string;
  mp_return_reason: string;
  supplier: string;
  expected_rid: string;
}
const useReturnsDatastore = () => {
  return useHttpDataStore<ReturnedLabel>('/api/datastore/ReturnsList');
};

export { useReturnsDatastore };
