import React, { useState, FC, useEffect } from 'react';
import { ErrorView, Spinner } from '../../../../UI';
import { noop } from '../../../../utils/common.utils';
import { NODE_RED_INSTANCES } from '../../../../utils/common.constants';
import { Eq, Query, RqlParser } from 'rollun-ts-rql';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Select,
  Theme,
  makeStyles,
} from '@material-ui/core';
import useHttpDataStore from '../../../../hooks/useHttpDatastore';
import { FILTERS_DATASTORE_URL } from '../../util/constants';
import { FilterRow } from '../Table/FiltersPreview';
import MuiButton from '../../../../UI/MuiButton';
import { randomString } from 'rollun-ts-utils';
import { Formik } from 'formik';
import * as yup from 'yup';

const useStyles = makeStyles((theme: Theme) => ({
  savedConfigsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
  },
  savedConfigs: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  configForm: {
    paddingLeft: theme.spacing(1),
  },
  configFormFieldset: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  configFormRow: {
    fontSize: '1.2rem',
  },
  configFormInput: {
    margin: '0px 4px',
  },
  allAtOnceWarning: {
    color: 'error',
  },
  saveConfigButton: {
    marginLeft: theme.spacing(1),
  },
}));

const validationSchema = yup.object().shape({
  chunkSize: yup
    .number()
    .min(0, 'min chunk size = 0')
    .max(5000, 'max chunk size = 5000')
    .required(''),
  timeout: yup
    .number()
    .min(0, 'min timeout = 0ms')
    .max(60000, 'max timeout = 60000ms'),
  delay: yup
    .number()
    .min(0, 'min delay = 0ms')
    .max(60000, 'max delay = 60000ms')
    .required(),
  nodeRedPipelineInputURL: yup
    .string()
    .min(1, 'min endpoint length = 1char')
    .max(100, 'max endpoint length = 100chars')
    .test('/ required', 'endpoint string must starts with /', function (value) {
      if (value && value[0] !== '/') {
        return this.createError();
      }

      return true;
    })
    .required(),
});

export type RequestType = 'single' | 'chunk' | 'all';
export type SourceDataType =
  | 'current_filter'
  | 'current_filter_no_limit'
  | 'full_table';
export type Config = {
  requestType: RequestType;
  sourceDataType: SourceDataType;
  timeout: number;
  chunkSize: number;
  nodeRedInstanceURL: string;
  nodeRedPipelineInputURL: string;
  delay: number;
};

const ConfigForm: FC<{
  disabled: boolean;
  datastoreUrl: string;
  onChangeQuery(query: Query): void;
  handleSubmit?: (c: Config) => void;
}> = ({ disabled, datastoreUrl, onChangeQuery, handleSubmit = noop }) => {
  const classes = useStyles();
  const [configName, setConfigName] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [{ result, status, error }, dispatch] = useHttpDataStore<FilterRow>(
    FILTERS_DATASTORE_URL,
  );

  const handleChangeConfig = (
    setValues: (v: Config, shouldValidate: boolean) => void,
    configId?: string,
  ) => {
    const config = result?.find(({ id }) => id === configId);

    if (config) {
      const { rql, node_red_config } = config;

      setValues(JSON.parse(node_red_config) as Config, false);
      if (rql) {
        onChangeQuery(new RqlParser().parse(rql));
      }
    }
  };

  const handleSaveConfig = (configValues: Config) => {
    dispatch('CREATE', {
      id: randomString(40),
      table_name: datastoreUrl,
      node_red_config: JSON.stringify(configValues),
      name: configName,
    }).then(() =>
      dispatch(
        'QUERY',
        new Query().setQuery(new Eq('table_name', datastoreUrl)),
      ),
    );
    setIsDialogOpen(false);
  };

  useEffect(() => {
    dispatch('QUERY', new Query().setQuery(new Eq('table_name', datastoreUrl)));
  }, [datastoreUrl]);

  return (
    <Formik
      initialValues={{
        nodeRedInstanceURL: NODE_RED_INSTANCES[0].url,
        nodeRedPipelineInputURL: '/ping',
        timeout: 5000,
        delay: 0,
        requestType: 'single',
        sourceDataType: 'current_filter',
        chunkSize: 1000,
      }}
      onSubmit={(values) => {
        handleSubmit && handleSubmit(values as Config);
      }}
      validationSchema={validationSchema}
      validateOnBlur
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        isValid,
        handleSubmit,
        setValues,
      }) => (
        <Box>
          <Box className={classes.savedConfigsContainer}>
            <Typography variant="h6">Node red saved configs:</Typography>
            {status === 'loading' ? (
              <Spinner />
            ) : status === 'error' ? (
              <ErrorView error={error} />
            ) : (
              <Box className={classes.savedConfigs}>
                {!result || result.length === 0 ? (
                  <Typography> no saved configs</Typography>
                ) : (
                  result
                    .filter(({ node_red_config }) => node_red_config !== null)
                    .map(({ id, name }) => (
                      <MuiButton
                        key={id}
                        color="info"
                        onClick={() => handleChangeConfig(setValues, id)}
                      >
                        {name}
                      </MuiButton>
                    ))
                )}
              </Box>
            )}
          </Box>
          <form className={classes.configForm} onSubmit={handleSubmit}>
            <fieldset
              className={classes.configFormFieldset}
              disabled={disabled}
            >
              <Box className={classes.configFormRow}>
                Request to node-red instance will be send throw this URL:
                <Select
                  className={classes.configFormInput}
                  name="nodeRedInstanceURL"
                  value={values.nodeRedInstanceURL}
                  onChange={handleChange}
                  native
                >
                  {NODE_RED_INSTANCES.map(({ type, name, url }) => (
                    <option value={url} key={url}>
                      [{type}] {name} ({url})
                    </option>
                  ))}
                </Select>
                <TextField
                  value={values.nodeRedPipelineInputURL}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={errors.nodeRedPipelineInputURL}
                  error={!!errors.nodeRedPipelineInputURL}
                  name="nodeRedPipelineInputURL"
                  rows={1}
                />
              </Box>
              <Box className={classes.configFormRow}>
                Response timeout:
                <TextField
                  className={classes.configFormInput}
                  value={values.timeout}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={errors.timeout}
                  error={!!errors.timeout}
                  name="timeout"
                />
                miliseconds (0 - without timeout)
              </Box>
              <Box className={classes.configFormRow}>
                Delay between requests:
                <TextField
                  className={classes.configFormInput}
                  value={values.delay}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={errors.delay}
                  error={!!errors.delay}
                  name="delay"
                />
                miliseconds (0 - without delay)
              </Box>
              <Box className={classes.configFormRow}>
                Send data to Node RED from:
                <Select
                  className={classes.configFormInput}
                  name="sourceDataType"
                  value={values.sourceDataType}
                  onChange={handleChange}
                  native
                >
                  <option value="current_filter">Current filter</option>
                  <option value="current_filter_no_limit">
                    Current filter, ignoring limit
                  </option>
                  <option value="full_table">Full table</option>
                </Select>
                <Select
                  name="requestType"
                  value={values.requestType}
                  onChange={handleChange}
                  native
                >
                  <option value="single">Row by row</option>
                  <option value="chunk">In chunks</option>
                  <option value="all">All at once</option>
                </Select>
                {values.requestType === 'all' && (
                  <Typography color={'error'}>
                    If dataset is bigger than 50k rows, sending all rows at once
                    may cause performance issues, or fail, optimal size is 1000
                  </Typography>
                )}
              </Box>
              {values.requestType === 'chunk' && (
                <Box className={classes.configFormRow}>
                  Of size:
                  <TextField
                    className={classes.configFormInput}
                    value={values.chunkSize}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.chunkSize}
                    error={!!errors.chunkSize}
                    name="chunkSize"
                    type="number"
                  />
                  items per chunk
                </Box>
              )}
              <Box>
                <MuiButton
                  color="primary"
                  disabled={!isValid && disabled}
                  type="submit"
                >
                  Start
                </MuiButton>
                <MuiButton
                  className={classes.saveConfigButton}
                  color="success"
                  disabled={!isValid && disabled}
                  onClick={() => setIsDialogOpen(true)}
                >
                  Save Config
                </MuiButton>
              </Box>
            </fieldset>
          </form>

          <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
            <DialogTitle>Type name of the new config</DialogTitle>
            <DialogContent>
              <TextField
                value={configName}
                onChange={(e) => setConfigName(e.target.value)}
                variant="outlined"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <MuiButton
                color="error"
                onClick={() => setIsDialogOpen(false)}
                fullWidth
              >
                Close
              </MuiButton>
              <MuiButton
                color="success"
                onClick={() => handleSaveConfig(values as Config)}
                fullWidth
              >
                Save
              </MuiButton>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </Formik>
  );
};

export default ConfigForm;
