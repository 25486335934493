export const srShipMethod = [
  'pickup-First-Class Package Service',
  'Priority Mail',
  'Priority Mail Legal Flat Rate Envelope',
  'Priority Mail Flat Rate Padded Envelope',
  'Priority Mail Small Flat Rate Box',
  'Priority Mail Medium Flat Rate Box',
  'Priority Mail Large Flat Rate Box',
  'Priority Mail Regional Rate Box A',
  'Priority Mail Regional Rate Box B',
  'Parcel Select Ground',
  'Priority Mail Flat Rate Envelope',
  'Priority Mail Cubic',
  'Priority Mail Large Flat Rate Board Game Box',
  'Priority Mail Medium Flat Rate Box. dropship - Standard',
  '2 DAY',
  'NEXT DAY',
  'FedEx Express Saver',
  'FedEx Ground',
  'FedEx Home Delivery',
  'FedEx International Economy',
  'FedEx International Priority',
  'FedEx Priority Overnight',
  'FedEx Priority Saturday',
  'FedEx SmartPost',
  'FedEx Standard Overnight',
  'FedEx 2Day One Rate Envelope',
  'FedEx 2Day One Rate Box',
  'UPS Ground',
  'UPS Next Day Air',
  'UPS Next Day Air Early',
  'UPS Next Day Air Saver',
  'UPS SurePost',
  'UPS 2nd Day Air',
  'UPS 2nd Day Air A.M.',
  'UPS 3 Day Select',
  'USPS First-Class Mail',
  'USPS Parcel Select',
  'USPS Priority Mail',
];

export const srShipMethodDropship = [
  'Standard',
  '2 DAY',
  '3 DAY',
  'NEXT DAY',
  'FedEx Express Saver',
  'FedEx Ground',
  'FedEx Home Delivery',
  'FedEx Priority Overnight',
  'FedEx SmartPost',
  'FedEx Standard Overnight',
  'FedEx 2Day',
  'FedEx 1Day',
  'UPS Ground Residential',
  'UPS Ground',
  'UPS Next Day Air',
  'UPS Next Day Air Early',
  'UPS Next Day Air Saver',
  'UPS SurePost',
  'UPS 2nd Day Air',
  'UPS 2nd Day Air A.M.',
  'UPS 3 Day Select',
  'USPS Priority Mail',
  'USPS Ground Advantage',
  'OnTrac Ground',
];

export const srShipMethodPickup = [
  'Ground Advantage',
  'Priority Mail',
  'Priority Mail Cubic',
  'Priority Mail Flat Rate Envelope',
  'Priority Mail Flat Rate Padded Envelope',
  'Priority Mail Legal Flat Rate Envelope',
  'Ground',
  'Ground Saver',
  'Home Delivery',
  'Express Saver',
  '2Day One Rate Envelope',
  '2Day One Rate Pak',
  '2Day One Rate Box',
  '2Day One Rate Small Box',
  '2Day One Rate Medium Box',
  '2Day One Rate Large Box',
  '2Day One Rate Extra Large Box',
  'Standard Overnight',
  'First-Class Package Service',
  'Parcel Select Ground',
];
