import HttpDatastore from 'rollun-ts-datastore';
import fetchCategoriesWithCompatiblesBySupplier from './fetchBySuppliersHelpers';

const fetchInventoryDataFromRm = async (rollunIds: string[]) => {
  const RMCatalog = new HttpDatastore(
    '/api/datastore/RockyMountainInventoryCacheDataStore',
  );

  const fieldsToFetch = [
    'rollun_id',
    'brand',
    'mf_id',
    'upc',
    's_quantity',
    'name',
    'vehicles',
  ];

  return fetchCategoriesWithCompatiblesBySupplier(
    rollunIds,
    RMCatalog,
    'RockyMountain',
    fieldsToFetch,
    'name',
  );
};
const fetchInventoryDataFromTr = async (rollunIds: string[]) => {
  const TRCatalog = new HttpDatastore(
    '/api/datastore/TuckerInventoryCacheDataStore',
  );

  const fieldsToFetch = [
    'rollun_id',
    'brand',
    'vendor_part',
    's_quantity',
    'item_description',
    'application',
  ];

  return fetchCategoriesWithCompatiblesBySupplier(
    rollunIds,
    TRCatalog,
    'TuckerRocky',
    fieldsToFetch,
    'item_description',
  );
};

const fetchInventoryDataFromAu = async (rollunIds: string[]) => {
  const AUCatalog = new HttpDatastore(
    '/api/datastore/AutodistInventoryCacheDataStore',
  );

  const fieldsToFetch = [
    'rollun_id',
    's_brand_id',
    'manufacturer_number',
    's_quantity',
    'item_name',
  ];

  return fetchCategoriesWithCompatiblesBySupplier(
    rollunIds,
    AUCatalog,
    'Autodist',
    fieldsToFetch,
    'item_name',
  );
};

const fetchInventoryDataFromPu = async (rollunIds: string[]) => {
  const PUCatalog = new HttpDatastore(
    '/api/datastore/PartsUnlimitedInventoryCacheDataStore',
  );

  const fieldsToFetch = [
    'rollun_id',
    'brand_name',
    'vendor_part_number',
    'upc_code',
    's_quantity',
    's_name',
  ];

  return fetchCategoriesWithCompatiblesBySupplier(
    rollunIds,
    PUCatalog,
    'PartsUnlimited',
    fieldsToFetch,
    's_name',
  );
};

export {
  fetchInventoryDataFromTr,
  fetchInventoryDataFromPu,
  fetchInventoryDataFromRm,
  fetchInventoryDataFromAu,
};
