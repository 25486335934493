import { makeStyles, Theme } from '@material-ui/core';
import { Page } from '../context';

const useStyles = makeStyles((theme: Theme) => ({
  cardContent: {
    padding: theme.spacing(1),
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
  media: {
    height: 140,
    img: {
      maxWidth: '100%',
      maxHeight: '100%',
      display: 'block',
    },
  },
}));

const includesCaseInsensitive = (haystack: string, needle: string) => {
  if (!(haystack || needle)) {
    return false;
  }

  return haystack
    .toLowerCase()
    .replaceAll(/[^a-zA-Z0-9]/g, '')
    .includes(needle.toLowerCase());
};

const getPreviousPageForCurrent = (currentPage: Page) => {
  if (
    currentPage === 'scanLabel' ||
    currentPage === 'notFoundLabel' ||
    currentPage === 'notFoundItem' ||
    currentPage === 'foundLabel' ||
    currentPage === 'possibleItem'
  ) {
    return 'returnLabels';
  }

  if (currentPage === 'scanItem' || currentPage === 'foundItem') {
    return 'foundLabel';
  }

  if (currentPage === 'returnLabels') {
    return 'setLocation';
  }

  return currentPage;
};

const rqlStartsWith = (value: string): string => `${value}%`;

export {
  useStyles,
  includesCaseInsensitive,
  getPreviousPageForCurrent,
  rqlStartsWith,
};
