import React, { Component } from 'react';
import Select from 'react-select';
import HttpDatastore from 'rollun-ts-datastore/dist';
import { FrontConfigRow } from '../../../RootApp/Root.app';
import { ErrorType } from '../../../../utils/common.types';
import { httpErrorHandler } from '../../../../utils/common.utils';
import GridEdit from './grid/Grid.edit';
import { AppConfig } from '../../../AbstractService';
import EditHelpPage from '../../../HelpPage/components/HelpPage.edit';
import ControlCenter from './ControlCenter/ControlCenter.edit';
import DefaultEditor from './DefaultEditor/DefaultEditor';

interface IState {
  servicesList: Array<FrontConfigRow>;
  selectedService: FrontConfigRow | null;
  isLoading: boolean;
  error: ErrorType | null;
}

interface IProps {
  resource?: string;
}

export interface IEditorProps {
  config: AppConfig;
  serviceID: string;
  onConfigSave?: (config: AppConfig) => void;
}

export interface IEditorState {
  config: AppConfig;
  isLoading: boolean;
  isError: boolean;
}

/**
 * UI to edit configs of pages.
 */

class EditService extends Component<IProps, IState> {
  state: IState = {
    servicesList: [],
    selectedService: null,
    isLoading: false,
    error: null,
  };

  servicesConfigDS = new HttpDatastore<FrontConfigRow>(
    '/api/datastore/frontConfigDataStore',
  );

  componentDidMount() {
    this.setState({ isLoading: true, error: null });
    this.fetchServicesConfigs().finally(() =>
      this.setState({ isLoading: false }),
    );
  }

  fetchServicesConfigs = async () => {
    return this.servicesConfigDS
      .query()
      .then((res) => {
        let selectedService = null;
        if (this.props.resource) {
          const foundService = res.find(
            (row) => row.resource === this.props.resource,
          );
          if (foundService) selectedService = foundService;
        }
        this.setState({ servicesList: res, selectedService });
      })
      .catch((err) =>
        httpErrorHandler(err, (code, text) =>
          this.setState({ error: { code, text } }),
        ),
      );
  };

  onServiceSelect = (value: any) => {
    this.setState({ selectedService: value.value });
  };

  renderServiceEditor = (selectedService: FrontConfigRow) => {
    // TODO add more Editors...
    // TODO make single props interface for every editor...
    const componentMap: { [key: string]: any } = {
      grid: GridEdit,
      HelpPage: EditHelpPage,
      ControlCenter: ControlCenter,
    };
    const Editor =
      componentMap[selectedService.config.appType] || DefaultEditor;
    return (
      <Editor config={selectedService.config} serviceID={selectedService.id} />
    );
    // const Editor = lazy(() => import(`./${selectedService.appType}.edit.tsx`));
    // return <Suspense fallback={<div className='m-5'><Spinner/></div>}>
    // 	{<Editor config={selectedService}/>}
    // </Suspense>
  };

  render() {
    const { servicesList, selectedService } = this.state;
    return (
      <div>
        <div>
          {selectedService ? (
            this.renderServiceEditor(selectedService)
          ) : (
            <>
              <h3>Select service to edit:</h3>
              <Select
                value={null}
                defaultValue={{ value: '', label: 'Select service...' } as any}
                className="col-md-6 mb-2"
                options={servicesList.map((service) => ({
                  value: service,
                  label: service.resource,
                }))}
                onChange={this.onServiceSelect}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

export default EditService;
