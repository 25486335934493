import axios from 'axios';

export const USER_NAMER_HEADER = 'X-User-Name';
export const USER_ROLES_HEADER = 'X-User-Roles';

export function axiosWithUserHeaders() {
  axios.interceptors.request.use((config) => {
    const userIdentity = JSON.parse(
      sessionStorage.getItem('CACHED_USER_IDENTITY') || 'null',
    );

    const configWithUserHeaders = {
      ...config,
      headers: {
        ...config.headers,
        ...(userIdentity?.user && {
          [USER_NAMER_HEADER]: userIdentity?.user,
        }),
        ...(userIdentity?.roles && {
          [USER_ROLES_HEADER]: userIdentity?.role,
        }),
      },
    };

    return configWithUserHeaders;
  });
}
