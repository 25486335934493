import React from 'react';
import AbstractTableWithFilter, {
  AbstractQueryEditorAppProps,
  AbstractQueryEditorAppState,
} from './AbstractTableWithFilter';
import Query from 'rollun-ts-rql/dist/Query';
import { Limit, Eq, RqlParser } from 'rollun-ts-rql';
import ReactDataGridAdapter from './ReactDataGridAdapter';
import _ from 'lodash';
import Paginator from '../../../../controls/Paginator/Paginator';
import { LoadingStatusEnum } from '../../../../utils/common.types';
import { randomString } from 'rollun-ts-utils/dist';
import { Box, Card, Theme, WithStyles, withStyles } from '@material-ui/core';
import MuiIconButton from '../../../../UI/MuiIconButton';

interface IProps extends AbstractQueryEditorAppProps, WithStyles {
  appName: string;
  datastoreUrl: string;

  onApplyFilter(query: Query): void;

  onToggleFilterEditor(): void;
}

const classes = (theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0.5),
  },
});

class TableFilterEditor extends AbstractTableWithFilter<
  IProps,
  AbstractQueryEditorAppState
> {
  selectedQueryFilter = '';
  rqlParser = new RqlParser();

  constructor(props: IProps) {
    super(props);
    this.state = {
      data: [],
      dataWithId: [],
      error: {
        text: '',
        code: -1,
      },
      loadingStatus: LoadingStatusEnum.loading,
      query: this.makeDefaultQuery(),
    };
  }

  makeDefaultQuery = () => {
    return new Query({
      limit: new Limit(20, 0),
      query: new Eq('table_name', this.props.appName),
    });
  };

  render() {
    const { datastoreUrl, classes } = this.props;
    const columnsConfig = {
      table_name: { isEditable: false },
      id: { isEditable: false },
      rql: { isEditable: false },
    };
    const isDisabled = this.state.loadingStatus === LoadingStatusEnum.loading;

    return (
      <div id={`gridContainer-${randomString()}`}>
        <Card elevation={1} className={classes.root}>
          <Box display="flex">
            <MuiIconButton
              disabled={isDisabled}
              title="save"
              color="success"
              onClick={() => {
                if (this.selectedQueryFilter === '') return;
                this.props.onApplyFilter(
                  this.rqlParser.parse(this.selectedQueryFilter),
                );
              }}
              iconName="check-square"
            />
            <MuiIconButton
              disabled={isDisabled}
              title="refresh data"
              color="primary"
              onClick={this.refreshTable}
              iconName="sync-alt"
            />
            <MuiIconButton
              disabled={isDisabled}
              title="delete"
              color="error"
              onClick={() => this.itemDelete()}
              iconName="trash-alt"
            />
          </Box>
        </Card>
        <ReactDataGridAdapter
          getSelectedRow={({ rql, id }) => {
            this.selectedQueryFilter = rql;
            this.selectedItemID = id;
          }}
          appName={datastoreUrl || 'Filter'}
          data={this.state.data}
          selectedRowIndex={0}
          error={this.state.error}
          loadingStatus={this.state.loadingStatus}
          columnsConfig={columnsConfig}
          onChangeDataValue={this.updateItem}
        />
        <Paginator
          query={
            new Query({ query: new Eq('table_name', this.props.datastoreUrl) })
          }
          currentCount={this.state.data.length}
          isLocal={this.props.isLocal}
          dataStoreURL={this.props.datastoreUrl}
          pageSizeOptions={[20, 50, 100, 500, 1000, 10000]}
          updateCount={this.state.loadingStatus === LoadingStatusEnum.loaded}
          onSetLimitNode={(limitNode: Limit) => {
            const query = _.cloneDeep(this.state.query);
            query.limitNode = limitNode;
            this.setQuery(query);
          }}
        />
      </div>
    );
  }
}

export default withStyles(classes)(TableFilterEditor);
