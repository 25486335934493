import md5 from 'md5';
import {
  CompatibleVehicle,
  ErrorType,
  Supplier,
} from '../../../utils/common.types';

export type EbayMarketplace = 'Plaisir' | 'Rollun';
export type UniformSupplier =
  | 'Autodist'
  | 'RockyMountain'
  | 'TuckerRocky'
  | 'PartsUnlimited';

export type ExchangeFileFormattoInput = {
  ePID?: string;
  rollunId: string;
  supplier?: UniformSupplier;
};
export type ExchangeFileFormattor = (
  data: ExchangeFileFormattoInput[],
  config: { marketplace: EbayMarketplace; supplier?: Supplier },
  progress?: (status: string) => void,
) => Promise<{
  data: Array<{ [key: string]: string | number | Array<CompatibleVehicle> }>;
  error: ErrorType | null;
}>;

export const getDescription = ({
  warranty = '',
  unspecifiedLength = '',
  mpn = '',
  brand = '',
}) => `
DETAILS:
Warranty: ${warranty}
Unspecified Length ${unspecifiedLength}
Manufacturer Part Number: ${mpn}
Brand: ${brand}

DESCRIPTION:
Sometimes photo may include additional parts, such as boxes, bike parts or other parts that work with this item that is for sale.
The picture is universal just because of big variety of motorcycles and products.
WARNING: Cancer and Reproductive Harm - www.P65Warnings.ca.gov
Please be aware! We charge sales tax according to US Laws in Wyoming (6.0%) and Utah (6.7%).
Please note, ebay warns that estimated delivery times may vary. We try our best to ship product within 1 business day and we use standard shipping (3-6 business days).
In case you are using eCheck system, we are waiting up to 3 days for getting your payment and then we send your package once it is cleared.
Return Address: Do not use sender's address as a return address.
-If order that arrives is damaged, let us know and we will solve this problem.
-Before you send the package for exchange or refund, please contact us for the additional information about our own Return Department.
-If you return package to sender, we cannot guarantee you refund or exchange. Please make sure you contact us and ask for a return address.
`;

export const genVehicleId = ({
  make,
  model,
  year,
}: {
  make: string;
  model: string;
  year: string | number;
}) => {
  return md5(`${make}|${model}|${year}`);
};

export const groupDataBySupplier = (
  data: ExchangeFileFormattoInput[],
): Record<UniformSupplier, string[]> => {
  return data.reduce<Record<UniformSupplier, string[]>>(
    (acc, { supplier, rollunId }) => {
      if (!supplier) {
        return acc;
      }

      return {
        ...acc,
        [supplier]: [...(supplier in acc ? acc[supplier] : []), rollunId],
      };
    },
    {} as Record<UniformSupplier, string[]>,
  );
};

export const stopWords = [
  'downpipes',
  'downpipe',
  'down pipes',
  'down pipe',
  'DOWNPIPES',
  'DOWNPIPE',
  'DOWN PIPE',
  'DOWN PIPES',
  'down-pipes',
  'down-pipe',
  'DOWN-PIPES',
  'DOWN-PIPE',
  'Downpipe',
  'Down Pipe',
  'Down Pipe',
  'Down-Pipe',
  'Downpipes',
  'Down Pipes',
  'Down Pipes',
  'Down-Pipes',
  'turbo downpipes',
  'turbo downpipe',
  'Turbo downpipes',
  'Turbo downpipe',
  'Turbo Downpipe',
  'Turbo Downpipes',
  'turbo-downpipe',
  'TURBO DOWNPIPE',
  'TURBO DOWNPIPES',
  'TURBO-DOWNPIPE',
  'TURBO-DOWNPIPES',
  'oxygen to fuel ration',
  'OXYGEN TO FUEL RATION',
  'oxygen to fuel rationing',
  'OXYGEN TO FUEL RATIONING',
  'oxygen to fuel ratio',
  'OXYGEN TO FUEL RATIO',
  'oxygen ration',
  'oxygen rationing',
  'OXYGEN RATION',
  'OXYGEN RATIONING',
  'effect gas exhaust temperatures',
  'effecting gas exhaust temperatures',
  'effect gas exhaust temperature',
  'effecting gas exhaust temperature',
  'EFFECT GAS EXHAUST TEMPERATURES',
  'EFFECTING GAS EXHAUST TEMPERATURES',
  'EFFECT GAS EXHAUST TEMPERATURE',
  'EFFECTING GAS EXHAUST TEMPERATURE',
  'effect gas exhaust',
  'EFFECT GAS EXHAUST ',
  'EFFECTING GAS EXHAUST',
  'effecting gas exhaust',
  'Power commander',
  'power commander',
  'Power Commander',
  'POWER COMMANDER',
  'Powercommander',
  'Power commanders',
  'power commanders',
  'POWER COMMANDERS',
  'Powercommanders',
  'tfi power box',
  'power box',
  'POWER BOX',
  'TFI power box',
  'TFI POWER BOX',
  'powerbox',
  'power-box',
  'POWER-BOX',
  'TFI Power Box',
  'TFI Power',
  'TFI Box',
  'sensor simulators',
  'Sensor simulators',
  'sensor Simulators',
  'Sensor Simulators',
  'SENSOR SIMULATORS',
  'sensor simulator',
  'Sensor simulator',
  'sensor Simulator',
  'Sensor Simulator',
  'SENSOR SIMULATOR',
  'SENSORSIMULATOR',
  'sensorsimulators',
  'sensorsimulator',
  'SENSORSIMULATOR',
  'SENSORSIMULATORS',
  'FUELPAK',
  'fuelpak',
  'FUEL PAK',
  'FUELPAKS',
  'fuelpaks',
  'Fuelpak',
  'Fuelpaks',
  'Fuel pak',
  'Fuel Pak',
  'FUEL PAKS',
  'Fuel Paks',
  'Fuel Processor',
  'fuel processor',
  'fuel processor',
  'fuel-processor',
  'Fuel processor',
  'Fuel Processors',
  'fuel processors',
  'fuel processors',
  'fuel-processors',
  'Fuel processors',
  'FUEL PROCESSOR ',
  'FUEL PROCESSORS',
  'Fuelpack',
  'Fuelpacks',
  'FUELPACKS',
  'FUELPACK',
  'Fuel pack',
  'Fuel packs',
  'Fuel Pack',
  'Fuel Packs',
  'Power Vision',
  'PowerVision',
  'POWER VISION',
  'POWERVISION',
  'POWER-VISION',
  'power vision',
  'power-vision',
  'powervision',
  'power Vision',
  'Fuel Controller',
  'fuel controller',
  'Fuel controller',
  'Fuelcontroller',
  'fuelcontroller',
  'fuel-controller',
  'Fuel-controller',
  'FUEL CONTROLLER',
  'FUELCONTROLLER',
  'FUEL-CONTROLLER',
  'Fuel Controllers',
  'fuel controllers',
  'Fuel controllers',
  'Fuelcontrollers',
  'fuelcontrollers',
  'fuel-controllers',
  'Fuel-controllers',
  'FUEL CONTROLLERS',
  'FUELCONTROLLERS',
  'FUEL-CONTROLLERS',
  'Leased',
  'lease',
  'to lease',
  'Lease',
  'leased',
  'LEASE',
  'LEASED',
  'leasing',
  'Leasing',
  'LEASING',
  'Carfax reports',
  'Carfax',
  'CARFAX REPORTS',
  'Carfax report',
  'CARFAX REPORT',
  'CARFAX',
  'cArfax',
  'Car-fax',
  'carfax reports',
  'carfax report',
  'Carfax Reports',
  'Carfax Report',
  'airbag covers',
  'AIRBAG COVERS',
  'AIRBAG COVER',
  'airbag cover',
  'Airbag covers',
  'airbag Covers',
  'Airbag Covers',
  'airbag-covers',
  'AIRBAG-COVERS',
  'airbag-cover',
  'AIRBAG-COVER',
  'Recalled airbag',
  'Recalled airbags',
  'RECALLED AIRBAGS',
  'RECALLED AIRBAG',
  'recalled airbags',
  'Recalled Airbags',
  'recalled-airbags',
  'recalled-airbag',
  'lease vehicles',
  'lease vehicle',
  'leased vehicles',
  'Lease Vehicle',
  'Leased Vehicles',
  'Leased Vehicle',
  'Lease Vehicles',
  'LEASED VEHICLES',
  'AutoCheck reports',
  'autocheck reports',
  'autocheck report',
  'auto check report',
  'auto check reports',
  'auto-check reports',
  'auto-check report',
  'AUTOCHECK REPORTS',
  'AUTOCHECK REPORT',
  'AUTO CHECK REPORTS',
  'AUTO CHECK REPORT',
  'AUTO-CHECK REPORT',
  'AUTO-CHECK REPORTS',
  'Oxygen sensor simulators',
  'Oxygen sensor simulator',
  'Oxygen sensors simulators',
  'Oxygen sensors simulator',
  'OXYGEN SENSOR SIMULATOR',
  'OXYGEN SENSORS SIMULATORS',
  'OXYGEN SENSORS SIMULATOR',
  'Oxygen Sensors Simulator',
  'Oxygen Sensors Simulators',
  'Oxygen Sensors Simulators',
  'oxygen sensor simulator',
  'oxygen sensor simulators',
  'Oxygen simulators',
  'Oxygen simulator',
  'oxygen simulator',
  'oxygen simulators',
  'oxygen simulating',
  'Oxygen Simulating',
  'OXYGEN SIMULATING',
  'OXYGEN SIMULATOR',
  'OXYGEN SIMULATORS',
  'oxygensimulator',
  'oxygensimulators',
  'oxygensimulating',
  'OXYGEN SIMULATING',
  'OXYGENSIMULATING',
  'OXYGENSIMULATOR',
  'OXYGENSIMULATORS',
  'Oxygen sensors',
  'Oxygen sensor ',
  'OXYGEN SENSOR ',
  'OXYGEN SENSORS ',
  'Oxygen Sensor',
  'Oxygen Sensors',
  'Oxygen-sensor ',
  'OXYGEN-SENSOR ',
  'Oxygen-sensors',
  'OXYGEN-SENSORS',
  'oxygen sensors',
  'oxygen sensor',
  'oxygen Sensors',
  'oxygen Sensor',
  'Malfunction indicator light eliminators',
  'Malfunction indicator light eliminator',
  'malfunction indicator light eliminators',
  'malfunction indicator light eliminator',
  'MALFUNCTION INDICATOR LIGHT ELIMINATOR',
  'MALFUNCTION INDICATOR LIGHT ELIMINATORS',
  'Malfunction Indicator Light Eliminator',
  'Malfunction Indicator Light Eliminators',
  'malfunction indicators light eliminators',
  'malfunction indicators light eliminator',
  'malfunction indicator light eliminators',
  'malfunctioning indicators light eliminators',
  'malfunctioning indicator light eliminator',
  'malfunctioning indicator light eliminators',
  'malfunctioning indicators light eliminator',
  'bypass',
  'bypassing',
  'bypassed',
  'BYPASS',
  'BYPASSING',
  'BYPASSED',
  'Bypass',
  'Bypassing',
  'Bypassed',
  'defeat emission-control systems',
  'defeat emission-control system',
  'defeating emission-control system',
  'defeating emission-control systems',
  'defeat emissioncontrol system',
  'defeat emissioncontrol systems',
  'defeat emission control system',
  'defeat emission control systems',
  'DEFEAT EMISSION CONTROL SYSTEMS',
  'DEFEAT EMISSION CONTROL SYSTEM',
  'DEFEATING EMISSION CONTROL SYSTEMS',
  'DEFEATING EMISSION CONTROL SYSTEM',
  'defeating emission control systems',
  'defeating emission control system',
  'render inoperative emission-control systems',
  'render inoperative emission-control system',
  'rendering inoperative emission-control systems',
  'rendering inoperative emission-control system',
  'rendering inoperative emissioncontrol systems',
  'rendering inoperative emissioncontrol system',
  'rendering inoperative emission control system',
  'rendering inoperative emission control systems',
  'RENDER INOPERATIVE EMISSION-CONTROL SYSTEM',
  'RENDER INOPERATIVE EMISSION-CONTROL SYSTEMS',
  'RENDERING INOPERATIVE EMISSION-CONTROL SYSTEMS',
  'RENDERING INOPERATIVE EMISSION-CONTROL SYSTEM',
  'RENDERING INOPERATIVE EMISSIONCONTROL SYSTEM',
  'RENDERING INOPERATIVE EMISSIONCONTROL SYSTEMS',
  'RENDERING INOPERATIVE EMISSION-CONTROL SYSTEM',
  'RENDERING INOPERATIVE EMISSION-CONTROL SYSTEMS',
  'inoperative emission-control systems',
  'inoperative emission-control system',
  'inoperative emission-control systems',
  'inoperative emissioncontrol system',
  'inoperative emission control system',
  'inoperative emission control systems',
  'INOPERATIVE EMISSION-CONTROL SYSTEM',
  'INOPERATIVE EMISSION-CONTROL SYSTEMS',
  'INOPERATIVE EMISSION-CONTROL SYSTEMS',
  'INOPERATIVE EMISSION-CONTROL SYSTEM',
  'INOPERATIVE EMISSIONCONTROL SYSTEM',
  'INOPERATIVE EMISSIONCONTROL SYSTEMS',
  'INOPERATIVE EMISSION-CONTROL SYSTEM',
  'INOPERATIVE EMISSION-CONTROL SYSTEMS',
  'render emission-control systems',
  'render emission-control system',
  'render emissioncontrol system',
  'render emissioncontrol systems',
  'rendering emission-control system',
  'rendering emission-control systems',
  'rendering emissioncontrol system',
  'rendering emissioncontrol systems',
  'rendering emission control systems',
  'rendering emission control system',
  'render emission control system',
  'render emission control systems',
  'RENDER EMISSION CONTROL SYSTEM',
  'RENDER EMISSIONCONTROL SYSTEM',
  'RENDERING EMISSION CONTROL SYSTEM',
  'RENDERING EMISSION-CONTROL SYSTEM',
  'RENDER EMISSION-CONTROL SYSTEM',
  'bypass emission-control systems',
  'bypass emission-control system',
  'Bypass Emission-control System',
  'Bypass Emission-control Systems',
  'bypass emissioncontrol system',
  'bypass emissioncontrol systems',
  'bypass emission control systems',
  'bypass emission control system',
  'BYPASS EMISSION-CONTROL SYSTEM',
  'BYPASS EMISSION-CONTROL SYSTEMS',
  'BYPASS EMISSIONCONTROL SYSTEMS',
  'BYPASS EMISSIONCONTROL SYSTEM',
  'BYPASS EMISSION CONTROL SYSTEM',
  'BYPASS EMISSION CONTROL SYSTEMS',
  'tuner device or software that can modify vehicle performance',
  'tuner device',
  'tuner devices',
  'tuning device',
  'tuning devices',
  'TUNING DEVICE',
  'TUNING DEVICES',
  'TUNER DEVICE',
  'tune device',
  'tune devices',
  'TUNE DEVICE',
  'TUNE DEVICES',
  'tunedevice',
  'tunedevices',
  'TUNEDEVICE',
  'TUNEDEVICES',
  'tuner',
  'tunning',
  'TUNER ',
  'TUNERS',
  'tuners',
  'TUNNING',
  'tuning',
  'TUNING',
  'tune',
  'tunes',
  'TUNE',
  'TUNES',
  'to tune',
  'TO TUNE',
  'tuned',
  'TUNED',
  'modify vehicle performance',
  'MODIFY VEHICLE PERFORMANCE',
  'MODIFY PERFORMANCE',
  'modifying vehicle performance',
  'MODIFYING VEHICLE PERFORMANCE',
  'modify performance',
  'modifying performance',
  'MODIFYING PERFORMANCE',
];
