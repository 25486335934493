import React, { FC, useState } from 'react';
import MuiButton, { MuiButtonProps } from './MuiButton';
import { Dialog, DialogActions, DialogTitle } from '@material-ui/core';

interface ConfirmButtonMuiProps extends MuiButtonProps {
  confirmationHeader: string;
  onConfirm: () => void;
  onDecline?: () => void;
  buttonText: string;
}

const ConfirmButtonMui: FC<ConfirmButtonMuiProps> = ({
  confirmationHeader,
  buttonText,
  onConfirm,
  onDecline,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = (isConfirmed: boolean) => {
    isConfirmed ? onConfirm() : onDecline?.();

    setIsOpen(false);
  };

  return (
    <>
      <MuiButton {...props} onClick={() => setIsOpen(true)}>
        {buttonText}
      </MuiButton>
      <Dialog open={isOpen} onClose={() => setIsOpen(true)}>
        <DialogTitle>{confirmationHeader}</DialogTitle>
        <DialogActions>
          <MuiButton color="primary" fullWidth onClick={() => onClick(true)}>
            Yes
          </MuiButton>
          <MuiButton color="error" fullWidth onClick={() => onClick(false)}>
            No
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmButtonMui;
