import React, { CSSProperties, ElementType, FC, ReactNode } from 'react';
import { Button, IconButton } from '@material-ui/core';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { noop } from '../utils/common.utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useCustomColor from '../hooks/useCustomColor';
import { Spinner } from './index';

export type MuiColors =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'error'
  | 'info';

export interface MuiButtonProps {
  onClick?(e: React.MouseEvent): void;

  id?: string;
  loading?: boolean;
  disabled?: boolean;
  children?: string | ReactNode | Array<ReactNode>;
  className?: string;
  color?: MuiColors;
  size?: 'xs' | 'sm' | 'lg';
  block?: boolean;
  title?: string;
  fullWidth?: boolean;
  style?: CSSProperties;
  type?: 'submit' | 'reset' | 'button';
  icon?: {
    name: IconProp;
    size?: SizeProp;
  };
  href?: string;
  variant?: 'contained' | 'outlined' | 'text';
  endIcon?: React.ReactNode;
  startIcon?: React.ReactNode;
  component?: ElementType;
}

const MuiButton: FC<MuiButtonProps> = ({
  onClick = noop,
  id = undefined,
  loading,
  disabled = false,
  children = 'Click me',
  color = undefined,
  fullWidth = false,
  title = '',
  style = {},
  type = 'button',
  icon,
  href = '',
  variant = 'contained',
  className = '',
  startIcon,
  endIcon,
  component = 'button',
}) => {
  const classes = useCustomColor(color);

  if (icon) {
    return (
      <IconButton
        onClick={onClick}
        id={id}
        style={{ ...style }}
        title={title}
        disabled={disabled}
        type={type}
        href={href}
        color="default"
      >
        <FontAwesomeIcon icon={icon.name} size={icon.size} />
      </IconButton>
    );
  }

  return (
    <Button
      className={`${classes.button} ${className}`}
      onClick={onClick}
      id={id}
      variant={variant}
      fullWidth={fullWidth}
      style={{ ...style }}
      title={title}
      disabled={disabled}
      type={type}
      href={href}
      startIcon={startIcon}
      endIcon={endIcon}
      component={component}
    >
      {loading ? <Spinner size={20} /> : children}
    </Button>
  );
};

export default MuiButton;
