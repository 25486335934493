import React, { FC } from 'react';
import { IconButton, IconButtonProps, useTheme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { MuiColors } from './MuiButton';

interface MuiIconButtonProps extends Omit<IconButtonProps, 'color'> {
  width?: number;
  height?: number;
  title?: string;
  iconName: IconProp;
  color?: MuiColors | string;
}

const MuiIconButton: FC<MuiIconButtonProps> = ({
  width = 40,
  height = 40,
  iconName,
  color = 'primary',
  title = '',
  disabled,
  ...props
}) => {
  const theme = useTheme();

  const getIconColor = () => {
    if (disabled) {
      return 'gray';
    }

    return color.startsWith('#')
      ? color
      : theme.palette[(color as unknown) as MuiColors].main;
  };

  return (
    <IconButton
      title={title}
      style={{
        width,
        height,
        color: getIconColor(),
      }}
      disabled={disabled}
      {...props}
    >
      <FontAwesomeIcon
        icon={iconName}
        style={{
          width: width - 20 > 0 ? width - 20 : width,
          height: width - 20 > 0 ? width - 20 : width,
        }}
      />
    </IconButton>
  );
};

export default MuiIconButton;
