import React, { Component } from 'react';
import AddRoute from './AddRoute';
import AddResource from './AddResource';
import AddRule from './AddRule';
import AddServiceConfig from './AddServiceConfig';
import ServiceCreated from './ServiceCreated';
import { Card } from '../../../../UI';
import { Link } from '@material-ui/core';

enum ConstructorSteps {
  ADD_ROUTE = 'add_route',
  ADD_RESOURCE = 'add_resource',
  ADD_RULE = 'add_rule',
  ADD_SERVICE_CONFIG = 'add_service_config',
  SERVICE_CREATED = 'created',
}

interface IState {
  createdResourceId: string;
  createdRouteName: string;
  createdRoutePath: string;
  step: ConstructorSteps;
}

// TODO try to make one abstract component for every step

class CreateService extends Component<unknown, IState> {
  state: IState = {
    createdResourceId: '',
    createdRouteName: '',
    createdRoutePath: '',
    step: ConstructorSteps.ADD_ROUTE,
  };

  _renderCurrentStepComponent = (step: ConstructorSteps) => {
    const {
      createdResourceId,
      createdRouteName,
      createdRoutePath,
    } = this.state;

    switch (step) {
      case ConstructorSteps.ADD_ROUTE:
        return (
          <AddRoute
            onRouteCreate={(createdRouteName, createdRoutePath) =>
              this.setState({
                createdRouteName,
                createdRoutePath,
                step: ConstructorSteps.ADD_RESOURCE,
              })
            }
          />
        );
      case ConstructorSteps.ADD_RESOURCE:
        return (
          <AddResource
            name={createdRouteName}
            onResourceCreate={(id) =>
              this.setState({
                createdResourceId: id,
                step: ConstructorSteps.ADD_RULE,
              })
            }
          />
        );
      case ConstructorSteps.ADD_RULE:
        return (
          <AddRule
            createdResourceId={createdResourceId}
            onRulesCreated={() =>
              this.setState({ step: ConstructorSteps.ADD_SERVICE_CONFIG })
            }
          />
        );
      case ConstructorSteps.ADD_SERVICE_CONFIG:
        return (
          <AddServiceConfig
            createdResourceName={createdRouteName}
            createdResourcePath={createdRoutePath}
            onConfigCreate={() =>
              this.setState({ step: ConstructorSteps.SERVICE_CREATED })
            }
          />
        );
      case ConstructorSteps.SERVICE_CREATED:
        return (
          <ServiceCreated
            resourceId={createdResourceId}
            name={createdRouteName}
            path={createdRoutePath}
            onOk={() =>
              this.setState({
                createdResourceId: '',
                createdRouteName: '',
                createdRoutePath: '',
                step: ConstructorSteps.ADD_ROUTE,
              })
            }
          />
        );
    }
  };

  render() {
    return (
      <Card>
        <Link
          variant="h6"
          href="https://github.com/rollun-com/rollun-net-frontend/tree/master/react-app/src/pages/ServiceConstructor"
        >
          Create service docs
        </Link>
        {this._renderCurrentStepComponent(this.state.step)}
      </Card>
    );
  }
}

export default CreateService;
