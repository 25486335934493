import { AppType } from '../../AbstractService';

export const createDefaultServiceConfig = (name: string, path: string) => ({
  appName: name,
  appPath: path,
  appType: 'grid' as AppType,
  appParams: {
    gridParams: {
      userNote: '',
      datastoreUrl: '/api/datastore/' + name,
    },
  },
});
