import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from '../../../../../UI';

export interface SortFieldEditorProps {
  fieldName: string;
  sortDirection: -1 | 1;

  onSortDirectionChange(fieldName: string, sortDirection: -1 | 1): void;
}

const SortNodeFieldEditor = ({
  fieldName,
  sortDirection,
  onSortDirectionChange,
}: SortFieldEditorProps) => {
  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    const dataTransfer = event.dataTransfer;
    if (dataTransfer) {
      dataTransfer.setData('nodefieldname', fieldName);
      dataTransfer.setData('sortnode', '');
    } else {
      throw new Error('Datatransfer is null or defined');
    }
  };

  return (
    <div
      className={`d-flex flex-row mb-1 sor-row`}
      draggable={true}
      onDragStart={handleDragStart}
    >
      <div className={`py-1 px-2 w-100 border-default header-cell-font mr-1`}>
        {fieldName}
      </div>
      <Button
        className="border-default"
        onClick={() =>
          onSortDirectionChange(fieldName, sortDirection === -1 ? 1 : -1)
        }
      >
        {sortDirection === 1 ? (
          <FontAwesomeIcon icon={'caret-up'} />
        ) : (
          <FontAwesomeIcon icon={'caret-down'} />
        )}
      </Button>
    </div>
  );
};

export default SortNodeFieldEditor;
