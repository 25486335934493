import React, { FC, useContext } from 'react';
import { httpErrorHandlerPromised } from '../../../../../../utils/common.utils';
import MuiButton from '../../../../../../UI/MuiButton';
import { Box } from '@material-ui/core';
import { DealContext } from '../../../GenericDeal/context/dealContext';
import {
  CompletedActionTypeEnum,
  Configuration,
  EventApi,
} from '../../../../../../crm-event-handler-api';
import { randomString } from 'rollun-ts-utils';

interface ScenariosProps {
  props: {
    scenarios?: {
      name: string;
      // TODO: url is not used anymore, need to check if it can be removed
      url: string;
      id: string;
    }[];
  };
}

const eventApi = new EventApi(
  new Configuration(),
  '/api/openapi/CRMEventHandler/v1',
);

export const Scenarios: FC<ScenariosProps> = ({ props }) => {
  const dealContext = useContext(DealContext);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleClick = async (id: string) => {
    setIsLoading(true);

    try {
      const row = dealContext
        ? {
            Id: dealContext.deal.id,
            ...dealContext.rawDeal,
          }
        : {};

      await eventApi.eventHandlerPost({
        entity: {
          type: 'deal',
          // Backend wants name as in a url on UI (https://rollun.net/crm/deals/orders/129513)
          // And yes, they want it in plural...
          // this code splits url by '/' and takes 4th element
          // 4th element is always deal type in plural
          name: location.pathname.split('/')[3].replace('-', '_'),
          row,
          // backend requires always empty object
          changedFields: {},
        },
        action: {
          type: CompletedActionTypeEnum.Button,
          context: {
            // @ts-expect-error TODO: backend wants string as well as a value but schema is broken rn.
            button_id: id,
          },
          // always 0
          delay: 0,
          // send UTC date always
          startedAt: new Date().toISOString().slice(0, 19).replace('T', ' '),
        },
        // doing some crazy stuff, but it works (backwards compatibility)
        lifecycleToken: randomString(40),
      });
    } catch (e) {
      alert((await httpErrorHandlerPromised(e)).text);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      style={{
        display: 'flex',
        gap: 8,
        flexWrap: 'wrap',
      }}
    >
      {props.scenarios?.map(({ name, id }) => (
        <MuiButton
          title={`Scenario name: ${name}. Scenario id: ${id}`}
          disabled={isLoading}
          onClick={() => handleClick(id)}
        >
          {name}
        </MuiButton>
      ))}
    </Box>
  );
};
