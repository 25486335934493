import { ShippingProduct } from './types';

export interface GoodsItemProps {
  item_id: string;
  shipping_label_id: string;
  part_number: string;
  status: 'start' | 'pending' | 'check' | 'ban' | 'not fit';
  itemNumber: number;
  quantity?: number;
  id: number;
  manager_note?: string;
  rollun_id: string;
  problem_report?: { type: string; note: string };
}

export const setListOfGoods = (
  currentLabelGoods: ShippingProduct[],
): GoodsItemProps[] => {
  const listOfGoods: GoodsItemProps[] = [];
  let _counter = 0;

  const getGoodQuantity = (
    labelGoods: ShippingProduct[],
    part_number: string,
  ): number => {
    return labelGoods.reduce(
      (acc, curr) => (curr.part_number === part_number ? acc + 1 : acc),
      0,
    );
  };

  const getItemCount = (
    goods: GoodsItemProps[],
    part_number: string,
  ): number => {
    if (!goods.find((el) => el.part_number === part_number)) {
      return 1;
    } else {
      return (
        goods.reduce(
          (acc, curr) => (curr.part_number === part_number ? acc + 1 : acc),
          0,
        ) + 1
      );
    }
  };
  currentLabelGoods.forEach((good) => {
    const quantity = getGoodQuantity(currentLabelGoods, good.part_number);
    const itemCount = getItemCount(listOfGoods, good.part_number);
    listOfGoods.push({
      shipping_label_id: good.shipping_label_id,
      part_number: good.part_number,
      rollun_id: good.rollun_id,
      itemNumber: itemCount,
      quantity: quantity,
      status: 'start',
      id: _counter,
      item_id: good.id,
      manager_note: good.manager_note,
    });
    _counter += 1;
  });
  return listOfGoods;
};
