import React, { FC, useState } from 'react';
import { LeftMenuType } from './LeftMenu';
import { Box, makeStyles, Typography, useTheme } from '@material-ui/core';
import LinkComponent from '../LinkComponent';
import ListItem from '@material-ui/core/ListItem';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { getBackgroundColor } from '../../../themeProvider';

export const useStyles = makeStyles(() => ({
  listItemLink: {
    paddingLeft: ({ leftPadding }: any) => leftPadding + 'px',
    backgroundColor: ({ leftPadding, itemUniqKey }) =>
      itemUniqKey ? getBackgroundColor(leftPadding) : '',
  },
  listItemChapter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  expandIcon: {
    width: '20px',
    height: '20px',
    color: 'white',
  },
  listItemWrap: {
    paddingLeft: ({ leftPadding }: any) => leftPadding + 'px',
    backgroundColor: ({ leftPadding, itemUniqKey }) =>
      itemUniqKey ? getBackgroundColor(leftPadding) : '',
  },
}));

interface LeftMenuChildrens {
  menuItems: LeftMenuType[];
  leftPadding: number;
}

// if the menu item doesn't have any child, this method simply returns a clickable menu item that redirects to any location and if there is no child this method uses recursion to go until the last level of children and then returns the item by the first condition.
const LeftMenuChildrens: FC<LeftMenuChildrens> = ({
  menuItems,
  leftPadding,
}) => {
  const [item, setItem] = useState<{ [key: string]: boolean }>({});

  // sets the current state of a menu item i.e. whether it is in expanded or collapsed state
  const handleClick = (item: string) => {
    setItem((prevItem) => ({
      ...prevItem,
      [item]: !prevItem[item],
    }));
  };

  if (!Array.isArray(menuItems)) {
    return null;
  }

  return (
    <>
      {menuItems.map((subOption: LeftMenuType, index: number) => {
        const uniqKey = `${subOption.label}_${index}`;
        const itemUniqKey = item[uniqKey];

        if (!subOption.content) {
          return (
            <MenuLink
              key={uniqKey}
              subOption={subOption}
              leftPadding={leftPadding}
              itemUniqKey={itemUniqKey}
            />
          );
        }
        return (
          <LeftMenuChapter
            key={uniqKey}
            uniqKey={uniqKey}
            handleClick={handleClick}
            subOption={subOption}
            leftPadding={leftPadding}
            itemUniqKey={itemUniqKey}
          />
        );
      })}
    </>
  );
};

interface MenuLinkProps {
  key: string;
  subOption: LeftMenuType;
  leftPadding: number | string;
  itemUniqKey: boolean;
}

const MenuLink: FC<MenuLinkProps> = ({
  key,
  subOption,
  leftPadding,
  itemUniqKey,
}) => {
  const classes = useStyles({ leftPadding, itemUniqKey });

  return (
    <Box key={key} className={classes.listItemLink}>
      <LinkComponent link={subOption.to || ''}>
        <ListItem button>
          <Typography variant="body1">{subOption.label}</Typography>
        </ListItem>
      </LinkComponent>
    </Box>
  );
};

interface LeftMenuChapterProps {
  key: string;
  uniqKey: string;
  handleClick: (item: string) => void;
  subOption: LeftMenuType;
  leftPadding: number | string;
  itemUniqKey: boolean;
}

const LeftMenuChapter: FC<LeftMenuChapterProps> = ({
  key,
  uniqKey,
  handleClick,
  subOption,
  leftPadding,
  itemUniqKey,
}) => {
  const classes = useStyles({ leftPadding, itemUniqKey });
  const theme = useTheme();

  return (
    <Box key={key} className={classes.listItemWrap}>
      <ListItem
        button
        onClick={() => handleClick(uniqKey)}
        //using inline styles to prevent color change on hover
        style={{
          backgroundColor: itemUniqKey ? theme.palette.secondary.main : '',
        }}
      >
        <Box className={classes.listItemChapter}>
          <Typography variant="body1">{subOption.label}</Typography>
          {itemUniqKey ? (
            <ExpandLess className={classes.expandIcon} />
          ) : (
            <ExpandMore className={classes.expandIcon} />
          )}
        </Box>
      </ListItem>
      <Collapse in={itemUniqKey} timeout="auto" unmountOnExit>
        <LeftMenuChildrens
          menuItems={subOption.content as LeftMenuType[]}
          leftPadding={15}
        />
      </Collapse>
    </Box>
  );
};

export default LeftMenuChildrens;
