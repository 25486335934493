import React, { useState } from 'react';
import CreateRouteForm from './CreateRouteForm';
import SetRules from './SetRules';
import { useHistory } from 'react-router-dom';
import useQueryParams from '../../../../hooks/useQueryParams';
import CreateConfig from './CreateConfig';
import PutInMenu from './PutInMenu';

type CreateServiceWithResourceSteps =
  | 'create-route'
  | 'set-rules'
  | 'put-in-menu'
  | 'add-config';

type ServiceConfig = {
  name: string;
  path: string;
  datastore: {
    routeId: string;
    resourceId: string;
  };
};

interface CreateServiceWithResourceContext {
  step: CreateServiceWithResourceSteps;
  routeId: string;
  resetService: () => void;
  serviceConfig: ServiceConfig | null;
  setRouteId: (routeId: string, step: CreateServiceWithResourceSteps) => void;
  setServiceConfig: (
    config: ServiceConfig,
    step: CreateServiceWithResourceSteps,
  ) => void;
  resourceId: string;
  setResourceId: (
    resourceId: string,
    step: CreateServiceWithResourceSteps,
  ) => void;
}

type SetQuery = {
  curStep: CreateServiceWithResourceSteps;
  curResourceId: string;
  curRouteId: string;
  curServiceConfig: ServiceConfig;
};

export const CreateServiceWithResourceContext = React.createContext<CreateServiceWithResourceContext | null>(
  null,
);

const CreateServiceWithResource = () => {
  const params = useQueryParams();
  const [step, setStep] = useState<CreateServiceWithResourceSteps>(
    (params && (params.step as CreateServiceWithResourceSteps)) ||
      'create-route',
  );
  const [routeId, setRouteId] = useState(params?.routeId || '');
  const [serviceConfig, setServiceConfig] = useState<ServiceConfig>(
    {
      name: params?.name,
      path: params?.path,
      datastore: {
        routeId: params?.dsRouteId,
        resourceId: params?.dsResourceId,
      },
    } || null,
  );
  const [resourceId, setResourceId] = useState(params.resourceId || '');
  const history = useHistory();

  const steps: { [key in CreateServiceWithResourceSteps]: JSX.Element } = {
    'put-in-menu': <PutInMenu />,
    'add-config': <CreateConfig />,
    'set-rules': <SetRules />,
    'create-route': <CreateRouteForm />,
  };

  const setQuery = ({
    curStep,
    curResourceId,
    curRouteId,
    curServiceConfig,
  }: Partial<SetQuery>) => {
    history.push(
      `?action=add_service_with_resource&step=${curStep}` +
        `&resourceId=${curResourceId}` +
        `&routeId=${curRouteId}` +
        `&name=${curServiceConfig?.name}` +
        `&path=${curServiceConfig?.path}` +
        `&dsRouteId=${curServiceConfig?.datastore.routeId}` +
        `&dsResourceId=${curServiceConfig?.datastore.resourceId}`,
    );
  };

  return (
    <CreateServiceWithResourceContext.Provider
      value={{
        step,
        routeId,
        serviceConfig,
        resetService: () => {
          history.push('?');
          setServiceConfig({
            datastore: {
              resourceId: '',
              routeId: '',
            },
            name: '',
            path: '',
          });
          setResourceId('');
          setRouteId('');
          setStep('create-route');
        },
        setServiceConfig: (config, step) => {
          setQuery({
            curServiceConfig: config,
            curRouteId: routeId,
            curResourceId: resourceId,
            curStep: step,
          });
          setServiceConfig(config);
          setStep(step);
        },
        setResourceId: (resourceId, step) => {
          setQuery({
            curResourceId: resourceId,
            curRouteId: routeId,
            curStep: step,
            curServiceConfig: serviceConfig,
          });
          setResourceId(resourceId);
          setStep(step);
        },
        resourceId,
        setRouteId: (routeId, step) => {
          setQuery({
            curRouteId: routeId,
            curStep: step,
            curServiceConfig: serviceConfig,
            curResourceId: resourceId,
          });
          setRouteId(routeId);
          setStep(step);
        },
      }}
    >
      {steps[step]}
    </CreateServiceWithResourceContext.Provider>
  );
};

export default CreateServiceWithResource;
