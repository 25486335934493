import React, { useEffect, useRef, useState } from 'react';
import './Scanner.css';
import { BrowserMultiFormatReader } from '@zxing/browser';
import { Result, BarcodeFormat } from '@zxing/library';
import { formatBarcode } from '../../ReturnApp/utils';

// https://github.com/samdutton/simpl/blob/gh-pages/getusermedia/sources/js/main.js

const w = (window as unknown) as Window & { stream: any; deviceInfos: any };

const codeReader = new BrowserMultiFormatReader(new Map(), {
  delayBetweenScanAttempts: 100,
});

// TODO: use barcode scanner component here as well
export const ScannerDemo: React.FC = () => {
  const ref = useRef<HTMLVideoElement>(null); // ref => { current: null }
  const [cameras, setCameras] = useState([]);
  const [results, setResults] = useState<
    {
      text: string;
      formatted: string;
      carrier: string;
      format: string;
      idx: number;
    }[]
  >([]);
  useEffect(() => {
    const promise = getStream()
      .then(getDevices)
      .then(gotDevices)
      .then(decodeFromStream);

    return () => {
      promise.then((c) => c.stop());
    };
  }, []);

  async function decodeFromStream() {
    console.log('decoding from stream');
    const control = await codeReader.decodeFromVideoElement(
      ref.current as HTMLVideoElement,
      onResult,
    );

    return control;
  }

  function onResult(result: Result | undefined) {
    console.log('result from decode', result);

    if (!result) {
      return;
    }
    const text = result.getText();
    const format = BarcodeFormat[result.getBarcodeFormat()];

    const { code: formatted, carrier } = formatBarcode(text, format);

    setResults((results) => {
      const { idx } = results[results.length - 1] || { idx: 0 };

      if (results.length >= 5) {
        return results
          .slice(1)
          .concat({ text, formatted, carrier, format, idx: idx + 1 });
      }
      return results.concat({ text, formatted, carrier, format, idx: idx + 1 });
    });
  }

  function getDevices() {
    console.log('getting devices');
    // AFAICT in Safari this only gets default devices until gUM is called :/
    return navigator.mediaDevices.enumerateDevices();
  }

  function gotDevices(deviceInfos: any) {
    console.log('got devices');
    setCameras(
      deviceInfos.filter((deviceInfo: any) => deviceInfo.kind === 'videoinput'),
    );
  }

  function getStream(cameraId?: string) {
    console.log('getting stream');
    if (w.stream) {
      w.stream.getTracks().forEach((track: any) => {
        track.stop();
      });
    }
    const constraints = {
      video: {
        deviceId: cameraId ? { exact: cameraId } : undefined,
        width: {
          ideal: 1920,
          max: 1920,
        },
        height: {
          ideal: 1080,
          max: 1080,
        },
      },
    };
    return navigator.mediaDevices
      .getUserMedia(constraints)
      .then(gotStream)
      .catch(handleError);
  }

  function gotStream(stream: MediaStream) {
    console.log('got stream');

    w.stream = stream; // make stream available to console

    ref.current && (ref.current.srcObject = stream);
  }

  function handleError(error: any) {
    console.error('Error: ', error);
  }

  console.log(results);
  return (
    <div>
      <div className="select">
        <label htmlFor="videoSource">Video source: </label>
        <select
          id="videoSource"
          onChange={(e) => {
            getStream(e.target.value);
          }}
        >
          {cameras.map((camera: any) => (
            <option value={camera.deviceId}>
              {camera.label} ({camera.deviceId})
            </option>
          ))}
        </select>
      </div>
      <div className="position-relative">
        <div
          className="position-absolute"
          style={{ zIndex: 9999, top: 10, left: 10 }}
        >
          {results.map(({ text, format, idx, formatted, carrier }) => (
            <>
              <pre className="m-0 color-red" key={idx}>
                #{idx}: format={format} carrier={carrier}
              </pre>
              <pre className="m-0 color-red">
                original--code={text} length={text.length}
              </pre>
              <pre className="m-0 color-red">
                formatted-code={formatted} length={formatted.length}
              </pre>
            </>
          ))}
        </div>
        <video ref={ref} autoPlay muted playsInline></video>
      </div>
    </div>
  );
};
