import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { labelProvider } from '../../../../../constants/label-provider';

export type OneSupplierResult =
  | null
  | {
      success: false;
      name: string;
      error: string;
    }
  | {
      success: true;
      name: string;
      dealId: string;
      type: 'DROPSHIP' | 'PICKUP';
    };

interface OneSupplierDialogProps {
  dialogOpen: boolean;
  handleDialogClose(): void;
  labelProviderOption: string;
  handleLabelProviderOptionChange(
    event: React.ChangeEvent<{ value: unknown }>,
  ): void;
  payload: any;
  handleDialogSubmit(): void;
  isLoading: boolean;
  result: OneSupplierResult;
}

export const OneSupplierDialog = (props: OneSupplierDialogProps) => {
  const {
    dialogOpen,
    handleDialogClose,
    labelProviderOption,
    handleLabelProviderOptionChange,
    payload,
    handleDialogSubmit,
    isLoading,
    result,
  } = props;

  console.log('props', props);

  return (
    <Dialog open={dialogOpen} onClose={handleDialogClose}>
      {!result ? (
        <>
          <DialogTitle>Select Label Provider Option</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              {payload?.type === 'PICKUP' && (
                <>
                  <InputLabel>Label Provider Option</InputLabel>
                  <Select
                    value={labelProviderOption}
                    onChange={handleLabelProviderOptionChange}
                  >
                    {labelProvider.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </FormControl>
            <pre>{JSON.stringify(payload, null, 2)}</pre>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDialogClose}
              disabled={isLoading}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={handleDialogSubmit}
              disabled={
                isLoading ||
                (payload?.type === 'PICKUP' && !labelProviderOption)
              }
              color="primary"
            >
              Submit
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>Deal creation result:</DialogTitle>
          <DialogContent>
            {result !== null && result.success ? (
              <Typography>
                <Link
                  to={`/crm/deals/${result.type.toLowerCase()}s/${
                    result.dealId
                  }`}
                >
                  {result.name}
                </Link>
              </Typography>
            ) : (
              <Typography>{`Creating ${result.name} failed with error - ${result.error}`}</Typography>
            )}
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};
