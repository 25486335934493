import React, { ReactNode, useState } from 'react';
import { isHTML, utf8Decode } from '../utils/common.utils';
import { ErrorType } from '../utils/common.types';
import Button from './Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zIndexes } from '../themeProvider';
import { Box, makeStyles } from '@material-ui/core';

interface ErrorProps {
  header?: string;
  children?: React.ReactNode;
  error?: ErrorType | null;
}

export const BEFORE_LOGIN_PATH_KEY = 'BEFORE_LOGIN_PATH_KEY';

const errorMessages: Record<number, string | ReactNode> = {
  401: (
    <div>
      You are not authorized to see this page. <a href="/login/">Login</a>, or
      switch account
    </div>
  ),
  403: (
    <div>
      You are not authorized to see this page.{' '}
      <a
        href="/login/"
        onClick={() => {
          localStorage.setItem(BEFORE_LOGIN_PATH_KEY, window.location.pathname);
          sessionStorage.setItem(
            BEFORE_LOGIN_PATH_KEY,
            window.location.pathname,
          );
        }}
      >
        Login
      </a>
      , or switch account
    </div>
  ),
  404: 'Not found. This resource does not exists, or has been moved.',
  // 504: 'Gateway timeout, try again, in few minutes'
};

const errorExplanations: Record<string, string> = {
  'HY000 - 1525':
    'Oops! You trying to search for empty rows in column with DATETIME data! You can easily avoid this error :) just REMOVE null() filter with column that contains DATETIME',
};

const useStyles = makeStyles(() => ({
  errorContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '8px 4px',
  },
  errorExplanation: {
    color: '#6477d8',
    fontSize: '2rem',
    textAlign: 'center',
    marginBottom: '30px',
  },
  errorHeader: {
    color: '#E74C3C',
    fontSize: '3rem',
    textAlign: 'center',
    margin: 0,
  },
  childrenContainer: {
    color: 'lightgray',
    marginTop: '12px',
  },
  errorContent: {
    fontSize: '1.5rem',
    color: 'lightgray',
    maxWidth: 400,
    overflowX: 'scroll',
    textAlign: 'center',
    margin: '0 12px',
  },
  iframeWrap: {
    position: 'relative',
  },
  iframeWrapFullScreen: {
    position: 'absolute',
    width: window.innerWidth,
    height: window.innerHeight,
    top: 0,
    left: 0,
    zIndex: zIndexes.low,
    background: '#d3d3d3',
  },
}));

/**
 * Simple component render error.
 * @param header
 * @param children
 * @param error
 */

const ErrorView = ({ header, children, error }: ErrorProps) => {
  const classes = useStyles();
  const errorHeader =
    header || 'Error ' + (error && error.code > 0 ? error.code : '');

  const getErrorExplanation = () => {
    if (!error) return null;

    const errorIdentifiers = Object.keys(errorExplanations);
    const occuredErrorCode = errorIdentifiers.find((errorCode) =>
      error.text.includes(errorCode),
    );

    if (!occuredErrorCode) {
      return null;
    }

    return errorExplanations[occuredErrorCode];
  };

  return (
    <Box className={classes.errorContainer}>
      <Box className={classes.errorExplanation}>{getErrorExplanation()}</Box>
      <Box className={classes.errorHeader}>{errorHeader}</Box>
      <ErrorContent error={error} />
      {children && <Box className={classes.childrenContainer}>{children}</Box>}
    </Box>
  );
};

const ErrorContent = ({ error }: { error: ErrorType | null | undefined }) => {
  const [isIframeFullScreen, setIframeFullScreen] = useState(false);
  const classes = useStyles();
  if (!error) return null;

  let children: string | ReactNode;
  if (errorMessages[error.code]) {
    children = errorMessages[error.code];
  } else if (isHTML(error.text)) {
    const iframeWrapClasses = `${classes.iframeWrap} ${
      isIframeFullScreen ? classes.iframeWrapFullScreen : ''
    }`;

    children = (
      <Box className={iframeWrapClasses}>
        <Button
          className="position-absolute"
          onClick={() => setIframeFullScreen((flag) => !flag)}
          style={{ top: 5, right: 5 }}
        >
          <FontAwesomeIcon
            icon={
              isIframeFullScreen ? 'compress-arrows-alt' : 'expand-arrows-alt'
            }
          />
        </Button>
        <iframe
          src={`data:text/html;charset=utf-8,${encodeURI(error.text)}`}
          title="error_view"
          width={isIframeFullScreen ? window.innerWidth : 400}
          height={isIframeFullScreen ? window.innerHeight : 300}
          frameBorder={1}
        />
      </Box>
    );
  } else {
    children = utf8Decode(error.text);
  }

  return <Box className={classes.errorContent}>{children}</Box>;
};

export default ErrorView;
