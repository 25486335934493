import React from 'react';
import AbstractService, {
  AbstractServiceProps,
} from '../../../AbstractService';
import AppContainer from '../../../../layout/AppContainer/AppContainer';
import { ErrorView, Spinner } from '../../../../UI';
import { DealType } from '../GenericDeal';
import FbaDeal from './components/FbaDeal';
import { prepTsentrPts } from '../constants/prep-tsentr-pts';
import { srName } from '../constants/sr-name';
import { fbaType } from '../constants/fba-type';

class CRMFbaDeal extends AbstractService<AbstractServiceProps> {
  render() {
    const { appConfig, error } = this.state;

    if (error) {
      return (
        <AppContainer services={{}}>
          <div className="w-100 h-100 d-flex flex-column justify-content-center">
            <ErrorView error={error}>
              <div className="d-flex justify-content-center p-3">
                <div
                  className="btn btn-primary cursor-pointer"
                  onClick={this.reloadHome}
                >
                  Home
                </div>
              </div>
            </ErrorView>
          </div>
        </AppContainer>
      );
    }

    if (appConfig === null) {
      return <Spinner />;
    }

    const { user, role } = this.props.userIdentity;
    return (
      <AppContainer
        currentResourceName={this.props.resourceName}
        services={this.props.availableServices}
        user={user}
        role={role}
      >
        <FbaDeal
          fieldsConfig={{
            General: {
              fields: [
                {
                  displayName: 'Data Zakaza',
                  type: 'input',
                  apiFieldPath: 'dataZakaza',
                },
                {
                  displayName: 'Active Workflow',
                  type: 'input',
                  apiFieldPath: 'activeWorkflow',
                },
                {
                  displayName: 'Shipment Id',
                  type: 'input',
                  apiFieldPath: 'shipmentId',
                },
                {
                  displayName: 'TrekNomer',
                  type: 'input',
                  apiFieldPath: 'trekNomer',
                },
                {
                  displayName: 'Edd',
                  type: 'input',
                  apiFieldPath: 'edd',
                },
                {
                  displayName: 'PrepTsentrPts',
                  type: 'input',
                  apiFieldPath: 'prepTsentrPts',
                },
                {
                  displayName: 'PrepCenterWorkOrderId',
                  type: 'select',
                  options: prepTsentrPts,
                  apiFieldPath: 'prepCenterWorkOrderId',
                },
                {
                  displayName: 'DataPostupleniyaVPrepTsentr',
                  type: 'input',
                  apiFieldPath: 'dataPostupleniyaVPrepTsentr',
                },
                {
                  displayName: 'PrimechaniePts',
                  type: 'input',
                  apiFieldPath: 'primechaniePts',
                },
                {
                  displayName: 'NomerSdelkiVDelovode',
                  type: 'input',
                  apiFieldPath: 'nomerSdelkiVDelovode',
                },
                {
                  displayName: 'DataOtgruzki',
                  type: 'input',
                  apiFieldPath: 'dataOtgruzki',
                },
                {
                  displayName: 'SrOrderNumber',
                  type: 'input',
                  apiFieldPath: 'srOrderNumber',
                },
                {
                  displayName: 'SrName',
                  type: 'select',
                  options: srName,
                  apiFieldPath: 'srName',
                },
                {
                  displayName: 'Type',
                  type: 'select',
                  options: fbaType,
                  apiFieldPath: 'type',
                },
                {
                  displayName: 'SrPaymentCard',
                  type: 'input',
                  apiFieldPath: 'srPaymentCard',
                },
                {
                  displayName: 'MpUaId',
                  type: 'input',
                  apiFieldPath: 'mpUaId',
                },
              ],
            },
          }}
          type={DealType.Fba}
          buttons={[]}
          widgets={[]}
        />
      </AppContainer>
    );
  }
}

export default CRMFbaDeal;
