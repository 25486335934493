import Sort, { SortOptions } from 'rollun-ts-rql/dist/nodes/Sort';
import SortNodeFieldEditor from '../SortNodeFieldEditor/SortNodeFieldEditor';
import React, { FC, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../../../../UI';
import { Box } from '@material-ui/core';
import _ from 'lodash';

export interface SortNodeEditorProps {
  node: Sort | undefined;
  onRemove(): void;
  onSortNodeChange(sortOptions: SortOptions): void;
}

const SortNodeEditor: FC<SortNodeEditorProps> = (props) => {
  const { node, onRemove, onSortNodeChange } = props;
  const [state, setState] = useState({
    awaitingDrop: false,
    validDropTarget: false,
  });

  const borderClassName = state.awaitingDrop
    ? state.validDropTarget
      ? 'border-valid'
      : 'border-invalid'
    : 'border-default';

  const renderEmptyEditor = () => (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
      <Button className="mb-1" size="lg" color="light">
        Drag to add sort node
      </Button>
    </Box>
  );

  const renderNotEmptyEditor = () => {
    if (!node) return null;
    const onSortDirectionChange = (
      fieldName: string,
      sortDirection: -1 | 1,
    ) => {
      changeSortOption(fieldName, sortDirection);
    };
    return (
      <>
        <div className="default-card-header">
          <div>
            <span>Sort fields</span>
          </div>
          <div>
            <Button size="sm" color="danger" onClick={() => onRemove()}>
              <FontAwesomeIcon icon={'times'} />
            </Button>
          </div>
        </div>
        <div className="d-flex flex-column active-sort-nodes card-text">
          {Object.entries(node.sortOptions).map((entry) => {
            const [fieldName, sortDirection] = entry;
            return (
              <SortNodeFieldEditor
                key={`${fieldName}`}
                fieldName={fieldName}
                sortDirection={sortDirection}
                onSortDirectionChange={onSortDirectionChange}
              />
            );
          })}
        </div>
      </>
    );
  };

  const disableDropTarget = () => {
    setState({ awaitingDrop: false, validDropTarget: false });
  };

  const checkDropPosibility = () => state.awaitingDrop && state.validDropTarget;

  const changeSortOption = (fieldName: string, sortDirection: -1 | 1) => {
    if (!node) return;
    const newSortOptions = {
      ...node.sortOptions,
      [fieldName]: sortDirection,
    };
    onSortNodeChange(newSortOptions);
  };

  const setDropPossibilityState = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const dataTransfer = event.dataTransfer;
    if (!dataTransfer) {
      throw new Error('Datatransfer is null or undefined');
    }

    const hasFieldName = dataTransfer.types.indexOf('nodefieldname') !== -1;
    const isSelectNode = dataTransfer.types.indexOf('selectnode') !== -1;

    if (hasFieldName && !isSelectNode) {
      setState({ awaitingDrop: true, validDropTarget: true });
      return;
    }

    setState({ awaitingDrop: true, validDropTarget: false });
  };

  const addNewSortOption = (event: React.DragEvent<HTMLDivElement>) => {
    const dataTransfer = event.dataTransfer;

    if (!checkDropPosibility()) {
      disableDropTarget();
      return;
    }

    if (!dataTransfer) {
      throw new Error('Datatransfer is null or undefined');
    }

    const fieldName = dataTransfer.getData('nodefieldname');
    const newSortOptions: SortOptions = node
      ? { ...node.sortOptions, [fieldName]: 1 }
      : { [fieldName]: 1 };

    onSortNodeChange(newSortOptions);
    disableDropTarget();
  };

  return (
    <div
      className={`d-flex flex-column card mx-1 h-100 border-transparent ${borderClassName}`}
    >
      <div
        className={`card-body p-3 ${
          !node || _.isEmpty(node.sortOptions)
            ? 'd-flex justify-content-center flex-column'
            : ''
        }`}
        onDragOver={setDropPossibilityState}
        onDrop={addNewSortOption}
        onDragLeave={disableDropTarget}
      >
        {node && _.size(node.sortOptions)
          ? renderNotEmptyEditor()
          : renderEmptyEditor()}
      </div>
    </div>
  );
};

export default React.memo(SortNodeEditor);
