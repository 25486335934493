import { Dimension } from './fetchDimensions';

export function fitItemInProperSize(
  item: Dimension,
): Dimension & { rightSize: boolean } {
  let properItem = item;

  if (
    +(properItem.length || -1) === -1 &&
    +(properItem.width || -1) === -1 &&
    +(properItem.height || -1) === -1 &&
    +(properItem.weight || -1) === -1
  ) {
    return {
      ...properItem,
      rightSize: true,
    };
  }

  if (+(properItem.length || 0) < 6) {
    properItem = {
      ...properItem,
      length: '6.000',
    };
  }

  if (+(properItem.width || 0) < 3) {
    properItem = {
      ...properItem,
      width: '3.000',
    };
  }

  if (
    +(properItem.length || 0) >= 18 ||
    +(properItem.width || 0) >= 18 ||
    +(properItem.height || 0) >= 18
  ) {
    return {
      ...properItem,
      rightSize: false,
    };
  }

  return {
    ...properItem,
    rightSize: true,
  };
}
