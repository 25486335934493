import React, { PureComponent, ReactNode } from 'react';
import GoodsItem from './GoodsItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ShippingLabel, ShippingProduct } from '../utils/types';
import { setListOfGoods, GoodsItemProps } from '../utils/LabelGoodsToList';
import Dialog from '../../../UI/Dialog';
import OrderProblemReport from './OrderProblemReport';
import { Query, Eq } from 'rollun-ts-rql';

import HttpDatastore from 'rollun-ts-datastore';
import {
  GOOD_PACKED,
  GOOD_STATUS_IN_PROGRESS,
  GOOD_STATUS_NOT_PACKED,
  GOOD_STATUS_WRONG_PACKAGE,
  LABEL_EMPTY,
  LABEL_PROBLEM_WITH_PRODUCT,
  LABEL_PACKED,
  ORDER_STATUS_IN_PROGRESS,
} from '../constants';
import LabelInfo from './LabelInfo';
import WrongPackage from './WrongPackage';
import { Button, Spinner, ErrorView } from '../../../UI';
import { ErrorType, Senders } from '../../../utils/common.types';
import { httpErrorHandler } from '../../../utils/common.utils';
import updatePickUpPackageStatus from '../utils/UpdatePickUpPackageStatus';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import axios from 'axios';
import { randomString } from 'rollun-ts-utils';

enum CurrentLabelGoodsAction {
  PENDING,
  ERROR,
  GOODS,
  LABEL_EMPTY,
  WRONG_PACKAGE,
}

interface IState {
  currentGoodIndex: number;
  action: CurrentLabelGoodsAction;
  error: ErrorType;
  modalOpen: boolean;
}

interface IProps {
  onLabelProcessed(label?: string, status?: string): void;

  senderName: Senders;
  currentLabel: ShippingLabel;
  order: { id: string; supplier: string };
}

// Check each good in one Label

export default class CurrentLabelGoods extends PureComponent<IProps, IState> {
  currentLabelGoods: Array<GoodsItemProps> = [];

  state = {
    currentGoodIndex: 0,
    action: CurrentLabelGoodsAction.PENDING,
    error: { code: -1, text: '' },
    modalOpen: false,
  };

  problemTypes = [
    { name: 'Other issue...', required: true },
    { name: "Package doesn't fit", required: true },
    { name: 'Defective package', required: true },
    { name: 'Package is not in stock', required: true },
    { name: 'Problem with shipping label', required: true },
  ];

  ordersStore = new HttpDatastore(`/api/datastore/unitedOrderDataStore`);
  labelDataStore = new HttpDatastore<ShippingLabel>(
    '/api/datastore/shippingLabelDataStore',
  );
  productDataStore = new HttpDatastore<ShippingProduct>(
    '/api/datastore/shippingProductsDataStore',
  );

  componentDidMount(): void {
    this.getGoods(this.props.currentLabel.id).then((result) => {
      this.currentLabelGoods = result as Array<GoodsItemProps>;
      this.setState({
        action:
          result && result.length > 0
            ? CurrentLabelGoodsAction.GOODS
            : CurrentLabelGoodsAction.LABEL_EMPTY,
      });
    });
  }

  getGoods = (code: string) => {
    return this.productDataStore
      .query(new Query({ query: new Eq('shipping_label_id', `${code}`) }))
      .then((result) => {
        if (!result || result.length === 0) return [];
        return setListOfGoods(result);
      })
      .catch((error) => {
        httpErrorHandler(error, (code, text) =>
          this.setState({
            action: CurrentLabelGoodsAction.ERROR,
            error: { code, text },
          }),
        );
      });
  };

  _checkForLabelStatus(listOfGoods: GoodsItemProps[]) {
    // checking the availability of all products in the current label
    for (let i = 0; i < listOfGoods.length; i++) {
      if (listOfGoods[i].status === 'ban') {
        return false;
      }
    }
    return true;
  }

  _updateProductsStatuses = async (
    listOfGoods: GoodsItemProps[],
    labelStatus: string,
  ) => {
    for (const good of listOfGoods) {
      let productStatus = '';
      if (labelStatus !== LABEL_PACKED) {
        if (good.status === 'not fit') {
          productStatus = GOOD_STATUS_WRONG_PACKAGE;
        } else if (good.status !== 'ban') {
          productStatus = GOOD_STATUS_NOT_PACKED;
        }
      } else {
        productStatus = GOOD_PACKED;
      }
      // if status is empty, assumed status already sent as problem
      if (productStatus) {
        await this.productDataStore.update({
          id: good.item_id,
          status: productStatus,
          note: '',
        });
      }
    }
  };

  _updateOrderStatus = (id: string, status: string) => {
    return this.ordersStore.update({ id, status });
  };

  _updateLabelStatus = (report: { type: string; note: string }) => {
    return this.labelDataStore.update({
      id: this.props.currentLabel.id,
      status: report.type,
      note: report.note,
    });
  };

  _setLabelAndProductStatus = async (
    listOfGoods: GoodsItemProps[],
    labelStatus?: string,
    note?: string,
  ) => {
    this.setState({ action: CurrentLabelGoodsAction.PENDING });
    const status =
      labelStatus ||
      (this._checkForLabelStatus(listOfGoods)
        ? LABEL_PACKED
        : LABEL_PROBLEM_WITH_PRODUCT);
    const problemDescription = note || '';
    try {
      console.log('processed', listOfGoods, status, note);
      const label = await this._updateLabelStatus({
        type: status,
        note: problemDescription,
      });
      await axios.post('/api/datastore/BarcodeActionHistory', {
        id: randomString(10),
        order_number: label.id,
        action: 'Updating pickup deal in mp',
        timestamp: new Date().getTime() / 1000,
      });

      await updatePickUpPackageStatus(
        label.id,
        status,
        listOfGoods,
        problemDescription,
      );
      await this._updateProductsStatuses(listOfGoods, status);
      await this._updateOrderStatus(
        label.united_order_id,
        ORDER_STATUS_IN_PROGRESS,
      );
      this.props.onLabelProcessed(label.id, status);
    } catch (e) {
      httpErrorHandler(e, (code, text) =>
        this.setState({
          action: CurrentLabelGoodsAction.ERROR,
          error: { code, text },
        }),
      );
    }
  };

  _updateProductStatus = (
    item: GoodsItemProps,
    report: { type: string; note: string },
  ) => {
    this.setState({ action: CurrentLabelGoodsAction.PENDING });
    const { currentGoodIndex } = this.state;
    this.productDataStore
      .update({ id: item.item_id, status: report.type, note: report.note })
      .then(() => {
        this.setState({
          action: CurrentLabelGoodsAction.GOODS,
          currentGoodIndex: 1 + currentGoodIndex,
        });
      })
      .catch((err) => {
        httpErrorHandler(err, (code, text) =>
          this.setState({
            action: CurrentLabelGoodsAction.ERROR,
            error: { code, text },
          }),
        );
      });
  };

  confirmProduct = () => {
    const { currentGoodIndex } = this.state;
    const item = this.currentLabelGoods[currentGoodIndex];
    item.status = 'check';
    this._updateProductStatus(item, {
      type: GOOD_STATUS_IN_PROGRESS,
      note: '',
    });
  };

  onProductProblem = (report: { type: string; note: string }) => {
    const { currentGoodIndex } = this.state;
    const item = this.currentLabelGoods[currentGoodIndex];
    item.status = 'ban';
    item.problem_report = report;
    console.log('problem');
    this._updateProductStatus(item, report);
  };

  _renderLabelSubmitButtons = () => {
    const allGoodsSuccess = !this.currentLabelGoods.find(
      (el) => el.status !== 'check',
    );
    return allGoodsSuccess ? (
      <div>
        <Button
          color="primary"
          block
          onClick={() => {
            this._setLabelAndProductStatus(this.currentLabelGoods).then();
          }}
        >
          Packed and Scan next label
        </Button>
        <OrderProblemReport
          problemTypes={this.problemTypes}
          actionOnSpecificProblemType={(type) => {
            if (type === "Package doesn't fit") {
              this.setState({ modalOpen: true });
              return true;
            }
            return false;
          }}
          onProblemSubmit={(report) => {
            this._setLabelAndProductStatus(
              this.currentLabelGoods,
              report.type,
              report.note,
            ).then();
          }}
        >
          If You have any problems with an order, please provide information
          about it, and 'Send problem'
        </OrderProblemReport>
      </div>
    ) : (
      <Button
        color="danger"
        block
        onClick={() => {
          this._setLabelAndProductStatus(this.currentLabelGoods).then();
        }}
      >
        Put aside and scan the next label
      </Button>
    );
  };

  renderSwitch = (action: CurrentLabelGoodsAction) => {
    const { error, currentGoodIndex } = this.state;
    const { currentLabel } = this.props;

    switch (action) {
      case CurrentLabelGoodsAction.PENDING:
        return (
          <div className="m-5">
            <Spinner />
          </div>
        );
      case CurrentLabelGoodsAction.LABEL_EMPTY:
        return (
          <ErrorView header="Error">
            <div>
              <h5>There is an error with {currentLabel.id} label</h5>
              <h5>This label doesn't have goods</h5>
              <h5 className="mb-4">
                Report this issue immediately to Your manager
              </h5>
              <Button
                color="primary"
                block
                onClick={() =>
                  this._setLabelAndProductStatus(
                    this.currentLabelGoods,
                    LABEL_EMPTY,
                  )
                }
              >
                Scan the next label
              </Button>
            </div>
          </ErrorView>
        );
      case CurrentLabelGoodsAction.WRONG_PACKAGE:
        return (
          <>
            <h6>
              Please, provide original weight and dimensions of a product(s)
            </h6>
            {currentLabel ? (
              <WrongPackage
                order={this.props.order}
                currentLabelGoods={this.currentLabelGoods}
                senderName={this.props.senderName}
                currentLabel={currentLabel}
                setLabelAndProductStatus={this._setLabelAndProductStatus}
              />
            ) : (
              <div className="m-5">No label selected</div>
            )}
          </>
        );
      case CurrentLabelGoodsAction.GOODS:
        // const allGoods         = this._styleGoods(this.currentLabelGoods);
        const currentItemCount = this._getCurrentGoodIndex(
          this.currentLabelGoods,
        );
        return (
          <div className="main-layout">
            {currentItemCount === 0 ? (
              <div className="w-100">
                <Button
                  className="mb-1"
                  color="primary"
                  onClick={() => this.props.onLabelProcessed()}
                >
                  <FontAwesomeIcon icon="arrow-left" />
                </Button>
              </div>
            ) : null}
            <LabelInfo
              id={currentLabel.id}
              shippingClass={currentLabel.shipping_class || ''}
              totalGoods={this.currentLabelGoods.length}
              unitedOrderId={currentLabel.united_order_id}
            >
              {/* Look in LabelInfo component, for description */}
              {/*{ allGoods }*/}
            </LabelInfo>
            <div className="block-layout">
              {/* hide goodsItem on all goods processed */}
              {currentGoodIndex < this.currentLabelGoods.length ? (
                <GoodsItem
                  order={this.props.order}
                  currentItem={this.currentLabelGoods[currentGoodIndex]}
                  senderName={this.props.senderName}
                  confirmProduct={this.confirmProduct}
                  onProductProblem={this.onProductProblem}
                />
              ) : (
                this._renderLabelSubmitButtons()
              )}
            </div>
            {}
          </div>
        );
      case CurrentLabelGoodsAction.ERROR:
        return (
          <ErrorView error={error}>
            <div className="d-flex justify-content-center">
              <Button
                color="primary"
                onClick={() => this.props.onLabelProcessed()}
              >
                Go Back
              </Button>
            </div>
          </ErrorView>
        );
    }
  };

  render() {
    return (
      <>
        <h2>
          {this.state.currentGoodIndex === 1 ? 'Check label' : 'Check good'}
        </h2>
        {this.renderSwitch(this.state.action)}
        <Dialog
          title="Attention"
          isOpen={this.state.modalOpen}
          options={{ centered: true }}
          onClose={() => this.setState({ modalOpen: false })}
          onOk={() =>
            this.setState({
              action: CurrentLabelGoodsAction.WRONG_PACKAGE,
              modalOpen: false,
            })
          }
          submitBtnText="Yes"
          onDecline={() => this.setState({ modalOpen: false })}
          declineBtnText="No"
        >
          <p>Are You sure, that Your package does not fit?</p>
        </Dialog>
      </>
    );
  }

  _styleGoods(goods: GoodsItemProps[]): ReactNode[] {
    type goodStyleParamsType = {
      [key: string]: {
        iconProp: { icon: IconProp; className: string };
        textProp: { className: string };
      };
    };
    const goodStyleParams: goodStyleParamsType = {
      check: {
        iconProp: { icon: 'check', className: 'icon color-green' },
        textProp: { className: 'color-green' },
      },
      ban: {
        iconProp: { icon: 'ban', className: 'icon color-red' },
        textProp: { className: 'color-red' },
      },
      pending: {
        iconProp: { icon: 'barcode', className: 'icon color-blue' },
        textProp: { className: 'color-blue' },
      },
      start: {
        iconProp: { icon: 'flag', className: 'icon color-gray' },
        textProp: { className: 'color-gray' },
      },
    };
    return goods.map((good) => {
      const goodProps = goodStyleParams[good.status];
      return (
        <div className="d-flex" key={good.item_id}>
          <FontAwesomeIcon {...goodProps.iconProp} />
          <div
            className={
              'font-weight-bold align-self-center ' +
              goodProps.textProp.className
            }
          >{`${good.part_number || good.rollun_id}`}</div>
        </div>
      );
    });
  }

  _getCurrentGoodIndex(goods: GoodsItemProps[]) {
    for (let i = 0; i < goods.length; i++) {
      if (goods[i].status !== 'check') {
        return i;
      }
    }
    return goods.length;
  }
}
