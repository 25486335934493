import useHttpDataStore from '../useHttpDatastore';

export interface ReturnedItem {
  track_number: string;
  rid: string;
  sr_item_identifier: string;
  sr_item_identifier_type: string;
  storage: string;
  supplier: string;
}

const useReturnedItemsDatastore = () => {
  return useHttpDataStore<ReturnedItem>('/api/datastore/ReturnsItemsList');
};

export { useReturnedItemsDatastore };
