import React, { useState } from 'react';
import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import axios from 'axios';
import { JSONataInteropControl } from '../../../../../../../Table/components/JSONataInterop/JSONataInteropControl';
import { Box } from '@material-ui/core';
import MuiButton from '../../../../../../../../UI/MuiButton';
import {
  ErrorHandler,
  baseError,
} from '../../../../../../../../UI/ErrorHandler';
import { getErrorObject } from '../../../../../../../Table/components/TableHeaderButtons/utils/alertError';

export type GenericHowToBuyVersion = 'HowToBuy' | 'CreateLinkedDeals';

interface ShippingInfoProps {
  howToBuyOptions?: any;
  bestShipping?: any;
  version: GenericHowToBuyVersion;
  deal?: any;
  uniqueItems?: any;
  rawDeal?: any;
}

export const ShippingInfo = ({
  howToBuyOptions,
  bestShipping,
  version,
  deal,
  uniqueItems,
  rawDeal,
}: ShippingInfoProps) => {
  const [shippingInfo, setShippingInfo] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | typeof baseError | null>(null);
  const isInfoVisible = shippingInfo !== null;

  const getShipInfo = async () => {
    try {
      setLoading(true);
      setError(null);

      const {
        data: { data: shipInfo },
      } = await axios.post(
        version === 'CreateLinkedDeals'
          ? '/api/webhook/CreateLinkDeal'
          : '/api/webhook/HowToBuy',
        {
          howToBuyOptions: howToBuyOptions,
          bestShipping: bestShipping,
          version: version,
          deal: deal,
          uniqueItems: uniqueItems,
          rawDeal: rawDeal,
        },
      );

      setLoading(false);
      setShippingInfo(
        typeof shipInfo === 'object'
          ? JSON.stringify(shipInfo)
          : String(shipInfo),
      );
    } catch (e) {
      setError(getErrorObject(e));
    } finally {
      setLoading(false);
    }
  };

  const clearShipInfo = () => {
    setShippingInfo(null);
  };

  return (
    <ErrorHandler
      error={error}
      retry={() => {
        setError(null);
      }}
      componentName="ShippingInfo"
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '12px',
          }}
        >
          {isInfoVisible ? (
            <MuiButton color={'error'} onClick={clearShipInfo}>
              Close ship info
            </MuiButton>
          ) : (
            <MuiButton
              color={'primary'}
              disabled={loading}
              onClick={getShipInfo}
            >
              Get ship info
            </MuiButton>
          )}
          <JSONataInteropControl
            isVisible={isInfoVisible}
            data={
              typeof shippingInfo === 'string'
                ? JSON.parse(shippingInfo)
                : shippingInfo
            }
          />
        </Box>
        {isInfoVisible && (
          <JsonView
            data={
              typeof shippingInfo === 'string'
                ? JSON.parse(shippingInfo)
                : shippingInfo
            }
            shouldExpandNode={allExpanded}
            style={defaultStyles}
          />
        )}
      </Box>
    </ErrorHandler>
  );
};
