import { PirateShipInfoWithDimensions } from './mergePirateShipInfoWithDimensions';

export const ignoredShippingMethods = {
  parcelSelectGround: 'Parcel Select Ground',
  priorityMailLegalFlatRateEnvelope: 'Priority Mail Legal Flat Rate Envelope',
  priorityMailFlatRateEnvelope: 'Priority Mail Flat Rate Envelope',
};

function lbsToOz(lbs: number) {
  return lbs * 16;
}

function formatPirateShipInfo(pirateShipInfo: PirateShipInfoWithDimensions) {
  const {
    orderId,
    shippingMethod,
    positions: { weight, width, length, height, rightSize },
    clientName,
    contactInfo: { address1, address2, city, postalCode, phone, state },
    sender,
  } = pirateShipInfo;

  return {
    'Order Number': orderId,
    'Mail Class': shippingMethod,
    Weight: weight !== null && +weight !== -1 ? lbsToOz(+weight) : weight,
    Length: length,
    Height: height,
    Width: width,
    Name: clientName,
    'Address 1': address1,
    'Address 2': address2,
    City: city,
    State: state,
    Country: 'US',
    'Postal Code': postalCode,
    'Zip +4': '',
    Phone: phone,
    about: {
      isRightSize: rightSize,
      sender,
    },
  };
}

export function formatPirateShipInfoForDownload(
  pirateShipInfoWithDimensions: PirateShipInfoWithDimensions[],
) {
  const response: Record<
    string,
    Record<string, ReturnType<typeof formatPirateShipInfo>[]>
  > = {
    normalItems: {},
    biggerThan18: {},
    [ignoredShippingMethods.priorityMailFlatRateEnvelope]: {},
    [ignoredShippingMethods.parcelSelectGround]: {},
    [ignoredShippingMethods.priorityMailLegalFlatRateEnvelope]: {},
  };

  const downloadData = pirateShipInfoWithDimensions.map(formatPirateShipInfo);

  for (const data of downloadData) {
    const { isRightSize, sender } = data.about;
    delete data.about; // deleting metadata about pirate ship info

    if (data['Mail Class'] in response) {
      response[data['Mail Class']][sender] = [
        ...(response[data['Mail Class']][sender] || []),
        data,
      ];
      continue;
    }

    if (isRightSize) {
      response.normalItems[sender] = [
        ...(response.normalItems[sender] || []),
        data,
      ];
      continue;
    }

    response.biggerThan18[sender] = [
      ...(response.normalItems[sender] || []),
      data,
    ];
  }

  return response;
}
