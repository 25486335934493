import React from 'react';
import AbstractService, { AbstractServiceProps } from '../AbstractService';
import { ErrorView, Spinner } from '../../UI';
import AppContainer from '../../layout/AppContainer/AppContainer';
import ServiceConstructorContainer from './components/ServiceConstructorContainer';

/**
 * UI to create/delete/edit pages.
 */

class Index extends AbstractService<AbstractServiceProps> {
  render() {
    const { appConfig, error } = this.state;

    if (error) {
      return (
        <AppContainer services={{}}>
          <div className="w-100 h-100 d-flex flex-column justify-content-center">
            <ErrorView error={error}>
              <div className="d-flex justify-content-center p-3">
                <div
                  className="btn btn-primary cursor-pointer"
                  onClick={this.reloadHome}
                >
                  Home
                </div>
              </div>
            </ErrorView>
          </div>
        </AppContainer>
      );
    }

    if (appConfig === null) {
      return <Spinner />;
    }

    const { user, role } = this.props.userIdentity;
    let searchParams: { [key: string]: string } | null = null;
    if (this.props.location && this.props.location.search) {
      this.props.location.search
        // remove ? from query string
        .slice(1)
        .split('&')
        .map((row) => {
          const [key = '', value = ''] = row.split('=');
          return [key, value];
        })
        .forEach(([key, value]) => {
          if (!searchParams) searchParams = {};
          searchParams[key] = value;
        });
    }
    return (
      <AppContainer
        user={user}
        role={role}
        currentResourceName={this.props.resourceName}
        services={this.props.availableServices}
      >
        <ServiceConstructorContainer
          history={this.props.history}
          searchParams={searchParams}
        />
      </AppContainer>
    );
  }
}

export default Index;
