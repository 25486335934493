import React, { FC } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
  Box,
  Typography,
} from '@material-ui/core';
import MuiButton from '../../../../../UI/MuiButton';
import RofUpdaterForm, { locations } from './RofUpdaterForm';
import { useUpdateRofItems } from '../hooks/useUpdateRofItems';
import { Spinner } from '../../../../../UI';

interface RofUpdaterDialogProps {
  isOpen: boolean;
  closeModal: () => void;
}

export type UpdateRofSign = '+' | '-' | '=';
export type UpdateData = {
  rid: string;
  sign: UpdateRofSign;
  quantity: number;
  price: number | null;
  qtyField: typeof locations[number];
};
export type UpdateFormData = {
  location: typeof locations[number];
  useNewPrice: boolean;
  data: UpdateData[];
};

const RofUpdaterDialog: FC<RofUpdaterDialogProps> = ({
  isOpen,
  closeModal,
}) => {
  const theme = useTheme();
  const [status, updateRofItems, createRofItems] = useUpdateRofItems();

  const idleStatusComponent = (
    <>
      {status.description && (
        <Typography variant="h6">{status.description}</Typography>
      )}
      <RofUpdaterForm
        onUpdateSubmit={updateRofItems}
        onCreateSubmit={createRofItems}
      />
      <MuiButton color="error" fullWidth onClick={closeModal}>
        Cancel
      </MuiButton>
    </>
  );

  const errorStatusComponent = (
    <Typography color="error">{status.description}</Typography>
  );

  const loadingStatusComponent = (
    <>
      <Spinner size={60} />
      <Typography variant="h5">{status.description}</Typography>
    </>
  );

  const statusToComponentsMap: Record<string, JSX.Element> = {
    idle: idleStatusComponent,
    error: errorStatusComponent,
    loading: loadingStatusComponent,
  };

  return (
    <Dialog open={isOpen} onClose={closeModal} maxWidth="sm" fullWidth>
      <DialogTitle>Rof Inventory Updater</DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          style={{ gap: theme.spacing(1) }}
        >
          {statusToComponentsMap[status.type]}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RofUpdaterDialog;
