import React, { useContext, useEffect } from 'react';
import { useReturnsDatastore } from '../../../hooks/datastores';
import { ErrorView, Spinner } from '../../../UI';
import { Query, Select } from 'rollun-ts-rql';
import { Grid, Typography } from '@material-ui/core';
import MuiButton from '../../../UI/MuiButton';
import _ from 'lodash';
import ReturnAppContext from '../context';
import { logger } from '../../../utils/logger';

const SelectLocation = () => {
  const [{ result, isLoading, error }, dispatch] = useReturnsDatastore();
  const context = useContext(ReturnAppContext);

  const handleSetLocation = (location: string) => {
    context.setLocation(location);
  };

  useEffect(() => {
    dispatch(
      'QUERY',
      new Query({
        select: new Select(['location']),
      }),
    );
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    logger.info(`ReturnApp.SelectLocation: error`, {
      error,
    });
    return <ErrorView error={error} />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          Select your location
        </Typography>
      </Grid>
      {_.uniq((result || []).map(({ location }) => location))
        .filter((location) => !!location)
        .map((location, index) => (
          <Grid item xs={12} key={index}>
            <MuiButton fullWidth onClick={() => handleSetLocation(location)}>
              {location}
            </MuiButton>
          </Grid>
        ))}
    </Grid>
  );
};

export default SelectLocation;
