import React, { FC, useState } from 'react';
import ReturnedLabelsPage from './ReturnLabelsPage';
import ScanItemPage from './ScanItemPage';
import WasNotFound from './WasNotFound';
import LabelWasFound from './LabelWasFound';
import ItemWasFound from './ItemWasFound';
import { Box, Typography } from '@material-ui/core';
import ReturnAppContext, { Page } from '../context';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import GoBackButton from './GoBackButton';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { getPreviousPageForCurrent } from '../utils';
import SelectLocation from './SelectLocation';
import { useQueryParamState } from '../../../hooks/useQueryParamState';
import { logger } from '../../../utils/logger';

const pageToComponents: Record<Page, JSX.Element> = {
  returnLabels: <ReturnedLabelsPage />,
  scanLabel: <ScanItemPage type="label" />,
  scanItem: <ScanItemPage type="item" />,
  foundLabel: <LabelWasFound />,
  foundItem: <ItemWasFound />,
  notFoundLabel: <WasNotFound type="label" />,
  notFoundItem: <WasNotFound type="item" />,
  setLocation: <SelectLocation />,
};

interface ReturnAppContainerProps {
  appName: string;
}

const ReturnAppContainer: FC<ReturnAppContainerProps> = ({ appName }) => {
  const [location, setLocation] = useQueryParamState<string>('location', '');
  const [page, setPage] = useQueryParamState<Page>('page', 'setLocation');
  const [currentItem, setCurrentItem] = useQueryParamState<string>('item', '');
  const [supplier, setSupplier] = useQueryParamState<string>('supplier', '');
  const [currentLabel, setCurrentLabel] = useQueryParamState<string>(
    'label',
    '',
  );

  const [lastAction, setLastAction] = useState<Page>('setLocation' as const);

  const setPageContext = (pageName: Page) => {
    logger.info(`ReturnApp.Context.setPageContext`, {
      page,
      pageName,
      location,
      currentItem,
      supplier,
      currentLabel,
      lastAction,
    });
    setLastAction(page);
    setPage(pageName);
  };

  const setLabelContext = (label: string, pageName: Page) => {
    logger.info(`ReturnApp.Context.setLabelContext`, {
      label,
      page,
      pageName,
      location,
      currentItem,
      supplier,
      currentLabel,
      lastAction,
    });
    setLastAction(page);
    setPage(pageName);
    setCurrentLabel(label);
  };

  return (
    <ReturnAppContext.Provider
      value={{
        page: page,
        lastAction: lastAction,
        setPage: setPageContext,
        label: currentLabel,
        item: currentItem,
        setItem: (item, pageName: Page) => {
          logger.info(`ReturnApp.Context.setItem`, {
            item,
            page,
            pageName,
            location,
            currentItem,
            supplier,
            currentLabel,
            lastAction,
          });
          setLastAction(page);
          setPage(pageName);
          setCurrentItem(item);
        },
        setLabel: setLabelContext,
        goBack: (currentPage: Page) => {
          const prev = getPreviousPageForCurrent(currentPage);
          logger.info(`ReturnApp.Context.goBack`, {
            prev,
            currentPage,
          });
          setPageContext(prev);
        },
        location,
        setLocation: (location: string) => {
          logger.info(`ReturnApp.Context.setLocation`, {
            location,
          });
          setPageContext('returnLabels');
          setLocation(location);
        },
        supplier,
        setSupplier: (supplier?: string) => {
          logger.info(`ReturnApp.Context.setSupplier`, {
            supplier,
          });
          setSupplier(supplier || '');
        },
      }}
    >
      {page === 'setLocation' ? (
        <Box marginBottom={1}>
          <Typography variant="h3" align="center">
            {appName}
          </Typography>
        </Box>
      ) : (
        <Box display="flex">
          <GoBackButton />
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            {currentLabel && (
              <Typography
                variant="body1"
                color="textSecondary"
                onClick={() => setPageContext('returnLabels')}
              >
                Return
              </Typography>
            )}
            {currentLabel && (
              <Typography
                variant="body1"
                color="textSecondary"
                onClick={() => setLabelContext(currentLabel, 'foundLabel')}
              >
                {currentLabel}
              </Typography>
            )}
          </Breadcrumbs>
        </Box>
      )}
      <Box maxWidth={500} margin="auto" marginBottom={5}>
        {pageToComponents[page]}
      </Box>
    </ReturnAppContext.Provider>
  );
};

export default ReturnAppContainer;
