import React, { Component } from 'react';
import { Button } from '../../../UI';
import { RouterProps } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CreateService from './CreateService/CreateService';
import DeleteService from './DeleteService/DeleteService';
import EditService from './EditService/EditService';
import CreateServiceWithResource from './CreateServiceWithResource/CreateServiceWithResource';

// TODO try to make one abstract component for every step
enum ServiceConstructorActionEnum {
  ADD_SERVICE = 'add_service',
  DELETE_SERVICE = 'delete_service',
  EDIT_SERVICE = 'edit_service',
  ADD_SERVICE_WITH_RESOURCE = 'add_service_with_resource',
}

type ServiceConstructorAction = ServiceConstructorActionEnum | null;

interface IState {
  selectedAction: ServiceConstructorAction;
}

interface IProps extends RouterProps {
  searchParams?: { [key: string]: string } | null;
}

class ServiceConstructorContainer extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    let initSelectedType: ServiceConstructorActionEnum | null = null;
    if (props.searchParams && props.searchParams.action) {
      const type: string = props.searchParams.action;
      if (
        (Object.values(ServiceConstructorActionEnum) as Array<string>).includes(
          type,
        )
      ) {
        initSelectedType = type as ServiceConstructorActionEnum;
      }
    }
    this.state = {
      selectedAction: initSelectedType,
    };
  }

  setAction = (selectedAction: ServiceConstructorAction) => {
    this.props.history.push({
      search: selectedAction ? `?action=${selectedAction}` : '',
    });
    this.setState({ selectedAction });
  };

  renderAction = (selectedAction: ServiceConstructorAction) => {
    const { searchParams = {} } = this.props;

    switch (selectedAction) {
      case ServiceConstructorActionEnum.ADD_SERVICE:
        return <CreateService />;
      case ServiceConstructorActionEnum.ADD_SERVICE_WITH_RESOURCE:
        return <CreateServiceWithResource />;
      case ServiceConstructorActionEnum.DELETE_SERVICE:
        return (
          <DeleteService
            resource={searchParams ? searchParams.resource : undefined}
          />
        );
      case ServiceConstructorActionEnum.EDIT_SERVICE:
        return (
          <EditService
            resource={searchParams ? searchParams.resource : undefined}
          />
        );
      case null:
        return (
          <div className="mt-4">
            <Button
              block
              color="info"
              className="mt-2"
              onClick={() =>
                this.setAction(ServiceConstructorActionEnum.EDIT_SERVICE)
              }
              style={{ height: 50 }}
            >
              Edit
            </Button>
            <Button
              block
              color="primary"
              className="mt-2"
              onClick={() =>
                this.setAction(ServiceConstructorActionEnum.ADD_SERVICE)
              }
              style={{ height: 50 }}
            >
              Create
            </Button>
            <Button
              block
              color="primary"
              className="mt-2"
              onClick={() =>
                this.setAction(
                  ServiceConstructorActionEnum.ADD_SERVICE_WITH_RESOURCE,
                )
              }
              style={{ height: 50 }}
            >
              Create with resource
            </Button>
            <Button
              block
              color="danger"
              className="mt-2"
              onClick={() =>
                this.setAction(ServiceConstructorActionEnum.DELETE_SERVICE)
              }
              style={{ height: 50 }}
            >
              Delete
            </Button>
          </div>
        );
    }
  };

  render() {
    const { selectedAction } = this.state;
    return (
      <div className="p-2">
        <div className="d-flex justify-content-between">
          <h2>Service constructor</h2>
          {selectedAction && (
            <Button onClick={() => this.setAction(null)}>
              <FontAwesomeIcon icon="arrow-left" />
            </Button>
          )}
        </div>
        {this.renderAction(this.state.selectedAction)}
      </div>
    );
  }
}

export default ServiceConstructorContainer;
