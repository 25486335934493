export const archiveScenarioOrder = [
  'no_payment_or_wrong',
  'canceled',
  'shipped',
  'pickup',
];
export const archiveScenarioDropship = [
  'wrong',
  'paid_not_shipped',
  'delivered',
  'cancelled',
];

export const archiveScenarioPickup = ['wrong', 'paid_not_shipped', 'delivered'];

export const archiveScenarioBag = [
  'wrong',
  'was_packaged',
  'paid_not_shipped',
  'cancelled',
];

export const archiveScenarioReturns = ['not_shipped', 'delivered'];

export const archiveScenarioReturnPickup = ['not_shipped', 'delivered'];

export const archiveScenarioReturnDropship = [
  'not_shipped',
  'delivered',
  'not_refunded',
];

export const archiveScenarioReturnBag = ['not_shipped', 'delivered'];

export const archiveScenarioProblem = ['not_solved', 'solved'];
