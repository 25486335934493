/* tslint:disable */
/* eslint-disable */
/**
 * LogsIngestor
 * An API to ingest logs, via HTTP. Mostly used by frontend apps. </br></br> </br></br> Notes - </br></br> The backend side will accept an array of logs, and will use logger to persist messages to ElasticSearch </br> The \'ingest\' endpoint will not throw 500, if some of the logs were not persisted successfully (event if all). Some retry mechanism should be implemente on the backend side. If we are not able to persist message after a reasonable amount of retries, just log to SDTOUT. </br> The \'ingest\' should not be syncronous, to not introduce long running http requests and big latency calls. If that is possible, return 204 to client immidiatelly, and process logs in a background. </br> Ingest logs schema reflects logger interface (not sure about datetime). 
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Message field is not required
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {Array<Message>}
     * @memberof ErrorResponse
     */
    'messages'?: Array<Message>;
}
/**
 * 
 * @export
 * @interface Log
 */
export interface Log {
    /**
     * Service name, used to identify the app (serice) that logs
     * @type {string}
     * @memberof Log
     */
    'service': string;
    /**
     * 
     * @type {string}
     * @memberof Log
     */
    'level': LogLevelEnum;
    /**
     * A short description of log message. Keep it short and undestandable
     * @type {string}
     * @memberof Log
     */
    'message': string;
    /**
     * A time of log. Optional, will be set to current UTC, if not specified. Usefull when batching
     * @type {string}
     * @memberof Log
     */
    'datetime'?: string;
    /**
     * A free object with any data in addition to message. Defaults to empty {} JS object If context is too big for internal logger, it is expected that log will be saved anyway, but truncated. 
     * @type {{ [key: string]: object; }}
     * @memberof Log
     */
    'context'?: { [key: string]: object; };
    /**
     * a string that represents a version of client application. Frontend is not immidiatelly updated, so it is usefull to know what varsion is used. added to the top level of log object (not context) on the same level as message. 
     * @type {string}
     * @memberof Log
     */
    'serviceVersion'?: string;
    /**
     * A unique string, that represents a request to the backend API. Must be generated on each request Always starts with \'ui-request-\' 
     * @type {string}
     * @memberof Log
     */
    'lifecycleToken': string;
    /**
     * A unique string, that represents user session. Always starts with \'ui-session-\' 
     * @type {string}
     * @memberof Log
     */
    'parentLifecycleToken': string;
}

/**
    * @export
    * @enum {string}
    */
export enum LogLevelEnum {
    Error = 'error',
    Warning = 'warning',
    Info = 'info'
}

/**
 * 
 * @export
 * @interface LogsIngestRequest
 */
export interface LogsIngestRequest {
    /**
     * 
     * @type {Array<Log>}
     * @memberof LogsIngestRequest
     */
    'logs'?: Array<Log>;
}
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'level'?: MessageLevelEnum;
    /**
     * UNDEFINED - Any undefined message type INVALID_RESPONSE - A message generated by the client stating that the response could not be decoded 
     * @type {string}
     * @memberof Message
     */
    'type'?: MessageTypeEnum;
    /**
     * Message, that describes what went wrong
     * @type {string}
     * @memberof Message
     */
    'text'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MessageLevelEnum {
    Emergency = 'emergency',
    Alert = 'alert',
    Critical = 'critical',
    Error = 'error',
    Warning = 'warning',
    Notice = 'notice',
    Info = 'info'
}
/**
    * @export
    * @enum {string}
    */
export enum MessageTypeEnum {
    Undefined = 'UNDEFINED',
    InvalidResponse = 'INVALID_RESPONSE'
}

/**
 * 
 * @export
 * @interface Result
 */
export interface Result {
    /**
     * 
     * @type {boolean}
     * @memberof Result
     */
    'result'?: boolean;
}
/**
 * 
 * @export
 * @interface SimpleResponse
 */
export interface SimpleResponse {
    /**
     * 
     * @type {Result}
     * @memberof SimpleResponse
     */
    'data'?: Result;
    /**
     * 
     * @type {Array<Message>}
     * @memberof SimpleResponse
     */
    'messages'?: Array<Message>;
}
/**
 * 
 * @export
 * @interface SuccessResponse
 */
export interface SuccessResponse {
    /**
     * 
     * @type {object}
     * @memberof SuccessResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<Message>}
     * @memberof SuccessResponse
     */
    'messages'?: Array<Message>;
}

/**
 * LogsIngestorApi - axios parameter creator
 * @export
 */
export const LogsIngestorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Ingest multiple log entries
         * @param {LogsIngestRequest} logsIngestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ingestPost: async (logsIngestRequest: LogsIngestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logsIngestRequest' is not null or undefined
            assertParamExists('ingestPost', 'logsIngestRequest', logsIngestRequest)
            const localVarPath = `/ingest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logsIngestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogsIngestorApi - functional programming interface
 * @export
 */
export const LogsIngestorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogsIngestorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Ingest multiple log entries
         * @param {LogsIngestRequest} logsIngestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ingestPost(logsIngestRequest: LogsIngestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ingestPost(logsIngestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LogsIngestorApi - factory interface
 * @export
 */
export const LogsIngestorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogsIngestorApiFp(configuration)
    return {
        /**
         * 
         * @summary Ingest multiple log entries
         * @param {LogsIngestRequest} logsIngestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ingestPost(logsIngestRequest: LogsIngestRequest, options?: any): AxiosPromise<SimpleResponse> {
            return localVarFp.ingestPost(logsIngestRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogsIngestorApi - object-oriented interface
 * @export
 * @class LogsIngestorApi
 * @extends {BaseAPI}
 */
export class LogsIngestorApi extends BaseAPI {
    /**
     * 
     * @summary Ingest multiple log entries
     * @param {LogsIngestRequest} logsIngestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsIngestorApi
     */
    public ingestPost(logsIngestRequest: LogsIngestRequest, options?: AxiosRequestConfig) {
        return LogsIngestorApiFp(this.configuration).ingestPost(logsIngestRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


