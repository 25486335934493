import { noop } from '../../../utils/common.utils';
import { Button } from '../../../UI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import React, { FC } from 'react';

interface IProps {
  onClick?: () => void;
  disabled?: boolean;
}

const PreviousButton: FC<IProps> = ({ onClick = noop, disabled = false }) => (
  <Button
    className="w-100"
    color="primary"
    id="previous-button"
    disabled={disabled}
    onClick={() => onClick()}
  >
    <FontAwesomeIcon
      style={{
        width: '20px',
        height: '20px',
      }}
      icon={faArrowLeft}
    />
  </Button>
);

export default PreviousButton;
