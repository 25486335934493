/* tslint:disable */
/* eslint-disable */
/**
 * CRMOrders
 * This is an API for Order deals managment  *Question to discuss*   - Deduplication logic in post methods?   - Id of entities will be generated. What format? UUID? Incremental id with prefix?   - Discuss How To Buy fields. Should we have separate Entity, that will be just referenced here?   - tracknumbers in MP?   - archive scenario?   - timezones?
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface Customer
 */
export interface Customer {
  /**
   * Unique identificator of order deal (generated by server).
   * @type {string}
   * @memberof Customer
   */
  id: string;
  /**
   * The first name of the customer
   * @type {string}
   * @memberof Customer
   */
  firstName: string;
  /**
   * The last name of the customer
   * @type {string}
   * @memberof Customer
   */
  lastName?: string;
  /**
   *
   * @type {CustomerAddress}
   * @memberof Customer
   */
  address: CustomerAddress;
  /**
   *
   * @type {CustomerContactInfo}
   * @memberof Customer
   */
  contactInfo: CustomerContactInfo;
  /**
   * When entity was created
   * @type {string}
   * @memberof Customer
   */
  createdAt: string;
  /**
   * When entity was created
   * @type {string}
   * @memberof Customer
   */
  updatedAt: string;
}
/**
 *
 * @export
 * @interface CustomerAddress
 */
export interface CustomerAddress {
  /**
   *
   * @type {string}
   * @memberof CustomerAddress
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof CustomerAddress
   */
  street1: string;
  /**
   *
   * @type {string}
   * @memberof CustomerAddress
   */
  street2: string;
  /**
   *
   * @type {string}
   * @memberof CustomerAddress
   */
  zip: string;
  /**
   *
   * @type {string}
   * @memberof CustomerAddress
   */
  state: string;
}
/**
 *
 * @export
 * @interface CustomerContactInfo
 */
export interface CustomerContactInfo {
  /**
   *
   * @type {string}
   * @memberof CustomerContactInfo
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CustomerContactInfo
   */
  phoneNumber: string;
}
/**
 * Message field is not required
 * @export
 * @interface ErrorResult
 */
export interface ErrorResult {
  /**
   *
   * @type {Array<Message>}
   * @memberof ErrorResult
   */
  messages?: Array<Message>;
}
/**
 *
 * @export
 * @interface Message
 */
export interface Message {
  /**
   *
   * @type {string}
   * @memberof Message
   */
  level?: MessageLevelEnum;
  /**
   * You can expose this enum for all your errors UNDEFINED - Any undefined message type LOGGER_MESSAGE - Same as undefined
   * @type {string}
   * @memberof Message
   */
  type?: MessageTypeEnum;
  /**
   * Message, that describes what went wrong
   * @type {string}
   * @memberof Message
   */
  text?: string;
}

export const MessageLevelEnum = {
  Emergency: 'emergency',
  Alert: 'alert',
  Critical: 'critical',
  Error: 'error',
  Warning: 'warning',
  Notice: 'notice',
  Info: 'info',
} as const;

export type MessageLevelEnum = typeof MessageLevelEnum[keyof typeof MessageLevelEnum];
export const MessageTypeEnum = {
  Undefined: 'UNDEFINED',
  LoggerMessage: 'LOGGER_MESSAGE',
} as const;

export type MessageTypeEnum = typeof MessageTypeEnum[keyof typeof MessageTypeEnum];

/**
 *
 * @export
 * @interface OrderDeal
 */
export interface OrderDeal {
  /**
   * Unique identificator of order deal (generated by server).
   * @type {string}
   * @memberof OrderDeal
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof OrderDeal
   */
  status: OrderDealStatusEnum;
  /**
   *
   * @type {Array<OrderProduct>}
   * @memberof OrderDeal
   */
  products?: Array<OrderProduct>;
  /**
   *
   * @type {Customer}
   * @memberof OrderDeal
   */
  customer?: Customer;
  /**
   *
   * @type {object}
   * @memberof OrderDeal
   */
  howToBuy3?: object;
  /**
   *
   * @type {object}
   * @memberof OrderDeal
   */
  howToBuy3Result?: object;
  /**
   *
   * @type {object}
   * @memberof OrderDeal
   */
  howToBuy3BestShipping?: object;
  /**
   *
   * @type {object}
   * @memberof OrderDeal
   */
  howToBuy4?: object;
  /**
   *
   * @type {object}
   * @memberof OrderDeal
   */
  howToBuy4Result?: object;
  /**
   *
   * @type {object}
   * @memberof OrderDeal
   */
  howToBuy4BestShipping?: object;
  /**
   *
   * @type {OrderDealMarketplace}
   * @memberof OrderDeal
   */
  marketplace?: OrderDealMarketplace;
  /**
   *
   * @type {OrderDealPayment}
   * @memberof OrderDeal
   */
  payment?: OrderDealPayment;
  /**
   *
   * @type {OrderDealShipping}
   * @memberof OrderDeal
   */
  shipping?: OrderDealShipping;
  /**
   * The problem with order, null if everything is fine
   * @type {string}
   * @memberof OrderDeal
   */
  problem?: string;
  /**
   * When entity was created
   * @type {string}
   * @memberof OrderDeal
   */
  createdAt: string;
  /**
   * When entity was created
   * @type {string}
   * @memberof OrderDeal
   */
  updatedAt: string;
}

export const OrderDealStatusEnum = {
  ParcelsPreparation: 'ParcelsPreparation',
  _60daytest: '60daytest',
  Archive: 'Archive',
} as const;

export type OrderDealStatusEnum = typeof OrderDealStatusEnum[keyof typeof OrderDealStatusEnum];

/**
 *
 * @export
 * @interface OrderDealListResult
 */
export interface OrderDealListResult {
  /**
   *
   * @type {Array<OrderDeal>}
   * @memberof OrderDealListResult
   */
  data?: Array<OrderDeal>;
  /**
   *
   * @type {Array<Message>}
   * @memberof OrderDealListResult
   */
  messages?: Array<Message>;
}
/**
 *
 * @export
 * @interface OrderDealMarketplace
 */
export interface OrderDealMarketplace {
  /**
   * The name of marketplace where order originated from
   * @type {string}
   * @memberof OrderDealMarketplace
   */
  name?: string;
  /**
   * The order number for original order on marketplace
   * @type {string}
   * @memberof OrderDealMarketplace
   */
  orderNumber?: string;
  /**
   * An id that points to a customer on marketplace
   * @type {string}
   * @memberof OrderDealMarketplace
   */
  customerId?: string;
  /**
   * The shipping method that order will be shiped to customer
   * @type {string}
   * @memberof OrderDealMarketplace
   */
  shippingMethod?: string;
  /**
   *
   * @type {string}
   * @memberof OrderDealMarketplace
   */
  shippingTemplate?: string;
  /**
   *
   * @type {string}
   * @memberof OrderDealMarketplace
   */
  note?: string;
}
/**
 *
 * @export
 * @interface OrderDealPayment
 */
export interface OrderDealPayment {
  /**
   * The date when payment was received from customer
   * @type {string}
   * @memberof OrderDealPayment
   */
  receivedAt?: string;
  /**
   *
   * @type {number}
   * @memberof OrderDealPayment
   */
  itemsTotal?: number;
  /**
   * Cost of goods sold.
   * @type {number}
   * @memberof OrderDealPayment
   */
  cogs?: number;
  /**
   * itemsTotal - cogs
   * @type {number}
   * @memberof OrderDealPayment
   */
  profit?: number;
}
/**
 *
 * @export
 * @interface OrderDealResult
 */
export interface OrderDealResult {
  /**
   *
   * @type {OrderDeal}
   * @memberof OrderDealResult
   */
  data?: OrderDeal;
  /**
   *
   * @type {Array<Message>}
   * @memberof OrderDealResult
   */
  messages?: Array<Message>;
}
/**
 *
 * @export
 * @interface OrderDealShipping
 */
export interface OrderDealShipping {
  /**
   *
   * @type {string}
   * @memberof OrderDealShipping
   */
  shipBy?: string;
  /**
   *
   * @type {string}
   * @memberof OrderDealShipping
   */
  deliverBy?: string;
}
/**
 * Object, represents item in bag. Each object does not include quantity. If item there are 2 items in deal, it will be duplicated.
 * @export
 * @interface OrderProduct
 */
export interface OrderProduct {
  /**
   * Id of item in CRM (rollunId)
   * @type {string}
   * @memberof OrderProduct
   */
  id?: string;
  /**
   * unit price
   * @type {number}
   * @memberof OrderProduct
   */
  price?: number;
  /**
   * The title of the items
   * @type {string}
   * @memberof OrderProduct
   */
  title?: string;
}
/**
 *
 * @export
 * @interface SuccessResult
 */
export interface SuccessResult {
  /**
   *
   * @type {object}
   * @memberof SuccessResult
   */
  data?: object;
  /**
   *
   * @type {Array<Message>}
   * @memberof SuccessResult
   */
  messages?: Array<Message>;
}

/**
 * OrderDealsApi - axios parameter creator
 * @export
 */
export const OrderDealsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get list of Order Deals
     * @summary Get list of Order Deals
     * @param {string} [filter] Returning orders with by a specific rql filter.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ordersGet: async (
      filter?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/orders`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns information of Order Deal by id
     * @summary Get info about specific Order Deal
     * @param {string} id Id of resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ordersIdGet: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('ordersIdGet', 'id', id);
      const localVarPath = `/orders/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Order Deal by id
     * @param {string} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ordersIdPut: async (
      id: string,
      body: object,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('ordersIdPut', 'id', id);
      // verify required parameter 'body' is not null or undefined
      assertParamExists('ordersIdPut', 'body', body);
      const localVarPath = `/orders/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create An Order Deal
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ordersPost: async (
      body: object,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('ordersPost', 'body', body);
      const localVarPath = `/orders`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OrderDealsApi - functional programming interface
 * @export
 */
export const OrderDealsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OrderDealsApiAxiosParamCreator(
    configuration,
  );
  return {
    /**
     * Get list of Order Deals
     * @summary Get list of Order Deals
     * @param {string} [filter] Returning orders with by a specific rql filter.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ordersGet(
      filter?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OrderDealListResult>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.ordersGet(
        filter,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Returns information of Order Deal by id
     * @summary Get info about specific Order Deal
     * @param {string} id Id of resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ordersIdGet(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OrderDealResult>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.ordersIdGet(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update Order Deal by id
     * @param {string} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ordersIdPut(
      id: string,
      body: object,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OrderDealResult>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.ordersIdPut(
        id,
        body,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create An Order Deal
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ordersPost(
      body: object,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OrderDealResult>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.ordersPost(
        body,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * OrderDealsApi - factory interface
 * @export
 */
export const OrderDealsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OrderDealsApiFp(configuration);
  return {
    /**
     * Get list of Order Deals
     * @summary Get list of Order Deals
     * @param {string} [filter] Returning orders with by a specific rql filter.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ordersGet(
      filter?: string,
      options?: any,
    ): AxiosPromise<OrderDealListResult> {
      return localVarFp
        .ordersGet(filter, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns information of Order Deal by id
     * @summary Get info about specific Order Deal
     * @param {string} id Id of resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ordersIdGet(id: string, options?: any): AxiosPromise<OrderDealResult> {
      return localVarFp
        .ordersIdGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update Order Deal by id
     * @param {string} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ordersIdPut(
      id: string,
      body: object,
      options?: any,
    ): AxiosPromise<OrderDealResult> {
      return localVarFp
        .ordersIdPut(id, body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create An Order Deal
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ordersPost(body: object, options?: any): AxiosPromise<OrderDealResult> {
      return localVarFp
        .ordersPost(body, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * OrderDealsApi - object-oriented interface
 * @export
 * @class OrderDealsApi
 * @extends {BaseAPI}
 */
export class OrderDealsApi extends BaseAPI {
  /**
   * Get list of Order Deals
   * @summary Get list of Order Deals
   * @param {string} [filter] Returning orders with by a specific rql filter.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderDealsApi
   */
  public ordersGet(filter?: string, options?: AxiosRequestConfig) {
    return OrderDealsApiFp(this.configuration)
      .ordersGet(filter, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns information of Order Deal by id
   * @summary Get info about specific Order Deal
   * @param {string} id Id of resource
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderDealsApi
   */
  public ordersIdGet(id: string, options?: AxiosRequestConfig) {
    return OrderDealsApiFp(this.configuration)
      .ordersIdGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Order Deal by id
   * @param {string} id
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderDealsApi
   */
  public ordersIdPut(id: string, body: object, options?: AxiosRequestConfig) {
    return OrderDealsApiFp(this.configuration)
      .ordersIdPut(id, body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create An Order Deal
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderDealsApi
   */
  public ordersPost(body: object, options?: AxiosRequestConfig) {
    return OrderDealsApiFp(this.configuration)
      .ordersPost(body, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
