import { copyToClipboard } from 'rollun-ts-utils';
import { httpErrorHandlerSync } from '../../../../../utils/common.utils';
import { lcTokenStore } from '../../../../CRMDeals/components/GenericDeal/components/CreateDealFromRawData';

export const getErrorObject = (e: any) => {
  const lctoken = (e as any)?.config?.headers?.lifecycleToken || '';
  const plctoken = lcTokenStore.getSessionToken();
  const { text, code } = httpErrorHandlerSync(e);

  const message = (e as any)?.message;
  const errorText =
    typeof message === 'object' ? JSON.stringify(message) : message;

  return {
    lctoken,
    plctoken,
    text: text || errorText,
    code: String(code),
  };
};

export const getErrorText = (e: any) => {
  const lctoken = (e as any)?.config?.headers?.lifecycleToken || '';
  const plctoken = lcTokenStore.getSessionToken();
  const { text, code } = httpErrorHandlerSync(e);

  const message = (e as any)?.message;
  const errorText =
    typeof message === 'object' ? JSON.stringify(message) : message;

  const error = `
          ${!!lctoken ? `lctoken: ${lctoken};\n` : ''}
          plctoken: ${plctoken};\n
          ${text || errorText ? `Text: ${text || errorText}\n` : ''}
          ${!!String(code) ? `Code: ${code}\n` : ''}
        `;

  return error;
};

export const alertError = async (e: any) => {
  const error = getErrorText(e);
  copyToClipboard(error);
  alert(`Error copied to clipboard!\n${error}`);
};
