import React, { FC, useContext, useEffect, useState } from 'react';
import { Order } from '../../../../api-clients/types';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import { DealContext } from '../../../../GenericDeal/context/dealContext';
import { fetchCreateLinkedDealsV3 } from './fetchCreateLinkedDealsV3';
import { HowToBuyV3 } from './HowToBuyV3';
import { TabPanel } from './TabPanel';
import { OneSupplier } from './OneSupplier/OneSupplier';

let createLinkedDealsV3Cache: undefined | any;

const CreateLinkedDealsV3: FC = () => {
  const dealContext = useContext(DealContext);
  const deal = dealContext?.deal as Order | undefined;
  const [value, setValue] = useState(0);
  const [howToBuyOptions, setHowToBuyOptions] = useState<any | null>(null);
  const [error, setError] = useState<null | {
    errorMessage: string;
    errorDetails: string;
  }>(null);

  useEffect(() => {
    if (createLinkedDealsV3Cache) {
      setHowToBuyOptions(createLinkedDealsV3Cache);
      return;
    }

    (async () => {
      if (deal) {
        try {
          const howToBuyOptions = await fetchCreateLinkedDealsV3(deal);

          setHowToBuyOptions(howToBuyOptions);
          createLinkedDealsV3Cache = howToBuyOptions;
          console.log('howToBuyOptions', howToBuyOptions);
        } catch (error) {
          const err = error as any;

          let errorMessage = 'Unknown error occurred.';
          if (err.response && err.response.data && err.response.data.messages) {
            const messages = err.response.data.messages;
            if (messages.length > 0) {
              errorMessage = messages
                .map((msg: { text: string }) => msg.text)
                .join('\n');
            }
          } else if (err.message) {
            errorMessage = err.message;
          }

          setError({
            errorMessage,
            errorDetails: JSON.stringify(err),
          });
        }
      }
    })();
  }, [deal]);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const renderContent = (options: any, error: any) => {
    if (error)
      return (
        <Box>
          <Typography
            style={{
              fontWeight: 'bold',
            }}
          >
            Error: {error.errorMessage}
          </Typography>
          <Typography>Details: {error.errorDetails}</Typography>
        </Box>
      );
    if (options === null) return <>Loading</>;
    return (
      <>
        <TabPanel value={value} index={0}>
          <OneSupplier deal={deal} howToBuyOptions={options} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <HowToBuyV3 howToBuyOptions={options.second} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          Page Three
        </TabPanel>
      </>
    );
  };

  return (
    <Box
      style={{
        maxWidth: '100%',
      }}
    >
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
      >
        <Tab label="One Parcel" />
        <Tab label="Several Parcels" />
      </Tabs>
      {renderContent(howToBuyOptions, error)}
    </Box>
  );
};

export default CreateLinkedDealsV3;
