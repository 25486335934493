import React from 'react';
import { Box } from '@material-ui/core';
import { CreateDealFromRawData } from '../../../../../CRMDeals/components/GenericDeal/components/CreateDealFromRawData';
import { ProblemReporter } from '../../../../../CRMDeals/components/GenericDeal/components/ProblemReporter';

export default function CRMControls() {
  return (
    <Box
      style={{
        display: 'flex',
        paddingTop: 8,
        justifyContent: 'space-between',
      }}
    >
      <ProblemReporter />
      <CreateDealFromRawData />
    </Box>
  );
}
