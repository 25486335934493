import React, { Component } from 'react';
import HttpDatastore from 'rollun-ts-datastore';
import SortableTree, {
  ExtendedNodeData,
  getNodeAtPath,
  removeNode,
  TreeItem,
} from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import {
  httpErrorHandler,
  optimizeChanges,
  rowTableDataToTree,
} from '../../../utils/common.utils';
import { Button, Card, ErrorView, HiddenInput, Spinner } from '../../../UI';
import { camelCaseToWords, randomString } from 'rollun-ts-utils/dist';
import { ErrorType } from '../../../utils/common.types';
import CreateNewNode from './CreateNewNode';

export interface FlatTreeItem {
  id: string;
  parent_id: null | string;

  [key: string]: string | null;
}

export type Change = {
  id: string;
  parent_id?: string | null;
  action: 'create' | 'update' | 'delete';
  title?: string;
  subtitle?: string;
};

interface IState {
  loading: boolean;
  treeData: Array<TreeItem>;
  error: ErrorType | null;
  modalOpen: boolean;
  title: string;
  subtitle: string;
  changes: Array<Change>;
}

interface IProps {
  dataStoreURL: string;
  treeNameField?: string;
  treeDataField?: string;
  appName: string;
}

/**
 * Editor for tree-like structures
 * Note: in database, every record need to have id as primary key and parent_id
 * as pointer to its parent
 * Another note:
 * this tree component can hold up to 2 different parameters in one tree item ->
 * title and subtitle. in data base it can be named in any other way, so
 * You can configure this names by props:
 *              treeNameField === title
 *              treeDataField === subtitle
 * IT IS MANDATORY, TO HAVE AT LEAST ONE OF THEM IN CONFIG
 */

class EditableTree extends Component<IProps, IState> {
  state: IState = {
    loading: false,
    treeData: [],
    error: null,
    modalOpen: false,
    title: '',
    subtitle: '',
    changes: [],
  };
  treeDataStore: HttpDatastore;

  selectedNode: TreeItem | null = null;

  /**
   * when creating nodes with subtitles, need to store them in some store
   * because there is no subtitles in tree data.
   */

  createdSubtitles: { [key: string]: string } = {};

  constructor(props: IProps) {
    super(props);
    this.treeDataStore = new HttpDatastore(props.dataStoreURL);
  }

  loadTreeData = () => {
    this.setState({ loading: true, error: null });
    this.treeDataStore
      .query()
      .then((res) => {
        this.setState({
          treeData: rowTableDataToTree(res as Array<FlatTreeItem>, {
            labelField: this.props.treeNameField,
          }),
        });
      })
      .catch((err) => {
        httpErrorHandler(err, (code, text) =>
          this.setState({ error: { code, text } }),
        );
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  _renderNodeControl = (rowInfo: ExtendedNodeData) => {
    return (
      <div>
        <Button
          color="danger"
          size="sm"
          onClick={() => this.onNodeDelete(rowInfo.node, rowInfo.path)}
        >
          Delete
        </Button>
      </div>
    );
  };

  componentDidMount(): void {
    this.loadTreeData();
  }

  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    // @ts-expect-error
    this.setState({ [name]: value });
  };

  // TODO add editing each node
  onNodeUpdate = (node: TreeItem) => {
    let subtitle = node.subtitle;
    if (this.createdSubtitles[node.id]) {
      subtitle = this.createdSubtitles[node.id];
    }
    this.setState(({ changes }) => ({
      changes: changes.concat({
        id: node.id,
        parent_id: node.parent_id,
        title: node.title,
        subtitle: subtitle,
        action: 'update',
      } as Change),
    }));
  };

  onNodeDelete = (node: TreeItem, path: Array<number | string>) => {
    const result = removeNode({
      path: path,
      treeData: this.state.treeData,
      getNodeKey: ({ node }: { node: TreeItem }) => node.node_id,
    });

    this.setState(({ changes }) => ({
      changes: changes.concat({
        id: node.id,
        action: 'delete',
      }),
      treeData: result ? result.treeData : [],
    }));
  };

  onNodeCreate = (title: string, subtitle: string) => {
    if (!title) return;

    const newTreeItem = {
      id: randomString(10),
      parent_id: null,
      title,
    };
    this.createdSubtitles[newTreeItem.id] = subtitle;
    this.setState(({ changes, treeData }) => ({
      changes: changes.concat({
        ...newTreeItem,
        action: 'create',
        subtitle,
      }),
      treeData: [
        { ...newTreeItem, node_id: randomString(5) + '_' + title },
      ].concat(treeData as any),
    }));
  };

  submitChanges = async () => {
    const { treeNameField, treeDataField } = this.props;

    console.log('all changes', this.state.changes);
    const changes = optimizeChanges(this.state.changes);
    console.log('optimized changes', changes);
    this.setState({ loading: true, changes: [] });
    try {
      for (const change of changes) {
        const res =
          change.action === 'delete'
            ? await this.treeDataStore.delete(change.id)
            : await this.treeDataStore[change.action]({
                id: change.id,
                parent_id: change.parent_id,
                [treeNameField || 'title']: change.title,
                [treeDataField || 'subtitle']: change.subtitle,
              });
        console.log('change results', res);
      }
      // clear subtitles cache
      this.createdSubtitles = {};
      this.loadTreeData();
    } catch (e) {
      httpErrorHandler(e, (code, text) => {
        this.setState({ loading: false, error: { code, text } });
      });
    }
  };

  render() {
    const { loading, error, treeData, changes } = this.state;
    const { treeNameField, treeDataField, appName } = this.props;

    return (
      <Card>
        <h3 className="text-center first-capitalize">
          {camelCaseToWords(appName)}
        </h3>
        <div className="row">
          <CreateNewNode
            treeDataField={treeDataField}
            treeNameField={treeNameField}
            onNodeCreate={this.onNodeCreate}
          />
          <div className="col-12 col-md-6 d-flex justify-content-center">
            {loading && <Spinner />}
            {error && (
              <Card>
                <ErrorView error={error}>
                  <Button color="primary" onClick={() => this.loadTreeData()}>
                    Back
                  </Button>
                </ErrorView>
              </Card>
            )}
            {!loading && !error && (
              <div>
                {changes.length > 0 && (
                  <div className="d-flex justify-content-between m-2">
                    <h5>You have some unsaved changes ({changes.length})</h5>
                    <Button
                      color="primary"
                      onClick={() => this.submitChanges()}
                      size="sm"
                      className="ml-2"
                    >
                      Save
                    </Button>
                  </div>
                )}
                <SortableTree
                  isVirtualized={true}
                  treeData={treeData}
                  style={{ height: 700, width: 500 }}
                  getNodeKey={({ node }: { node: TreeItem }) => node.node_id}
                  onChange={(treeData: Array<TreeItem>) => {
                    this.setState({ treeData });
                  }}
                  onMoveNode={({ node, nextPath }: any) => {
                    const newParentNode = getNodeAtPath({
                      treeData: this.state.treeData,
                      getNodeKey: ({ node }: { node: TreeItem }) =>
                        node.node_id,
                      path: nextPath.slice(0, nextPath.length - 1),
                    });
                    const oldParentId = node.parent_id;
                    if (newParentNode) {
                      if (newParentNode.treeIndex === -1) {
                        node.parent_id = null;
                      } else {
                        node.parent_id = newParentNode.node.id;
                      }
                    }
                    // Prevent reload if parent id did not change
                    if (oldParentId !== node.parent_id) {
                      this.onNodeUpdate(node);
                    }
                  }}
                  generateNodeProps={(rowInfo: ExtendedNodeData) => ({
                    title: (
                      <HiddenInput
                        initValue={
                          rowInfo.node.title
                            ? rowInfo.node.title.toString()
                            : ''
                        }
                        onSubmit={(str) =>
                          str !== rowInfo.node.title &&
                          this.onNodeUpdate({
                            ...rowInfo.node,
                            title: str,
                          })
                        }
                      />
                    ),
                    // subtitle: () => <div style={{display: 'none'}}></div>,
                    buttons: [this._renderNodeControl(rowInfo)],
                  })}
                />
              </div>
            )}
          </div>
        </div>
      </Card>
    );
  }
}

export default EditableTree;
