import React, { useState } from 'react';
import { Paper, TextField, Box, makeStyles } from '@material-ui/core';
import MuiButton from '../../../../../UI/MuiButton';
import { useParams } from 'react-router-dom';
import { clientsApi } from '../hooks/useGetClient';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    padding: 8,
    width: 300,
  },
  inputs: {
    display: 'flex',
    gap: 16,
    flexDirection: 'column',
  },
}));

interface AddressInfoProps {
  address: {
    street1: string;
    street2: string;
    city: string;
    state: string;
    zip: string;
  };
}

const AddressInfo = ({ address }: AddressInfoProps) => {
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();

  const [street1, setStreet1] = useState(address.street1);
  const [street2, setStreet2] = useState(address.street2);
  const [city, setCity] = useState(address.city);
  const [state, setState] = useState(address.state);
  const [zip, setZip] = useState(address.zip);

  const handleSave = async () => {
    const data = {
      address: {
        street1,
        street2,
        city,
        state,
        zip,
      },
    };

    try {
      await clientsApi.clientsIdPut(id, data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Paper elevation={1} className={classes.root}>
      <MuiButton color="primary" style={{ marginLeft: 'auto' }}>
        Copy
      </MuiButton>
      <Box className={classes.inputs}>
        <TextField
          fullWidth
          value={street1}
          label="Street 1"
          onChange={(e) => setStreet1(e.target.value)}
        />
        <TextField
          fullWidth
          value={street2}
          label="Street 2"
          onChange={(e) => setStreet2(e.target.value)}
        />
        <TextField
          fullWidth
          value={city}
          label="City"
          onChange={(e) => setCity(e.target.value)}
        />

        <TextField
          fullWidth
          value={state}
          label="State"
          onChange={(e) => setState(e.target.value)}
        />

        <TextField
          fullWidth
          value={zip}
          label="Zip"
          onChange={(e) => setZip(e.target.value)}
        />
      </Box>

      <MuiButton
        fullWidth
        color="success"
        onClick={handleSave}
        disabled={
          street1 === address.street1 &&
          street2 === address.street2 &&
          city === address.city &&
          state === address.state &&
          zip === address.zip
        }
      >
        Save Address
      </MuiButton>
    </Paper>
  );
};

export default AddressInfo;
