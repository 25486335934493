import { useEffect, useState } from 'react';

const useResponsiveHeight = (minus: number) => {
  const [height, setHeight] = useState(window.innerHeight - minus);

  const changeHeight = () => {
    setHeight(window.innerHeight - minus);
  };

  useEffect(() => {
    window.addEventListener('resize', changeHeight);

    return () => window.removeEventListener('resize', changeHeight);
  }, []);

  return height;
};

export default useResponsiveHeight;
