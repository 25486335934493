import {
  EbayMarketplace,
  ExchangeFileFormattor,
  groupDataBySupplier,
  UniformSupplier,
} from './index';
import {
  httpErrorHandlerPromised,
  noop,
  makeStaticCDNPath,
} from '../../../utils/common.utils';
import { fetchUniformDataBySupplierName } from './fetchUniformDataBySupplierName';
import { prepareDescription } from './fetchRockyMountainData';

const getLocationFromSupplier = (supplierName: UniformSupplier) => {
  switch (supplierName) {
    case 'Autodist':
      return 'ME 04401';
    case 'PartsUnlimited':
      return 'NC 28790';
    case 'TuckerRocky':
      return 'TX 76177';
    case 'RockyMountain':
      return 'KY 40391|UT 84651';
  }
};

const getMarketplaceSpecificImages = (marketplace: EbayMarketplace) => {
  const marketplaceToImagesMap = {
    Plaisir: [
      makeStaticCDNPath('images/avec_plaisir.png'),
      makeStaticCDNPath('images/warehouse_plaisir.png'),
    ],
    Rollun: [
      makeStaticCDNPath('images/warehouse_rollun.png'),
      makeStaticCDNPath('images/rollun_logo.png'),
    ],
  };
  return marketplaceToImagesMap[marketplace];
};

export const getFileExchangeByRollunIdAndSrName: ExchangeFileFormattor = async (
  data,
  { marketplace },
  progress = noop,
) => {
  try {
    const groupedBySupplier = groupDataBySupplier(data);

    if (!Object.keys(groupedBySupplier)) {
      throw new Error('No supplier were provided');
    }

    const supplierData = await fetchUniformDataBySupplierName(
      groupedBySupplier,
      progress,
    );

    if (supplierData.error) {
      throw new Error(supplierData.error.text);
    }

    const fileExchange = supplierData.data.map(
      ({
        category_id,
        id,
        brand,
        mpn,
        title_ebay,
        composed_description,
        size,
        color,
        upc,
        uploaded_images,
        ct_plaisir_price,
        ct_rollun_price,
        s_quantity,
        supplier,
      }) => {
        progress('File exchange is ready.');
        return {
          '*Action(SiteID=eBayMotors|Country=US|Currency=USD|Version=745|CC=UTF-8)':
            'AddFixedPriceItem',
          InventoryTrackingMethod: 'SKU',
          CustomLabel: id,
          '*Category': category_id,
          StoreCategory: '',
          '*Title': title_ebay,
          Subtitle: '',
          Relationship: '',
          RelationshipDetails: '',
          '*ConditionID': '1000',
          'C:<item specific name>': '',
          '*C:Brand': brand,
          'C:Size': size,
          '*C:Manufacturer Part Number': mpn,
          'C:Type': '',
          'C:Color': color,
          'C:Warranty': 'Unspecified Length',
          'C:Custom Bundle': '',
          'C:Bundle Description': '',
          'C:Modified Item': '',
          'C:Modification Description': '',
          'C:Non-Domestic Product': '',
          'Product:UPC': upc,
          'C:California Prop 65 Warning':
            'WARNING: Cancer and Reproductive Harm - www.P65Warnings.ca.gov',
          'C:Country/Region of Manufacture': '',
          PicURL: [
            ...(uploaded_images || []),
            ...getMarketplaceSpecificImages(marketplace),
          ]
            .slice(0, 9)
            .join(' | '),
          GalleryType: '',
          '*Description': prepareDescription(composed_description),
          '*Format': 'FixedPrice',
          '*Duration': 'GTC',
          '*StartPrice':
            (marketplace === 'Rollun' ? ct_rollun_price : ct_plaisir_price) ||
            0,
          BuyItNowPrice: '',
          '*Quantity': +s_quantity < 5 ? 0 : 5,
          PayPalAccepted: '',
          PayPalEmailAddress: '',
          ImmediatePayRequired: '',
          PaymentInstructions: '',
          '*Location': getLocationFromSupplier(supplier),
          ShippingType: 'Flat',
          'ShippingService-1:Option': 'ShippingMethodStandard',
          'ShippingService-1:Cost': '0',
          'ShippingService-1:AdditionalCost': '0',
          'ShippingService-2:Option': '',
          'ShippingService-2:Cost': '',
          '*DispatchTimeMax': '1',
          PromotionalShippingDiscount: '',
          ShippingDiscountProfileID: '',
          DomesticRateTable: '',
          '*ReturnsAcceptedOption': 'ReturnsAccepted',
          ReturnsWithinOption: 'Days_30',
          RefundOption: 'MoneyBack',
          ShippingCostPaidByOption: 'Seller',
          AdditionalDetails: '',
          UseTaxTable: '',
          ShippingProfileName: 'Flat:Standard Shipp(Free),1 business day',
          ReturnProfileName: 'Returns Accepted,Seller,30 Days,Money Back',
          PaymentProfileName: 'eBay Payments:eBay Payments',
          VehiclesCompatibles: [],
        };
      },
    );

    progress('File exchange is ready.');
    return {
      data: fileExchange,
      error: null,
    };
  } catch (e) {
    const error = await httpErrorHandlerPromised(e);
    return {
      data: [],
      error,
    };
  }
};
