import React, { FC } from 'react';
import { WidgetProps } from './DealWidgets';
import { Box, Paper, Typography } from '@material-ui/core';
import MuiIconButton from '../../../../../UI/MuiIconButton';
import { DocsLink } from '../../../../../UI/DocsLink';

interface WidgetContainerProps {
  widget: WidgetProps;
  isOpen: boolean;
  onClose: () => void;
  setDisplayName: (displayName: string) => void;
  props?: Record<string, unknown>;
}

const WidgetContainer: FC<WidgetContainerProps> = ({
  widget,
  onClose,
  isOpen,
  setDisplayName,
  props,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Paper
      elevation={1}
      style={{
        padding: 8,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 8,
      }}
    >
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          display="flex"
          alignItems="center"
          style={{
            gap: '16px',
          }}
        >
          <Typography variant="h6">{widget.displayName}</Typography>
          {widget?.docsLink && widget?.docsTitle && (
            <DocsLink link={widget.docsLink} title={widget.docsTitle} />
          )}
        </Box>
        <MuiIconButton onClick={onClose} color="error" iconName="times" />
      </Box>
      <Box width="100%">
        {React.createElement(widget.component, {
          setDisplayName,
          props: props || {},
        })}
      </Box>
    </Paper>
  );
};

export default WidgetContainer;
