import useHttpDataStore from '../useHttpDatastore';

export interface UnidentifiedReturnsItem {
  id: string;
  sr_item_identifier: string | null;
  sr_item_identifier_type: string | null;
  track_number: string;
  photo: string;
  status: 'new' | string;
  createdAt: string;
  rid: string | null;
  status_from_manager: 'ROF' | 'rubbish' | 'damaged';
  added_to_inventory: 'yes' | 'no';
  storage: string;
  supplier: string;
  available: 'yes' | 'no';
}

const useUnidentifiedReturnsItemsDatastore = () => {
  return useHttpDataStore<UnidentifiedReturnsItem>(
    '/api/datastore/UnidentifiedReturnsItems',
  );
};

export { useUnidentifiedReturnsItemsDatastore };
