import React from 'react';
import AppContainer from '../../layout/AppContainer/AppContainer';
import AbstractService, { AbstractServiceProps } from '../AbstractService';
import { ErrorView } from '../../UI';
import { UsersControl } from './components/UsersControl';
import { Typography } from '@material-ui/core';

export class UsersControlPanel extends AbstractService<AbstractServiceProps> {
  render() {
    const { error } = this.state;

    if (error) {
      return (
        <AppContainer services={{}}>
          <div className="w-100 h-100 d-flex flex-column justify-content-center">
            <ErrorView error={error}>
              <div className="d-flex justify-content-center p-3">
                <div
                  className="btn btn-primary cursor-pointer"
                  onClick={this.reloadHome}
                >
                  Home
                </div>
              </div>
            </ErrorView>
          </div>
        </AppContainer>
      );
    }

    const { user, role } = this.props.userIdentity;
    return (
      <AppContainer
        currentResourceName={this.props.resourceName}
        services={this.props.availableServices}
        user={user}
        role={role}
      >
        <Typography variant={'h4'}>Choose a user:</Typography>
        <UsersControl />
      </AppContainer>
    );
  }
}
