import React, { FC, useContext } from 'react';
import MuiButton, { MuiColors } from '../../../UI/MuiButton';
import { useUnidentifiedReturnsItemsDatastore } from '../../../hooks/datastores';
import { Typography } from '@material-ui/core';
import { Spinner } from '../../../UI';
import { GlobalErrorContext } from '../../RootApp/Root.app';

interface AddToInventoryItemProps {
  title: string;
  color: MuiColors;
  id: string;
  refreshList: () => void;
}

const AddToInventoryItem: FC<AddToInventoryItemProps> = ({
  title,
  color,
  id,
  refreshList,
}) => {
  const [
    { isLoading, error },
    dispatch,
  ] = useUnidentifiedReturnsItemsDatastore();
  const alertContext = useContext(GlobalErrorContext);

  const addItemToInventory = async (id: string) => {
    await dispatch('UPDATE', {
      id,
      added_to_inventory: 'yes',
      available: 'no',
    });

    alertContext?.showAlert('Done', 'success');
    await refreshList();
  };

  return (
    <>
      {error && (
        <Typography color="error" align="center">
          {error.text}
        </Typography>
      )}
      <MuiButton fullWidth color={color} onClick={() => addItemToInventory(id)}>
        {isLoading ? <Spinner size={20} /> : title}
      </MuiButton>
    </>
  );
};

export default AddToInventoryItem;
