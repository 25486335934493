import { ReturnBag } from '../../api-clients/types';
import ApiReturnBags from '../../api-clients/api-clients-return-bags';
import { useContext } from 'react';
import { DealContext } from '../../GenericDeal/context/dealContext';

export const useMutateDeal = () => {
  const dealContext = useContext(DealContext);

  const handleUpdateDeal = async (
    id: string,
    data: Partial<Omit<ReturnBag, 'id' | 'positions'>>,
  ) => {
    const updatedDealDTO = await new ApiReturnBags().update(id, data);

    if (!dealContext) return;
    const { setDealState, deal, rawDeal } = dealContext;
    setDealState({
      deal: { ...deal, data },
      rawDeal: { ...rawDeal, updatedDealDTO },
    });
  };

  return {
    handleUpdateDeal,
  };
};
