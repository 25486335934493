import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
}));

/**
 * Just block which helps you make clickable
 * element non clickable. Usage example:
 *
 * <div>
 *    <button></button>
 *    <NonClickable />
 * </div>
 */

const NonClickable: FC = React.memo(() => <div className={useStyles().root} />);

export default NonClickable;
