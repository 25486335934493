import HttpDatastore from 'rollun-ts-datastore';
import { OfferDTO, Offer } from './types';
import { Query, In, And, Eq, Eqn } from 'rollun-ts-rql';

const datastore = new HttpDatastore<OfferDTO>('/api/datastore/OffersDataStore');

export default class ApiClientsOffers {
  async getById(id: string): Promise<Offer> {
    const offerDTO: OfferDTO = await datastore.read(id);

    const offer = this.convertDTOtoModel(offerDTO);
    return offer;
  }

  async getByIds(ids: string[]): Promise<Offer[]> {
    const offersDTO = await datastore.query(
      new Query().setQuery(new In('Id', ids)),
    );

    const offers = offersDTO.map((offerDTO) =>
      this.convertDTOtoModel(offerDTO),
    );
    return offers;
  }

  async getByRID(rid: string): Promise<OfferDTO[]> {
    const offersDTO = await datastore.query(
      new Query().setQuery(
        new And([new Eq('Article', rid), new Eq('ParentId', null)]),
      ),
    );
    return offersDTO;
  }

  async create(offer: Omit<Offer, 'id' | 'parentId'>): Promise<Offer> {
    const originOffer = await this.createOrGetOriginOffer(offer);
    return this.createPersonalOffer(originOffer, offer);
  }

  async update(
    offerId: string,
    offerData: Omit<Offer, 'id' | 'parentId'>,
  ): Promise<void> {
    const offer = await this.getById(offerId);
    const offerDTO = this.convertModelToDTO({
      ...offer,
      ...offerData,
    }) as OfferDTO;

    offerDTO.Id = offerId;
    await datastore.update(offerDTO);
  }

  convertDTOtoModel(offerDTO: OfferDTO): Offer {
    const {
      Id: id,
      Article: article,
      Name: name,
      ParentId: parentId,
    } = offerDTO;

    const offer: Offer = { id, article, name, parentId };
    return offer;
  }

  convertModelToDTO(offer: Omit<Offer, 'id'>): Omit<OfferDTO, 'Id'> {
    return {
      Article: offer.article,
      Name: offer.name,
      ParentId: offer.parentId,
    };
  }

  private async createPersonalOffer(
    originOffer: Offer,
    personalOffer: Omit<Offer, 'id' | 'parentId'>,
  ) {
    const offerDTO = this.convertModelToDTO({
      ...personalOffer,
      parentId: originOffer.id,
    });
    const newOfferDTO = await datastore.create(offerDTO);
    return this.convertDTOtoModel(newOfferDTO);
  }

  private async createOrGetOriginOffer(offer: Omit<Offer, 'id' | 'parentId'>) {
    const originOffer = await datastore.query(
      new Query().setQuery(
        new And([new Eq('Article', offer.article), new Eqn('ParentId')]),
      ),
    );

    if (originOffer.length === 0) {
      const offerDTO = this.convertModelToDTO({
        ...offer,
        parentId: null,
      });
      const newOfferDTO = await datastore.create(offerDTO);
      return this.convertDTOtoModel(newOfferDTO);
    }

    return this.convertDTOtoModel(originOffer[0]);
  }
}

export const apiClientsOffer = new ApiClientsOffers();
