import React, { FC } from 'react';
import { Box, makeStyles, Paper, Theme } from '@material-ui/core';
import { useGetClient } from '../hooks/useGetClient';
import { useParams } from 'react-router-dom';
import ContactInfo from './ContactInfo';
import AddressInfo from './AddressInfo';
import DealTable from './DealTable';
import { Spinner } from '../../../../../UI';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    width: '100%',
    gap: 8,
    padding: 8,
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  inputs: {
    display: 'flex',
    gap: 16,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
}));

const Header: FC = ({}) => {
  const classes = useStyles();

  const { id } = useParams<{ id: string }>();
  const { data } = useGetClient(id);

  console.log(data);

  return data ? (
    <Paper
      elevation={1}
      style={{
        padding: 24,
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        gap: 24,
      }}
    >
      <Box style={{ display: 'flex', gap: 40 }}>
        <Box fontSize={32}>{`id: ${data?.id}`}</Box>
      </Box>
      <Box className={classes.main}>
        <Box flex={3}>
          <DealTable />
        </Box>
        <Box display="flex" flex={1} style={{ gap: 16 }} flexDirection="column">
          <AddressInfo address={(data as any).address} />
          <ContactInfo contactInfo={(data as any).contactInfo} />
        </Box>
      </Box>
    </Paper>
  ) : (
    <Spinner />
  );
};

export default Header;
