import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

interface BpmnDiagramDialogProps {
  isDialogOpen: boolean;
  fileName: string;
  updateExisting: boolean;
  canUpdateExisting: boolean;
  handleUpdateExistingChange: (event: any) => void;
  handleFileNameChange: (event: any) => void;
  handleUpload: () => void;
  handleCloseDialog: () => void;
  isDiagramDsEntity: boolean;
  uploadResult:
    | null
    | {
        success: true;
        type: 'created' | 'updated';
      }
    | {
        success: false;
        message: string;
      };
}

export const BpmnDiagramDialog = (props: BpmnDiagramDialogProps) => {
  const {
    isDialogOpen,
    fileName,
    updateExisting,
    canUpdateExisting,
    handleUpdateExistingChange,
    handleFileNameChange,
    handleUpload,
    handleCloseDialog,
    isDiagramDsEntity,
    uploadResult,
  } = props;

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  useEffect(() => {
    if (uploadResult) {
      setIsSubmitDisabled(false);
    }
  }, [uploadResult]);

  const handleButtonClick = () => {
    setIsSubmitDisabled(true);
    handleUpload();
  };

  return (
    <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
      <DialogTitle>Upload File</DialogTitle>
      <DialogContent>
        <TextField
          label="File Name"
          variant="outlined"
          fullWidth
          value={fileName}
          onChange={handleFileNameChange}
          margin="dense"
        />
        {!!isDiagramDsEntity && (
          <FormControlLabel
            control={
              <Checkbox
                checked={updateExisting}
                onChange={handleUpdateExistingChange}
                color="primary"
                disabled={!canUpdateExisting}
              />
            }
            label="Update existing file"
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleButtonClick}
          color="primary"
          disabled={!fileName || isSubmitDisabled}
        >
          Submit
        </Button>
      </DialogActions>
      {uploadResult && (
        <DialogContent>
          {uploadResult.success ? (
            <p>File {uploadResult.type} successfully!</p>
          ) : (
            <p>Error: {uploadResult.message}</p>
          )}
        </DialogContent>
      )}
    </Dialog>
  );
};
