import React, { useEffect, useMemo, useState } from 'react';
import Card from 'react-bootstrap/Card';
import '../index.css';
import AddUserForm from './AddUserForm';
import SetUserRoles from './SetUserRoles';
import CreateNewUser from './CreateNewUser';
import HttpDatastore from 'rollun-ts-datastore/dist';
import { Query, Select } from 'rollun-ts-rql';
import { Roles, User } from '../types';

const AddNewUser: React.FC = () => {
  const roleDataStore = useMemo(
    () => new HttpDatastore('api/datastore/roleDataStore'),
    [],
  );
  const [submittedUser, setSubmittedUser] = useState(false);
  const [submittedUserRoles, setSubmittedUserRoles] = useState(false);
  const [user, setUser] = useState<User>({
    id: '',
    name: '',
    password: '',
  });
  const [roles, setRoles] = useState<Roles>([]);
  const [initialRoles, setInitialRoles] = useState<string[]>([]);

  const fetchRoles = async () => {
    try {
      const roles = await roleDataStore.query(
        new Query({
          select: new Select(['id']),
        }),
      );
      setInitialRoles(roles.map((role) => role.id));
    } catch (e) {}
  };

  useEffect(() => {
    fetchRoles().then();
  }, []);

  const handleAddUserSubmit = (user: User) => {
    setSubmittedUser(true);
    setUser(user);
  };

  const handleAddUserRolesSubmit = (roles: Roles) => {
    setSubmittedUserRoles(true);
    setRoles(roles);
  };

  const handleSubmit = () => {
    setSubmittedUser(false);
    setSubmittedUserRoles(false);
    setUser({
      id: '',
      name: '',
      password: '',
    });
    setRoles([]);
  };

  return (
    <Card>
      <Card.Header>
        <h1>Add New User</h1>
      </Card.Header>
      <AddUserForm
        onSubmit={handleAddUserSubmit}
        disabled={submittedUser}
        user={user}
      />
      <SetUserRoles
        submitted={submittedUser}
        userRoles={initialRoles}
        handleSubmit={handleAddUserRolesSubmit}
        disabled={submittedUserRoles}
      />
      <CreateNewUser
        submittedUserRoles={submittedUserRoles}
        user={user}
        roles={roles}
        onSubmit={handleSubmit}
      />
    </Card>
  );
};

export default AddNewUser;
