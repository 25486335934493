import React, { FC } from 'react';
import { Slide, Snackbar } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Alert } from '@material-ui/lab';
import { zIndexes } from '../themeProvider';

export type AlertType = 'success' | 'info' | 'warning' | 'error';

interface CustomAlertProps {
  transitionDirection?: 'left' | 'right' | 'up' | 'down';
  isOpen: boolean;
  onClose: () => void;
  alertType?: AlertType;
  msg: string;
  variant?: 'standard' | 'filled' | 'outlined';
  autoHideDuration?: number;
}

const CustomAlert: FC<CustomAlertProps> = ({
  transitionDirection = 'right',
  isOpen,
  onClose,
  alertType = 'success',
  msg,
  variant = 'filled',
  autoHideDuration = 6000,
}) => {
  const SlideTransition = (props: TransitionProps) => (
    <Slide {...props} direction={transitionDirection} />
  );

  return (
    <Snackbar
      style={{
        zIndex: zIndexes.biggest,
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={isOpen}
      TransitionComponent={SlideTransition}
      autoHideDuration={autoHideDuration}
      onClose={() => onClose()}
    >
      <Alert severity={alertType} variant={variant}>
        {msg}
      </Alert>
    </Snackbar>
  );
};

export default CustomAlert;
