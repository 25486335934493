import { ReturnBagItem } from '../../../hooks/datastores';
import { useEffect, useState } from 'react';

export type RidWithFound = {
  id: number;
  rid: string;
  found: 'yes' | 'no';
  problem: string | null;
  inventory: string;
  item_id: number | null;
};
type UseReactiveChangeOfFound = (
  result: ReturnBagItem[] | null,
) => [RidWithFound[], (item: RidWithFound) => void];

const useReactiveChangeOfFound: UseReactiveChangeOfFound = (
  result: ReturnBagItem[] | null,
) => {
  const [ridsWithFound, setRidsWithFound] = useState<RidWithFound[]>([]);

  const changeRidsWithFound = ({
    id,
    rid,
    found,
    problem,
    inventory,
    item_id,
  }: RidWithFound) => {
    const foundItem = ridsWithFound.find((item) => item.rid === rid);

    if (!foundItem) return;

    setRidsWithFound((prevValue) =>
      prevValue.map((item) => {
        if (item.id === id) {
          return {
            id,
            rid,
            found,
            problem,
            inventory,
            item_id,
          };
        }

        return item;
      }),
    );
  };

  useEffect(() => {
    if (!result) return;
    setRidsWithFound(
      result.map(({ rid, found, problem, id, inventory, item_id }) => ({
        rid,
        found,
        problem,
        id,
        inventory,
        item_id,
      })),
    );
  }, [result]);

  return [ridsWithFound, changeRidsWithFound];
};

export { useReactiveChangeOfFound };
