import React, { FC, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from '@material-ui/core';
import MuiIconButton from '../../../UI/MuiIconButton';
import MuiButton from '../../../UI/MuiButton';
import { useCreateSecret } from '../hooks/useCreateSecret';

const CreateSecretDialog: FC = () => {
  const { mutate, isLoading } = useCreateSecret();

  const [open, setOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [value, setValue] = useState<string>('');

  const handleSecretCreate = async () => {
    await mutate({ name, value });
    setOpen(false);
  };

  return (
    <>
      <MuiIconButton
        style={{
          flex: 1,
        }}
        iconName="plus-square"
        color="success"
        onClick={() => setOpen(true)}
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Create Secret</DialogTitle>
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
          }}
        >
          <TextField
            label="Secret name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            name="name"
            variant="outlined"
          />
          <TextField
            label="Secret value"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            name="value"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <MuiButton
            color="success"
            disabled={!name || !value || isLoading}
            onClick={handleSecretCreate}
          >
            Create
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateSecretDialog;
