import React, { useEffect, useMemo, useState } from 'react';
import { Query, Select } from 'rollun-ts-rql';
import HttpDatastore from 'rollun-ts-datastore';
import { UserSelect } from './UserSelect';

export const UsersControl = () => {
  const [initialRoles, setInitialRoles] = useState<string[]>([]);
  const roleDataStore = useMemo(
    () => new HttpDatastore('api/datastore/roleDataStore'),
    [],
  );

  const fetchRoles = async () => {
    try {
      const roles = await roleDataStore.query(
        new Query({
          select: new Select(['id']),
        }),
      );
      setInitialRoles(roles.map((role) => role.id));
    } catch (e) {}
  };

  useEffect(() => {
    fetchRoles().then();
  }, []);

  return <UserSelect rolesList={initialRoles} />;
};
