import React, { FC, useMemo, useState } from 'react';
import { useGetSecrets } from '../hooks/useGetSecrets';
import ErrorView from '../../../UI/ErrorView';
import { Spinner } from '../../../UI/index';
import { Box, TextField } from '@material-ui/core';
import SecretRow from './SecretRow';
import CreateSecretDialog from './CreateSecretDialog';

const SecretManagerContainer: FC = () => {
  const [search, setSearch] = useState<string>('');
  const [selectedSecret, setSelectedSecret] = useState<string | undefined>(
    undefined,
  );
  const { data: secrets, error, isLoading } = useGetSecrets();
  const matchedSecrets = useMemo(
    () =>
      secrets
        ? secrets.filter(({ id }) =>
            id.toLowerCase().includes(search.toLowerCase()),
          )
        : [],
    [search, secrets],
  );

  if (error) {
    return (
      <ErrorView
        error={{
          text: (error as Error).message,
          code: 500,
        }}
      />
    );
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Box
      width="100%"
      padding={1}
      display="flex"
      flexDirection="column"
      style={{
        gap: 8,
      }}
    >
      <Box display="flex">
        <TextField
          style={{
            flex: 30,
          }}
          label="Search"
          variant="outlined"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <CreateSecretDialog />
      </Box>

      {matchedSecrets.map(({ id, version }) => (
        <SecretRow
          id={id}
          version={version}
          onClick={(id) => setSelectedSecret(id)}
          selected={selectedSecret === id}
        />
      ))}
    </Box>
  );
};

export default SecretManagerContainer;
