import React, { FC } from 'react';
import NonClickable from '../../../../../UI/NonClickable';
import { Box, Typography, makeStyles, Theme } from '@material-ui/core';
import { Card } from '../../../../../UI';
import { PlusMinusIcon } from './AddableIconButton';
import { TableHeaderButton } from '../../../../AbstractService';
import headerButtons, {
  HeaderButton,
} from '../../../../Table/components/TableHeaderButtons/buttons';

interface IProps {
  buttons?: TableHeaderButton[];
  updateByPath: (name: string, newValue: any) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0, 1),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
}));

const TableHeaderButtons: FC<IProps> = ({ buttons, updateByPath }) => {
  const classes = useStyles();

  const updateHeaderButtonsConfig = (
    buttonName: TableHeaderButton['name'],
    isButtonInConfig: boolean,
  ) => {
    const absolutePath = 'appParams.gridParams.tableHeaderButtons';
    let newButtonsConfig = buttons ?? [];

    isButtonInConfig
      ? (newButtonsConfig = newButtonsConfig.filter(
          (button) => button.name !== buttonName,
        ))
      : newButtonsConfig.push({ name: buttonName });

    updateByPath(absolutePath, newButtonsConfig);
  };

  const renderButton = (headerButton: HeaderButton) => {
    const isButtonInConfig = Array.isArray(buttons)
      ? buttons.some((button) => button.name === headerButton.name)
      : false;

    return (
      <Box position="relative" mr={3}>
        {React.createElement(headerButton.component)}
        <Box display="flex" justifyContent="flex-end" pr={1.5}>
          <PlusMinusIcon
            onUpdate={() =>
              updateHeaderButtonsConfig(headerButton.name, isButtonInConfig)
            }
            value={isButtonInConfig}
          />
        </Box>
        <NonClickable />
      </Box>
    );
  };

  return (
    <Card noMargin className={classes.root}>
      <Typography variant="h5" className={classes.title}>
        Header buttons:
      </Typography>
      <Box display="flex">{headerButtons.map(renderButton)}</Box>
    </Card>
  );
};

export default TableHeaderButtons;
