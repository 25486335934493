import React, { FC } from 'react';
import ConfirmButtonMui from '../../../UI/ConfirmButtonMui';
import { RidWithFound, useSubmitItem } from '../hooks';
import { Box, Grid, LinearProgress, useTheme } from '@material-ui/core';

interface SubmitItemProps {
  itemId: number;
  rid: string;
  item: RidWithFound;
  changeItemFound: (item: RidWithFound) => void;
}

const SubmitItem: FC<SubmitItemProps> = ({
  itemId,
  rid,
  changeItemFound,
  item,
}) => {
  const [{ status }, submitItem] = useSubmitItem();
  const theme = useTheme();

  const handleClick = (id: number, rid: string, found: 'yes' | 'no') => {
    changeItemFound({
      id,
      rid,
      found,
      problem: found === 'no' ? 'Could not find item' : null,
      inventory: item.inventory,
      item_id: item.item_id,
    });
    submitItem(id, rid, found);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box width="100%" display="flex" style={{ gap: theme.spacing(1) }}>
          <ConfirmButtonMui
            buttonText="Found"
            confirmationHeader="Are you sure you found this item"
            color="success"
            fullWidth
            disabled={status === 'loading'}
            onConfirm={() => handleClick(itemId, rid, 'yes')}
          />
          <ConfirmButtonMui
            buttonText="Not found"
            confirmationHeader="Are you sure you did not found this item"
            color="error"
            fullWidth
            disabled={status === 'loading'}
            onConfirm={() => handleClick(itemId, rid, 'no')}
          />
        </Box>
      </Grid>
      {status === 'loading' && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}
    </Grid>
  );
};

export default SubmitItem;
