import { BagFillingPagesEnum } from '../BagFillingPages';
import { useQueryParamState } from '../../../hooks/useQueryParamState';
import { BagFillingContextProps } from '../BagFillingContext';

type GoToNextPage = () => void;
type GoToPreviousPage = (context: Partial<BagFillingContextProps>) => void;
type GoToPage = (page: BagFillingPagesEnum) => void;

const useProgramPages = () => {
  const [currentPage, setCurrentPage] = useQueryParamState<BagFillingPagesEnum>(
    'page',
    BagFillingPagesEnum.SetLocation,
  );

  const goToPage: GoToPage = (page: BagFillingPagesEnum) => {
    setCurrentPage(page);
  };

  const goToNextPage: GoToNextPage = () => {
    // not implemented yet
    setCurrentPage(BagFillingPagesEnum.BagsList);
  };
  const goToPreviousPage: GoToPreviousPage = (
    context: Partial<BagFillingContextProps>,
  ) => {
    if (currentPage === BagFillingPagesEnum.BagPreview) {
      context.setBagId?.('');
      return goToPage(BagFillingPagesEnum.BagsList);
    }

    if (currentPage === BagFillingPagesEnum.BagsList) {
      context.setStorage?.('');
      return goToPage(BagFillingPagesEnum.SetLocation);
    }
  };

  return {
    currentPage,
    goToPage,
    goToNextPage,
    goToPreviousPage,
  };
};

export { useProgramPages };
export type { GoToPage, GoToNextPage, GoToPreviousPage };
