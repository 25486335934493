import React, { useEffect, useMemo, useState } from 'react';
import { clearBrowserCache, scrollToTop } from '../utils/common.utils';
import { copyToClipboard } from 'rollun-ts-utils/dist';
import {
  BottomNavigation,
  Box,
  IconButton,
  Link,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import MuiButton from '../UI/MuiButton';
import DeleteIcon from '@material-ui/icons/Delete';

export interface FooterProps {
  currentResourceName?: string;
  // snowAmount: number;
  // onSnowAmountChange: (snowAmount: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    position: 'fixed',
    bottom: 0,
    height: 50,
  },
  resources: {
    gap: theme.spacing(1),
    marginLeft: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down(932)]: {
      flexDirection: 'column',
    },
  },
  version: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      margin: theme.spacing(0, 1),
    },
  },
}));

// const AirbnbSlider = withStyles({
//   root: {
//     color: '#3a8589',
//     height: 3,
//     padding: '13px 0',
//   },
//   thumb: {
//     height: 27,
//     width: 27,
//     backgroundColor: '#fff',
//     marginTop: -12,
//     marginLeft: -13,
//     boxShadow: '#ebebeb 0 2px 2px',
//     '&:focus, &:hover, &$active': {
//       boxShadow: '#ccc 0 2px 3px 1px',
//     },
//     '& .bar': {
//       // display: inline-block !important;
//       height: 9,
//       width: 1,
//       backgroundColor: 'currentColor',
//       marginLeft: 1,
//       marginRight: 1,
//     },
//   },
//   active: {},
//   track: {
//     height: 3,
//   },
//   rail: {
//     color: '#d8d8d8',
//     opacity: 1,
//     height: 3,
//   },
// })(Slider);
//
// const AirbnbThumbComponent = (props: any) => (
//   <span {...props}>
//     <span>
//       <AcUnitIcon />
//     </span>
//   </span>
// );

const Footer: React.FC<FooterProps> = ({
  currentResourceName,
  // onSnowAmountChange,
  // snowAmount,
}) => {
  const classes = useStyles();
  // const [currentSnowAmount, setCurrentSnowAmount] = useState(snowAmount);
  const [width, setWidth] = useState(window.innerWidth);
  const isTablet = useMemo(() => width < 932, [width]);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const hardReload = () => {
    clearBrowserCache();
    window.location.reload();
  };

  // const changeSnow = useCallback(
  //   _.debounce((snowAmount) => {
  //     onSnowAmountChange(snowAmount);
  // setCurrentSnowAmount(snowAmount);
  // }, 300),
  // [],
  // );

  const version = process.env.REACT_APP_VERSION || '0.0.0';
  return (
    <BottomNavigation className={classes.root}>
      <Box display="flex">
        <MuiButton
          onClick={() => hardReload()}
          title="hard reload"
          icon={{
            name: 'sync-alt',
            size: 'sm',
          }}
        />
        <Box className={classes.resources}>
          {/*<AirbnbSlider*/}
          {/*  ThumbComponent={AirbnbThumbComponent}*/}
          {/*  value={currentSnowAmount}*/}
          {/*  onChange={(e, number) => {*/}
          {/*    setCurrentSnowAmount(number as number);*/}
          {/*    changeSnow(number);*/}
          {/*  }}*/}
          {/*  style={{ width: '250px' }}*/}
          {/*  valueLabelDisplay="auto"*/}
          {/*  step={25}*/}
          {/*  marks*/}
          {/*  min={0}*/}
          {/*  max={750}*/}
          {/*/>*/}
          {!isTablet && currentResourceName && (
            <>
              <Typography variant="body1">
                Resource:{' '}
                <Link
                  color="primary"
                  href={`/service-constructor?action=edit_service&resource=${currentResourceName}`}
                >
                  {currentResourceName}
                </Link>
              </Typography>
              <MuiButton
                onClick={() => copyToClipboard(currentResourceName || '')}
                icon={{
                  name: 'copy',
                }}
              />
              <IconButton
                href={`/service-constructor?action=delete_service&resource=${currentResourceName}`}
                size="medium"
              >
                <DeleteIcon
                  style={{
                    color: 'darkred',
                    width: '25px',
                    height: '25px',
                  }}
                />
              </IconButton>
            </>
          )}
        </Box>
      </Box>
      <Box className={classes.version}>
        <Typography variant="body1">
          Rollun LC © 2014-{new Date().getFullYear()}
        </Typography>
        <Typography variant="body1">{version}</Typography>
        <MuiButton onClick={scrollToTop} icon={{ name: 'arrow-up' }} />
      </Box>
    </BottomNavigation>
  );
};

export default Footer;
