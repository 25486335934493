import React, { PureComponent, ReactNode } from 'react';
import FilterEditor from './FilterEditor/FilterEditor';
import Dialog from '../../../../UI/Dialog';
import Query from 'rollun-ts-rql/dist/Query';
import MuiIconButton from '../../../../UI/MuiIconButton';

export interface QueryEditorInModalProps {
  query: Query;
  fieldNames: string[];
  onApplyQuery: (query: Query) => void;
  isDisabled?: boolean;
  appName: string;
  idField?: string;
}

interface IState {
  isOpen: boolean;
}

export default class FilterEditorInModal extends PureComponent<QueryEditorInModalProps> {
  state: IState = {
    isOpen: false,
  };

  render(): ReactNode {
    return (
      <>
        <Dialog
          isOpen={this.state.isOpen}
          title="Filter Editor"
          options={{ centered: true, size: 4 }}
          onClose={() => this.handleDialogClose()}
        >
          <FilterEditor
            appName={this.props.appName}
            onApplyQuery={(query: Query) => {
              this.handleDialogClose();
              this.props.onApplyQuery(query);
            }}
            fieldNames={this.props.fieldNames}
            query={this.props.query}
            idField={this.props.idField}
          />
        </Dialog>
        <MuiIconButton
          title="set filter"
          color="primary"
          width={40}
          height={40}
          iconName="filter"
          disabled={this.props.isDisabled}
          onClick={() => {
            this.handleDialogOpen();
          }}
        />
      </>
    );
  }

  handleDialogClose() {
    this.setState({ isOpen: false });
  }

  handleDialogOpen() {
    this.setState({ isOpen: true });
  }
}
