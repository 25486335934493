import { Box, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { FC } from 'react';
import MuiButton from '../../../UI/MuiButton';

interface ModalDialogProps {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  onConfirm: () => void;
  onDecline: () => void;
}

export const ModalDialog: FC<ModalDialogProps> = (props) => {
  const { isModalOpen, setIsModalOpen, onConfirm, onDecline } = props;

  return (
    <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <DialogTitle>Did you apply sticker?</DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          style={{
            gap: 8,
          }}
        >
          <MuiButton
            color="success"
            style={{
              color: '#fff',
            }}
            onClick={onConfirm}
          >
            Yes
          </MuiButton>
          <MuiButton color="error" onClick={onDecline}>
            No
          </MuiButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
