import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import '../index.css';
import { Button } from '../../../UI';
import { Roles } from '../types';

interface IProps {
  submitted: boolean;
  userRoles: string[];
  handleSubmit: (roles: Roles) => void;
  disabled: boolean;
}

const SetUserRoles: React.FC<IProps> = ({
  submitted,
  userRoles,
  handleSubmit,
  disabled,
}) => {
  const [roles, setRoles] = useState<Roles>([]);

  const handleSubmitRoles = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (roles.length === 0) {
      return;
    }

    handleSubmit(roles);
  };

  return submitted ? (
    <Card className="inner-card popup-animation test">
      <Card.Header>
        <h5>Choose roles for user</h5>
      </Card.Header>
      <Card.Body>
        <div className="form-wrapper">
          <div className="add-user-roles">
            <Form className="add-user-roles" onSubmit={handleSubmitRoles}>
              {userRoles.map((role) => (
                <div className="outer-checkbox" key={role}>
                  <Form.Check
                    label={role}
                    id={`${role}`}
                    disabled={disabled}
                    checked={roles.find((el) => el.role === role)?.checked}
                    onChange={(e: any) => {
                      if (!!roles.find((el) => el.role === role)) {
                        const newArr = roles.map((el) =>
                          el.role === role
                            ? { role, checked: e.target.checked }
                            : el,
                        );
                        setRoles(newArr);
                        return;
                      }

                      setRoles((roles) => [...roles, { role, checked: true }]);
                    }}
                    className={'checkbox-text'}
                  />
                </div>
              ))}

              <Button type="submit" disabled={disabled} color="primary">
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </Card.Body>
    </Card>
  ) : null;
};

export default SetUserRoles;
