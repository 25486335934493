import React, { PureComponent } from 'react';
import { WithStyles } from '@material-ui/core';

export interface IEditorProps extends WithStyles {
  value: string;

  column: { key: string };

  onCommitCancel(): void;

  onCommit(value: string): void;

  onOverrideKeyDown(e: React.KeyboardEvent): void;
}

export default abstract class AbstractEditor<S = unknown> extends PureComponent<
  IEditorProps,
  S
> {
  // should return an object of key/value pairs to be merged back to the row
  abstract getValue(): { [key: string]: any };

  // Use this ref for Your input to enable autofocus.
  inputRef = React.createRef<HTMLInputElement>();
  // If applicable, should return back the primary html input node that is used to edit the data
  // Otherwise return null
  // If value is an input node, then this node will be focussed on when the editor opens
  // Return undefined, to disable input ref behavior at all
  getInputNode(): HTMLInputElement | null | undefined {
    return this.inputRef.current;
  }
}
