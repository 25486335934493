import React, { FC, useContext, useRef, useState } from 'react';
import { MenuItem } from '../../AppContainer/AppContainer';
import 'semantic-ui-css/semantic.min.css';
import { ServiceInfo } from '../../../pages/RootApp/Root.app';
import InputWithSelect from '../../../UI/InputWithSelect';
import {
  Box,
  makeStyles,
  Menu,
  Theme,
  MenuItem as MenuItemComponent,
  Typography,
  useTheme,
} from '@material-ui/core';
import NestedMenuItem from './NestedMenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Spinner } from '../../../UI';
import LinkComponent from '../LinkComponent';

export interface IProps {
  loading: boolean;
  services: { [key: string]: ServiceInfo };
}

/**
 * Nav for left and top menus
 */

type NestedDropdownMenu = {
  label: string;
  subMenus: MenuItem[];
};

const NestedMenuContext = React.createContext<{
  closeMenu: () => void;
  anchorEl: null | HTMLElement;
}>({
  closeMenu: () => {
    return;
  },
  anchorEl: null,
});

const useStyles = makeStyles((theme: Theme) => ({
  topNav: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
}));

const MuiSelect: FC<IProps> = ({ services, loading }) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="row" className={classes.topNav}>
      {loading ? (
        <Spinner size={20} />
      ) : (
        <>
          <InputWithSelect
            options={Object.entries(services)
              .filter(([key]) => key !== window.location.pathname)
              .map(([key, value]) => ({
                value: key,
                label: value.name,
              }))}
            value=""
            label="Search"
          />
        </>
      )}
    </Box>
  );
};

// put a forward ref, because of some error
const ItemWithSubmenus: FC<NestedDropdownMenu> = React.forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ label, subMenus }, ref) => {
    const dropdownMenuContext = useContext(NestedMenuContext);

    return (
      <NestedMenuItem
        key={label}
        label={label}
        parentMenuOpen={!!dropdownMenuContext.anchorEl}
        onClick={dropdownMenuContext.closeMenu}
      >
        {subMenus
          .sort((item1, item2) => item1.label.localeCompare(item2.label))
          .map((menu) => {
            if (menu.subMenus) {
              return (
                <ItemWithSubmenus
                  key={menu.label}
                  subMenus={menu.subMenus}
                  label={menu.label}
                />
              );
            }
            const { url, label } = menu;
            return (
              <MenuItemComponent key={label}>
                <LinkComponent link={url}>{label}</LinkComponent>
              </MenuItemComponent>
            );
          })}
      </NestedMenuItem>
    );
  },
);

const NestedDropdownMenu: FC<NestedDropdownMenu> = ({ label, subMenus }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const lastHoverTimeoutId = useRef<number | null>(null);
  const theme = useTheme();

  const handleHover = (el: HTMLElement | null, hover: boolean) => {
    if (hover) {
      // clear scheduled close dropdown after time, if mouse returned to dd.
      if (lastHoverTimeoutId.current) {
        clearTimeout(lastHoverTimeoutId.current);
        lastHoverTimeoutId.current = null;
      }

      setAnchorEl(el);
      return;
    }

    // add a little delay before close after mouse out, to prevent accidental leave from dropdown
    lastHoverTimeoutId.current = window.setTimeout(() => {
      setAnchorEl(null);
    }, 150);
  };

  return (
    <Box color={theme.palette.primary.main}>
      <Box
        display="flex"
        alignItems="center"
        onMouseEnter={(e) => handleHover(e.currentTarget, true)}
      >
        <Typography variant="h6">{label}</Typography>
        <ArrowDropDownIcon />
      </Box>
      <NestedMenuContext.Provider
        value={{
          closeMenu: () => handleHover(null, false),
          anchorEl,
        }}
      >
        <Menu
          open={!!anchorEl}
          keepMounted
          onClose={() => handleHover(null, false)}
          anchorEl={anchorEl}
          MenuListProps={{
            onMouseEnter: (e) => handleHover(e.currentTarget, true),
            onMouseLeave: () => handleHover(null, false),
          }}
        >
          <ItemWithSubmenus label={label} subMenus={subMenus} />
        </Menu>
      </NestedMenuContext.Provider>
    </Box>
  );
};

export const NestedMenu: FC<{ item: MenuItem }> = ({ item }) => {
  const { label, url, subMenus } = item;
  if (subMenus && subMenus.length !== 0) {
    return <NestedDropdownMenu subMenus={subMenus} label={label} />;
  }
  if (label && url) {
    return <LinkComponent link={url}>{label}</LinkComponent>;
  }

  throw new Error('Invalid config for TopMenu');
};

export default MuiSelect;
