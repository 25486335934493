import React, { FC, Suspense, lazy, useState } from 'react';
import MuiButton from '../../../../../../UI/MuiButton';
import HowToReturnContext from '../GenericHowToReturn/context/context';

const HowToReturnDialog = lazy(
  () => import('../GenericHowToReturn/components/HowToReturnDialog'),
);

interface HowToReturnProps {
  dataStoreUrl?: string;
  defaultQuery?: string;
}

const HowToReturnV3: FC<HowToReturnProps> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  return (
    <>
      <MuiButton
        size="xs"
        color="primary"
        onClick={() => {
          setIsOpen(true);
          setIsMounted(true);
        }}
      >
        How to return V3
      </MuiButton>
      {isMounted && (
        <HowToReturnContext.Provider
          value={{
            version: 'v3',
            basePath: '/api/openapi/ReturnsModule/v3',
          }}
        >
          <Suspense fallback={''}>
            <HowToReturnDialog
              onClose={() => setIsOpen(false)}
              isOpen={isOpen}
            />
          </Suspense>
        </HowToReturnContext.Provider>
      )}
    </>
  );
};

export default HowToReturnV3;
