import { IconButton, TextField } from '@material-ui/core';
import React, { FC, useMemo, useRef, useState } from 'react';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { isJSON, isJSONObject } from 'rollun-ts-utils';
import { JSONataInteropControl } from '../../../../Table/components/JSONataInterop/JSONataInteropControl';

interface CopiedTextFieldProps {
  defaultValue: string;
  isObject: boolean;
  label: string;
  name: string;
  disabled: boolean;
  size: 'medium' | 'small';
  variant: 'filled' | 'outlined' | 'standard';
  onBlur?: (e: any) => Promise<void>;
  onCopyAlert?: () => void;
  multiline?: boolean;
}

export const CopiedTextField: FC<CopiedTextFieldProps> = ({
  defaultValue,
  isObject,
  label,
  name,
  disabled,
  size,
  variant,
  onBlur,
  onCopyAlert,
  multiline,
}) => {
  const [showCopyIcon, setShowCopyIcon] = useState(false);
  const textfieldRef = useRef<HTMLInputElement>(null);
  const [isJsonataOpen, setIsJsonataOpen] = useState(false);
  const isJSONValue = useMemo(() => {
    const isObject = defaultValue && typeof defaultValue === 'object';
    return isObject || isJSONObject(defaultValue);
  }, [defaultValue]);

  const handleMouseMove = () => {
    setShowCopyIcon(true);
  };

  const handleMouseLeave = () => {
    setShowCopyIcon(false);
  };

  const handleCopy = () => {
    const value = multiline
      ? (textfieldRef?.current?.querySelector?.(
          'textarea[aria-invalid="false"]',
        ) as HTMLTextAreaElement)?.innerHTML
      : (textfieldRef?.current?.querySelector?.('input') as HTMLInputElement)
          ?.value;
    if (!value) return;
    navigator.clipboard.writeText(value);
    onCopyAlert?.();
  };

  return (
    <TextField
      ref={textfieldRef}
      defaultValue={isObject ? JSON.stringify(defaultValue) : defaultValue}
      label={label}
      name={name}
      multiline={multiline}
      onBlur={onBlur}
      InputProps={{
        endAdornment: (
          <>
            {isJSONValue && (
              <JSONataInteropControl
                data={
                  isJSON(defaultValue) ? JSON.parse(defaultValue) : defaultValue
                }
                isVisible={showCopyIcon}
                open={isJsonataOpen}
                setOpen={setIsJsonataOpen}
                iconWidth={36}
                iconHeight={36}
                width={21}
                height={21}
              />
            )}
            {showCopyIcon && (
              <IconButton
                onClick={handleCopy}
                style={{
                  visibility: showCopyIcon ? 'visible' : 'hidden',
                  height: 36,
                  width: 36,
                }}
              >
                <FileCopyIcon />
              </IconButton>
            )}
          </>
        ),
      }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      size={size}
      variant={variant}
      disabled={disabled}
    />
  );
};
