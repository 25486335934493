import { useReturnBagItems } from '../../../hooks/datastores';
import { HttpDatastoreStatus } from '../../../hooks/useHttpDatastore';

type UseSubmitItem = () => [
  {
    status: HttpDatastoreStatus;
  },
  (itemId: number, rid: string, found: 'yes' | 'no') => void,
];

const useSubmitItem: UseSubmitItem = () => {
  const [{ status }, dispatch] = useReturnBagItems();

  const submitItem = async (
    itemId: number,
    rid: string,
    found: 'yes' | 'no',
  ) => {
    await dispatch('UPDATE', {
      id: itemId,
      found,
      problem: found === 'no' ? `Could not find item: ${rid}` : null,
    });
  };

  return [{ status }, submitItem];
};

export { useSubmitItem };
