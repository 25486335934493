import React, { Component } from 'react';
import { IEditorProps, IEditorState } from '../EditService';
import HttpDatastore from 'rollun-ts-datastore/dist';
import { FrontConfigRow } from '../../../../RootApp/Root.app';
import { Button } from '../../../../../UI';
import _ from 'lodash/fp';
import { AppConfig } from '../../../../AbstractService';
import WebHooksEdit from '../grid/WebHooks.edit';

class ControlCenter extends Component<IEditorProps, IEditorState> {
  constructor(props: IEditorProps) {
    super(props);
    this.state = {
      config: props.config,
      isLoading: false,
      isError: false,
    };
  }

  ROOT_PARAMS_PROP_NAME = 'ControlCenterParams';

  servicesConfigDS = new HttpDatastore<FrontConfigRow>(
    '/api/datastore/frontConfigDataStore',
  );

  updateByPath = (path: string, newValue: any) => {
    this.setState({
      config: _.update(path)(() => newValue)(this.state.config),
    });
  };

  deleteByPath = (path: string) => {
    this.setState({ config: _.unset(path)(this.state.config) });
  };

  handleConfigSave = (newConfig: AppConfig) => {
    this.setState({ isLoading: true, isError: false });
    this.servicesConfigDS
      .update({ id: this.props.serviceID, config: newConfig })
      .catch((err) => {
        console.log('err', err);
        this.setState({ isError: true });
      })
      .finally(() => this.setState({ isLoading: false }));
  };

  render() {
    const { config, isLoading, isError } = this.state;
    const params: any = (config.appParams as any)[this.ROOT_PARAMS_PROP_NAME];
    if (!params) {
      return (
        <h3>
          Couldn't find {this.ROOT_PARAMS_PROP_NAME} field in appParams in
          config.
        </h3>
      );
    }

    return (
      <div>
        <div className="d-flex my-2 justify-content-between">
          <h3>Table edit</h3>
          <Button
            color={isError ? 'danger' : 'primary'}
            disabled={isLoading}
            onClick={() => this.handleConfigSave(config)}
          >
            Save
          </Button>
        </div>
        <WebHooksEdit
          hooks={params.hooks}
          onHooksUpdate={(hooks, path?) => {
            const absolutePath = `appParams.${
              this.ROOT_PARAMS_PROP_NAME
            }.hooks${path || ''}`;
            hooks
              ? this.updateByPath(absolutePath, hooks)
              : this.deleteByPath(absolutePath);
          }}
        />
      </div>
    );
  }
}

export default ControlCenter;
