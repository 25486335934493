import React, { FC } from 'react';
import { In, Query } from 'rollun-ts-rql';
import { FluidFormField } from '../../../../UI/FluidForm';
import DeleteItemDialog from '../../../../forms/DeleteItemDialog';
import NewItemCreatorDialog from '../../../../forms/NewItemCreatorDialog';
import {
  makeStaticCDNPath,
  noop,
  ProgressCallback,
} from '../../../../utils/common.utils';
import { LoadingStatusEnum } from '../../../../utils/common.types';
import DataDownloader from '../FilterEditor/DataDownloader';
import CopyPasteUploader, {
  CopyPasteUploaderParams,
} from '../CopyPasteUploaders/CopyPasteUploader';
import DownloadFullTable from '../FilterEditor/DownloadFullTable';
import { setQueryNode } from '../../../../utils/query.utils';
import { OnUploadData } from './TableWithFilter';
import FilterEditorInModal from '../FilterEditor/FilterEditorInModal';
import TagsUpdater from '../../../../UI/TagsUpdater';
import Tags from '../../../../UI/Tags';
import { ColumnsConfig } from '../../util/config.utils';
import Card from '@material-ui/core/Card';
import { makeStyles, Theme, IconButton, Box } from '@material-ui/core';
import MuiIconButton from '../../../../UI/MuiIconButton';
import { ResetFilter } from '../../../../controls/ResetFilter/ResetFilter';
import { TagsUpdaterParams } from '../../../AbstractService';
import { JSONataInteropControl } from '../JSONataInterop/JSONataInteropControl';
import MuiButton from '../../../../UI/MuiButton';

export interface QueryAppControlsProps {
  deleteOptions: {
    all?: boolean;
    one?: boolean;
    handler(progressCallback?: ProgressCallback): void;
  };
  loadingStatus: LoadingStatusEnum;
  columnsConfig?: ColumnsConfig;
  query: Query;
  formConfig?: FluidFormField[];
  fieldNames: Array<string>;
  getCurrentData: () => Array<any>;
  pasteUploaderParams?: CopyPasteUploaderParams;
  oldPasteUploader?: boolean;
  handleCreate?: OnUploadData;
  datastoreUrl: string;
  appName?: string;
  userNote?: string;
  toggleUserNote?: (() => void) | null;
  idField?: string;
  tagsUpdaterParams?: TagsUpdaterParams;

  onChangeQuery(query: Query): void;

  onItemDelete(): void;

  onItemCreate(data: Record<string, unknown>): void;

  toggleNodeRedInterop?(): void;

  clearQuery(): void;

  reloadData(): void;

  submitCLDV3Item?: false | (() => void);

  copyDataToClipboard: (withHeaders?: boolean) => void;

  openInBpmnEditor?: false | (() => void);
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0.5, 1),
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    [theme.breakpoints.down(800)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  controls: {
    [theme.breakpoints.down(800)]: {
      justifyContent: 'center',
    },
  },
}));

const TableControls: FC<QueryAppControlsProps> = ({
  deleteOptions,
  query,
  pasteUploaderParams,
  handleCreate,
  getCurrentData,
  datastoreUrl,
  formConfig = [],
  userNote,
  oldPasteUploader,
  toggleNodeRedInterop,
  idField = 'id',
  fieldNames,
  onChangeQuery,
  loadingStatus,
  reloadData,
  onItemCreate,
  onItemDelete,
  clearQuery,
  toggleUserNote,
  appName,
  tagsUpdaterParams = {
    enableTagsUpdater: false,
  },
  columnsConfig,
  submitCLDV3Item,
  copyDataToClipboard,
  openInBpmnEditor,
}) => {
  const classes = useStyles();

  const defaultFormConfig = fieldNames.map((field: string) => ({
    field,
  }));
  const formConfigMerged = defaultFormConfig.map((conf) => {
    if (columnsConfig?.[conf.field]) {
      conf = { ...columnsConfig[conf.field], ...conf };
    }

    const overriddenConf = formConfig.find((fc) => fc.field === conf.field);
    if (overriddenConf) {
      if (columnsConfig?.[conf.field])
        return { ...columnsConfig[conf.field], ...overriddenConf };

      return overriddenConf;
    }
    return conf;
  });
  const isDisabled = loadingStatus === LoadingStatusEnum.loading;

  return (
    <>
      <Card elevation={1} classes={{ root: classes.root }}>
        <Box width="100%" display="flex" className={classes.controls}>
          <FilterEditorInModal
            appName={datastoreUrl}
            query={query}
            fieldNames={fieldNames}
            onApplyQuery={(query) => onChangeQuery(query)}
            isDisabled={isDisabled}
            idField={idField}
          />
          <MuiIconButton
            title="refresh data"
            color="primary"
            width={40}
            height={40}
            iconName="sync-alt"
            disabled={isDisabled}
            onClick={reloadData}
          />
          <NewItemCreatorDialog
            formConfig={formConfigMerged}
            isDisabled={isDisabled}
            onFormSubmit={(data) => {
              onItemCreate(data);
            }}
          />
          {deleteOptions.one && (
            <DeleteItemDialog
              isDisabled={isDisabled}
              title={'Delete one selected item'}
              body={"This action can't be undone! Are You sure?"}
              icon="trash-alt"
              onFormSubmit={onItemDelete}
            />
          )}
          {deleteOptions.all && (
            <DeleteItemDialog
              isDisabled={isDisabled}
              title="Delete items by filter"
              body="This action can't be undone! Are You sure?"
              icon="radiation-alt"
              onFormSubmit={(progressCallback?) =>
                deleteOptions.handler(progressCallback)
              }
            />
          )}
          {pasteUploaderParams && handleCreate && (
            <CopyPasteUploader
              params={pasteUploaderParams}
              isDisabled={isDisabled}
              isMultiCreateSupported={
                !(
                  oldPasteUploader === true ||
                  pasteUploaderParams.isLegacy === true
                )
              }
              handleCreateItems={handleCreate}
            />
          )}
          {copyDataToClipboard && (
            <MuiIconButton
              color="info"
              title="copy current datastore data to clipboard"
              width={40}
              height={40}
              iconName="copy"
              disabled={isDisabled}
              onClick={() => copyDataToClipboard()}
            />
          )}
          {copyDataToClipboard && (
            <MuiIconButton
              title="copy current datastore data with headers to clipboard"
              width={40}
              height={40}
              iconName="copy"
              disabled={isDisabled}
              onClick={() => copyDataToClipboard(true)}
            />
          )}
          <DownloadFullTable appName={appName} datastoreUrl={datastoreUrl} />
          <DataDownloader
            isDisabled={isDisabled}
            type="CSV"
            getData={getCurrentData}
          />
          <DataDownloader
            isDisabled={isDisabled}
            type="JSON"
            getData={getCurrentData}
          />
          {toggleNodeRedInterop && (
            <IconButton
              disabled={isDisabled}
              onClick={isDisabled ? noop : toggleNodeRedInterop}
              style={{
                width: 40,
                height: 40,
              }}
              title="toggle node red interop"
            >
              <img
                style={{
                  borderRadius: '100%',
                }}
                src={makeStaticCDNPath('/images/node-red-button.ico')}
                alt="node-red"
                width={20}
                height={20}
              />
            </IconButton>
          )}
          <JSONataInteropControl
            isVisible
            width={20}
            height={20}
            isDisabled={isDisabled}
            data={getCurrentData()}
            title="open JSONata for current table data"
          />
          {tagsUpdaterParams.enableTagsUpdater && (
            <TagsUpdater
              datastoreURL={datastoreUrl}
              fieldNames={fieldNames}
              idField={idField}
              query={query}
              stringifyTags={!tagsUpdaterParams?.parsedFormat}
            />
          )}
          {toggleUserNote && (
            <MuiIconButton
              title={userNote || 'Empty'}
              color="info"
              width={40}
              height={40}
              iconName="info-circle"
              onClick={toggleUserNote}
            />
          )}
          {submitCLDV3Item && (
            <MuiButton
              title="submit item cldv3"
              color="success"
              onClick={submitCLDV3Item}
            >
              Create
            </MuiButton>
          )}
          {openInBpmnEditor && (
            <MuiButton
              title="Open in bpmn editor"
              color="primary"
              disabled={isDisabled}
              onClick={openInBpmnEditor}
            >
              Open in bpmn editor
            </MuiButton>
          )}
        </Box>
        <Box display="flex">
          {/* Use custom search bar for Tags table */}
          {window.location.pathname === '/Tags' ? (
            <Box minWidth={250}>
              <Tags
                onChange={(tags) => {
                  onChangeQuery(
                    setQueryNode(
                      tags.length > 0 ? new In('id', tags) : undefined,
                      query,
                    ),
                  );
                }}
                placeholder="Start typing to search, or fast-create tag"
              />
            </Box>
          ) : (
            <ResetFilter
              onCancelSearch={clearQuery}
              loadingStatus={loadingStatus}
            />
          )}
        </Box>
      </Card>
    </>
  );
};

export default TableControls;
