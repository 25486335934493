import { useQuery } from 'react-query';
import { Query, Or, Eq, Eqn, Not, And } from 'rollun-ts-rql';
import HttpDatastore from 'rollun-ts-datastore';
import {
  BagDTO,
  DropshipDTO,
  OrderDTO,
  PickupDTO,
} from '../../api-clients/types';

const GET_PROBLEM_DEALS_KEY = 'getProblemDeals';

const ordersDatastore = new HttpDatastore<OrderDTO>(
  '/api/datastore/OrdersDataStore',
);
const bagsDatastore = new HttpDatastore<BagDTO>('/api/datastore/BagsDataStore');
const dropshipsDatastore = new HttpDatastore<DropshipDTO>(
  '/api/datastore/DropshipsDataStore',
);
const pickupsDatastore = new HttpDatastore<DropshipDTO>(
  '/api/datastore/PickupsDataStore',
);

interface ErrorInfo {
  id: string;
  problem: string;
}

type DealsType = 'bag' | 'order' | 'pickup' | 'dropship';

interface ErrorInformer {
  dropship: ErrorInfo[];
  order: ErrorInfo[];
  bag: ErrorInfo[];
  pickup: ErrorInfo[];
}

export const useGetProblemDeals = () => {
  return useQuery(
    GET_PROBLEM_DEALS_KEY,
    async () => {
      const problemDeals = new ProblemDeals();
      return await problemDeals.getProblemDeals();
    },
    {
      retry: 0,
    },
  );
};

class ProblemDeals {
  private amazonShoptimistic: ErrorInformer = {
    dropship: [],
    order: [],
    bag: [],
    pickup: [],
  };
  private ebayRollun: ErrorInformer = {
    dropship: [],
    order: [],
    bag: [],
    pickup: [],
  };
  private ebayPlaisir: ErrorInformer = {
    dropship: [],
    order: [],
    bag: [],
    pickup: [],
  };

  async getProblemDeals() {
    this.clearProblems();

    await this.getDeals();
    return {
      'Amazon Shoptimistic': this.amazonShoptimistic,
      'Ebay Rollun': this.ebayRollun,
      'Ebay Plaisir': this.ebayPlaisir,
    };
  }

  private async getDeals() {
    await this.getDeal(ordersDatastore, 'order');
    await this.getDeal(bagsDatastore, 'bag');
    await this.getDeal(dropshipsDatastore, 'dropship');
    await this.getDeal(pickupsDatastore, 'pickup');
  }

  private async getDeal(
    datastore: HttpDatastore<BagDTO | PickupDTO | OrderDTO | DropshipDTO>,
    type: DealsType,
  ) {
    const deal = await this.getDealDTOQuery(datastore);
    this.sortProblems(deal, type);
  }

  private clearProblems() {
    this.amazonShoptimistic = {
      dropship: [],
      order: [],
      bag: [],
      pickup: [],
    };
    this.ebayPlaisir = {
      dropship: [],
      order: [],
      bag: [],
      pickup: [],
    };
    this.ebayRollun = {
      dropship: [],
      order: [],
      bag: [],
      pickup: [],
    };
  }

  private async getDealDTOQuery(
    datastore: HttpDatastore<BagDTO | PickupDTO | OrderDTO | DropshipDTO>,
  ): Promise<(BagDTO | PickupDTO | OrderDTO | DropshipDTO)[]> {
    return await datastore.query(
      new Query({
        query: new And([
          new Not([
            new Or([
              new Eq('ProblemDescription', '-'),
              new Eqn('ProblemDescription'),
            ]),
          ]),
          new Not([new Eq('StatusName', 'Archive')]),
        ]),
      }),
    );
  }

  private sortProblems(
    deals: (BagDTO | PickupDTO | OrderDTO | DropshipDTO)[],
    type: DealsType,
  ) {
    deals.map((item) => {
      switch (item.MpName) {
        case 'Amazon Shoptimistic':
          this.amazonShoptimistic[type].push({
            id: item.Id,
            problem: item.ProblemDescription || '',
          });
          break;
        case 'Ebay Rollun':
          this.ebayRollun[type].push({
            id: item.Id,
            problem: item.ProblemDescription || '',
          });
          break;
        case 'Ebay Plaisir':
          this.ebayPlaisir[type].push({
            id: item.Id,
            problem: item.ProblemDescription || '',
          });
      }
    });
  }
}
