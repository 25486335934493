import React, { Component } from 'react';
import { ParcelItemType, ParcelType } from '../utils/types';
import { Button, Spinner, ErrorView, Card } from '../../../UI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ParcelCard from './ParcelCard';
import ParcelItemView from './ParcelItemView';
import HttpDatastore from 'rollun-ts-datastore';
import { ErrorType } from '../../../utils/common.types';
import { httpErrorHandler } from '../../../utils/common.utils';
import { BarcodeScanner } from '../../../lib/barcode-scanner/BarcodeScanner';

enum ScanParcelAction {
  LOADING = 'loading',
  ERROR = 'error',
  SCAN_BARCODE = 'scan_barcode',
  PROCESS_PARCEL_ITEM = 'process_parcel_items',
}

interface IProps {
  parcel: ParcelType;

  onScanComplete(): void;
}

interface IState {
  action: ScanParcelAction;
  error: ErrorType;
  parcelItems: Array<ParcelItemType>;
  barcodeInput: string;
  selectedItem: ParcelItemType | null;
}

class ScanParcel extends Component<IProps, IState> {
  state: IState = {
    action: ScanParcelAction.LOADING,
    parcelItems: [],
    error: { code: 0, text: '' },
    barcodeInput: '',
    selectedItem: null,
  };

  productsDataStore: HttpDatastore<ParcelItemType>;

  constructor(props: IProps) {
    super(props);
    this.productsDataStore = new HttpDatastore<ParcelItemType>(
      '/api/datastore/Barcode_' + props.parcel.id,
    );
  }

  componentDidMount(): void {
    this.fetchProducts();
  }

  fetchProducts = () => {
    this.setState({ action: ScanParcelAction.LOADING });
    this.productsDataStore
      .query()
      .then((res) =>
        this.setState({
          parcelItems: res,
          action: ScanParcelAction.SCAN_BARCODE,
        }),
      )
      .catch((err) => {
        httpErrorHandler(err, (code, text) =>
          this.setState({
            action: ScanParcelAction.ERROR,
            error: { code, text },
          }),
        );
      });
  };
  onBarcodeScanned = (barcode: string) => {
    const item = this.state.parcelItems.find((item) => item.fnsku === barcode);
    item
      ? this.setState({
          action: ScanParcelAction.PROCESS_PARCEL_ITEM,
          selectedItem: item,
        })
      : this.setState({
          action: ScanParcelAction.ERROR,
          error: {
            code: 0,
            text: `There is no item with [${
              barcode || 'empty'
            }] barcode in this label`,
          },
        });
  };

  onItemProcessComplete = () => {
    this.setState({ action: ScanParcelAction.SCAN_BARCODE });
  };

  renderSwitch = (action: ScanParcelAction) => {
    const { parcel } = this.props;
    const { error, parcelItems, barcodeInput, selectedItem } = this.state;
    switch (action) {
      case ScanParcelAction.SCAN_BARCODE:
        return (
          <Card className="d-flex justify-content-center align-items-center flex-column -3">
            <div>
              <div className="d-flex">
                <Button
                  color="primary"
                  size="sm"
                  onClick={this.props.onScanComplete}
                >
                  <FontAwesomeIcon icon="arrow-left" />
                </Button>
                <h3 className="ml-3">Search barcode</h3>
              </div>
            </div>
            <BarcodeScanner onResult={this.onBarcodeScanned} />
            <Card>
              <p>If You can't scan, enter barcode manually</p>
              <input
                className="form-control mb-1 mr-1"
                type="text"
                value={barcodeInput}
                onChange={(e) =>
                  this.setState({ barcodeInput: e.target.value })
                }
              />
              <Button
                onClick={() => this.onBarcodeScanned(this.state.barcodeInput)}
                color="primary"
                block
              >
                Enter
              </Button>
            </Card>
            <Card className="w-100">
              {parcelItems.length === 0 ? (
                <h5 className="m-2 text-center">
                  There is no items in this label!
                </h5>
              ) : (
                <>
                  <h4>Barcodes in this parcel:</h4>
                  <div className="d-flex">
                    <div className="font-weight-bold w-50">Fnsku:</div>
                    <div className="font-weight-bold w-50">Part Number:</div>
                  </div>
                  {parcelItems.map((item) => (
                    <div key={item.id} className="d-flex">
                      <div className="font-weight-bold w-50">{item.fnsku}</div>
                      <div className="font-weight-bold w-50">
                        {item.part_number}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </Card>
            {parcel ? (
              <ParcelCard showDataTime parcel={parcel} />
            ) : (
              <p>No parcel selected</p>
            )}
          </Card>
        );
      case ScanParcelAction.PROCESS_PARCEL_ITEM:
        return selectedItem ? (
          <ParcelItemView
            item={selectedItem}
            onItemFinish={this.onItemProcessComplete}
          />
        ) : (
          <p>No item selected</p>
        );
      case ScanParcelAction.LOADING:
        return (
          <div className="m-5">
            <Spinner />
          </div>
        );
      case ScanParcelAction.ERROR:
        return (
          <ErrorView error={error}>
            <Button color="primary" onClick={() => this.props.onScanComplete()}>
              Back
            </Button>
          </ErrorView>
        );
    }
  };

  render() {
    return this.renderSwitch(this.state.action);
  }
}

export default ScanParcel;
