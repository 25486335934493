export const srName = [
  'RockyMountain',
  'RollunOffice',
  'Autodist',
  'Partzilla',
  'PartsUnlimited',
  'Turn14',
  'Wps',
  'other',
];
