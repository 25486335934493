import React, { FC, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Button, Checkbox } from '../../../UI';
import { Roles } from '../../AddNewUser/types';
import { Query, And, Eq } from 'rollun-ts-rql';
import HttpDatastore from 'rollun-ts-datastore';

interface RolesFormProps {
  userRoles: Roles;
  rolesList: string[];
  userId: string;
}

const roleDataStore = new HttpDatastore('/api/datastore/userRoleDataStore');

export const RolesUpdater: FC<RolesFormProps> = ({
  userRoles,
  rolesList,
  userId,
}) => {
  const [roles, setRoles] = useState<Roles>(userRoles);

  const deleteRoles = async (roles: string[]) => {
    if (!roles.length) return;
    const rolesDs = await roleDataStore.query(
      new Query({
        query: new And([
          new Eq('user_id', userId),
          ...roles.map((role: string) => new Eq('roles_id', role)),
        ]),
      }),
    );
    return Promise.all(
      rolesDs.map(({ id }) => {
        roleDataStore.delete(id);
      }),
    );
  };

  const addRoles = async (roles: string[]) => {
    return Promise.all(
      roles.map((role: string) =>
        roleDataStore.create({
          id: `${userId}_${role}`,
          user_id: userId,
          roles_id: role,
        }),
      ),
    );
  };

  const filterRoles = () => {
    const result: {
      forAdd: string[];
      forDelete: string[];
    } = {
      forAdd: [],
      forDelete: [],
    };

    const userRoleNames = userRoles.map((userRole) => userRole.role);
    roles.forEach((roleObj) => {
      const { role, checked } = roleObj;
      const existingRoleIndex = userRoleNames.indexOf(role);

      if (checked) {
        if (existingRoleIndex === -1) {
          result.forAdd.push(role);
        }
      } else {
        if (existingRoleIndex !== -1) {
          result.forDelete.push(role);
        }
      }
    });

    return result;
  };

  const updateRoles = () => {
    const { forDelete, forAdd } = filterRoles();

    deleteRoles(forDelete);
    addRoles(forAdd);
  };

  return (
    <Box>
      <Typography>Choose roles for user</Typography>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '10px',
          gap: '10px',
        }}
      >
        {rolesList.map((role: string) => (
          <Box
            style={{
              display: 'flex',
            }}
            key={role}
          >
            <Checkbox
              initValue={roles.find((el) => el.role === role)?.checked}
              onChange={(e: any) => {
                const checked = e || false;
                const existingRoleIndex = roles.findIndex(
                  (el) => el.role === role,
                );

                if (existingRoleIndex !== -1) {
                  const updatedRoles = [...roles];
                  updatedRoles[existingRoleIndex] = { role, checked };
                  setRoles(updatedRoles);
                } else {
                  setRoles((roles) => [...roles, { role, checked: true }]);
                }
              }}
            />
            <Typography>{role}</Typography>
          </Box>
        ))}
      </Box>

      <Button onClick={updateRoles} color="primary">
        Submit
      </Button>
    </Box>
  );
};
