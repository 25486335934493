import React, { Component } from 'react';
import Parcels from './Parcels';
import ScanParcel from './ScanParcel';
import { ParcelType } from '../utils/types';

enum ParcelAppAction {
  PARCELS_LIST = 'parcels_list',
  SCAN_PARCEL = 'scan_parcel',
}

interface IState {
  action: ParcelAppAction;
  selectedParcel: ParcelType | null;
}

class ParcelAppContainer extends Component<unknown, IState> {
  state: IState = {
    action: ParcelAppAction.PARCELS_LIST,
    selectedParcel: null,
  };

  onParcelSelect = (parcel: ParcelType) => {
    this.setState({
      selectedParcel: parcel,
      action: ParcelAppAction.SCAN_PARCEL,
    });
  };

  onParcelScanComplete = () => {
    this.setState({ action: ParcelAppAction.PARCELS_LIST });
  };

  renderSwitch = (action: ParcelAppAction) => {
    const { selectedParcel } = this.state;
    switch (action) {
      case ParcelAppAction.PARCELS_LIST:
        return <Parcels onSelect={this.onParcelSelect} />;
      case ParcelAppAction.SCAN_PARCEL:
        return selectedParcel ? (
          <ScanParcel
            parcel={selectedParcel}
            onScanComplete={this.onParcelScanComplete}
          />
        ) : (
          <p>No parcel selected</p>
        );
    }
  };

  render() {
    return this.renderSwitch(this.state.action);
  }
}

export default ParcelAppContainer;
