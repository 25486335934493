import React, { FC, useEffect } from 'react';
import { Box, Paper, Typography, useMediaQuery } from '@material-ui/core';
import { DealType, DealConfig } from '../index';
import _ from 'lodash';
import { Deal } from '../../api-clients/types';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import MuiIconButton from '../../../../../UI/MuiIconButton';
import { StatusSelector } from './StatusSelector';

interface HeaderProps {
  deal: Deal;
  type: DealType;
  availableStatuses?: {
    id: string;
    name: string;
    color?: string;
  }[];
  dealConfig: DealConfig;
}

const Header: FC<HeaderProps> = ({
  deal,
  type,
  availableStatuses = [],
  dealConfig,
}) => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const location = useLocation();

  const handleGoBack = () => {
    const listPath = location.pathname.replace(`/${id}`, '');
    history.push(listPath);
  };

  useEffect(() => {
    if (type) document.title = `${_.upperFirst(type)}: ${deal.id}`;

    return () => {
      document.title = 'Rollun CMS';
    };
  }, []);

  const isPhone = useMediaQuery('(max-width: 767px)');
  return (
    <Paper
      elevation={1}
      style={{
        padding: 8,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          flexDirection: isPhone ? 'column' : 'row',
        }}
      >
        <Box style={{ display: 'flex', gap: 8, flexDirection: 'column' }}>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <MuiIconButton
              width={40}
              height={40}
              iconName="arrow-left"
              onClick={handleGoBack}
            />
            <Typography
              style={{
                fontSize: 32,
                display: 'inline',
              }}
            >
              {`${_.upperFirst(type)}. ID: ${deal.id}`}
            </Typography>
          </Box>
          {!dealConfig.disableStatusSelector && (
            <StatusSelector availableStatuses={availableStatuses} />
          )}
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: isPhone ? '' : '400px',
            marginTop: isPhone ? '8px' : '',
          }}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography>Creation Date:</Typography>
            <Typography>{deal.timeCreated}</Typography>
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography>Last Update Date:</Typography>
            <Typography>{deal.timeUpdated}</Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default Header;
