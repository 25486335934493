import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from '../../../../../UI/Spinner';
import GenericDeal, { DealProps, DealType } from '../../GenericDeal';
import { ErrorView } from '../../../../../UI/index';
import { useGetPickupDeal } from '../hooks/useGetPickupDeal';
import { useMutateItems } from '../hooks/useMutateItems';
import { useMutateDeal } from '../hooks/useMutateDeal';
import { Box } from '@material-ui/core';
import MuiButton from '../../../../../UI/MuiButton';
import { useMutateClient } from '../../GenericDeal/hooks/useMutateClient';
import { apiClientsPickups } from '../../api-clients/api-clients-pickups';

const PickupDeal: FC<DealProps> = ({ buttons, fieldsConfig, widgets }) => {
  const { id } = useParams<{ id: string }>();
  const { data, error, isFetching } = useGetPickupDeal(id);
  const { handleAddItems, handleDeleteItems } = useMutateItems();
  const { handleUpdateClient } = useMutateClient();
  const { handleUpdateDeal } = useMutateDeal();
  const history = useHistory();

  console.log(isFetching, error, data);
  if (isFetching && data?.id !== id) {
    return <Spinner />;
  }

  if (error && !data) {
    return (
      <ErrorView
        error={{
          text: (error as Error).message,
          code: (error as Error).message.includes('not found') ? 404 : 403,
        }}
      >
        <Box
          style={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <MuiButton onClick={() => history.goBack()}>Back</MuiButton>
          <MuiButton onClick={() => location.reload()}>Reload</MuiButton>
        </Box>
      </ErrorView>
    );
  }

  return (
    <GenericDeal
      data={data!}
      rawData={data ? apiClientsPickups.convertModelToDTO(data!) : {}}
      type={DealType.Pickup}
      buttons={buttons}
      fieldsConfig={fieldsConfig}
      widgets={widgets}
      onItemsAdd={handleAddItems}
      onItemsDelete={handleDeleteItems}
      onDealUpdate={handleUpdateDeal}
      onClientUpdate={handleUpdateClient}
      availableStatuses={[
        {
          id: '50',
          name: 'Parcel creation',
          color: '#F28411',
        },
        {
          id: '51',
          name: 'Pick-up preparation',
          color: '#7DFF6C',
        },
        {
          id: '52',
          name: 'Packaging and sending',
          color: '#8100E5',
        },
        {
          id: '53',
          name: 'Tracking',
          color: '#B4013D',
        },
        {
          id: '54',
          name: 'Archive',
          color: '#E3DB47',
        },
      ]}
    />
  );
};

export default PickupDeal;
