import React from 'react';
import { OrderType } from '../utils/types';
import { formatDate } from '../utils';
import { getLinkedDealsQuantity } from '../utils/getLinkedDealsQuantity';

interface IProps {
  order: OrderType;
  active: boolean;

  onChange(id: string, remove: boolean): void;
}

const Order: React.FC<IProps> = ({ order, onChange, active }) => {
  const { id, store, TimeCreated, status } = order;
  const renderedStatus = status && status.trim() ? status : 'Not Packed';
  const color = TimeCreated === formatDate(new Date()) ? '#2E86C1' : '#34495E';
  const border = active ? 'border-primary' : 'border-default';
  const { data, isLoading, error } = getLinkedDealsQuantity(id);

  const handleClick = () => {
    onChange(id, active);
  };

  return (
    <div
      className={'cursor-pointer d-flex py-2 px-1 mb-2 w-100 ' + border}
      style={{ color }}
      onClick={handleClick}
    >
      <div className="d-flex w-100" style={{ fontSize: '0.9rem' }}>
        <div className="m-1" style={{ width: '25%' }}>
          {id}
        </div>
        {store && (
          <div className="m-1" style={{ width: '35%' }}>
            {store}
          </div>
        )}
        <div className="m-1" style={{ width: '25%' }}>
          {renderedStatus}
        </div>
        <div className="m-1" style={{ width: '15%' }}>
          {isLoading ? 'Loading...' : error ? error : data}
        </div>
      </div>
    </div>
  );
};

export default Order;
