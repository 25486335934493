import { PureComponent, ReactNode } from 'react';
import React from 'react';
import Spinner from '../UI/Spinner';
import { ProgressCallback } from '../utils/common.utils';
import MuiIconButton from '../UI/MuiIconButton';
import {
  Typography,
  Dialog,
  DialogTitle,
  Box,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import MuiButton from '../UI/MuiButton';

export interface DeleteItemDialogProps {
  isDisabled?: boolean;
  title: string;
  body: string;
  icon?: any;

  onFormSubmit(callback: ProgressCallback): void;
}

interface IState {
  isOpen: boolean;
  success: number;
  fail: number;
  inProgress: boolean;
  total: number;
}

export default class DeleteItemDialog extends PureComponent<
  DeleteItemDialogProps,
  IState
> {
  state = {
    isOpen: false,
    inProgress: false,
    success: 0,
    fail: 0,
    total: 0,
  };

  render(): ReactNode {
    const { title, body, onFormSubmit, icon } = this.props;
    const { success, fail, total, inProgress } = this.state;
    return (
      <div>
        <MuiIconButton
          title={title}
          color="error"
          width={40}
          height={40}
          iconName={icon ? icon : 'trash-alt'}
          disabled={this.props.isDisabled}
          onClick={() => this.handleOpenDialog()}
        />
        <Dialog
          open={this.state.isOpen}
          onClose={() => this.handleCloseDialog()}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            {inProgress ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Spinner />
                <Box paddingTop={1}>
                  Total: {success + fail}/{total}
                </Box>
                <Box paddingTop={1}>succeed: {success}</Box>
                <Box paddingTop={1}>fail: {fail}</Box>
              </Box>
            ) : (
              <Typography variant="body1">{body}</Typography>
            )}
          </DialogContent>
          <DialogActions>
            <MuiButton
              fullWidth
              color="error"
              onClick={() => {
                onFormSubmit((success, fail, total) => {
                  console.log(success, fail);
                  if (success + fail < total) {
                    this.setState({
                      success,
                      fail,
                      total,
                      inProgress: true,
                    });
                  } else {
                    this.setState({
                      success: 0,
                      fail: 0,
                      total: 0,
                      inProgress: false,
                      isOpen: false,
                    });
                  }
                });
              }}
            >
              Yes
            </MuiButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  protected handleCloseDialog() {
    this.setState({
      isOpen: false,
      inProgress: false,
      success: 0,
      fail: 0,
    });
  }

  protected handleOpenDialog() {
    this.setState({ isOpen: true });
  }
}
