import { makeStaticCDNPath } from './common.utils';

// export type CSVValue = string | number;
// export type CSVRow = {[key: string]: CSVValue};
// export type CSVRows = Array<CSVRow>;

export const LAST_PATH_BEFORE_403_CACHE_NAME = 'LAST_PATH_BEFORE_403';

export const NODE_RED_INSTANCES = [
  {
    type: 'dev',
    name: 'autohowtobuy2',
    url: 'https://au-howtobuy-node-red.rollun.net/',
  },
  {
    type: 'dev',
    name: 'crm callbacks',
    url: 'https://crm-callbacks.rollun.net/',
  },
  {
    type: 'dev',
    name: 'crm',
    url: 'https://crm-node-red.rollun.net/',
  },
  {
    type: 'prod',
    name: 'Main',
    url: 'https://nodered.rollun.net/',
  },
  {
    type: 'dev',
    name: 'Ebay',
    url: 'https://ebay-node-red.rollun.net/',
  },
  {
    type: 'dev',
    name: 'CEO',
    url: 'https://ceo-node-red.rollun.net/',
  },
  {
    type: 'dev',
    name: 'CTO',
    url: 'https://cto-node-red.rollun.net/',
  },
  {
    type: 'dev',
    name: 'Dev manager',
    url: 'https://dev-manager-node-red.rollun.net/',
  },
  {
    type: 'prod',
    name: 'Megaplan',
    url: 'https://nr-megaplan.rollun.net/',
  },
  {
    type: 'prod',
    name: 'Accounting',
    url: 'https://nr-accounting.rollun.net/',
  },
  {
    type: 'prod',
    name: 'Accounting v2',
    url: 'https://nr-buhgalteria.rollun.net/',
  },
];

export const drawerWidth = 300;

export const NO_IMAGE = makeStaticCDNPath('images/no-image.png');

export const MINIFIED_REACT_ERRORS: Record<string, string> = {
  '0':
    'React.addons.createFragment(...): Encountered an invalid child; DOM elements are not valid children of React components.',
  '1': 'update(): expected target of %s to be an array; got %s.',
  '2':
    'update(): expected spec of %s to be an array; got %s. Did you forget to wrap your parameter in an array?',
  '3':
    'update(): You provided a key path to update() that did not contain one of %s. Did you forget to include {%s: ...}?',
  '4': 'Cannot have more than one key in an object with %s',
  '5': "update(): %s expects a spec of type 'object'; got %s",
  '6': "update(): %s expects a target of type 'object'; got %s",
  '7': 'Expected %s target to be an array; got %s',
  '8':
    'update(): expected spec of %s to be an array of arrays; got %s. Did you forget to wrap your parameters in an array?',
  '9': 'update(): expected spec of %s to be a function; got %s.',
  '10': 'findAllInRenderedTree(...): instance must be a composite component',
  '11':
    'TestUtils.scryRenderedDOMComponentsWithClass expects a className as a second argument.',
  '12': 'ReactShallowRenderer render(): Invalid component element.%s',
  '13':
    'ReactShallowRenderer render(): Shallow rendering works only with custom components, not primitives (%s). Instead of calling `.render(el)` and inspecting the rendered output, look at `el.props` directly instead.',
  '14':
    'TestUtils.Simulate expects a component instance and not a ReactElement.TestUtils.Simulate will not work if you are using shallow rendering.',
  '15': 'reactComponentExpect(...): instance must be a composite component',
  '16': 'Do not override existing functions.',
  '17': 'All native instances should have a tag.',
  '18': 'Expected a component class, got %s.%s',
  '19': 'Expect a native root tag, instead got %s',
  '20': 'RawText "%s" must be wrapped in an explicit <Text> component.',
  '21': 'findNodeHandle(...): Argument is not a component (type: %s, keys: %s)',
  '22':
    'findNodeHandle(...): Unable to find node handle for unmounted component.',
  '23': 'onlyChild must be passed a children with exactly one child.',
  '24': 'Mismatched list of contexts in callback queue',
  '25': 'Trying to release an instance into a pool of a different type.',
  '26': 'Unexpected node: %s',
  '27':
    'Transaction.perform(...): Cannot initialize a transaction when there is already an outstanding transaction.',
  '28': 'Transaction.closeAll(): Cannot close transaction when none are open.',
  '29': 'accumulate(...): Accumulated items must be not be null or undefined.',
  '30': 'accumulateInto(...): Accumulated items must not be null or undefined.',
  '31':
    'Objects are not valid as a React child (found: %s). If you meant to render a collection of children, use an array instead.',
  '32': 'Unable to find element with ID %s.',
  '33': 'getNodeFromInstance: Invalid argument.',
  '34': 'React DOM tree root should always have a node reference.',
  '35': 'isAncestor: Invalid argument.',
  '36': 'getParentInstance: Invalid argument.',
  '37': '_registerComponent(...): Target container is not a DOM element.',
  '38': 'parentComponent must be a valid React Component',
  '39': 'ReactDOM.render(): Invalid component element.%s',
  '40': 'unmountComponentAtNode(...): Target container is not a DOM element.',
  '41': 'mountComponentIntoNode(...): Target container is not valid.',
  '42':
    "You're trying to render a component to the document using server rendering but the checksum was invalid. This usually means you rendered a different component type or props on the client from the one on the server, or your render() methods are impure. React cannot handle this case due to cross-browser quirks by rendering at the document root. You should look for environment dependent code in your components and ensure the props are the same client and server side:\n%s",
  '43':
    "You're trying to render a component to the document but you didn't use server rendering. We can't do this without using server rendering due to cross-browser quirks. See ReactDOMServer.renderToString() for server rendering.",
  '44': 'findDOMNode was called on an unmounted component.',
  '45': 'Element appears to be neither ReactComponent nor DOMNode (keys: %s)',
  '46': 'renderToString(): You must pass a valid ReactElement.',
  '47': 'renderToStaticMarkup(): You must pass a valid ReactElement.',
  '48':
    "injectDOMPropertyConfig(...): You're trying to inject DOM property '%s' which has already been injected. You may be accidentally injecting the same DOM property config twice, or you may be injecting two configs that have conflicting property names.",
  '49': 'DOMProperty: Properties that have side effects must use property: %s',
  '50':
    'DOMProperty: Value can be one of boolean, overloaded boolean, or numeric value, but not a combination: %s',
  '51':
    'dangerouslyRenderMarkup(...): Cannot render markup in a worker thread. Make sure `window` and `document` are available globally before requiring React when unit testing or use ReactDOMServer.renderToString for server rendering.',
  '52': 'dangerouslyRenderMarkup(...): Missing markup.',
  '53': 'Danger: Assigning to an already-occupied result index.',
  '54': 'Danger: Did not assign to every index of resultList.',
  '55': 'Danger: Expected markup to render %s nodes, but rendered %s.',
  '56':
    'dangerouslyReplaceNodeWithMarkup(...): Cannot render markup in a worker thread. Make sure `window` and `document` are available globally before requiring React when unit testing or use ReactDOMServer.renderToString() for server rendering.',
  '57': 'dangerouslyReplaceNodeWithMarkup(...): Missing markup.',
  '58':
    'dangerouslyReplaceNodeWithMarkup(...): Cannot replace markup of the <html> node. This is because browser quirks make this unreliable and/or slow. If you want to render to the root you must use server rendering. See ReactDOMServer.renderToString().',
  '59':
    '%s is a void element tag and must not have `children` or use `props.dangerouslySetInnerHTML`.',
  '60': 'Can only set one of `children` or `props.dangerouslySetInnerHTML`.',
  '61':
    '`props.dangerouslySetInnerHTML` must be in the form `{__html: ...}`. Please visit https://reactjs.org/link/dangerously-set-inner-html for more information.',
  '62':
    "The `style` prop expects a mapping from style properties to values, not a string. For example, style={{marginRight: spacing + 'em'}} when using JSX.",
  '63': 'Must be mounted to trap events',
  '64': 'trapBubbledEvent(...): Requires node to be rendered.',
  '65': 'Invalid tag: %s',
  '66':
    '<%s> tried to unmount. Because of cross-browser quirks it is impossible to unmount some top-level components (eg <html>, <head>, and <body>) reliably and efficiently. To fix this, have a single top-level component that never unmounts render these elements.',
  '67': 'Missing closing comment for text component %s',
  '68':
    'Expected devtool events to fire for the child before its parent includes it in onSetChildren().',
  '69':
    'Expected onSetDisplayName() to fire for the child before its parent includes it in onSetChildren().',
  '70':
    'Expected onSetChildren() or onSetText() to fire for the child before its parent includes it in onSetChildren().',
  '71':
    'Expected onMountComponent() to fire for the child before its parent includes it in onSetChildren().',
  '72':
    'Expected onSetParent() and onSetChildren() to be consistent (%s has parents %s and %s).',
  '73':
    'ReactClassInterface: You are attempting to override `%s` from your class specification. Ensure that your method names do not overlap with React methods.',
  '74':
    'ReactClassInterface: You are attempting to define `%s` on your component more than once. This conflict may be due to a mixin.',
  '75':
    "ReactClass: You're attempting to use a component class or function as a mixin. Instead, just use a regular object.",
  '76':
    "ReactClass: You're attempting to use a component as a mixin. Instead, just use a regular object.",
  '77':
    'ReactClass: Unexpected spec policy %s for key %s when mixing in component specs.',
  '78':
    'ReactClass: You are attempting to define a reserved property, `%s`, that shouldn\'t be on the "statics" key. Define it as an instance property instead; it will still be accessible on the constructor.',
  '79':
    'ReactClass: You are attempting to define `%s` on your component more than once. This conflict may be due to a mixin.',
  '80': 'mergeIntoWithNoDuplicateKeys(): Cannot merge non-objects.',
  '81':
    'mergeIntoWithNoDuplicateKeys(): Tried to merge two objects with the same key: `%s`. This conflict may be due to a mixin; in particular, this may be caused by two getInitialState() or getDefaultProps() methods returning objects with clashing keys.',
  '82': '%s.getInitialState(): must return an object or null',
  '83':
    'createClass(...): Class specification must implement a `render` method.',
  '84':
    '%s: %s type `%s` is invalid; it must be a function, usually from React.PropTypes.',
  '85':
    'setState(...): takes an object of state variables to update or a function which returns an object of state variables.',
  '86': 'SimpleEventPlugin: Unhandled event type, `%s`.',
  '87':
    "Cannot provide a checkedLink and a valueLink. If you want to use checkedLink, you probably don't want to use valueLink and vice versa.",
  '88':
    "Cannot provide a valueLink and a value or onChange event. If you want to use value or onChange, you probably don't want to use valueLink.",
  '89':
    "Cannot provide a checkedLink and a checked property or onChange event. If you want to use checked or onChange, you probably don't want to use checkedLink",
  '90':
    'ReactDOMInput: Mixing React and non-React radio inputs with the same `name` is not supported.',
  '91': '`dangerouslySetInnerHTML` does not make sense on <textarea>.',
  '92': 'If you supply `defaultValue` on a <textarea>, do not pass children.',
  '93': '<textarea> can only have at most one child.',
  '94': 'Expected %s listener to be a function, instead got type %s',
  '95':
    'processEventQueue(): Additional events were enqueued while processing an event queue. Support for this has not yet been implemented.',
  '96':
    'EventPluginRegistry: Cannot inject event plugins that do not exist in the plugin ordering, `%s`.',
  '97':
    'EventPluginRegistry: Event plugins must implement an `extractEvents` method, but `%s` does not.',
  '98': 'EventPluginRegistry: Failed to publish event `%s` for plugin `%s`.',
  '99':
    'EventPluginRegistry: More than one plugin attempted to publish the same event name, `%s`.',
  '100':
    'EventPluginRegistry: More than one plugin attempted to publish the same registration name, `%s`.',
  '101':
    'EventPluginRegistry: Cannot inject event plugin ordering more than once. You are likely trying to load more than one copy of React.',
  '102':
    'EventPluginRegistry: Cannot inject two different event plugins using the same name, `%s`.',
  '103': 'executeDirectDispatch(...): Invalid `event`.',
  '104':
    'ReactCompositeComponent: injectEnvironment() can only be called once.',
  '105':
    '%s(...): A valid React element (or null) must be returned. You may have returned undefined, an array or some other invalid object.',
  '106': '%s.state: must be set to an object or null',
  '107':
    '%s.getChildContext(): childContextTypes must be defined in order to use getChildContext().',
  '108': '%s.getChildContext(): key "%s" is not defined in childContextTypes.',
  '109':
    '%s.render(): A valid React element (or null) must be returned. You may have returned undefined, an array or some other invalid object.',
  '110': 'Stateless function components cannot have refs.',
  '111': 'There is no registered component for the tag %s',
  '112': 'getNextDescendantID(%s, %s): Received an invalid React DOM ID.',
  '113':
    'getNextDescendantID(...): React has made an invalid assumption about the DOM hierarchy. Expected `%s` to be an ancestor of `%s`.',
  '114': 'getFirstCommonAncestorID(%s, %s): Expected a valid React DOM ID: %s',
  '115':
    'traverseParentPath(...): Cannot traverse from and to the same ID, `%s`.',
  '116':
    'traverseParentPath(%s, %s, ...): Cannot traverse from two IDs that do not have a parent path.',
  '117':
    'traverseParentPath(%s, %s, ...): Detected an infinite loop while traversing the React DOM ID tree. This may be due to malformed IDs: %s',
  '118': 'updateTextContent called on non-empty component.',
  '119':
    "addComponentAsRefTo(...): Only a ReactOwner can have refs. You might be adding a ref to a component that was not created inside a component's `render` method, or you have multiple copies of React loaded (details: https://reactjs.org/link/refs-must-have-owner).",
  '120':
    "removeComponentAsRefFrom(...): Only a ReactOwner can have refs. You might be removing a ref to a component that was not created inside a component's `render` method, or you have multiple copies of React loaded (details: https://reactjs.org/link/refs-must-have-owner).",
  '121':
    'performUpdateIfNecessary: Unexpected batch number (current %s, pending %s)',
  '122':
    '%s(...): Expected the last optional `callback` argument to be a function. Instead received: %s.',
  '123':
    'ReactUpdates: must inject a reconcile transaction class and batching strategy',
  '124':
    "Expected flush transaction's stored dirty-components length (%s) to match dirty-components array length (%s).",
  '125':
    "ReactUpdates.asap: Can't enqueue an asap callback in a context where updates are not being batched.",
  '126': 'ReactUpdates: must provide a reconcile transaction class',
  '127': 'ReactUpdates: must provide a batching strategy',
  '128': 'ReactUpdates: must provide a batchedUpdates() function',
  '129': 'ReactUpdates: must provide an isBatchingUpdates boolean attribute',
  '130':
    'Element type is invalid: expected a string (for built-in components) or a class/function (for composite components) but got: %s.%s',
  '131': 'Encountered invalid React node of type %s',
  '132': 'Ended a touch event which was not counted in trackedTouchCount.',
  '133': 'Touch object is missing identifier',
  '134': 'Touch data should have been recorded on start',
  '135': 'Cannot find single active touch',
  '136':
    'Attempted to update component `%s` that has already been unmounted (or failed to mount).',
  '137':
    '%s is a void element tag and must neither have `children` nor use `dangerouslySetInnerHTML`.',
  '138': 'Touch object is missing identifier.',
  '139': "ReactTestRenderer: .update() can't be called after unmount.",
  '140':
    'Expected hook events to fire for the child before its parent includes it in onSetChildren().',
  '141':
    'Expected onSetChildren() to fire for a container child before its parent includes it in onSetChildren().',
  '142':
    'Expected onBeforeMountComponent() parent and onSetChildren() to be consistent (%s has parents %s and %s).',
  '143':
    'React.Children.only expected to receive a single React element child.',
  '144': 'Item must have been set',
  '145':
    'hostContainerInfo should be populated before getPublicInstance is called.',
  '146':
    'React Component in warnForMissingKey should have a _store. This error is likely caused by a bug in React. Please file an issue.',
  '147':
    'Missing owner for string ref %s. This error is likely caused by a bug in React. Please file an issue.',
  '148': 'Expected ref to be a function or a string.',
  '149':
    'Element ref was specified as a string (%s) but no owner was set. You may have multiple copies of React loaded. (details: https://reactjs.org/link/refs-must-have-owner).',
  '150':
    'An object is not an iterable. This error is likely caused by a bug in React. Please file an issue.',
  '151': 'An iterable object provided no iterator.',
  '152':
    '%s(...): Nothing was returned from render. This usually means a return statement is missing. Or, to render nothing, return null.',
  '153': 'Resuming work not yet implemented.',
  '154':
    'We should always have pending or current props. This error is likely caused by a bug in React. Please file an issue.',
  '155':
    'An indeterminate component should never have mounted. This error is likely caused by a bug in React. Please file an issue.',
  '156':
    'Unknown unit of work tag (%s). This error is likely caused by a bug in React. Please file an issue.',
  '157':
    'Invalid type of work. This error is likely caused by a bug in React. Please file an issue.',
  '158':
    'There must be pending props for an initial mount. This error is likely caused by a bug in React. Please file an issue.',
  '159':
    'There should always be pending or memoized props. This error is likely caused by a bug in React. Please file an issue.',
  '160':
    'Expected to find a host parent. This error is likely caused by a bug in React. Please file an issue.',
  '161':
    'Invalid host parent fiber. This error is likely caused by a bug in React. Please file an issue.',
  '162':
    'This should have a text node initialized. This error is likely caused by a bug in React. Please file an issue.',
  '163':
    'This unit of work tag should not have side-effects. This error is likely caused by a bug in React. Please file an issue.',
  '164': 'A coroutine cannot have host component children.',
  '165':
    'Should be resolved by now. This error is likely caused by a bug in React. Please file an issue.',
  '166':
    'We must have new props for new mounts. This error is likely caused by a bug in React. Please file an issue.',
  '167':
    'An indeterminate component should have become determinate before completing. This error is likely caused by a bug in React. Please file an issue.',
  '168':
    'Unexpected context found on stack. This error is likely caused by a bug in React. Please file an issue.',
  '169':
    'Expected to have an instance by this point. This error is likely caused by a bug in React. Please file an issue.',
  '170':
    'Expected subtree parent to be a mounted class component. This error is likely caused by a bug in React. Please file an issue.',
  '171':
    'Found unexpected detached subtree parent. This error is likely caused by a bug in React. Please file an issue.',
  '172': 'The custom dialog was already injected.',
  '173': 'Injected showDialog() must be a function.',
  '174':
    'Expected host context to exist. This error is likely caused by a bug in React. Please file an issue.',
  '175':
    'Expected prepareToHydrateHostInstance() to never be called. This error is likely caused by a bug in React. Please file an issue.',
  '176':
    'Expected prepareToHydrateHostTextInstance() to never be called. This error is likely caused by a bug in React. Please file an issue.',
  '177':
    'Cannot commit the same tree as before. This error is likely caused by a bug in React. Please file an issue.',
  '178':
    'Should have next effect. This error is likely caused by a bug in React. Please file an issue.',
  '179':
    'Should have a pending commit. This error is likely caused by a bug in React. Please file an issue.',
  '180':
    'Commit phase errors should be scheduled to recover with task priority. This error is likely caused by a bug in React. Please file an issue.',
  '181':
    'Switch statement should be exhaustive. This error is likely caused by a bug in React. Please file an issue.',
  '182':
    'performWork was called recursively. This error is likely caused by a bug in React. Please file an issue.',
  '183':
    'Should have found an error boundary. This error is likely caused by a bug in React. Please file an issue.',
  '184':
    'No error for given unit of work. This error is likely caused by a bug in React. Please file an issue.',
  '185':
    'Maximum update depth exceeded. This can happen when a component repeatedly calls setState inside componentWillUpdate or componentDidUpdate. React limits the number of nested updates to prevent infinite loops.',
  '186':
    'Task updates can only be scheduled as a nested update or inside batchedUpdates.',
  '187':
    'flushSync was called from inside a lifecycle method. It cannot be called when React is already rendering.',
  '188': 'Unable to find node on an unmounted component.',
  '189':
    'Child was not found in either parent set. This indicates a bug in React related to the return pointer. Please file an issue.',
  '190':
    "Return fibers should always be each others' alternates. This error is likely caused by a bug in React. Please file an issue.",
  '191':
    'Invalid argument passed as callback. Expected a function. Instead received: %s',
  '192':
    'React depends on requestAnimationFrame. Make sure that you load a polyfill in older browsers.',
  '193':
    'The server renderer does not implement support for array children yet.',
  '194':
    'Fiber needs to be injected to handle a fiber target for controlled events. This error is likely caused by a bug in React. Please file an issue.',
  '195':
    'The internal instance must be a React host component. This error is likely caused by a bug in React. Please file an issue.',
  '196': 'Missing injection for fiber getContextForSubtree',
  '197': 'Injected invokeGuardedCallback() must be a function.',
  '198':
    'clearCaughtError was called but no error was captured. This error is likely caused by a bug in React. Please file an issue.',
  '199': 'Fiber owners should not show up in Stack stack traces.',
  '200': 'Target container is not a DOM element.',
  '201':
    "ReactDOM.render(): Invalid component element. Instead of passing a string like 'div', pass React.createElement('div') or <div />.",
  '202':
    'ReactDOM.render(): Invalid component element. Instead of passing a class like Foo, pass React.createElement(Foo) or <Foo />.',
  '203':
    'ReactDOM.render(): Invalid component element. This may be caused by unintentionally loading two independent copies of React.',
  '204': 'ReactDOM.render(): Invalid component element.',
  '205': 'renderToNodeStream(): Invalid component element.',
  '206': 'renderToStaticNodeStream(): Invalid component element.',
  '207':
    'ReactDOMServer.renderToNodeStream(): The streaming API is not available in the browser. Use ReactDOMServer.renderToString() instead.',
  '208':
    'ReactDOMServer.renderToStaticNodeStream(): The streaming API is not available in the browser. Use ReactDOMServer.renderToStaticMarkup() instead.',
  '209': 'renderToString(): Invalid component element.',
  '210': 'renderToStaticMarkup(): Invalid component element.',
  '211': 'Missing injection for fiber findDOMNode',
  '212': 'Missing injection for stack findDOMNode',
  '213': 'Element appears to be neither ReactComponent nor DOMNode. Keys: %s',
  '214': 'toTree() does not yet know how to handle nodes with tag=%s',
  '215': 'something went wrong',
  '216': 'Text children should already be flattened.',
  '217': 'ReactART does not support the type "%s"',
  '218': 'ReactART: Can not insert node before itself',
  '219': 'getInspectorDataForViewTag() is not available in production',
  '220': 'Container does not support insertBefore operation',
  '221': 'Tried to register two views with the same name %s',
  '222': 'View config not found for name %s',
  '224':
    "Can't read from currently-mounting component. This error is likely caused by a bug in React. Please file an issue.",
  '225':
    'Unexpected object passed to ReactTestInstance constructor (tag: %s). This is probably a bug in React.',
  '226':
    'Unsupported component type %s in test renderer. This is probably a bug in React.',
  '227':
    'ReactDOM was loaded before React. Make sure you load the React package before loading ReactDOM.',
  '228':
    'TestUtils.Simulate expected a DOM node as the first argument but received a React element. Pass the DOM node you wish to simulate the event on instead. Note that TestUtils.Simulate will not work if you are using shallow rendering.',
  '229':
    'TestUtils.Simulate expected a DOM node as the first argument but received a component instance. Pass the DOM node you wish to simulate the event on instead.',
  '230':
    "_processChildContext is not available in React 16+. This likely means you have multiple copies of React and are attempting to nest a React 15 tree inside a React 16 tree using unstable_renderSubtreeIntoContainer, which isn't supported. Try to make sure you have only one copy of React (and ideally, switch to ReactDOM.unstable_createPortal).",
  '231':
    'Expected `%s` listener to be a function, instead got a value of `%s` type.',
  '232':
    "_processChildContext is not available in React 16+. This likely means you have multiple copies of React and are attempting to nest a React 15 tree inside a React 16 tree using unstable_renderSubtreeIntoContainer, which isn't supported. Try to make sure you have only one copy of React (and ideally, switch to ReactDOM.createPortal).",
  '233': 'Unsupported top level event type "%s" dispatched',
  '234': 'Event cannot be both direct and bubbling: %s',
  '235': 'Persistent reconciler is disabled.',
  '236': 'Noop reconciler is disabled.',
  '237': 'Mutating reconciler is disabled.',
  '238':
    'Task updates can only be scheduled as a nested update or inside batchedUpdates. This error is likely caused by a bug in React. Please file an issue.',
  '239': 'Measure not implemented yet',
  '240': 'Text components are not supported for now.',
  '241': 'Text components are not yet supported.',
  '242':
    '%s: %s type `%s` is invalid; it must be a function, usually from the `prop-types` package, but received `%s`.',
  '243':
    'renderRoot was called recursively. This error is likely caused by a bug in React. Please file an issue.',
  '244':
    'Should have a previous and last root. This error is likely caused by a bug in React. Please file an issue.',
  '245':
    'performWorkOnRoot was called recursively. This error is likely caused by a bug in React. Please file an issue.',
  '246':
    'Should be working on a root. This error is likely caused by a bug in React. Please file an issue.',
  '247': 'A call cannot have host component children.',
  '248': 'Not implemented.',
  '249':
    'ReactShallowRenderer render(): Shallow rendering works only with custom components, but the provided element type was `%s`.',
  '250': 'batch.render: Cannot render a batch that already committed.',
  '251': 'batch.commit: Cannot commit a batch multiple times.',
  '252':
    'The `document` global was defined when React was initialized, but is not defined anymore. This can happen in a test environment if a component schedules an update from an asynchronous callback, but the test has already finished running. To solve this, you can either unmount the component at the end of your test (and ensure that any asynchronous operations get canceled in `componentWillUnmount`), or you can change the test itself to be asynchronous.',
  '253':
    'work.commit(): Cannot commit while already rendering. This likely means you attempted to commit from inside a lifecycle method.',
  '254':
    "Element ref was specified as a string (%s) but no owner was set. This could happen for one of the following reasons:\n1. You may be adding a ref to a functional component\n2. You may be adding a ref to a component that was not created inside a component's render method\n3. You have multiple copies of React loaded\nSee https://reactjs.org/link/refs-must-have-owner for more information.",
  '255': 'Expected ReactFbErrorUtils.invokeGuardedCallback to be a function.',
  '257':
    'Portals are not currently supported by the server renderer. Render them conditionally so that they only appear on the client render.',
  '258':
    'Unknown element-like object type: %s. This is likely a bug in React. Please file an issue.',
  '259':
    'The experimental Call and Return types are not currently supported by the server renderer.',
  '260':
    'Element type is invalid: expected a string (for built-in components) or a class/function (for composite components) but got: %s.',
  '261':
    'Cannot commit an incomplete root. This error is likely caused by a bug in React. Please file an issue.',
  '262':
    'Expired work should have completed. This error is likely caused by a bug in React. Please file an issue.',
  '263': 'dispatch: Cannot dispatch during the render phase.',
  '264':
    'An error logging effect should not have been scheduled if no errors were captured. This error is likely caused by a bug in React. Please file an issue.',
  '265':
    'This unit of work tag cannot capture errors. This error is likely caused by a bug in React. Please file an issue.',
  '266': 'A subscription must return an unsubscribe function.',
  '267':
    'React.cloneElement(...): The argument must be a React element, but you passed %s.',
  '268': 'Argument appears to not be a ReactComponent. Keys: %s',
  '269':
    'Profiler must specify an "id" string and "onRender" function as props',
  '270':
    'The current renderer does not support persistence. This error is likely caused by a bug in React. Please file an issue.',
  '271':
    'Failed to replay rendering after an error. This is likely caused by a bug in React. Please file an issue with a reproducing case to help us find it.',
  '273': 'Nesting of <View> within <Text> is not currently supported.',
  '274': 'Text strings must be rendered within a <Text> component.',
  '275':
    'The current renderer does not support mutation. This error is likely caused by a bug in React. Please file an issue.',
  '276':
    'React depends on requestAnimationFrame. Make sure that you load a polyfill in older browsers. https://reactjs.org/link/react-polyfills',
  '277':
    'Context.unstable_read(): Context can only be read while React is rendering, e.g. inside the render method or getDerivedStateFromProps.',
  '278': 'unstable_createRoot(...): Target container is not a DOM element.',
  '279': 'Trying to release an event instance into a pool of a different type.',
  '280':
    'setRestoreImplementation() needs to be called to handle a target for controlled events. This error is likely caused by a bug in React. Please file an issue.',
  '281':
    'Finished root should have a work-in-progress. This error is likely caused by a bug in React. Please file an issue.',
  '282':
    'If the root does not have an updateQueue, we should have already bailed out. This error is likely caused by a bug in React. Please file an issue.',
  '283':
    'Element type is invalid. Received a promise that resolves to: %s. Promise elements must resolve to a class or function.',
  '284':
    'Expected ref to be a function, a string, an object returned by React.createRef(), or null.',
  '285':
    'The root failed to unmount after an error. This is likely a bug in React. Please file an issue.',
  '286':
    '%s(...): the first argument must be a React class instance. Instead received: %s.',
  '287':
    'It is not supported to run the profiling version of a renderer (for example, `react-dom/profiling`) without also replacing the `schedule/tracking` module with `schedule/tracking-profiling`. Your bundler might have a setting for aliasing both modules. Learn more at https://reactjs.org/link/profiling',
  '288':
    'It is not supported to run the profiling version of a renderer (for example, `react-dom/profiling`) without also replacing the `schedule/tracing` module with `schedule/tracing-profiling`. Your bundler might have a setting for aliasing both modules. Learn more at https://reactjs.org/link/profiling',
  '289': 'Function components cannot have refs.',
  '290':
    "Element ref was specified as a string (%s) but no owner was set. This could happen for one of the following reasons:\n1. You may be adding a ref to a function component\n2. You may be adding a ref to a component that was not created inside a component's render method\n3. You have multiple copies of React loaded\nSee https://reactjs.org/link/refs-must-have-owner for more information.",
  '291':
    'Log of yielded values is not empty. Call expect(Scheduler).toHaveYielded(...) first.',
  '292':
    'The matcher `toHaveYielded` expects an instance of React Test Renderer.\n\nTry: expect(Scheduler).toHaveYielded(expectedYields)',
  '293':
    'Context can only be read while React is rendering, e.g. inside the render method or getDerivedStateFromProps.',
  '294': 'ReactDOMServer does not yet support Suspense.',
  '295': 'ReactDOMServer does not yet support lazy-loaded components.',
  '296':
    'Log of yielded values is not empty. Call expect(ReactTestRenderer).unstable_toHaveYielded(...) first.',
  '297':
    'The matcher `unstable_toHaveYielded` expects an instance of React Test Renderer.\n\nTry: expect(ReactTestRenderer).unstable_toHaveYielded(expectedYields)',
  '298': 'Hooks can only be called inside the body of a function component.',
  '299': 'createRoot(...): Target container is not a DOM element.',
  '300':
    'Rendered fewer hooks than expected. This may be caused by an accidental early return statement.',
  '301':
    'Too many re-renders. React limits the number of renders to prevent an infinite loop.',
  '302':
    'It is not supported to run the profiling version of a renderer (for example, `react-dom/profiling`) without also replacing the `scheduler/tracing` module with `scheduler/tracing-profiling`. Your bundler might have a setting for aliasing both modules. Learn more at https://reactjs.org/link/profiling',
  '303':
    'ReactDOMServer did not find an internal fallback frame for Suspense. This is a bug in React. Please file an issue.',
  '304':
    'Maximum number of concurrent React renderers exceeded. This can happen if you are not properly destroying the Readable provided by React. Ensure that you call .destroy() on it if you no longer want to read from it, and did not read to the end. If you use .pipe() this should be automatic.',
  '305':
    'The current renderer does not support hydration. This error is likely caused by a bug in React. Please file an issue.',
  '306':
    'Element type is invalid. Received a promise that resolves to: %s. Lazy element type must resolve to a class or function.%s',
  '307':
    'Hooks can only be called inside the body of a function component. (https://reactjs.org/link/invalid-hook-call)',
  '308':
    'Context can only be read while React is rendering. In classes, you can read it in the render method or getDerivedStateFromProps. In function components, you can read it directly in the function body, but not inside Hooks like useReducer() or useMemo().',
  '309':
    'Function components cannot have string refs. We recommend using useRef() instead. Learn more about using refs safely here: https://reactjs.org/link/strict-mode-string-ref',
  '310': 'Rendered more hooks than during the previous render.',
  '311':
    'Should have a queue. This is likely a bug in React. Please file an issue.',
  '312': 'Rendered more hooks than during the previous render',
  '313':
    'Unknown priority level. This error is likely caused by a bug in React. Please file an issue.',
  '314':
    'Pinged unknown suspense boundary type. This is probably a bug in React.',
  '315':
    'Suspense boundaries are never on the root. This is probably a bug in React.',
  '316':
    'Expected skipPastDehydratedSuspenseInstance() to never be called. This error is likely caused by a bug in React. Please file an issue.',
  '317':
    'Expected to have a hydrated suspense instance. This error is likely caused by a bug in React. Please file an issue.',
  '318':
    'A dehydrated suspense component was completed without a hydrated node. This is probably a bug in React.',
  '319':
    'A dehydrated suspense boundary must commit before trying to render. This is probably a bug in React.',
  '320': 'Expected ReactFiberErrorDialog.showErrorDialog to be a function.',
  '321':
    'Invalid hook call. Hooks can only be called inside of the body of a function component. This could happen for one of the following reasons:\n1. You might have mismatching versions of React and the renderer (such as React DOM)\n2. You might be breaking the Rules of Hooks\n3. You might have more than one copy of React in the same app\nSee https://reactjs.org/link/invalid-hook-call for tips about how to debug and fix this problem.',
  '322': 'forwardRef requires a render function but was given %s.',
  '323': 'React has blocked a javascript: URL as a security precaution.',
  '326': 'Expected a valid priority level',
  '327': 'Should not already be working.',
  '328': 'Should have a work-in-progress.',
  '329': 'Unknown root exit status.',
  '330': 'Should be working on an effect.',
  '331': 'Cannot flush passive effects while already rendering.',
  '332': 'Unknown priority level.',
  '333':
    'This should have a parent host component initialized. This error is likely caused by a bug in React. Please file an issue.',
  '334': 'accumulate(...): Accumulated items must not be null or undefined.',
  '335': 'ReactDOMServer does not yet support the event API.',
  '338': 'ReactDOMServer does not yet support the fundamental API.',
  '340':
    'Threw in newly mounted dehydrated component. This is likely a bug in React. Please file an issue.',
  '341':
    'We just came from a parent so we must have had a parent. This is a bug in React.',
  '342':
    "A React component suspended while rendering, but no fallback UI was specified.\n\nAdd a <Suspense fallback='...'> component higher in the tree to provide a loading indicator or placeholder to display.",
  '343': 'ReactDOMServer does not yet support scope components.',
  '344':
    'Expected prepareToHydrateHostSuspenseInstance() to never be called. This error is likely caused by a bug in React. Please file an issue.',
  '345': 'Root did not complete. This is a bug in React.',
  '348':
    'ensureListeningTo(): received a container that was not an element node. This is likely a bug in React.',
  '349':
    'Expected a work-in-progress root. This is a bug in React. Please file an issue.',
  '350':
    'Cannot read from mutable source during the current render without tearing. This is a bug in React. Please file an issue.',
  '351': 'Unsupported server component type: %s',
  '352': 'React Lazy Components are not yet supported on the server.',
  '353':
    'A server block should never encode any other slots. This is a bug in React.',
  '354': 'getInspectorDataForViewAtPoint() is not available in production.',
  '355':
    'The object passed back from useOpaqueIdentifier is meant to be passed through to attributes only. Do not read the value directly.',
  '356': 'Could not read the cache.',
  '357':
    'The current renderer does not support React Scopes. This error is likely caused by a bug in React. Please file an issue.',
  '358': 'Invalid update priority: %s. This is a bug in React.',
  '360': 'Invalid lane: %s. This is a bug in React.',
  '361':
    'The current renderer does not support test selectors. This error is likely caused by a bug in React. Please file an issue.',
  '362': 'Could not find React container within specified host subtree.',
  '363': 'Test selector API is not supported by this renderer.',
  '364':
    'Invalid host root specified. Should be either a React container or a node with a testname attribute.',
  '365': 'Invalid selector type %s specified.',
  '366':
    'ReactDOM.createEventHandle: setListener called on an target that did not have a corresponding root. This is likely a bug in React.',
  '367':
    'ReactDOM.createEventHandle: setListener called on an element target that is not managed by React. Ensure React rendered the DOM element.',
  '368':
    'ReactDOM.createEventHandle: setListener called on an invalid target. Provide a valid EventTarget or an element managed by React.',
  '369':
    'ReactDOM.createEventHandle: setter called on an invalid target. Provide a valid EventTarget or an element managed by React.',
  '370':
    'ReactDOM.createEventHandle: setter called with an invalid callback. The callback must be a function.',
  '371': 'Text string must be rendered within a <Text> component.\n\nText: %s',
  '372':
    'Cannot call unstable_createEventHandle with "%s", as it is not an event known to React.',
  '373': 'This Hook is not supported in Server Components.',
  '374':
    'Event handlers cannot be passed to client component props. Remove %s from these props if possible: %s\nIf you need interactivity, consider converting part of this to a client component.',
  '375':
    "Functions cannot be passed directly to client components because they're not serializable. Remove %s (%s) from this object, or avoid the entire object: %s",
  '376':
    'Only global symbols received from Symbol.for(...) can be passed to client components. The symbol Symbol.for(%s) cannot be found among global symbols. Remove %s from this object, or avoid the entire object: %s',
  '377':
    'BigInt (%s) is not yet supported in client component props. Remove %s from this object or use a plain number instead: %s',
  '378':
    'Type %s is not supported in client component props. Remove %s from this object, or avoid the entire object: %s',
  '379':
    'Refs cannot be used in server components, nor passed to client components.',
  '380': 'Reading the cache is only supported while rendering.',
  '381': 'This feature is not supported by ReactSuspenseTestUtils.',
  '382':
    'This query has received more parameters than the last time the same query was used. Always pass the exact number of parameters that the query needs.',
  '383':
    'This query has received fewer parameters than the last time the same query was used. Always pass the exact number of parameters that the query needs.',
  '384': 'Refreshing the cache is not supported in Server Components.',
  unknown: 'Did not match any errors',
};
