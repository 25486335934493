import React, { Component } from 'react';
import Spinner from '../../../../UI/Spinner';
import HttpDatastore from 'rollun-ts-datastore';
import Dialog from '../../../../UI/Dialog';
import Query from 'rollun-ts-rql/dist/Query';
import Eq from 'rollun-ts-rql/dist/nodes/scalarNodes/Eq';
import { Button, Card, ErrorView } from '../../../../UI';
import { ErrorType } from '../../../../utils/common.types';
import { httpErrorHandler } from '../../../../utils/common.utils';
import { Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import MuiTooltip from '../../../../UI/MuiTooltip';
import { SERVICE_CONSTRUCTOR_FORM_HELPER_TEXTS } from '../../constants';
import { Help } from '@material-ui/icons';
import MuiButton from '../../../../UI/MuiButton';

const {
  ADD_RESOURCE: { NAME, PARENT_ID, PATTERN },
} = SERVICE_CONSTRUCTOR_FORM_HELPER_TEXTS;

interface IState {
  parent_id: string;
  error: ErrorType | null;
  loading: boolean;
  modalOpen: boolean;
}

interface IProps {
  onResourceCreate(id: string): void;

  name: string;
}

const getInitState = () => ({
  parent_id: '',
  error: null,
  loading: false,
  modalOpen: false,
});

class AddResource extends Component<IProps, IState> {
  ResourceDataStore = new HttpDatastore('/api/datastore/resourceDataStore');
  createdId = '';

  state: IState = getInitState();

  _checkDataIfExists = (name: string) => {
    return this.ResourceDataStore.query(
      new Query({
        query: new Eq('name', name),
      }),
    );
  };

  _sendData = (name: string, parent_id: string) => {
    this.ResourceDataStore.create({
      name,
      pattern: name,
      parent_id: parent_id ? parent_id : null,
    }).then((res: any) => {
      this.setState({ loading: false });
      this.props.onResourceCreate(res.id);
    });
  };

  onResourceCreate = () => {
    const { parent_id } = this.state;
    const { name } = this.props;
    if (!name)
      return this.setState({ error: { code: 0, text: 'Create route first' } });
    this.setState({ loading: true, error: null });
    this._checkDataIfExists(name)
      .then((res: any) => {
        if (res.length > 0) {
          this.createdId = res[0].id;
          this.setState({ modalOpen: true, loading: false });
        } else {
          this._sendData(name, parent_id);
        }
      })
      .catch((err) =>
        httpErrorHandler(err, (code, text) =>
          this.setState({ loading: false, error: { code, text } }),
        ),
      );
  };

  onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    this.setState({ [name]: value });
  };

  render() {
    const { parent_id, error, loading, modalOpen } = this.state;
    const { name } = this.props;

    if (error) {
      return (
        <Card>
          <ErrorView error={error}>
            <Button
              color="primary"
              onClick={() => {
                this.setState({ loading: false, error: null });
              }}
            >
              Retry
            </Button>
          </ErrorView>
        </Card>
      );
    }

    if (loading) {
      return (
        <div className="m-5">
          <Spinner />
        </div>
      );
    }

    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Add Resource</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Name"
              value={name}
              name="name"
              disabled
              InputProps={{
                endAdornment: (
                  <MuiTooltip title={NAME} arrow>
                    <InputAdornment position="end">
                      <Help />
                    </InputAdornment>
                  </MuiTooltip>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Pattern"
              value={name}
              name="pattern"
              disabled
              InputProps={{
                endAdornment: (
                  <MuiTooltip title={PATTERN} arrow>
                    <InputAdornment position="end">
                      <Help />
                    </InputAdornment>
                  </MuiTooltip>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Parent Id"
              value={parent_id}
              name="parent_id"
              onChange={this.onInputChange}
              InputProps={{
                endAdornment: (
                  <MuiTooltip title={PARENT_ID} arrow>
                    <InputAdornment position="end">
                      <Help />
                    </InputAdornment>
                  </MuiTooltip>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MuiButton
              fullWidth
              onClick={this.onResourceCreate}
              color="primary"
            >
              Create
            </MuiButton>
          </Grid>
        </Grid>
        <Dialog
          title="Confirm"
          isOpen={modalOpen}
          options={{ centered: true }}
          onClose={() => this.setState({ modalOpen: false })}
          onDecline={() => this.setState({ modalOpen: false })}
          onOk={() => {
            this.props.onResourceCreate(this.createdId);
          }}
        >
          Route with this data:
          <div className="p-2 card border">
            <p className="ml-2">Name: {name}</p>
            <p className="ml-2">Pattern: {name}</p>
            <p className="ml-2">Id: {this.createdId}</p>
          </div>
          exists. Use it to proceed?
        </Dialog>
      </div>
    );
  }
}

export default AddResource;
