// @ts-nocheck
import ReactDataGrid from 'rollun-react-data-grid';

export class DataGrid extends ReactDataGrid<any> {
  _mounted: boolean;
  dataGridComponent: Element | null;

  constructor(props: any) {
    super(props);
    this._mounted = false;
    this.dataGridComponent = null;
  }

  componentDidMount() {
    this._mounted = true;
    this.dataGridComponent = this.grid.getElementsByClassName(
      'react-grid-Viewport',
    )[0];
    window.addEventListener('resize', this.metricsUpdated);
    if ((this.props as any)?.cellRangeSelection) {
      this.dataGridComponent?.addEventListener('mouseup', this.onWindowMouseUp);
      this.metricsUpdated();
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    window.removeEventListener('resize', this.metricsUpdated);
    this.dataGridComponent?.removeEventListener(
      'mouseup',
      this.onWindowMouseUp,
    );
  }
}
