import React, { FC, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import useResponsiveHeight from '../../../hooks/useResponsiveHeight';
import { Skeleton } from '@material-ui/lab';
import DisplayMetricsInTable from './DisplayMetricsInTable';
import { MetricIds, MetricInfoResponse } from '../types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteIcon from '@material-ui/icons/Delete';
import { AlertContext } from './MetricUI';

interface IProps {
  metricId: string;
  refreshMetrics: boolean;
  refresh: () => void;
}

const useStyles = makeStyles(() => ({
  metricInfoHeader: {
    backgroundColor: '#F7F7F7',
  },
  tableBarHeader: {
    position: 'static',
    backgroundColor: '#F7F7F7',
    width: '100%',
  },
  accordionDetails: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  tabPanel: {
    marginTop: '10px',
  },
  summary: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

const MetricShow: FC<IProps> = ({ metricId, refreshMetrics, refresh }) => {
  const containerHeight = useResponsiveHeight(280);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [metricIds, setMetricIds] = useState<MetricIds>([]);
  const [filteredMetricIds, setFilteredMetricIds] = useState<MetricIds>([]);
  const [loadingInfo, setLoadingInfo] = useState<{
    loading: boolean;
    name: string;
  }>();
  const [loadingNames, setLoadingNames] = useState<boolean>(false);
  const [expended, setExpended] = useState<string>('');
  const [
    currentMetricInfo,
    setCurrentMetricInfo,
  ] = useState<MetricInfoResponse>();
  const alert = useContext(AlertContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const classes = useStyles();

  useEffect(() => {
    const getMetricIds = async () => {
      setLoadingNames(true);
      try {
        const { data } = await axios.get('/api/webhook/V1MetricListMetrics');
        setMetricIds(data);
      } catch (e) {
        console.log(e);
        alert.showAlert('Could not get info by metric', 'error');
      }
      setLoadingNames(false);
    };
    getMetricIds();
  }, [refreshMetrics]);

  useEffect(() => {
    setFilteredMetricIds(metricIds.filter((name) => name.includes(metricId)));
    setPage(0);
  }, [metricId, metricIds]);

  const getMetricInfo = async (metricId: string, update?: boolean) => {
    if (expended === metricId && !update) {
      setExpended('');
      return;
    }
    setLoadingInfo({
      loading: true,
      name: metricId,
    });

    try {
      const { data } = await axios.post(
        `/api/webhook/V1MetricGetById`,
        { metricId: metricId },
        { headers: { 'Content-Type': 'application/json' } },
      );
      setCurrentMetricInfo(data);
    } catch (e) {
      alert.showAlert('Could not get info by metric', 'error');
    }

    setExpended(metricId);
    setLoadingInfo({
      loading: false,
      name: '',
    });
  };

  if (loadingNames) {
    return (
      <div
        style={{
          height: '100%',
        }}
      >
        <Skeleton height={70} />
        <Skeleton height={70} />
        <Skeleton height={70} />
        <Skeleton height={70} />
        <Skeleton height={70} />
        <Skeleton height={70} />
        <Skeleton height={70} />
        <Skeleton height={70} />
        <Skeleton height={70} />
      </div>
    );
  }

  const deleteMetric = async (metricId: string) => {
    try {
      await axios.delete(`/api/v1/Metric/${metricId}`);
      alert.showAlert(
        `Metric with id: ${metricId} has been deleted`,
        'success',
      );
    } catch (e) {
      alert.showAlert(
        `Metric with id: ${metricId} has not been deleted due to an error`,
        'error',
      );
    }
    setDialogOpen(false);
    refresh();
  };

  const refreshMetric = async (metricId: string) => {
    await getMetricInfo(metricId, true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  if (metricIds.length === 0) {
    return (
      <div>
        <Typography>No metrics were found</Typography>
      </div>
    );
  }

  return (
    <div
      style={{
        maxHeight: containerHeight,
      }}
    >
      <div
        style={{
          maxHeight: containerHeight - 30,
          overflowY: 'scroll',
        }}
      >
        <List>
          {filteredMetricIds
            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
            .map((name) => (
              <div
                style={{
                  backgroundColor: '#F7F7F7',
                }}
                key={name}
              >
                <ListItem>
                  <div className={classes.summary}>
                    <div
                      style={{ width: '100%' }}
                      onClick={() => getMetricInfo(name)}
                    >
                      <div>
                        <Typography variant="h5">
                          {name.slice(0, name.indexOf(metricId))}
                          <span style={{ backgroundColor: 'yellow' }}>
                            {name.slice(
                              name.indexOf(metricId),
                              name.indexOf(metricId) + metricId.length,
                            )}
                          </span>
                          {name.slice(
                            name.indexOf(metricId) + metricId.length,
                            name.length,
                          )}
                        </Typography>
                        {loadingInfo?.loading && loadingInfo?.name === name ? (
                          <LinearProgress />
                        ) : null}
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'row',
                      }}
                    >
                      <IconButton
                        onClick={() => refreshMetric(name)}
                        style={{ display: expended === name ? 'flex' : 'none' }}
                      >
                        <RefreshIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => setDialogOpen(true)}
                        style={{
                          display: expended === name ? 'flex' : 'none',
                          color: 'darkred',
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton onClick={() => getMetricInfo(name)}>
                        {expended === name ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </IconButton>
                    </div>
                  </div>
                </ListItem>
                <Collapse
                  className={classes.accordionDetails}
                  in={expended === name}
                  timeout={0}
                  unmountOnExit
                >
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Box fontWeight="fontWeightBold" fontSize="15px">
                            Name
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box fontSize="15px">{currentMetricInfo?.name}</Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Box fontWeight="fontWeightBold" fontSize="15px">
                            Total Count
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box fontSize="15px">
                            {currentMetricInfo?.total_count}
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Box fontWeight="fontWeightBold" fontSize="15px">
                            Most recent metric
                          </Box>
                        </TableCell>
                        <TableCell>
                          <DisplayMetricsInTable
                            metrics={
                              currentMetricInfo?.most_recent_metric_point
                                ? [currentMetricInfo?.most_recent_metric_point]
                                : null
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Box fontWeight="fontWeightBold" fontSize="15px">
                            Top 10 metric
                          </Box>
                        </TableCell>
                        <TableCell>
                          <DisplayMetricsInTable
                            metrics={
                              currentMetricInfo?.top_10_metric_points
                                ? currentMetricInfo?.top_10_metric_points
                                : null
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Collapse>
              </div>
            ))}
        </List>
      </div>
      <TablePagination
        component="div"
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
        count={filteredMetricIds.length}
        page={page}
        onPageChange={(_, page) => setPage(page)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(e) => {
          setRowsPerPage(parseInt(e.target.value, 10));
          setPage(0);
        }}
      />
      <Dialog
        open={dialogOpen}
        maxWidth="sm"
        fullWidth
        onClose={() => handleClose()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Are you sure</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Delete metric with id: <strong>{expended}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => deleteMetric(expended)} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MetricShow;
