import React, { FC, useContext } from 'react';
import { LeftMenuContext, MenuItem } from '../../AppContainer/AppContainer';
import 'react-metismenu/dist/react-metismenu-standart.min.css';
import MuiLeftMenu from './MuiLeftMenu';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import { drawerWidth } from '../../../utils/common.constants';
import { normalizeMenus } from '../utils';

export type LeftMenuType = {
  icon?: string;
  label: string;
  to?: string;
  test?: string;
  externalLink?: boolean;
  content?: Array<LeftMenuType>;
};

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    zIndex: theme.zIndex.drawer - 10,
    '& a, p': {
      color: 'white',
    },
    maxWidth: drawerWidth,
  },
  paper: {
    backgroundColor: 'rgb(52,58,64)',
    minWidth: drawerWidth,
    maxWidth: drawerWidth,
    width: drawerWidth,
  },
}));

const LeftMenu: FC<{ content: Array<MenuItem> }> = ({ content }) => {
  const classes = useStyles();
  const leftMenu = useContext(LeftMenuContext);
  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  return isTablet ? (
    <Drawer
      className={classes.list}
      transitionDuration={{ enter: 500, exit: 500 }}
      variant="temporary"
      anchor="left"
      open={leftMenu.isOpen}
      onClose={() => {
        leftMenu.setIsOpen();
      }}
      classes={{ paper: classes.paper }}
    >
      <MuiLeftMenu menuItems={normalizeMenus(content)} />;
    </Drawer>
  ) : (
    <Drawer
      className={classes.list}
      transitionDuration={{ enter: 500, exit: 500 }}
      variant="persistent"
      anchor="left"
      open={leftMenu.isOpen}
      onClose={() => {
        leftMenu.setIsOpen();
      }}
      classes={{ paper: classes.paper }}
    >
      <MuiLeftMenu menuItems={normalizeMenus(content)} />;
    </Drawer>
  );
};

export default LeftMenu;
