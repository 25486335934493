import { Button } from '../../../UI';
import { CircularProgress } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import React, { FC } from 'react';
import { noop } from '../../../utils/common.utils';

interface IProps {
  onClick: () => void;
  loadingStep: boolean;
  disabled?: boolean;
}

const NextButton: FC<IProps> = ({ onClick = noop, loadingStep, disabled }) => (
  <Button
    color="primary"
    className="w-100"
    id="next-button"
    disabled={disabled}
    onClick={() => onClick()}
  >
    {loadingStep ? (
      <CircularProgress
        style={{
          width: '20px',
          height: '20px',
          color: 'white',
        }}
      />
    ) : (
      <FontAwesomeIcon
        style={{
          width: '20px',
          height: '20px',
        }}
        icon={faArrowRight}
      />
    )}
  </Button>
);

export default NextButton;
