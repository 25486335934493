import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { DealType } from '../../../GenericDeal';
import { useGetLinkedDeals } from './hooks/useGetLinkedDeals';
import { ErrorView } from '../../../../../../UI/index';
import { Link } from 'react-router-dom';
import { Query } from 'rollun-ts-rql';

interface LinkedDealsProps {
  setDisplayName: (displayName: string) => void;
  props?: {
    linkField?: string;
    apiClients?: {
      type: DealType;
      apiClient: {
        getByQuery: <T>(query: Query) => Promise<T>;
      };
      customLinkField?: string;
    }[];
  };
}

const LinkedDeals = ({ setDisplayName, props }: LinkedDealsProps) => {
  const { data, isLoading, isError } = useGetLinkedDeals(
    setDisplayName,
    props?.linkField,
    props?.apiClients,
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return (
      <ErrorView
        error={{
          text: 'Unknown error',
          code: 500,
        }}
      />
    );
  }

  const getUrl = (type: DealType, id: string) => {
    const urlType =
      type === DealType.ReturnOrder
        ? 'returns'
        : type === DealType.ReturnBag
        ? 'return-bags'
        : type === DealType.ReturnPickup
        ? 'return-pickups'
        : type === DealType.ReturnDropship
        ? 'return-dropships'
        : `${type}s`;

    return `/crm/deals/${urlType}/${id}`;
  };

  return (
    <TableContainer component={Box}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Linked Deal Id</TableCell>
            <TableCell align="left">Type</TableCell>
            <TableCell align="left">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(data || []).map((row) => (
            <TableRow key={row.id}>
              <TableCell align="left">
                <Link to={getUrl(row.type, row.id)}>{row.id}</Link>
              </TableCell>
              <TableCell align="left">{row.type}</TableCell>
              <TableCell align="left">{row.statusName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LinkedDeals;
