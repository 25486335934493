import React, { CSSProperties, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { noop } from '../utils/common.utils';

interface IProps {
  onClick?(e: React.MouseEvent): void;

  id?: string;
  disabled?: boolean;
  children?: string | ReactNode | Array<ReactNode>;
  className?: string;
  color?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'info'
    | 'default'
    | 'light'
    | string;
  size?: 'xs' | 'sm' | 'lg';
  block?: boolean;
  title?: string;
  style?: CSSProperties;
  type?: 'submit' | 'reset' | 'button';
  icon?: {
    name: IconProp;
    size?: SizeProp;
  };
}

const Button: React.FC<IProps> = (props) => {
  const {
    onClick = noop,
    id = undefined,
    disabled = false,
    children = 'Click me',
    className = '',
    color = 'default',
    size,
    block = false,
    title = '',
    style = {},
    type = 'button',
    icon,
  } = props;

  let _className = 'btn ';
  _className += size ? `btn-${size}` : '';
  _className += ` btn-${color}`;
  _className += disabled ? ' cursor-not-allowed disabled' : '';
  _className += block ? ' btn-block' : '';
  _className += ` ${className}`;

  return (
    <button
      id={id}
      type={type}
      disabled={disabled}
      style={style}
      title={title}
      className={_className}
      onClick={onClick}
    >
      {icon ? <FontAwesomeIcon icon={icon.name} size={icon.size} /> : children}
    </button>
  );
};

export default Button;
