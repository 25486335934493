import { useMutation, useQueryClient } from 'react-query';
import { secretsApi } from '../api-client';
import { useContext } from 'react';
import { GlobalErrorContext } from '../../RootApp/Root.app';
import { GET_ALL_VERSIONS_OF_SECRET_KEY } from './useGetAllVersionsOfSecret';
import { GET_SECRETS_KEY } from './useGetSecrets';

export const CREATE_SECRET_MUTATION_KEY = 'CREATE_SECRET_MUTATION_KEY';

export const useCreateSecret = () => {
  const alertContext = useContext(GlobalErrorContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: CREATE_SECRET_MUTATION_KEY,
    mutationFn: async ({ name, value }: { name: string; value: string }) => {
      return secretsApi.secretsControllerCreateSecret({
        id: name,
        value,
      });
    },
    onSuccess: async () => {
      alertContext?.showAlert('Secret created successfully', 'success');
      await queryClient.invalidateQueries([
        GET_ALL_VERSIONS_OF_SECRET_KEY,
        GET_SECRETS_KEY,
      ]);
    },
    onError: (error) => {
      alertContext?.showAlert((error as Error).message, 'error');
    },
  });
};
