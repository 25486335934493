import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import CreateOneProduct from './CreateOneProduct';
import HttpDatastore from 'rollun-ts-datastore/dist';
import { httpErrorHandler } from '../../../../utils/common.utils';
import { ErrorType } from '../../../../utils/common.types';
import { Button, ErrorView, Spinner } from '../../../../UI';
import CreateMultipleProducts from './CreateMultipleProducts';

interface IState {
  error: ErrorType | null;
  isLoading: boolean;
  suppliersNames: Array<string>;
}

class CreateProductFromSupplierContainer extends Component<unknown, IState> {
  SupplierList = new HttpDatastore<{ id: string }>(
    '/api/datastore/SupplierList',
  );

  state: IState = {
    isLoading: false,
    error: null,
    suppliersNames: [],
  };

  componentDidMount() {
    this.fetchSuppliersList();
  }

  fetchSuppliersList = () => {
    this.setState({ isLoading: true, error: null });
    this.SupplierList.query()
      .then((res) => this.setState({ suppliersNames: res.map((el) => el.id) }))
      .catch((err) => {
        httpErrorHandler(err, (code, text) => {
          this.setState({ error: { code, text } });
        });
      })
      .finally(() => this.setState({ isLoading: false }));
  };

  render() {
    const { isLoading, error, suppliersNames } = this.state;

    if (error) {
      return (
        <div className="m-2">
          <ErrorView error={error}>
            <Button onClick={this.fetchSuppliersList} color="primary">
              Retry
            </Button>
          </ErrorView>
        </div>
      );
    }

    return (
      <div className="m-2">
        {isLoading ? (
          <div className="py-5">
            <Spinner />
          </div>
        ) : (
          <Tabs id="create-product-from-supplier-tabs" defaultActiveKey="first">
            <Tab eventKey="first" title="Create one Rollun ID">
              <CreateOneProduct suppliersNames={suppliersNames} />
            </Tab>
            <Tab eventKey="second" title="Create multiple Rollun IDs">
              <CreateMultipleProducts suppliersNames={suppliersNames} />
            </Tab>
          </Tabs>
        )}
      </div>
    );
  }
}

export default CreateProductFromSupplierContainer;
