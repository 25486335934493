import React, { Component } from 'react';
import { InputLabel, MenuItem, Select, FormControl } from '@material-ui/core';

interface IProps {
  value: string;
  onChange: (str: string) => void;
  options: Array<string>;
  fieldName: string;
}

// ??????????????

class Selector extends Component<IProps> {
  render() {
    const { value, onChange, options = [], fieldName } = this.props;
    const optionsWithEmptyField = ['', ...options];
    const labelId = `select-${fieldName}`;

    return (
      <FormControl variant="outlined" fullWidth>
        <InputLabel id={labelId}>{fieldName}</InputLabel>
        <Select
          labelId={labelId}
          value={value}
          fullWidth
          variant="outlined"
          label={fieldName}
          onChange={(e) => onChange(e.target.value as string)}
        >
          {optionsWithEmptyField.map((option) => (
            <MenuItem value={option} key={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

export default Selector;
