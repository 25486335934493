import { createContext } from 'react';
import { BagFillingPagesEnum } from './BagFillingPages';
import { GoToNextPage, GoToPage, GoToPreviousPage } from './hooks';

export interface BagFillingContextProps {
  bagId: string;
  page: BagFillingPagesEnum;
  goToNextPage: GoToNextPage;
  goToPage: GoToPage;
  goToPreviousPage: GoToPreviousPage;
  setBagId: (bagId: string) => void;
  setStorage: (location: string) => void;
  storage: string;
}

const BagFillingContext = createContext<Partial<BagFillingContextProps>>({});

export { BagFillingContext };
