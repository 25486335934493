import HttpDatastore from 'rollun-ts-datastore';
import {
  ReturnDropship,
  ReturnDropshipDTO,
  Position,
  Client,
  SPECIAL_ITEMS_IDS,
} from './types';
import ApiClientsPositions from './api-clients-position';
import { Query } from 'rollun-ts-rql';
import ApiClients from './api-clients-clients';
import { compareStringNumbers } from '../common/utils/compareStringNumbers';

const datastore = new HttpDatastore<ReturnDropshipDTO>(
  '/api/datastore/ReturnDropshipsDataStore',
);

export default class ApiClientsReturnDropships {
  apiPositions: ApiClientsPositions;
  apiClients: ApiClients;

  constructor() {
    this.apiPositions = new ApiClientsPositions();
    this.apiClients = new ApiClients();
  }

  async getById(id: string): Promise<ReturnDropship> {
    const dropshipDTO = await datastore.read(id);

    if (!dropshipDTO) {
      throw new Error(`Return dropship with id ${id} not found`);
    }

    const positions = this.apiPositions.getByDealId(id);
    const client = this.apiClients.getById(dropshipDTO.Contractor);

    const order = await Promise.all([positions, client]).then((data) => {
      return this.convertDTOtoModel(dropshipDTO, ...data);
    });

    return order;
  }

  async getByQuery(query: Query): Promise<ReturnDropship[]> {
    const dropshipsDTO = await datastore.query(query);
    return Promise.all(
      dropshipsDTO.map((dropshipDTO) => this.getById(dropshipDTO.Id)),
    );
  }

  async deleteItems(id: string, itemIds: string[]): Promise<void> {
    await Promise.all(
      itemIds.map((itemId) => this.apiPositions.delete(id, itemId)),
    );
  }

  async create(data: Omit<ReturnDropship, 'id'>): Promise<ReturnDropship> {
    const dropshipDTO = this.convertModelToDTO(data);
    const dropship = await datastore.create(dropshipDTO);

    if (data.positions) {
      await this.addItems(dropship.Id, data.positions);
    }

    return this.getById(dropship.Id);
  }

  async addItems(
    id: string,
    items: Omit<Position, 'offerId' | 'dealId' | 'id'>[],
  ): Promise<void> {
    for (const item of items) {
      await this.apiPositions.add(id, item);
    }
  }

  async update(
    id: string,
    data: Partial<Omit<ReturnDropship, 'id' | 'positions'>>,
  ) {
    await datastore.update({
      ...this.convertModelToDTO(data),
      Id: id,
    });
  }

  async addOrUpdateItems(
    id: string,
    items: Omit<Position, 'offerId' | 'dealId' | 'id'>[],
  ) {
    const deal = await this.getById(id);
    const positions = deal.positions;

    const tempSortedItems: Position[] = [];

    for (let i = 0; i < positions.length; i++) {
      const position = { ...positions[i] };

      if (
        tempSortedItems.filter(
          (item) =>
            item.article === position.article &&
            compareStringNumbers(item.cost, position.cost) &&
            item.name === position.name,
        ).length
      )
        continue;

      tempSortedItems.push(position);

      const sameItems = items.filter(
        (item) =>
          item.article === position.article &&
          compareStringNumbers(item.cost, position.cost) &&
          item.name === position.name,
      );
      if (!sameItems.length) continue;

      const allQuantity = sameItems.reduce(
        (prev, cur) => prev + +cur.quantity,
        0,
      );
      position.quantity = +position.quantity + allQuantity;

      await this.apiPositions.update(position);
    }

    const filteredItems = items.filter((item) => {
      for (let i = 0; i < tempSortedItems.length; i++) {
        const position = { ...tempSortedItems[i] };
        if (
          item.article === position.article &&
          compareStringNumbers(item.cost, position.cost) &&
          item.name === position.name
        )
          return false;
      }
      return true;
    });

    if (filteredItems.length) await this.addItems(id, filteredItems);
  }

  async updateClient(id: string, data: Client) {
    await this.apiClients.update(id, data);
  }

  async updateClientByOrderId(orderId: string, data: Client) {
    const { Contractor: clientId } = await datastore.read(orderId);
    await this.apiClients.update(clientId, data);
  }

  convertDTOtoModel(
    returnDropshipDTO: ReturnDropshipDTO,
    positions: Position[],
    client: Client,
  ): ReturnDropship {
    const no_special_items_positions = positions.filter(
      (position) => !SPECIAL_ITEMS_IDS.includes(position.article),
    );

    return {
      id: returnDropshipDTO.Id,
      GUID: returnDropshipDTO.GUID,
      name: returnDropshipDTO.Name,
      contractor: returnDropshipDTO.Contractor,
      timeCreated: returnDropshipDTO.TimeCreated,
      timeUpdated: returnDropshipDTO.TimeUpdated,
      owner: returnDropshipDTO.Owner,
      isDraft: returnDropshipDTO.IsDraft,
      isPaid: returnDropshipDTO.IsPaid,
      finalPrice: returnDropshipDTO.FinalPrice,
      programId: returnDropshipDTO.ProgramId,
      mpName: returnDropshipDTO.MpName,
      mpOrderNumber: returnDropshipDTO.MpOrderNumber,
      mpReturnNumber: returnDropshipDTO.MpReturnNumber,
      srName: returnDropshipDTO.SrName,
      srReturnNumber: returnDropshipDTO.SrReturnNumber,
      mpRetReason: returnDropshipDTO.MpRetReason,
      dealStatus: returnDropshipDTO.DealStatus,
      returnLocation: returnDropshipDTO.ReturnLocation,
      labelCreator: returnDropshipDTO.LabelCreator,
      tracknumber: returnDropshipDTO.Tracknumber,
      carrier: returnDropshipDTO.Carrier,
      shipStatus: returnDropshipDTO.ShipStatus,
      returnCondition: returnDropshipDTO.ReturnCondition,
      srRefundedSum: returnDropshipDTO.SrRefundedSum,
      problemDescription: returnDropshipDTO.ProblemDescription,
      mpUaId: returnDropshipDTO.MpUaId,
      manager: returnDropshipDTO.Manager,
      status: returnDropshipDTO.Status,
      statusName: returnDropshipDTO.StatusName,
      programName: returnDropshipDTO.ProgramName,
      relatedReturn: returnDropshipDTO.RelatedReturn,
      positions,
      no_special_items_positions,
      client: client,
    };
  }

  convertModelToDTO(
    returnDropship: Partial<
      Omit<ReturnDropship, 'id' | 'positions' | 'client'>
    >,
  ): Partial<Omit<ReturnDropshipDTO, 'Id'>> {
    return {
      GUID: returnDropship.GUID,
      Name: returnDropship.name,
      Contractor: returnDropship.contractor,
      TimeCreated: returnDropship.timeCreated,
      TimeUpdated: returnDropship.timeUpdated,
      Owner: returnDropship.owner,
      IsDraft: returnDropship.isDraft,
      IsPaid: returnDropship.isPaid,
      FinalPrice: returnDropship.finalPrice,
      ProgramId: returnDropship.programId,
      MpName: returnDropship.mpName,
      MpOrderNumber: returnDropship.mpOrderNumber,
      MpReturnNumber: returnDropship.mpReturnNumber,
      SrName: returnDropship.srName,
      SrReturnNumber: returnDropship.srReturnNumber,
      MpRetReason: returnDropship.mpRetReason,
      DealStatus: returnDropship.dealStatus,
      ReturnLocation: returnDropship.returnLocation,
      LabelCreator: returnDropship.labelCreator,
      Tracknumber: returnDropship.tracknumber,
      Carrier: returnDropship.carrier,
      ShipStatus: returnDropship.shipStatus,
      ReturnCondition: returnDropship.returnCondition,
      SrRefundedSum: returnDropship.srRefundedSum,
      ProblemDescription: returnDropship.problemDescription,
      MpUaId: returnDropship.mpUaId,
      Manager: returnDropship.manager,
      Status: returnDropship.status,
      RelatedReturn: returnDropship.relatedReturn,
      StatusName: returnDropship.statusName,
      ProgramName: returnDropship.programName,
    };
  }
}

export const apiClientsReturnDropship = new ApiClientsReturnDropships();
