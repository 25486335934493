import { Problem } from './../../api-clients/types';
import { apiClientsProblems } from '../../api-clients/api-clients-problems';

export const useMutateDeal = () => {
  const handleUpdateDeal = async (
    id: string,
    data: Partial<Omit<Problem, 'id' | 'positions'>>,
  ) => {
    await apiClientsProblems.update(id, data);
  };

  return {
    handleUpdateDeal,
  };
};
