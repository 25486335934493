// For some reason webpack could not import types from this file when it was .d.ts

export enum LoadingStatusEnum {
  loading = 'loading',
  loaded = 'loaded',
  error = 'error',
  empty = 'empty',
}

export enum ComponentAction {
  LOADING = 'loading',
  LOADED = 'loaded',
  ERROR = 'error',
}

export interface ErrorType {
  code: number;
  text: string;
}

export type REACT_SELECT_ANY = any;

export const SUPPLIER_NAMES = [
  'RockyMountain',
  'PartsUnlimited',
  'TuckerRocky',
] as const;

export enum Senders {
  PickupKy = 'pickup_ky',
  PickupUt = 'pickup_ut',
  PickupTx = 'pickup_tx',
  PickupWi = 'pickup_wi',
  PickupCn = 'pickup_cn',
}

export type Supplier = typeof SUPPLIER_NAMES[number];

export type CompatibleVehicle = {
  make: string;
  model_slim: string;
  submodel: string;
  year: string;
};
