import useHttpDataStore from '../useHttpDatastore';

interface ResourceDatastore {
  id: string;
  name: string;
  pattern: string;
  parent_id: string | null;
}

const useResourceDatastore = () => {
  return useHttpDataStore<ResourceDatastore>(
    '/api/datastore/resourceDataStore',
  );
};

export { useResourceDatastore };
