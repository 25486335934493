import { logger } from '../../../utils/logger';
import ApiClientsBags from '../../CRMDeals/components/api-clients/api-clients-bags';
import { Query, In, And, Not, Eq, Select } from 'rollun-ts-rql';

const updateBagPackageStatus = async (orderNumbers: Array<string>) => {
  await updateStatus(orderNumbers).catch((error) => {
    logger.error('UpdateBagPackageStatus Error', error);
  });
};

/**
 * Update 'Bag' deal in megaplan that is the same as order in this app
 * @param orderNumbers
 */

const bagsApi = new ApiClientsBags();

const updateStatus = async (orderNumbers: Array<string>) => {
  const bags = await bagsApi.getByQuery(
    new Query()
      .setQuery(
        new And([
          new In('SrOrderNumber', orderNumbers),
          new Not([new Eq('Status', '24')]),
        ]),
      )
      .setSelect(new Select(['Id', 'SrOrderNumber'])),
  );

  const promises = [];

  for (const orderNumber of orderNumbers) {
    const bag = bags.find(
      (bag) => bag.srOrderNumber?.toString() === orderNumber,
    );
    if (!bag) {
      logger.error('updateBagPackageStatus', {
        reason: 'bag not found',
        orderNumber,
      });
      continue;
    }
    promises.push(bagsApi.update(bag.id, { bagStatus: 'packed bag' }));
  }

  const results = await Promise.allSettled(promises);

  for (const result of results) {
    if (result.status === 'rejected') {
      logger.error('updateBagPackageStatus', {
        reason: result.reason,
      });
    }
  }
};

export default updateBagPackageStatus;
