import React, { FC, useContext, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import { AppConfig } from '../../../../AbstractService';
import { replaceSlashWithDash } from './RulesEditor';
import Select from 'react-select';
import MuiButton from '../../../../../UI/MuiButton';
import {
  privilegesVariants,
  SelectorProps,
} from '../../CreateService/AddRoute';
import { zIndexes } from '../../../../../themeProvider';
import { GlobalErrorContext } from '../../../../RootApp/Root.app';
import RulesTreeEditor from '../../../../ACLRulesEditor/components/RulesTreeEditor';
import {
  useRouteDatastore,
  useResourceDatastore,
} from '../../../../../hooks/datastores';

export type Steps = 'add-resource' | 'rules';

interface CreateResourceDsProps {
  isOpen: boolean;
  setIsOpen: () => void;
  appParams: AppConfig;
}

const CreateResourceDs: FC<CreateResourceDsProps> = ({
  isOpen,
  setIsOpen,
  appParams,
}) => {
  const [step, setStep] = useState<Steps>('add-resource');
  const [{ status }, routeDispatch] = useRouteDatastore();
  const [
    { status: resourceStatus, result: resourceResult },
    resourceDispatch,
  ] = useResourceDatastore();
  const globalErrorContext = useContext(GlobalErrorContext);
  const [privileges, setPrivileges] = useState<SelectorProps[]>(
    privilegesVariants,
  );
  const [path, setPath] = useState(
    appParams.appParams.gridParams?.datastoreUrl || '',
  );
  const [name, setName] = useState(
    replaceSlashWithDash(appParams.appParams.gridParams?.datastoreUrl),
  );

  const createResource = () => {
    if (!name || !path || !privileges) {
      globalErrorContext?.showAlert(
        'Cannot create resource with one of the field empty',
        'error',
      );
      return;
    }

    let methods = '';
    privileges.forEach((el, index) => {
      methods += el.label + (index === privileges.length - 1 ? '' : ',');
    });
    routeDispatch('CREATE', {
      path,
      name,
      middleware: 'rollun\\datastore\\Middleware\\DataStoreApi',
      methods,
    });
    resourceDispatch('CREATE', {
      name,
      pattern: name,
      parent_id: null,
    });
    setStep('rules');
  };

  const steps = {
    'add-resource': (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="path"
              label="path"
              fullWidth
              placeholder="Path of the resource"
              variant="outlined"
              onChange={(e) => setPath(e.target.value)}
              value={path}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="name"
              label="name"
              fullWidth
              placeholder="Name of the resource"
              variant="outlined"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              menuPlacement="top"
              style={{ zIndex: zIndexes.biggest }}
              value={privileges}
              isMulti
              options={privilegesVariants}
              onChange={(data: any) => {
                console.log(data);
                setPrivileges(data || []);
              }}
            />
          </Grid>
        </Grid>
      </Box>
    ),
    rules: resourceResult && resourceResult[0].id && (
      <RulesTreeEditor resourceId={resourceResult[0].id} />
    ),
  };

  if (resourceResult) {
    console.log(resourceResult[0].id);
  }
  return (
    <Dialog open={isOpen} onClose={setIsOpen}>
      <DialogTitle>Create resource for datastore</DialogTitle>
      <Box marginY={2} marginX={4}>
        {steps[step]}
      </Box>
      <DialogActions>
        <MuiButton
          color="secondary"
          onClick={() => setStep('add-resource')}
          disabled={step === 'add-resource'}
        >
          Previous
        </MuiButton>
        <MuiButton
          color="primary"
          disabled={resourceStatus === 'loading' || status === 'loading'}
          onClick={() => {
            if (step === 'rules') {
              setIsOpen();
              setStep('add-resource');
              return;
            }

            createResource();
          }}
        >
          {resourceStatus === 'loading' || status === 'loading'
            ? 'Loading...'
            : step === 'rules'
            ? 'Close'
            : 'Next'}
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateResourceDs;
