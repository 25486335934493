import React, { useContext } from 'react';
import { CreateServiceWithResourceContext } from './CreateServiceWithResource';
import { Box } from '@material-ui/core';
import EditableTree from '../../../EditableTree/components/EditableTree';
import MuiButton from '../../../../UI/MuiButton';

const PutInMenu = () => {
  const createService = useContext(CreateServiceWithResourceContext);

  return (
    <Box>
      <MuiButton
        color="primary"
        style={{ float: 'right' }}
        onClick={() => createService?.resetService()}
      >
        Finish
      </MuiButton>
      <EditableTree
        treeDataField="url"
        treeNameField="label"
        appName="Place your node in menu"
        dataStoreURL="/api/datastore/menuForUserRole_db"
      />
    </Box>
  );
};

export default PutInMenu;
