import React from 'react';
import withControls from './withControls';
import { IRendererProps } from './DefaultRenderer';

const BooleanRenderer: React.FC<IRendererProps> = ({ value }) => {
  const stringValue = `${value}`;
  if (stringValue === '1') {
    return <div>TRUE</div>;
  }
  if (stringValue === '0') {
    return <div>FALSE</div>;
  }
  return <div>Invalid bool value: {value}</div>;
};

export default withControls(BooleanRenderer);
