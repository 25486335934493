import { GoodsItemProps } from './LabelGoodsToList';
import { LABEL_PACKED, LABEL_PROBLEM_WITH_PRODUCT } from '../constants';
import { Query, And, Not, Eq, Select } from 'rollun-ts-rql';
import ApiClientsPickups from '../../CRMDeals/components/api-clients/api-clients-pickups';
import { logger } from '../../../utils/logger';

const pickupsApi = new ApiClientsPickups();

async function updateStatus(
  trackNumber: string,
  problemDescription: string,
  status: string,
) {
  const [pickup] = await pickupsApi.getByQuery(
    new Query()
      .setQuery(
        new And([
          new Eq('Tracknumber', trackNumber),
          new Not([new Eq('Status', '24')]),
        ]),
      )
      .setSelect(new Select(['Id'])),
  );

  if (!pickup) {
    return;
  }

  await pickupsApi.update(pickup.id, {
    ...(status === LABEL_PACKED
      ? { wasPacked: 'yes', problemDescription: '-' }
      : { problemDescription }),
  });
}

const updatePickUpPackageStatus = async (
  trackNumber: string,
  status: string,
  listOfItems: Array<GoodsItemProps>,
  note?: string,
) => {
  let problemDescription = '';
  if (status !== LABEL_PACKED) {
    problemDescription +=
      status !== LABEL_PROBLEM_WITH_PRODUCT ? 'Problem with label\n' : '';
    problemDescription += `${status}\n`;
    problemDescription += note ? `Note: ${note}\n` : '';
    if (status === LABEL_PROBLEM_WITH_PRODUCT) {
      listOfItems.forEach((item) => {
        if (item.status === 'ban') {
          problemDescription += `Item: ${item.rollun_id} ${item.part_number}\n`;
          if (item.problem_report) {
            problemDescription += `Status: ${item.problem_report.type}\n`;
            problemDescription += item.problem_report.note
              ? `Note: ${item.problem_report.note}\n`
              : '';
          }
        }
      });
    }
  }

  await updateStatus(trackNumber, problemDescription, status).catch((error) => {
    logger.error('UpdatePickUpPackageStatus Error', error);
  });
};

export default updatePickUpPackageStatus;
