import { Position } from '../../api-clients/types';
import HttpDatastore from 'rollun-ts-datastore';
import { Eq, Query } from 'rollun-ts-rql';
import ApiClientsOffers from '../../api-clients/api-clients-offers';

export const checkIfInCatalog = async (rollunId: string) => {
  return await new HttpDatastore('/api/datastore/CatalogDataStore').read(
    rollunId,
  );
};

export const fetchRollunIdByASIN = async (asin: string | null | undefined) => {
  if (!asin) return;
  const items = await new HttpDatastore(
    '/api/datastore/AmazonMsinRidAccordance',
  ).query(new Query().setQuery(new Eq('msin', asin)));

  if (items.length > 0) {
    return items[0].rid;
  }

  throw new Error(`Item with SKU ${asin} not found in Rollun Catalog`);
};

export const checkIfExistsInMP = async (rid: string) => {
  return await new ApiClientsOffers().getByRID(rid);
};

export async function validatePositions(positions: Position[]) {
  return await Promise.all(
    positions.map(async (position) => {
      const article = position.article;
      const catalogItem = await checkIfInCatalog(article);
      if (!catalogItem) {
        throw new Error(`Couldn't find Rollun ID [${article}]`);
      }

      const isInMegaplan = await checkIfExistsInMP(article);
      if (
        !isInMegaplan &&
        !confirm(`
          Product not found in Megaplan catalog, add it?
      `)
      ) {
        throw new Error(
          `Couldn't find Product in Megaplan by article [${article}]`,
        );
      }
    }),
  );
}

export const matchRegExp = (
  input: string | null | undefined,
  regexp: RegExp,
  index = 1,
) => {
  const match = input?.match(regexp);
  return match ? match[index] : undefined;
};
