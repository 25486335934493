import React, { PureComponent } from 'react';
import { ShippingLabel } from '../utils/types';
import { GoodsItemProps } from '../utils/LabelGoodsToList';
import WrongPackageItem from './WrongPackageItem';
import { LABEL_DOES_NOT_FIT } from '../constants';
import { Button } from '../../../UI';
import { Senders } from '../../../utils/common.types';

export interface WrongPackageProps {
  currentLabelGoods: GoodsItemProps[];
  currentLabel: ShippingLabel;
  senderName: Senders;
  order: { id: string; supplier: string };

  setLabelAndProductStatus(
    listOfGoods: GoodsItemProps[],
    labelStatus?: string,
    note?: string,
  ): void;
}

interface IState {
  currentItemIndex: number;
  allGoodsScanned: boolean;
  selectedPackageType: string;
}

export default class WrongPackage extends PureComponent<
  WrongPackageProps,
  IState
> {
  availablePackages = [
    '',
    'Other packaging',
    'First-Class Package Service',
    'Priority Mail',
    'Priority Mail Flat Rate Envelope',
    'Priority Mail Legal Flat Rate Envelope',
    'Priority Mail Flat Rate Padded Envelope',
    'Priority Mail Small Flat Rate Box',
    'Priority Mail Medium Flat Rate Box',
    'Priority Mail Large Flat Rate Box',
    'Priority Mail Large Flat Rate Board Game Box',
    'Priority Mail Regional Rate Box A',
    'Priority Mail Regional Rate Box B',
    'Parcel Select Ground',
  ];

  state = {
    allGoodsScanned: false,
    currentItemIndex: 0,
    selectedPackageType: this.availablePackages[0],
  };

  listOfGoods: GoodsItemProps[];

  constructor(props: any) {
    super(props);
    // Filter out same goods
    this.listOfGoods = this.props.currentLabelGoods.reduce(
      (acc: Array<GoodsItemProps>, good) => {
        return acc.find((el) => el.part_number === good.part_number)
          ? acc
          : [...acc, good];
      },
      [],
    );
  }

  onPackageTypeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ selectedPackageType: e.target.value });
  };

  render() {
    const { currentItemIndex, allGoodsScanned } = this.state;
    return (
      <>
        <div className="main-layout">
          <div className="block-layout">
            <WrongPackageItem
              order={this.props.order}
              currentItem={this.listOfGoods[currentItemIndex]}
              allGoodScanned={this.state.allGoodsScanned}
              senderName={this.props.senderName}
              onAccept={(problemWithGood: boolean) => {
                if (problemWithGood) {
                  this.listOfGoods[currentItemIndex].status = 'not fit';
                }
                const nextIndex = this.state.currentItemIndex + 1;
                if (nextIndex === this.listOfGoods.length) {
                  this.setState({ allGoodsScanned: true });
                } else {
                  this.setState({ currentItemIndex: nextIndex });
                }
              }}
            />
          </div>
          {allGoodsScanned ? (
            <div>
              <h3>Select package type</h3>
              <div>
                Please, choose package, that in Your opinion is appropriate for
                this package
              </div>
              <select
                className="custom-select my-2"
                value={this.state.selectedPackageType}
                onChange={this.onPackageTypeSelect}
              >
                {this.availablePackages.map((item) => (
                  <option key={item}>{item}</option>
                ))}
              </select>
              <Button
                disabled={this.state.selectedPackageType === ''}
                color="danger"
                block
                onClick={() => {
                  this.props.setLabelAndProductStatus(
                    this.listOfGoods,
                    LABEL_DOES_NOT_FIT,
                    this.state.selectedPackageType,
                  );
                }}
              >
                Put aside and scan the next label
              </Button>
            </div>
          ) : null}
        </div>
      </>
    );
  }
}
