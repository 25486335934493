import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from '../../../../../UI/Spinner';
import GenericDeal, { DealProps, DealType } from '../../GenericDeal';
import { useGetOrderDeal } from '../hooks/useGetOrderDeal';
import { ErrorView } from '../../../../../UI';
import { useMutateItems } from '../hooks/useMutateItems';
import { useMutateDeal } from '../hooks/useMutateDeal';
import { Box } from '@material-ui/core';
import MuiButton from '../../../../../UI/MuiButton';
import { useMutateClient } from '../../GenericDeal/hooks/useMutateClient';
import { apiClientOrders } from '../../api-clients/api-clients-orders';

const OrderDeal: FC<DealProps> = ({ buttons, fieldsConfig, widgets }) => {
  const { id } = useParams<{ id: string }>();
  const { data, error, isFetching } = useGetOrderDeal(id);
  const { handleAddItems, handleDeleteItems } = useMutateItems();
  const { handleUpdateClient } = useMutateClient();
  const { handleUpdateDeal } = useMutateDeal();
  const history = useHistory();

  if (isFetching && data?.id !== id) {
    return <Spinner />;
  }

  if (error && !data) {
    return (
      <ErrorView
        error={{
          text: (error as Error).message,
          code: (error as Error).message.includes('not found') ? 404 : 403,
        }}
      >
        <Box
          style={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <MuiButton onClick={() => history.goBack()}>Back</MuiButton>
          <MuiButton onClick={() => location.reload()}>Reload</MuiButton>
        </Box>
      </ErrorView>
    );
  }

  return (
    <GenericDeal
      data={data!}
      rawData={data ? apiClientOrders.convertModelToDTO(data!) : {}}
      type={DealType.Order}
      buttons={buttons}
      fieldsConfig={fieldsConfig}
      widgets={widgets}
      onItemsAdd={handleAddItems}
      onItemsDelete={handleDeleteItems}
      onDealUpdate={handleUpdateDeal}
      onClientUpdate={handleUpdateClient}
      availableStatuses={[
        {
          id: '12',
          name: 'Order creation',
          color: '#D93222',
        },
        {
          id: '13',
          name: 'Pending',
          color: '#4F3BFF',
        },
        {
          id: '14',
          name: 'Parcels preparation',
          color: '#F28411',
        },
        {
          id: '15',
          name: '60 day test',
          color: '#00B59B',
        },
        {
          id: '16',
          name: 'Archive',
          color: '#E3DB47',
        },
      ]}
    />
  );
};

export default OrderDeal;
