import React, { createContext, useEffect, useMemo, useState } from 'react';

export const JSONataContext = createContext<{
  data: null | any;
  setData: React.Dispatch<any>;
  isOpen: null | boolean;
  setIsOpen: React.Dispatch<boolean>;
}>({
  data: null,
  isOpen: null,
  setData: () => {
    return;
  },
  setIsOpen: () => {
    return;
  },
});

interface JSONataProviderProps {
  initialValue: any;
}

export const JSONataProvider: React.FC<JSONataProviderProps> = ({
  initialValue,
  children,
}) => {
  const [data, setData] = useState(initialValue);
  const [isOpen, setIsOpen] = useState(false);
  const value = useMemo(() => ({ data, setData, isOpen, setIsOpen }), [
    data,
    isOpen,
  ]);

  useEffect(() => {
    setData(initialValue);
  }, [initialValue]);

  return (
    <JSONataContext.Provider value={value}>{children}</JSONataContext.Provider>
  );
};
