import React, { FC, useContext } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import MuiButton from '../../../../UI/MuiButton';
import Query from 'rollun-ts-rql/dist/Query';
import Eq from 'rollun-ts-rql/dist/nodes/scalarNodes/Eq';
import { GlobalErrorContext } from '../../../RootApp/Root.app';
import { CreateServiceWithResourceContext } from './CreateServiceWithResource';
import { useResourceDatastore } from '../../../../hooks/datastores';
import MuiTooltip from '../../../../UI/MuiTooltip';
import { Help } from '@material-ui/icons';

import { SERVICE_CONSTRUCTOR_FORM_HELPER_TEXTS } from '../../constants';

const {
  ADD_RESOURCE: { NAME, PATTERN },
} = SERVICE_CONSTRUCTOR_FORM_HELPER_TEXTS;

interface CreateResourceProps {
  name: string;
}

const CreateResource: FC<CreateResourceProps> = ({ name }) => {
  const [{ error, status }, dispatch] = useResourceDatastore();
  const globalError = useContext(GlobalErrorContext);
  const createService = useContext(CreateServiceWithResourceContext);

  const checkIfExists = async (name: string) => {
    const res = await dispatch(
      'QUERY',
      new Query({
        query: new Eq('name', name),
      }),
    );

    return res && res.length !== 0;
  };

  const handleCreateResource = async () => {
    if (await checkIfExists(name)) {
      globalError?.showAlert(
        'The resource already exists. Cannot create one.',
        'error',
      );
      return;
    }

    const res = await dispatch('CREATE', {
      name,
      pattern: name,
      parent_id: null,
    });
    // create resource for datastore
    const datastoreResourceId = await dispatch('CREATE', {
      name: `api-datastore-${name}`,
      pattern: `api-datastore-${name}`,
      parent_id: null,
    });

    createService?.setResourceId(
      (res && res[0] && res[0].id) || '',
      'create-route',
    );
    createService?.setServiceConfig(
      {
        name: createService?.serviceConfig?.name || '',
        path: createService?.serviceConfig?.path || '',
        datastore: {
          resourceId: (datastoreResourceId && datastoreResourceId[0].id) || '',
          routeId: createService?.routeId,
        },
      },
      'set-rules',
    );
  };

  return (
    <Box>
      <Box marginTop={2} marginBottom={3}>
        <Typography variant="h5">Create a resource</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            value={name}
            disabled
            fullWidth
            label="Name"
            InputProps={{
              endAdornment: (
                <MuiTooltip title={NAME} arrow>
                  <InputAdornment position="end">
                    <Help />
                  </InputAdornment>
                </MuiTooltip>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={name}
            disabled
            fullWidth
            label="Pattern"
            InputProps={{
              endAdornment: (
                <MuiTooltip title={PATTERN} arrow>
                  <InputAdornment position="end">
                    <Help />
                  </InputAdornment>
                </MuiTooltip>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {error && (
            <Typography align="center" color="error">
              {error.text}
            </Typography>
          )}
          <MuiButton onClick={handleCreateResource} color="success" fullWidth>
            {status === 'loading' ? (
              <CircularProgress size="1.5rem" />
            ) : (
              'Create a resource'
            )}
          </MuiButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateResource;
