import React, { useEffect } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { Formik } from 'formik';
import MuiIconButton from './MuiIconButton';
import { formatBarcode } from '../pages/ReturnApp/utils';

export type BarcodeInputProps = {
  onSubmit: (barcode: string) => void;
  onClear?: () => void;
  barcode?: string;
  onChange?: (barcode: string) => void;
};

export function BarcodeInput(props: BarcodeInputProps) {
  const [
    knownCarrierCodeDetected,
    setKnownCarrierCodeDetected,
  ] = React.useState('');

  return (
    <Formik
      initialValues={{ barcode: props.barcode || '' }}
      onSubmit={(result) => props.onSubmit(result.barcode)}
    >
      {({ handleChange, handleSubmit, values, handleBlur, setFieldValue }) => {
        useEffect(() => {
          if (props.barcode !== undefined) {
            setFieldValue('barcode', props.barcode);
          }
        }, [props.barcode]);

        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <TextField
                  fullWidth={true}
                  variant="outlined"
                  disabled={false}
                  placeholder="enter barcode ..."
                  onBlur={handleBlur}
                  onChange={(event) => {
                    const { carrier, code } = formatBarcode(
                      event.target.value,
                      'CODE_128',
                    );
                    if (carrier === 'unknown') {
                      setKnownCarrierCodeDetected('');
                      handleChange(event);
                      props.onChange?.(event.target.value);
                    } else {
                      setKnownCarrierCodeDetected(carrier);
                      setFieldValue('barcode', code);
                      props.onChange?.(code);
                    }
                  }}
                  value={values.barcode}
                  name="barcode"
                  type="text"
                  size="small"
                  autoFocus
                />
              </Grid>
              <Grid item xs={2}>
                <MuiIconButton
                  iconName="ban"
                  color="error"
                  disabled={values.barcode.length === 0}
                  onClick={() => {
                    setKnownCarrierCodeDetected('');
                    setFieldValue('barcode', '');
                    props.onClear?.();
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <MuiIconButton
                  disabled={values.barcode.length === 0}
                  color="success"
                  type="submit"
                  iconName="play"
                />
              </Grid>
            </Grid>
            {knownCarrierCodeDetected && (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  Code from {knownCarrierCodeDetected} detected, replacing input
                  value
                </Grid>
              </Grid>
            )}
          </form>
        );
      }}
    </Formik>
  );
}
