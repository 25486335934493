function _getStateAbbr(stateInfo: string) {
  const twoLetterState = stateInfo.match(/ [a-z]{2} /i);
  if (twoLetterState) {
    return twoLetterState[0].trim().toUpperCase();
  }
  const fullNameStateInfo = states.find(
    (state) =>
      state.State.toLocaleLowerCase().replace(/\s/g, '') ===
      stateInfo.trim().toLocaleLowerCase().replace(/\s/g, ''),
  );
  if (fullNameStateInfo) {
    return fullNameStateInfo.Code;
  }
  return null;
}

export function parseClientLocation(str: string) {
  const [city, stateInfo = ''] = str.split(',');
  const [zip] = stateInfo.match(/[0-9\-]+/i) || [null];
  const rowStateName = stateInfo.replace(/[0-9\-]+/i, '');
  return {
    city,
    state: _getStateAbbr(rowStateName),
    zip,
  };
}

export const states = [
  {
    State: 'Alabama',
    Abbrev: 'Ala.',
    Code: 'AL',
  },
  {
    State: 'Alaska',
    Abbrev: 'Alaska',
    Code: 'AK',
  },
  {
    State: 'Arizona',
    Abbrev: 'Ariz.',
    Code: 'AZ',
  },
  {
    State: 'Arkansas',
    Abbrev: 'Ark.',
    Code: 'AR',
  },
  {
    State: 'California',
    Abbrev: 'Calif.',
    Code: 'CA',
  },
  {
    State: 'Colorado',
    Abbrev: 'Colo.',
    Code: 'CO',
  },
  {
    State: 'Connecticut',
    Abbrev: 'Conn.',
    Code: 'CT',
  },
  {
    State: 'Delaware',
    Abbrev: 'Del.',
    Code: 'DE',
  },
  {
    State: 'District of Columbia',
    Abbrev: 'D.C.',
    Code: 'DC',
  },
  {
    State: 'Florida',
    Abbrev: 'Fla.',
    Code: 'FL',
  },
  {
    State: 'Georgia',
    Abbrev: 'Ga.',
    Code: 'GA',
  },
  {
    State: 'Hawaii',
    Abbrev: 'Hawaii',
    Code: 'HI',
  },
  {
    State: 'Idaho',
    Abbrev: 'Idaho',
    Code: 'ID',
  },
  {
    State: 'Illinois',
    Abbrev: 'Ill.',
    Code: 'IL',
  },
  {
    State: 'Indiana',
    Abbrev: 'Ind.',
    Code: 'IN',
  },
  {
    State: 'Iowa',
    Abbrev: 'Iowa',
    Code: 'IA',
  },
  {
    State: 'Kansas',
    Abbrev: 'Kans.',
    Code: 'KS',
  },
  {
    State: 'Kentucky',
    Abbrev: 'Ky.',
    Code: 'KY',
  },
  {
    State: 'Louisiana',
    Abbrev: 'La.',
    Code: 'LA',
  },
  {
    State: 'Maine',
    Abbrev: 'Maine',
    Code: 'ME',
  },
  {
    State: 'Maryland',
    Abbrev: 'Md.',
    Code: 'MD',
  },
  {
    State: 'Massachusetts',
    Abbrev: 'Mass.',
    Code: 'MA',
  },
  {
    State: 'Michigan',
    Abbrev: 'Mich.',
    Code: 'MI',
  },
  {
    State: 'Minnesota',
    Abbrev: 'Minn.',
    Code: 'MN',
  },
  {
    State: 'Mississippi',
    Abbrev: 'Miss.',
    Code: 'MS',
  },
  {
    State: 'Missouri',
    Abbrev: 'Mo.',
    Code: 'MO',
  },
  {
    State: 'Montana',
    Abbrev: 'Mont.',
    Code: 'MT',
  },
  {
    State: 'Nebraska',
    Abbrev: 'Nebr.',
    Code: 'NE',
  },
  {
    State: 'Nevada',
    Abbrev: 'Nev.',
    Code: 'NV',
  },
  {
    State: 'New Hampshire',
    Abbrev: 'N.H.',
    Code: 'NH',
  },
  {
    State: 'New Jersey',
    Abbrev: 'N.J.',
    Code: 'NJ',
  },
  {
    State: 'New Mexico',
    Abbrev: 'N.M.',
    Code: 'NM',
  },
  {
    State: 'New York',
    Abbrev: 'N.Y.',
    Code: 'NY',
  },
  {
    State: 'North Carolina',
    Abbrev: 'N.C.',
    Code: 'NC',
  },
  {
    State: 'North Dakota',
    Abbrev: 'N.D.',
    Code: 'ND',
  },
  {
    State: 'Ohio',
    Abbrev: 'Ohio',
    Code: 'OH',
  },
  {
    State: 'Oklahoma',
    Abbrev: 'Okla.',
    Code: 'OK',
  },
  {
    State: 'Oregon',
    Abbrev: 'Ore.',
    Code: 'OR',
  },
  {
    State: 'Pennsylvania',
    Abbrev: 'Pa.',
    Code: 'PA',
  },
  {
    State: 'Rhode Island',
    Abbrev: 'R.I.',
    Code: 'RI',
  },
  {
    State: 'South Carolina',
    Abbrev: 'S.C.',
    Code: 'SC',
  },
  {
    State: 'South Dakota',
    Abbrev: 'S.D.',
    Code: 'SD',
  },
  {
    State: 'Tennessee',
    Abbrev: 'Tenn.',
    Code: 'TN',
  },
  {
    State: 'Texas',
    Abbrev: 'Tex.',
    Code: 'TX',
  },
  {
    State: 'Utah',
    Abbrev: 'Utah',
    Code: 'UT',
  },
  {
    State: 'Vermont',
    Abbrev: 'Vt.',
    Code: 'VT',
  },
  {
    State: 'Virginia',
    Abbrev: 'Va.',
    Code: 'VA',
  },
  {
    State: 'Washington',
    Abbrev: 'Wash.',
    Code: 'WA',
  },
  {
    State: 'West Virginia',
    Abbrev: 'W.Va.',
    Code: 'WV',
  },
  {
    State: 'Wisconsin',
    Abbrev: 'Wis.',
    Code: 'WI',
  },
  {
    State: 'Wyoming',
    Abbrev: 'Wyo.',
    Code: 'WY',
  },
];
