import React, { FC, memo, useContext, useEffect, useState } from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import AdditionalFields from './AdditionalFields';
import { DealProps, DealType } from '../index';
import MuiButton from '../../../../../UI/MuiButton';
import FullProductsView from './FullProductsView';
import AddItemModal from './AddItemModal';
import DealWidgets, { WidgetProps } from './DealWidgets';
import { Deal, Bag, Position, SortedPosition } from '../../api-clients/types';
import MuiIconButton from '../../../../../UI/MuiIconButton';
import { DealContext } from '../context/dealContext';
import { queryClient } from '../../../../../queryClient';
import ItemsTable from './ItemsTable';
import ApiClientsPositions from '../../api-clients/api-clients-position';
import { ActivityLogs } from './ActivityLogs/ActivityLogs';

interface PositionsTableProps {
  deal: Deal;
  fieldsConfig: DealProps['fieldsConfig'];
  widgets: WidgetProps[];
  type: DealType;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    gap: 8,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  inputs: {
    display: 'flex',
    gap: 16,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
}));

const PositionsTable: FC<PositionsTableProps> = ({
  deal,
  fieldsConfig,
  type,
  widgets,
}) => {
  const [viewMode, setViewMode] = useState<'simple' | 'full'>('simple');
  const [selectedItem, setSelectedItem] = useState<Position | null>(null);
  const [loading, setLoading] = useState(false);
  const [sortedPositions, setSortedPositions] = useState<SortedPosition[]>([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [refreshPositions, setRefreshPositions] = useState(false);
  const [positions, setPositions] = useState<Position[]>([]);
  const dealContext = useContext(DealContext);
  const classes = useStyles();

  const {
    positions: dealPositions = [],
    no_special_items_positions = [],
  } = deal as Bag;
  useEffect(() => {
    setPositions(dealPositions);
    setRefreshPositions(true);
  }, [dealPositions]);

  useEffect(() => {
    if (!refreshPositions) return;
    setRefreshPositions(false);
    const tempSortedPositions: SortedPosition[] = [];

    for (let i = 0; i < positions.length; i++) {
      const position = { ...positions[i] } as SortedPosition;
      position.total = (+position.cost * +position.quantity).toFixed(2);

      tempSortedPositions.push(position);
    }

    setSortedPositions(tempSortedPositions);
    setTotalPrice(
      +tempSortedPositions
        .reduce((prev, cur) => prev + +cur.total, 0)
        .toFixed(2),
    );
  }, [positions]);

  useEffect(() => {
    return () => {
      setPositions([]);
    };
  }, []);

  useEffect(() => {
    if (!refreshPositions) {
      return;
    }

    new ApiClientsPositions()
      .getByDealId(deal.id)
      .then((res) => setPositions(res));
  }, [refreshPositions]);

  const handleViewModeChange = () => {
    setViewMode(viewMode === 'simple' ? 'full' : 'simple');
  };

  const handleRemoveItem = async () => {
    if (selectedItem === null) {
      alert('Please select item first');
      return;
    }

    setLoading(true);
    try {
      await dealContext?.onItemsDelete?.(deal.id, [selectedItem.id]);
      await queryClient.invalidateQueries('GET_DEAL_KEY');
    } catch (e) {
      alert((e as any).message || 'Something went wrong');
    } finally {
      setLoading(false);
      setSelectedItem(null);
    }
  };

  const onPositionsUpdate = () => {
    setRefreshPositions(true);
  };

  return (
    <Box className={classes.root}>
      <Box
        flex={3}
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          minWidth: 300,
          minHeight: 500,
          padding: 8,
          gap: 8,
        }}
      >
        <Box>
          <DealWidgets widgets={widgets} />
        </Box>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <MuiButton
            style={{ height: 33.42, width: 184 }}
            color="primary"
            onClick={handleViewModeChange}
          >
            {viewMode === 'simple' ? 'Show items cards' : 'back items table'}
          </MuiButton>
          <Box>
            <MuiIconButton
              iconName="trash-alt"
              color="error"
              title="Remove selected item"
              onClick={handleRemoveItem}
              disabled={loading}
            />
            <AddItemModal type={type} />
          </Box>
        </Box>
        {viewMode === 'simple' ? (
          <ItemsTable
            positions={sortedPositions}
            onRowSelect={setSelectedItem}
            loading={loading}
            onPositionsUpdate={onPositionsUpdate}
          />
        ) : (
          <FullProductsView products={no_special_items_positions} />
        )}
        <Box
          style={{
            fontSize: 24,
            lineHeight: '120%',
            textAlign: 'right',
            marginTop: 8,
          }}
        >
          Total price: {totalPrice} $
        </Box>
        <ActivityLogs />
      </Box>
      <Box
        flex={1}
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <AdditionalFields
          dealData={deal}
          fieldsConfig={fieldsConfig}
          type={type}
        />
      </Box>
    </Box>
  );
};

export default memo(PositionsTable);
