import React, { FC } from 'react';
import { RidWithFound, useItemCardStyles, useItemInfo } from '../hooks';
import { Card, CardActions, CardContent, Typography } from '@material-ui/core';
import { ErrorView, Spinner } from '../../../UI';
import FullScreenImageOnClick from '../../ReturnApp/components/FullScreenImageOnClick';
import { NO_IMAGE } from '../../../utils/common.constants';
import SubmitItem from './SubmitItem';

interface ItemCardProps {
  item: RidWithFound;
  setRidsWithFound: (item: RidWithFound) => void;
}

const ItemCard: FC<ItemCardProps> = ({ item, setRidsWithFound }) => {
  const { result, error, status } = useItemInfo(item.rid);
  const classes = useItemCardStyles();

  return (
    <Card
      className={
        item.found === 'yes' || item.problem
          ? classes.foundItem
          : classes.notFoundItem
      }
    >
      {status === 'loading' ? (
        <Spinner />
      ) : error ? (
        <ErrorView error={error} />
      ) : result ? (
        <>
          <CardContent>
            <Typography variant="h3" align="center">
              {item.inventory === 'damaged'
                ? 'Take item from damaged inventory'
                : 'Take item from ROF inventory'}
            </Typography>
            <Typography variant="h4" align="center">
              {item.found === 'yes'
                ? 'Item was found'
                : item.problem
                ? 'Item has been processed'
                : ''}
            </Typography>
            <FullScreenImageOnClick
              src={
                !result.image || result.image === '-1' ? NO_IMAGE : result.image
              }
            />
            {item.item_id && (
              <Typography variant="body1">Item ID: {item.item_id}</Typography>
            )}
            <Typography variant="body1">Rollun ID: {result.rid}</Typography>
            <Typography variant="body1">
              Brand: {result.brand || '-'}
            </Typography>
            <Typography variant="body1">Mpn: {result.mpn || '-'}</Typography>
            <Typography variant="body1">Csn: {result.csn || '-'}</Typography>
            <Typography variant="body1">
              Title: {result.title || '-'}
            </Typography>
          </CardContent>
          <CardActions>
            <SubmitItem
              itemId={item.id}
              rid={result.rid}
              item={item}
              changeItemFound={setRidsWithFound}
            />
          </CardActions>
        </>
      ) : (
        <Typography variant="h4">Item was not found {item.rid}</Typography>
      )}
    </Card>
  );
};

export default ItemCard;
