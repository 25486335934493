import React, { FC } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import MetricInfo from './MetricInfo';
import { Metric } from '../types';

interface IProps {
  metrics: Metric[] | null;
}

const DisplayMetricsInTable: FC<IProps> = ({ metrics }) => {
  if (!metrics) {
    return <Box fontSize="15px">No metrics available</Box>;
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="left">
            <Box fontWeight="fontWeightBold" fontSize="15px">
              Value
            </Box>
          </TableCell>
          <TableCell align="left">
            <Box fontWeight="fontWeightBold" fontSize="15px">
              Date
            </Box>
          </TableCell>
          <TableCell align="left">
            <Box fontWeight="fontWeightBold" fontSize="15px">
              Info (optional)
            </Box>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {metrics.map((metric, i) => (
          <MetricInfo key={i} value={metric} />
        ))}
      </TableBody>
    </Table>
  );
};

export default DisplayMetricsInTable;
