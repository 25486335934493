import { useCallback, useState } from 'react';
import { HttpDatastoreStatus } from '../../../../../hooks/useHttpDatastore';
import { Dimension, fetchDimensions } from '../utils/fetchDimensions';
import { ignoredShippingMethods } from '../utils/formatPirateShipInfoForDownload';
import {
  downloadClickNShipInfoFiles,
  getDimensions,
} from '../utils/downloadClickNShipInfoFiles';
import { apiClientsPickups } from '../../../../CRMDeals/components/api-clients/api-clients-pickups';
import { useCurrentQuery } from '../../../../../hooks/useCurrentQuery';
import { Pickup } from '../../../../CRMDeals/components/api-clients/types';
import { lbsToOz } from 'rollun-ts-utils';
import { apiClientsDropship } from '../../../../CRMDeals/components/api-clients/api-clients-dropships';
import { getErrorText } from '../utils/alertError';

type State = {
  status: HttpDatastoreStatus;
  description: string;
};
type UseDownloadClickNShipInfo = [
  {
    isLoading: boolean;
    error: string;
  },
  () => Promise<void>,
];

const getApiByDataStoreUrl = (dataStoreUrl: string) => {
  switch (dataStoreUrl) {
    case '/api/datastore/DropshipsDataStore':
      return apiClientsDropship;
    case '/api/datastore/PickupsDataStore':
      return apiClientsPickups;
    default:
      break;
  }
};

const getDealTypeByDataStoreUrl = (dataStoreUrl: string) => {
  switch (dataStoreUrl) {
    case '/api/datastore/DropshipsDataStore':
      return 'Dropship';
    case '/api/datastore/PickupsDataStore':
      return 'Pickup';
    default:
      break;
  }
};

export function useDownloadClickNShipInfo(
  dataStoreUrl: string,
): UseDownloadClickNShipInfo {
  const currentQuery = useCurrentQuery();
  const [processState, setProcessState] = useState<State>({
    status: 'loaded',
    description: '',
  });
  const api = getApiByDataStoreUrl(dataStoreUrl);

  const setPartialProcessState = (state: Partial<State>) => {
    setProcessState((prevState) => ({
      ...prevState,
      ...state,
    }));
  };

  const getCSVFromDeals = (deals: Pickup[], dimensions: Dimension[]) => {
    // in biggerThan18 variable, we are storing items which dimensions bigger than 18
    const response: Record<string, any> = {
      normalItems: [],
      biggerThan18: [],
      [`Priority Mail Cubic`]: [],
      [`Priority Mail`]: [],
      [`First-Class Package Service`]: [],
      [ignoredShippingMethods.priorityMailFlatRateEnvelope]: [],
      [ignoredShippingMethods.parcelSelectGround]: [],
      [ignoredShippingMethods.priorityMailLegalFlatRateEnvelope]: [],
    };

    // return true if item is a right size, and false if item is not a right size
    deals.forEach((deal: Pickup) => {
      const shippingMethod = deal.srShipMethod;

      const dealType = getDealTypeByDataStoreUrl(dataStoreUrl);
      if (!dealType) throw new Error('DealType should be Dropship or Pickup');
      const { properItem, rightSize } = getDimensions(
        deal,
        dimensions,
        dealType,
      );
      if (!properItem) throw new Error('Deal dimensions is undefined');

      const firstName = deal.client.firstName || deal.client.name;
      const { address1, address2, state, city, postalCode } = deal.client;

      const result = {
        'Order Number': deal.mpOrderNumber,
        'Mail Class': shippingMethod,
        Weight:
          properItem.weight !== '-1'
            ? lbsToOz(properItem.weight)
            : properItem.weight,
        Length: properItem.length,
        Height: properItem.height,
        Width: properItem.width,
        Name: firstName,
        'Address 1': address1,
        'Address 2': address2,
        City: city,
        State: state,
        Country: 'US',
        'Postal Code': postalCode,
        'Zip +4': '',
        Phone: deal.client.phone,
      };

      if (response.hasOwnProperty(shippingMethod)) {
        response[shippingMethod].push(result);
        return;
      }

      if (rightSize) {
        response.normalItems.push(result);
        return;
      }

      response.biggerThan18.push(result);
    });

    return {
      normalItems: response.normalItems,
      biggerThan18: response.biggerThan18,
      priorityMailCubic: response[`Priority Mail Cubic`],
      priorityMail: response[`Priority Mail`],
      firstClassPackageService: response[`First-Class Package Service`],
      priorityMailFlatRateEnvelope:
        response[ignoredShippingMethods.priorityMailFlatRateEnvelope],
      parcelSelectGround: response[ignoredShippingMethods.parcelSelectGround],
      priorityMailLegalFlatRateEnvelope:
        response[ignoredShippingMethods.priorityMailLegalFlatRateEnvelope],
    };
  };

  const filterUniqueDeals = (deals: any[]) => {
    return deals.reduce((acc, deal) => {
      const orderId = deal.mpOrderNumber;
      if (!acc.find(({ mpOrderNumber }: any) => mpOrderNumber === orderId)) {
        return acc.concat(deal);
      }
      return acc;
    }, []);
  };

  const handleDownload = useCallback(async () => {
    try {
      setPartialProcessState({ status: 'loading' });

      if (!api) throw new Error('There is no api to get deals from');
      if (currentQuery.limitNode > 100)
        throw new Error('Set page limit less than 101');

      const deals = await api.getByQuery(currentQuery);

      if (deals.length === 0 || !deals) {
        throw new Error(`There was no deals to download by filter`);
      }

      const rollunIds = (deals as any)
        .map(({ no_special_items_positions }: any) =>
          no_special_items_positions.map(({ article }: any) => article),
        )
        .flat();

      const uniqueDeals = filterUniqueDeals(deals);

      if (rollunIds.length === 0) {
        throw new Error(`There was no items in deals by filter`);
      }

      const dimensions = await fetchDimensions(rollunIds);
      const downloadData = getCSVFromDeals(uniqueDeals, dimensions);

      downloadClickNShipInfoFiles(downloadData);
      setPartialProcessState({ status: 'loaded' });
    } catch (e) {
      const text = getErrorText(e);
      alert(text);
      setPartialProcessState({ status: 'error', description: text });
    }
  }, []);

  return [
    {
      isLoading: processState.status === 'loading',
      error: processState.status === 'error' ? processState.description : '',
    },
    handleDownload,
  ];
}
