import React, { Component } from 'react';
import TableWithFilter from '../../Table/components/Table/TableWithFilter';
import _ from 'lodash';
import Dialog from '../../../UI/Dialog';
import Spinner from '../../../UI/Spinner';
import BrandsMapperAction from './BrandsMapperAction';
import { Button } from '../../../UI';
import JsonRenderer from '../../Table/components/Table/Cell/Renderers/JsonRenderer';
import { noop } from '../../../utils/common.utils';
import { AppConfig } from '../../AbstractService';

interface IState {
  selectedItem: { id: string; brand: string } | null;
  modalOpen: boolean;
  loading: boolean;
  maxWidth: number;
}

export const UNKNOWN_BRANDS_DATA_STORE = '/api/datastore/unknownBrandsStore';
export const BRAND_MAP_DATA_STORE = '/api/datastore/CatalogBrandMapping';

interface IProps {
  config: AppConfig['appParams']['BrandsMapperParams'];
  currentResourceName?: string;
}

class BrandsMapper extends Component<IProps, IState> {
  state: IState = {
    selectedItem: null,
    modalOpen: false,
    loading: false,
    maxWidth: window.innerWidth / 2 - 100,
  };

  unknownBrandsUpdater: (() => void) | null = null;
  brandsMappingUpdater: (() => void) | null = null;

  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleModalClose = () => {
    if (this.unknownBrandsUpdater && this.brandsMappingUpdater) {
      this.unknownBrandsUpdater();
      this.brandsMappingUpdater();
    }
    this.setState({ modalOpen: false, selectedItem: null });
  };

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({
        maxWidth:
          window.innerWidth < 992
            ? window.innerWidth - 100
            : window.innerWidth / 2 - 100,
      });
    });
  }

  render() {
    const { selectedItem, modalOpen, loading } = this.state;
    const { config = {}, currentResourceName } = this.props;
    return (
      <div>
        <h3 className="mt-2 text-center">Brands Mapper</h3>
        {selectedItem ? (
          <div className="card font-weight-bold mx-5 mb-2 p-2">
            Selected item:{' '}
            {Object.entries(selectedItem).map(([key, content]) => {
              const value = _.isString(content)
                ? content
                : JSON.stringify(content);
              return (
                <div key={key}>
                  <span>
                    {key}: {value}
                  </span>
                </div>
              );
            })}
            <Button
              className="mt-2"
              color="primary"
              size="sm"
              onClick={this.handleModalOpen}
            >
              Transfer
            </Button>
          </div>
        ) : (
          <p className="text-center text-capitalize">
            Select item from second table first!
          </p>
        )}

        <div className="d-flex flex-column flex-lg-row">
          <div className="col-lg-6">
            <h1>Brands </h1>
            <TableWithFilter
              maxTableWidth={this.state.maxWidth}
              appName={''}
              datastoreUrl={BRAND_MAP_DATA_STORE}
              getTableReloader={(updater) =>
                (this.brandsMappingUpdater = updater)
              }
              enableDeleteItem={true}
              onChangeQuery={noop}
              userNote={config.brandsGridParams?.userNote}
              descriptionConfigPath={
                'config.appParams.BrandsMapperParams.brandsGridParams.userNote'
              }
              currentResourceName={currentResourceName}
            />
          </div>
          <div className="col-lg-6">
            <h1>Unknown brands</h1>
            <TableWithFilter
              maxTableWidth={this.state.maxWidth}
              appName={''}
              getTableReloader={(updater) =>
                (this.unknownBrandsUpdater = updater)
              }
              onRowSelect={(row: any) => {
                this.setState({ selectedItem: row });
              }}
              columnsConfig={{
                mapping: { renderer: JsonRenderer, isEditable: false },
              }}
              enableDeleteItem={true}
              datastoreUrl={UNKNOWN_BRANDS_DATA_STORE}
              onChangeQuery={noop}
              userNote={config.unknownBrandsGridParams?.userNote}
              descriptionConfigPath={
                'config.appParams.BrandsMapperParams.unknownBrandsGridParams.userNote'
              }
              currentResourceName={currentResourceName}
            />
          </div>
          <Dialog
            isOpen={modalOpen}
            title="Title"
            options={{ centered: true }}
            onClose={this.handleModalClose}
          >
            {loading ? (
              <div>
                <Spinner />
              </div>
            ) : (
              <BrandsMapperAction
                onActionComplete={this.handleModalClose}
                selectedItem={selectedItem}
              />
            )}
          </Dialog>
        </div>
      </div>
    );
  }
}

export default BrandsMapper;
