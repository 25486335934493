import React, { Component } from 'react';
import TableWithFilter from '../../Table/components/Table/TableWithFilter';
import { Dialog } from '../../../UI';
import RulesTreeEditor from './RulesTreeEditor';

const ACL_RESOURCE_DS = 'api/datastore/resourceDataStore';

export interface Resource {
  id: string;
  name: string;
  pattern: string;
  parent_id: string | null;
}

interface IState {
  selectedItem: Resource | null;
  isModalOpen: boolean;
}

class AclRuleEditor extends Component<unknown, IState> {
  state: IState = { selectedItem: null, isModalOpen: false };

  unknownBrandsUpdater: null | (() => void) = null;

  render() {
    const { isModalOpen, selectedItem } = this.state;

    return (
      <div>
        <h2 className="text-center mt-2">ACL Resources</h2>
        <p className="text-center font-weight-bold mt-2">
          Select any resource to edit it's ACL rules.
        </p>
        <TableWithFilter
          appName={'ACLResources'}
          userNote="id - id of the route, random stringname - name of the route, has to match name of the route in [table](https://rollun.net/routesConfig)pattern - has to match name of the routeparent_id - id of the parent route"
          getTableReloader={(updater) => (this.unknownBrandsUpdater = updater)}
          onRowSelect={(row) =>
            // prevent modal opening on first render.
            selectedItem
              ? this.setState({
                  selectedItem: row as Resource,
                  isModalOpen: true,
                })
              : this.setState({ selectedItem: row as Resource })
          }
          datastoreUrl={ACL_RESOURCE_DS}
        />
        <Dialog
          isOpen={isModalOpen}
          title={
            selectedItem
              ? `Edit resource ${selectedItem.name}`
              : 'Edit resource rules'
          }
          onClose={this.handleModalClose}
          options={{ centered: true, size: 4 }}
        >
          {selectedItem ? (
            <RulesTreeEditor resourceId={selectedItem.id} />
          ) : (
            <h2>You have not selected resource to edit rules</h2>
          )}
        </Dialog>
      </div>
    );
  }

  handleModalOpen = () => {
    this.setState({ isModalOpen: true });
  };

  handleModalClose = () => {
    this.setState({ isModalOpen: false });
  };
}

export default AclRuleEditor;
