import React, { FC } from 'react';
import withControls from './withControls';
import { IRendererProps } from './DefaultRenderer';
import { Link } from 'react-router-dom';

export interface ILocalLinkRenderProps extends IRendererProps {
  config?: {
    // example - '/api/test/{value}/test/'
    // value is a placeholder for the value of the cell
    pattern?: string;
  };
}

const addStartingSlash = (v: string) => (v[0] === '/' ? v : `/${v}`);

const LocalLinkRenderer: FC<ILocalLinkRenderProps> = ({ value, config }) => {
  const link = config?.pattern
    ? config?.pattern.replace('{value}', value)
    : addStartingSlash(value);

  return (
    <Link to={link}>
      <span>{value}</span>
    </Link>
  );
};

export default withControls(LocalLinkRenderer);
