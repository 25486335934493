import HttpDatastore from 'rollun-ts-datastore';
import {
  Position,
  Client,
  SPECIAL_ITEMS_IDS,
  ReturnPickupDTO,
  ReturnPickup,
} from './types';
import ApiClientsPositions from './api-clients-position';
import { Query } from 'rollun-ts-rql';
import ApiClients from './api-clients-clients';
import { compareStringNumbers } from '../common/utils/compareStringNumbers';

const datastore = new HttpDatastore<ReturnPickupDTO>(
  '/api/datastore/ReturnPickupsDataStore',
);

export default class ApiClientsReturnPickups {
  apiPositions: ApiClientsPositions;
  apiClients: ApiClients;

  constructor() {
    this.apiPositions = new ApiClientsPositions();
    this.apiClients = new ApiClients();
  }

  async getById(id: string): Promise<ReturnPickup> {
    const pickupDTO = await datastore.read(id);

    if (!pickupDTO) {
      throw new Error(`Return pickup with id ${id} not found`);
    }

    const positions = this.apiPositions.getByDealId(id);
    const client = this.apiClients.getById(pickupDTO.Contractor);

    const order = await Promise.all([positions, client]).then((data) => {
      return this.convertDTOtoModel(pickupDTO, ...data);
    });

    return order;
  }

  async getByQuery(query: Query): Promise<ReturnPickup[]> {
    const PickupsDTO = await datastore.query(query);
    return Promise.all(
      PickupsDTO.map((PickupDTO) => this.getById(PickupDTO.Id)),
    );
  }

  async deleteItems(id: string, itemIds: string[]): Promise<void> {
    await Promise.all(
      itemIds.map((itemId) => this.apiPositions.delete(id, itemId)),
    );
  }

  async create(data: ReturnPickup): Promise<ReturnPickup> {
    const pickupDTO = this.convertModelToDTO(data);
    const pickup = await datastore.create(pickupDTO);

    if (data.positions) {
      await this.addItems(pickup.Id, data.positions);
    }

    return this.getById(pickup.Id);
  }

  async addItems(
    id: string,
    items: Omit<Position, 'offerId' | 'dealId' | 'id'>[],
  ): Promise<void> {
    for (const item of items) {
      await this.apiPositions.add(id, item);
    }
  }

  async update(
    id: string,
    data: Partial<Omit<ReturnPickup, 'id' | 'positions'>>,
  ) {
    await datastore.update({
      ...this.convertModelToDTO(data),
      Id: id,
    });
  }

  async addOrUpdateItems(
    id: string,
    items: Omit<Position, 'offerId' | 'dealId' | 'id'>[],
  ) {
    const deal = await this.getById(id);
    const positions = deal.positions;

    const tempSortedItems: Position[] = [];

    for (let i = 0; i < positions.length; i++) {
      const position = { ...positions[i] };

      if (
        tempSortedItems.filter(
          (item) =>
            item.article === position.article &&
            compareStringNumbers(item.cost, position.cost) &&
            item.name === position.name,
        ).length
      )
        continue;

      tempSortedItems.push(position);

      const sameItems = items.filter(
        (item) =>
          item.article === position.article &&
          compareStringNumbers(item.cost, position.cost) &&
          item.name === position.name,
      );
      if (!sameItems.length) continue;

      const allQuantity = sameItems.reduce(
        (prev, cur) => prev + +cur.quantity,
        0,
      );
      position.quantity = +position.quantity + allQuantity;

      await this.apiPositions.update(position);
    }

    const filteredItems = items.filter((item) => {
      for (let i = 0; i < tempSortedItems.length; i++) {
        const position = { ...tempSortedItems[i] };
        if (
          item.article === position.article &&
          compareStringNumbers(item.cost, position.cost) &&
          item.name === position.name
        )
          return false;
      }
      return true;
    });

    if (filteredItems.length) await this.addItems(id, filteredItems);
  }

  async updateClient(id: string, data: Client) {
    await this.apiClients.update(id, data);
  }

  async updateClientByOrderId(orderId: string, data: Client) {
    const { Contractor: clientId } = await datastore.read(orderId);
    await this.apiClients.update(clientId, data);
  }

  convertDTOtoModel(
    returnPickupDTO: ReturnPickupDTO,
    positions: Position[],
    client: Client,
  ): ReturnPickup {
    const no_special_items_positions = positions.filter(
      (position) => !SPECIAL_ITEMS_IDS.includes(position.article),
    );

    return {
      id: returnPickupDTO.Id,
      GUID: returnPickupDTO.GUID,
      name: returnPickupDTO.Name,
      contractor: returnPickupDTO.Contractor,
      timeCreated: returnPickupDTO.TimeCreated,
      timeUpdated: returnPickupDTO.TimeUpdated,
      owner: returnPickupDTO.Owner,
      isDraft: returnPickupDTO.IsDraft,
      isPaid: returnPickupDTO.IsPaid,
      finalPrice: returnPickupDTO.FinalPrice,
      programId: returnPickupDTO.ProgramId,
      mpName: returnPickupDTO.MpName,
      mpOrderNumber: returnPickupDTO.MpOrderNumber,
      mpReturnNumber: returnPickupDTO.MpReturnNumber,
      mpRetReason: returnPickupDTO.MpRetReason,
      dealStatus: returnPickupDTO.DealStatus,
      returnLocation: returnPickupDTO.ReturnLocation,
      labelCreator: returnPickupDTO.LabelCreator,
      tracknumber: returnPickupDTO.Tracknumber,
      carrier: returnPickupDTO.Carrier,
      shipStatus: returnPickupDTO.ShipStatus,
      returnCondition: returnPickupDTO.ReturnCondition,
      itemCondition: returnPickupDTO.ItemCondition,
      problemDescription: returnPickupDTO.ProblemDescription,
      srName: returnPickupDTO.SrName,
      mpUaId: returnPickupDTO.MpUaId,
      manager: returnPickupDTO.Manager,
      status: returnPickupDTO.Status,
      statusName: returnPickupDTO.StatusName,
      programName: returnPickupDTO.ProgramName,
      relatedReturn: returnPickupDTO.RelatedReturn,
      positions,
      no_special_items_positions,
      client,
    };
  }

  convertModelToDTO(
    returnPickup: Partial<Omit<ReturnPickup, 'id' | 'positions' | 'client'>>,
  ): Partial<Omit<ReturnPickupDTO, 'Id'>> {
    return {
      GUID: returnPickup.GUID,
      Name: returnPickup.name,
      Contractor: returnPickup.contractor,
      TimeCreated: returnPickup.timeCreated,
      TimeUpdated: returnPickup.timeUpdated,
      Owner: returnPickup.owner,
      IsDraft: returnPickup.isDraft,
      IsPaid: returnPickup.isPaid,
      FinalPrice: returnPickup.finalPrice,
      ProgramId: returnPickup.programId,
      MpName: returnPickup.mpName,
      MpOrderNumber: returnPickup.mpOrderNumber,
      MpReturnNumber: returnPickup.mpReturnNumber,
      MpRetReason: returnPickup.mpRetReason,
      DealStatus: returnPickup.dealStatus,
      ReturnLocation: returnPickup.returnLocation,
      LabelCreator: returnPickup.labelCreator,
      Tracknumber: returnPickup.tracknumber,
      Carrier: returnPickup.carrier,
      ShipStatus: returnPickup.shipStatus,
      ReturnCondition: returnPickup.returnCondition,
      ItemCondition: returnPickup.itemCondition,
      ProblemDescription: returnPickup.problemDescription,
      SrName: returnPickup.srName,
      MpUaId: returnPickup.mpUaId,
      Manager: returnPickup.manager,
      Status: returnPickup.status,
      RelatedReturn: returnPickup.relatedReturn,
      StatusName: returnPickup.statusName,
      ProgramName: returnPickup.programName,
    };
  }
}

export const apiClientsReturnPickup = new ApiClientsReturnPickups();
