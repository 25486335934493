import { makeStyles, Theme } from '@material-ui/core';
import { Page } from '../context';

const useStyles = makeStyles((theme: Theme) => ({
  cardContent: {
    padding: theme.spacing(1),
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
  media: {
    height: 140,
    img: {
      maxWidth: '100%',
      maxHeight: '100%',
      display: 'block',
    },
  },
}));

const includesCaseInsensitive = (haystack: string, needle: string) => {
  if (!(haystack || needle)) {
    return false;
  }

  return haystack
    .toLowerCase()
    .replaceAll(/[^a-zA-Z0-9]/g, '')
    .includes(needle.toLowerCase());
};

const getPreviousPageForCurrent = (currentPage: Page) => {
  if (
    currentPage === 'scanLabel' ||
    currentPage === 'notFoundLabel' ||
    currentPage === 'notFoundItem' ||
    currentPage === 'foundLabel'
  ) {
    return 'returnLabels';
  }

  if (currentPage === 'scanItem' || currentPage === 'foundItem') {
    return 'foundLabel';
  }

  if (currentPage === 'returnLabels') {
    return 'setLocation';
  }

  return currentPage;
};

const rqlStartsWith = (value: string): string => `${value}%`;

const formatBarcode = (code: string, type: string) => {
  let formattedCode = code;
  let carrier = 'unknown';
  if (type === 'CODE_39') {
    formattedCode = code.replace(/-/g, '');
  }
  if (type === 'CODE_128') {
    carrier = getCarrierType(code);

    formattedCode = formatCarrierCode(code, carrier);
  }
  return { code: formattedCode, type, carrier };
};

const getCarrierType = (rawCode: string) => {
  if (rawCode.length === 34) {
    return 'fedex';
  }

  if (rawCode.length === 30) {
    return 'usps';
  }

  if (rawCode.length === 18 && rawCode.includes('Z')) {
    return 'ups';
  }

  return 'unknown';
};

const formatCarrierCode = (code: string, carrier: string) => {
  if (carrier === 'usps') {
    // usps 30 length leave last 22
    return code.slice(8, 30);
  }
  if (carrier === 'fedex') {
    // fedex 34 length leave last 12
    return code.slice(22, 34);
  }
  if (carrier === 'ups') {
    // ups 18 length leave all
    return code;
  }

  return code;
};

export {
  formatBarcode,
  useStyles,
  includesCaseInsensitive,
  getPreviousPageForCurrent,
  rqlStartsWith,
};
