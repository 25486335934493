import _ from 'lodash';
import { FlatTreeItem } from '../pages/EditableTree/components/EditableTree';
import { randomString, wait } from 'rollun-ts-utils/dist';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowAltCircleUp,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBan,
  faBarcode,
  faBars,
  faCaretDown,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faCloudDownloadAlt,
  faCog,
  faCompressArrowsAlt,
  faCopy,
  faDownload,
  faEdit,
  faExclamation,
  faExclamationCircle,
  faExpandArrowsAlt,
  faFileDownload,
  faFilter,
  faFlag,
  faInfoCircle,
  faMinus,
  faPlus,
  faPlusSquare,
  faRadiationAlt,
  faSearch,
  faSnowflake,
  faSortDown,
  faSortUp,
  faSync,
  faSyncAlt,
  faTags,
  faTimes,
  faTrash,
  faTrashAlt,
  faUpload,
  faPlay,
} from '@fortawesome/free-solid-svg-icons';
import Axios from 'axios';
import { logger } from './logger';
import { BEFORE_LOGIN_PATH_KEY } from '../UI/ErrorView';
import {
  CACHE_NAME_PREFIX,
  CACHED_INFO_TIMESTAMP,
  PUT_RULES_NAME_PREFIX,
  ROLES_INHERITANCE,
  USER_FRONT_CONFIG,
  USER_UNAUTHORIZED,
} from '../pages/Table/util/constants';

export function saveBeforeLoginPath() {
  const path = `${window.location.pathname}${window.location.search}`;

  sessionStorage.setItem(BEFORE_LOGIN_PATH_KEY, path);
}

/**
 * Rounds number to min - max range, if min > max behavior is undefined
 * @param n
 * @param min
 * @param max
 */

export const clamp = (n: number, min = 0, max = 1) =>
  Math.min(Math.max(n, min), max);

/**
 * Utility function to keep track of progress of fulfil Promise Array {proms}
 * @param proms -> Array of promises
 * @param progress_cb -> callback on each promise fulfil
 * @return {Promise}
 */

export type ProgressCallback = (
  success: number,
  fail: number,
  totalAmount: number,
  failedItems?: Array<any> | any,
) => void;

export const allProgress = async (
  proms: Array<Promise<any>>,
  progress_cb: ProgressCallback,
) => {
  let success = 0;
  let fail = 0;
  const promsLength = proms.length;

  for (const promise of proms) {
    try {
      await promise;
      progress_cb(++success, fail, promsLength);
    } catch (e) {
      httpErrorHandler(e, (code, text) => {
        // if ([/already exist/i, /Duplicate entry/i].some(el => el.test(text))) {
        // 	progress_cb(++success, fail, promsLength, failedItems);
        // } else {
        progress_cb(success, ++fail, promsLength, { error: text });
        // }
      });
      //
      // if (e.text) {
      // 	const errorText = await e.text();
      // 	if (errorText.indexOf('already exist') > -1) {
      // 		progress_cb(++success, fail, promsLength, failedItems);
      // 	} else {
      // 		progress_cb(success, ++fail, promsLength, failedItems);
      // 	}
      // } else {
      // 	progress_cb(success, ++fail, promsLength, failedItems);
      // }
    }
  }
  return Promise.all(proms);
};

/**
 * Sends data in {tasks} array using task.handler, in chunks of Promises
 * @param tasks
 * @param progressCallback
 */

export type Task<T = any> = { data: T; handler: (data: T) => Promise<any> };

export const sendRequestsInChunks = async <S = any>(
  tasks: Array<Task<S>>,
  progressCallback: ProgressCallback,
  chunkSize = 5,
) => {
  const chunks = _.chunk(tasks, chunkSize);
  let success = 0;
  let fail = 0;
  let lastSuccess = 0;
  let lastFail = 0;
  let failedItems: Array<any> = [];
  const chunkProgressCallback = (
    s: number,
    f: number,
    total: number,
    failedItem?: any,
  ) => {
    if (lastSuccess !== s) {
      lastSuccess = s;
      success++;
    }
    if (lastFail !== f) {
      lastFail = f;
      fail++;
      const { data } = tasks[success + fail - 1];
      failedItems = failedItems.concat(
        Array.isArray(data)
          ? data.map((el) => ({ ...el, ...failedItem }))
          : { ...data, ...failedItem },
      );
    }
    if (s + f === total) {
      lastSuccess = 0;
      lastFail = 0;
    }
    progressCallback(success, fail, tasks.length, failedItems);
  };

  for (const chunk of chunks) {
    try {
      await allProgress(
        chunk.map((task) => task.handler(task.data)),
        chunkProgressCallback,
      );
      await wait(500);
    } catch (e) {
      console.log('err', e);
    }
  }
};

/**
 *
 */

type Options = {
  idField?: string;
  parentIdField?: string;
  childrenField?: string;
};
export const unflatten = (arr: Array<any>, options: Options = {}) => {
  const {
    idField = 'id',
    parentIdField = 'parent_id',
    childrenField = 'children',
  } = options;
  const tree = [];
  const mappedArr: { [key: string]: any } = {};
  let mappedElem: { [x: string]: string | number } = {};

  // First map the nodes of the array to an object -> create a hash Table.
  arr.forEach((el) => {
    mappedArr[el[idField]] = el;
  });
  for (const id in mappedArr) {
    if (mappedArr.hasOwnProperty(id)) {
      mappedElem = mappedArr[id];
      // If the element is not at the root level, add it to its parent array of children.
      if (mappedElem[parentIdField]) {
        if (mappedArr[mappedElem[parentIdField]]) {
          if (mappedArr[mappedElem[parentIdField]][childrenField]) {
            mappedArr[mappedElem[parentIdField]][childrenField].push(
              mappedElem,
            );
          } else {
            mappedArr[mappedElem[parentIdField]][childrenField] = [mappedElem];
          }
        }
      }
      // If the element is at the root level, add it to first level elements array.
      else {
        tree.push(mappedElem);
      }
    }
  }
  return tree;
};

/**
 * Convert flat data to tree, and format data as SortableTree component expects
 * @param data
 * @param options
 */

export const rowTableDataToTree = (
  data: Array<FlatTreeItem>,
  { labelField = 'title' } = {},
) => {
  const formattedData = data.map((el) => {
    const { id, parent_id } = el;
    return {
      id,
      parent_id,
      node_id: randomString(5) + '_' + el[labelField],
      title: el[labelField],
      expanded: true,
    };
  });
  return unflatten(formattedData);
};

/**
 * Get depth of tree-like JSON hierarchy
 * @reference {https://stackoverflow.com/questions/16075664/how-to-get-the-total-depth-of-an-unknown-json-hierarchy}
 * @param obj
 * @param childrenKey
 * Examples:
 *      const obj = {a: 1, children: [{a: '2'}]}
 *      getDepth(obj) -> 2
 *
 *      const obj2 = {a: 1, subNode: {a: '2'}};
 *      getDepth(obj2, 'subNode') -> 2
 */

export const getDepth = (obj: any, childrenKey = 'children') => {
  let depth = 0;
  const forEachCallback = (d: any) => {
    const tmpDepth = getDepth(d, childrenKey);
    if (tmpDepth > depth) {
      depth = tmpDepth;
    }
  };
  if (_.isArray(obj)) {
    obj.forEach((el: any) => {
      if (el[childrenKey]) {
        el[childrenKey].forEach(forEachCallback);
      }
    });
  } else if (obj[childrenKey]) {
    _.isArray(obj[childrenKey])
      ? obj[childrenKey].forEach(forEachCallback)
      : forEachCallback(obj[childrenKey]);
  }
  return 1 + depth;
};

/**
 * Scroll to top of the page smoothly
 */

export const scrollToTop = () => {
  if (window.scrollY === 0) return;
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

async function checkUserAuthorization() {
  try {
    // @ts-expect-error
    const phpsessidCookie = cookieStore
      ? // @ts-expect-error
        await cookieStore.get('PHPSESSID')
      : null;

    const cacheStr = sessionStorage.getItem('CACHED_USER_IDENTITY');
    if (!cacheStr) return { phpsessidCookie, userAuthorized: false };
    const cache = JSON.parse(cacheStr);
    if (!phpsessidCookie || cache.expires === 0) {
      return;
    } else if (
      !cache ||
      phpsessidCookie.expires !== cache.expires ||
      new Date(phpsessidCookie.expires) < new Date()
    )
      return { phpsessidCookie, userAuthorized: false };

    return { phpsessidCookie, userAuthorized: true };
  } catch (err) {
    console.error(err);
  }
  // we still have browsers that save cookie after expiration time,
  // it depends of browser settings, but this check will help to cover some cases
  try {
    const matches = document.cookie.match(/(?:^|; )PHPSESSID=([^;]*)/);
    return { userAuthorized: !!matches };
  } catch (err) {
    console.error(err);
  }
}

/**
 * Gets user identity by requesting same origin, and getting 'x-identity' header
 * from response.
 *
 */

export const getUserIdentity = async (disableCache = false) => {
  const cache = sessionStorage.getItem('CACHED_USER_IDENTITY');
  const userUnautorized = JSON.parse(
    localStorage.getItem(USER_UNAUTHORIZED) || '',
  );

  console.log('getUserIdentity userUnautorized', userUnautorized);

  let user: { user: string; role: string; expires: number } = {
    user: '',
    role: '',
    expires: 0,
  };

  if (cache) {
    user = JSON.parse(cache);
  }

  const userAuthorization = await checkUserAuthorization();
  const validUserRole = !userAuthorization
    ? user.user && user.role
    : user.user && user.role && userAuthorization.userAuthorized;

  if (!validUserRole) {
    sessionStorage.removeItem('CACHED_USER_IDENTITY');
  }

  if (validUserRole) {
    return user;
  }

  if (userUnautorized) {
    return {
      user: '',
      role: 'guest',
      expires: 0,
    };
  }

  return Axios.get('/api/datastore/UserFrontConfig?limit(1)').then(
    ({ headers }) => {
      const userIdentity = {
        user: '',
        role: '',
        expires: 0,
      };

      const headersMap = new Map<string, string>(Object.entries(headers));

      headersMap.forEach((el: string, key: string) => {
        if (/x-identity/gi.test(key)) {
          const user = JSON.parse(el);
          userIdentity.user = user.name || '';
          userIdentity.role = (user.roles || ['guest']).join(', ') || '';
          userIdentity.expires =
            userAuthorization?.phpsessidCookie?.expires || 0;
        }
      });

      if (!disableCache) {
        sessionStorage.setItem(
          'CACHED_USER_IDENTITY',
          JSON.stringify(userIdentity),
        );
      }

      if (!userIdentity.user) {
        localStorage.setItem(USER_UNAUTHORIZED, 'true');
      }

      return userIdentity;
    },
  );
};

/**
 * util for handling http errors
 * Right now, in case of error, backend returns error as text, or json, or whatever backend wants..., so i need to parse it...
 * P.S. i hope it will be refactored in future (written 02.12.2019)
 * (remove this P.S. when refactoring is done please)
 * @param err
 * @param callback
 */

export const httpErrorHandler = (
  err: any,
  callback: (code: number, text: string) => void,
) => {
  if (err.isAxiosError) {
    const {
      response: { status, statusText, data = '' },
    } = err;
    if (status === 403) {
      localStorage.setItem('LAST_403_PATH', window.location.pathname);
    }
    const stringResponseBody =
      typeof data === 'string' ? data : JSON.stringify(data);
    callback(
      status || 0,
      `${statusText ? `${statusText}. ` : ''}${
        data.message || stringResponseBody
      }`,
    );
  } else if (_.isFunction(err.text)) {
    err.text().then((e: string) => {
      try {
        const json = JSON.parse(e);
        callback(err.status, json.error || e);
      } catch (parsingError) {
        callback(err.status, e);
      }
    });
  } else if (err.message) {
    callback(0, err.message);
  } else if (err.error) {
    callback(500, err.error);
  } else {
    callback(0, 'Unknown error');
  }
};

export const httpErrorHandlerPromised = (
  err: any,
): Promise<{ code: number; text: string }> =>
  new Promise((resolve) =>
    httpErrorHandler(err, (code, text) =>
      resolve({
        code,
        text,
      }),
    ),
  );

export const httpErrorHandlerSync = (
  err: any,
): { code: number; text: string } => {
  let code: number | null = null;
  let text: string | null = null;

  httpErrorHandler(err, (_code: number, _text: string) => {
    code = _code;
    text = _text;
  });

  return {
    code: code || 0,
    text: text || 'Unknown error',
  };
};

export const CDN_STATIC_URL = 'https://rollun.s3.eu-central-1.amazonaws.com';
export const makeStaticCDNPath = (filename: string) =>
  CDN_STATIC_URL + (filename[0] === '/' ? filename : `/${filename}`);

/**
 * before parsing, rql string needs to be properly encoded.
 * all special characters need to be replaced with encoded values.
 *
 * For now its enough to replace only 4 characters:
 * - -> %2D
 * _ -> %5F
 * . -> %2E
 * ~ -> %7E
 * The full list of such characters:
 *                ( -> %28
 ) -> %29
 - -> %2D
 _ -> %5F
 . -> %2E
 ~ -> %7E
 * -> %2A
 ' -> %27
 ! -> %21
 * @param rql
 */

export const encodeRqlString = (rql: string) => {
  return (
    rql
      // replace type definition, because RqlParser cant parse type properly
      .replace(/-/g, (...matches) => {
        const [, idx, match] = matches;
        // attention crutch! prevent replacing - if it is sort direction
        if (idx >= 5 && match.slice(idx - 5, idx - 1) === 'sort') {
          return '-';
        }
        return '%2D';
      })
      .replace(/_/g, '%5F')
      .replace(/\./g, '%2E')
      .replace(/~/g, '%7E')
  );
};

/**
 * For some reason response from backed returns unencoded, and unicode values
 * like \u0027 sent as separate characters, so need to replace them to normal values...
 * @param utf8String
 */

export const utf8Decode = (utf8String: string) => {
  return utf8String.replace(/\\u0027/g, "'");
};

export const cacheValidCheck = (force = false) => {
  const timestamp = localStorage.getItem(CACHED_INFO_TIMESTAMP);

  const userAuthorization = localStorage.getItem(USER_UNAUTHORIZED);

  console.log('timestamp', timestamp, userAuthorization);

  if (!timestamp) {
    localStorage.setItem(CACHED_INFO_TIMESTAMP, JSON.stringify(Date.now()));
    return false;
  }

  const cachedHoursAgo =
    (Date.now() - JSON.parse(timestamp)) / (1000 * 60 * 60);

  if (cachedHoursAgo > 12 || force) {
    localStorage.setItem(CACHED_INFO_TIMESTAMP, JSON.stringify(Date.now()));
    return false;
  }

  return true;
};

/**
 * Function clears cached data such as:
 *    - cache configs for every page
 *    - all session storage
 */

export const clearBrowserCache = () => {
  const localStorageKeys = Object.keys(localStorage);
  for (const key of localStorageKeys) {
    if (
      key.includes(CACHE_NAME_PREFIX) ||
      key.includes(PUT_RULES_NAME_PREFIX) ||
      key.includes(ROLES_INHERITANCE) ||
      key.includes(USER_UNAUTHORIZED) ||
      key.includes(USER_FRONT_CONFIG) ||
      key.includes(CACHED_INFO_TIMESTAMP)
    ) {
      localStorage.removeItem(key);
    }
  }
  const beforeLoginPath = sessionStorage.getItem(BEFORE_LOGIN_PATH_KEY);
  console.log('beforeLoginPath', beforeLoginPath);
  sessionStorage.clear();
  cacheValidCheck(true);
  if (beforeLoginPath) {
    sessionStorage.setItem(BEFORE_LOGIN_PATH_KEY, beforeLoginPath);
    console.log('beforeLoginPath', beforeLoginPath);
  }
};

/**
 * Group by some property
 * @ref https://stackoverflow.com/a/34890276
 * @param xs
 * @param key
 */

export const groupBy = function (
  xs: Array<any>,
  key: string | number,
): { [key: string]: Array<any> } {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

/**
 * Noop func - does nothing, can be used as stub
 */

export const noop = () => {
  return;
};

/**
 * Check if string is parsable HTML
 * @param str
 * @see https://stackoverflow.com/a/15458968
 */

export const isHTML = (str: string): boolean => {
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
};

/**
 * Turns first letter in work to upper case
 * @param str
 */

export const firstToUpper = (str = ''): string => {
  if (str === '') return '';
  if (str.length === 1) return str.toUpperCase();
  return str[0].toLowerCase() + str.slice(1);
};

export const setupIcons = () => {
  library.add(
    faArrowRight,
    faArrowLeft,
    faBan,
    faSearch,
    faTimes,
    faFileDownload,
    faSnowflake,
    faExclamationCircle,
    faSortUp,
    faMinus,
    faCloudDownloadAlt,
    faInfoCircle,
    faTrash,
    faCaretUp,
    faCaretDown,
    faPlus,
    faCog,
    faExclamation,
    faExpandArrowsAlt,
    faCompressArrowsAlt,
    faSync,
    faRadiationAlt,
    faTrashAlt,
    faExpandArrowsAlt,
    faCompressArrowsAlt,
    faTags,
    faPlusSquare,
    faSyncAlt,
    faCheckSquare,
    faBars,
    faArrowAltCircleUp,
    faArrowUp,
    faCopy,
    faFlag,
    faBarcode,
    faCheck,
    faSortDown,
    faCheckCircle,
    faFilter,
    faDownload,
    faUpload,
    faEdit,
    faPlay,
  );
};

export const optimizeChanges = <T extends { action: string }>(
  _changes: Array<T> | null,
): Array<T> => {
  if (!_changes) {
    return [];
  }

  const byId = groupBy(_changes, 'id');
  return Object.values(byId).reduce((acc: Array<T>, changes: Array<T>) => {
    const deleteAction = changes.find((change) => change.action === 'delete');
    const createAction = changes.find((change) => change.action === 'create');
    const lastUpdateAction = changes
      .reverse()
      .find((change) => change.action === 'update');

    if (deleteAction && createAction) {
      return acc;
    }

    if (deleteAction && !createAction) {
      return acc.concat(deleteAction);
    }

    if (createAction && !lastUpdateAction) {
      return acc.concat(createAction);
    }

    if (createAction && lastUpdateAction) {
      return acc.concat({
        ...lastUpdateAction,
        action: 'create',
      });
    }
    if (lastUpdateAction) {
      return acc.concat(lastUpdateAction);
    }
    return acc;
  }, []);
};

export const merge = (a: any[] | null, b: any[] | null, key: string) => {
  if (!a || !b) {
    return [];
  }

  const x = (a: any[]) => {
    a.forEach((b: any) => {
      if (!(b[key] in obj)) {
        obj[b[key]] = obj[b[key]] || {};
        array.push(obj[b[key]]);
      }
      Object.keys(b).forEach((k) => {
        obj[b[key]][k] = b[k];
      });
    });
  };

  const array: any[] = [];
  const obj: any = {};

  x(a);
  x(b);
  return array;
};
