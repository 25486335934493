import React, { FC, useState } from 'react';
import MuiButton from '../../../../../UI/MuiButton';
import { useCurrentQuery } from '../../../../../hooks/useCurrentQuery';
import HttpDatastore from 'rollun-ts-datastore';
import axios from 'axios';

const webhookMapping: Record<string, string> = {
  '/api/datastore/EbayPlaisirInventory': '/api/webhook/clearMpProblemsPlaisir',
  '/api/datastore/EbayRollunInventory': '/api/webhook/clearMpProblemsRollun',
};

const ClearMpProblems: FC<any> = ({ dataStoreUrl }) => {
  const datastore = new HttpDatastore(dataStoreUrl);
  const currentQuery = useCurrentQuery();
  const [isLoading, setIsLoading] = useState(false);

  const handleClear = async () => {
    setIsLoading(true);

    try {
      const webhookUrl = webhookMapping[dataStoreUrl];

      if (!webhookUrl) {
        alert('There are no webhook for this datastore url');
        return;
      }

      const queryResult = await datastore.query(currentQuery);
      const mlns = queryResult.map((item) => item.mln);

      if (!mlns.length) {
        alert('ClearMpProblems Error: There are no mlns');
        return;
      }

      const result = await axios.post(webhookUrl, {
        mlns,
      });
      const { data } = result;
      console.log('handleClearResult', { result, mlns });

      if (data.status === 'ok') {
        alert('ClearMpProblems completed successfully');
      } else if (data.status === 'error') {
        alert(`ClearMpProblems Error: ${data.message}`);
      } else {
        alert(`ClearMpProblems Unknown result message: ${data.message}`);
      }
    } catch (err) {
      alert(`Error: ${JSON.stringify(err)}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MuiButton
      color="primary"
      onClick={handleClear}
      title="clears mp_problem for items that are currently visible in table"
      loading={isLoading}
    >
      Clear Mp Problems
    </MuiButton>
  );
};

export default ClearMpProblems;
