import React, { FC } from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';

interface ConfirmAfterItemProps {
  handleOk: () => void;
  handleNotOk: () => void;
  onClose: () => void;
  isOpen: boolean;
}

const ConfirmAfterItem: FC<ConfirmAfterItemProps> = ({
  handleNotOk,
  handleOk,
  isOpen,
}) => {
  return (
    <Dialog open={isOpen} onClose={handleNotOk} fullWidth>
      <DialogTitle>Do you have any more items?</DialogTitle>
      <DialogActions>
        <Button
          onClick={handleOk}
          id="modal-yes"
          variant="outlined"
          color="primary"
        >
          Yes
        </Button>
        <Button
          onClick={handleNotOk}
          id="modal-no"
          color="secondary"
          variant="outlined"
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmAfterItem;
