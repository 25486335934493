import React, { FC } from 'react';
import AdditionalFields from './AdditionalFields';
import { DealConfig, DealProps, DealType } from '../index';
import { Deal } from '../../api-clients/types';
import PositionsTable from './PositionsTable';
import { WidgetProps } from './DealWidgets';

interface DealInfoProps {
  deal: Deal;
  fieldsConfig: DealProps['fieldsConfig'];
  widgets: WidgetProps[];
  type: DealType;
  dealConfig: DealConfig;
}

const DealInfo: FC<DealInfoProps> = ({
  deal,
  fieldsConfig,
  type,
  widgets,
  dealConfig,
}) => {
  return dealConfig.disableItemsTable ? (
    <AdditionalFields dealData={deal} fieldsConfig={fieldsConfig} type={type} />
  ) : (
    <PositionsTable
      deal={deal}
      fieldsConfig={fieldsConfig}
      type={type}
      widgets={widgets}
    />
  );
};

export default DealInfo;
