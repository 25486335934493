import React, { useState } from 'react';
import { useGetLinkedDeals } from './hooks/useGetLinkedDeals';
import {
  Box,
  TextField,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { Spinner } from '../../../../../../UI';
import MuiButton from '../../../../../../UI/MuiButton';
import MuiIconButton from '../../../../../../UI/MuiIconButton';
import { Link } from 'react-router-dom';

const FindItemsByCns = () => {
  const [ridsToCnsMapping, setRidsToCnsMapping] = useState<
    { rid: string; csns: string[] }[]
  >([]);
  const [csnsToFind, setCsnsToFind] = useState<string[]>([]);
  const [currentCsn, setCurrentCsn] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  const { data: deals, isLoading } = useGetLinkedDeals(setRidsToCnsMapping);

  const countCsnsInDeal = (dealId: string, csn: string) => {
    const deal = deals?.find((deal) => deal.id === dealId);

    if (!deal) {
      return 0;
    }

    const ridByCsn = ridsToCnsMapping.find(({ csns }) => csns.includes(csn));

    if (!ridByCsn) {
      return 0;
    }

    const product = deal.no_special_items_positions
      .filter((product) => product.article! == ridByCsn.rid)
      .map(({ quantity }) => quantity)
      .reduce((acc, curr) => acc + +curr, 0);

    return product;
  };

  if (isLoading) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner />
      </Box>
    );
  }

  const csnInDealTable = (deals || [])
    // count csns in deals
    .map((deal) => ({
      id: deal.id,
      csns: Object.fromEntries(
        csnsToFind.map((csn) => [csn, countCsnsInDeal(deal.id, csn)]),
      ),
    }))
    // ignore deals without at least one csn
    .filter(({ csns }) => Object.values(csns).some((count) => count > 0));

  return (
    <Box display="flex" width="100%" flexDirection="column" style={{ gap: 8 }}>
      {csnsToFind.length > 0 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                {csnInDealTable?.map(({ id }) => (
                  <TableCell key={id}>
                    <Link to={`/crm/deals/pickups/${id}`}>{id}</Link>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {csnsToFind.map((csn) => (
                <TableRow key={csn}>
                  <TableCell component="th" scope="row">
                    <MuiIconButton
                      onClick={() => {
                        setCsnsToFind((csns) => csns.filter((c) => c !== csn));
                      }}
                      color="error"
                      iconName="times"
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {csn}
                  </TableCell>
                  {csnInDealTable.map(({ id, csns }) => (
                    <TableCell key={id}>{csns[csn]}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Box
        display="flex"
        flexDirection="column"
        style={{
          width: 160,
          gap: 8,
        }}
      >
        {isEditing && (
          <TextField
            label="CSN to find"
            onChange={(e) => setCurrentCsn(e.target.value)}
            value={currentCsn}
          />
        )}
        <MuiButton
          color="success"
          onClick={() => {
            setIsEditing((prev) => {
              if (prev) {
                setCsnsToFind((prev) => [...prev, currentCsn]);
                setCurrentCsn('');
              }
              return !prev;
            });
          }}
          style={{ width: 160 }}
        >
          Add CSN
        </MuiButton>
      </Box>
    </Box>
  );
};

export default FindItemsByCns;
