import React from 'react';
import AbstractService, {
  AbstractServiceProps,
} from '../../../AbstractService';
import AppContainer from '../../../../layout/AppContainer/AppContainer';
import { ErrorView, Spinner } from '../../../../UI';
import { DealType } from '../GenericDeal';
import { ReturnPickupDeal } from './components/ReturnPickupDeal';
import { clientFieldConfig } from '../GenericDeal/config/client-field.config';
import { suppliers } from '../constants/suppliers';
import { carriers } from '../constants/carrier';
import { dealStatusPickup } from '../constants/deal-status';
import { returnConditionPickup } from '../constants/return-condition';
import { returnLocationsPickup } from '../constants/return-location';
import { shipStatus } from '../constants/ship-status';
import { labelCreator } from '../constants/label-creator';
import LinkedDeals from '../common/widgets/LinkedDeals/index';
import { returnReason } from '../constants/return-reason';
import { returnMarketplaceNames } from '../constants/return-marketplace-names';
import { Scenarios } from '../common/widgets/Scenarios/index';
import { scenarios } from '../../../Table/components/TableHeaderButtons/buttons/Scenarios/scenarios';
import { archiveScenarioReturnPickup } from '../constants/archive-scenario';

export class CRMReturnPickupDeal extends AbstractService<AbstractServiceProps> {
  render() {
    const { appConfig, error } = this.state;

    if (error) {
      return (
        <AppContainer services={{}}>
          <div className="w-100 h-100 d-flex flex-column justify-content-center">
            <ErrorView error={error}>
              <div className="d-flex justify-content-center p-3">
                <div
                  className="btn btn-primary cursor-pointer"
                  onClick={this.reloadHome}
                >
                  Home
                </div>
              </div>
            </ErrorView>
          </div>
        </AppContainer>
      );
    }

    if (appConfig === null) {
      return <Spinner />;
    }

    const { user, role } = this.props.userIdentity;
    return (
      <AppContainer
        currentResourceName={this.props.resourceName}
        services={this.props.availableServices}
        user={user}
        role={role}
      >
        <ReturnPickupDeal
          fieldsConfig={{
            ...clientFieldConfig,
            Return: {
              fields: [
                {
                  displayName: 'Marketplace name',
                  type: 'select',
                  options: returnMarketplaceNames,
                  apiFieldPath: 'mpName',
                },
                {
                  displayName: 'Marketplace Order Number',
                  type: 'input',
                  apiFieldPath: 'mpOrderNumber',
                },
                {
                  displayName: 'Marketplace Return Number',
                  type: 'input',
                  apiFieldPath: 'mpReturnNumber',
                },
                {
                  displayName: 'Marketplace Ret Reason',
                  type: 'select',
                  options: returnReason,
                  apiFieldPath: 'mpRetReason',
                },
                {
                  displayName: 'Deal Status',
                  type: 'select',
                  options: dealStatusPickup,
                  apiFieldPath: 'dealStatus',
                },
                {
                  displayName: 'Supplier Name',
                  type: 'select',
                  options: suppliers,
                  apiFieldPath: 'srName',
                },
              ],
            },
            'Tech fields': {
              fields: [
                {
                  displayName: 'Item Condition',
                  type: 'input',
                  apiFieldPath: 'itemCondition',
                },
                {
                  displayName: 'Archive scenario',
                  type: 'select',
                  options: archiveScenarioReturnPickup,
                  apiFieldPath: 'archiveScenario',
                },
                {
                  displayName: 'Return Condition',
                  type: 'select',
                  options: returnConditionPickup,
                  apiFieldPath: 'returnCondition',
                },
                {
                  displayName: 'Problem Description',
                  type: 'input',
                  apiFieldPath: 'problemDescription',
                  multiline: true,
                },
              ],
            },
            Tracking: {
              fields: [
                {
                  displayName: 'Return Location',
                  type: 'select',
                  options: returnLocationsPickup,
                  apiFieldPath: 'returnLocation',
                },
                {
                  displayName: 'Label Creator',
                  type: 'select',
                  options: labelCreator,
                  apiFieldPath: 'labelCreator',
                },
                {
                  displayName: 'Tracknumber',
                  type: 'input',
                  apiFieldPath: 'tracknumber',
                },
                {
                  displayName: 'Carrier',
                  type: 'select',
                  options: carriers,
                  apiFieldPath: 'carrier',
                },
                {
                  displayName: 'Ship Status',
                  type: 'select',
                  options: shipStatus,
                  apiFieldPath: 'shipStatus',
                },
              ],
            },
          }}
          type={DealType.ReturnPickup}
          buttons={[]}
          widgets={[
            {
              displayName: 'Linked deals',
              buttonName: 'Linked deals',
              component: LinkedDeals,
              props: {
                linkField: 'relatedReturn',
              },
              docsLink:
                'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.hwje1ah0h4ok',
              docsTitle: 'docs for Linked deals widget',
            },
            {
              displayName: 'Scenarios',
              buttonName: 'Scenarios',
              component: Scenarios,
              props: {
                scenarios: scenarios.returnPickup,
              },
              docsLink:
                'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.m1wjq0kj6hxo',
              docsTitle: 'docs for Scenarios widget',
            },
          ]}
        />
      </AppContainer>
    );
  }
}
