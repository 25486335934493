import React from 'react';
import AbstractService, {
  AbstractServiceProps,
} from '../../../AbstractService';
import AppContainer from '../../../../layout/AppContainer/AppContainer';
import { ErrorView, Spinner } from '../../../../UI';
import { DealType } from '../GenericDeal';
import ReturnsDeal from './components/ReturnsDeal';
import { clientFieldConfig } from '../GenericDeal/config/client-field.config';
import { suppliers } from '../constants/suppliers';
import { carriers } from '../constants/carrier';
import { returnLocations } from '../constants/return-location';
import { returnCondition } from '../constants/return-condition';
import { dealStatus } from '../constants/deal-status';
import { labelCreator } from '../constants/label-creator';
import LinkedDeals from '../common/widgets/LinkedDeals/index';
import { returnReason } from '../constants/return-reason';
import { returnMarketplaceNames } from '../constants/return-marketplace-names';
import { Scenarios } from '../common/widgets/Scenarios';
import { apiClientsReturnPickup } from '../api-clients/api-clients-return-pickups';
import { apiClientsReturnDropship } from '../api-clients/api-clients-return-dropships';
import { scenarios } from '../../../Table/components/TableHeaderButtons/buttons/Scenarios/scenarios';
import { archiveScenarioReturns } from '../constants/archive-scenario';

export class CRMReturnsDeal extends AbstractService<AbstractServiceProps> {
  render() {
    const { appConfig, error } = this.state;

    if (error) {
      return (
        <AppContainer services={{}}>
          <div className="w-100 h-100 d-flex flex-column justify-content-center">
            <ErrorView error={error}>
              <div className="d-flex justify-content-center p-3">
                <div
                  className="btn btn-primary cursor-pointer"
                  onClick={this.reloadHome}
                >
                  Home
                </div>
              </div>
            </ErrorView>
          </div>
        </AppContainer>
      );
    }

    if (appConfig === null) {
      return <Spinner />;
    }

    const { user, role } = this.props.userIdentity;
    return (
      <AppContainer
        currentResourceName={this.props.resourceName}
        services={this.props.availableServices}
        user={user}
        role={role}
      >
        <ReturnsDeal
          fieldsConfig={{
            ...clientFieldConfig,
            Marketplace: {
              fields: [
                {
                  displayName: 'Marketplace name',
                  type: 'select',
                  options: returnMarketplaceNames,
                  apiFieldPath: 'mpName',
                },
                {
                  displayName: 'Marketplace Order Number',
                  type: 'input',
                  apiFieldPath: 'mpOrderNumber',
                },
                {
                  displayName: 'Marketplace Return Number',
                  type: 'input',
                  apiFieldPath: 'mpReturnNumber',
                },
                {
                  displayName: 'Marketplace Date Opened',
                  type: 'datetime',
                  apiFieldPath: 'mpDateOpened',
                },
                {
                  displayName: 'Marketplace Ret Reason',
                  type: 'select',
                  options: returnReason,
                  apiFieldPath: 'mpRetReason',
                },
                {
                  displayName: 'Supplier Name',
                  type: 'select',
                  options: suppliers,
                  apiFieldPath: 'srName',
                },
              ],
            },
            'Tech fields': {
              fields: [
                {
                  displayName: 'Deal Status',
                  type: 'select',
                  options: dealStatus,
                  apiFieldPath: 'dealStatus',
                },
                {
                  displayName: 'Archive scenario',
                  type: 'select',
                  options: archiveScenarioReturns,
                  apiFieldPath: 'archiveScenario',
                },
                {
                  displayName: 'Problem Description',
                  type: 'input',
                  apiFieldPath: 'problemDescription',
                  multiline: true,
                },
                {
                  displayName: 'Notes',
                  type: 'input',
                  apiFieldPath: 'notes',
                },
                {
                  displayName: 'Return Condition',
                  type: 'select',
                  options: returnCondition,
                  apiFieldPath: 'returnCondition',
                },
              ],
            },
            Tracking: {
              fields: [
                {
                  displayName: 'Return Location',
                  type: 'select',
                  options: returnLocations,
                  apiFieldPath: 'returnLocation',
                },
                {
                  displayName: 'Label Creator',
                  type: 'select',
                  options: labelCreator,
                  apiFieldPath: 'labelCreator',
                },
                {
                  displayName: 'Tracknumber',
                  type: 'input',
                  apiFieldPath: 'tracknumber',
                },
                {
                  displayName: 'Carrier',
                  type: 'select',
                  options: carriers,
                  apiFieldPath: 'carrier',
                },
              ],
            },
          }}
          type={DealType.Pickup}
          buttons={[]}
          widgets={[
            {
              displayName: 'Linked deals',
              buttonName: 'Linked deals',
              component: LinkedDeals,
              props: {
                linkField: 'id',
                apiClients: [
                  {
                    type: DealType.ReturnPickup,
                    apiClient: apiClientsReturnPickup,
                    customLinkField: 'relatedReturn',
                  },
                  {
                    type: DealType.ReturnDropship,
                    apiClient: apiClientsReturnDropship,
                    customLinkField: 'relatedReturn',
                  },
                ],
              },
              docsLink:
                'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.hwje1ah0h4ok',
              docsTitle: 'docs for Linked deals widget',
            },
            {
              displayName: 'Scenarios',
              buttonName: 'Scenarios',
              component: Scenarios,
              props: {
                scenarios: scenarios.return,
              },
              docsLink:
                'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.m1wjq0kj6hxo',
              docsTitle: 'docs for Scenarios widget',
            },
          ]}
        />
      </AppContainer>
    );
  }
}
