import LifecycleToken from './lifecycle-token';
import { Configuration, LogLevelEnum, LogsIngestorApi } from '../logger-api';

const loggerApi = new LogsIngestorApi(
  new Configuration(),
  '/api/openapi/LogsIngestor/v1',
);

export class Logger {
  private lcTokenStore = new LifecycleToken(sessionStorage);

  private isLocal = process.env.REACT_APP_LOCAL === 'true';

  private logsQueue: any[] = [];
  private flushLogsTimeout: null | number = null;

  private readonly serviceName = `rollun-net-frontend${
    this.isLocal ? '-local' : ''
  }`;

  // since logger adds event listener, make it a singleton to
  // prevent memory leak
  public static instance: Logger | null;
  constructor() {
    if (Logger.instance) {
      return Logger.instance;
    }

    Logger.instance = this;

    // flush logs on page unload, to prevent losing logs
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        this.flushLogs();
      }
    });
  }

  private queueLog(level: LogLevelEnum, message: string, context?: any) {
    if (this.isLocal) return;
    const lifecycleToken =
      context?.lifecycleToken || this.lcTokenStore.getRequestToken();

    const logEntry = {
      service: this.serviceName,
      level,
      datetime: new Date().toISOString(),
      message,
      context,
      serviceVersion: process.env.REACT_APP_VERSION || 'unknown',
      lifecycleToken,
      parentLifecycleToken: this.lcTokenStore.getSessionToken(),
    };

    this.logsQueue.push(logEntry);

    console.log('queue log', { logEntry, queue: this.logsQueue });
    if (this.logsQueue.length > 100) {
      // flush logs if queue is too big
      console.log('flushing logs by queue size');
      this.flushLogs();
    } else {
      if (!this.flushLogsTimeout) {
        // flush logs after 30 seconds
        this.flushLogsTimeout = window.setTimeout(() => {
          console.log('flushing logs by timeout');
          this.flushLogs();
        }, 30000);
      }
    }
  }

  private flushLogs() {
    console.log('flushing logs', this.logsQueue);
    const logsToFlush = [...this.logsQueue];
    this.logsQueue = [];
    if (this.flushLogsTimeout) {
      window.clearTimeout(this.flushLogsTimeout);
      this.flushLogsTimeout = null;
    }

    if (!logsToFlush.length) {
      return;
    }

    loggerApi
      .ingestPost({ logs: logsToFlush })
      .catch((err) => console.error('Writing log message error', err));
  }

  info = (message: string, context: any) =>
    this.queueLog(LogLevelEnum.Info, message, context);
  warning = (message: string, context: any) =>
    this.queueLog(LogLevelEnum.Warning, message, context);
  error = (message: string, context: any) =>
    this.queueLog(LogLevelEnum.Error, message, context);
}

export const logger = new Logger();
