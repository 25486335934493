import React, { FC, useEffect, useState } from 'react';
import { OrderType } from '../utils/types';
import {
  ORDER_PARTIALLY_PACKED,
  ORDER_PICKED_UP_FROM_SUPPLIER,
  ORDERS_HAVE_BEEN_DELIVERED_TO_THE_POST_OFFICE,
  SHOW_ORDERS_FOR_DAYS,
} from '../constants';
import { formatDate } from '../utils';
import HttpDatastore from 'rollun-ts-datastore';
import Query from 'rollun-ts-rql/dist/Query';
import Sort from 'rollun-ts-rql/dist/nodes/Sort';
import Spinner from '../../../UI/Spinner';
import Order from './Order';
import { Button, ConfirmButton, ErrorView } from '../../../UI';
import { httpErrorHandler } from '../../../utils/common.utils';
import { ErrorType, Senders } from '../../../utils/common.types';
import { And, Eq, Ge } from 'rollun-ts-rql';
import { Box } from '@material-ui/core';

enum OrdersAction {
  PENDING,
  GET_ORDERS,
  ERROR,
}

type PreprocessedOrdersArray = Array<PreprocessedOrder>;
type PreprocessedOrder = {
  TimeCreated: string | undefined;
  orders: OrderType[];
};

interface IProps {
  senderName: Senders;

  onOrdersSelectComplete(
    orders: Array<{ id: string; supplier: string }>,
    somePartiallyPacked: boolean,
  ): void;
}

const Orders: FC<IProps> = ({ senderName, onOrdersSelectComplete }) => {
  const [action, setAction] = useState(OrdersAction.GET_ORDERS);
  const [orders, setOrders] = useState<OrderType[]>([]);
  const [error, setError] = useState<ErrorType>();
  const [somePartiallyPacked, setSomePartiallyPacked] = useState(false);
  const [ordersToWork, setOrdersToWork] = useState<string[]>([]);

  const ordersStore = new HttpDatastore<OrderType>(
    `/api/datastore/unitedOrderDataStore`,
  );
  const barcodeActionDataStore = new HttpDatastore(
    '/api/datastore/BarcodeActionHistory',
  );

  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = () => {
    setAction(OrdersAction.PENDING);
    ordersStore
      .query(
        new Query({
          query: new And([
            // Get all orders for past {SHOW_ORDERS_PERIOD_DAYS} days
            new Ge(
              'TimeCreated',
              formatDate(
                new Date(Date.now() - 86400 * SHOW_ORDERS_FOR_DAYS * 1000),
              ),
            ),
            new Eq('sender', senderName),
          ]),
          sort: new Sort({ TimeCreated: -1 }),
        }),
      )
      .then((data) => {
        setAction(OrdersAction.GET_ORDERS);
        setOrders(data);
        setSomePartiallyPacked(
          _someOrderPartiallyPacked(data as Array<OrderType>),
        );
      })
      .catch((error) => {
        httpErrorHandler(error, (code, text) => {
          setAction(OrdersAction.ERROR);
          setError({ code, text });
        });
      });
  };

  // if at least one of orders has status {ORDER_PARTIALLY_PACKED},
  // returns true and false otherwise.
  const _someOrderPartiallyPacked = (data: Array<OrderType>): boolean => {
    return !!data.find((el) => {
      if (!el.status) return false;
      return el.status.trim() === ORDER_PARTIALLY_PACKED;
    });
  };

  const handleOrderSelect = (order: string, remove: boolean) => {
    // add or remove orders
    const NewOrdersToWork = remove
      ? ordersToWork.filter((item) => item !== order)
      : [...ordersToWork, order];

    setOrdersToWork(NewOrdersToWork);
  };

  const handleOrdersByAction = (
    action:
      | typeof ORDERS_HAVE_BEEN_DELIVERED_TO_THE_POST_OFFICE
      | typeof ORDER_PICKED_UP_FROM_SUPPLIER,
  ) => {
    setAction(OrdersAction.PENDING);

    const promises = ordersToWork.map((order) =>
      barcodeActionDataStore.create({
        order_number: order,
        sender: senderName,
        action,
        timestamp: new Date().getTime() / 1000,
      }),
    );
    Promise.all(promises)
      .then(() => setAction(OrdersAction.GET_ORDERS))
      .catch((e) =>
        httpErrorHandler(e, (code, text) => {
          setAction(OrdersAction.ERROR);
          setError({ code, text });
        }),
      );
  };

  const preprocessOrders = (orders: OrderType[]): PreprocessedOrdersArray =>
    orders.reduce((acc: PreprocessedOrdersArray, order) => {
      const elem = acc.find((el) => el.TimeCreated === order.TimeCreated);
      if (elem) {
        elem.orders.push(order);
      } else {
        acc.push({ TimeCreated: order.TimeCreated, orders: [order] });
      }
      return acc;
    }, []);

  const renderSwitch = (appState: OrdersAction) => {
    const buttonDisabled = ordersToWork.length === 0;
    switch (appState) {
      case OrdersAction.PENDING:
        return (
          <Box className="w-100 h-100 vh-50">
            <Spinner />
          </Box>
        );
      case OrdersAction.GET_ORDERS: {
        if (orders && orders.length > 0) {
          return (
            <>
              <h2>Check orders</h2>
              {/*<h3>{this.props.supplierName}</h3>*/}
              <span>Select orders</span>
              <Box className="layout">
                <Box className="orders-layout">
                  <Box
                    style={{
                      maxWidth: '600px',
                      width: '100%',
                    }}
                  >
                    {preprocessOrders(orders).map((obj: PreprocessedOrder) => (
                      <Box>
                        <Box>
                          <h6>{obj.TimeCreated}</h6>
                          {obj.orders.map((order: OrderType) => (
                            <Box>
                              <Order
                                order={order}
                                active={ordersToWork.includes(order.id)}
                                key={order.id}
                                onChange={handleOrderSelect}
                              />
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    ))}
                    <Button
                      disabled={buttonDisabled}
                      block
                      color="primary"
                      onClick={() => {
                        onOrdersSelectComplete(
                          orders.filter(({ id }) => ordersToWork.includes(id)),
                          somePartiallyPacked,
                        );
                      }}
                    >
                      Start scanning labels
                    </Button>
                    <ConfirmButton
                      modalBody="Are You sure, that You picked up orders from supplier?"
                      onConfirm={() =>
                        handleOrdersByAction(ORDER_PICKED_UP_FROM_SUPPLIER)
                      }
                      className="btn-success btn-block"
                      submitBtnText="Yes"
                      declineBtnText="No"
                      disabled={buttonDisabled}
                    >
                      Orders are picked up from the supplier
                    </ConfirmButton>
                    <ConfirmButton
                      modalBody="Are You sure, that orders have been delivered to post office"
                      onConfirm={() =>
                        handleOrdersByAction(
                          ORDERS_HAVE_BEEN_DELIVERED_TO_THE_POST_OFFICE,
                        )
                      }
                      className="btn-info btn-block"
                      submitBtnText="Yes"
                      declineBtnText="No"
                      disabled={buttonDisabled}
                    >
                      {ORDERS_HAVE_BEEN_DELIVERED_TO_THE_POST_OFFICE}
                    </ConfirmButton>
                  </Box>
                </Box>
              </Box>
            </>
          );
        } else {
          return <Box>There are no orders</Box>;
        }
      }
      case OrdersAction.ERROR:
        return (
          <ErrorView error={error}>
            <Box className="d-flex justify-content-center">
              <Button color="primary" onClick={getOrders}>
                Try again
              </Button>
            </Box>
          </ErrorView>
        );
      default:
        return;
    }
  };

  return <Box className="main-layout">{renderSwitch(action)}</Box>;
};

export default Orders;
