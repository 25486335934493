import { Component, ReactNode } from 'react';
import React from 'react';
import { RqlParser, Limit, Query } from 'rollun-ts-rql';
import { CopyPasteUploaderParams } from '../CopyPasteUploaders/CopyPasteUploader';
import TableWithFilter, { RowContent } from './TableWithFilter';
import TableFilterEditor from './TableFilterEditor';
import _ from 'lodash';
import { encodeRqlString } from '../../../../utils/common.utils';
import { FILTERS_DATASTORE_URL } from '../../util/constants';
import { FluidFormField } from '../../../../UI/FluidForm';
import { ColumnsConfig } from '../../util/config.utils';
import TableHeader from './TableHeader';
import { ErrorType } from '../../../../utils/common.types';
import CustomAlert from '../../../../UI/CustomAlert';
import { Box, Theme, WithStyles, withStyles, Card } from '@material-ui/core';
import MuiButton from '../../../../UI/MuiButton';
import { DataStoreInterface } from 'rollun-ts-datastore';
import { HeaderConfig, TagsUpdaterParams } from '../../../AbstractService';

export interface QueryAppProps extends WithStyles {
  appName: string;
  dataStoreUrl: string;
  columnsConfig?: ColumnsConfig;
  pasteUploaderParams?: CopyPasteUploaderParams;
  idField?: string;
  enableDeleteAll?: boolean;
  enableDeleteItem?: boolean;
  filterQuery?: string;
  defaultQuery?: string;
  isLocal?: boolean;
  onChangeQuery?: (query: Query) => void;
  formConfig?: FluidFormField[];
  userNote?: string;
  currentResourceName?: string;
  onRowSelect?(row: RowContent): void;
  descriptionConfigPath?: string;
  initialLocalDataStore?: DataStoreInterface;
  header?: HeaderConfig;
  tagsUpdaterParams?: TagsUpdaterParams;
  openInBpmnEditor?: boolean;
}

interface IState {
  query: Query | null;
  isFiltersEdit: boolean;
  isFiltersShown: boolean;
  error: ErrorType | null;
}

const classes = (theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
    padding: theme.spacing(1),
    [theme.breakpoints.down(1000)]: {
      flexDirection: 'column',
    },
  },
});

export const makeDefaultQuery = () => new Query({ limit: new Limit(20, 0) });

class TableContainer extends Component<QueryAppProps, IState> {
  rqlParser: RqlParser = new RqlParser();

  constructor(props: QueryAppProps) {
    super(props);
    const [query, error] = this.makeDefaultQuery();

    this.state = {
      query,
      isFiltersEdit: false,
      isFiltersShown: true,
      error,
    };
  }

  componentDidMount(): void {
    const { appName } = this.props;
    const capitalizedWords = appName
      .split('-')
      .filter(Boolean)
      .map((word) => `${word?.[0].toUpperCase()}${word.slice(1)}`);

    const modifiedAppName =
      capitalizedWords?.[0] === 'Crm' && capitalizedWords?.[1] === 'Deals'
        ? [
            ...capitalizedWords.slice(2),
            capitalizedWords[0],
            capitalizedWords[1],
          ].join('')
        : capitalizedWords.join('');

    const title = modifiedAppName;
    if (this.props.appName && title) document.title = title;
  }

  componentWillUnmount(): void {
    document.title = 'Rollun CMS';
  }

  makeDefaultQuery = (): [Query, ErrorType | null] => {
    const { defaultQuery, onChangeQuery } = this.props;
    const error = {
      code: 400,
      text: `Could not parse your query. Your query: ${this.props.defaultQuery}`,
    };

    // if undefined, means the rql url is empty, ignore this case
    if (!defaultQuery) return [makeDefaultQuery(), null];
    const encodedRql = encodeRqlString(defaultQuery);
    try {
      const parsedQuery = this.rqlParser.parse(encodedRql);

      parsedQuery.limitNode._offset = parsedQuery.limitNode._offset || 0;
      return [parsedQuery, null];
    } catch (err) {
      console.log("Couldn't parse default query!", defaultQuery, err);

      const q = makeDefaultQuery();
      // fire change event, because default query could not be parsed
      // and it falls back to defaults - so query changed
      onChangeQuery && onChangeQuery(q);
      return [q, error];
    }
  };

  onToggleFiltersShow = () => {
    this.setState(({ isFiltersShown }) => ({
      isFiltersShown: !isFiltersShown,
    }));
  };

  render(): ReactNode {
    const {
      dataStoreUrl,
      columnsConfig,
      pasteUploaderParams,
      enableDeleteAll,
      idField,
      appName,
      enableDeleteItem,
      isLocal,
      formConfig,
      userNote,
      descriptionConfigPath,
      classes,
      currentResourceName,
      onRowSelect,
      initialLocalDataStore,
      header,
      tagsUpdaterParams = {
        enableTagsUpdater: false,
      },
      openInBpmnEditor,
    } = this.props;

    const { isFiltersEdit, query, error } = this.state;

    return (
      <Box height="100%">
        {!!error && (
          <CustomAlert
            isOpen={!!error}
            msg="Your filter was not correct. We used the default one"
            onClose={() => this.setState({ error: null })}
            alertType="error"
          />
        )}
        <Card className={classes.root} elevation={3}>
          <TableHeader appName={appName} dataStoreUrl={dataStoreUrl} />
          <div className="table-header__buttons">
            <MuiButton
              size="xs"
              color="primary"
              id="saved-filters"
              onClick={this.onToggleFiltersShow}
            >
              Saved Filters
            </MuiButton>
            <MuiButton
              size="xs"
              color="info"
              id="filters-table"
              onClick={this.onToggleFilterEditor}
            >
              {isFiltersEdit ? 'Back' : 'Filters Table'}
            </MuiButton>
          </div>
        </Card>
        {isFiltersEdit ? (
          <TableFilterEditor
            appName={dataStoreUrl}
            datastoreUrl={FILTERS_DATASTORE_URL}
            onApplyFilter={this.onApplyFilter}
            onToggleFilterEditor={this.onToggleFilterEditor}
          />
        ) : (
          <TableWithFilter
            descriptionConfigPath={descriptionConfigPath}
            enableNodeRedInterop
            isFiltersShown={this.state.isFiltersShown}
            currentResourceName={currentResourceName}
            appName={appName}
            isLocal={isLocal}
            initialLocalDataStore={initialLocalDataStore}
            datastoreUrl={dataStoreUrl}
            formConfig={formConfig}
            query={query || makeDefaultQuery()}
            columnsConfig={columnsConfig}
            pasteUploaderParams={pasteUploaderParams}
            enableDeleteAll={enableDeleteAll}
            enableDeleteItem={enableDeleteItem}
            idField={idField}
            userNote={userNote}
            onChangeQuery={this.onChangeQuery}
            onRowSelect={onRowSelect}
            header={header}
            tagsUpdaterParams={tagsUpdaterParams}
            openInBpmnEditor={openInBpmnEditor}
          />
        )}
      </Box>
    );
  }

  onChangeQuery = (query: Query) => {
    if (this.props.onChangeQuery) {
      this.props.onChangeQuery(query);
    }
    this.setState({ query: query });
  };

  onApplyFilter = (query: Query) => {
    this.setState({
      query: _.cloneDeep(query),
      isFiltersEdit: false,
    });
  };

  onToggleFilterEditor = () => {
    this.setState(({ isFiltersEdit }) => ({
      isFiltersEdit: !isFiltersEdit,
    }));
  };
}

export default withStyles(classes)(TableContainer);
