import React, { FC } from 'react';
import { UploadFileButton } from './UploadFileButton';
import { MuiButtonProps } from './MuiButton';

interface UploadBase64FileButtonProps extends MuiButtonProps {
  setBase64Data: (
    value: React.SetStateAction<{ file: string; name: string }>,
  ) => void;
  className?: string;
}

export const UploadBase64FileButton: FC<UploadBase64FileButtonProps> = (
  props,
) => {
  const { children, setBase64Data } = props;

  const handleFileUpload = (event: any) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader?.result?.toString()?.split?.(',')[1];
        if (base64String) {
          setBase64Data({ file: base64String, name: selectedFile.name });
        }
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  return (
    <UploadFileButton
      handleFileUpload={handleFileUpload}
      accept=".pdf"
      {...props}
    >
      {children}
    </UploadFileButton>
  );
};
