import React, { Component } from 'react';
import { RouteRow } from '../../utils/types';

class RouteView extends Component<{ route: RouteRow }> {
  render() {
    const { route } = this.props;
    return (
      <div className="mx-3 my-2">
        <h3>Route</h3>
        <div className="d-flex justify-content-between ">
          <a style={{ fontSize: '1rem' }} href={route.path}>
            {route.name}
          </a>
        </div>
      </div>
    );
  }
}

export default RouteView;
