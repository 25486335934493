import React from 'react';
import AbstractTableWithFilter, {
  AbstractQueryEditorAppProps,
  AbstractQueryEditorAppState,
} from './AbstractTableWithFilter';
import { FluidFormField } from '../../../../UI/FluidForm';
import {
  CopyPastePasteAfterField,
  CopyPastePasteAfterValues,
  CopyPasteUploaderParams,
} from '../CopyPasteUploaders/CopyPasteUploader';
import {
  AbstractQueryNode,
  AbstractScalarNode,
  And,
  Eq,
  Limit,
  Or,
  Query,
  Sort,
} from 'rollun-ts-rql';
import _ from 'lodash';
import { makeDefaultQuery } from './TableContainer';
import TableControls from './TableControls';
import Paginator from '../../../../controls/Paginator/Paginator';
import {
  clamp,
  ProgressCallback,
  sendRequestsInChunks,
  Task,
} from '../../../../utils/common.utils';
import { LoadingStatusEnum } from '../../../../utils/common.types';
import FiltersPreview from './FiltersPreview';
import { appendQuery, setQueryNode } from '../../../../utils/query.utils';
import AbstractLogicalNode from 'rollun-ts-rql/dist/nodes/logicalNodes/AbstractLogicalNode';
import NodeRedInterop from '../NodeRedInterop/NodeRedInterop';
import { LocalDatastore } from 'rollun-ts-datastore';
import { castValueForSearch } from '../../util';
import { ColumnsConfig } from '../../util/config.utils';
import ReactDataGridAdapter from './ReactDataGridAdapter';
import TableDescription from './TableDescription';
import { wait } from 'rollun-ts-utils/dist';
import MultiUpdateDialog from './MultiUpdateDialog';
import { HeaderConfig, TagsUpdaterParams } from '../../../AbstractService';
import { HeaderComponentContainer } from './FooterComponents/HeaderComponentContainer';
import { logger } from '../../../../utils/logger';
import { isEditAllowed } from './utils';
import { JSONataProvider } from '../JSONataInterop/JSONataContext';
import { JSONataInteropModal } from '../JSONataInterop/JSONataInteropModal';
import { parse } from 'qs';

export type OnUploadData = (
  _data: Array<any>,
  callback: ProgressCallback,
  forceDisableMultiCreate?: boolean,
) => void;

export interface RowContent {
  [key: string]: any;
}

interface IProps extends AbstractQueryEditorAppProps {
  appName: string;
  columnsConfig?: ColumnsConfig;
  query?: Query;
  formConfig?: FluidFormField[];
  pasteUploaderParams?: CopyPasteUploaderParams;
  enableDeleteAll?: boolean;
  enableDeleteItem?: boolean;
  userNote?: string;
  enableNodeRedInterop?: boolean;
  currentResourceName?: string;
  isFiltersShown?: boolean;
  additionalHeightMinus?: number;
  // example: config.appParams.gridParams.userNote
  descriptionConfigPath?: string;
  maxTableWidth?: number;
  onRowSelect?(row: RowContent): void;
  header?: HeaderConfig;
  tagsUpdaterParams?: TagsUpdaterParams;
  submitCLDV3ItemFromCLDV3?(item: any): void;
  openInBpmnEditor?: boolean;
}

interface IState extends AbstractQueryEditorAppState {
  isUserNoteShown: boolean;
  nodeRedInteropShown: boolean;
  isMultiUpdateModalShown: boolean;
  isFiltersShown: boolean;
  headerName: string;
  isEditAllowed: null | boolean;
  localSortOptions: {
    direction: 1 | -1 | null;
    name: string;
  } | null;
  shouldUpdatePaginatorCount: number;
}

class TableWithFilter extends AbstractTableWithFilter<IProps, IState> {
  state: IState = {
    data: [],
    dataWithId: [],
    error: {
      text: '',
      code: -1,
    },
    query: _.cloneDeep(this.props.query) || makeDefaultQuery(),
    loadingStatus: LoadingStatusEnum.loading,
    isUserNoteShown: false,
    nodeRedInteropShown: false,
    isMultiUpdateModalShown: false,
    isFiltersShown: false,
    headerName: '',
    isEditAllowed: null,
    localSortOptions: null,
    shouldUpdatePaginatorCount: 0,
  };

  async componentDidMount() {
    super.componentDidMount();

    try {
      const res = await isEditAllowed(this.props.datastoreUrl);
      this.setState({ isEditAllowed: res });
    } catch (e) {
      logger.info('isEditAllowed', {
        message: 'Failed to get isEditAllowed',
        error: (e as Error).message,
      });
      this.setState({ isEditAllowed: true });
    }
  }

  formatSearchOptions(
    q: AbstractQueryNode,
  ): Array<{ name: string; value: string }> {
    if (q instanceof AbstractLogicalNode) {
      return q.subNodes.reduce(
        (acc: { name: string; value: string }[], node) => {
          return node instanceof AbstractScalarNode
            ? acc.concat({
                name: node.field,
                value: node.value.toString(),
              })
            : acc;
        },
        [],
      );
    }

    if (q instanceof AbstractScalarNode) {
      return [
        {
          name: q.field,
          value: q.value.toString(),
        },
      ];
    }
    return [];
  }

  formatSortOptions(
    sortNode?: Sort,
  ): Array<{ name: string; direction: -1 | 1 }> {
    if (sortNode) {
      return Object.entries(sortNode.sortOptions).map(([name, direction]) => ({
        name,
        direction,
      }));
    }
    return [];
  }

  toggleNodeRedInterop = () => {
    this.setState(({ nodeRedInteropShown }) => ({
      nodeRedInteropShown: !nodeRedInteropShown,
    }));
  };

  submitCLDV3Item = () => {
    if (!this.props.submitCLDV3ItemFromCLDV3) {
      return;
    }
    const item = this.state.data.find(({ id }) => id === this.selectedItemID);
    this.props.submitCLDV3ItemFromCLDV3(item);
  };

  openInBpmnEditor = () => {
    const item = this.state.data.find(({ id }) => id === this.selectedItemID);

    window.location.href = `/bpmn?id=${item.id}`;
  };

  toggleMultiUpdateModal = (headerName = '') => {
    this.setState(({ isMultiUpdateModalShown }) => ({
      isMultiUpdateModalShown: !isMultiUpdateModalShown,
      headerName,
    }));
  };

  clearItemCell = (row: any, cellName: string, initialValue: any) => {
    const idField = this.props.idField;
    if (!idField || !row || !cellName) {
      return;
    }

    const item = {
      [idField]: row[idField],
      [cellName]: initialValue,
    };
    this.datastore.update(item);
  };

  copyDataToClipboard = (withHeaders = false) => {
    const headers = Object.entries(this.state.data[0])
      .map(([col]) => col)
      .join('\t');

    const rows = this.state.data.map((rowObject) => {
      const row = Object.entries(rowObject).map(([col, value]) => value);
      return row.join('\t');
    });
    if (withHeaders) {
      rows.splice(0, 0, headers);
    }

    const data = rows.join('\n');

    navigator.clipboard.writeText(data);
  };

  render() {
    const {
      columnsConfig,
      formConfig,
      pasteUploaderParams,
      appName,
      enableDeleteAll,
      enableDeleteItem,
      idField,
      datastoreUrl,
      userNote,
      enableNodeRedInterop = false,
      isFiltersShown,
      descriptionConfigPath,
      currentResourceName,
      header,
      tagsUpdaterParams = {
        enableTagsUpdater: false,
      },
      openInBpmnEditor,
    } = this.props;
    console.log('this.props', this.props);
    console.log('dataWithId', this.state.dataWithId);

    const {
      nodeRedInteropShown,
      isMultiUpdateModalShown,
      isUserNoteShown,
      query: { sortNode, queryNode },
      isEditAllowed,
    } = this.state;

    const deleteOptions = {
      all: enableDeleteAll,
      one: enableDeleteItem,
      handler: this.itemsDelete,
    };

    const sortOptions = this.formatSortOptions(sortNode);

    // turn Scalar nodes to array of name and value to properly display that in fast access panel
    const searchOptions = this.formatSearchOptions(queryNode);

    return (
      <JSONataProvider initialValue={this.state.data}>
        <JSONataInteropModal isVisible={false} />
        <div className="h-100 d-flex justify-content-start flex-column">
          {isFiltersShown && (
            <FiltersPreview
              datastoreUrl={datastoreUrl}
              setQuery={(query) => query && this.setQuery(query)}
            />
          )}
          {/* TODO replace old descriptions with markdown format*/}
          {/*{userNote && this.state.isUserNoteShown &&*/}
          {/* <Description source={userNote}/>}*/}
          <TableDescription
            currentResourceName={currentResourceName}
            userNote={userNote}
            isUserNoteShown={isUserNoteShown}
            configPath={descriptionConfigPath}
          />
          {header?.additionalHeaderComponent && (
            <HeaderComponentContainer
              component={header.additionalHeaderComponent}
            />
          )}
          <TableControls
            datastoreUrl={datastoreUrl}
            idField={idField}
            appName={appName}
            deleteOptions={deleteOptions}
            onItemDelete={this.itemDelete}
            onItemCreate={this.itemCreate}
            loadingStatus={this.state.loadingStatus}
            fieldNames={this.actualFieldNames}
            columnsConfig={columnsConfig}
            query={_.cloneDeep(this.state.query)}
            onChangeQuery={this.setQuery}
            formConfig={formConfig}
            reloadData={() => {
              this.refreshCount();
              this.refreshTable();
            }}
            clearQuery={this.resetQuery}
            getCurrentData={() => this.state.data}
            pasteUploaderParams={pasteUploaderParams}
            oldPasteUploader={!this.multiCreateSupported}
            userNote={userNote}
            toggleNodeRedInterop={
              enableNodeRedInterop ? this.toggleNodeRedInterop : undefined
            }
            toggleUserNote={this.toggleUserNote}
            handleCreate={this.onUploadData}
            tagsUpdaterParams={tagsUpdaterParams}
            submitCLDV3Item={
              !!this.props.submitCLDV3ItemFromCLDV3 && this.submitCLDV3Item
            }
            copyDataToClipboard={this.copyDataToClipboard}
            openInBpmnEditor={openInBpmnEditor && this.openInBpmnEditor}
          />

          <ReactDataGridAdapter
            isFiltersShown={this.props.isFiltersShown}
            maxWidth={this.props.maxTableWidth}
            additionalHeightMinus={this.props.additionalHeightMinus}
            getSelectedRow={(row: any) => {
              const { onRowSelect } = this.props;
              if (onRowSelect) onRowSelect(row);
              this.setSelectedItemId(row);
            }}
            isEditAllowed={isEditAllowed}
            onHeaderMenuToggle={this.toggleMultiUpdateModal}
            searchOptions={searchOptions}
            onSearchFieldChange={this.changeSearchField}
            sortOptions={sortOptions}
            toggleSort={this.toggleSort}
            toggleLocalSort={this.toggleLocalSort}
            appName={appName || 'grid'}
            data={this.state.data}
            selectedRowIndex={0}
            onLookup={this.onLookup}
            error={this.state.error}
            loadingStatus={
              isEditAllowed !== null
                ? this.state.loadingStatus
                : LoadingStatusEnum.loading
            }
            columnsConfig={columnsConfig}
            onChangeDataValue={this.updateItem}
            idField={idField}
            clearItemCell={this.clearItemCell}
          />
          <Paginator
            currentCount={this.state.data.length}
            idField={idField}
            query={this.state.query}
            shouldUpdatePaginatorCount={this.state.shouldUpdatePaginatorCount}
            isLocal={this.props.isLocal}
            dataStoreURL={this.props.datastoreUrl}
            getCount={
              this.datastore instanceof LocalDatastore
                ? () => this.datastore.count()
                : undefined
            }
            loadingStatus={this.state.loadingStatus}
            updateCount={this.state.loadingStatus === LoadingStatusEnum.loaded}
            pageSizeOptions={[20, 50, 100, 500, 1000, 10000, 50000]}
            onSetLimitNode={(limitNode: Limit) => {
              const query = _.cloneDeep(this.state.query);
              query.limitNode = limitNode;
              this.setQuery(query);
            }}
          />
          {enableNodeRedInterop && nodeRedInteropShown && (
            <NodeRedInterop
              data={this.state.data}
              query={this.state.query}
              onChangeQuery={this.setQuery}
              scrollIntoViewOnMount
              datastoreURL={datastoreUrl}
            />
          )}
          <MultiUpdateDialog
            datastoreUrl={datastoreUrl}
            fieldNames={this.fieldNames}
            headerName={this.state.headerName}
            idField={idField || 'id'}
            isMultiUpdateModalShown={isMultiUpdateModalShown}
            query={this.state.query}
            toggleMultiUpdateModal={this.toggleMultiUpdateModal}
          />
        </div>
      </JSONataProvider>
    );
  }

  onLookup = (name: string, value: string) => {
    const { query } = this.state;

    const subNodes =
      query.queryNode?.name === 'and' && !!query.queryNode?.subNodes.length
        ? (query.queryNode.subNodes as any[])
        : [query.queryNode];

    if (query && query?.limitNode && query?.limitNode?.offset) {
      query.limitNode.offset = 0;
    }

    this.setQuery(
      _.cloneDeep(query).setQuery(
        query.queryNode
          ? new And([new Eq(name, value), ...subNodes])
          : new Eq(name, value),
      ),
    );
  };

  toggleUserNote = () => {
    this.setState(({ isUserNoteShown }) => ({
      isUserNoteShown: !isUserNoteShown,
    }));
  };

  toggleSort = (name: string, currentDirection?: -1 | 1 | null) => {
    // there are only 3 options of sort:
    // desc(-1), asc(1) , not selected(null | undefined)
    // so on each srt toggle move sort to next state
    //        -1      1      null            -1      1
    // ... -> desc -> asc -> not selected -> desc -> asc ...
    // so if current sort is 1, just delete it
    const newSortOption =
      currentDirection === 1
        ? null // in case of other options, set
        : { [name]: currentDirection === -1 ? 1 : -1 };
    const sortOptions = this.state.query.sortNode
      ? this.state.query.sortNode.sortOptions
      : {};
    const newSortOptions = newSortOption
      ? { ...sortOptions, ...newSortOption }
      : _.pickBy(sortOptions, (_, key) => key !== name);
    let newQuery;
    if (Object.keys(newSortOptions).length === 0) {
      newQuery = _.cloneDeep(this.state.query);
      newQuery.sortNode = undefined;
    } else {
      newQuery = appendQuery(new Sort(newSortOptions), this.state.query);
    }
    this.setQuery(newQuery);
  };

  toggleLocalSort = (name: string) => {
    const options = this.state.localSortOptions;
    if (!options) {
      alert(
        "It's local sorting, it will sort values only loaded on this datastore page",
      );
    }

    let currentOption = {
      name,
      direction: 1,
    };
    if (!!options && options.name === currentOption!.name) {
      currentOption = {
        name,
        direction: options.direction === 1 ? -1 : 1,
      };
    }

    this.setState({
      localSortOptions: currentOption as any,
    });

    function compare(rowA: any, rowB: any, isParseFloat = false) {
      let a = rowA[name];
      let b = rowB[name];
      if (isParseFloat) {
        a = parseFloat(rowA[name]);
        b = parseFloat(rowB[name]);
      }

      if (a < b) {
        return currentOption.direction * -1;
      }
      if (a > b) {
        return currentOption.direction * 1;
      }
      return 0;
    }

    function isFloat(n: any) {
      const possibleNumber = parseFloat(n.match(/^-?\d*(\.\d+)?$/));

      return typeof possibleNumber === 'number' && !isNaN(possibleNumber);
    }

    const allFloats = this.state.dataWithId.every((val) => isFloat(val[name]));

    if (!allFloats) {
      alert('Not all values are numbers, will be sorted as strings');
    }

    const sortedData = this.state.data.sort((a, b) => compare(a, b, allFloats));

    this.setState({
      data: sortedData,
    });
  };

  changeSearchField = (name: string, value: string | null) => {
    const { queryNode } = this.state.query;
    let newQueryNode;
    if (queryNode instanceof AbstractLogicalNode) {
      newQueryNode = _.cloneDeep(queryNode);
      if (value === null) {
        if (queryNode.subNodes.length === 2) {
          newQueryNode = queryNode.subNodes.find(
            (node) => (node as AbstractScalarNode).field !== name,
          );
        } else {
          newQueryNode.removeNode(
            queryNode.subNodes.findIndex(
              (node) => (node as AbstractScalarNode).field === name,
            ),
          );
        }
      } else {
        newQueryNode.addNode(new Eq(name, castValueForSearch(value)));
      }
    } else {
      if (value === null) {
        newQueryNode = undefined;
      } else {
        newQueryNode =
          queryNode && queryNode.field !== name
            ? new And([queryNode, new Eq(name, castValueForSearch(value))])
            : new Eq(name, castValueForSearch(value));
      }
    }
    this.setQuery(setQueryNode(newQueryNode, this.state.query));
  };

  refreshCount = () => {
    this.setState(({ shouldUpdatePaginatorCount }) => ({
      shouldUpdatePaginatorCount: ++shouldUpdatePaginatorCount,
    }));
  };

  onUploadData: OnUploadData = (
    _data,
    callback,
    forceDisableMultiCreate = true,
  ) => {
    const { pasteUploaderParams } = this.props;

    const CHUNK_SIZE = 100;
    const REQUEST_CHUNK_SIZE = 25;
    const data = forceDisableMultiCreate ? _data : _.chunk(_data, CHUNK_SIZE);

    const tasks: Array<Task> = data.map((chunk) => ({
      data: chunk,
      handler: async (chunk) => {
        const items = Array.isArray(chunk) ? chunk : [chunk];
        if (chunk.length === 0) return Promise.resolve();
        const expressions = _.chunk(items, REQUEST_CHUNK_SIZE).map((chunk) => {
          const exprs = chunk
            .map((item) => {
              let entries = Object.entries(item);

              if (pasteUploaderParams?.fieldNamesToCompare) {
                entries = entries.filter(([key]) =>
                  pasteUploaderParams?.fieldNamesToCompare?.includes(key),
                );
              }
              console.log('entries', entries);

              const exprs = entries
                // filter fields that are not in fieldNames to prevent errors
                .filter(([key]) => this.actualFieldNames.includes(key))
                .map(([key, val]: any) => new Eq(key, val));
              console.log('expr', exprs);
              if (exprs.length === 0) {
                return null;
              }
              return exprs.length === 1 ? exprs[0] : new And(exprs);
            })
            .filter((expr) => !!expr) as Array<AbstractQueryNode>;
          if (exprs.length === 0) return null;
          console.log('expr after', exprs);
          return exprs.length === 1 ? exprs[0] : new Or(exprs);
        });
        await wait(2000);
        const res = await Promise.all(
          expressions.map((expr) => {
            if (!expr) return Promise.resolve([]);
            return this.datastore.query(new Query({ query: expr }));
          }),
        );
        const flattenRes = res.flat();

        const getObjectWithFieldsToBeCompared = (
          item: Record<string, any>,
          fields?: string[],
        ) => {
          return _.pickBy(item, (_, key) =>
            fields ? fields.includes(key) : key,
          );
        };

        const dataToCreate = items.filter((item: any) => {
          const itemToCreate = pasteUploaderParams?.fieldNamesToCompare
            ? getObjectWithFieldsToBeCompared(
                item,
                pasteUploaderParams.fieldNamesToCompare,
              )
            : item;

          return !flattenRes.find((b) => {
            const itemToBeCreated = pasteUploaderParams?.fieldNamesToCompare
              ? getObjectWithFieldsToBeCompared(
                  item,
                  pasteUploaderParams.fieldNamesToCompare,
                )
              : _.pickBy(b, (_, key) => key in item);

            return _.isEqual(itemToBeCreated, itemToCreate);
          });
        });

        const replacedDataToCreate = replaceEmptyFieldsWithNull(
          dataToCreate,
          pasteUploaderParams?.fieldNamesToReplaceWithNullIfEmpty || [],
        );

        const placedAfterDataToCreate = placeAfterField(
          replacedDataToCreate,
          pasteUploaderParams?.pasteAfterField || [],
        );

        console.log(
          'response',
          flattenRes,
          'datatocreate',
          dataToCreate,
          'placedAfterDataToCreate',
          placedAfterDataToCreate,
        );
        console.log('create chunk', items, dataToCreate, replacedDataToCreate);
        await wait(2000);

        const createFunc = pasteUploaderParams?.rewrite
          ? this.datastore.rewrite.bind(this.datastore)
          : this.datastore.create.bind(this.datastore);

        return placedAfterDataToCreate.length === 0
          ? Promise.resolve()
          : createFunc(placedAfterDataToCreate);
      },
    }));
    sendRequestsInChunks(
      tasks,
      forceDisableMultiCreate
        ? callback // multiply all by CHUNK_SIZE to display progress properly
        : (success, fail, totalAmount, failedItems) =>
            callback(
              clamp(success * CHUNK_SIZE, 0, _data.length),
              clamp(fail * CHUNK_SIZE, 0, _data.length),
              _data.length,
              (failedItems || []).flat(),
            ),
      forceDisableMultiCreate ? 5 : 1,
    )
      .then(() => this.refreshTable())
      .catch();
  };
}

const placeAfterField = (
  items: Record<string, any>[],
  fields: CopyPastePasteAfterField[],
) => {
  if (fields.length === 0) {
    return items;
  }

  const result = [];

  const getValueByAlias = (alias: CopyPastePasteAfterValues) => {
    if (alias === CopyPastePasteAfterValues.YearMonthDay) {
      return new Date().toISOString().slice(0, 10);
    }

    if (alias === CopyPastePasteAfterValues.IsoStringDate) {
      // without miliseconds
      return new Date().toISOString().split('.')[0];
    }

    return alias;
  };

  for (const { fieldName, value, delimiter } of fields) {
    for (const data of items) {
      if (fieldName) {
      }
      result.push({
        ...data,
        [fieldName]: `${data[fieldName]}${delimiter}${getValueByAlias(value)}`,
      });
    }
  }

  return result;
};

const replaceEmptyFieldsWithNull = (
  items: Record<string, any>[],
  fields: string[],
) => {
  return items.map((item) => {
    const replacedItem = { ...item };

    Object.keys(replacedItem).forEach((key) => {
      if (fields.includes(key) && typeof replacedItem[key] === 'string') {
        replacedItem[key] =
          replacedItem[key].length === 0 ? null : replacedItem[key];
      }
    });

    return replacedItem;
  });
};

export default TableWithFilter;
