import React, { FC } from 'react';
import {
  DataGrid,
  GridColumnResizeParams,
  GridColumns,
} from '@mui/x-data-grid';
import { Position, SortedPosition } from '../../api-clients/types';
import ApiClientsPositions from '../../api-clients/api-clients-position';
import _ from 'lodash';
import { logger } from '../../../../../utils/logger';

interface ItemsTableProps {
  positions: SortedPosition[];
  onRowSelect: (position: Position) => void;
  onPositionsUpdate: () => void;
  loading: boolean;
}

const columns: GridColumns = [
  {
    field: '№',
    renderCell: (index: any) => index.api.getRowIndex(index.row.id) + 1,
    headerAlign: 'left' as const,
    align: 'left' as const,
  },
  {
    field: 'article',
    headerName: 'Rollun Id',
    headerAlign: 'left' as const,
    align: 'left' as const,
  },
  {
    field: 'name',
    headerName: 'Name',
    editable: true,
    headerAlign: 'left' as const,
    align: 'left' as const,
  },
  {
    field: 'cost',
    headerName: 'Cost',
    type: 'number',
    editable: true,
    headerAlign: 'left' as const,
    align: 'left' as const,
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    type: 'number',
    editable: true,
    headerAlign: 'left' as const,
    align: 'left' as const,
  },
  {
    field: 'total',
    headerName: 'Total',
    type: 'number',
    headerAlign: 'left' as const,
    align: 'left' as const,
  },
];

const ItemsTable: FC<ItemsTableProps> = ({
  positions,
  onRowSelect,
  onPositionsUpdate,
  loading,
}) => {
  const handleEditCellChangeCommitted = React.useCallback(
    async (props: any) => {
      const apiPositions = new ApiClientsPositions();
      const position = props?.row
        ? { ...props.row }
        : await apiPositions.getById(props.id);
      position[props.field] = props.value;
      props.field === 'name'
        ? await apiPositions.updateWithOffer(position)
        : await apiPositions.update(position);
      onPositionsUpdate();
    },
    [],
  );

  const handleResize = _.debounce((params: GridColumnResizeParams) => {
    logger.info('resizedItemsTable', {});

    const {
      colDef: { field, computedWidth },
    } = params;

    const columnsConfig = JSON.parse(
      localStorage.getItem('CRMColumnsConfig') || '{}',
    );

    columnsConfig[field] = computedWidth;

    localStorage.setItem('CRMColumnsConfig', JSON.stringify(columnsConfig));
  }, 200);

  const getColumns = () => {
    const columnsConfig = JSON.parse(
      localStorage.getItem('CRMColumnsConfig') || '{}',
    );

    return columns.map((column) => {
      const columnConfig = columnsConfig[column.field];

      return {
        ...column,
        width: columnConfig || column.width,
        ...(!columnConfig && { flex: 1 }),
      };
    });
  };

  return (
    <DataGrid
      rows={positions}
      columns={getColumns()}
      onColumnResize={handleResize}
      onRowClick={(e) => onRowSelect(e.row as Position)}
      onCellEditCommit={handleEditCellChangeCommitted}
      loading={loading}
      autoHeight
      style={{
        height: '100%',
      }}
    />
  );
};

export default ItemsTable;
