import React from 'react';

export type Page =
  | 'returnLabels'
  | 'scanLabel'
  | 'scanItem'
  | 'foundLabel'
  | 'foundItem'
  | 'notFoundLabel'
  | 'setLocation'
  | 'notFoundItem';

interface ReturnAppContext {
  page: Page;
  lastAction: Page;
  setPage: (pageName: Page, locationInner?: string) => void;
  label: string;
  item: string;
  setLabel: (label: string, pageName: Page) => void;
  setItem: (item: string, pageName: Page) => void;
  goBack: (currentPage: Page) => void;
  location: string;
  setLocation: (location: string) => void;
  supplier: string;
  setSupplier: (supplier?: string) => void;
}

const ReturnAppContext = React.createContext<ReturnAppContext>({
  page: 'returnLabels',
  setPage: () => {
    return;
  },
  label: '',
  item: '',
  setLabel: () => {
    return;
  },
  setItem: () => {
    return;
  },
  lastAction: 'returnLabels',
  goBack: () => {
    return;
  },
  location: '',
  setLocation: () => {
    return;
  },
  supplier: '',
  setSupplier: () => {
    return;
  },
});

export default ReturnAppContext;
