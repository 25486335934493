import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { ErrorView } from '../../../../../UI';
import ItemsCards from './ItemsCards';
import { useGetItemsData } from '../hooks/useGetItemsData';
import { Position } from '../../api-clients/types';

interface FullProductsViewProps {
  products: Position[];
}

const FullProductsView: FC<FullProductsViewProps> = ({ products }) => {
  const { isLoading, error, data } = useGetItemsData(products);

  return (
    <Box>
      {isLoading ? (
        <Box>Loading...</Box>
      ) : error ? (
        <ErrorView error={{ text: JSON.stringify(error), code: 500 }} />
      ) : (
        <ItemsCards data={data} />
      )}
    </Box>
  );
};

export default FullProductsView;
