import React, { useContext, FC, useState, useEffect } from 'react';
import GenericHowToBuy from '../Generic/GenericHowToBuy';
import { Spinner } from '../../../../../../../UI/index';
import { DealContext } from '../../../../GenericDeal/context/dealContext';
import { HowToBuyOptionsResult } from '../HowToBuy4/utils/getHowToBuyOptions';
import { fetchNewLinkedDealsData } from '../HowToBuy4/utils/fetchCreateLinkedDealsDataV2';
import { Order } from '../../../../api-clients/types';

let createLinkedDealsV2Cache: HowToBuyOptionsResult | undefined = undefined;

const CreateLinkedDealsV2: FC = () => {
  const dealContext = useContext(DealContext);
  const deal = dealContext?.deal as Order | undefined;

  const [howToBuyOptions, setHowToBuyOptions] = useState<
    HowToBuyOptionsResult | undefined
  >();

  useEffect(() => {
    if (createLinkedDealsV2Cache) {
      setHowToBuyOptions(createLinkedDealsV2Cache);
      return;
    }

    (async () => {
      if (deal) {
        const howToBuyOptions = await fetchNewLinkedDealsData(
          deal.no_special_items_positions,
        );

        setHowToBuyOptions(howToBuyOptions);
        createLinkedDealsV2Cache = howToBuyOptions;
        console.log(howToBuyOptions);
      }
    })();
  }, [deal]);

  if (!deal || !howToBuyOptions) {
    return <Spinner />;
  }

  return (
    <GenericHowToBuy
      howToBuyOptions={howToBuyOptions}
      version="CreateLinkedDeals"
    />
  );
};

export default CreateLinkedDealsV2;
