import React, { FC } from 'react';
import PasteUploaderOptionEdit from './PasteUploaderOption.edit';
import { CopyPasteUploaderParams } from '../../../../../Table/components/CopyPasteUploaders/CopyPasteUploader';

interface PasteUploaderOptionsEditProps {
  pasteUploaderParams: CopyPasteUploaderParams;
  onUpdate: (value: any, path: string) => void;
}

const PasteUploaderOptionsEdit: FC<PasteUploaderOptionsEditProps> = ({
  pasteUploaderParams,
  onUpdate,
}) => {
  return (
    <>
      <PasteUploaderOptionEdit
        name="noId"
        value={pasteUploaderParams.noId}
        onUpdate={onUpdate}
      />
      <PasteUploaderOptionEdit
        name="rewrite"
        value={pasteUploaderParams.rewrite}
        onUpdate={onUpdate}
      />
    </>
  );
};

export default PasteUploaderOptionsEdit;
