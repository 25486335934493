import React, { Component } from 'react';
import { ErrorType } from '../../../../utils/common.types';
import HttpDatastore from 'rollun-ts-datastore';
import { ResourceRow, RouteRow, RuleRow } from '../../utils/types';
import { httpErrorHandler } from '../../../../utils/common.utils';
import { Button, ErrorView, Spinner } from '../../../../UI';
import Select from 'react-select';
import { Query, Eq } from 'rollun-ts-rql';
import { FrontConfigRow } from '../../../RootApp/Root.app';
import RouteView from './RouteView';
import ResourceView from './ResourceView';
import RulesView from './RulesView';
import ConfigView from './ConfigView';

interface IState {
  isLoading: boolean;
  progress: any;
  error: ErrorType | null;
  routes: Array<RouteRow>;
  selectedRoute: RouteRow | null | undefined;
  selectedResource: ResourceRow | null | undefined;
  selectedRules: RuleRow[] | null | undefined;
  selectedConfig: FrontConfigRow | null | undefined;
}

interface IProps {
  resource?: string;
}

// TODO add error handling

class DeleteService extends Component<IProps, IState> {
  RouteDataStore = new HttpDatastore<RouteRow>('/api/datastore/RouteTable');
  ResourceDataStore = new HttpDatastore<ResourceRow>(
    '/api/datastore/resourceDataStore',
  );
  RuleDataStore = new HttpDatastore<RuleRow>('/api/datastore/ruleDataStore');
  FrontConfigDataStore = new HttpDatastore<FrontConfigRow>(
    '/api/datastore/frontConfigDataStore',
  );

  state: IState = {
    isLoading: false,
    progress: null,
    error: null,
    routes: [],
    selectedRoute: null,
    selectedResource: null,
    selectedRules: null,
    selectedConfig: null,
  };

  componentDidMount() {
    this.fetchRoutesConfig().then((route) => {
      route && this.fetchServiceData(route);
    });
  }

  fetchRoutesConfig = async () => {
    this.setState({ isLoading: true, error: null });
    try {
      const res = await this.RouteDataStore.query();
      this.setState({ routes: res, isLoading: false });
      return res.find((route) => route.name === this.props.resource);
    } catch (err) {
      httpErrorHandler(err, (code, text) =>
        this.setState({ error: { code, text }, isLoading: false }),
      );
    }
  };

  fetchServiceData = async (selectedRoute?: RouteRow) => {
    if (!selectedRoute) return;
    this.setState({ selectedRoute: selectedRoute });
    const resource = await this.ResourceDataStore.query(
      new Query({
        query: new Eq('name', selectedRoute.name),
      }),
    );
    this.setState({ selectedResource: resource[0] });
    const rules = await this.RuleDataStore.query(
      new Query({
        query: new Eq('resource_id', resource[0].id),
      }),
    );
    this.setState({ selectedRules: rules });
    const config = await this.FrontConfigDataStore.query(
      new Query({
        query: new Eq('resource', resource[0].name),
      }),
    );
    this.setState({ selectedConfig: config[0] });
  };

  deleteAllServiceInfo = async () => {
    this.setState({ isLoading: true });
    const {
      selectedRoute,
      selectedConfig,
      selectedResource,
      selectedRules,
    } = this.state;
    if (selectedRoute) {
      await this.RouteDataStore.delete(selectedRoute.id || '');
      this.setState({ selectedRoute: null });
    }
    if (selectedRules) {
      for (const rule of selectedRules) {
        await this.RuleDataStore.delete(rule.id || '');
      }
      this.setState({ selectedRules: null });
    }
    if (selectedResource) {
      await this.ResourceDataStore.delete(selectedResource.id || '');
      this.setState({ selectedResource: null });
    }
    if (selectedConfig) {
      await this.FrontConfigDataStore.delete(selectedConfig.id || '');
    }
    this.fetchRoutesConfig();
    this.setState({ selectedConfig: null, isLoading: false });
  };

  onRouteSelect = (value: any) => {
    const selectedRoute = this.state.routes.find(
      (route) => route.id === value.value,
    );
    this.setState({
      selectedRoute,
      selectedRules: null,
      selectedResource: null,
      selectedConfig: null,
    });
    this.setState({ isLoading: true });
    this.fetchServiceData(selectedRoute)
      .catch((err) => console.error(err))
      .finally(() => this.setState({ isLoading: false }));
  };

  render() {
    const { isLoading, error, selectedRoute, routes } = this.state;

    if (error) {
      return (
        <div>
          <ErrorView error={error}>
            <Button color="primary" onClick={this.fetchRoutesConfig}>
              Retry
            </Button>
          </ErrorView>
        </div>
      );
    }
    const defaultOption = { value: '', label: 'Select Route' };
    return (
      <div>
        <Select
          value={
            selectedRoute
              ? { value: selectedRoute.id, label: selectedRoute.path }
              : defaultOption
          }
          defaultValue={defaultOption}
          className="col-md-6 mb-2"
          options={routes.map((route) => ({
            value: route.id,
            label: route.path,
          }))}
          onChange={this.onRouteSelect}
        />
        {this.state.selectedRoute && (
          <RouteView route={this.state.selectedRoute} />
        )}
        {this.state.selectedResource && (
          <ResourceView resource={this.state.selectedResource} />
        )}
        {this.state.selectedRules && (
          <RulesView rules={this.state.selectedRules} />
        )}
        {this.state.selectedConfig && (
          <ConfigView config={this.state.selectedConfig} />
        )}
        {isLoading && (
          <div className="m-5">
            <Spinner />
          </div>
        )}
        {selectedRoute && (
          <Button
            block
            disabled={isLoading}
            className="my-2"
            onClick={() => this.deleteAllServiceInfo()}
            color="danger"
          >
            Delete all
          </Button>
        )}
      </div>
    );
  }
}

export default DeleteService;
