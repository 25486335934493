import React, { FC, useContext } from 'react';
import { DealContext } from '../context/dealContext';
import { Deal } from '../../api-clients/types';
import { Box, MenuItem, Select } from '@material-ui/core';
import { httpErrorHandlerPromised } from '../../../../../utils/common.utils';
import { useStatusRules } from '../hooks/useStatusRules';
import _ from 'lodash';

interface StatusSelectorProps {
  availableStatuses: {
    id: string;
    name: string;
    color?: string;
  }[];
}

export const StatusSelector: FC<StatusSelectorProps> = ({
  availableStatuses,
}) => {
  const dealContext = useContext(DealContext);
  const deal = dealContext?.deal as Deal;
  const [currentStatus, setCurrentStatus] = React.useState(deal.status);
  const { isFetching: areRulesFetching, data: rules } = useStatusRules();
  const [loading, setLoading] = React.useState(false);

  const getValidStatuses = () => {
    if (!rules) {
      return [];
    }

    const currentSchema = `${_.upperFirst(dealContext?.type)}s`;
    const currentStatusName = availableStatuses.find(
      (status) => status.id === currentStatus,
    )?.name as string;

    // yes, this is a cringe stuff from managers.
    // never listen to them, they are always wrong :)))
    const actualSchema =
      currentSchema === 'ReturnBags'
        ? 'Return_bag'
        : currentSchema === 'ReturnDropships'
        ? 'Return_dropship'
        : currentSchema === 'ReturnPickups'
        ? 'Return_pickup'
        : currentSchema === 'Fbas'
        ? 'Fba'
        : currentSchema;

    if (!(actualSchema in rules && currentStatusName in rules[actualSchema])) {
      return availableStatuses;
    }

    const validRules = rules[actualSchema][currentStatusName]
      .map((rule) => {
        const fullStatus = availableStatuses.find(
          (status) => status.name === rule,
        );
        return fullStatus;
      })
      .filter(Boolean)
      .concat(
        availableStatuses.find((status) => status.name === currentStatusName),
      );

    return validRules as {
      id: string;
      name: string;
      color: string;
    }[];
  };

  const handleStatusChange = async (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    try {
      setLoading(true);
      await dealContext?.onDealUpdate<Deal>(deal.id, {
        status: event.target.value as string,
      });
      setCurrentStatus(event.target.value as string);
    } catch (e) {
      alert((await httpErrorHandlerPromised(e)).text);
    } finally {
      setLoading(false);
    }
  };

  const getSelectColor = () => {
    const status = availableStatuses.find(
      (status) => status.id === currentStatus,
    );
    return status?.color;
  };

  // TODO: figure out what statuses are available for problem deal
  if (availableStatuses.length === 0) {
    return (
      <Box fontSize={16}>{`Status: ${deal.statusName || deal.status}`}</Box>
    );
  }

  return (
    <Select
      disabled={loading || areRulesFetching}
      value={currentStatus}
      onChange={handleStatusChange}
      style={{
        width: 200,
        backgroundColor: getSelectColor(),
        minWidth: 200,
      }}
    >
      {getValidStatuses().map((status) => (
        <MenuItem
          value={status.id}
          key={status.id}
          style={{
            backgroundColor: status.color,
          }}
        >
          {status.name}
        </MenuItem>
      ))}
    </Select>
  );
};
