export interface GenericDealDTO {
  Id: string;
  Tracknumber: string | null;
  ProblemDescription: string | null;
  SrName: string;
  MpName: string;
  TimeCreated: string;
  TimeUpdated: string;
  SrOrderNumber: number | null;
  SrShipMethod: string | null;
  SrOrderId: string | null;
  Carrier: string | null;
  Sender: string;
  Status: string | null;
  Contractor: string;
}

export interface GenericDeal {
  id: string;
  tracknumber: string | null;
  problemDescription: string | null;
  srName: string;
  mpName: string;
  timeCreated: string;
  timeUpdated: string;
  srOrderNumber: number | null;
  srShipMethod: string | null;
  srOrderId: string | null;
  carrier: string | null;
  sender: string;
  status: string | null;
}

export interface OfferDTO {
  Id: string;
  Article: string;
  Name: string;
  ParentId: string | null;
}

export interface OfferData {
  article: string;
  name: string;
}
export interface Offer extends OfferData {
  id: string;
  parentId: string | null;
}

export interface PositionDTO {
  Id: string;
  DealId: string;
  OfferId: string;
  Count: number;
  Cost: string;
}

export interface Position extends OfferData {
  id: string;
  dealId: string;
  offerId: string;
  quantity: number;
  cost: string;
}

export interface SortedPosition extends Position {
  total: string;
}

export interface BagDTO extends GenericDealDTO {
  BagStatus: string;
  ProblemDescription: string | null;
  MpName: string;
  SrPaymentCard: string;
  SrName: string;
  Type: string | null;
  SrOrderId: string | null;
  Sender: string;
  Tracknumber: string | null;
  Carrier: string | null;
  ShipStatus: string | null;
  RetBag: string | null;
  ArchiveScenario: string | null;
  DateDealCreation: string | null;
  SrShipMethod: string | null;
  TimeCreated: string;
  TimeUpdated: string;
  Name: string | null;
  GUID: string | null;
  Owner: string | null;
  FinalPrice: string | null;
  SrOrderNumber: number;
  SrItemsPrice: string | null;
  Status: string | null;
  ProgramId: string;
  SrReturnNumber: string;
  StatusName: string | null;
}

export interface Bag extends GenericDeal {
  bagStatus: string;
  problemDescription: string | null;
  mpName: string;
  srPaymentCard: string;
  srName: string;
  type: string | null;
  srOrderId: string | null;
  sender: string;
  tracknumber: string | null;
  carrier: string | null;
  shipStatus: string | null;
  retBag: string | null;
  archiveScenario: string | null;
  srShipMethod: string | null;
  timeCreated: string;
  timeUpdated: string;
  finalPrice: string | null;
  srOrderNumber: number;
  srItemsPrice: string | null;
  positions: Position[];
  no_special_items_positions: Position[];
  status: string | null;
  statusName: string | null;
  srReturnNumber: string;
}

// TODO: add all types for pickups, when datastore will be available
export interface PickupDTO extends GenericDealDTO {
  Id: string;
  BagLink: string;
  Tracknumber: string;
  SrName: string;
  MpName: string;
  MpOrderNumber: string;
  TimeCreated: string;
  TimeUpdated: string;
  SrShipMethod: string;
  SrOrderId: string | null;
  Carrier: string;
  Sender: string;
  Status: string;
  StatusName: string | null;
  Owner: number;
  EnterTracknumber: string;
  Weight: string;
  Dimensions: string;
  Total: string;
  ArchiveScenario: string;
  WasPacked: string;
  DateRofReservation: string;
  DateRofSubtraction: string;
  DateEnteringTracknumberIntoMp: string;
  RelatedOrder: string | null;
  ShipStatus: string | null;
  LabelProvider: string | null;
}

export interface Pickup extends GenericDeal {
  id: string;
  bagLink: string;
  tracknumber: string;
  srName: string;
  mpName: string;
  mpOrderNumber: string;
  timeCreated: string;
  timeUpdated: string;
  srShipMethod: string;
  srOrderId: string | null;
  carrier: string;
  sender: string;
  status: string;
  shipStatus: string | null;
  owner: number;
  enterTracknumber: string;
  positions: Position[];
  no_special_items_positions: Position[];
  statusName: string | null;
  contractor: string;
  weight: string;
  dimensions: string;
  total: string;
  archiveScenario: string;
  wasPacked: string;
  dateRofReservation: string;
  dateRofSubtraction: string;
  dateEnteringTracknumberIntoMp: string;
  client: Client;
  relatedOrder: string | null;
  labelProvider: string | null;
}

export interface OrderDTO extends GenericDealDTO {
  Id: string;
  Name: string;
  IsDraft: string;
  IsPaid: string;
  MpName: string;
  MpClientId: string;
  MpOrderNumber: string;
  MpShipTemplate: string;
  MpOrderNote: string;
  Problem: string;
  TracknumbersInMp: string;
  HowToBuy3: string;
  HowToBuy3Result: string;
  HowToBuy3BestShipping: string;
  HowToBuy4: string;
  HowToBuy4Result: string;
  HowToBuy4BestShipping: string;
  DateCrPayed: string | null;
  DateShipBy: string | null;
  DateDeliverBy: string | null;
  ProblemDescription: string;
  TimeCreated: string;
  TimeUpdated: string;
  Contractor: string;
  MpShipMethod: string;
  Owner: string;
  FinalPrice: string;
  Cogs: string;
  Profit: string;
  GUID: string;
  MpUaId: string;
  Status: string;
  Tracknumber: string;
  ArchiveScenario: string;
  Description: string;
  StatusName: string | null;
  Sender: string;
}

export interface Order {
  id: string;
  name: string;
  isDraft: string;
  isPaid: string;
  mpName: string;
  mpClientId: string;
  mpOrderNumber: string;
  mpShipTemplate: string;
  mpOrderNote: string;
  problem: string;
  tracknumbersInMp: string;
  howToBuy3: string;
  howToBuy3Result: string;
  howToBuy3BestShipping: string;
  howToBuy4: string;
  howToBuy4Result: string;
  howToBuy4BestShipping: string;
  dateCrPayed: string | null;
  dateShipBy: string | null;
  dateDeliverBy: string | null;
  problemDescription: string;
  timeCreated: string;
  timeUpdated: string;
  contractor: string;
  mpShipMethod: string;
  owner: string;
  finalPrice: string;
  cogs: string;
  profit: string;
  status: string;
  tracknumber: string;
  archiveScenario: string;
  description: string;
  client: Client;
  sender: string;
  positions: Position[];
  no_special_items_positions: Position[];
  statusName: string | null;
}

export interface ClientDTO {
  Id: string;
  Name: string | null;
  Address1: string;
  Address2: string | null;
  City: string;
  State: string;
  PostalCode: string;
  Icq: string | null;
  GUID: string | null;
  Site: string | null;
  Email: string | null;
  Skype: string | null;
  Gender: string | null;
  Jabber: string | null;
  Phone: string | null;
  Twitter: string | null;
  Birthday: string | null;
  Facebook: string | null;
  LastName: string | null;
  FirstName: string;
  MiddleName: string | null;
  PersonType: string | null;
  Description: string | null;
  TimeCreated: string;
  TimeUpdated: string;
  ChildContacts: string | null;
  PromisingRate: number | null;
  PreferTransport: string | null;
}

export interface Client {
  id: string;
  name: string | null;
  address1: string;
  address2: string | null;
  city: string;
  state: string;
  postalCode: string;
  icq: string | null;
  GUID: string | null;
  site: string | null;
  email: string | null;
  skype: string | null;
  gender: string | null;
  jabber: string | null;
  phone: string | null;
  twitter: string | null;
  birthday: string | null;
  facebook: string | null;
  lastName: string | null;
  firstName: string;
  middleName: string | null;
  personType: string | null;
  description: string | null;
  timeCreated: string;
  timeUpdated: string;
  childContacts: string | null;
  promisingRate: number | null;
  preferTransport: string | null;
}

export interface DropshipDTO extends Omit<GenericDealDTO, 'SrOrderNumber'> {
  Id: string;
  Name: string;
  Status: string;
  TimeCreated: string;
  TimeUpdated: string;
  MpName: string;
  MpOrderNumber: string;
  SrShipMethod: string;
  SrPaymentCard: string;
  SrOrderNumber: string;
  SrOrderStatus: string;
  RetBag: string;
  ArchiveScenario: string;
  SrVirtualName: string;
  Total: string;
  DateEnteringTracknumberIntoMp: string;
  ShipStatus: string;
  AdditionalTracknumbers: string;
  DateLabelCreation: string;
  SrName: string;
  EnterTracknumber: string;
  Sender: string;
  Carrier: string;
  Owner: number;
  StatusName: string | null;
  SrReturnNumber: string;
  RelatedOrder: string | null;
}
export interface Dropship {
  id: string;
  name: string;
  status: string;
  timeCreated: string;
  timeUpdated: string;
  mpName: string;
  mpOrderNumber: string;
  srShipMethod: string;
  srName: string;
  enterTracknumber: string;
  tracknumber: string | null;
  sender: string;
  owner: number;
  carrier: string;
  srPaymentCard: string;
  srOrderStatus: string;
  srOrderNumber: string;
  retBag: string;
  archiveScenario: string;
  srVirtualName: string;
  total: string;
  dateEnteringTracknumberIntoMp: string;
  shipStatus: string;
  additionalTracknumbers: string;
  dateLabelCreation: string;
  positions: Position[];
  client: Client;
  no_special_items_positions: Position[];
  statusName: string | null;
  contractor: string;
  srReturnNumber: string;
  relatedOrder: string | null;
  problemDescription: string | null;
}

export type Deal =
  | Bag
  | Order
  | Pickup
  | Dropship
  | ReturnOrder
  | ReturnPickup
  | ReturnBag
  | ReturnDropship
  | Problem
  | Fba
  | Employees;

export type DealDTO =
  | BagDTO
  | OrderDTO
  | PickupDTO
  | DropshipDTO
  | ReturnOrderDTO
  | ReturnPickupDTO
  | ReturnBagDTO
  | ReturnDropshipDTO
  | ProblemDTO
  | FbaDTO
  | EmployeesDTO;
// TODO: create api clients
export const SPECIAL_ITEMS_IDS = ['11111', '11112', '11114', '11115', '11116'];

export interface ReturnOrderDTO {
  Id: string;
  GUID: string | null;
  Name: string | null;
  Contractor: string;
  TimeCreated: string;
  TimeUpdated: string;
  Owner: number | null;
  IsDraft: number | null;
  IsPaid: number | null;
  FinalPrice: number | null;
  ProgramId: number;
  MpName: string | null;
  MpOrderNumber: string | null;
  MpReturnNumber: string | null;
  MpDateOpened: string | null;
  MpRetReason: string | null;
  DealStatus: string | null;
  ReturnLocation: string | null;
  LabelCreator: string | null;
  Tracknumber: string | null;
  Carrier: string | null;
  Notes: string | null;
  ReturnCondition: string | null;
  ProblemDescription: string | null;
  SrName: string | null;
  MpUaId: string | null;
  Manager: number | null;
  Status: number;
  StatusName: string;
  ProgramName: string;
}

export interface ReturnOrder {
  id: string;
  GUID: string | null;
  name: string | null;
  contractor: string;
  timeCreated: string;
  timeUpdated: string;
  owner: number | null;
  isDraft: number | null;
  isPaid: number | null;
  finalPrice: number | null;
  programId: number;
  mpName: string | null;
  mpOrderNumber: string | null;
  mpReturnNumber: string | null;
  mpDateOpened: string | null;
  mpRetReason: string | null;
  dealStatus: string | null;
  returnLocation: string | null;
  labelCreator: string | null;
  tracknumber: string | null;
  carrier: string | null;
  notes: string | null;
  returnCondition: string | null;
  problemDescription: string | null;
  srName: string | null;
  mpUaId: string | null;
  manager: number | null;
  status: number;
  statusName: string;
  programName: string;
  positions: Position[];
  no_special_items_positions: Position[];
  client: Client | null;
}

export interface ReturnBagDTO {
  Id: string;
  ArchiveScenario: string | null;
  GUID: string | null;
  Name: string | null;
  Contractor: string;
  TimeCreated: string;
  TimeUpdated: string;
  Owner: number | null;
  IsDraft: number | null;
  IsPaid: number | null;
  FinalPrice: number | null;
  ParselInfo: string | null;
  MpName: string | null;
  ProgramId: number;
  Storage: string | null;
  SrName: string | null;
  Tracknumber: string | null;
  Carrier: string | null;
  SrReturnNumber: string | null;
  ProblemDescription: string | null;
  BagStatus: string | null;
  InfoLink: string | null;
  Manager: number | null;
  MpUaId: number | null;
  ProgramName: string;
  Status: number;
  StatusName: string;
}

export interface ReturnBag {
  id: string;
  archiveScenario: string | null;
  GUID: string | null;
  name: string | null;
  contractor: string;
  timeCreated: string;
  timeUpdated: string;
  owner: number | null;
  isDraft: number | null;
  isPaid: number | null;
  finalPrice: number | null;
  parselInfo: string | null;
  mpName: string | null;
  programId: number;
  storage: string | null;
  srName: string | null;
  tracknumber: string | null;
  carrier: string | null;
  srReturnNumber: string | null;
  problemDescription: string | null;
  bagStatus: string | null;
  infoLink: string | null;
  manager: number | null;
  mpUaId: number | null;
  programName: string;
  status: number;
  statusName: string;
  positions: Position[];
  no_special_items_positions: Position[];
}

export interface ReturnPickupDTO {
  Id: string;
  GUID: string | null;
  Name: string | null;
  Contractor: string;
  TimeCreated: string;
  TimeUpdated: string;
  Owner: number | null;
  IsDraft: number | null;
  IsPaid: number | null;
  FinalPrice: number | null;
  ProgramId: number;
  MpName: string | null;
  MpOrderNumber: string | null;
  MpReturnNumber: string | null;
  RelatedReturn: string | null;
  MpRetReason: string | null;
  DealStatus: string | null;
  ReturnLocation: string | null;
  LabelCreator: string | null;
  Tracknumber: string | null;
  Carrier: string | null;
  ShipStatus: string | null;
  ReturnCondition: string | null;
  ItemCondition: string | null;
  ProblemDescription: string | null;
  SrName: string | null;
  MpUaId: string | null;
  Manager: number | null;
  Status: number;
  StatusName: string;
  ProgramName: string;
}

export interface ReturnPickup {
  id: string;
  GUID: string | null;
  name: string | null;
  contractor: string;
  timeCreated: string;
  timeUpdated: string;
  owner: number | null;
  isDraft: number | null;
  isPaid: number | null;
  finalPrice: number | null;
  programId: number;
  mpName: string | null;
  mpOrderNumber: string | null;
  mpReturnNumber: string | null;
  mpRetReason: string | null;
  dealStatus: string | null;
  returnLocation: string | null;
  labelCreator: string | null;
  tracknumber: string | null;
  carrier: string | null;
  shipStatus: string | null;
  returnCondition: string | null;
  itemCondition: string | null;
  problemDescription: string | null;
  srName: string | null;
  mpUaId: string | null;
  manager: number | null;
  status: number;
  statusName: string;
  programName: string;
  relatedReturn: string | null;
  positions: Position[];
  no_special_items_positions: Position[];
  client: Client;
}

export interface ReturnDropshipDTO {
  Id: string;
  GUID: string | null;
  Name: string | null;
  Contractor: string;
  TimeCreated: string;
  TimeUpdated: string;
  Owner: number | null;
  IsDraft: number | null;
  IsPaid: number | null;
  FinalPrice: number | null;
  ProgramId: number;
  MpName: string | null;
  MpOrderNumber: string | null;
  MpReturnNumber: string | null;
  SrName: string | null;
  SrReturnNumber: string | null;
  MpRetReason: string | null;
  DealStatus: string | null;
  ReturnLocation: string | null;
  RelatedReturn: string | null;
  LabelCreator: string | null;
  Tracknumber: string | null;
  Carrier: string | null;
  ShipStatus: string | null;
  ReturnCondition: string | null;
  SrRefundedSum: string | null;
  ProblemDescription: string | null;
  MpUaId: string | null;
  Manager: number | null;
  Status: number | null;
  StatusName: string | null;
  ProgramName: string;
}

export interface ReturnDropship {
  id: string;
  GUID: string | null;
  name: string | null;
  contractor: string;
  timeCreated: string;
  timeUpdated: string;
  owner: number | null;
  isDraft: number | null;
  isPaid: number | null;
  finalPrice: number | null;
  programId: number;
  mpName: string | null;
  mpOrderNumber: string | null;
  mpReturnNumber: string | null;
  srName: string | null;
  srReturnNumber: string | null;
  mpRetReason: string | null;
  dealStatus: string | null;
  returnLocation: string | null;
  labelCreator: string | null;
  tracknumber: string | null;
  carrier: string | null;
  shipStatus: string | null;
  returnCondition: string | null;
  srRefundedSum: string | null;
  problemDescription: string | null;
  mpUaId: string | null;
  manager: number | null;
  status: number | null;
  statusName: string | null;
  programName: string;
  relatedReturn: string | null;
  positions: Position[];
  no_special_items_positions: Position[];
  client: Client;
}

export interface ReturnDeal {
  returnOrder: ReturnOrder;
  order: Order | Fba;
  positions: Position[];
}

export interface ProblemDTO {
  Id: string;
  GUID: string;
  Name: string;
  IsDraft: string;
  IsPaid: string;
  TimeCreated: string;
  TimeUpdated: string;
  Owner: string;
  Total: string;
  MpName: string;
  Reason: any[];
  UserId: string;
  History: string;
  Manager: string;
  Auditors?: any;
  ArchiveScenario: string | null;
  Solution: any[];
  Supplier: string;
  WeMustDo: string;
  '4wemustdo': string;
  WeMustDo1: string;
  WeMustDo2: string;
  WeWaitFor: string;
  '4wewaitfor': string;
  Contractor?: any;
  FinalPrice: string;
  WeWaitFor1: string;
  WeWaitFor2: string;
  WeWaitFrom: string;
  '4wewaitfrom': string;
  Description: string;
  Ordernumber: string;
  Tracknumber: string;
  WeWaitFrom1: string;
  Marketplace1: string;
  MpOrderNumber: string;
  SrOrderNumber: string;
  DateLastAction: string;
  Rmreturnnumber: string;
  SrReturnNumber: string;
  SupplierRefundToUs: string;
  TracknumberCarrier: string;
  OrderLinkInMegaplan: string;
  Dateofthelastactions: string;
  SourceOfCommunication: string;
  SrOriginalOrderNumber: string;
  Rmreplacementreturnnumber: string;
  Status: string;
  StatusName: string;
  RelatedDeal?: string | null;
}

export interface Problem {
  id: string;
  name: string;
  isDraft: string;
  isPaid: string;
  timeCreated: string;
  timeUpdated: string;
  owner: string;
  total: string;
  mpName: string;
  reason: any[];
  userId: string;
  history: string;
  manager: string;
  auditors?: any;
  solution: any[];
  supplier: string;
  weMustDo: string;
  '4wemustdo': string;
  weMustDo1: string;
  weMustDo2: string;
  weWaitFor: string;
  '4wewaitfor': string;
  contractor?: any;
  finalPrice: string;
  weWaitFor1: string;
  weWaitFor2: string;
  weWaitFrom: string;
  '4wewaitfrom': string;
  positions: Position[];
  description: string;
  ordernumber: string;
  tracknumber: string;
  weWaitFrom1: string;
  marketplace1: string;
  mpOrderNumber: string;
  srOrderNumber: string;
  dateLastAction: string;
  rmreturnnumber: string;
  srReturnNumber: string;
  supplierRefundToUs: string;
  tracknumberCarrier: string;
  orderLinkInMegaplan: string;
  dateofthelastactions: string;
  sourceOfCommunication: string;
  srOriginalOrderNumber: string;
  rmreplacementreturnnumber: string;
  status: string;
  statusName: string;
  relatedDeal?: string | null;
  client: Client;
  archiveScenario: string | null;
}

export interface Fba {
  id: string;
  dataZakaza: string | null;
  activeWorkflow: string | null;
  shipmentId: string | null;
  trekNomer: string | null;
  edd: string | null;
  prepTsentrPts: string | null;
  prepCenterWorkOrderId: string | null;
  dataPostupleniyaVPrepTsentr: string | null;
  primechaniePts: string | null;
  nomerSdelkiVDelovode: string | null;
  dataOtgruzki: string | null;
  srOrderNumber: string | null;
  srName: string | null;
  type: string | null;
  srPaymentCard: string | null;
  mpUaId: string | null;
  status: string;
  statusName: string;
  timeCreated: string;
  timeUpdated: string;
  client: Client;
  positions: Position[];
  no_special_items_positions: Position[];
}

export interface FbaDTO {
  Id: string;
  DataZakaza: string | null;
  ActiveWorkflow: string | null;
  ShipmentId: string | null;
  TrekNomer: string | null;
  Edd: string | null;
  PrepTsentrPts: string | null;
  PrepCenterWorkOrderId: string | null;
  DataPostupleniyaVPrepTsentr: string | null;
  PrimechaniePts: string | null;
  NomerSdelkiVDelovode: string | null;
  DataOtgruzki: string | null;
  SrOrderNumber: string | null;
  SrName: string | null;
  Type: string | null;
  SrPaymentCard: string | null;
  MpUaId: string | null;
  Status: string;
  StatusName: string;
  TimeCreated: string;
  TimeUpdated: string;
}

export interface Employees {
  id: string;
  typeOfEmployment: string | null;
  team: string | null;
  name: string | null;
  position: string | null;
  fileWithPasswords: string | null;
  fileSalary: string | null;
  passportDrLicense: string | null;
  identificationNumber: string | null;
  W8W9Form: string | null;
  W8W9Form2021: string | null;
  W8W9Form2022: string | null;
  W8W9Form2023: string | null;
  kontaktiBlizkih: string | null;
  mpUaId: string | null;
  date1stDayAtWork: string | null;
  slackName: string | null;
  email: string | null;
  status: string;
  statusName: string;
  timeCreated: string;
  timeUpdated: string;
}

export interface EmployeesDTO {
  Id: string;
  TypeOfEmployment: string | null;
  Team: string | null;
  Name: string | null;
  Position: string | null;
  FileWithPasswords: string | null;
  FileSalary: string | null;
  PassportDrLicense: string | null;
  IdentificationNumber: string | null;
  W8W9Form: string | null;
  W8W9Form2021: string | null;
  W8W9Form2022: string | null;
  W8W9Form2023: string | null;
  KontaktiBlizkih: string | null;
  MpUaId: string | null;
  Date1stDayAtWork: string | null;
  SlackName: string | null;
  Email: string | null;
  Status: string;
  StatusName: string;
  TimeCreated: string;
  TimeUpdated: string;
}

export type GetByQueryOptions = {
  excludePositions?: boolean;
};
