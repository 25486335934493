import { Pickup } from '../../api-clients/types';
import ApiClientsPickups from '../../api-clients/api-clients-pickups';

export const useMutateDeal = () => {
  const handleUpdateDeal = async (
    id: string,
    data: Partial<Omit<Pickup, 'id' | 'positions'>>,
  ) => {
    await new ApiClientsPickups().update(id, data);
  };

  return {
    handleUpdateDeal,
  };
};
