import { Box, Typography } from '@material-ui/core';
import { ActivityLog } from '../../hooks/useGetActivityLogs';
import React, { FC } from 'react';
import { ActionComponent } from './ActionComponent';
import { ActivityValue } from './ActivityValue';
import { ActorComponent } from './ActorComponent';

export const ActivityLogComponent: FC<{
  log: ActivityLog;
}> = ({ log }) => {
  const changedFields = Object.keys(
    Array.isArray(log.event?.entity?.changedFields)
      ? {}
      : log.event?.entity?.changedFields || {},
  );

  const filteredChangedFields =
    changedFields.length > 1
      ? changedFields.filter((field) => field !== 'TimeUpdated')
      : changedFields;

  if (
    !(log?.event && log?.action.type === 'update') &&
    !(log?.event && log?.action.type === 'update_megaplan') &&
    !(log.action.type && (log.action.context as any)?.url) &&
    !(
      (log.action.context as any)?.scenario_id &&
      log.action.type === 'scenario_request'
    )
  ) {
    return <></>;
  }

  return (
    <Box
      style={{
        padding: 8,
        border: '1px solid #ccc',
        borderRadius: 4,
        width: 'fit-content',
        minWidth: '100%',
      }}
    >
      <Box>
        {log?.problem && (
          <Typography color="error" style={{ fontWeight: 'bold' }}>
            Problem: {log.problem}
          </Typography>
        )}
        <ActorComponent log={log} />
        <ActionComponent log={log} />
      </Box>
      {['create', 'update'].includes(log.event?.action.type || '') && (
        <Box>
          {filteredChangedFields.map((field) => (
            <Box
              key={field}
              style={{
                marginLeft: 8,
                display: 'flex',
                flexDirection: 'row',
                gap: 4,
              }}
            >
              <Typography>{field}</Typography>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 4,
                }}
              >
                <Typography style={{ fontWeight: 'bold' }}>
                  {' from '}
                </Typography>
                <Box>
                  <ActivityValue
                    value={
                      ((log.event?.entity?.changedFields as Record<
                        string,
                        unknown
                      >)?.[field] as string) || 'null'
                    }
                  />
                </Box>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 4,
                }}
              >
                <Typography style={{ fontWeight: 'bold' }}>{' to '}</Typography>
                <Box>
                  <ActivityValue
                    value={
                      ((log.event?.action?.context as Record<
                        string,
                        unknown
                      >)?.[field] as string) || 'null'
                    }
                  />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      )}
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 4,
        }}
      >
        <Typography style={{ fontWeight: 'bold' }}>Started at:</Typography>
        <Typography>{` ${log.started_at}`}</Typography>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 4,
        }}
      >
        {log.finished_at ? (
          <>
            <Typography style={{ fontWeight: 'bold' }}>Finished at:</Typography>
            <Typography>{` ${log.finished_at}`}</Typography>
          </>
        ) : (
          <Typography style={{ fontWeight: 'bold' }}>In progress</Typography>
        )}
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 4,
        }}
      >
        {log.lct && (
          <>
            <Typography style={{ fontWeight: 'bold' }}>
              LifecycleToken:
            </Typography>
            <Typography>{` ${log.lct}`}</Typography>
          </>
        )}
      </Box>
    </Box>
  );
};
