import { Box, Typography, makeStyles, Theme } from '@material-ui/core';
import React, { FC, ReactNode, useEffect } from 'react';
import MuiButton from './MuiButton';
import { logger } from '../utils/logger';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2, 3),
  },
  button: {
    marginTop: theme.spacing(1),
  },
}));

export const baseError = {
  text: '',
  code: '',
  lctoken: '',
  plctoken: '',
};

interface ErrorHandlerProps {
  error: typeof baseError | string | null;
  retry: () => void;
  children: ReactNode;
  componentName: string;
}

export const ErrorHandler: FC<ErrorHandlerProps> = ({
  error,
  retry,
  children,
  componentName,
}) => {
  const classes = useStyles();
  useEffect(() => {
    if (!error) return;

    if (typeof error === 'string') {
      logger.error(`ErrorHandler: ${componentName}`, { error });
    }

    if (typeof error === 'object') {
      logger.error(`ErrorHandler: ${componentName}`, error);
    }
  }, [error]);

  if (typeof error === 'string') {
    return (
      <Box className={classes.container}>
        <Typography>Error: {error}</Typography>
        <MuiButton className={classes.button} onClick={retry}>
          Try again
        </MuiButton>
      </Box>
    );
  }

  if (typeof error === 'object' && !!error) {
    return (
      <Box className={classes.container}>
        <Typography>Error:</Typography>
        {!!error.text && <Typography>Text: {error.text}</Typography>}
        {!!error.code && <Typography>Code: {error.code}</Typography>}
        {!!error.lctoken && (
          <Typography>LifeCycleToken: {error.lctoken}</Typography>
        )}
        {!!error.plctoken && (
          <Typography>ParentLifeCycleToken: {error.plctoken}</Typography>
        )}
        <MuiButton className={classes.button} onClick={retry}>
          Try again
        </MuiButton>
      </Box>
    );
  }

  return <>{children}</>;
};
