import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { DealProps } from '..';
import WebHookActivator, {
  WebHookConfig,
} from '../../../../Table/components/webHookActivator/WebhookActivator';

interface TableHeaderButtonContainerProps {
  buttons: DealProps['buttons'];
}

const DealButtons: FC<TableHeaderButtonContainerProps> = ({ buttons }) => {
  const components = buttons.map((button) => {
    if (!!(button as any).component) {
      return (button as any).component;
    }

    return <WebHookActivator isSlim {...(button as WebHookConfig)} />;
  });

  return (
    <Box display="flex" alignItems="center" style={{ gap: 8, padding: 8 }}>
      {components.map((component) => component)}
    </Box>
  );
};

export default DealButtons;
