import React from 'react';
import AbstractService, {
  AbstractServiceProps,
} from '../../../AbstractService';
import AppContainer from '../../../../layout/AppContainer/AppContainer';
import { ErrorView, Spinner } from '../../../../UI';
import { DealType } from '../GenericDeal';
import { ProblemDeal } from './components/ProblemDeal';
import { FindOriginalDealsByRids } from '../Bag/widgets/FindItemsByRidAndCSN/FindItemsByRidAndCSN';
import LinkedDeals from '../common/widgets/LinkedDeals';
import { archiveScenarioProblem } from '../constants/archive-scenario';
import { apiClientsPickups } from '../api-clients/api-clients-pickups';
import { apiClientsDropship } from '../api-clients/api-clients-dropships';
import { apiClientsOrders } from '../../api-clients';
import { apiClientsBag } from '../api-clients/api-clients-bags';

export class CRMProblemDeal extends AbstractService<AbstractServiceProps> {
  render() {
    const { appConfig, error } = this.state;

    if (error) {
      return (
        <AppContainer services={{}}>
          <div className="w-100 h-100 d-flex flex-column justify-content-center">
            <ErrorView error={error}>
              <div className="d-flex justify-content-center p-3">
                <div
                  className="btn btn-primary cursor-pointer"
                  onClick={this.reloadHome}
                >
                  Home
                </div>
              </div>
            </ErrorView>
          </div>
        </AppContainer>
      );
    }

    if (appConfig === null) {
      return <Spinner />;
    }

    const { user, role } = this.props.userIdentity;
    return (
      <AppContainer
        currentResourceName={this.props.resourceName}
        services={this.props.availableServices}
        user={user}
        role={role}
      >
        <ProblemDeal
          fieldsConfig={{
            General: {
              fields: [
                {
                  displayName: 'Supplier',
                  type: 'input',
                  apiFieldPath: 'supplier',
                },
                {
                  displayName: '4WeMustDo',
                  type: 'input',
                  apiFieldPath: '4wemustdo',
                },
                {
                  displayName: '4WeWaitFor',
                  type: 'input',
                  apiFieldPath: '4wewaitfor',
                },
                {
                  displayName: '4WeWaitFrom',
                  type: 'input',
                  apiFieldPath: '4wewaitfrom',
                },
                {
                  displayName: 'Ordernumber',
                  type: 'input',
                  apiFieldPath: 'ordernumber',
                },
                {
                  displayName: 'Tracknumber',
                  type: 'input',
                  apiFieldPath: 'tracknumber',
                },
                {
                  displayName: 'TracknumberCarrier',
                  type: 'input',
                  apiFieldPath: 'tracknumberCarrier',
                },
                {
                  displayName: 'Marketplace1',
                  type: 'input',
                  apiFieldPath: 'marketplace1',
                },
                {
                  displayName: 'MpOrderNumber',
                  type: 'input',
                  apiFieldPath: 'mpOrderNumber',
                },
                {
                  displayName: 'SrOrderNumber',
                  type: 'input',
                  apiFieldPath: 'srOrderNumber',
                },
                {
                  displayName: 'SrReturnNumber',
                  type: 'input',
                  apiFieldPath: 'srReturnNumber',
                },
                {
                  displayName: 'SupplierRefundToUs',
                  type: 'input',
                  apiFieldPath: 'supplierRefundToUs',
                },
                {
                  displayName: 'SrOriginalOrderNumber',
                  type: 'input',
                  apiFieldPath: 'srOriginalOrderNumber',
                },
                {
                  displayName: 'Reason',
                  type: 'input',
                  apiFieldPath: 'reason',
                },
                {
                  displayName: 'Solution',
                  type: 'input',
                  apiFieldPath: 'solution',
                },
                {
                  displayName: 'SourceOfCommunication',
                  type: 'input',
                  apiFieldPath: 'sourceOfCommunication',
                },
                {
                  displayName: 'Archive scenario',
                  type: 'select',
                  options: archiveScenarioProblem,
                  apiFieldPath: 'archiveScenario',
                },
              ],
            },
          }}
          type={DealType.Problem}
          buttons={[]}
          widgets={[
            {
              buttonName: 'Find original deals by RIDs or CSNs',
              displayName: 'Find original deals by RIDs or CSNs',
              component: FindOriginalDealsByRids,
              docsLink:
                'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.s20xwpofdhyn',
              docsTitle: 'docs for FindOriginalDealsByRids widget',
            },
            {
              displayName: 'Linked deals',
              buttonName: 'Linked deals',
              component: LinkedDeals,
              docsLink:
                'https://docs.google.com/document/d/1wkHUPzR_MJsBdkjFYLtpGTbQ9QFTMn2_CY7lKyfxo1Q/edit#heading=h.hwje1ah0h4ok',
              docsTitle: 'docs for Linked deals widget',
              props: {
                linkField: 'relatedDeal',
                apiClients: [
                  {
                    type: DealType.Pickup,
                    apiClient: apiClientsPickups,
                    customLinkField: 'id',
                  },
                  {
                    type: DealType.Dropship,
                    apiClient: apiClientsDropship,
                    customLinkField: 'id',
                  },
                  {
                    type: DealType.Order,
                    apiClient: apiClientsOrders,
                    customLinkField: 'id',
                  },
                  {
                    type: DealType.Bag,
                    apiClient: apiClientsBag,
                    customLinkField: 'id',
                  },
                ],
              },
            },
          ]}
        />
      </AppContainer>
    );
  }
}
