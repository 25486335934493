import {
  OrderDealsApi,
  Configuration as OrderApiConfiguration,
} from '../../crm-order-api/index';
import ApiClientsBags from './components/api-clients/api-clients-bags';
import ApiClientsPickups from './components/api-clients/api-clients-pickups';
import ApiClientsOrders from './components/api-clients/api-clients-orders';

export const bagClient = new ApiClientsBags();

export const orderClient = new OrderDealsApi(
  new OrderApiConfiguration({
    basePath: '/api/openapi/CRMOrders/v1',
  }),
);

export const apiClientsOrders = new ApiClientsOrders();

export const pickupClient = new ApiClientsPickups();
