import Axios from 'axios';
import { Order } from '../../../../api-clients/types';
import _ from 'lodash';
import { fetchNewLinkedDealsData } from '../HowToBuy4/utils/fetchCreateLinkedDealsDataV2';
import {
  HowToBuyOptionsRecord,
  HowToBuyOptionsResult,
} from '../HowToBuy4/utils/getHowToBuyOptions';

function parseDate(dateStr: string | null) {
  if (!dateStr) {
    return 0;
  }
  const [datePart, timePart] = dateStr.split(' ');

  let parts;
  if (datePart.includes('-')) {
    parts = datePart.split('-');
  } else if (datePart.includes('.')) {
    parts = datePart.split('.');
    parts = [parts[2], parts[1], parts[0]];
  } else {
    return 0;
  }

  const timeParts = timePart.split(':');

  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1;
  const day = parseInt(parts[2], 10);
  const hour = parseInt(timeParts[0], 10);
  const minute = parseInt(timeParts[1], 10);
  const second = parseInt(timeParts[2], 10);

  return new Date(year, month, day, hour, minute, second);
}

interface ShippingMethodsResponseData {
  sender: string;
  carrier: string | null;
  shippingMethodId: string;
  shippingMethodName: string;
  cost: number;
  trackNumberDate: string;
  shippingArriveDate: string;
}

interface ShippingMethodsResponseMessage {
  level: string;
  text: string;
  type: string;
}

interface ShippingMethodsResponse {
  config: any;
  data: {
    data: ShippingMethodsResponseData[];
    message: ShippingMethodsResponseMessage[];
  };
  headers: any;
  request: any;
  status: number;
  statusText: string;
}

const getSendersByRid = async (rid: string) => {
  const senders = await Axios.get(
    `/api/webhook/VictualSupplierItemInfo?rid=${rid}`,
  );

  return senders;
};

const getShippingMethods = async (
  deal: Order,
): Promise<ShippingMethodsResponse> => {
  const positions = deal.no_special_items_positions;

  if (!positions || !positions.length) {
    throw new Error('There are no positions');
  }

  const senders = await Promise.all(
    positions.map((position) => getSendersByRid(position.article)),
  );

  const res = senders.map((sender) =>
    Object.entries(sender.data).map(([key, value]: any) => {
      return value?.name;
    }),
  );
  const flattenRes = _.flatten(res);
  const resultSenders = _.uniq(flattenRes);

  const {
    name,
    phone,
    email,
    state,
    city,
    address1,
    address2,
    postalCode,
  } = deal.client;

  const resultClient = {
    name,
    phone,
    email,
    address: {
      // "USA" - because htb don't work for not USA deliveries
      country: 'USA',
      state,
      city,
      postalCode,
      address1,
      address2,
    },
  };

  const resultItems = positions.map((position) => ({
    rid: position.article,
    qty: +position.quantity,
  }));

  const items = positions.map((position) => ({
    Quantity: +position.quantity,
    OrderItemId: position.name.split(' ').reverse()[0],
  }));

  const labels = {
    marketplaceName: deal.mpName,
    orderId: deal.mpOrderNumber,
    items,
  };

  const reqBody = {
    senders: resultSenders,
    customer: resultClient,
    items: resultItems,
    labels,
  };

  const shippingMethods: ShippingMethodsResponse = await Axios.post(
    '/api/openapi/ShippingMethods/v2/shipping',
    reqBody,
  );

  console.log('shippingMethods', shippingMethods);

  return shippingMethods;
};

type CombinedBySupplierResult = Record<
  string,
  {
    linkedDealsV2: HowToBuyOptionsRecord;
    shippingOptions: ShippingMethodsResponseData[];
  }
>;

const combineBySupplier = (
  LinkedDealsV2Data: HowToBuyOptionsResult,
  shippingMethods: ShippingMethodsResponse,
  deal: Order,
): CombinedBySupplierResult => {
  const combinedBySupplier: CombinedBySupplierResult = {};
  Object.entries(LinkedDealsV2Data.howToBuyOptions).map(
    ([key, linkedDealsV2]) => {
      const shippingOptions = shippingMethods.data.data.filter(
        ({ sender, shippingMethodId }) => {
          console.log('sender, key', {
            sender,
            key,
          });

          if (shippingMethodId.startsWith('ROF')) {
            return `${sender}_rof` === key;
          }
          return sender === key;
        },
      );
      const shippingOptionsFormatted = shippingOptions.map((option) => {
        return {
          ...option,
          shippingMethodName: !!option.carrier
            ? `${option.carrier} ${option.shippingMethodName}`
            : option.shippingMethodName,
        };
      });

      linkedDealsV2.map((linkedDeal) => {
        const position = deal.no_special_items_positions.find(
          (position) => position.article === linkedDeal.rollunId,
        );
        console.log({
          linkedDeal,
          position,
          deal,
          linkedDealsV2,
        });

        if (!!position) {
          linkedDeal.qty = position.quantity;
        }
      });

      combinedBySupplier[key] = {
        linkedDealsV2,
        shippingOptions: shippingOptionsFormatted,
      };
    },
  );

  console.log('combinedBySupplier', combinedBySupplier);

  return combinedBySupplier;
};

interface InputData {
  id: string;
  sender: string;
  shippingMethod: string;
  deliveryCost: number;
  cogs: string;
  trackNumberDate: string;
  shippingArriveDate: string;
  isDeliveryOnTime: string;
  hoursLate: number;
  daysLate: number;
  [key: string]: any;
}

interface TransformedItem {
  rollunId: string;
  mpItemId: string;
  qty: string;
  uniqItemId: string;
  warehouseQty?: string;
  price: string;
  isAvailable: string;
  shippingMethods: string[];
  shippingOptions: any[];
  inventoryQuantity: string;
  deliveryCost: string;
  cogs: number;
  trackNumberDate: string;
  shippingArriveDate: string;
  isDeliveryOnTime: string;
  hoursLate: string;
  daysLate: string;
  labelProvider: string | null;
}

interface TransformedData {
  [key: string]: TransformedItem[];
}

const transformData = (
  data: InputData[],
  combinedBySupplier: any,
): TransformedData => {
  const result: TransformedData = {};

  console.log('transformData', { data });

  data.forEach((order) => {
    const sender = order.sender;
    console.log('transformData', { order, sender });

    if (!result[sender]) {
      result[sender] = [];
    }

    const itemKeys = Object.keys(order).filter((key) =>
      key.startsWith('item['),
    );

    console.log('transformData', { itemKeys });

    const items: { [key: string]: any } = {};
    itemKeys.forEach((key) => {
      const match = key.match(/item\[(\d+)\]\.(.+)/);
      if (match) {
        const [, index, prop] = match;
        if (!items[index]) items[index] = {};
        items[index][prop] = order[key];
      }
    });

    console.log('transformData', { items });

    const {
      deliveryCost,
      cogs,
      trackNumberDate,
      shippingArriveDate,
      isDeliveryOnTime,
      hoursLate,
      daysLate,
      labelProvider,
    } = order;

    console.log('transformData', { order, items });
    Object.values(items).forEach((item: any) => {
      const transformedItem: any = {
        rollunId: item.rid,
        mpItemId: item.mpItemId,
        qty: item.qty,
        uniqItemId: item.uniqItemId,
        warehouseQty: item.warehouseQty,
        price: item.price,
        isAvailable: item.isAvailable,
        shippingMethods: [order.shippingMethod],
        shippingOptions: [],
        inventoryQuantity: item.inventoryQuantity,
        deliveryCost,
        cogs,
        trackNumberDate,
        shippingArriveDate,
        isDeliveryOnTime,
        hoursLate,
        daysLate,
        labelProvider,
      };
      console.log('transformDataItems', {
        item,
        items,
        transformedItem,
        sender,
        result,
      });
      // add deliveryCost, cogs, trackNumberDate, shippingArriveDate, isDeliveryOnTime, hoursLate, daysLate
      result[sender].push(transformedItem);
    });
  });

  console.log('{data, combinedBySupplier}', {
    data,
    combinedBySupplier,
    result,
  });

  const res: any = {};
  Object.entries(result).map(([sender, options]) => {
    const result2: {
      sender: string;
      shippingMethods: any[];
      shippingData: Record<string, any>;
      shippingOptions: any[];
      mpItemId: string;
      qty: string;
      uniqItemId: string;
      warehouseQty?: string;
      price: string;
      isAvailable: string;
      deliveryCost: string;
      cogs: string;
      trackNumberDate: string;
      shippingArriveDate: string;
      isDeliveryOnTime: string;
      hoursLate: string;
      daysLate: string;
      labelProvider: string;
      deliveryCostMapping: Record<string, number>;
    } = {
      sender,
      shippingMethods: [],
      shippingOptions: [],
      shippingData: {},
      qty: '',
      uniqItemId: '',
      mpItemId: '',
      warehouseQty: '',
      price: '',
      isAvailable: '',
      deliveryCost: '',
      cogs: '',
      trackNumberDate: '',
      shippingArriveDate: '',
      isDeliveryOnTime: '',
      hoursLate: '',
      daysLate: '',
      labelProvider: '',
      deliveryCostMapping: {},
    };

    const data = combinedBySupplier[sender];
    const { shippingOptions } = data as any;
    console.log('sender, shippingOptions', { sender, shippingOptions });
    const compiledOption = result[sender]?.[0];
    const filteredShippingOptions = shippingOptions.filter((option: any) => {
      if (sender.includes('rof') && option.shippingMethodId.startsWith('ROF')) {
        return option.sender === sender.replace(/_rof$/, '');
      }
      return option.sender === sender;
    });

    console.log({ filteredShippingOptions, compiledOption });

    if (!compiledOption) {
      return;
    }

    filteredShippingOptions.map((option: any) => {
      result2.shippingData[option.shippingMethodName] = {
        ...compiledOption,
      };
      result2.shippingOptions = filteredShippingOptions;
      result2.labelProvider +=
        `${option.shippingMethodName}: ${compiledOption.labelProvider}` + '\n';
      result2.qty +=
        `${option.shippingMethodName}: ${compiledOption.qty}` + '\n';
      result2.uniqItemId +=
        `${option.shippingMethodName}: ${compiledOption.uniqItemId}` + '\n';
      result2.warehouseQty = `${compiledOption.warehouseQty}`;
      result2.price = `${compiledOption.price}`;
      result2.isAvailable +=
        `${option.shippingMethodName}: ${compiledOption.isAvailable}` + '\n';
      result2.deliveryCost +=
        `${option.shippingMethodName}: ${compiledOption.deliveryCost}` + '\n';
      result2.cogs +=
        `${option.shippingMethodName}: ${compiledOption.cogs}` + '\n';
      result2.trackNumberDate +=
        `${option.shippingMethodName}: ${compiledOption.trackNumberDate}` +
        '\n';
      result2.shippingArriveDate +=
        `${option.shippingMethodName}: ${compiledOption.shippingArriveDate}` +
        '\n';
      result2.isDeliveryOnTime +=
        `${option.shippingMethodName}: ${compiledOption.isDeliveryOnTime}` +
        '\n';
      result2.hoursLate +=
        `${option.shippingMethodName}: ${compiledOption.hoursLate}` + '\n';
      result2.daysLate +=
        `${option.shippingMethodName}: ${compiledOption.daysLate}` + '\n';
      result2.mpItemId +=
        `${option.shippingMethodName}: ${compiledOption.mpItemId}` + '\n';
      result2.deliveryCostMapping[
        option.shippingMethodName
      ] = +compiledOption.deliveryCost;
    });

    Object.entries(result2.shippingData).map(([shippingMethodName]: any) => {
      result2.shippingMethods.push(shippingMethodName);
    });

    console.log('transformDataOptions', {
      result,
      result2,
      options,
      sender,
      res,
    });

    res[sender] = result2;
  });

  const changes: any = {};
  Object.entries(combinedBySupplier).map(([sender, options]): any => {
    ((options as any).linkedDealsV2 as any).map(
      (option: any, index: number) => {
        if (!res[sender]) {
          return;
        }
        if (!changes?.[sender]) {
          changes[sender] = [];
        }

        const changedOptions = {
          ...res[sender],
          ...option,
          ...res[sender][index],
          shippingMethods: res[sender].shippingMethods,
        };
        changes[sender].push(changedOptions);
        console.log({
          res,
          changes,
          sender,
          option,
          rs: result[sender],
          rss: res[sender],
          changedOptions,
        });
      },
    );
  });
  console.log('{data, combineBySupplier}', {
    data,
    combinedBySupplier,
    changes,
  });

  return changes;
};

export const fetchCreateLinkedDealsV3 = async (deal: Order) => {
  const shippingMethods = await getShippingMethods(deal);
  console.log('shippingMethods', shippingMethods);

  const LinkedDealsV2Data = await fetchNewLinkedDealsData(
    deal.no_special_items_positions,
  );

  const combinedBySupplier = combineBySupplier(
    LinkedDealsV2Data,
    shippingMethods,
    deal,
  );

  console.log('combinedBySupplier', combinedBySupplier);

  const result = Object.entries(combinedBySupplier).map(([key, value]) => {
    const datastoreEntry = [];

    let isAllAvailableItems = true;
    value.linkedDealsV2.map((item) => {
      if ((item.warehouseQty || 0) < +item.qty) {
        console.log('itemNot', { item, value });
        isAllAvailableItems = false;
      }
    });
    // if less - not display, if more - idk what to do, but think it's not okay
    if (value.linkedDealsV2.length !== deal.no_special_items_positions.length) {
      isAllAvailableItems = false;
    }

    const itemsData = {} as any;

    (value as any).linkedDealsV2.map((item: any, index: any) => {
      itemsData[`item[${index}].rid`] = item.rollunId;
      itemsData[`item[${index}].qty`] = item.qty;
      itemsData[`item[${index}].price_per_item`] = item.price;
      itemsData[`item[${index}].inventoryQuantity`] = item.inventoryQuantity;
      itemsData[`item[${index}].warehouseQty`] = item.warehouseQty;
      itemsData[`item[${index}].isAvailable`] = item.isAvailable ? 'yes' : 'no';
    });

    for (let i = 0; i < (value as any).shippingOptions.length; i++) {
      const shippingOption = (value as any).shippingOptions[i];
      let totalPrice = 0;
      (value as any).linkedDealsV2.map((item: any) => {
        totalPrice += item.qty * item.price;
      });
      const cogs = (totalPrice + shippingOption.cost).toFixed(2);

      const isDeliveryOnTime = deal.mpName.includes('Amazon')
        ? parseDate(deal.dateDeliverBy) >
          parseDate(shippingOption.shippingArriveDate)
          ? 'yes'
          : 'no'
        : deal.mpName.includes('Ebay')
        ? parseDate(deal.dateShipBy) > parseDate(shippingOption.trackNumberDate)
          ? 'yes'
          : 'no'
        : 'error: mpName not Ebay or Amazon';

      const timeDifferenceMs = deal.mpName.includes('Amazon')
        ? (new Date(parseDate(shippingOption.shippingArriveDate)) as any) -
          (new Date(parseDate(deal.dateDeliverBy)) as any)
        : deal.mpName.includes('Ebay')
        ? (new Date(parseDate(shippingOption.trackNumberDate)) as any) -
          (parseDate(deal.dateShipBy) as any)
        : null;
      const hoursLate = !!timeDifferenceMs
        ? Math.floor(timeDifferenceMs / (1000 * 60 * 60))
        : null;
      const daysLate = !!hoursLate ? Math.floor(hoursLate / 24) : null;

      const labelProvider = key.includes('pickup')
        ? !!shippingOption?.labelProvider && !!shippingOption.cost
          ? shippingOption?.labelProvider > shippingOption.cost
            ? 'Rollun'
            : 'Amazon'
          : 'Rollun'
        : null;

      const res = {
        id: `${key}--${shippingOption.shippingMethodName}`,
        sender: key,
        shippingMethod: shippingOption.shippingMethodName,
        deliveryCost: shippingOption.cost,
        labelProvider,
        cogs: +cogs,
        trackNumberDate: shippingOption.trackNumberDate,
        shippingArriveDate: shippingOption.shippingArriveDate,
        isDeliveryOnTime,
        hoursLate,
        daysLate,
        ...itemsData,
        isAllAvailableItems,
      };

      datastoreEntry.push(res);
    }

    return datastoreEntry;
  });

  const finalResult = _.flatten(result).filter(Boolean);

  console.log('resu', {
    deal,
    shippingMethods,
    LinkedDealsV2Data,
    result,
    combinedBySupplier,
    finalResult,
  });

  console.log('finalResult, combinedBySupplier', {
    finalResult,
    combinedBySupplier,
  });

  const transformedData = transformData(finalResult, combinedBySupplier);

  const notFinalResult: any = [];
  finalResult
    .filter(({ isAllAvailableItems }) => !!isAllAvailableItems)
    .forEach((item) => {
      delete item['isAllAvailableItems'];
      notFinalResult.push(item);
    });

  console.log('transformData', {
    transformedData,
    finalResult,
    notFinalResult,
  });

  return {
    first: notFinalResult,
    second: { howToBuyOptions: transformedData },
  };
};
