import React from 'react';
import AbstractService, { AbstractServiceProps } from '../AbstractService';
import AppContainer from '../../layout/AppContainer/AppContainer';
import { ErrorView, Spinner } from '../../UI';
import EbayRefreshTokenContainer from './components/EbayRefreshTokenContainer';

/**
 * EbayRefreshToken - utility page - must help our users retrieve ebay refresh token easier
 */

class EbayRefreshToken extends AbstractService<AbstractServiceProps> {
  render() {
    const { appConfig, error } = this.state;

    if (error) {
      return (
        <AppContainer services={{}}>
          <div className="w-100 h-100 d-flex flex-column justify-content-center">
            <ErrorView error={error}>
              <div className="d-flex justify-content-center p-3">
                <div
                  className="btn btn-primary cursor-pointer"
                  onClick={this.reloadHome}
                >
                  Home
                </div>
              </div>
            </ErrorView>
          </div>
        </AppContainer>
      );
    }

    if (appConfig === null) {
      return <Spinner />;
    }
    if (!appConfig.appParams.EbayRefreshTokenParams) {
      throw new Error(`No EbayRefreshToken found!`);
    }
    const { agreementLink } = appConfig.appParams.EbayRefreshTokenParams;
    let code: string | null = null;
    if (this.props.location) {
      const query = this.props.location.search
        .slice(1)
        .split('&')
        .map((row) => row.split('='))
        .reduce((acc: { [key: string]: any }, row) => {
          acc[row[0]] = row[1];
          return acc;
        }, {});
      code = query['code'];
    }
    const { user, role } = this.props.userIdentity;
    return (
      <AppContainer
        currentResourceName={this.props.resourceName}
        services={this.props.availableServices}
        user={user}
        role={role}
      >
        <EbayRefreshTokenContainer code={code} agreementLink={agreementLink} />
      </AppContainer>
    );
  }
}

export default EbayRefreshToken;
