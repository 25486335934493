import { Sender, SENDERS } from '../../../../../constants/senders';
import { Position } from '../../../../../api-clients/types';
import { SUPPLIERS } from '../../../../../constants/suppliers';
import { uniqBy } from 'lodash';

export type HowToBuyShippingOption = {
  sender: string;
  Sr_ship_method: string;
  date_Sr_track_receiving: string;
  date_Sr_delivery: string;
  price_ship: number;
  Sr_name: string;
  price_item: number;
  availability: number;
  price_ship_and_item: number;
  virtual_Sr_name: string;
};

export type HowToBuyOptionData = {
  items_data: {
    [key: string]: {
      ship_options: HowToBuyShippingOption[];
    };
  };
};

export type HowToBuyOptionBestShipping = HowToBuyShippingOption;

export type HowToBuyOptionsRecord = (ReturnType<
  typeof formatHowToBuyWarehouseOption
> & {
  mpItemId?: string;
  uniqItemId?: string;
  warehouseQty?: number;
  shippingMethods?: string[];
  inventoryQuantity?: Record<string, number>;
})[];

export type HowToBuyOptionsResult = {
  howToBuyOptions: Record<string, HowToBuyOptionsRecord>;
  bestShipping?: ReturnType<typeof formatShippingOption> | null;
};

export function getHowToBuyOptions(
  rawData: string,
  warehouseField: 'sender' | 'virtual_Sr_name',
  rawBestShip: string | undefined,
  products: Position[],
): HowToBuyOptionsResult {
  const { data, bestShip } = parseHowToBuyData(rawData, rawBestShip);

  const bestShipping = bestShip
    ? formatShippingOption(bestShip, warehouseField)
    : null;
  const howToBuyOptions = formatHowToBuyOptions(products, data, warehouseField);

  return {
    howToBuyOptions,
    bestShipping,
  };
}

function formatHowToBuyOptions(
  products: Position[],
  data: HowToBuyOptionData,
  warehouseField: 'sender' | 'virtual_Sr_name',
) {
  const uniqueItems = uniqBy(products, ({ article }) => article);
  const result: Record<
    string,
    ReturnType<typeof formatHowToBuyWarehouseOption>[]
  > = {};

  for (const product of uniqueItems) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const shippingOptions = data.items_data[product.article].ship_options.map(
      (shipOption) => {
        return {
          ...shipOption,
          [warehouseField]: getRealVirtualSrName(
            shipOption[warehouseField],
            shipOption.Sr_name,
          ),
        };
      },
    );

    const warehouseOptions =
      warehouseField === 'sender'
        ? Array.from(new Set(shippingOptions.map((option) => option.sender)))
        : SUPPLIERS.map(({ virtual_warehouse }) => virtual_warehouse);

    for (const warehouse of warehouseOptions as string[]) {
      const warehouseRow = formatHowToBuyWarehouseOption(
        warehouse,
        shippingOptions,
        warehouseField,
        product,
      );

      if (warehouse in result) {
        result[warehouse].push(warehouseRow);
        continue;
      }

      result[warehouse] = [warehouseRow];
    }
  }

  return result;
}

function formatHowToBuyWarehouseOption(
  warehouse: string,
  shippingOptions: HowToBuyShippingOption[],
  warehouseField: 'sender' | 'virtual_Sr_name',
  product: Position,
) {
  const warehouseData = shippingOptions.filter((ship_option) => {
    const currentWarehouse = ship_option[warehouseField];
    return warehouse === currentWarehouse;
  });

  if (!warehouseData) {
    return makeDefaultData(product);
  }

  try {
    return getDataFromHowToBuyField(warehouseData, product, warehouseField);
  } catch (err) {
    throw new Error(
      `Error while parsing How to buy data: ${(err as Error).message}`,
    );
  }
}

function parseHowToBuyData(rawData: string, rawBestShip: string | undefined) {
  let data: HowToBuyOptionData;
  let bestShip: HowToBuyOptionBestShipping;

  try {
    data = typeof rawData === 'string' ? JSON.parse(rawData) : rawData;
    bestShip = !rawBestShip
      ? null
      : typeof rawBestShip === 'string'
      ? JSON.parse(rawBestShip)
      : rawBestShip;
  } catch (e) {
    throw new Error(
      `HowToBuy field is ${rawData ? 'not in valid format' : 'empty'}!`,
    );
  }

  return {
    data,
    bestShip,
  };
}

function getRealVirtualSrName(warehouse: string, srName: string) {
  if (warehouse === 'pickup_ky' && srName === 'ROF') {
    return 'pickup_ky_ROF';
  }

  if (warehouse === 'pickup_tx' && srName === 'ROF') {
    return 'pickup_tx_ROF';
  }

  if (warehouse === 'pickup_cn' && srName === 'ROF') {
    return 'pickup_cn_ROF';
  }

  if (SENDERS.includes(warehouse as Sender)) {
    return warehouse;
  }

  return warehouse;
}

function formatShippingOption(
  {
    price_ship,
    date_Sr_delivery,
    date_Sr_track_receiving,
    Sr_ship_method,
    Sr_name,
    availability,
    ...rest
  }: HowToBuyShippingOption,
  warehouseField: 'sender' | 'virtual_Sr_name',
) {
  return {
    cost: price_ship,
    price_item: rest.price_item,
    qty: availability,
    shippingArriveDate: date_Sr_delivery,
    trackNumberDate: date_Sr_track_receiving,
    deliverySender: getRealVirtualSrName(rest[warehouseField], Sr_name),
    shippingMethodName: Sr_ship_method,
    supplierAbbreviation: Sr_name,
  };
}

function getDataFromHowToBuyField(
  warehouseData: HowToBuyShippingOption[],
  product: Position,
  warehouseField: 'sender' | 'virtual_Sr_name',
) {
  return {
    rollunId: product.article,
    qty: product.quantity, // always 1 for now
    price: warehouseData[0] ? warehouseData[0].price_item : 0,
    isAvailable: true,
    shippingOptions: warehouseData
      // filter duplicates
      .filter(
        ({ Sr_ship_method, Sr_name, ...rest }, idx, array) =>
          array.findIndex((e) => {
            return (
              e.Sr_ship_method === Sr_ship_method &&
              e[warehouseField] === rest[warehouseField] &&
              e.Sr_name === Sr_name
            );
          }) === idx,
      )
      .map((shipOption) => formatShippingOption(shipOption, warehouseField))
      .sort((a, b) => a.cost - b.cost),
  };
}

function makeDefaultData(product: Position) {
  return {
    rollunId: product.article,
    qty: 1,
    price: 0,
    isAvailable: false,
    shippingOptions: [],
  };
}
