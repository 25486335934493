import React from 'react';
import axios from 'axios';
import { useContext, useEffect, useMemo, useState } from 'react';
import MuiButton from '../../../../../../UI/MuiButton';
import { DealContext } from '../../../GenericDeal/context/dealContext';
import Paper from '@material-ui/core/Paper';
import { Query, Contains } from 'rollun-ts-rql';
import HttpDatastore from 'rollun-ts-datastore';
import { Link } from 'react-router-dom';
import {
  Box,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import ApiClientsDropships from '../../../api-clients/api-clients-dropships';
import ApiClientsBags from '../../../api-clients/api-clients-bags';

const API_ORIGIN = 'https://rollun.net';

const datastore = axios.create({
  baseURL: API_ORIGIN,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
  },
});

const bagsDatastore = new HttpDatastore('/api/datastore/BagsDataStore');
const dropshipsDatastore = new HttpDatastore(
  '/api/datastore/DropshipsDataStore',
);

const DEFAULT_MSG = 'Unknown error';
const errorToString = (err: any) => {
  const { response } = err;
  if (!response) {
    return err.message || DEFAULT_MSG;
  }

  const { data } = response;
  if (!data) {
    return err.message || DEFAULT_MSG;
  }

  // datastore error
  if (typeof data === 'string') {
    return data;
  }

  if (data.error) {
    return data.error;
  }

  // megaplan error
  if (data && data.meta) {
    const { meta } = data;
    if (!meta.errors || meta.errors.length === 0) return DEFAULT_MSG;

    const [error] = meta.errors;

    return `MP error - ${meta.status}, ${
      error.field ? error.field : 'Message'
    }: ${error.message}`;
  }
  return DEFAULT_MSG;
};

export const FindOriginalDealsByRids = () => {
  const possibleFindBys = useMemo(() => ['rid', 'csn'], []);
  const [findBy, setFindBy] = useState('rid');
  const [searchValue, setSearchValue] = useState('');
  const [submittedSearch, setSubmittedSearch] = useState('');

  const clickHandler = () => {
    return (event: React.MouseEvent) => {
      setSubmittedSearch(searchValue);
      event.preventDefault();
    };
  };

  return (
    <Box>
      <Box>
        <Select
          onChange={(e) => setFindBy(e.target.value as string)}
          style={{
            minWidth: 120,
          }}
          value={findBy}
        >
          {possibleFindBys.map((findB) => (
            <MenuItem
              value={findB}
              style={{
                minWidth: 120,
              }}
            >
              {findB}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box
        style={{
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <TextField
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </Box>
      <Box>
        <MuiButton onClick={clickHandler()}>Find</MuiButton>
      </Box>
      {submittedSearch && (
        <OriginalDealsTable search={submittedSearch} findBy={findBy} />
      )}
    </Box>
  );
};

const OriginalDealsTable = ({ search, findBy }: any) => {
  const [deals, setDeals] = useState<any[]>([]);
  const [status, setStatus] = useState('idle');
  const [error, setError] = useState('');
  const dealContext = useContext(DealContext);

  const formatSearch = useMemo(
    () => ({
      rid: (rid: any) => rid,
      csn: async (csn: any) => {
        const {
          data: [ridWithCsn],
        } = await datastore.get(
          `/api/datastore/RollunIdWithCsnView?limit(1,0)&eq(csn,${csn})`,
        );

        if (!ridWithCsn) {
          throw new Error(`Cannot find rid to csn - ${csn}`);
        }

        return ridWithCsn.rid;
      },
    }),
    [],
  );

  useEffect(() => {
    const fetchDeals = async () => {
      try {
        setStatus('loading');
        // @ts-expect-error
        const rid = await formatSearch[findBy](search);

        const query = new Query().setQuery(
          new Contains('RetBag', dealContext?.deal.id),
        );

        const dropships = dropshipsDatastore.query(query);
        const bags = bagsDatastore.query(query);

        const [bagDeals, dropshipDeals] = await Promise.all([bags, dropships]);

        const allDeals = [...bagDeals, ...dropshipDeals];

        allDeals.forEach((item) => {
          item.RetBag = JSON.parse(item.RetBag);
        });

        const tempDeals = allDeals.filter((item) => {
          const deal = item.RetBag.filter((i: any) => i.rid === rid);
          return !!deal.length;
        });

        await Promise.all(
          tempDeals.map(async (item) => {
            const deal =
              item.ProgramName === 'Dropship'
                ? await new ApiClientsDropships().getById(item.Id)
                : await new ApiClientsBags().getById(item.Id);
            const quantity = deal.positions.reduce((prev, cur) => {
              if (cur.article === rid) {
                return prev + +cur.quantity;
              }
              return prev;
            }, 0);
            item.quantity = quantity;
          }),
        );

        console.log('tempDeals', tempDeals);
        setDeals(tempDeals);
        setStatus('loaded');
      } catch (e) {
        setError(errorToString(e));
        setStatus('error');
      }
    };

    fetchDeals();
  }, [search, findBy]);

  if (!search) {
    return null;
  }

  if (status === 'error') {
    return <h3>{error}</h3>;
  }

  if (status === 'loading') {
    return <h3>Loading...</h3>;
  }

  if (deals.length === 0) {
    return <h3>No deals found</h3>;
  }

  return (
    <TableContainer component={Paper} style={{ marginTop: 10 }}>
      <Table style={{ minWidth: '100%' }}>
        <TableHead className="">
          <TableRow>
            <TableCell scope="col">{findBy}</TableCell>
            <TableCell scope="col">Deal link</TableCell>
            <TableCell scope="col">Quantity</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="">
          {deals.map((deal) => (
            <TableRow key={deal.id}>
              <TableCell className="align-middle" scope="row">
                {search}
              </TableCell>
              <TableCell scope="row" className="link-primary align-middle">
                <Link
                  to={`/crm/deals/${
                    deal.ProgramName === 'Dropship' ? 'dropships' : 'bags'
                  }/${deal.Id}/`}
                >
                  {deal.Id}
                </Link>
              </TableCell>
              <TableCell className="align-middle" scope="row">
                {deal.quantity}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
