/* tslint:disable */
/* eslint-disable */
/**
 * RollunSecretManager
 * Service for creating automation
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BadRequestDto
 */
export interface BadRequestDto {
    /**
     * 
     * @type {number}
     * @memberof BadRequestDto
     */
    'statusCode': BadRequestDtoStatusCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof BadRequestDto
     */
    'error': BadRequestDtoErrorEnum;
}

export const BadRequestDtoStatusCodeEnum = {
    NUMBER_400: 400
} as const;

export type BadRequestDtoStatusCodeEnum = typeof BadRequestDtoStatusCodeEnum[keyof typeof BadRequestDtoStatusCodeEnum];
export const BadRequestDtoErrorEnum = {
    BadRequest: 'Bad Request'
} as const;

export type BadRequestDtoErrorEnum = typeof BadRequestDtoErrorEnum[keyof typeof BadRequestDtoErrorEnum];

/**
 * 
 * @export
 * @interface CreateSecretBody
 */
export interface CreateSecretBody {
    /**
     * Secret ID
     * @type {string}
     * @memberof CreateSecretBody
     */
    'id': string;
    /**
     * Secret value
     * @type {string}
     * @memberof CreateSecretBody
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface InternalServerErrorDto
 */
export interface InternalServerErrorDto {
    /**
     * 
     * @type {number}
     * @memberof InternalServerErrorDto
     */
    'statusCode': InternalServerErrorDtoStatusCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof InternalServerErrorDto
     */
    'message': InternalServerErrorDtoMessageEnum;
}

export const InternalServerErrorDtoStatusCodeEnum = {
    NUMBER_500: 500
} as const;

export type InternalServerErrorDtoStatusCodeEnum = typeof InternalServerErrorDtoStatusCodeEnum[keyof typeof InternalServerErrorDtoStatusCodeEnum];
export const InternalServerErrorDtoMessageEnum = {
    InternalServerError: 'Internal Server Error'
} as const;

export type InternalServerErrorDtoMessageEnum = typeof InternalServerErrorDtoMessageEnum[keyof typeof InternalServerErrorDtoMessageEnum];

/**
 * 
 * @export
 * @interface SecretDto
 */
export interface SecretDto {
    /**
     * Secret ID
     * @type {string}
     * @memberof SecretDto
     */
    'id': string;
    /**
     * Secret value
     * @type {string}
     * @memberof SecretDto
     */
    'value': string;
    /**
     * Secret version
     * @type {number}
     * @memberof SecretDto
     */
    'version': number;
    /**
     * Secret created at
     * @type {string}
     * @memberof SecretDto
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface SecretWithoutValueDto
 */
export interface SecretWithoutValueDto {
    /**
     * Secret ID
     * @type {string}
     * @memberof SecretWithoutValueDto
     */
    'id': string;
    /**
     * Secret version
     * @type {number}
     * @memberof SecretWithoutValueDto
     */
    'version': number;
    /**
     * Secret created at
     * @type {string}
     * @memberof SecretWithoutValueDto
     */
    'createdAt': string;
}

/**
 * SecretsApi - axios parameter creator
 * @export
 */
export const SecretsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create secret
         * @summary Create secret
         * @param {CreateSecretBody} createSecretBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secretsControllerCreateSecret: async (createSecretBody: CreateSecretBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSecretBody' is not null or undefined
            assertParamExists('secretsControllerCreateSecret', 'createSecretBody', createSecretBody)
            const localVarPath = `/secrets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSecretBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all secrets
         * @summary Get all secrets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secretsControllerGetAllSecrets: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/secrets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all versions of secret by id
         * @summary Get all versions of secret by id
         * @param {string} id Secret ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secretsControllerGetAllVersionsOfSecretById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('secretsControllerGetAllVersionsOfSecretById', 'id', id)
            const localVarPath = `/secrets/versions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get secret by id
         * @summary Get secret by id (latest version)
         * @param {string} id Secret ID
         * @param {string} [version] Secret version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secretsControllerGetSecretById: async (id: string, version?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('secretsControllerGetSecretById', 'id', id)
            const localVarPath = `/secrets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SecretsApi - functional programming interface
 * @export
 */
export const SecretsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SecretsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create secret
         * @summary Create secret
         * @param {CreateSecretBody} createSecretBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async secretsControllerCreateSecret(createSecretBody: CreateSecretBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecretDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.secretsControllerCreateSecret(createSecretBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all secrets
         * @summary Get all secrets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async secretsControllerGetAllSecrets(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SecretWithoutValueDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.secretsControllerGetAllSecrets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all versions of secret by id
         * @summary Get all versions of secret by id
         * @param {string} id Secret ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async secretsControllerGetAllVersionsOfSecretById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SecretWithoutValueDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.secretsControllerGetAllVersionsOfSecretById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get secret by id
         * @summary Get secret by id (latest version)
         * @param {string} id Secret ID
         * @param {string} [version] Secret version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async secretsControllerGetSecretById(id: string, version?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecretDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.secretsControllerGetSecretById(id, version, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SecretsApi - factory interface
 * @export
 */
export const SecretsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SecretsApiFp(configuration)
    return {
        /**
         * Create secret
         * @summary Create secret
         * @param {CreateSecretBody} createSecretBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secretsControllerCreateSecret(createSecretBody: CreateSecretBody, options?: any): AxiosPromise<SecretDto> {
            return localVarFp.secretsControllerCreateSecret(createSecretBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all secrets
         * @summary Get all secrets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secretsControllerGetAllSecrets(options?: any): AxiosPromise<Array<SecretWithoutValueDto>> {
            return localVarFp.secretsControllerGetAllSecrets(options).then((request) => request(axios, basePath));
        },
        /**
         * Get all versions of secret by id
         * @summary Get all versions of secret by id
         * @param {string} id Secret ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secretsControllerGetAllVersionsOfSecretById(id: string, options?: any): AxiosPromise<Array<SecretWithoutValueDto>> {
            return localVarFp.secretsControllerGetAllVersionsOfSecretById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get secret by id
         * @summary Get secret by id (latest version)
         * @param {string} id Secret ID
         * @param {string} [version] Secret version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secretsControllerGetSecretById(id: string, version?: string, options?: any): AxiosPromise<SecretDto> {
            return localVarFp.secretsControllerGetSecretById(id, version, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SecretsApi - object-oriented interface
 * @export
 * @class SecretsApi
 * @extends {BaseAPI}
 */
export class SecretsApi extends BaseAPI {
    /**
     * Create secret
     * @summary Create secret
     * @param {CreateSecretBody} createSecretBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public secretsControllerCreateSecret(createSecretBody: CreateSecretBody, options?: AxiosRequestConfig) {
        return SecretsApiFp(this.configuration).secretsControllerCreateSecret(createSecretBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all secrets
     * @summary Get all secrets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public secretsControllerGetAllSecrets(options?: AxiosRequestConfig) {
        return SecretsApiFp(this.configuration).secretsControllerGetAllSecrets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all versions of secret by id
     * @summary Get all versions of secret by id
     * @param {string} id Secret ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public secretsControllerGetAllVersionsOfSecretById(id: string, options?: AxiosRequestConfig) {
        return SecretsApiFp(this.configuration).secretsControllerGetAllVersionsOfSecretById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get secret by id
     * @summary Get secret by id (latest version)
     * @param {string} id Secret ID
     * @param {string} [version] Secret version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public secretsControllerGetSecretById(id: string, version?: string, options?: AxiosRequestConfig) {
        return SecretsApiFp(this.configuration).secretsControllerGetSecretById(id, version, options).then((request) => request(this.axios, this.basePath));
    }
}


