import React, { FC, useState } from 'react';
import { SHIPMENT_TYPE_WAREHOUSE, useDoneProcessingBag } from '../hooks';
import { Box, Grid, LinearProgress, Typography } from '@material-ui/core';
import MuiButton from '../../../UI/MuiButton';
import AddIcon from '@material-ui/icons/Add';
import BoxCard from './BoxCard';
import { ErrorView } from '../../../UI';
import { randomString } from 'rollun-ts-utils/dist';

export type Dimension = {
  weight: string;
  length: string;
  width: string;
  height: string;
};

interface SetDimensionsProps {
  bagId?: string;
}

const SetDimensions: FC<SetDimensionsProps> = ({ bagId }) => {
  const [
    { result, status, error },
    handleDoneProcessingBag,
    handleDoneProcessingNoNeedBag,
  ] = useDoneProcessingBag(bagId);
  const [boxes, setBoxes] = useState<
    (Dimension & { id: string; isSubmitted: boolean })[]
  >([]);

  return (
    <Grid item xs={12}>
      {error ? (
        <ErrorView error={error} />
      ) : status === 'loading' ? (
        <LinearProgress />
      ) : result?.shipment_type === SHIPMENT_TYPE_WAREHOUSE ? (
        <MuiButton
          fullWidth
          color="success"
          onClick={handleDoneProcessingNoNeedBag}
        >
          Done
        </MuiButton>
      ) : (
        <>
          <Box marginBottom={1}>
            <Typography variant="h4" align="center">
              Current boxes added{' '}
              {boxes.filter((box) => box.isSubmitted).length}/{boxes.length}
            </Typography>
          </Box>
          <Box marginBottom={1}>
            <MuiButton
              color="success"
              fullWidth
              onClick={() =>
                setBoxes((prevBoxes) => [
                  ...prevBoxes,
                  {
                    id: randomString(20),
                    width: '',
                    height: '',
                    weight: '',
                    length: '',
                    isSubmitted: false,
                  },
                ])
              }
            >
              <Box marginRight={1}>
                <AddIcon />
              </Box>
              Add box
            </MuiButton>
          </Box>
          <Grid container spacing={2}>
            {boxes.map((box, index) => (
              <Grid item xs={12} key={box.id}>
                <BoxCard
                  id={box.id}
                  index={index}
                  onRemove={(index) => {
                    setBoxes((prevBoxes) =>
                      prevBoxes.filter((box) => box.id !== index),
                    );
                  }}
                  onSubmit={(dimension, index) => {
                    setBoxes((prevBoxes) =>
                      prevBoxes.map((box) => {
                        if (box.id === index) {
                          return {
                            ...box,
                            ...dimension,
                            isSubmitted: true,
                          };
                        }

                        return box;
                      }),
                    );
                  }}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <MuiButton
                color="primary"
                fullWidth
                disabled={
                  boxes.length === 0 ||
                  boxes.filter((box) => box.isSubmitted).length !== boxes.length
                }
                onClick={() => handleDoneProcessingBag(boxes)}
              >
                Done packing
              </MuiButton>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default SetDimensions;
