import React, { FC, useContext, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  LinearProgress,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import MuiButton from '../../../UI/MuiButton';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { Formik } from 'formik';
import * as yup from 'yup';
import ConfirmAfterItem from './ConfirmAfterItem';
import ReturnAppContext from '../context';
import HttpDatastore from 'rollun-ts-datastore/dist';
import { httpErrorHandlerPromised } from '../../../utils/common.utils';
import { isJSON, randomString } from 'rollun-ts-utils/dist';
import { useReturnsDatastore } from '../../../hooks/datastores';
import { ErrorView, Spinner } from '../../../UI';
import { Eq, Limit, Query } from 'rollun-ts-rql';
import { useStorageByLocation } from '../../../hooks/useStorageByLocation';
import { BarcodeScanner } from '../../../lib/barcode-scanner/BarcodeScanner';
import { logger } from '../../../utils/logger';
import { useQueryParamState } from '../../../hooks/useQueryParamState';

const useStyles = makeStyles(() => ({
  video: {
    '& video, img': {
      maxWidth: 300,
    },
  },
}));

interface WasNotFoundProps {
  type: 'item' | 'label';
}

const ProblemSchema = yup.object().shape({
  problem: yup.string().min(1).max(255).required(),
});

const UnidentifiedReturnsItemsDatastore = new HttpDatastore(
  '/api/datastore/UnidentifiedReturnsItems',
);
const UnidentifiedReturnsDatastore = new HttpDatastore(
  '/api/datastore/UnidentifiedReturns',
);
const ReturnsListDatastore = new HttpDatastore('/api/datastore/ReturnsList');

const WasNotFound: FC<WasNotFoundProps> = ({ type }) => {
  const [cameraError, setCameraError] = useState('');
  const [error, setError] = useState('');
  const [image, setImage] = useState('');
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [closed, setClosed] = useState(true);
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const [isSubmittedItem, setIsSubmittedItem] = useState(false);
  const getStorageByLocation = useStorageByLocation();
  const [
    { result, error: httpError, status },
    dispatch,
  ] = useReturnsDatastore();
  const classes = useStyles();
  const context = useContext(ReturnAppContext);
  const [
    processingRid,
    setProcessingRid,
    removeProcessingRid,
  ] = useQueryParamState<string>('processingRid');

  useEffect(() => {
    if (type === 'item') {
      dispatch(
        'QUERY',
        new Query({
          query: new Eq('track_number', context.label),
          limit: new Limit(1),
        }),
      );
    }
  }, [type]);

  const handleCameraError = (error: Error) => {
    if (error.message.includes("'getSupportedConstraints' of undefined")) {
      setCameraError('Cannot use camera in unsecure environment');
      return;
    }

    setCameraError(error.message);
  };

  const handleTakePhoto = (image: string) => {
    setImage(image);
    if (type === 'item') {
      setIsScannerOpen(true);
    } else {
      setIsSubmittedItem(true);
    }
  };

  const handleSubmitLabel = async () => {
    const label = randomString(20);

    await UnidentifiedReturnsDatastore.create({
      track_number: label,
      photo: image,
    });

    context.setLabel(label, 'foundLabel');
  };

  const handleSubmitItem = async (problem: string, code: string) => {
    await UnidentifiedReturnsItemsDatastore.create({
      track_number: context.label,
      photo: image,
      sr_item_identifier: randomString(15),
      sr_item_identifier_type: '',
      status: problem,
      storage: await getStorageByLocation(context.location),
      id: code,
      supplier: context.supplier,
      available: 'yes',
    });
    logger.info('WasNotFoundTest context', {
      context,
    });
    await ReturnsListDatastore.update({
      track_number: context.label,
      status: 'processed',
    });
    logger.info('after ReturnsListDatastore in WasNotFoundTest', {
      context,
    });

    const [label] = await ReturnsListDatastore.query(
      new Query().setQuery(new Eq('track_number', context.label)),
    );

    const expectedRidsRaw = label.expected_rid;
    const expectedRids: string | string[] = isJSON(expectedRidsRaw)
      ? JSON.parse(expectedRidsRaw)
      : expectedRidsRaw;

    console.log(expectedRidsRaw, expectedRids, processingRid);

    if (!Array.isArray(expectedRids)) {
      setClosed(false);
      return;
    }

    if (+processingRid < expectedRids.length - 1) {
      context.setPage('possibleItem');
      setProcessingRid((+processingRid + 1).toString());
      return;
    }

    if (+processingRid === expectedRids.length - 1) {
      removeProcessingRid();
    }

    setClosed(false);
  };

  const handleSubmitProduct = async (problem: string) => {
    setLoading(true);
    setError('');
    try {
      if (type === 'item') {
        if (!code) {
          return setError('Item code could not be empty');
        }

        await handleSubmitItem(problem, code);
        return;
      }
      await handleSubmitLabel();
    } catch (e) {
      const { text } = await httpErrorHandlerPromised(e);
      setError(`Could not post unidentified ${type}. Reason: ${text}`);
    }
    setClosed(false);
    setLoading(false);
  };

  if (status === 'loading') {
    return <Spinner />;
  }

  if (httpError) {
    return <ErrorView error={httpError} />;
  }

  const isItemDamaged =
    type !== 'label' &&
    !!result &&
    !!result[0] &&
    result[0].mp_return_reason === 'damaged';
  return (
    <Box>
      <Grid container spacing={1}>
        {isItemDamaged && (
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              This item has to be put in damaged inventory
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="body1" align="center" color="error">
            {error}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" align="center">
            Take a photo of the {type}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.video}>
          {image ? (
            <>
              <Box display="flex" justifyContent="center">
                <img src={image} alt="test" />
              </Box>
              <Box marginTop={1}>
                <MuiButton
                  fullWidth
                  color="warning"
                  onClick={() => setImage('')}
                >
                  Retake
                </MuiButton>
              </Box>
            </>
          ) : null}
          <Box maxWidth={500} display={image.length !== 0 ? 'none' : ''}>
            <Camera
              isDisplayStartCameraError={false}
              idealFacingMode="environment"
              isImageMirror={false}
              onTakePhoto={(dataUri) => handleTakePhoto(dataUri)}
              onCameraError={(error) => handleCameraError(error)}
            />
          </Box>
        </Grid>
        {isScannerOpen ? (
          <Grid item xs={12}>
            <TextField
              name="code"
              fullWidth
              variant="outlined"
              label="Item code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <Box marginTop={1}>
              <MuiButton
                type="submit"
                fullWidth
                onClick={() => {
                  if (code.length > 0) {
                    setIsSubmittedItem(true);
                  }
                }}
              >
                Submit code
              </MuiButton>
            </Box>
            <BarcodeScanner onResult={(code: string) => setCode(code)} />
          </Grid>
        ) : null}
        {cameraError ? (
          <Grid item xs={12}>
            <Typography align="center" variant="h4" color="error">
              {cameraError}
            </Typography>
          </Grid>
        ) : null}
        {type === 'label' ? (
          <Grid item xs={12}>
            <MuiButton
              disabled={image.length === 0}
              fullWidth
              color="primary"
              onClick={() => handleSubmitProduct('')}
            >
              Process items
            </MuiButton>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Box marginBottom={1}>
              <MuiButton
                disabled={
                  image.length === 0 ||
                  loading ||
                  !isSubmittedItem ||
                  isItemDamaged
                }
                fullWidth
                color="primary"
                onClick={() => handleSubmitProduct('new')}
              >
                Product is new
              </MuiButton>
            </Box>
            <Formik
              initialValues={{
                problem: '',
              }}
              onSubmit={(values) => handleSubmitProduct(values.problem)}
              validationSchema={ProblemSchema}
            >
              {({ handleChange, handleSubmit, values, handleBlur }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        disabled={image.length === 0}
                        placeholder="Describe problem"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.problem}
                        fullWidth
                        name="problem"
                        type="text"
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MuiButton
                        color="error"
                        type="submit"
                        fullWidth
                        disabled={
                          values.problem.length === 0 ||
                          loading ||
                          !isSubmittedItem
                        }
                      >
                        Submit problem
                      </MuiButton>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
            <ConfirmAfterItem
              isOpen={!closed}
              onClose={() => {
                context.setPage('returnLabels');
                setClosed(true);
              }}
              handleNotOk={() => context.setPage('returnLabels')}
              handleOk={() => context.setPage('foundLabel')}
            />
          </Grid>
        )}
        {loading && (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default WasNotFound;
