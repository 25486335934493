import HttpDatastore from 'rollun-ts-datastore';
import {
  Dropship,
  DropshipDTO,
  Position,
  Client,
  SPECIAL_ITEMS_IDS,
  GetByQueryOptions,
} from './types';
import ApiClientsPositions from './api-clients-position';
import { Query, Eq, In } from 'rollun-ts-rql';
import ApiClients from './api-clients-clients';
import { compareStringNumbers } from '../common/utils/compareStringNumbers';
import _ from 'lodash';

const datastore = new HttpDatastore<DropshipDTO>(
  '/api/datastore/DropshipsDataStore',
);

export default class ApiClientsDropships {
  apiPositions: ApiClientsPositions;
  apiClients: ApiClients;

  constructor() {
    this.apiPositions = new ApiClientsPositions();
    this.apiClients = new ApiClients();
  }

  async getById(id: string): Promise<Dropship> {
    const dropshipDTO = await datastore.read(id);

    if (!dropshipDTO) {
      throw new Error(`Dropship with id ${id} not found`);
    }

    const positions = this.apiPositions.getByDealId(id);
    const client = dropshipDTO.Contractor
      ? this.apiClients.getById(dropshipDTO.Contractor)
      : ((null as unknown) as Client);

    const order = await Promise.all([positions, client]).then((data) => {
      return this.convertDTOtoModel(dropshipDTO, ...data);
    });

    return order;
  }

  async getByMpOrderNumber(mpOrderNumber: string): Promise<Dropship | null> {
    const dropships = await datastore.query(
      new Query().setQuery(new Eq('MpOrderNumber', mpOrderNumber)),
    );

    const [dropshipDTO] = dropships.filter(
      (dropship) =>
        (dropship.StatusName === 'Archive' &&
          dropship.ArchiveScenario !== 'wrong' &&
          dropship.ArchiveScenario !== 'paid_not_shipped') ||
        dropship.StatusName !== 'Archive',
    );

    if (!dropshipDTO) {
      return null;
    }

    const positions = this.apiPositions.getByDealId(dropshipDTO.Id);
    const client = dropshipDTO.Contractor
      ? this.apiClients.getById(dropshipDTO.Contractor)
      : ((null as unknown) as Client);

    const order = await Promise.all([positions, client]).then((data) => {
      return this.convertDTOtoModel(dropshipDTO, ...data);
    });

    return order;
  }

  async getByQuery(
    query: Query,
    options?: GetByQueryOptions,
  ): Promise<Dropship[]> {
    const dropshipsDTO = await datastore.query(query);

    if (!dropshipsDTO.length) return [];

    const dealIds = dropshipsDTO.map((PickupDTO) => PickupDTO.Id);
    const dealIdsChunks = _.chunk(dealIds, 100);

    const clientIds = dropshipsDTO
      .map((PickupDTO) => PickupDTO.Contractor)
      .filter(Boolean);
    const clientIdsChunks = _.chunk(clientIds, 100);

    const positions = options?.excludePositions
      ? []
      : (
          await Promise.all(
            dealIdsChunks.map((dealIdsChunks) =>
              this.apiPositions.getByQuery(
                new Query().setQuery(new In('DealId', dealIdsChunks)),
              ),
            ),
          )
        ).flat();

    const clients = (
      await Promise.all(
        clientIdsChunks.map((clientChukn) =>
          this.apiClients.getByQuery(
            new Query().setQuery(new In('Id', clientChukn)),
          ),
        ),
      )
    ).flat();

    return dropshipsDTO.map((PickupDTO) => {
      const dealPositions = positions.filter(
        (position) => position.dealId === PickupDTO.Id,
      );
      const client = clients.find(
        (client) => client.id === PickupDTO.Contractor,
      );
      return this.convertDTOtoModel(PickupDTO, dealPositions, client as Client);
    });
  }

  async deleteItems(id: string, itemIds: string[]): Promise<void> {
    await Promise.all(
      itemIds.map((itemId) => this.apiPositions.delete(id, itemId)),
    );
  }

  async create(data: Omit<Dropship, 'id'>): Promise<Dropship> {
    const dropshipDTO = this.convertModelToDTO(data);
    const dropship = await datastore.create(dropshipDTO);

    if (data.positions) {
      await this.addItems(dropship.Id, data.positions);
    }

    return this.getById(dropship.Id);
  }

  async addItems(
    id: string,
    items: Omit<Position, 'offerId' | 'dealId' | 'id'>[],
  ): Promise<void> {
    for (const item of items) {
      await this.apiPositions.add(id, item);
    }
  }

  async update(id: string, data: Partial<Omit<Dropship, 'id' | 'positions'>>) {
    await datastore.update({
      ...this.convertModelToDTO(data),
      Id: id,
    });
  }

  async addOrUpdateItems(
    id: string,
    items: Omit<Position, 'offerId' | 'dealId' | 'id'>[],
  ) {
    const deal = await this.getById(id);
    const positions = deal.positions;

    const tempSortedItems: Position[] = [];

    for (let i = 0; i < positions.length; i++) {
      const position = { ...positions[i] };

      if (
        tempSortedItems.filter(
          (item) =>
            item.article === position.article &&
            compareStringNumbers(item.cost, position.cost) &&
            item.name === position.name,
        ).length
      )
        continue;

      tempSortedItems.push(position);

      const sameItems = items.filter(
        (item) =>
          item.article === position.article &&
          compareStringNumbers(item.cost, position.cost) &&
          item.name === position.name,
      );
      if (!sameItems.length) continue;

      const allQuantity = sameItems.reduce(
        (prev, cur) => prev + +cur.quantity,
        0,
      );
      position.quantity = +position.quantity + allQuantity;

      await this.apiPositions.update(position);
    }

    const filteredItems = items.filter((item) => {
      for (let i = 0; i < tempSortedItems.length; i++) {
        const position = { ...tempSortedItems[i] };
        if (
          item.article === position.article &&
          compareStringNumbers(item.cost, position.cost) &&
          item.name === position.name
        )
          return false;
      }
      return true;
    });

    if (filteredItems.length) await this.addItems(id, filteredItems);
  }

  convertDTOtoModel(
    dropshipDTO: DropshipDTO,
    positions: Position[],
    client: Client,
  ): Dropship {
    const no_special_items_positions = positions.filter(
      (position) => !SPECIAL_ITEMS_IDS.includes(position.article),
    );

    return {
      id: dropshipDTO.Id,
      name: dropshipDTO.Name,
      status: dropshipDTO.Status,
      timeCreated: dropshipDTO.TimeCreated,
      timeUpdated: dropshipDTO.TimeUpdated,
      mpName: dropshipDTO.MpName,
      mpOrderNumber: dropshipDTO.MpOrderNumber,
      srName: dropshipDTO.SrName,
      srShipMethod: dropshipDTO.SrShipMethod,
      sender: dropshipDTO.Sender,
      enterTracknumber: dropshipDTO.EnterTracknumber,
      tracknumber: dropshipDTO.Tracknumber,
      owner: dropshipDTO.Owner,
      carrier: dropshipDTO.Carrier,
      positions,
      no_special_items_positions,
      statusName: dropshipDTO.StatusName,
      srPaymentCard: dropshipDTO.SrPaymentCard,
      srOrderStatus: dropshipDTO.SrOrderStatus,
      srOrderNumber: dropshipDTO.SrOrderNumber,
      retBag: dropshipDTO.RetBag,
      archiveScenario: dropshipDTO.ArchiveScenario,
      srVirtualName: dropshipDTO.SrVirtualName,
      total: dropshipDTO.Total,
      dateEnteringTracknumberIntoMp: dropshipDTO.DateEnteringTracknumberIntoMp,
      shipStatus: dropshipDTO.ShipStatus,
      additionalTracknumbers: dropshipDTO.AdditionalTracknumbers,
      dateLabelCreation: dropshipDTO.DateLabelCreation,
      client: client,
      contractor: dropshipDTO.Contractor,
      srReturnNumber: dropshipDTO.SrReturnNumber,
      relatedOrder: dropshipDTO.RelatedOrder,
      problemDescription: dropshipDTO.ProblemDescription,
    };
  }

  convertModelToDTO(
    dropship: Partial<Omit<Dropship, 'id' | 'positions' | 'client'>>,
  ): Partial<Omit<DropshipDTO, 'Id'>> {
    return {
      Name: dropship.name,
      Status: dropship.status,
      TimeCreated: dropship.timeCreated,
      TimeUpdated: dropship.timeUpdated,
      StatusName: dropship.statusName,
      MpName: dropship.mpName,
      MpOrderNumber: dropship.mpOrderNumber,
      SrName: dropship.srName,
      SrShipMethod: dropship.srShipMethod,
      Sender: dropship.sender,
      EnterTracknumber: dropship.enterTracknumber,
      Tracknumber: dropship.tracknumber,
      Owner: dropship.owner,
      Carrier: dropship.carrier,
      SrPaymentCard: dropship.srPaymentCard,
      SrOrderStatus: dropship.srOrderStatus,
      SrOrderNumber: dropship.srOrderNumber,
      RetBag: dropship.retBag,
      ArchiveScenario: dropship.archiveScenario,
      SrVirtualName: dropship.srVirtualName,
      Total: dropship.total,
      DateEnteringTracknumberIntoMp: dropship.dateEnteringTracknumberIntoMp,
      ShipStatus: dropship.shipStatus,
      AdditionalTracknumbers: dropship.additionalTracknumbers,
      DateLabelCreation: dropship.dateLabelCreation,
      SrReturnNumber: dropship.srReturnNumber,
      Contractor: dropship.contractor,
      RelatedOrder: dropship.relatedOrder,
      ProblemDescription: dropship.problemDescription,
    };
  }
}

export const apiClientsDropship = new ApiClientsDropships();
