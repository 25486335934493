import React, { Component } from 'react';
import Dialog from '../../../UI/Dialog';
import { Button } from '../../../UI';

interface IProps {
  problemTypes: Array<{ name: string; required?: boolean }>;
  onProblemSubmit: (report: { type: string; note: string }) => void;
  actionOnSpecificProblemType?: (type: string) => boolean;
}

interface IState {
  selectedIssue: string;
  issueNote: string;
  modalOpen: boolean;
}

class OrderProblemReport extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedIssue: this.props.problemTypes[0].name,
      issueNote: '',
      modalOpen: false,
    };
  }

  render() {
    const onSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
      if (this.props.actionOnSpecificProblemType) {
        if (this.props.actionOnSpecificProblemType(e.target.value)) {
          return;
        }
      }
      this.setState({ selectedIssue: e.target.value });
    };
    const onInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      this.setState({ issueNote: e.target.value });
    };

    const currentIssue = this.props.problemTypes.find(
      (prob) => prob.name === this.state.selectedIssue,
    );
    const required = currentIssue ? currentIssue.required : false;
    return (
      <div>
        <div>
          <div className="px-5 py-2">{this.props.children}</div>
          <select
            className="custom-select my-2"
            value={this.state.selectedIssue}
            onChange={onSelect}
          >
            {this.props.problemTypes.map(({ name }) => (
              <option key={name}>{name}</option>
            ))}
          </select>
          <textarea
            className="form-control mb-2"
            value={this.state.issueNote}
            onChange={onInput}
            cols={30}
            rows={2}
            maxLength={255}
            spellCheck={true}
            placeholder={
              'Issue note ' + (required ? '(required)' : '(optional)')
            }
          />
          <Button
            size="sm"
            block
            color="danger"
            disabled={this.state.issueNote === '' ? required === true : false}
            onClick={() => this.setState({ modalOpen: true })}
          >
            Send problem
          </Button>
          <Dialog
            title="Attention"
            options={{ centered: true }}
            isOpen={this.state.modalOpen}
            onDecline={() => this.setState({ modalOpen: false })}
            onClose={() => this.setState({ modalOpen: false })}
            onOk={() => {
              this.setState({ modalOpen: false });
              this.props.onProblemSubmit({
                type: this.state.selectedIssue,
                note: this.state.issueNote,
              });
            }}
            submitBtnText="Yes"
            declineBtnText="No"
          >
            <p>Check Your problem report:</p>
            <p>
              <b>Issue type:</b> {this.state.selectedIssue}
            </p>
            <p>
              <b>Issue Note:</b> {this.state.issueNote}
            </p>
            <div className="text-center">
              <b>Is it correct?</b>
            </div>
          </Dialog>
        </div>
      </div>
    );
  }
}

export default OrderProblemReport;
