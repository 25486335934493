import React, { Component } from 'react';
import { OrderType, ShippingLabel } from '../utils/types';
import HttpDatastore from 'rollun-ts-datastore';
import Spinner from '../../../UI/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from './Label';
import {
  LABEL_DID_NOT_RECEIVED,
  LABEL_PACKED,
  ORDER_PARTIALLY_PACKED,
  ORDER_STATUS_DONE,
} from '../constants';
import CurrentLabelGoods from './CurrentLabelGoods';
import { Button, ConfirmButton, ErrorView } from '../../../UI';
import { ErrorType, Senders } from '../../../utils/common.types';
import { httpErrorHandler } from '../../../utils/common.utils';
import updateBagPackageStatus from '../utils/UpdateBagPackageStatus';
import { QueryBuilder, Eq, In } from 'rollun-ts-rql';
import { BarcodeScanner } from '../../../lib/barcode-scanner/BarcodeScanner';
import { formatBarcode } from '../../ReturnApp/utils';
import axios from 'axios';
import { downloadDataWithContentType } from 'rollun-ts-utils';
import { logger } from '../../../utils/logger';
import { BarcodeInput } from '../../../UI/BarcodeInput';
import { alertError } from '../../Table/components/TableHeaderButtons/utils/alertError';

enum LabelAction {
  PENDING,
  LABELS_LIST,
  GOODS_LIST,
  NO_LABELS,
  ERROR,
}

interface IProps {
  orders: Array<{ id: string; supplier: string }>;
  somePartiallyPacked: boolean;
  senderName: Senders;

  onGoBack(): void;

  onLabelsProcessingComplete(): void;
}

interface IState {
  action: LabelAction;
  labels: Array<ShippingLabel>;
  currentLabel: null | ShippingLabel;
  error: ErrorType;
  hideProcessedLabels: boolean;
  inputBarcode: string;
  isLabelsLoading: boolean;
}

class Labels extends Component<IProps, IState> {
  state: IState = {
    action: LabelAction.LABELS_LIST,
    labels: [],
    currentLabel: null,
    error: { code: -1, text: '' },
    hideProcessedLabels: false,
    inputBarcode: '',
    isLabelsLoading: false,
  };

  labelsStore = new HttpDatastore<ShippingLabel>(
    `/api/datastore/shippingLabelDataStore`,
  );
  ordersStore: HttpDatastore = new HttpDatastore<OrderType>(
    `/api/datastore/unitedOrderDataStore`,
  );

  componentDidMount(): void {
    this.getLabels();
  }

  getLabels = () => {
    const { orders } = this.props;
    this.setState({ action: LabelAction.PENDING });

    this.labelsStore
      .query(
        new QueryBuilder()
          .from(
            new In(
              'united_order_id',
              orders.map(({ id }) => id),
            ),
          )
          .getQuery(),
      )
      .then((data) => {
        data.length > 0
          ? this.setState({
              labels: data as any,
              action: LabelAction.LABELS_LIST,
            })
          : this.setState({
              action: LabelAction.NO_LABELS,
              error: { code: -1, text: 'There are no labels in this order!' },
            });
      })
      .catch((error) =>
        httpErrorHandler(error, (code, text) =>
          this.setState({ action: LabelAction.ERROR, error: { code, text } }),
        ),
      );
  };

  _checkCurrentLabel(code: string) {
    const { labels } = this.state;

    const currentLabel = labels.find((item) => item.id === code);
    if (currentLabel) {
      const status = currentLabel.status ? currentLabel.status.trim() : '';
      if (status === LABEL_PACKED) {
        return this.setState({
          action: LabelAction.ERROR,
          error: { code: 0, text: 'This label is already processed' },
          inputBarcode: '',
        });
      }
      this.setState({
        currentLabel,
        action: LabelAction.GOODS_LIST,
        inputBarcode: '',
      });
    } else {
      this.setState({
        action: LabelAction.PENDING,
        inputBarcode: '',
        currentLabel: null,
      });
      this.labelsStore
        .query(new QueryBuilder().from(new Eq('id', code)).getQuery())
        .then((res: Array<any>) => {
          const text =
            res.length > 0
              ? `This label from ${res[0].united_order_id} order is not in current order(s)`
              : `This label does not exist in our data base!`;
          this.setState({
            action: LabelAction.NO_LABELS,
            error: { code: 0, text },
          });
        })
        .catch((err) =>
          httpErrorHandler(err, (code, text) =>
            this.setState({ action: LabelAction.ERROR, error: { code, text } }),
          ),
        );
    }
  }

  async handleLabelsDownload() {
    this.setState({ isLabelsLoading: true });
    for (const order of this.props.orders) {
      try {
        // TODO: move api calls to separate layer
        const result: {
          data: {
            data: {
              file: string;
            };
          };
        } = await axios.post(
          '/api/openapi/PDFMerger/v2/merged-by-sr-order-number',
          {
            srOrderNumber: order.id,
          },
        );
        const { file } = result.data.data;

        downloadDataWithContentType(
          Buffer.from(file, 'base64'),
          'application/pdf',
          `${order.id}.pdf`,
        );
      } catch (err) {
        logger.error('PDFMerger error', { err });
        alertError(err);
        console.error(err);
      }
    }
    this.setState({ isLabelsLoading: false });
  }

  // if at least one label has empty status -> false and true otherwise
  _allLabelsProcessed = (labels: Array<ShippingLabel>): boolean =>
    !labels.find((label) => !label.status || !label.status.trim());

  _renderLabels(
    orders: { id: string; supplier: string }[],
    labels: ShippingLabel[],
  ) {
    const { hideProcessedLabels, inputBarcode } = this.state;
    const input = inputBarcode.trim();
    return (
      <div className="d-flex flex-column">
        <div>
          <BarcodeInput
            onSubmit={(barcode) => {
              this._checkCurrentLabel(barcode);
            }}
            barcode={inputBarcode}
            onClear={() => {
              this.setState({ inputBarcode: '' });
            }}
            onChange={(barcode) => {
              this.setState({ inputBarcode: barcode });
            }}
          />
          <ConfirmButton
            onDecline={() => this.onOrdersComplete().then()}
            modalBody={
              <>
                <p>
                  You pressed "No more labels" but some labels from this order
                  aren`t scanned
                </p>
                <p>Do you have more labels?</p>
              </>
            }
            onClick={(modalOpenCallback) => {
              for (const label of this.state.labels) {
                if (!label.status) {
                  return modalOpenCallback();
                }
              }
              this.onOrdersComplete().then();
            }}
            declineBtnText="No, I don`t"
            submitBtnText="Yes, I have, let`s scan"
            className="btn-danger btn-block mt-2"
          >
            No more Labels
          </ConfirmButton>
          <Button
            style={{ marginTop: '7px' }}
            color="primary"
            className="w-100"
            disabled={this.state.isLabelsLoading}
            onClick={() => this.handleLabelsDownload()}
          >
            Download Labels
          </Button>
        </div>
        <div className="orders-layout">
          <h5>Labels to process:</h5>
          <Button
            color="primary"
            block
            onClick={() => {
              this.setState({ hideProcessedLabels: !hideProcessedLabels });
            }}
          >
            <FontAwesomeIcon
              flip={hideProcessedLabels ? 'vertical' : undefined}
              icon="arrow-alt-circle-up"
            />
          </Button>
          <div className="w-100">
            {orders.map((order) => (
              <div className="orders-layout" key={order.id}>
                <p className="font-weight-bold">Order #:{order.id}</p>
                {labels.map((label: any) => {
                  if (label.united_order_id === order.id) {
                    return (
                      <Label
                        key={label.id}
                        label={label}
                        hideProcessed={hideProcessedLabels}
                        inputLabel={input}
                        onLabelCopy={(id: string) =>
                          this.setState({ inputBarcode: id })
                        }
                        onLabelSubmit={(id: string) => {
                          this._checkCurrentLabel(id);
                        }}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  // Set order status
  // it doest not set status 'in progress', if status is 'done'
  setOrdersStatus = async (ordersNumbers: string[], status: string) => {
    const orders = await this.ordersStore.query(
      new QueryBuilder().from(new In('id', ordersNumbers)).getQuery(),
    );

    await Promise.allSettled([
      ...orders
        .filter((order) => order.status !== ORDER_STATUS_DONE)
        .map((order) => this.ordersStore.update({ id: order.id, status })),
      // update Bag(order) status in megaplan, if {status} is ORDER_STATUS_DONE
      ...(status === ORDER_STATUS_DONE
        ? [updateBagPackageStatus(ordersNumbers)]
        : []),
    ]);
  };

  onOrdersPartiallyPack = async () => {
    const packedOrders = [];
    const notPackedOrders = [];
    this.setState({ action: LabelAction.PENDING });
    for (const order of this.props.orders) {
      const labelsFromOrder = this.state.labels.filter(
        (label) => label.united_order_id === order.id,
      );
      if (this._allLabelsProcessed(labelsFromOrder)) {
        packedOrders.push(order.id);
      } else {
        notPackedOrders.push(order.id);
      }
    }
    try {
      if (packedOrders.length > 0) {
        await this.setOrdersStatus(packedOrders, ORDER_STATUS_DONE);
      }
      if (notPackedOrders.length > 0) {
        await this.setOrdersStatus(notPackedOrders, ORDER_PARTIALLY_PACKED);
      }
      this.props.onGoBack();
    } catch (e) {
      httpErrorHandler(e, (code, text) =>
        this.setState({ action: LabelAction.ERROR, error: { code, text } }),
      );
    }
  };

  onOrdersComplete = async () => {
    this.setState({ action: LabelAction.PENDING });
    try {
      await this.setOrdersStatus(
        this.props.orders.map(({ id }) => id),
        ORDER_STATUS_DONE,
      );
      const promises = this.state.labels
        .filter((label) => label.status === null || label.status.trim() === '')
        .map((label) =>
          this.labelsStore.update({
            id: label.id,
            status: LABEL_DID_NOT_RECEIVED,
          }),
        );
      await Promise.all(promises);
      this.props.onLabelsProcessingComplete();
    } catch (e) {
      httpErrorHandler(e, (code, text) =>
        this.setState({ action: LabelAction.ERROR, error: { code, text } }),
      );
    }
  };

  renderSwitch(action: LabelAction) {
    const { orders } = this.props;
    const { labels, currentLabel } = this.state;
    switch (action) {
      case LabelAction.PENDING:
        return (
          <div className="w-100 h-100 vh-50">
            <Spinner />
          </div>
        );
      case LabelAction.LABELS_LIST:
        return (
          <>
            <div className="mb-1 ml-3 w-100">
              <Button color="primary" onClick={this.props.onGoBack}>
                <FontAwesomeIcon icon="arrow-left" />
              </Button>
            </div>
            <h2>Scan label</h2>
            <div className="stripe bg-black">
              <BarcodeScanner
                onResult={(code: string) => {
                  this._checkCurrentLabel(code);
                }}
                resultFormatter={formatBarcode}
              />
            </div>
            <div>
              <div className="d-flex flex-column">
                {/* this is commented for now, why? have no idea */}
                {/* but this ticket might help tou understand https://trello.com/c/6f3M8cG0 */}
                {/*{!this._allLabelsProcessed(this.state.labels) &&*/}
                {/*!this.props.somePartiallyPacked ? (*/}
                {/*  <ConfirmButton*/}
                {/*    modalBody="Are You sure, that You want to pack current orders partially?"*/}
                {/*    submitBtnText="Yes"*/}
                {/*    declineBtnText="No"*/}
                {/*    className="btn-info btn-block"*/}
                {/*    onConfirm={() => this.onOrdersPartiallyPack().then()}*/}
                {/*  >*/}
                {/*    Pack partially*/}
                {/*  </ConfirmButton>*/}
                {/*) : null}*/}
                {this._renderLabels(orders, labels)}
              </div>
            </div>
          </>
        );
      case LabelAction.GOODS_LIST:
        const order = orders.find(
          (order) => order.id === currentLabel?.united_order_id,
        )!;

        return (
          <>
            {currentLabel ? (
              <CurrentLabelGoods
                order={order}
                onLabelProcessed={this.onLabelProcessed}
                senderName={this.props.senderName}
                currentLabel={currentLabel}
              />
            ) : null}
          </>
        );
      case LabelAction.NO_LABELS:
      case LabelAction.ERROR:
        return (
          <ErrorView error={this.state.error}>
            <div className="d-flex justify-content-center">
              <Button color="primary" block onClick={() => this.getLabels()}>
                Try again
              </Button>
            </div>
          </ErrorView>
        );
      default:
        return <div>Unknown action: [{LabelAction[action]}]</div>;
    }
  }

  onLabelProcessed = (label?: string, status?: string) => {
    this.setState(({ labels }) => ({
      action: LabelAction.LABELS_LIST,
      labels:
        label && status
          ? labels.map((currLabel) =>
              currLabel.id === label ? { ...currLabel, status } : currLabel,
            )
          : labels,
    }));
  };

  render() {
    const { action } = this.state;
    return <div className="main-layout">{this.renderSwitch(action)}</div>;
  }
}

export default Labels;
