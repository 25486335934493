import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import MuiButton from '../../../UI/MuiButton';
import MuiTooltip from '../../../UI/MuiTooltip';
import SubmitItem from './SubmitItem';
import ReturnAppContext from '../context';
import { Eq, Limit, Query, Select } from 'rollun-ts-rql';
import _ from 'lodash';
import {
  useCatalogDatastore,
  CatalogItem,
  DimensionItem,
  useDimensionDatastore,
  useReturnsDatastore,
} from '../../../hooks/datastores';
import FullScreenImageOnClick from './FullScreenImageOnClick';

export type ReturnItem = CatalogItem & DimensionItem;

const ItemWasFound = () => {
  const [catalogData, catalogDispatch] = useCatalogDatastore();
  const [dimensionData, dimensionDispatch] = useDimensionDatastore();
  const [returnData, returnDispatch] = useReturnsDatastore();
  const [items, setItems] = useState<ReturnItem[]>([]);
  const [currentItem, setCurrentItem] = useState<ReturnItem | null>(null);
  const context = useContext(ReturnAppContext);

  const getReturnInfo = async () => {
    return returnDispatch(
      'QUERY',
      new Query({
        query: new Eq('track_number', context.label),
        limit: new Limit(1),
      }),
    );
  };

  const getItemsDimensions = async (rid: string) => {
    try {
      return dimensionDispatch(
        'QUERY',
        new Query({
          query: new Eq('id', rid),
          select: new Select([
            'id',
            'width',
            'height',
            'length',
            'weight',
            'image',
            'title',
          ]),
        }),
      );
    } catch (e) {
      return [];
    }
  };

  const getItemsByRollunId = async (rid: string) => {
    try {
      return catalogDispatch(
        'QUERY',
        new Query({
          query: new Eq('id', rid),
          select: new Select(['id', 'brand_id', 'manufacturer_part_number']),
        }),
      );
    } catch (e) {
      return [];
    }
  };

  const formatDimensions = ({ width, height, length, weight }: ReturnItem) => {
    return `${width || 'no width'} * ${height || 'no height'} * ${
      length || 'no length'
    } * ${weight || 'no weight'}`;
  };

  useEffect(() => {
    const getItemsByTrackNumber = async () => {
      const [dimensions, rollunInfo] = await Promise.all([
        getItemsDimensions(context.item),
        getItemsByRollunId(context.item),
        getReturnInfo(),
      ]);

      setItems(_.merge(dimensions, rollunInfo) as ReturnItem[]);
    };

    getItemsByTrackNumber();
  }, []);

  const handleThisItem = (item: any) => {
    setCurrentItem(item);
  };

  if (
    catalogData.status === 'loading' ||
    dimensionData.status === 'loading' ||
    returnData.status === 'loading'
  ) {
    return (
      <Box marginTop={1}>
        <LinearProgress />
      </Box>
    );
  }

  if (catalogData.error || dimensionData.error || returnData.error) {
    return (
      <Typography id="error" variant="h5" align="center" color="error">
        {catalogData.error?.text ||
          dimensionData.error?.text ||
          returnData.error?.text}
      </Typography>
    );
  }

  if (items.length === 0) {
    return (
      <Typography variant="h5" align="center" color="error">
        There was no items with this id
      </Typography>
    );
  }
  const isItemDamaged =
    !!returnData.result &&
    !!returnData.result[0] &&
    returnData.result[0].mp_return_reason === 'damaged';

  return (
    <Box marginBottom={4}>
      {isItemDamaged && (
        <Box marginBottom={1}>
          <Typography variant="h4" align="center">
            This item has to be put in damaged inventory
          </Typography>
        </Box>
      )}
      <Box marginBottom={1}>
        <MuiButton
          fullWidth
          onClick={() => context.setPage('notFoundItem')}
          color="error"
        >
          Not found item
        </MuiButton>
      </Box>
      {currentItem ? (
        <SubmitItem item={currentItem} isDamaged={isItemDamaged} />
      ) : (
        <Grid container spacing={1}>
          {items.map((item) => (
            <Grid item xs={12} key={item.id}>
              <Card elevation={3}>
                <CardActionArea>
                  <FullScreenImageOnClick src={item.image} />
                  <CardContent>
                    <Typography variant="body1">
                      Rollun ID: {item.id}
                    </Typography>
                    <Typography variant="body1">
                      Brand: {item.brand_id || '-'}
                    </Typography>
                    <Typography variant="body1">
                      Mpn: {item.manufacturer_part_number || '-'}
                    </Typography>
                    <Typography variant="body1">
                      Title: {item.title || '-'}
                    </Typography>
                    <MuiTooltip title="Dimensions: width * height * length * weight">
                      <Typography variant="body1">
                        Dimensions: {formatDimensions(item)}
                      </Typography>
                    </MuiTooltip>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <MuiButton
                    color="success"
                    fullWidth
                    onClick={() => handleThisItem(item)}
                  >
                    This item
                  </MuiButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default ItemWasFound;
