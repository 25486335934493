import React, { Component } from 'react';
import AgreementLink from './AgreementLink';
import GenerateRefreshToken from './GenerateRefreshToken';

interface IState {
  redirectUri: string;
  clientId: string;
  clientSecret: string;
}

interface IProps {
  code?: string | null;
  // link, needed to get access to your reseller account from dev app
  agreementLink: string;
}

class EbayRefreshTokenContainer extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      redirectUri: localStorage.getItem('ebay_last__redirectUri') || '',
      clientId: localStorage.getItem('ebay_last__clientId') || '',
      clientSecret: localStorage.getItem('ebay_last__clientSecret') || '',
    };
  }

  handleCredentialsChange = (key: string, value: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    this.setState({ [key]: value });
    // cache to local store
    localStorage.setItem('ebay_last__' + key, value);
  };

  render() {
    const { redirectUri, clientSecret, clientId } = this.state;
    const { code } = this.props;
    return (
      <div className="m-2">
        <div className="m-2">
          <h1 className="mt-2">
            Get new OAuth2 refresh token for Ebay REST API
          </h1>
          <h4 className="mt-2">
            Note: if token is not shown after visiting generated link and
            logging in account, the reason might me not using american VPN
          </h4>
          <h5 className="mt-2">
            Enter{' '}
            <a href="https://developer.ebay.com/api-docs/static/oauth-redirect-uri.html">
              Client ID
            </a>
          </h5>
          <input
            type="text"
            name="clientId"
            className="form-control"
            value={clientId}
            placeholder="Client ID"
            onChange={(e) =>
              this.handleCredentialsChange(e.target.name, e.target.value)
            }
          />
          <h5 className="mt-2">
            Enter{' '}
            <a href="https://developer.ebay.com/api-docs/static/oauth-credentials.html">
              Client Secret
            </a>
          </h5>
          <input
            type="text"
            name="clientSecret"
            className="form-control"
            value={clientSecret}
            placeholder="Client Secret"
            onChange={(e) =>
              this.handleCredentialsChange(e.target.name, e.target.value)
            }
          />
          <h5 className="mt-2">
            Enter{' '}
            <a href="https://developer.ebay.com/api-docs/static/oauth-credentials.html">
              Ebay Redirect URI
            </a>
          </h5>
          <input
            type="text"
            name="redirectUri"
            className="form-control"
            value={redirectUri}
            placeholder="Redirect URI"
            onChange={(e) =>
              this.handleCredentialsChange(e.target.name, e.target.value)
            }
          />
        </div>
        {redirectUri && clientSecret && clientId && (
          <AgreementLink
            redirectUri={redirectUri}
            clientId={clientId}
            clientSecret={clientSecret}
          />
        )}
        {code && (
          <GenerateRefreshToken
            code={code}
            redirectUri={redirectUri}
            clientId={clientId}
            clientSecret={clientSecret}
          />
        )}
      </div>
    );
  }
}

export default EbayRefreshTokenContainer;
