import React from 'react';
import { WebHookConfig } from '../../../../Table/components/webHookActivator/WebhookActivator';
import _ from 'lodash/fp';
import Card from '@material-ui/core/Card';
import { useTheme } from '@material-ui/core';
import { Box, Typography } from '@material-ui/core';
import MuiButton from '../../../../../UI/MuiButton';
import WebhookEdit from './Webhook.edit';

interface IProps {
  hooks?: Array<WebHookConfig>;
  // update full webhooks array, or particular element by path (using lodash _.update)
  // if {path} is not specified, full object {hooks} will be replaced with new hooks.
  // if not, value by relative path will be replaced with {hooks}.
  onHooksUpdate: (
    hooks: Array<WebHookConfig> | string | boolean | null,
    path?: string,
  ) => void;
}

const WebHooksEdit: React.FC<IProps> = ({ hooks, onHooksUpdate }) => {
  const theme = useTheme();

  const swapHooks = (idx1: number, idx2: number) => {
    if (hooks) {
      const newHooks = _.cloneDeep(hooks);
      [newHooks[idx1], newHooks[idx2]] = [newHooks[idx2], newHooks[idx1]];
      onHooksUpdate(newHooks);
    }
  };

  const updateHook = () => {
    onHooksUpdate(
      (hooks || []).concat({
        name: 'WebHook',
        url: '/path-does-not-exist',
      }),
    );
  };

  return (
    <Card style={{ padding: theme.spacing(1) }}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">WebHooks: </Typography>
        <MuiButton color="success" onClick={updateHook}>
          Add
        </MuiButton>
      </Box>
      {!hooks || hooks.length === 0 ? (
        <Typography variant="h6">No WebHooks</Typography>
      ) : (
        <Box display="flex" style={{ gap: theme.spacing(1) }}>
          {hooks.map((hook, idx, array) => (
            <WebhookEdit
              index={idx}
              hooks={array}
              config={hook}
              swapHooks={swapHooks}
              onHooksUpdate={onHooksUpdate}
              totalLength={array.length}
            />
          ))}
        </Box>
      )}
    </Card>
  );
};

export default React.memo(
  WebHooksEdit,
  (prevProps: IProps, nextProps: IProps) => {
    console.log(
      'check equal',
      prevProps,
      nextProps,
      _.isEqual(prevProps.hooks, nextProps.hooks),
    );
    return _.isEqual(prevProps.hooks, nextProps.hooks);
  },
);
