import React, { Component } from 'react';
import BarcodeScannerContainer from './BarcodeApp';
import { Card } from '../../../UI';
import { Senders } from '../../../utils/common.types';

interface IState {
  senderName: Senders | null;
}

interface IProps {
  displayName: string;
}

class SelectSender extends Component<IProps, IState> {
  state: IState = { senderName: null };

  handleSupplierSelect = (supplierName: Senders) => {
    this.setState({ senderName: supplierName });
  };

  handleSupplierReset = () => {
    this.setState({ senderName: null });
  };

  render() {
    const { displayName } = this.props;
    const { senderName } = this.state;
    if (senderName) {
      return (
        <BarcodeScannerContainer
          onSupplierReset={this.handleSupplierReset}
          senderName={senderName}
          displayName={displayName}
        />
      );
    }

    return (
      <div className="d-flex justify-content-center">
        <div style={{ maxWidth: 300 }}>
          <h2 className="mb-4">Select your sender</h2>
          {Object.values(Senders)
            .filter(
              (sender) => sender !== 'pickup_tx' && sender !== 'pickup_wi',
            )
            .map((name) => (
              <Card
                key={name}
                style={{ height: 100 }}
                className="cursor-pointer d-flex justify-content-center flex-column align-items-center"
                onClick={() => this.handleSupplierSelect(name)}
              >
                <h3>{name}</h3>
              </Card>
            ))}
        </div>
      </div>
    );
  }
}

export default SelectSender;
