import { CreateProductPayload } from './types';
import axios from 'axios';

export const createProductsFromSupplier = async (
  items: Array<CreateProductPayload>,
) => {
  const result = await axios.post('/api/webhook/CreateProductFromSupplier', {
    items,
  });
  return result.data;
};
