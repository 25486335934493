import React, { FC, useState } from 'react';
import { Box, MenuItem, Select, TableCell, TableRow } from '@material-ui/core';
import _ from 'lodash';
import {
  getHowToBuyOptions,
  HowToBuyOptionsResult,
} from '../../HowToBuy4/utils/getHowToBuyOptions';
import { formatDate } from '../../../../../common/utils/dates';
import { labelProvider } from '../../../../../constants/label-provider';
import { GenericHowToBuyVersion } from './ShippingInfo';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface HowToBuyRowProps {
  warehouse: string;
  howToBuyOption: HowToBuyOptionsResult['howToBuyOptions'][string];
  bestShipping?: ReturnType<typeof getHowToBuyOptions>['bestShipping'];
  onRowClick: (
    rid: string,
    incomingWarehouse: string,
    shippingMethod: string,
    enterTracknumber: string,
    labelProvider: string,
  ) => void;
  onShippingMethodChange: (warehouse: string, shippingMethod: string) => void;
  onLabelProviderChange: (warehouse: string, labelProvider: string) => void;
  selectedItems: {
    warehouse: string;
    rid: string;
  }[];
  isPickup: boolean;
  version: GenericHowToBuyVersion;
}

const enterTracknumberOptions = [
  'products in order',
  'manually',
  "don't enter",
  'product in parcel',
];

const HowToBuyRow: FC<HowToBuyRowProps> = ({
  warehouse,
  howToBuyOption,
  bestShipping,
  onRowClick,
  selectedItems,
  onShippingMethodChange,
  onLabelProviderChange,
  isPickup,
  version,
}) => {
  const [isQuantityShowed, setIsQuantityShowed] = useState(false);

  const getPriceByHowToBuy = (data: typeof howToBuyOption[number]) => {
    const rid = data?.rollunId;

    if (rid) {
      // handle how to buy 4
      if (
        data.shippingOptions?.length > 1 &&
        typeof data.shippingOptions[0] === 'object'
      ) {
        return data.shippingOptions[0]?.price_item || 0;
      }

      // handle create linked deals v2
      return data.price;
    }
  };

  const isBestShipping = (
    data: typeof howToBuyOption[number]['shippingOptions'][number],
    warehouse: string,
  ) => {
    if (!bestShipping) {
      return false;
    }

    return (
      bestShipping &&
      bestShipping.shippingMethodName === data.shippingMethodName &&
      bestShipping.deliverySender === warehouse
    );
  };

  const getShippingMethodsFromOptions = (data: typeof howToBuyOption) => {
    if (howToBuyOption[0].shippingMethods) {
      return howToBuyOption[0].shippingMethods;
    }

    const shippingMethods = data.map(({ shippingOptions }) =>
      shippingOptions.map(({ shippingMethodName }) => shippingMethodName),
    );
    return [...new Set(shippingMethods.flat())];
  };

  const isSelectedRid = (rid: string) =>
    selectedItems.find(
      ({ warehouse: selectedWarehouse, rid: selectedRid }) =>
        selectedWarehouse === warehouse && selectedRid === rid,
    );

  const currentRids = howToBuyOption.map(({ rollunId }) => rollunId);

  return (
    <TableRow key={warehouse}>
      <TableCell>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          {howToBuyOption[0]?.inventoryQuantity ? (
            <>
              <Box
                onClick={() => setIsQuantityShowed(true)}
                style={{
                  color: isQuantityShowed ? 'red' : 'blue',
                  cursor: isQuantityShowed ? 'auto' : 'pointer',
                }}
              >
                {_.upperFirst(warehouse)}
                {isQuantityShowed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Box>
              {isQuantityShowed && (
                <Box>
                  {howToBuyOption.map((option, optionIndex) => {
                    return option && option.inventoryQuantity ? (
                      <React.Fragment key={optionIndex}>
                        <Box
                          style={{
                            color: 'red',
                          }}
                        >
                          RID - {option.rollunId}
                        </Box>
                        {option?.inventoryQuantity &&
                          Object.entries(option.inventoryQuantity).map(
                            ([location, quantity], index) => (
                              <Box key={index}>
                                {location}: {quantity}
                              </Box>
                            ),
                          )}
                      </React.Fragment>
                    ) : (
                      <></>
                    );
                  })}
                </Box>
              )}
            </>
          ) : (
            <Box>{_.upperFirst(warehouse)}</Box>
          )}
        </Box>
      </TableCell>
      {howToBuyOption.map((data, index) => (
        <TableCell key={index}>
          <Box
            display="flex"
            alignItems="center"
            style={{
              gap: 16,
              padding: 8,
              ...(isSelectedRid(data.rollunId as string) && {
                border: '1px solid blue',
                borderRadius: 30,
              }),
              ...(getPriceByHowToBuy(data) === 0 && {
                background: '#C8C8C8',
                borderRadius: '5px',
                opacity: '0.5',
              }),
            }}
            onClick={() => {
              if (getPriceByHowToBuy(data) !== 0) {
                onRowClick(
                  data.rollunId as string,
                  warehouse,
                  getShippingMethodsFromOptions(howToBuyOption)[0],
                  enterTracknumberOptions[0],
                  labelProvider[0],
                );
              }
            }}
          >
            <Box fontWeight="bold" fontSize={16}>
              {`${getPriceByHowToBuy(data)}$ ${
                data.warehouseQty ? ` X ${data.warehouseQty}` : ''
              }`}
            </Box>
            {data.shippingOptions.length === 0 ? (
              version === 'HowToBuy' ? (
                <Box>No shipping options</Box>
              ) : null
            ) : (
              <Box
                width="100%"
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
              >
                {data.shippingOptions.map((shippingOption, index) => {
                  const isBest = isBestShipping(shippingOption, warehouse);
                  const formattedCost =
                    (shippingOption.cost || 0).toFixed(2) + '$';
                  const trackNumberDate = shippingOption.trackNumberDate
                    ? formatDate(shippingOption.trackNumberDate)
                    : null;
                  const deliveryDate = shippingOption.shippingArriveDate
                    ? formatDate(shippingOption.shippingArriveDate)
                    : null;

                  return (
                    <Box
                      key={index}
                      display="flex"
                      justifyContent="space-between"
                      style={{
                        ...(isBest && {
                          color: 'blue',
                        }),
                      }}
                    >
                      <Box>
                        {isBest && <strong>(best) </strong>}
                        {shippingOption.shippingMethodName}
                      </Box>

                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-end"
                      >
                        <Box>
                          <strong>{formattedCost}</strong>
                        </Box>

                        {trackNumberDate && (
                          <Box>
                            <strong>Track Number Assigned:</strong>{' '}
                            {trackNumberDate}
                          </Box>
                        )}

                        {deliveryDate && (
                          <Box>
                            <strong>Estimated Delivery:</strong> {deliveryDate}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
        </TableCell>
      ))}
      <TableCell>
        <Select
          defaultValue={getShippingMethodsFromOptions(howToBuyOption)[0]}
          style={{
            minWidth: 150,
            maxWidth: 150,
          }}
          disabled={!currentRids.some((rid) => isSelectedRid(rid))}
          onChange={(e) =>
            onShippingMethodChange(warehouse, e.target.value as string)
          }
        >
          {getShippingMethodsFromOptions(howToBuyOption).map(
            (option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ),
          )}
        </Select>
      </TableCell>
      <TableCell>
        {isPickup ? (
          <Select
            defaultValue={labelProvider[0]}
            style={{
              minWidth: 150,
              maxWidth: 150,
            }}
            disabled={!currentRids.some((rid) => isSelectedRid(rid))}
            onChange={(e) =>
              onLabelProviderChange(warehouse, e.target.value as string)
            }
          >
            {labelProvider.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Box>Not pickup</Box>
        )}
      </TableCell>
    </TableRow>
  );
};

export default HowToBuyRow;
