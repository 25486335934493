import React from 'react';
import { LocalDatastore } from 'rollun-ts-datastore';
import TableWithFilter from '../../../../../../../Table/components/Table/TableWithFilter';
import { OneSupplierDialog, OneSupplierResult } from './OneSupplierDialog';
import { Dropship, Order, Pickup } from '../../../../../api-clients/types';
import { useEffect, useRef, useState } from 'react';
import { getSupplierInfoBySenderName } from '../../HowToBuy4/utils/supplierUtils';
import { httpErrorHandlerPromised } from '../../../../../../../../utils/common.utils';
import { enterTracknumber } from '../../../../../constants/enter-tracknumber';
import { apiClientsDropship } from '../../../../../api-clients/api-clients-dropships';
import { apiClientsPickups } from '../../../../../api-clients/api-clients-pickups';
import { labelProvider } from '../../../../../constants/label-provider';
import { Query, Eq, And, Eqn } from 'rollun-ts-rql';

interface OneSupplierProps {
  howToBuyOptions: null | any;
  deal: Order | undefined;
}

export const OneSupplier = (props: OneSupplierProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [payload, setPayload] = useState<any>(null);
  const [labelProviderOption, setLabelProviderOption] = useState(
    labelProvider[0],
  );
  const [result, setResult] = useState<OneSupplierResult>(null);
  const [isLoading, setIsLoading] = useState(false);
  const tableTabRef = useRef(null);
  const [maxTableWidth, setMaxTableWidth] = useState<any>();
  const [submittedItem, setSubmittedItem] = useState<null | any>();

  const { howToBuyOptions, deal } = props;

  useEffect(() => {
    const handleResize = () => {
      if (tableTabRef.current) {
        setMaxTableWidth((tableTabRef.current as any).clientWidth);
      }
    };
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [howToBuyOptions]);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleLabelProviderOptionChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setLabelProviderOption(event.target.value as string);
  };

  const submitCLDV3ItemFromCLDV3 = (item: any) => {
    if (!deal) {
      throw new Error('deal isnt defined');
    }
    setSubmittedItem(item);
    console.log({ item, deal });

    const supplier = getSupplierInfoBySenderName(item.sender);

    if (!supplier) {
      throw new Error('supplier isnt defined');
    }

    const positions = deal.no_special_items_positions.map((position) => {
      return {
        ...position,
        cost: 0,
      };
    });

    const newPayload = {
      mpName: deal.mpName,
      srShipMethod: item.shippingMethod,
      mpOrderNumber: deal.mpOrderNumber,
      contractor: deal.contractor,
      sender: item.sender,
      owner: deal.owner,
      type: supplier.supplier_type,
      srName: supplier.id,
      relatedOrder: deal.id,
      positions,
      labelProvider: item.labelProvider,
    };

    setPayload(newPayload);
    if (newPayload.labelProvider) {
      setLabelProviderOption(newPayload.labelProvider);
    }
    setDialogOpen(true);
  };

  const checkNegativeProfit = () => {
    if (!deal) {
      throw new Error('deal isnt defined');
    }

    const cogs = submittedItem.cogs;

    const profit = +deal.profit;

    if (profit < cogs) {
      const createDealAnyway = confirm(`
        Total price with Shipping(${cogs.toFixed(2)}) 
        more than Profit(${profit.toFixed(2)}).
        Create deal anyway?
      `);

      if (!createDealAnyway) {
        return true;
      }
    }
    return false;
  };

  const findItemFromDealInDeals = (
    srcDeal: Order,
    dealsToSearch: (Pickup | Dropship)[],
  ) => {
    const { positions: srcPositions } = srcDeal;

    for (const deal of dealsToSearch) {
      const { no_special_items_positions: positions = [] } = deal;

      console.log('positions', positions, srcPositions);
      const sameItem = positions.find((pos) =>
        srcPositions.find((srcPos) => srcPos.article === pos.article),
      );
      if (sameItem)
        return {
          dealId: deal.id,
          itemId: sameItem.offerId,
          rollunId: sameItem.article || 'No rollun ID',
        };
    }
    return null;
  };

  const checkForDuplicates = async (dealPayload: any, supplierName: string) => {
    const type: 'DROPSHIP' | 'PICKUP' = dealPayload.type;
    const apiClient =
      type === 'DROPSHIP' ? apiClientsDropship : apiClientsPickups;
    const query = new Query().setQuery(
      new And([
        new Eq('RelatedOrder', dealPayload.relatedOrder),
        new Eqn('ArchiveScenario'),
      ]),
    );
    const linkedDeals = [
      ...(await apiClientsPickups.getByQuery(query)),
      ...(await apiClientsDropship.getByQuery(query)),
    ];
    console.log('linkedDeals', linkedDeals, dealPayload);
    const sameItem = findItemFromDealInDeals(dealPayload, linkedDeals);
    let failedDeals: { supplierName: string }[] = [];

    if (
      sameItem &&
      !confirm(`
          Attempt to create deal
          for supplier [${supplierName}] failed!
          Linked deal(id: ${sameItem?.dealId})
          already has item(id: ${sameItem?.itemId}, rollun_id: ${sameItem?.rollunId}).
          Create deal anyway?
      `)
    ) {
      failedDeals = failedDeals.concat({ supplierName: supplierName });
      return true;
    }
    const mpName = dealPayload.mpNmae;
    const mpOrderNumber = dealPayload.mpOrderNumber;
    const createdDealsIds = await apiClient.getByQuery(
      new Query().setQuery(
        new And([
          new Eq('MpName', mpName),
          new Eq('MpOrderNumber', mpOrderNumber),
          new Eq('SrName', supplierName),
        ]),
      ),
    );

    if (
      createdDealsIds.length > 0 &&
      !confirm(`
          Deals with
          MP Name - ${mpName}
          MP Order Num - ${mpOrderNumber}
          Sr Name - ${supplierName}
          already exists!
          Ids: ${(createdDealsIds as any[]).map((deal) => deal.id).join(', ')}
          Create deal anyway?
      `)
    ) {
      failedDeals = failedDeals.concat({ supplierName: supplierName });
      return true;
    }

    console.log('failedDeals', failedDeals);
  };

  const handleDialogSubmit = async () => {
    setIsLoading(true);
    if (!payload) return;
    const updatedPayload = {
      ...payload,
      enterTracknumber: enterTracknumber[0],
      ...(payload?.type === 'PICKUP'
        ? { labelProvider: labelProviderOption }
        : {}),
    };

    const isNegativeProfit = checkNegativeProfit();
    if (isNegativeProfit) {
      return;
    }

    console.log({ updatedPayload, submittedItem, deal });

    const {
      mpName,
      srName,
      mpOrderNumber,
      srShipMethod,
      labelProvider,
      enterTracknumber: enterTn,
      type,
      contractor,
      relatedOrder,
      owner,
      sender,
      positions,
    } = updatedPayload;

    const dealPayload = {
      mpName,
      srName,
      mpOrderNumber,
      srShipMethod,
      labelProvider,
      enterTracknumber: enterTn,
      type,
      contractor,
      relatedOrder,
      owner,
      sender,
      positions,
    };
    try {
      if (await checkForDuplicates(dealPayload, srName)) {
        setResult({
          success: false,
          name: `Deal with supplier ${srName}, ship method ${srShipMethod}`,
          error: 'Canceled by user',
        });
      } else {
        const apiClient =
          payload.type === 'DROPSHIP' ? apiClientsDropship : apiClientsPickups;

        const newDeal = await apiClient.create(updatedPayload);

        setResult({
          success: true,
          name: `Deal with supplier ${payload.srName}, ship method ${payload.srShipMethod}`,
          dealId: newDeal.id,
          type: payload.type,
        });
      }
    } catch (err) {
      setResult({
        success: false,
        name: `Deal with supplier ${payload.srName}, ship method ${payload.srShipMethod}`,
        error: (await httpErrorHandlerPromised(err)).text,
      });
    }

    setIsLoading(false);
  };

  return (
    <>
      <OneSupplierDialog
        result={result}
        dialogOpen={dialogOpen}
        handleDialogClose={handleDialogClose}
        labelProviderOption={labelProviderOption}
        handleLabelProviderOptionChange={handleLabelProviderOptionChange}
        payload={payload}
        handleDialogSubmit={handleDialogSubmit}
        isLoading={isLoading}
      />
      <div ref={tableTabRef}>
        <TableWithFilter
          appName="CreateLinkedDealsV3"
          isLocal
          isFiltersShown
          maxTableWidth={maxTableWidth}
          datastoreUrl="CreateLinkedDealsV3Widget"
          initialLocalDataStore={
            new LocalDatastore({
              initialData: howToBuyOptions.first,
              idField: 'id',
            })
          }
          submitCLDV3ItemFromCLDV3={submitCLDV3ItemFromCLDV3}
        />
      </div>
    </>
  );
};
