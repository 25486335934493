import React, { useEffect, useState } from 'react';
import HttpDatastore from 'rollun-ts-datastore/dist';
import { FILTERS_DATASTORE_URL } from '../../util/constants';
import { Query, Eq, RqlParser } from 'rollun-ts-rql';
import {
  Typography,
  Card,
  Button,
  makeStyles,
  Box,
  LinearProgress,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';

export interface FilterRow {
  id?: string;
  name: string;
  rql: string;
  table_name: string;
  node_red_config: string;
}

const filtersDS = new HttpDatastore<FilterRow>(FILTERS_DATASTORE_URL);

interface IProps {
  datastoreUrl: string;
  setQuery: (query: Query | null) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 1, 1, 1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

const FiltersPreview: React.FC<IProps> = ({ datastoreUrl, setQuery }) => {
  const [filters, setFilters] = useState<Array<FilterRow>>([]);
  const [status, setStatus] = useState('idle');
  const { search } = useLocation();

  const { root, button } = useStyles();

  useEffect(() => {
    const getFilters = async () => {
      try {
        setStatus('loading');
        const data = await filtersDS.query(
          new Query({ query: new Eq('table_name', datastoreUrl) }),
        );

        setFilters(
          data
            .filter(({ node_red_config }) => node_red_config === null)
            .sort((a, b) => {
              const aFormatted = a.name.toLowerCase();
              const bFormatted = b.name.toLowerCase();
              if (aFormatted < bFormatted) {
                return -1;
              }
              if (aFormatted > bFormatted) {
                return 1;
              }
              return 0;
            }),
        );
        setStatus('loaded');
      } catch (e) {
        setStatus('error');
      }
    };

    getFilters();
  }, [datastoreUrl]);

  const parseFilterRql = (rql: string) => {
    try {
      return new RqlParser().parse(rql);
    } catch (err) {
      console.log("Couldn't parse rql", err, rql);
    }
    return null;
  };

  if (status === 'error') {
    return (
      <Card className={root}>
        <Box marginLeft={1}>
          <Typography variant="h5">
            There was an error getting filters
          </Typography>
        </Box>
      </Card>
    );
  }

  if (status === 'loading') {
    return (
      <Card className={root}>
        <LinearProgress />
      </Card>
    );
  }

  if (filters.length === 0) {
    return null;
  }

  return (
    <Card className={root}>
      {filters.map((filter) => {
        const isActive = search.slice(1) === filter.rql;

        return (
          <Button
            className={button}
            color={isActive ? 'secondary' : 'primary'}
            size="small"
            variant="contained"
            title={'Filter query: ' + decodeURIComponent(filter.rql)}
            key={filter.id}
            onClick={() => {
              setQuery(parseFilterRql(filter.rql));
            }}
          >
            {filter.name}
          </Button>
        );
      })}
    </Card>
  );
};

export default FiltersPreview;
