export const returnReason = [
  'Ordered by mistake',
  'Item arrived too late',
  'Client changed mind',
  'Missing parts or pieces',
  'Wrong item sent',
  "Doesn't fit my vehicle",
  "Doesn't match description or photos",
  'Found a better price',
  "Doesn't work or defective",
  'Arrived damaged',
  "Didn't approve purchase",
  'other',
];
