import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from '../../../../../UI/Spinner';
import GenericDeal, { DealProps, DealType } from '../../GenericDeal';
import { ErrorView } from '../../../../../UI/index';
import { useGetReturnPickupDeal } from '../hooks/useGetReturnPickupDeal';
import { useMutateItems } from '../hooks/useMutateItems';
import { useMutateDeal } from '../hooks/useMutateDeal';
import { Box } from '@material-ui/core';
import MuiButton from '../../../../../UI/MuiButton';
import { useMutateClient } from '../../GenericDeal/hooks/useMutateClient';
import { apiClientsReturnPickup } from '../../api-clients/api-clients-return-pickups';

export const ReturnPickupDeal: FC<DealProps> = ({
  buttons,
  fieldsConfig,
  widgets,
}) => {
  const { id } = useParams<{ id: string }>();
  const { data, error, isFetching } = useGetReturnPickupDeal(id);
  const { handleAddItems, handleDeleteItems } = useMutateItems();
  const { handleUpdateClient } = useMutateClient();
  const { handleUpdateDeal } = useMutateDeal();
  const history = useHistory();

  if (isFetching && data?.id !== id) {
    return <Spinner />;
  }

  if (error && !data) {
    return (
      <ErrorView
        error={{
          text: (error as Error).message,
          code: (error as Error).message.includes('not found') ? 404 : 403,
        }}
      >
        <Box
          style={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <MuiButton onClick={() => history.goBack()}>Back</MuiButton>
          <MuiButton onClick={() => location.reload()}>Reload</MuiButton>
        </Box>
      </ErrorView>
    );
  }

  return (
    <GenericDeal
      data={data!}
      rawData={data ? apiClientsReturnPickup.convertModelToDTO(data!) : {}}
      type={DealType.ReturnPickup}
      buttons={buttons}
      fieldsConfig={fieldsConfig}
      widgets={widgets}
      onItemsAdd={handleAddItems}
      onItemsDelete={handleDeleteItems}
      onDealUpdate={handleUpdateDeal}
      onClientUpdate={handleUpdateClient}
      availableStatuses={[
        {
          id: '76',
          name: 'Labels creation',
          color: '#E71773',
        },
        {
          id: '77',
          name: 'Mp_return_authorization',
          color: '#84216B',
        },
        {
          id: '78',
          name: 'Waiting_for_sending',
          color: '#215B33',
        },
        {
          id: '79',
          name: 'Waiting for receiving',
          color: '#AE31CF',
        },
        {
          id: '80',
          name: 'Archive',
          color: '#FCEC25',
        },
      ]}
    />
  );
};
