import React from 'react';
import AbstractService, { AbstractServiceProps } from '../AbstractService';
import AppContainer from '../../layout/AppContainer/AppContainer';
import { ScannerDemo } from './components/ScannerDemo';

class BarcodeScannerDemo extends AbstractService<AbstractServiceProps> {
  render() {
    const { user, role } = this.props.userIdentity;

    return (
      <AppContainer
        user={user}
        role={role}
        currentResourceName={this.props.resourceName}
        services={this.props.availableServices}
      >
        <ScannerDemo />
      </AppContainer>
    );
  }
}

export default BarcodeScannerDemo;
