import { useLocation } from 'react-router-dom';
import RqlParser from 'rollun-ts-rql/dist/RqlParser.js';
import { makeDefaultQuery } from '../pages/Table/components/Table/TableContainer';

export const useCurrentQuery = () => {
  const { search } = useLocation();

  if (search.slice(1) === '') {
    return makeDefaultQuery();
  }

  try {
    return new RqlParser().parse(search.slice(1));
  } catch (e) {
    return makeDefaultQuery();
  }
};
