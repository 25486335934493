import React from 'react';
import MarkdownIt from 'markdown-it';
import './MarkdowViewer.css';

const mdParser = new MarkdownIt();

const MarkdownViewer: React.FC<{ source: string }> = ({ source }) => {
  const markdown = mdParser.render(source);
  return (
    <div
      dangerouslySetInnerHTML={{ __html: markdown }}
      className="md-viewer-root"
    />
  );
};

export default MarkdownViewer;
