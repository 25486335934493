import HttpDatastore from 'rollun-ts-datastore';
import { BagDTO, Bag, Position, SPECIAL_ITEMS_IDS } from './types';
import ApiClientsPositions from './api-clients-position';
import { Query } from 'rollun-ts-rql';
import { compareStringNumbers } from '../common/utils/compareStringNumbers';

const datastore = new HttpDatastore<BagDTO>('/api/datastore/BagsDataStore');

export default class ApiClientsBags {
  apiPositions: ApiClientsPositions;

  constructor() {
    this.apiPositions = new ApiClientsPositions();
  }

  async getById(id: string): Promise<Bag> {
    const bagDTO = await datastore.read(id);

    if (!bagDTO) {
      throw new Error(`Bag with id ${id} not found`);
    }

    const positions = this.apiPositions.getByDealId(id);

    const bag = Promise.all([bagDTO, positions]).then((data) => {
      return this.convertDTOtoModel(...data);
    });
    return bag;
  }

  async getByQuery(query: Query): Promise<Bag[]> {
    const bagsDTO = await datastore.query(query);
    const positions = await Promise.all(
      bagsDTO.map((bagDTO) => this.apiPositions.getByDealId(bagDTO.Id)),
    );

    return bagsDTO.map((bagDTO, index) =>
      this.convertDTOtoModel(bagDTO, positions[index]),
    );
  }

  async create(data: Omit<Bag, 'id'>): Promise<Bag> {
    const pickupDTO = this.convertModelToDTO(data);
    const pickup = await datastore.create(pickupDTO);

    if (data.positions) {
      await this.addItems(pickup.Id, data.positions);
    }

    return this.getById(pickup.Id);
  }

  async update(id: string, data: Partial<Omit<Bag, 'id' | 'positions'>>) {
    await datastore.update({
      ...this.convertModelToDTO(data),
      Id: id,
    });
  }

  async addOrUpdateItems(
    id: string,
    items: Omit<Position, 'offerId' | 'dealId' | 'id'>[],
  ) {
    const deal = await this.getById(id);
    const positions = deal.positions;

    const tempSortedItems: Position[] = [];

    for (let i = 0; i < positions.length; i++) {
      const position = { ...positions[i] };

      if (
        tempSortedItems.filter(
          (item) =>
            item.article === position.article &&
            compareStringNumbers(item.cost, position.cost) &&
            item.name === position.name,
        ).length
      )
        continue;

      tempSortedItems.push(position);

      const sameItems = items.filter(
        (item) =>
          item.article === position.article &&
          compareStringNumbers(item.cost, position.cost) &&
          item.name === position.name,
      );
      if (!sameItems.length) continue;

      const allQuantity = sameItems.reduce(
        (prev, cur) => prev + +cur.quantity,
        0,
      );
      position.quantity = +position.quantity + allQuantity;

      await this.apiPositions.update(position);
    }

    const filteredItems = items.filter((item) => {
      for (let i = 0; i < tempSortedItems.length; i++) {
        const position = { ...tempSortedItems[i] };
        if (
          item.article === position.article &&
          compareStringNumbers(item.cost, position.cost) &&
          item.name === position.name
        )
          return false;
      }
      return true;
    });

    if (filteredItems.length) await this.addItems(id, filteredItems);
  }

  async deleteItems(id: string, itemIds: string[]): Promise<void> {
    await Promise.all(
      itemIds.map((itemId) => this.apiPositions.delete(id, itemId)),
    );
  }

  async addItems(
    id: string,
    items: Omit<Position, 'offerId' | 'dealId' | 'id'>[],
  ): Promise<void> {
    for (const item of items) {
      await this.apiPositions.add(id, item);
    }
  }

  convertDTOtoModel(bagDTO: BagDTO, positions: Position[]): Bag {
    const {
      Id: id,
      BagStatus: bagStatus,
      ProblemDescription: problemDescription,
      MpName: mpName,
      SrPaymentCard: srPaymentCard,
      SrName: srName,
      Type: type,
      SrOrderId: srOrderId,
      Sender: sender,
      Tracknumber: tracknumber,
      Carrier: carrier,
      ShipStatus: shipStatus,
      RetBag: retBag,
      ArchiveScenario: archiveScenario,
      SrShipMethod: srShipMethod,
      TimeCreated: timeCreated,
      TimeUpdated: timeUpdated,
      FinalPrice: finalPrice,
      SrOrderNumber: srOrderNumber,
      SrItemsPrice: srItemsPrice,
      Status: status,
      StatusName: statusName,
      SrReturnNumber: srReturnNumber,
    } = bagDTO;

    const no_special_items_positions = positions.filter(
      (position) => !SPECIAL_ITEMS_IDS.includes(position.article),
    );

    const bag: Bag = {
      id,
      bagStatus,
      problemDescription,
      mpName,
      srPaymentCard,
      srName,
      type,
      srOrderId,
      sender,
      tracknumber,
      carrier,
      shipStatus,
      retBag,
      archiveScenario,
      srShipMethod,
      timeCreated,
      timeUpdated,
      finalPrice,
      srOrderNumber,
      srItemsPrice,
      status,
      positions,
      no_special_items_positions,
      statusName,
      srReturnNumber,
    };
    return bag;
  }

  convertModelToDTO(
    bag: Partial<Omit<Bag, 'id' | 'positions'>>,
  ): Partial<Omit<BagDTO, 'Id'>> {
    return {
      BagStatus: bag.bagStatus,
      ProblemDescription: bag.problemDescription,
      MpName: bag.mpName,
      SrPaymentCard: bag.srPaymentCard,
      SrName: bag.srName,
      Type: bag.type,
      SrOrderId: bag.srOrderId,
      Sender: bag.sender,
      Tracknumber: bag.tracknumber,
      Carrier: bag.carrier,
      ShipStatus: bag.shipStatus,
      RetBag: bag.retBag,
      ArchiveScenario: bag.archiveScenario,
      SrShipMethod: bag.srShipMethod,
      TimeCreated: bag.timeCreated,
      TimeUpdated: bag.timeUpdated,
      FinalPrice: bag.finalPrice,
      SrOrderNumber: bag.srOrderNumber,
      SrItemsPrice: bag.srItemsPrice,
      Status: bag.status,
      StatusName: bag.statusName,
      SrReturnNumber: bag.srReturnNumber,
    };
  }
}

export const apiClientsBag = new ApiClientsBags();
