import React, { useState } from 'react';
import MetricShow from './MetricShow';
import {
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import CustomAlert, { AlertType } from '../../../UI/CustomAlert';

interface AlertContext {
  isOpen: boolean;
  msg: string;
  type: AlertType;
  showAlert: (msg: string, type: AlertType) => void;
}

export const AlertContext = React.createContext<AlertContext>({
  isOpen: false,
  msg: '',
  type: 'error',
  showAlert: () => {
    return;
  },
});

const useStyles = makeStyles(() => ({
  root: {
    padding: '20px',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const MetricUi = () => {
  const classes = useStyles();
  const [metricName, setMetricName] = useState<string>('');
  const [refresh, setRefresh] = useState<boolean>(false);
  const [alertMsg, setAlertMsg] = useState<string>('');
  const [alertType, setAlertType] = useState<AlertType>('error');
  const [alertOpen, setAlertOpen] = useState<boolean>(false);

  return (
    <AlertContext.Provider
      value={{
        isOpen: alertOpen,
        msg: alertMsg,
        type: 'error',
        showAlert: (msg: string, type: AlertType) => {
          setAlertMsg(msg);
          setAlertType(type);
          setAlertOpen(true);
        },
      }}
    >
      <CustomAlert
        msg={alertMsg}
        onClose={() => {
          setAlertOpen(false);
          setAlertMsg('');
        }}
        isOpen={alertOpen}
        alertType={alertType}
      />
      <div className={classes.root}>
        <div style={{ marginBottom: '10px' }}>
          <Typography variant="h3">Search for a metric</Typography>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <TextField
            value={metricName}
            variant="outlined"
            onChange={(e) => setMetricName(e.target.value)}
            label="Metric name"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={() => setRefresh(!refresh)}
                >
                  <Refresh />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div>
          <MetricShow
            metricId={metricName}
            refreshMetrics={refresh}
            refresh={() => setRefresh(!refresh)}
          />
        </div>
      </div>
    </AlertContext.Provider>
  );
};

export default MetricUi;
