import { Theme } from '@material-ui/core';
import {
  SHIPMENT_TYPE_POSTAL,
  SHIPMENT_TYPE_WAREHOUSE,
  STATUS_IN_PROCESS,
  STATUS_PROCESSED,
} from '../hooks';

const getCardBorderColorByStatus = (status: string, theme: Theme) => {
  if (status === STATUS_PROCESSED) {
    return theme.palette.success.main;
  }

  if (status === STATUS_IN_PROCESS) {
    return theme.palette.primary.light;
  }

  return theme.palette.background.paper;
};

const getDeliveryTypeString = (shipmentType: string) => {
  if (shipmentType === SHIPMENT_TYPE_WAREHOUSE) {
    return 'Delivery to warehouse';
  }

  if (shipmentType === SHIPMENT_TYPE_POSTAL) {
    return 'Delivery by postal';
  }

  return 'No shipment type was not specified';
};

const getStatusString = (status: string) => {
  if (status === STATUS_PROCESSED) {
    return 'The bag has been processed';
  }

  if (status === STATUS_IN_PROCESS) {
    return 'The bag is being processed';
  }

  return 'The bag has not been processed';
};

export { getCardBorderColorByStatus, getDeliveryTypeString, getStatusString };
