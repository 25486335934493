import React from 'react';
import AbstractEditor, { IEditorProps } from './AbstractEditor';
import Dialog from '@material-ui/core/Dialog';
import {
  DialogActions,
  DialogTitle,
  TextField,
  Box,
  Input,
  Typography,
} from '@material-ui/core';
import MuiButton from '../../../../../../UI/MuiButton';

const inputWrapStyles = {
  display: 'flex',
  columnGap: '30px',
  alignItems: 'center',
  marginBottom: '15px',
};

const inputStyles = { width: '30px', height: '18px' };

interface IState {
  managerCommentObject: any;
}

type MgCommentType = {
  priceDrop: string;
  advertising: string;
  volumePricing: string;
  competitorDoMapViolation: string;
  discountPromo: string;
  uncompetitive: string;
  comment: string;
};

const mgCommentTemplate: MgCommentType = {
  priceDrop: '0',
  advertising: '0',
  volumePricing: '0',
  competitorDoMapViolation: '0',
  discountPromo: '0',
  uncompetitive: '0',
  comment: 'text',
};

class ManagersCommentEditor extends AbstractEditor<IState> {
  constructor(props: IEditorProps) {
    super(props);
    this.state = {
      managerCommentObject: props.value
        ? JSON.parse(props.value)
        : mgCommentTemplate,
    };
  }

  getValue = () => {
    return { [this.props.column.key]: this.state.managerCommentObject };
  };

  getInputNode(): HTMLInputElement | null | undefined {
    return undefined;
  }

  onInputChange = (name: string, value: string) => {
    const validValues = ['0', '1', ''];
    if (name !== 'comment' && !validValues.includes(value)) {
      return;
    }

    this.setState((state) => ({
      managerCommentObject: {
        ...state.managerCommentObject,
        [`${name}`]: value,
      },
    }));
  };

  banParentKeyListeners = (e: React.KeyboardEvent<HTMLElement>) => {
    const { key } = e;
    if (key === 'Enter' || key === 'Tab' || key.indexOf('Arrow') > -1) {
      e.stopPropagation();
    }
  };

  render() {
    const { onCommit, onCommitCancel } = this.props;
    const { managerCommentObject } = this.state;
    const {
      priceDrop,
      advertising,
      volumePricing,
      competitorDoMapViolation,
      discountPromo,
      uncompetitive,
      comment,
    } = managerCommentObject;

    return (
      <Box
        onBlur={(e) => e.stopPropagation()}
        onKeyDown={(e) => {
          this.banParentKeyListeners(e);
        }}
      >
        <Dialog
          onClose={() => {
            return;
          }}
          open={true}
          fullWidth
        >
          <DialogTitle>
            Manager's comment editor
            <Typography
              style={{
                fontSize: '14px',
                fontStyle: 'italic',
                color: 'lightgrey',
              }}
            >
              *every field's value except 'comment' should be 0 or 1
            </Typography>
          </DialogTitle>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            style={{ padding: '0 25px' }}
          >
            <Box style={inputWrapStyles}>
              Price drop
              <Input
                style={inputStyles}
                value={priceDrop}
                onChange={(e) =>
                  this.onInputChange('priceDrop', e.target.value)
                }
              />
            </Box>

            <Box style={inputWrapStyles}>
              Advertising
              <Input
                style={inputStyles}
                value={advertising}
                onChange={(e) =>
                  this.onInputChange('advertising', e.target.value)
                }
              />
            </Box>

            <Box style={inputWrapStyles}>
              Volume pricing
              <Input
                style={inputStyles}
                value={volumePricing}
                onChange={(e) =>
                  this.onInputChange('volumePricing', e.target.value)
                }
              />
            </Box>

            <Box style={inputWrapStyles}>
              Competitor do map violation
              <Input
                style={inputStyles}
                value={competitorDoMapViolation}
                onChange={(e) =>
                  this.onInputChange('competitorDoMapViolation', e.target.value)
                }
              />
            </Box>

            <Box style={inputWrapStyles}>
              Discount promo
              <Input
                style={inputStyles}
                value={discountPromo}
                onChange={(e) =>
                  this.onInputChange('discountPromo', e.target.value)
                }
              />
            </Box>

            <Box style={inputWrapStyles}>
              Uncompetitive
              <Input
                style={inputStyles}
                value={uncompetitive}
                onChange={(e) =>
                  this.onInputChange('uncompetitive', e.target.value)
                }
              />
            </Box>

            <TextField
              label="comment"
              type="text"
              value={comment}
              onChange={(e) => this.onInputChange('comment', e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
            />
          </Box>
          <DialogActions>
            <MuiButton
              onClick={() => {
                onCommit(JSON.stringify(managerCommentObject));
                onCommitCancel();
              }}
              color="success"
            >
              Ok
            </MuiButton>
            <MuiButton color="error" onClick={() => onCommitCancel()}>
              Cancel
            </MuiButton>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}

export default ManagersCommentEditor;
