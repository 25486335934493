import { RollunIdWithCsnItem } from '../../../hooks/datastores';
import { Csn } from '../components/IdentifiedItemInfo';

const isUrl = (url: string) => /https?:\/\//.test(url);

const formatCsnsFromItems = (items: RollunIdWithCsnItem[]) => {
  const uniqueRidsWithNonUniqueItems: Record<
    string,
    RollunIdWithCsnItem[]
  > = {};

  for (const item of items) {
    if (uniqueRidsWithNonUniqueItems[item.rid]) {
      uniqueRidsWithNonUniqueItems[item.rid].push(item);
      continue;
    }
    uniqueRidsWithNonUniqueItems[item.rid] = [item];
  }

  return Object.keys(uniqueRidsWithNonUniqueItems).reduce<
    Record<string, Csn[]>
  >((acc, rid) => {
    const itemsByRid = uniqueRidsWithNonUniqueItems[rid].map(
      ({ sr_name, csn }) => ({
        supplier: sr_name,
        csn: csn,
      }),
    );

    return { ...acc, [rid]: itemsByRid };
  }, {});
};

export { isUrl, formatCsnsFromItems };
