export function stdTimezoneOffset(date: Date) {
  const jan = new Date(date.getFullYear(), 0, 1);
  const jul = new Date(date.getFullYear(), 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
}

// based on https://stackoverflow.com/a/11888430
export function isDstObserved(date: Date) {
  return date.getTimezoneOffset() < stdTimezoneOffset(date);
}

export function PTDateToUTC(date: Date) {
  return shiftDate(date, -8);
}

export function shiftDate(date: Date, shift: number) {
  const actualOffset = shift + (isDstObserved(new Date()) ? 1 : 0);
  const converted = new Date(date.getTime() + actualOffset * 1000 * 60 * 60);
  return dateToUTC(converted);
}

export function dateToUTC(data: Date) {
  return new Date(
    Date.UTC(
      data.getFullYear(),
      data.getMonth(),
      data.getDate(),
      data.getHours(),
      data.getMinutes(),
    ),
  );
}

export function formatDate(date: Date | string) {
  const dateObject = typeof date === 'string' ? new Date(date) : date;
  const month = '' + (dateObject.getMonth() + 1);
  const day = '' + dateObject.getDate();
  const year = dateObject.getFullYear();
  const minutes = '' + dateObject.getMinutes();
  const hours = '' + dateObject.getHours();

  const ymd = [
    year,
    month.length < 2 ? '0' + month : month,
    day.length < 2 ? '0' + day : day,
  ].join('-');

  const hm = [
    hours.length < 2 ? '0' + hours : hours,
    minutes.length < 2 ? '0' + minutes : minutes,
  ].join(':');

  return `${ymd} ${hm}`;
}

export function getDate(date = new Date()) {
  return date.toISOString().slice(0, 10);
}
