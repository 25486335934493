import React, { Component } from 'react';
import { ResourceRow } from '../../utils/types';

class ResourceView extends Component<{ resource: ResourceRow }> {
  render() {
    const { resource } = this.props;
    return (
      <div className="mx-3 my-2">
        <h3>Resource</h3>
        <div className="d-flex justify-content-between">
          <h5>{resource.id}</h5>
          <h5>{resource.name}</h5>
          <h5>{resource.pattern}</h5>
        </div>
      </div>
    );
  }
}

export default ResourceView;
