import React, { Component } from 'react';
import HttpDatastore from 'rollun-ts-datastore';
import Spinner from '../../../UI/Spinner';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';
import {
  BRAND_MAP_DATA_STORE,
  UNKNOWN_BRANDS_DATA_STORE,
} from './BrandsMapper';
import { Button, ErrorView } from '../../../UI';
import { ErrorType } from '../../../utils/common.types';
import { httpErrorHandler } from '../../../utils/common.utils';
import { Query, Eqn } from 'rollun-ts-rql';

type Brand = { id?: string; alias: string; brand_id: string };

interface IProps {
  onActionComplete(): void;

  selectedItem: { id: string; brand: string } | null;
}

interface IState {
  loading: boolean;
  data: Array<Brand>;
  selectedValue: string;
  selectedId: string;
  error: ErrorType | null;
}

class NewBrandMapping extends Component<IProps, IState> {
  state: IState = {
    loading: true,
    data: [],
    selectedValue: '',
    error: null,
    selectedId: '',
  };

  BrandMappingDataStore = new HttpDatastore<Brand>(BRAND_MAP_DATA_STORE);
  UnknownBrandsDataStore = new HttpDatastore(UNKNOWN_BRANDS_DATA_STORE);

  componentDidMount(): void {
    this.BrandMappingDataStore.query(
      new Query({
        query: new Eqn('brand_id'),
      }),
    )
      .then((res) => this.setState({ loading: false, data: res }))
      .catch((e) =>
        httpErrorHandler(e, (code, text) =>
          this.setState({ loading: false, error: { code, text } }),
        ),
      );
  }

  // Can't find right type for {selectedOption}.
  handleChange = (selectedOption: ValueType<any>) => {
    if (!selectedOption) return;
    this.setState({
      selectedValue: selectedOption.value,
      selectedId: selectedOption.id,
    });
  };

  _handleNewBrandMapping = async () => {
    const { selectedId } = this.state;
    if (!this.props.selectedItem) return;
    const {
      selectedItem: { brand, id },
    } = this.props;
    await this.BrandMappingDataStore.create({
      brand_id: selectedId,
      alias: brand,
    });
    await this.UnknownBrandsDataStore.delete(id);
  };

  handleSubmit = () => {
    this.setState({ loading: true });
    this._handleNewBrandMapping()
      .then(() => this.props.onActionComplete())
      .catch((e) =>
        httpErrorHandler(e, (code, text) =>
          this.setState({ error: { code, text } }),
        ),
      );
  };

  render() {
    const { loading, data, selectedValue, selectedId, error } = this.state;

    if (error) {
      return (
        <ErrorView error={error}>
          <Button
            color="primary"
            onClick={() => this.setState({ error: null, loading: false })}
          >
            Back
          </Button>
        </ErrorView>
      );
    }

    if (loading) {
      return (
        <div className="my-5">
          <Spinner />
        </div>
      );
    }

    const value = {
      value: selectedValue,
      label: selectedValue,
      id: selectedId,
    };

    return (
      <div>
        Brand:
        <Select
          value={value}
          onChange={this.handleChange}
          options={data.map((el: Brand) => ({
            value: el.alias,
            label: el.alias,
            id: el.id,
          }))}
        />
        {selectedValue ? (
          <div className="d-flex justify-content-end">
            <Button className="m-1" color="primary" onClick={this.handleSubmit}>
              Create
            </Button>
          </div>
        ) : (
          <p className="m-1 font-weight-bold">Select brand first!</p>
        )}
      </div>
    );
  }
}

export default NewBrandMapping;
