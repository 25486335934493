import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from '../../../../../UI/Spinner';
import GenericDeal, { DealProps, DealType } from '../../GenericDeal';
import { ErrorView } from '../../../../../UI';
import { useMutateItems } from '../hooks/useMutateItems';
import { useMutateDeal } from '../hooks/useMutateDeal';
import { useGetDropshipDeal } from '../hooks/useGetDropshipDeal';
import { Box } from '@material-ui/core';
import MuiButton from '../../../../../UI/MuiButton';
import { useMutateClient } from '../../GenericDeal/hooks/useMutateClient';
import { apiClientsDropship } from '../../api-clients/api-clients-dropships';

const DropshipDeal: FC<DealProps> = ({ buttons, fieldsConfig, widgets }) => {
  const { id } = useParams<{ id: string }>();
  const { data, error, isFetching } = useGetDropshipDeal(id);
  const { handleAddItems, handleDeleteItems } = useMutateItems();
  const { handleUpdateClient } = useMutateClient();
  const { handleUpdateDeal } = useMutateDeal();
  const history = useHistory();

  if (isFetching && data?.id !== id) {
    return <Spinner />;
  }

  if (error && !data) {
    return (
      <ErrorView
        error={{
          text: (error as Error).message,
          code: (error as Error).message.includes('not found') ? 404 : 403,
        }}
      >
        <Box
          style={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <MuiButton onClick={() => history.goBack()}>Back</MuiButton>
          <MuiButton onClick={() => location.reload()}>Reload</MuiButton>
        </Box>
      </ErrorView>
    );
  }

  return (
    <GenericDeal
      data={data!}
      rawData={data ? apiClientsDropship.convertModelToDTO(data!) : {}}
      type={DealType.Dropship}
      buttons={buttons}
      fieldsConfig={fieldsConfig}
      widgets={widgets}
      onItemsAdd={handleAddItems}
      onItemsDelete={handleDeleteItems}
      onDealUpdate={handleUpdateDeal}
      onClientUpdate={handleUpdateClient}
      availableStatuses={[
        {
          id: '104',
          name: 'Parcel creation',
          color: '#E71773',
        },
        {
          id: '105',
          name: 'Purchase',
          color: '#8100E5',
        },
        {
          id: '106',
          name: 'Track-numbers getting',
          color: '#F28411',
        },
        {
          id: '107',
          name: 'Tracking',
          color: '#4F3BFF',
        },
        {
          id: '108',
          name: 'Archive',
          color: '#E3DB47',
        },
      ]}
    />
  );
};

export default DropshipDeal;
