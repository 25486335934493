import React, { FC } from 'react';
import { MuiColors } from '../../../../../UI/MuiButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MuiIconButton from '../../../../../UI/MuiIconButton';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Box } from '@material-ui/core';

interface AddableIconButtonProps {
  value?: any;
  color: MuiColors;
  name: string;
  icon: IconProp;
  defaultValue?: any;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  deleteByPath?: (name: string) => void;
  updateByPath?: (name: string, newValue: any) => void;
  handleUpdate?: () => void;
}

export const PlusMinusIcon: FC<{ onUpdate: () => void; value: any }> = ({
  onUpdate,
  value,
}) => {
  return (
    <Box
      position="relative"
      style={{
        transform: 'translate(25px, -45px)',
        cursor: 'pointer',
      }}
      onClick={onUpdate}
    >
      <FontAwesomeIcon
        icon={value ? 'minus' : 'plus'}
        size="1x"
        className={value ? 'text-danger' : 'text-success'}
      />
    </Box>
  );
};

const AddableIconButton: FC<AddableIconButtonProps> = ({
  value,
  name,
  icon,
  color,
  defaultValue,
  deleteByPath,
  updateByPath,
  handleUpdate: handleUpdateCustom,
  onClick,
}) => {
  const pathInGridParams = `appParams.gridParams.${name}`;

  const handleUpdate = () => {
    value
      ? deleteByPath && deleteByPath(pathInGridParams)
      : updateByPath && updateByPath(pathInGridParams, defaultValue);
  };

  return (
    <Box>
      <MuiIconButton
        onClick={onClick}
        color={color}
        disabled={!value}
        iconName={icon}
      />
      <PlusMinusIcon
        onUpdate={handleUpdateCustom || handleUpdate}
        value={value}
      />
    </Box>
  );
};

export default AddableIconButton;
