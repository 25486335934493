import HttpDatastore from 'rollun-ts-datastore';
import {
  Position,
  ProblemDTO,
  Problem,
  Client,
  SPECIAL_ITEMS_IDS,
} from './types';
import ApiClientsPositions from './api-clients-position';
import { Query } from 'rollun-ts-rql';
import { compareStringNumbers } from '../common/utils/compareStringNumbers';
import ApiClients from './api-clients-clients';

const datastore = new HttpDatastore<ProblemDTO>(
  '/api/datastore/ProblemsDataStore',
);

export default class ApiClientsProblems {
  apiPositions: ApiClientsPositions;
  apiClients: ApiClients;

  constructor() {
    this.apiPositions = new ApiClientsPositions();
    this.apiClients = new ApiClients();
  }

  async getById(id: string): Promise<Problem> {
    const problemDTO = await datastore.read(id);

    if (!problemDTO) {
      throw new Error(`Problem with id ${id} not found`);
    }

    const positions = this.apiPositions.getByDealId(id);
    const client = this.apiClients.getById(problemDTO.Contractor);

    const problem = Promise.all([problemDTO, positions, client]).then(
      (data) => {
        return this.convertDTOtoModel(...data);
      },
    );
    return problem;
  }

  async addOrUpdateItems(
    id: string,
    items: Omit<Position, 'offerId' | 'dealId' | 'id'>[],
  ) {
    const deal = await this.getById(id);
    const positions = deal.positions;

    const tempSortedItems: Position[] = [];

    for (let i = 0; i < positions.length; i++) {
      const position = { ...positions[i] };

      if (
        tempSortedItems.filter(
          (item) =>
            item.article === position.article &&
            compareStringNumbers(item.cost, position.cost) &&
            item.name === position.name,
        ).length
      )
        continue;

      tempSortedItems.push(position);

      const sameItems = items.filter(
        (item) =>
          item.article === position.article &&
          compareStringNumbers(item.cost, position.cost) &&
          item.name === position.name,
      );
      if (!sameItems.length) continue;

      const allQuantity = sameItems.reduce(
        (prev, cur) => prev + +cur.quantity,
        0,
      );
      position.quantity = +position.quantity + allQuantity;

      await this.apiPositions.update(position);
    }

    const filteredItems = items.filter((item) => {
      for (let i = 0; i < tempSortedItems.length; i++) {
        const position = { ...tempSortedItems[i] };
        if (
          item.article === position.article &&
          compareStringNumbers(item.cost, position.cost) &&
          item.name === position.name
        )
          return false;
      }
      return true;
    });

    if (filteredItems.length) await this.addItems(id, filteredItems);
  }

  async update(id: string, data: Partial<Omit<Problem, 'id' | 'positions'>>) {
    await datastore.update({
      ...this.convertModelToDTO(data),
      Id: id,
    });
  }

  async getByQuery(query: Query): Promise<Problem[]> {
    const problemsDTO = await datastore.query(query);
    const positions = await Promise.all(
      problemsDTO.map((problemDTO) =>
        this.apiPositions.getByDealId(problemDTO.Id),
      ),
    );
    const clients = await Promise.all(
      problemsDTO.map((problemDTO) =>
        this.apiClients.getById(problemDTO.Contractor),
      ),
    );

    return problemsDTO.map((problemDTO, index) =>
      this.convertDTOtoModel(problemDTO, positions[index], clients[index]),
    );
  }

  async create(problem: Partial<Omit<Problem, 'id'>>): Promise<Problem> {
    const problemDTO = await datastore.create(this.convertModelToDTO(problem));
    const positions = await this.apiPositions.getByDealId(problemDTO.Id);
    const client = await this.apiClients.getById(problemDTO.Contractor);

    if (problem.positions) {
      await this.addItems(problemDTO.Id, problem.positions);
    }
    return this.convertDTOtoModel(problemDTO, positions, client);
  }

  async addItems(
    id: string,
    items: Omit<Position, 'offerId' | 'dealId' | 'id'>[],
  ): Promise<void> {
    for (const item of items) {
      await this.apiPositions.add(id, item);
    }
  }

  async deleteItems(id: string, itemIds: string[]): Promise<void> {
    await Promise.all(
      itemIds.map((itemId) => this.apiPositions.delete(id, itemId)),
    );
  }

  convertDTOtoModel(
    problemDTO: ProblemDTO,
    positions: Position[],
    client: Client,
  ): Problem {
    const no_special_items_positions = positions?.filter(
      (position) => !SPECIAL_ITEMS_IDS.includes(position.article),
    );

    const problem = {
      id: problemDTO.Id,
      name: problemDTO.Name,
      positions,
      no_special_items_positions,
      isDraft: problemDTO.IsDraft,
      isPaid: problemDTO.IsPaid,
      timeCreated: problemDTO.TimeCreated,
      timeUpdated: problemDTO.TimeUpdated,
      owner: problemDTO.Owner,
      total: problemDTO.Total,
      mpName: problemDTO.MpName,
      reason: problemDTO.Reason,
      userId: problemDTO.UserId,
      history: problemDTO.History,
      manager: problemDTO.Manager,
      auditors: problemDTO.Auditors,
      solution: problemDTO.Solution,
      supplier: problemDTO.Supplier,
      weMustDo: problemDTO.WeMustDo,
      '4wemustdo': problemDTO['4wemustdo'],
      weMustDo1: problemDTO.WeMustDo1,
      weMustDo2: problemDTO.WeMustDo2,
      weWaitFor: problemDTO.WeWaitFor,
      '4wewaitfor': problemDTO['4wewaitfor'],
      contractor: problemDTO.Contractor,
      finalPrice: problemDTO.FinalPrice,
      weWaitFor1: problemDTO.WeWaitFor1,
      weWaitFor2: problemDTO.WeWaitFor2,
      weWaitFrom: problemDTO.WeWaitFrom,
      '4wewaitfrom': problemDTO['4wewaitfrom'],
      description: problemDTO.Description,
      ordernumber: problemDTO.Ordernumber,
      tracknumber: problemDTO.Tracknumber,
      weWaitFrom1: problemDTO.WeWaitFrom1,
      archiveScenario: problemDTO.ArchiveScenario,
      marketplace1: problemDTO.Marketplace1,
      mpOrderNumber: problemDTO.MpOrderNumber,
      srOrderNumber: problemDTO.SrOrderNumber,
      dateLastAction: problemDTO.DateLastAction,
      rmreturnnumber: problemDTO.Rmreturnnumber,
      srReturnNumber: problemDTO.SrReturnNumber,
      supplierRefundToUs: problemDTO.SupplierRefundToUs,
      tracknumberCarrier: problemDTO.TracknumberCarrier,
      orderLinkInMegaplan: problemDTO.OrderLinkInMegaplan,
      dateofthelastactions: problemDTO.Dateofthelastactions,
      sourceOfCommunication: problemDTO.SourceOfCommunication,
      srOriginalOrderNumber: problemDTO.SrOriginalOrderNumber,
      rmreplacementreturnnumber: problemDTO.Rmreplacementreturnnumber,
      status: problemDTO.Status,
      statusName: problemDTO.StatusName,
      relatedDeal: problemDTO.RelatedDeal,
      client: client,
    };

    return problem;
  }

  convertModelToDTO(
    problem: Partial<Omit<Problem, 'id' | 'positions'>>,
  ): Partial<Omit<ProblemDTO, 'Id'>> {
    return {
      Name: problem.name,
      IsDraft: problem.isDraft,
      IsPaid: problem.isPaid,
      TimeCreated: problem.timeCreated,
      TimeUpdated: problem.timeUpdated,
      Owner: problem.owner,
      Total: problem.total,
      MpName: problem.mpName,
      Reason: problem.reason,
      UserId: problem.userId,
      History: problem.history,
      Manager: problem.manager,
      Auditors: problem.auditors,
      Solution: problem.solution,
      ArchiveScenario: problem.archiveScenario,

      Supplier: problem.supplier,
      WeMustDo: problem.weMustDo,
      '4wemustdo': problem['4wemustdo'],
      WeMustDo1: problem.weMustDo1,
      WeMustDo2: problem.weMustDo2,
      WeWaitFor: problem.weWaitFor,
      '4wewaitfor': problem['4wewaitfor'],
      Contractor: problem.contractor,
      FinalPrice: problem.finalPrice,
      WeWaitFor1: problem.weWaitFor1,
      WeWaitFor2: problem.weWaitFor2,

      WeWaitFrom: problem.weWaitFrom,
      '4wewaitfrom': problem['4wewaitfrom'],
      Description: problem.description,

      Ordernumber: problem.ordernumber,
      Tracknumber: problem.tracknumber,
      WeWaitFrom1: problem.weWaitFrom1,
      Marketplace1: problem.marketplace1,
      MpOrderNumber: problem.mpOrderNumber,
      SrOrderNumber: problem.srOrderNumber,
      DateLastAction: problem.dateLastAction,
      Rmreturnnumber: problem.rmreturnnumber,
      SrReturnNumber: problem.srReturnNumber,
      SupplierRefundToUs: problem.supplierRefundToUs,
      TracknumberCarrier: problem.tracknumberCarrier,
      OrderLinkInMegaplan: problem.orderLinkInMegaplan,
      Dateofthelastactions: problem.dateofthelastactions,
      SourceOfCommunication: problem.sourceOfCommunication,
      SrOriginalOrderNumber: problem.srOriginalOrderNumber,
      Rmreplacementreturnnumber: problem.rmreplacementreturnnumber,
      Status: problem.status,
      StatusName: problem.statusName,
      RelatedDeal: problem.relatedDeal,
    };
  }
}

export const apiClientsProblems = new ApiClientsProblems();
