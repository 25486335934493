import { makeStyles } from '@material-ui/core';
import { MuiColors } from '../UI/MuiButton';
import { useMemo } from 'react';

const useCustomColor = (color: MuiColors | undefined) => {
  const useStyles = useMemo(
    () =>
      makeStyles((theme) =>
        !color
          ? { button: {} }
          : {
              button: {
                color: theme.palette.getContrastText(theme.palette[color].main),
                backgroundColor: theme.palette[color].main,
                '&:hover': {
                  backgroundColor: theme.palette[color].dark,
                  color: theme.palette.getContrastText(
                    theme.palette[color].main,
                  ),
                },
                disabled: {},
              },
            },
      ),
    [color],
  );

  return useStyles();
};

export default useCustomColor;
