import React, { FC, useContext } from 'react';
import './exerciser.css';
import { IconButton } from '@material-ui/core';
import { makeStaticCDNPath } from '../../../../utils/common.utils';
import { JSONataContext } from './JSONataContext';

export type JSONataPayload =
  | Record<string, unknown>
  | Array<any>
  | string
  | number;

interface IProps {
  isDisabled?: boolean;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  data?: JSONataPayload;
  width?: number;
  height?: number;
  iconWidth?: number;
  iconHeight?: number;
  isVisible: boolean;
  title?: string;
}

export const JSONataInteropControl: FC<IProps> = ({
  isDisabled = false,
  width = 27,
  height = 27,
  iconWidth = 40,
  data,
  iconHeight = 40,
  setOpen,
  isVisible,
  title = '',
}) => {
  const { setData, isOpen, setIsOpen } = useContext(JSONataContext);

  const toggle = () => {
    const isNotOpened = !isOpen;

    setData(data);
    setIsOpen(isNotOpened);
    setOpen && setOpen(isNotOpened);
  };

  return (
    <>
      {isVisible && (
        <IconButton
          title={title}
          onClick={toggle}
          style={{
            width: iconWidth,
            height: iconHeight,
          }}
        >
          <img
            style={{
              borderRadius: '100%',
            }}
            src={makeStaticCDNPath('/images/jsonata-button.png')}
            className={isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}
            alt="jsonata"
            width={width}
            height={height}
          />
        </IconButton>
      )}
    </>
  );
};
