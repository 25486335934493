export const dealStatus = [
  'authorized_waiting_for_receiving',
  'authorized_waiting_for_sending_and_replacement',
  'full_refund_needed',
  'partial_refund_needed',
  'no_refund_needed',
  'closed',
];

export const dealStatusPickup = [
  'authorized_waiting_for_receiving',
  'authorized_waiting_for_sending_and_replacement',
  'authorized',
  'authorized_with_replacement',
];

export const dealStatusDropship = [
  'authorized_waiting_for_receiving',
  'authorized',
  'none',
];
