/**
|--------------------------------------------------
| Statuses
|--------------------------------------------------
 */

export const LABEL_PROBLEM_WITH_PRODUCT = 'Problem with product';
export const LABEL_DOES_NOT_FIT = "Package doesn't fit";
export const LABEL_EMPTY = 'Empty Label';
export const LABEL_PACKED = 'PACKED';
export const LABEL_DID_NOT_RECEIVED = 'Did not received';

export const ORDER_STATUS_IN_PROGRESS = 'Being processed';
export const ORDER_STATUS_DONE = 'Processed';
export const ORDER_PARTIALLY_PACKED = 'Order partially packed';

export const GOOD_STATUS_NOT_PACKED = 'Not packed';
export const GOOD_STATUS_WRONG_PACKAGE = 'Wrong package';
export const GOOD_STATUS_IN_PROGRESS = 'In progress';
export const GOOD_PACKED = 'PACKED';

/**
|--------------------------------------------------
| Constants
|--------------------------------------------------
 */

export const SHOW_ORDERS_FOR_DAYS = 4;

/**
 |--------------------------------------------------
 | Actions
 |--------------------------------------------------
 */

export const ORDER_PICKED_UP_FROM_SUPPLIER = 'Order picked up from supplier';
export const ORDERS_HAVE_BEEN_DELIVERED_TO_THE_POST_OFFICE =
  'Orders have been delivered to the post office';
