import React, { useRef, useState } from 'react';
import { Form, Card } from 'react-bootstrap';
import { Button } from '../../../UI';
import '../index.css';
import { User } from '../types';

interface IProps {
  onSubmit: (user: User) => void;
  disabled: boolean;
  user: User;
}

const AddUserForm: React.FC<IProps> = ({ onSubmit, disabled, user }) => {
  const [validated, setValidated] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    if (!form.checkValidity()) {
      setValidated(true);
      return;
    }
    const formData = new FormData(form);

    setValidated(true);
    onSubmit({
      id: formData.get('userId') as string,
      password: formData.get('password') as string,
      name: formData.get('username') as string,
    });
    formRef.current?.reset();
  };

  return (
    <Card className="inner-card">
      <Card.Header>
        <h5>Choose username, user id and password</h5>
      </Card.Header>
      <Card.Body>
        <div className="form-wrapper">
          {/* Because react-bootstrap Form element has bug in its ref, need to cast formRef to some wierd type */}
          <Form
            ref={
              (formRef as unknown) as string & ((ref: Element | null) => void)
            }
            id="form-add-user"
            className="add-user-form"
            onSubmit={handleFormSubmit}
            noValidate
            validated={validated}
          >
            <div>
              <Form.Group controlId="formPlaintextId">
                <Form.Control
                  placeholder="User Id (required)"
                  type="text"
                  required
                  defaultValue={user.id}
                  disabled={disabled}
                  name="userId"
                />
              </Form.Group>

              <Form.Group controlId="formPlaintextEmail">
                <Form.Control
                  placeholder="Username (required)"
                  type="text"
                  required
                  defaultValue={user.name}
                  disabled={disabled}
                  name="username"
                />
              </Form.Group>

              <Form.Group controlId="formPlaintextPassword">
                <Form.Control
                  type="password"
                  placeholder="Password (optional)"
                  name="password"
                  defaultValue={user.password}
                  disabled={disabled}
                />
                <div style={{ color: 'gray' }}>
                  Your password must be hashed with password_hash php function
                  and PASSWORD_BCRYPT algorithm
                </div>
              </Form.Group>
            </div>

            <Button type="submit" disabled={disabled} color="primary">
              Submit
            </Button>
          </Form>
        </div>
      </Card.Body>
    </Card>
  );
};

export default AddUserForm;
