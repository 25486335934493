import React, { FC } from 'react';
import MuiButton from '../../../../../UI/MuiButton';
import { useDownloadClickNShipInfo } from '../hooks/useDownloadClickNShipInfo';

interface DownloadClickNShipInfoProps {
  dataStoreUrl: string;
}

const DownloadClickNShipInfo: FC<DownloadClickNShipInfoProps> = ({
  dataStoreUrl,
}) => {
  const [{ isLoading, error }, handleDownload] = useDownloadClickNShipInfo(
    dataStoreUrl,
  );

  if (error) {
    alert(`There was an error! ${error}`);
  }

  return (
    <MuiButton color="success" onClick={handleDownload} loading={isLoading}>
      Download Click'N'Ship info
    </MuiButton>
  );
};

export default DownloadClickNShipInfo;
