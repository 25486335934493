import { useQuery } from 'react-query';
import {
  ClientsApi,
  Configuration,
  Client,
} from '../../../../../crm-api/index';

export const GET_CLIENT_KEY = 'GET_CLIENT_KEY';
export const clientsApi = new ClientsApi(
  new Configuration({
    basePath: '/api/openapi/CRMClients/v1',
  }),
);

export const useGetClient = (id: string) => {
  return useQuery<Client>(GET_CLIENT_KEY, async () => {
    try {
      const client = await clientsApi.clientsIdGet(id);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return client.data.data!;
    } catch (error) {
      return {
        id,
        address: {
          city: 'city',
          street1: 'street1',
          street2: 'street2',
          zip: 'zip',
          state: 'state',
        },
        contactInfo: {
          email: 'test1',
          phoneNumber: 'test1',
        },
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      } as any;
    }
  });
};
