import React, { Component, ReactNode } from 'react';
import { Button, ErrorView } from '../UI';
import _ from 'lodash';
import { MINIFIED_REACT_ERRORS } from '../utils/common.constants';
import { logger } from '../utils/logger';

type ErrorState = Record<string, number>;

interface IState {
  lastError: string;
  didCatch: boolean;
  currentErrors: ErrorState;
}

/**
 * Error Boundary component, catches any render error, and renders fallback UI
 * @see https://en.reactjs.org/docs/error-boundaries.html
 */

// TODO add max errors logged to state, to not spam logger, if there are a lot of errors

interface ErrorBoundaryProps {
  children?: ReactNode;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, IState> {
  maxErrorsCount = 5;

  state = {
    lastError: '',
    didCatch: false,
    currentErrors: {} as ErrorState,
  };

  static getDerivedStateFromError() {
    return { didCatch: true };
  }

  resetErrorCount = _.debounce((code: string) => {
    this.setState(({ currentErrors }) => ({
      currentErrors: {
        ...currentErrors,
        [code]: 0,
      },
    }));
  }, 3000);

  incrementErrorCount = (code: string) => {
    const { currentErrors } = this.state;

    if (!currentErrors[code]) {
      this.setState(({ currentErrors }) => ({
        currentErrors: {
          ...currentErrors,
          [code]: 1,
        },
      }));
      return;
    }

    this.setState(({ currentErrors }) => ({
      currentErrors: {
        ...currentErrors,
        [code]: currentErrors[code] + 1,
      },
    }));
  };

  componentDidCatch(error: Error): void {
    const { currentErrors } = this.state;
    const [msg, type] = this.decodeMinifiedError(error.toString());

    if (currentErrors[type] >= this.maxErrorsCount) {
      this.resetErrorCount(type);
      return;
    }

    this.setState({ lastError: `${type}: ${msg}` });

    this.incrementErrorCount(type);
    logger.error(msg, {
      message: msg,
      type,
      originalMsg: error.toString(),
    });
  }

  parse = (str: string, args: string[], i = 0) =>
    str.replace(/%s/g, () => args[i++]);

  decodeMinifiedError = (msg: string) => {
    const urlMatch = msg.match(/https?:\/\/[^\s]+/);

    if (!urlMatch) {
      return [`Could not parse the error msg! ${msg}`, 'unknown'];
    }

    const { searchParams } = new URL(urlMatch[0]);
    const args = searchParams.getAll('args[]');
    const [invariant] = searchParams.getAll('invariant');
    if (!MINIFIED_REACT_ERRORS[invariant]) {
      return [`Cannot find error by code: "${invariant}". ${msg}`, 'unknown'];
    }

    return [this.parse(MINIFIED_REACT_ERRORS[invariant], args), invariant];
  };

  _formatTimeNumber = (n: number) => (n < 10 ? '0' : '') + n;

  render() {
    if (this.state.didCatch) {
      return (
        <div className="w-100 h-100 justify-content-center d-flex flex-column align-items-center">
          <div className="material-shadow px-4 pb-4 border-radius bg-white">
            <ErrorView header="Error">
              <div className="text-center">
                <p className="font-weight-bold px-4">
                  An Error occurred while rendering
                </p>
                <p className="font-weight-bold px-4">
                  Complete error report You can find in console
                </p>
                <pre>{this.state.lastError}</pre>
                <Button
                  color="primary"
                  block
                  onClick={() => window.location.reload()}
                >
                  Reload page
                </Button>
              </div>
            </ErrorView>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
