import React, { PureComponent } from 'react';
import Footer from '../Footer';
import Header from '../Header/Header';
import HttpDatastore from 'rollun-ts-datastore';
import { ServiceInfo } from '../../pages/RootApp/Root.app';
import { unflatten } from '../../utils/common.utils';
import BreadCrumbs from '../BreadCrumbs';
import _ from 'lodash';
import LeftMenu from '../NavMenu/LeftMenu/LeftMenu';
import {
  Container,
  Paper,
  Theme,
  WithStyles,
  withStyles,
  withWidth,
} from '@material-ui/core';
import { drawerWidth } from '../../utils/common.constants';
import { TagsUpdaterParams } from '../../pages/AbstractService';

export interface MenuItem {
  label: string;
  url: string;
  subMenus?: Array<MenuItem>;
}

type FlatMenuItem = {
  id: string;
  label: string;
  url: string;
  parent_id: string;
};

export interface AppContainerProps extends WithStyles {
  user?: string;
  role?: string;
  services: { [key: string]: ServiceInfo };
  disableLeftMenu?: boolean;
  currentResourceName?: string;
  fullWidth?: boolean;
  width: string;
  fullHeight?: boolean;
}

interface IState {
  menuItems: { leftMenu: Array<MenuItem>; topMenu: Array<MenuItem> };
  loading: boolean;
  containerHeight: number;
  isLeftMenuShown: boolean;
  // snowAmount: number;
}

/**
 * Container for any service right now(In fact You can use any container for pages)
 * It fetches configs for Header and NavMenu from datastore
 */

export const LeftMenuContext = React.createContext<{
  isOpen: boolean;
  setIsOpen: () => void;
}>({
  isOpen: localStorage.getItem('isLeftMenuOpen') === 'true',
  setIsOpen: () => {
    return;
  },
});

export const EditorContext = React.createContext<{
  tagsUpdaterParams: TagsUpdaterParams;
} | null>(null);

const styles = (theme: Theme) => ({
  root: {
    fontSize: '0.8rem',
    minHeight: '100vh',
  },
  innerContainer: {
    display: 'flex',
    margin: 0,
    width: '100%',
    height: '100%',
  },
  container: {
    maxWidth: '100%',
    maxHeight: '100%',
    zIndex: 5,
    overflow: 'scroll',
    padding: theme.spacing(1),
  },
  appContainer: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    height: '100%',
  },
  mainContent: {
    minHeight: '300px',
    padding: theme.spacing(1, 1),
  },
});

class AppContainer extends PureComponent<AppContainerProps, IState> {
  MenuDataStore = new HttpDatastore<FlatMenuItem>(
    '/api/datastore/menuForUserRole_db',
  );
  handleResizeWindow = _.debounce(() => {
    this.setState({ containerHeight: window.innerHeight - 100 });
  }, 300);

  constructor(props: AppContainerProps) {
    super(props);
    this.state = {
      menuItems: {
        leftMenu: [],
        topMenu: [],
      },
      loading: false,
      containerHeight: window.innerHeight - 100,
      // snowAmount: +(localStorage.getItem('snowAmount') || 80),
      isLeftMenuShown:
        localStorage.getItem('isLeftMenuOpen') === 'true' &&
        !props.disableLeftMenu,
    };
  }

  // Fetch configs for top and left menus

  /**
   * Filters menu items according to config, got from frontConfig
   * @param leftMenu {MenuItem}
   * @param topMenu {MenuItem}
   * @private
   */

  fetchMenuItems = () => {
    const cacheName = 'LAYOUT_CACHE';
    const cachedConfig = sessionStorage.getItem('LAYOUT_CACHE');

    if (cachedConfig) {
      this.setState({ menuItems: JSON.parse(cachedConfig) });
    } else {
      this.setState({ loading: true });
      this.MenuDataStore.query()
        .then((menuConfigArray) => {
          const tree = unflatten(menuConfigArray, {
            childrenField: 'subMenus',
          });

          const leftMenu: MenuItem[] = (tree.find(
            (el) => el.label === 'Left Menu',
          )?.subMenus || []) as MenuItem[];
          const topMenu: MenuItem[] = (tree.find(
            (el) => el.label === 'Top Menu',
          )?.subMenus || []) as MenuItem[];

          const menuItems = {
            leftMenu,
            topMenu,
          };

          sessionStorage.setItem(cacheName, JSON.stringify(menuItems));
          this.setState({
            menuItems,
            loading: false,
          });
        })
        .catch((e) => {
          console.log(e);
          this.setState({ loading: false });
        });
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResizeWindow);
  }

  componentDidMount() {
    console.log('App container mounted');
    console.log('didMount services::: ', this.props.services);
    this.fetchMenuItems();
    window.addEventListener('resize', this.handleResizeWindow);
  }

  render() {
    const {
      user,
      role,
      disableLeftMenu = false,
      currentResourceName,
      services,
      classes: { root, container, innerContainer, appContainer, mainContent },
      width,
    } = this.props;
    const { loading, containerHeight } = this.state;

    return (
      <div
        id="layout"
        className={root}
        style={{
          ...(this.props.fullHeight ? { height: '100%' } : {}),
        }}
      >
        {/*<Snowfall*/}
        {/*  snowflakeCount={this.state.snowAmount}*/}
        {/*  style={{ zIndex: 100000 }}*/}
        {/*/>*/}
        <LeftMenuContext.Provider
          value={{
            isOpen: this.state.isLeftMenuShown && !disableLeftMenu,
            setIsOpen: () => {
              this.setState(({ isLeftMenuShown }) => {
                localStorage.setItem('isLeftMenuOpen', `${!isLeftMenuShown}`);
                return { isLeftMenuShown: !isLeftMenuShown };
              });
            },
          }}
        >
          <Header
            items={this.state.menuItems.topMenu}
            user={user}
            role={role}
            loading={loading}
            services={services}
            disableLeftMenu={disableLeftMenu}
          />
          {!disableLeftMenu && (
            <LeftMenu content={this.state.menuItems.leftMenu} />
          )}
          <Container
            id="container"
            className={container}
            style={{
              height: containerHeight,
            }}
          >
            <div
              className={innerContainer}
              style={{
                justifyContent: disableLeftMenu ? 'center' : undefined,
              }}
            >
              <div
                style={{
                  transition: this.state.isLeftMenuShown
                    ? 'margin-left 0.5s cubic-bezier(0, 0, 0.2, 1) 0ms, width 0.5s cubic-bezier(0, 0, 0.2, 1) 0ms'
                    : 'margin-left 0.5s cubic-bezier(0.4, 0, 0.6, 1) 0ms, width 0.5s cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                  width:
                    this.state.isLeftMenuShown &&
                    !['xs', 'sm', 'md'].includes(width)
                      ? `calc(100% - ${drawerWidth}px)`
                      : '100%',
                  marginLeft:
                    this.state.isLeftMenuShown &&
                    !['xs', 'sm', 'md'].includes(width)
                      ? drawerWidth
                      : '',
                }}
                className={appContainer}
              >
                <BreadCrumbs
                  menus={this.state.menuItems.leftMenu}
                  currentPath={window.location.pathname}
                />
                <Paper
                  elevation={3}
                  variant="elevation"
                  className={mainContent}
                  style={{
                    ...(this.props.fullHeight ? { height: '100vh' } : {}),
                  }}
                >
                  {this.props.children}
                </Paper>
              </div>
            </div>
          </Container>
        </LeftMenuContext.Provider>
        <Footer
          // snowAmount={this.state.snowAmount}
          // onSnowAmountChange={(snowAmount) => {
          //   localStorage.setItem('snowAmount', `${snowAmount}`);
          //   this.setState({ snowAmount });
          // }}
          currentResourceName={
            ((process.env.NODE_ENV === 'development' ||
              (role && role.indexOf('admin') > -1)) &&
              currentResourceName) ||
            ''
          }
        />
        ;
      </div>
    );
  }
}

export default withStyles(styles)(withWidth()(AppContainer));
