import React, { FC } from 'react';
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const LinkComponent: FC<{ link: string }> = ({ link, children }) => {
  if (/https?:\/\//.test(link)) {
    return <Link href={link}>{children}</Link>;
  }
  return <RouterLink to={`${link}`}>{children}</RouterLink>;
};

export default LinkComponent;
